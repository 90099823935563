import React, { useLayoutEffect } from "react";
import * as am5 from "@amcharts/amcharts5";
import * as am5hierarchy from "@amcharts/amcharts5/hierarchy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import { getUniqueAmchartId } from "../../utils/amchartUtils";
import { mchartDataFormat } from "../../services/detailsServices";
import { useAtom } from "jotai";

const AmchartThumbnailNew = ({ selectedData }) => {
  // give unique id to amchart canvas to avoid duplicate id error
  const uniqueDivId = getUniqueAmchartId();

  useLayoutEffect(() => {
    var root = am5.Root.new(`${uniqueDivId}`);

    root.setThemes([am5themes_Animated.new(root)]);

    var container = root.container.children.push(
      am5.Container.new(root, {
        width: am5.percent(100),
        height: am5.percent(100),
        layout: root.verticalLayout,
      })
    );
    root.interfaceColors.set("grid", am5.color(0xff0000));

    var series = container.children.push(
      am5hierarchy.ForceDirected.new(root, {
        singleBranchOnly: false,
        downDepth: 1,
        initialDepth: 1,
        topDepth: 0,
        //node id use for size
        valueField: "id",
        // text value or node label
        // categoryField: "name",
        name: "text",
        // node children
        childDataField: "children",
        manyBodyStrength: -40,
        minRadius: 9,
        maxRadius: 9,
        xField: "x",
        yField: "y",
      })
    );

    series.nodes.template.setAll({
      draggable: false,
      toggleKey: "none",
      cursorOverStyle: "default",
    });

    // node label text
    series.nodes.template.setAll({
      tooltipText: "",
      width: am5.percent(90),
      tooltipY: 0,
    });

    series.showOnInit = false;

    series.links.template.setAll({
      strokeWidth: 1.5,
    });

    series.links.template.adapters.add("stroke", function (fill, target) {
      return am5.color(0x68c7fc);
    });

    series.circles.template.setAll({
      templateField: "nodeSettings",
      // fill: am5.color(0x4093ff),
    });

    //Outer Circle color
    series.outerCircles.template.setAll({
      templateField: "nodeSettings1",
    });

    var tooltip = am5.Tooltip.new(root, {
      getFillFromSprite: false,
      getStrokeFromSprite: false,
      autoTextColor: false,
      getLabelFillFromSprite: true,
      // labelText: "[bold]{name}[/]\n{valueX.formatDate()}: {valueY}"
    });

    tooltip.get("background").setAll({
      fill: am5.color(0xffffff),
      // fillOpacity: 0.8,
    });

    series.set("tooltip", tooltip);

    series.events.on("inited", function () {
      series.animate({ property: "velocityDecay", to: 1 }, 3000);
    });

    series.data.setAll(mchartDataFormat(selectedData, true));

    return () => {
      root.dispose();
    };
  }, [selectedData]);

  return (
    <div
      id={`${uniqueDivId}`}
      style={{
        width: "150px",
        height: "100px",
        marginLeft: "-23px",
        marginTop: "-5px",
      }}
    ></div>
  );
};

export default AmchartThumbnailNew;
