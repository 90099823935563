import { useAtom } from "jotai";
import React from "react";
import Highlighter from "react-highlight-words";
import { useLocation } from "react-router-dom";
import { searchedWord } from "../../atom/jotai";
import { deslugifyText } from "../../utils/basicUtils";

/**
 * Description: Component to hightlight searched text
 */
const HighlightText = ({ text, keywords }) => {
  // get the searched word from state
  const [searchStr] = useAtom(searchedWord);
  const location = useLocation();
  const currentLocation = location?.pathname.split("/")[1];

  var textSearchStr;
  var searchWords = [];

  if (searchStr.includes("keywords")) {
    //If Nlp query is searched
    searchWords.push(searchStr.slice(9));
  } else {
    // deslugify the searched text
    textSearchStr = deslugifyText(searchStr);

    // words to search
    searchWords = textSearchStr.split(/\s/).filter((word) => word);
  }

  return (
    <>
      {searchWords == "*" ? (
        <p>{text}</p>
      ) : (
        <Highlighter
          highlightClassName="YourHighlightClass"
          caseSensitive={false}
          highlightStyle={{ fontWeight: "normal" }}
          searchWords={!keywords ? searchWords : keywords}
          textToHighlight={text === null ? " " : text.toString()}
        />
      )}
    </>
  );
};

export default HighlightText;
