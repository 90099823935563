import React, { useState, useContext } from "react";
import { IconButton } from "@mui/material";
import { CloseOutlined, Search } from "@mui/icons-material";
import {
  KeyboardArrowDownOutlined,
  KeyboardArrowUpOutlined,
} from "@mui/icons-material";
import { useAtom } from "jotai";
import "./InternalSearchBox.css";
import {
  totalCountOfHighlightWord,
  activeCountOfHighlightWord,
  positionOfHighlightWord,
  finalMapping,
  indexOfSearchedKeywordAtom,
} from "../../atom/jotai";
import { useEffect } from "react";
import { jsx } from "@emotion/react";

const InternalSearchBox = ({
  findElement,
  setFindElement,
  currentCount,
  setCurrentCount,
  prevHighlightedLOC,
  setprevHighlightedLOC,
  handleDownHighlightedSelection,
  handleUpHighlightedSelection,
}) => {
  const openSidebar = false;
  const [finalPosition, setFinalPosition] = useAtom(finalMapping);

  //Jotai states
  const [totalCountOfHighlightedWord, setTotalCountOfHighlitedWord] = useAtom(
    totalCountOfHighlightWord
  );
  const [activeCountOfHighlightedWord, setActiveCountOfHighlitedWord] = useAtom(
    activeCountOfHighlightWord
  );
  const [positionOfHighlightedWord, setPositionOfHighlightedWord] = useAtom(
    positionOfHighlightWord
  );
  const [indexOfSearchedKeyword, setIndexOfSearchedKeyword] = useAtom(
    indexOfSearchedKeywordAtom
  );

  // console.log("currentCount", findElement, currentCount);

  return (
    <>
      <div
        className={openSidebar ? "find-input-sidebarOpen" : "find-input"}
        id="resultInternalSearchDiv"
      >
        <input
          id="resultInternalSearchInput"
          className="find-input-filed"
          type="text"
          placeholder={`Find`}
          value={findElement === null ? "" : findElement}
          onKeyDown={(ev) => {
            if (
              ev.keyCode === 40 &&
              currentCount < totalCountOfHighlightedWord
            ) {
              setFindElement(ev.target.value);
              handleDownHighlightedSelection(ev);
            }
          }}
          onKeyUp={(ev) => {
            if (ev.keyCode === 38 && currentCount >= 2) {
              setFindElement(ev.target.value);
              handleUpHighlightedSelection(ev);
            }
          }}
          onChange={(e) => {
            setFindElement(e.target.value);
            setCurrentCount(0);
            setprevHighlightedLOC([]);
            setIndexOfSearchedKeyword(-1);
            setActiveCountOfHighlitedWord(0);
          }}
        />

        <span
          style={
            findElement === "" || findElement === null
              ? { marginLeft: "40px" }
              : { marginLeft: "10px" }
          }
        >
          {findElement === "" || findElement === null
            ? ""
            : `${currentCount}/${totalCountOfHighlightedWord}`}
          {/* {activeCountOfHighlightedWord}/{totalCountOfHighlightedWord} */}
        </span>
        <span style={{ marginLeft: "10px" }}>|</span>
        <IconButton
          id="resultInternalSearchDownArrow"
          aria-label="downArrow"
          style={{ padding: "0px", marginTop: "3px", marginLeft: "10px" }}
          title="Down"
          onClick={(e) => {
            handleUpHighlightedSelection(e);
          }}
          disabled={currentCount <= 1}
        >
          <KeyboardArrowUpOutlined />
        </IconButton>

        <IconButton
          id="resultInternalSearchUpArrow"
          aria-label="upArrow"
          style={{ padding: "0px", marginTop: "3px" }}
          title="Up"
          onClick={(e) => {
            handleDownHighlightedSelection(e);
          }}
          disabled={currentCount >= totalCountOfHighlightedWord}
        >
          <KeyboardArrowDownOutlined />
        </IconButton>

        {/* {findElement && ( */}
        <IconButton
          id="resultInternalSearchCancelButton"
          type="submit"
          style={{ padding: "0px", marginTop: "3px", marginLeft: "10px" }}
          className="cancel_querypage_searchIcons"
          onClick={(e) => {
            setFindElement("");
            setTotalCountOfHighlitedWord(0);
            setIndexOfSearchedKeyword(-1);
            setCurrentCount(0);
            setprevHighlightedLOC([]);
            document
              .getElementById(`0scrollToHighlighter`)
              .scrollIntoView(false, {
                behavior: "smooth",
                block: "start",
              });
          }}
          disabled={findElement === "" || findElement === null}
          aria-label="cancel"
        >
          {" "}
          <CloseOutlined fontSize="small" />
        </IconButton>
        {/* )} */}

        {/* <span className="searchIcons">
          <Search className="searchIconResult" onClick={(e) => handleSearch(e)} />
        </span> */}
      </div>
    </>
  );
};

export default InternalSearchBox;
