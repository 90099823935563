export const extractDataFromToken = (token) => {
    try {
      const base64Url = token.split('.')[1]; // Extract the payload section of the token
      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/'); // Replace URL-safe characters
      const decodedPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      }).join('')); // Decode the base64-encoded payload and handle URL encoding
  
      const data = JSON.parse(decodedPayload); // Parse the decoded payload as JSON
      return data;
    } catch (error) {
      // Handle token decoding error
      console.log('Error decoding token:', error);
      return null;
    }
  }