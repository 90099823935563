import React, { useEffect, useState } from "react";
import { db_keys_constaants } from "../../constants/databaseKeyConstants";
import MuiTable from "../Table/MuiTable";
import NoResult from "../NoResult/NoResult";
import { dateFormate } from "../../utils/basicUtils";
import { nlp_date_key_const } from "../../constants/nplConstants";

const NLPQqueryResultTable = ({ nlpResponseData }) => {
  //state for maintaing table columns data
  const [columnData, setColumnData] = useState([]);

  //state for maintaing table rows data
  const [rowData, setRowData] = useState([]);

  useEffect(() => {
    if (nlpResponseData) {
      let col_data = [];
      let col_data_row_wise = [];
      let row_data = [];
      nlpResponseData.length > 0 &&
        nlpResponseData.map((nlpData) => {
          let row_data_object = {};

          nlpData.hasOwnProperty("_source") &&
            Object.entries(nlpData._source).map(([key, value], i) => {
              let col_data_object = {};
              if (!key.toLocaleLowerCase().includes("_id")) {
                col_data_object = {
                  field: key,
                  headerName: db_keys_constaants.hasOwnProperty(`${key}`)
                    ? db_keys_constaants[key]
                    : key,
                  sortable: false,
                  descending: false,
                  checked: true,
                  position: "sticky",
                  left: 0,
                  dropDown: false,
                  slider: false,
                };

                //Adding column name as per row wise
                col_data_row_wise.push(col_data_object);
              }

              row_data_object[key] =
                value === null || value === ""
                  ? "-"
                  : value === true
                  ? "True"
                  : value === false
                  ? "False"
                  : nlp_date_key_const.includes(`${key}`)
                  ? dateFormate(value)
                  : value;
            });
          col_data.push(col_data_row_wise);
          col_data_row_wise = []; //making array blank to avoid duplication
          row_data.push(row_data_object);
        });

      if (col_data.length > 0) {
        setColumnData(col_data[0]);
      }
      if (row_data.length > 0) {
        setRowData(row_data);
      }
    }
  }, [nlpResponseData]);

  const handleManageColumns = (e) => {
    // setAnchorMangColMnuOpt(e.currentTarget);
  };

  //Below function is called when any row is clicked,and also it redirects the details page
  const handleRowClick = (e, current_row_data) => {
    // if (isAccessProvided("details", "crud", null, "read", accessDataByRole)) {
    //   e.stopPropagation();
    //   e.preventDefault();
    //   analyticServices(
    //     `Selected Type : ${current_row_data?.__typename}/${current_row_data?.name} `
    //   );
    //   navigate(listviewRowClick(current_row_data));
    // }
  };

  return columnData && rowData ? (
    <MuiTable
      table_columns_data={columnData && columnData}
      table_rows_data={rowData && rowData}
      handleManageColumns={handleManageColumns}
      page={"nlp"}
      //   find_element={find_element}
      handleRowClick={handleRowClick}
      //   handleCardButton={handleCardButton}
      //   handleSort={handleSort}
      //   handleSelectPolicy={handleSelectPolicy}
      //   policy_data={policy_data}
      //   scores_selected_policy={scores_selected_policy}
      //   searchText={searchText}
      //   page={page}
      //   handleScreInclusionColumnClk={handleScreInclusionColumnClk}
      //   isScrIncLoading={isScrIncLoading}
      //   rowIdWhrScoreIncClicked={rowIdWhrScoreIncClicked}
    />
  ) : (
    <NoResult title={"No results to show"} />
  );
};

export default NLPQqueryResultTable;
