import { request } from "graphql-request";
import { useMutation, useQuery } from "react-query";
import { authUser, regionIDAtom, selectedBusinessUnit } from "../atom/jotai";
import { queryKeyConstants } from "../constants/basicConstants";
import { ColorIndexNames, MutationKeyName } from "../constants/dataTypes";
import {
  getAppKonferScore,
  getModalCardData,
  getRiskFactors,
  getAllRegions,
  getAllRegion,
  testingSe3,
  getCardTemplateCreatedByName,
  getCPOAnnotationsQuery,
  getCardTemplateDatasetQuery,
} from "../graphql/queries";
import {
  formattedData,
  sortDataByKonferScore,
} from "../services/listviewServices";
import { deslugifyText } from "../utils/basicUtils";
import nodeApiInstance from "./instance/nodeApiInstance";
import { KONFER_SCORE } from "../constants/basicConstants";
import { userRoles, typeNames } from "../constants/dataTypes";
import { useAtom } from "jotai";
import {
  getDataIds,
  getFormatedData,
  mapBusinessUnits,
  uniqueRegionFinalIds,
} from "../services/searchServices";
import _ from "lodash";
import { complianceTagValue } from "../constants/complianceConstants";
import axios from "axios";
import { getLocalTokens } from "../utils/authUtils";
import { toast } from "react-toastify";
import { extractionToasts } from "../constants/toasterMsgConstants";

const endpoint = `${process.env.REACT_APP_GRAPHQL_API}v1/graphql`;
const endpointForAgent = `${process.env.REACT_APP_ADMIN_CONFIGURATION}/config`;

// search services
export const useGetSearchDetails = (searchText) => {
  let finalSearch;

  const [selectedBuId] = useAtom(selectedBusinessUnit);
  const [regionID] = useAtom(regionIDAtom);
  const [user] = useAtom(authUser);

  if (searchText === KONFER_SCORE) {
    finalSearch = "*";
  } else if (searchText === "*") {
    finalSearch = "*";
  } else {
    finalSearch = String(searchText);
  }

  let units, region;
  let userRole = user?.role;
  let contibutorId = user?.contributor_id;

  region = regionID == -1 ? complianceTagValue.AllRegions : regionID;

  regionID == complianceTagValue.AllRegions
    ? (units = [0])
    : (units =
        selectedBuId.length == 0
          ? [complianceTagValue.AllBusinessUnis]
          : selectedBuId);
  return useQuery(
    ["searchData", searchText, units, contibutorId, region],
    async () => {
      const { data } = await nodeApiInstance.post("graph_data/search-data", {
        searchText: finalSearch,
      });

      const { appIds, modelIds, dataSetIds } = getDataIds(data);

      let finalAppIds = [];
      let finalModelIds = [];
      let finalDsIds = [];

      if (
        region === complianceTagValue.AllRegions &&
        (userRole === userRoles.ADMIN ||
          userRole === userRoles.CPO ||
          userRole === userRoles.SUPER_ADMIN ||
          userRole === userRoles.ORG_ADMIN)
      ) {
        finalAppIds = appIds;
        finalModelIds = modelIds;
        finalDsIds = dataSetIds;
      } else {
        let regionAppIds, regionModelIds, regionDataIds;
        if (appIds && appIds.length > 0) {
          let { data } = await nodeApiInstance.post(
            "/business_unit/get-entity-region-business-unit",
            {
              type: "app",
              business_unit_id: units[0],
              region_id: region,
              contributor_id: contibutorId,
            }
          );

          regionAppIds = data.data;
        }

        if (modelIds && modelIds.length > 0) {
          let { data } = await nodeApiInstance.post(
            "/business_unit/get-entity-region-business-unit",
            {
              type: "model",
              business_unit_id: units[0],
              region_id: region,
              contributor_id: contibutorId,
            }
          );

          regionModelIds = data.data;
        }

        if (dataSetIds && dataSetIds.length > 0) {
          let { data } = await nodeApiInstance.post(
            "/business_unit/get-entity-region-business-unit",
            {
              type: "input_data_table",
              business_unit_id: units[0],
              region_id: region,
              contributor_id: contibutorId,
            }
          );

          regionDataIds = data.data;
        }

        const filteredArray = [4, 5].filter((value) => [2, 3].includes(value));

        finalAppIds = uniqueRegionFinalIds(
          appIds,
          regionAppIds && Object.keys(regionAppIds).length > 0
            ? _.uniq(mapBusinessUnits(regionAppIds, typeNames.apps, true))
            : []
        );

        finalModelIds = uniqueRegionFinalIds(
          modelIds,
          regionModelIds && Object.keys(regionModelIds).length > 0
            ? _.uniq(mapBusinessUnits(regionModelIds, typeNames.models, true))
            : []
        );

        finalDsIds = uniqueRegionFinalIds(
          dataSetIds,
          regionDataIds && Object.keys(regionDataIds).length > 0
            ? _.uniq(mapBusinessUnits(regionDataIds, typeNames.datasets, true))
            : []
        );
      }

      const finalFormatData = getFormatedData(
        finalAppIds,
        finalModelIds,
        finalDsIds
      );
      return {
        appIds: finalAppIds,
        modelIds: finalModelIds,
        dataSetIds: finalDsIds,
        finalFormatData,
        data: data,
        keywords: data?.keywords,
      };
    },
    {
      enabled: !!searchText,
    }
  );
};

// Details API
export const useGetAppsDetails = (
  appIds,
  isApp,
  konferScore,
  isSortUp,
  modInvoker,
  subModInvoker
) => {
  return useQuery(
    [queryKeyConstants.appsDetails, appIds, isSortUp],
    async () => {
      let finalData;

      const { data } = await nodeApiInstance.post(
        "details/get-entity-details",
        {
          type: "app",
          entity_ids: appIds,
          modInvoker,
          subModInvoker,
        }
      );

      let formatData = formattedData(data?.data, "app");

      if (konferScore) {
        const dataToMap = formatData.app; //data.app;

        finalData = sortDataByKonferScore(
          dataToMap,
          ["risk_factors_data[0].konfer_composite_score"],
          [isSortUp ? "asc" : "desc"]
        );
      } else {
        finalData = formatData.app; //data.app;
      }
      return finalData;
    },
    {
      enabled: !!isApp,
    }
  );
};

export const useGetModelsDetails = (
  modelIds,
  isModel,
  konferScore,
  isSortUp,
  modInvoker,
  subModInvoker
) => {
  return useQuery(
    [queryKeyConstants.modelsDetails, modelIds, isSortUp],
    async () => {
      let finalData;
      const { data } = await nodeApiInstance.post(
        "details/get-entity-details",
        {
          type: "model",
          entity_ids: modelIds,
          modInvoker,
          subModInvoker,
        }
      );

      let formatData = formattedData(data?.data, "model");
      if (konferScore) {
        const dataToMap = formatData.model;
        finalData = sortDataByKonferScore(
          dataToMap,
          ["risk_factors_data.score_data[0].konfer_composite_score"],
          [isSortUp ? "asc" : "desc"]
        );
      } else {
        finalData = formatData.model;
      }
      return finalData;
    },
    {
      enabled: !!isModel,
    }
  );
};

export const useGetDatasetsDetails = (
  datasetIds,
  isDataset,
  konferScore,
  isSortUp,
  modInvoker,
  subModInvoker
) => {
  return useQuery(
    [queryKeyConstants.datasetsDetails, datasetIds, isSortUp],
    async () => {
      let finalData;
      const { data } = await nodeApiInstance.post(
        "details/get-entity-details",
        {
          type: "input_data_table",
          entity_ids: datasetIds,
          modInvoker,
          subModInvoker,
        }
      );

      let formatData = formattedData(data?.data, "input_data_table");

      if (konferScore) {
        const dataToMap = formatData.input_data_table;
        finalData = sortDataByKonferScore(
          dataToMap,
          ["risk_factors_data.score_data[0].konfer_composite_score"],
          [isSortUp ? "asc" : "desc"]
        );
      } else {
        finalData = formatData.input_data_table;
      }
      return finalData;
    },
    {
      enabled: !!isDataset,
    }
  );
};

// Get Data By ID

export const useGetAppData = (appId, callApi) => {
  return useQuery(
    [queryKeyConstants.appData, appId],

    async () => {
      const { data } = await nodeApiInstance.get("/details/get-entity-data", {
        params: {
          entity_type: "app",
          entity_id: appId,
        },
      });

      return data.data;
    },
    {
      enabled: !!callApi,
    }
  );
};

export const useGetModelData = (modelId, callApi) => {
  return useQuery(
    [queryKeyConstants.modelData, modelId],
    async () => {
      const { data } = await nodeApiInstance.get("/details/get-entity-data", {
        params: {
          entity_type: "model",
          entity_id: modelId,
        },
      });

      return data.data;
    },
    {
      enabled: !!callApi,
    }
  );
};

export const useGetDatasetData = (datasetId, callApi) => {
  return useQuery(
    [queryKeyConstants.datasetData, datasetId],
    async () => {
      const { data } = await nodeApiInstance.get("/details/get-entity-data", {
        params: {
          entity_type: "input_data_table",
          entity_id: datasetId,
        },
      });

      return data.data;
    },
    {
      enabled: !!callApi,
    }
  );
};

// get card template
export const useGetCardTemplate = (keyName, callApi) => {
  return useQuery(
    [queryKeyConstants.cardTemplateData, keyName],
    async () => {
      const data = await request(
        endpoint,
        getCardTemplateDatasetQuery(keyName)
      );

      return data;
    },
    {
      enabled: !!callApi,
    }
  );
};

//contributor name

export const useGetCardTemplateCreatedByName = (contributorId) => {
  return useQuery(
    [queryKeyConstants.cardTemplateDataContributor, contributorId],
    async () => {
      const data = await request(
        endpoint,
        getCardTemplateCreatedByName(contributorId)
      );

      return data;
    }
  );
};
//Annotations

export const useGetAnnotations = (
  node_id,
  node_name,
  toCall,
  subModInvoker
) => {
  let params;
  if (node_name === ColorIndexNames.contributor) {
    params = `{
              ${MutationKeyName.contributor}: {_eq: ${node_id}}, 
              ${MutationKeyName.app}: {_is_null: true}, 
              ${MutationKeyName.model}: {_is_null: true}, 
              ${MutationKeyName.input_data_column}: {_is_null: true}, 
              ${MutationKeyName.input_data_table}: {_is_null: true}
            }`;
  } else {
    params = `{ type : { _eq: ${node_name} },type_id: {_eq: ${node_id}} }`;
  }

  return useQuery(
    [queryKeyConstants.getAnnotation, node_id, node_name],
    async () => {
      const { data } = await nodeApiInstance.get("/annotation/get-annotation", {
        params: {
          node_type: node_name,
          node_id: node_id,
          subModInvoker,
        },
      });
      return data.data;
    },
    {
      enabled: !!toCall,
    }
  );
};

// Risk Factor

export const useGetRiskFactorData = (indexName, id, callApi) => {
  const finalRiskMonth = [];
  return useQuery(
    [queryKeyConstants.riskfactors, indexName, id],
    async () => {
      const data = await nodeApiInstance.get(
        `/risk_factors/get-risk-factors-data?indexName=${indexName}&id=${id}`
      );

      return data.data.data;
    },
    {
      enabled: !!callApi,
    }
  );
};

// individual konfer score

export const useGetAppKonferScore = (appId, callApi) => {
  return useQuery(
    [queryKeyConstants.getAppKonferScore, appId],
    async () => {
      const data = await request(endpoint, getAppKonferScore(appId));

      return data;
    },
    {
      enabled: !!callApi,
    }
  );
};

export const useGetModelKonferScore = (modelId, callApi) => {
  return useQuery(
    [queryKeyConstants.getModelKonferScore, modelId],
    async () => {
      const data = await request(endpoint, getAppKonferScore(modelId));

      return data;
    },
    {
      enabled: !!callApi,
    }
  );
};

export const useGetDatasetKonferScore = (datasetId, callApi) => {
  return useQuery(
    [queryKeyConstants.getDatasetKonferScore, datasetId],
    async () => {
      const data = await request(endpoint, getAppKonferScore(datasetId));

      return data;
    },
    {
      enabled: !!callApi,
    }
  );
};

// get Business units
export const useGetBusinessUnits = (contributerId, callApi) => {
  return useQuery(
    [queryKeyConstants.businessUnits, contributerId],
    async () => {
      const data = await nodeApiInstance.get(
        "/business_unit/get-business-units-by-contributor",
        {
          params: {
            contributor_id: contributerId,
          },
        }
      );
      return data;
    },
    {
      enabled: !callApi,
    }
  );
};

// modal cards apis
export const useGetModalCardData = (cardId) => {
  return useQuery([queryKeyConstants.modelCardData, cardId], async () => {
    let data = await nodeApiInstance.post("/details/get-card-data", {
      cardId,
    });
    return data.data.data.cards[0];
  });
};

// Userlist API
export const useGetUserlist = (modInvoker, subModInvoker, callApi) => {
  return useQuery(
    [queryKeyConstants.userlist],
    async () => {
      const data = await nodeApiInstance.get(
        `/details/get-user-list?modInvoker=${modInvoker}&subModInvoker=${subModInvoker}`
      );
      return data.data.data.contributor;
    },
    {
      enabled: callApi,
    }
  );
};

//Get role, region, business units API
/** type = "all" means api gives all role, region and business unit data
 *  type = "role" means gives role data
 *  type = "region" means gives region data
 *  type = "business_unit" means gives business unit data
 */
export const useGetRoleRegionBuData = (type, subModInvoker, callApi) => {
  return useQuery(
    [queryKeyConstants.getRoleRegionBuData],
    async () => {
      const data = await nodeApiInstance.get(
        `/details/get-bu-region-role?type=${type}&subModInvoker=${subModInvoker}`
      );

      return data?.data?.data;
    },
    {
      enabled: callApi,
    }
  );
};

export const useGetRiskFactors = () => {
  return useQuery(queryKeyConstants.getRiskFactorsVisibilityData, async () => {
    const data = await request(endpoint, getRiskFactors());
    return data.risk_factors;
  });
};

// observability data
export const useGetObservabilityData = (nodeType, nodeId) => {
  return useQuery(
    [queryKeyConstants.getObservabilityData, nodeType, nodeId],
    async () => {
      const data = await nodeApiInstance.get("/graph_data/table-data", {
        params: {
          indexName: nodeType,
          id: nodeId,
        },
      });
      return data.data;
    },
    {
      retry: false,
    }
  );
};

// Get sagemaker config data
export const useAdminConfigurationGetConfig = (
  contributor_id,
  source,
  callApi
) => {
  return useQuery(
    [queryKeyConstants.getConfigData, contributor_id, source],
    async () => {
      const data = await nodeApiInstance.get(
        `details/get-extraction-credentials?contributor_id=${contributor_id}&extraction_source=${source}`
      );
      return data?.data?.data;
    },
    {
      enabled: callApi,
    }
  );
};

// get editable fields
export const useGetEntityEditableFields = () => {
  return useQuery(queryKeyConstants.getEditableFields, async () => {
    const { data } = await nodeApiInstance.get(
      "/details/get-entity-details-editable"
    );
    return data.data;
  });
};

export const useGetEditableFields = (callApi) => {
  return useQuery(
    [queryKeyConstants.getEditableFields],
    async () => {
      const data = await nodeApiInstance.get("details/get-editable-fields");
      return data?.data?.data;
    },
    {
      enabled: callApi,
    }
  );
};

// Userlist API
export const useGetAllBusinessUnits = (callApi) => {
  return useQuery(
    [queryKeyConstants.getAllBusinessUnits],
    async () => {
      const data = await nodeApiInstance.get("details/get-bu-region");
      return data.data.data.business_unit;
    },
    {
      enabled: callApi,
    }
  );
};

/* Roles list API */
export const useGetRoles = (callApi) => {
  return useQuery(
    [queryKeyConstants.getAllRoles],
    async () => {
      const data = await nodeApiInstance.get("/role/get-role");
      return data?.data?.data?.roles;
    },
    {
      enabled: callApi,
    }
  );
};

/* get Access list API */
export const useGetAccessList = (callApi) => {
  return useQuery(
    [queryKeyConstants.getAccessList],
    async () => {
      const data = await nodeApiInstance.get("/role/get-access-list");
      return data?.data?.data;
    },
    {
      enabled: callApi,
    }
  );
};

/* Get Access list by role id */
export const useGetAccessByRole = (rolesId, callApi) => {
  return useQuery(
    [queryKeyConstants.getAccessByRole, rolesId],
    async () => {
      const data = await nodeApiInstance.get("/role/get-role-access", {
        params: {
          roles_id: rolesId,
        },
      });
      return data?.data?.data?.roles[0];
    },
    {
      enabled: callApi,
    }
  );
};

// get compliance listview confidence factors
export const useGetCPOListviewCF = (callApi, buId, reId, conId, role) => {
  return useQuery(
    [queryKeyConstants.getlistviewConfidenceFactors, buId, reId],
    async () => {
      const data = await nodeApiInstance.post(
        "/details/business-unit-avg-app",
        {
          business_unit_id: buId,
          region_id: reId,
          contributer_id: conId,
          role: role,
          organization_id: 1,
          isOrg: reId === 0 ? "true" : "false",
        }
      );
      return data.data.data[0];
    },
    {
      enabled: callApi,
    }
  );
};

//Get All apps details for admin nodes businessunit relationship
export const useGetAllNodeBuRelData = (callApi) => {
  return useQuery(
    [queryKeyConstants.getAllNodeBuRelData],
    async () => {
      const data = await nodeApiInstance.get(
        "details/get-business-unit-assignment"
      );
      return data?.data?.data;
    },
    {
      enabled: callApi,
    }
  );
};

// get compliance summary confidence factors
export const useGetSummaryConfidenceFactors = (appIds, callApi) => {
  return useQuery(
    [queryKeyConstants.getSummaryConfidenceFactors, appIds],
    async () => {
      const data = await nodeApiInstance.get(
        "/details/risk-avg-app-model-dataset",
        {
          params: {
            app_id: appIds,
          },
        }
      );
      return data.data.data;
    },
    {
      enabled: callApi,
    }
  );
};

// get Business units
export const useGetBusinessUnitsNew = (
  role,
  contributerId,
  callApi,
  modInvoker
) => {
  return useQuery(
    [queryKeyConstants.businessUnitsNew, contributerId],
    async () => {
      const data = await nodeApiInstance.get(
        "/business_unit/get-all-business-units-by-contributor",
        {
          params: {
            contributor_id: contributerId,
            role: role,
            modInvoker,
          },
        }
      );
      return data;
    },
    {
      enabled: callApi,
    }
  );
};

//  get json data for python code analysis.
export const useGetJsonDataForPythonCode = () => {
  return useQuery([queryKeyConstants.getDependencyFileData], async () => {
    const data = await nodeApiInstance.get("/card/get-dependencies-data");
    return data;
  });
};

// get slack users
export const useGetSlackUsers = (subModInvoker) => {
  return useQuery(queryKeyConstants.getSlackUsers, async () => {
    const data = await nodeApiInstance.get("/details/slack-users", {
      params: {
        subModInvoker,
      },
    });
    return data.data?.data;
  });
};

export const useGetCPOAnnotations = (
  typeName,
  typeId,
  toCall,
  subModInvoker
) => {
  return useQuery(
    [queryKeyConstants.getCPOAnnotation, typeName, typeId],
    async () => {
      const { data } = await nodeApiInstance.get("/annotation/get-annotation", {
        params: {
          node_type: typeName,
          node_id: typeId,
          subModInvoker,
        },
      });
      return data.data;
    },
    {
      enabled: !!toCall,
    }
  );
};
export const useGetExtractionHistory = (callApi) => {
  return useQuery(
    [queryKeyConstants.getExtractionHistory],
    async () => {
      const { data } = await nodeApiInstance.get(
        "/details/get-extract_history",
        {
          params: {
            status: "WIP",
          },
        }
      );
      return data?.data;
    },
    {
      enabled: callApi,
      refetchInterval: 6000,
    }
  );
};

// get card Template data

// get compliance confidence factors for listview and summary
export const useGetCardTemplates = (type, callApi) => {
  return useQuery(
    [queryKeyConstants.getTemplateData, type],
    async () => {
      const data = await nodeApiInstance.get("/details/get-template-detail", {
        params: {
          type: type,
        },
      });

      return data.data.data;
    },
    {
      enabled: callApi,
    }
  );
};

// get compliance confidence factors for listview and summary
export const useGetConfidenceFactors = (type, type_id, score_type, callApi) => {
  return useQuery(
    [queryKeyConstants.getConfidenceFactors, type, type_id, score_type],
    async () => {
      const data = await nodeApiInstance.get("/risk_factors/profile-score", {
        params: {
          type: type,
          type_id: type_id,
          scoreType: score_type,
        },
      });
      return data.data.data;
    },
    {
      enabled: callApi,
    }
  );
};

// get listview table data
export const useGetListviewTableData = (payload) => {
  const {
    searchText,
    region_id,
    business_unit_id,
    contributor_id,
    user_role,
    view_name,
    callApi,
    modInvoker,
  } = payload;

  let finalSearch;
  if (searchText === KONFER_SCORE) {
    finalSearch = "*";
  } else if (searchText === "*") {
    finalSearch = "*";
  } else {
    const searchDataVal = String(searchText).split("-").join(" ");
    finalSearch = String(searchDataVal);
  }
  return useQuery(
    [
      queryKeyConstants.getListviewTableData,
      searchText,
      region_id,
      business_unit_id,
      contributor_id,
      user_role,
      modInvoker,
    ],
    async () => {
      const data = await nodeApiInstance.post(
        "/graph_data/get-list-view-data",
        {
          searchText: finalSearch,
          region_id: region_id,
          business_unit_id: business_unit_id,
          contributor_id: contributor_id,
          user_role: user_role,
          view_name: view_name,
          modInvoker: modInvoker,
        }
      );
      return data.data.resData;
    },
    {
      enabled: callApi,
    }
  );
};

// Get Policies API
export const useGetAllPolicies = (modInvoker, subModInvoker, callApi) => {
  return useQuery(
    [queryKeyConstants.getAllPolicies],
    async () => {
      const data = await nodeApiInstance.get(
        `/policy/get-policies?modInvoker=${modInvoker}&subModInvoker=${subModInvoker}`
      );
      return data;
    },
    {
      enabled: callApi,
    }
  );
};

export const useGetRiskFactorsByScoreId = (scoreId) => {
  return useQuery(
    [queryKeyConstants.getRiskFactorsByScoreId, scoreId],
    async () => {
      const data = await nodeApiInstance.get(
        "/risk_factors/get-risk-factor-visibility",
        {
          params: {
            score_id: scoreId,
          },
        }
      );
      return data?.data?.data?.confidence_factors_config;
    },
    {
      enabled: !!scoreId,
    }
  );
};

/* Below function is used to get code analysis data from the backend */
export const useGetCodeAnalysisData = (codeAnalysisData, modInvoker) => {
  return useQuery(
    [queryKeyConstants.getCodeAnalysisData, codeAnalysisData],
    async () => {
      const data = await nodeApiInstance.post("/card/get-code-analysis-data", {
        data: codeAnalysisData,
        modInvoker,
      });
      return data?.data?.resData?.data;
    },
    {
      enabled: !!codeAnalysisData,
    }
  );
};

export const useGetAllScores = (modInvoker, subModInvoker, callApi) => {
  return useQuery(
    [queryKeyConstants.getScoresList],
    async () => {
      const data = await nodeApiInstance.get(
        `/policy/get-policies?modInvoker=${modInvoker}&subModInvoker=${subModInvoker}`
      );
      const filteredRows = data?.data?.data?.score_type_config?.filter(
        (row) => {
          return row.status.toUpperCase() === "PUBLISHED";
        }
      );
      return filteredRows;
    },
    {
      enabled: callApi,
    }
  );
};

export const handleFileDownload = async (file, modInvoker) => {
  let authTokens = getLocalTokens();
  fetch(
    `${process.env.REACT_APP_REST_API}/card/file?id=${file.id}&modInvoker=${modInvoker}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authTokens?.accessToken}`,
      },
    }
  )
    .then((response) => {
      return response.blob();
    })
    .then((result) => {
      const url = window.URL.createObjectURL(new Blob([result]));
      // Create a temporary anchor tag
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", file.file_name);
      // Set the desired file name
      document.body.appendChild(link);
      link.click();
    })
    .catch((error) => {});
};

export const handleSampleFileDownload = async (
  fileName,
  source,
  modInvoker
) => {
  let authTokens = getLocalTokens();

  fetch(
    `${process.env.REACT_APP_REST_API}/card/file?file_name=${fileName}&source=${source}&modInvoker=${modInvoker}`,
    {
      method: "GET",

      headers: {
        "Content-Type": "application/json",

        Authorization: `Bearer ${authTokens?.accessToken}`,
      },
    }
  )
    .then((response) => {
      return response.blob();
    })

    .then((result) => {
      const url = window.URL.createObjectURL(new Blob([result]));

      // Create a temporary anchor tag

      const link = document.createElement("a");

      link.href = url;

      link.setAttribute("download", fileName);

      // Set the desired file name
      document.body.appendChild(link);

      link.click();
    })

    .catch((error) => {});
};

//get question id for new question
export const useGetAlphaNumricIDForNewQue = () => {
  const getAlphaNumricIDForNewQue = async (payload) => {
    const data = await nodeApiInstance.post(
      `${process.env.REACT_APP_REST_API}/card/get-alphanumeric-id`,
      payload
    );
    return data.data.data;
  };
  return useMutation(getAlphaNumricIDForNewQue, {
    onError: (error) => {
      toast.error("Unable to create new question");
    },
  });
};

export const getAccessDataByRole = async (contributerId, role) => {
  const res = await nodeApiInstance.get(`/auth/get-access-by-roles`, {
    params: {
      contributerId: contributerId,
      role: role,
    },
  });

  return res.data.data;
};

//below function is used for getting answer from open ai for single question
export const useSearchQueInOpenAIForSingle = () => {
  const getAnswerForSingleCustomQue = async (payload) => {
    // const { filePayload, cardId, cardType, cardTypeId } = payload;

    const response = await axios.post(
      `${process.env.REACT_APP_OPEN_AI_API}/doc_qa_single`,
      payload
    );

    return response.data;
  };

  return useMutation(getAnswerForSingleCustomQue, {
    onSuccess: () => {},
    onError: (err) => {},
  });
};

//below function is used for getting answer from open ai for bulk questions
export const useSearchQueInOpenAIForBulk = () => {
  const getAnswerForBulkQue = async (payload) => {
    const response = await axios.post(
      `${process.env.REACT_APP_OPEN_AI_API}/doc_qa_all`,
      payload
    );
    return response.data;
  };

  return useMutation(getAnswerForBulkQue, {
    onSuccess: () => {},
    onError: (err) => {},
  });
};

//Api to get organisation logo
export const useGetCompanyLogo = (organizationID, callApi) => {
  return useQuery(
    [queryKeyConstants.getCompanyLogo],
    async () => {
      const data = await nodeApiInstance.get(
        `details/get-org-logo?org_id=${organizationID}`
      );

      return data.data.data;
    },
    {
      enabled: callApi,
    }
  );
};

// Getextraction history data
export const useGetExtractHistoryData = (callApi) => {
  return useQuery(
    [queryKeyConstants.getExtractHistoryData],
    async () => {
      const data = await nodeApiInstance.get(
        "/details/get-all-extract-history-data"
      );
      return data.data.data;
    },
    {
      enabled: callApi,
    }
  );
};

// Downlaod Extract History Error Log File
export const handleGetExtractHistoryErrLogFile = (history_id) => {
  let authTokens = getLocalTokens();

  fetch(
    `${process.env.REACT_APP_ADMIN_CONFIGURATION}/download_error_warning_log?agent_history_id=${history_id}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authTokens?.accessToken}`,
      },
    }
  )
    .then((response) => {
      return response.blob();
    })
    .then((result) => {
      if (result?.size > 0) {
        const url = window.URL.createObjectURL(new Blob([result]));
        // Create a temporary anchor tag
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `Error_log_for_Extraction_ID_${history_id}`
        );
        // Set the desired file name
        document.body.appendChild(link);
        link.click();
      } else {
        toast.error(extractionToasts.ErrLogFileDwnldFailed);
      }
    })
    .catch((error) => {
      toast.error(error);
    });
};

/* Below function is used to get code from nlp database */
export const useGetNlpQueryResults = (searchText, callApi) => {
  return useQuery(
    [queryKeyConstants.getNlpQueryData, searchText],
    async () => {
      const tokenRes = await axios.post(
        `${process.env.REACT_APP_NLP_API}/token/`
      );

      const token = tokenRes.data.token;

      const response = await axios.post(
        `${process.env.REACT_APP_NLP_API}/search/app,model,input_data_table,annotation`,
        {
          nlq_query: searchText,
        },
        {
          headers: {
            "x-access-token": token,
          },
        }
      );

      return response?.data;
    },
    {
      refetchOnWindowFocus: false,
    },
    {
      enabled: callApi,
    }
  );
};
