import React, { useState } from "react";
import { FormControl, IconButton, MenuItem, Select } from "@mui/material";
import { Square, ExpandMore } from "@mui/icons-material";
import ConfidenceFactorsGraphview from "../ConfidenceFactorsGraphview/ConfidenceFactorsGraphview";
import ConfidenceFactorsListview from "../ConfidenceFactorsListview/ConfidenceFactorsListview";
import InfoIcon from "../../../assets/images/compliance/InfoIcon.svg";
import "./ConfidenceFactors.css";
import { riskfactorCategoryArray } from "../../../constants/complianceConstants";
import { entityNameConst } from "../../../constants/dataTypes";
import { PolicyNameConst } from "../../../constants/policyConstants";

const ConfidenceFactors = ({
  isSummary,
  dataCount,
  riskFactorsSummaryData,
  listviewConFactorsData,
  riskFactorCategoryName,
  setRiskFactorCategoryName,
  policyData,
}) => {
  return (
    <div className="confidence-factors">
      <div className="confidence-factors-heading">
        <h3>Scores by</h3>

        <div>
          <Select
            sx={{
              "& .MuiSelect-select": {
                padding: "1px 0px 1px 5px",
                fontSize: "16px",
                fontWeight: "500",
                background: "#ffffff",
                marginLeft: "10px",
                width: "150px",
                borderRadius: "4px",
                "@media screen and (max-height: 780px)": {
                  fontSize: "14px",
                },
              },
              "& .MuiSelect-root": {
                maxHeight: "150px",
              },
            }}
            variant="standard"
            id="complianceScoreTypeSelect"
            value={riskFactorCategoryName}
            onChange={(e) => {
              setRiskFactorCategoryName(e.target.value);
            }}
            disableUnderline={true}
            IconComponent={ExpandMore}
            MenuProps={{
              style: { maxHeight: "30%" },
              PaperProps: {
                sx: {
                  border: "0.5px solid #6D8291",
                  boxShadow: "0px 5px 10px rgba(0, 0, 0, 0.25)",
                  borderRadius: "4px",
                  paddingTop: "1px",

                  "& .MuiMenuItem-root": {
                    // padding: 1,
                    fontFamily: "var( --fontFamilyText) !important",
                    lineHeight: "1rem",
                    "@media screen and (max-height: 780px)": {
                      fontSize: "14px",
                    },
                  },
                },
              },
            }}
          >
            {policyData?.map((policy, index) => {
              const val = ["Konfer Confidence", "Human Impact"];
              if (!val.includes(policy.score_name)) {
                return;
              } else {
                return (
                  policy.score_name !== "Human Impact" && (
                    <MenuItem
                      key={index}
                      value={policy.score_name}
                      id="selectMenuItem"
                      sx={{
                        "&.Mui-selected": {
                          backgroundColor: "#F6F6F6",
                          "&.Mui-focusVisible": { background: "#F6F6F6" },
                        },
                      }}
                    >
                      {/* for demo */}
                      {/* {policy.score_name} */}
                      {policy.score_name === "Konfer Confidence"
                        ? PolicyNameConst.konfer_confidence_wo_tm
                        : policy.score_name}
                    </MenuItem>
                  )
                );
              }
            })}
            <MenuItem
              id="selectMenuItem"
              sx={{
                "&.Mui-selected": {
                  backgroundColor: "#F6F6F6",
                  "&.Mui-focusVisible": { background: "#F6F6F6" },
                },
              }}
            >
              <div style={{ display: "flex" }}>
                <p
                  style={{
                    color: "#bec9d1",
                    margin: 0,
                    padding: "1px 0px 1px 0px",
                    marginRight: "10px",
                  }}
                >
                  {PolicyNameConst.policies.toUpperCase()}
                </p>
                {/* <ArrowDownwardIcon style={{ color: '#bec9d1' }}/> */}
              </div>
            </MenuItem>

            {policyData?.map((policy, index) => {
              const val = ["Konfer Confidence", "Human Impact"];
              if (val.includes(policy.score_name)) {
                return;
              } else {
                return (
                  <MenuItem
                    key={index}
                    value={policy.score_name}
                    id="selectMenuItem"
                    sx={{
                      "&.Mui-selected": {
                        backgroundColor: "#F6F6F6",
                        "&.Mui-focusVisible": { background: "#F6F6F6" },
                      },
                    }}
                  >
                    {policy.score_name}
                  </MenuItem>
                );
              }
            })}
          </Select>
        </div>

        {/* <IconButton
          type="submit"
          aria-label="Info"
          sx={{ padding: "0px", margin: "-8px 0px 0px 15px" }}
          disabled
        >
          <img src={InfoIcon} alt="Confidence Factors" width="20" />
        </IconButton> */}

        {isSummary && (
          <div className="info-bar">
            <div className="score_ball mr20px">
              <Square className="appColor" />
              <p className="nodeName">{entityNameConst.applications}</p>
            </div>
            <div className="score_ball mr20px">
              <Square className="modelColor" />
              <p className="nodeName">
                {entityNameConst.models}({dataCount.model})
              </p>
            </div>
            {/* <div className="score_ball mr20px">
              <Square className="datasetColor" />
              <p className="nodeName">Data Sources({dataCount.dataset})</p>
            </div> */}
          </div>
        )}
      </div>
      {!isSummary ? (
        <div className="confidence-factors-info-block-list">
          <ConfidenceFactorsListview
            listviewConFactorsData={listviewConFactorsData}
          />
        </div>
      ) : (
        <div className="confidence-factors-info-block">
          <ConfidenceFactorsGraphview
            riskFactorsSummaryData={riskFactorsSummaryData}
          />
        </div>
      )}
    </div>
  );
};

export default ConfidenceFactors;
