import React, { useEffect, useRef, useState } from "react";
import CardTemplateDescription from "../CardTemplateDescription/CardTemplateDescriptionNew";
import CardTemplateQuestionData from "../CardTemplateQuestionData/CardTemplateQuestionData";
import { useGetAlphaNumricIDForNewQue } from "../../api/readApiFunctions";
import "./CardTemplateSectionData.css";
import { Tooltip } from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { cardTemplateSectionDataTooltips } from "../../constants/tooltipConstants";
import { isAccessProvided } from "../../services/assetManagementService";
import {
  moduleConstants,
  subModuleConstants,
} from "../../constants/accessManagementConstants";

const CardTemplateSectionData = ({
  sectionData,
  isEditActive,
  policyData,
  handleTemplateEdit,
  editedState,
  selectedEntity,
  filteredPolicies,
  newQueAdded,
  handleNewQueAdd,
  handleFilterPolicyOnEdit,
  accessDataByRole,
}) => {
  const ref = useRef(null);

  const [filteredQuestions, setFilteredQuestions] = useState([]);

  const {
    mutate: getAlphaNumricIDForNewQue,
    data: alphaNumrivIDForNewQue,
    isSuccess,
  } = useGetAlphaNumricIDForNewQue();

  const handleQuestionProps = () => {
    if (editedState.length > 0) {
      return editedState[0];
    } else {
      return sectionData;
    }
  };

  const scrollToLastFruit = () => {
    const lastChildElement = ref.current?.lastElementChild;
    lastChildElement?.scrollIntoView(false, {
      behavior: "smooth",
      block: "start",
    });
  };

  useEffect(() => {
    if (newQueAdded === true) {
      scrollToLastFruit();
      handleNewQueAdd(false);
    }
  }, [newQueAdded]);

  // check the questions with filtered policies
  useEffect(() => {
    if (filteredPolicies.length > 0) {
      // loop through the template data
      const questionsWithPolicy = [];
      const filteredQuestionIds = [];
      sectionData?.questions?.map((question) => {
        let policies = [];
        if (
          question.policy !== null &&
          question.policy !== "NULL" &&
          question.policy !== ""
        ) {
          policies = question.policy.split(",");
        }
        policies.map((policyName) => {
          if (
            filteredPolicies.includes(policyName) &&
            !filteredQuestionIds.includes(question.template_question_id)
          ) {
            questionsWithPolicy.push(question);
            filteredQuestionIds.push(question.template_question_id);
          }
        });
      });
      setFilteredQuestions(questionsWithPolicy);
    }
  }, [filteredPolicies, sectionData]);

  useEffect(() => {
    if (isEditActive) {
      setFilteredQuestions([]);
      handleFilterPolicyOnEdit([]);
    }
  }, [isEditActive]);

  useEffect(() => {
    if (isSuccess) {
      handleTemplateEdit(
        sectionData.section_id,
        "createQuestion",
        "create",
        alphaNumrivIDForNewQue.id,
        null
      );
    }
  }, [alphaNumrivIDForNewQue]);

  return (
    <div
      className={
        isEditActive ? "section_data_container_edit" : "section_data_container"
      }
      ref={ref}
    >
      <CardTemplateDescription
        descriptionData={sectionData}
        isEditActive={isEditActive}
        type="sectionDescription"
        handleTemplateEdit={handleTemplateEdit}
        editedDescription={editedState[0]}
        sectionId={sectionData?.section_id}
      />

      {sectionData?.subsections?.map((subsection, index) => {
        return (
          <CardTemplateDescription
            key={subsection.subsection_id}
            descriptionData={subsection}
            isEditActive={isEditActive}
            type="subsectionDescription"
            handleTemplateEdit={handleTemplateEdit}
            editedDescription={editedState[0]?.subsections[index]}
            sectionId={sectionData?.section_id}
          />
        );
      })}

      <div
        style={{
          height: "27px",
          alignItems: "center",
          paddingTop: "5px",
          marginBottom: "10px",
          marginTop: "40px",
          display: "flex",
          justifyContent: "space-between",
          paddingRight: "22px",
        }}
      >
        <strong
          style={{
            marginLeft: "10px",
            alignItems: "center",
            textAlign: "center",
            fontSize: "17px",
          }}
        >
          Questionnaire
        </strong>
      </div>

      <div className="add_question">
        {isAccessProvided(
          moduleConstants.card_templates,
          subModuleConstants.crud,
          null,
          "write",
          accessDataByRole
        ) === true &&
          isEditActive && (
            <Tooltip
              placement="top"
              title={cardTemplateSectionDataTooltips.addNewQuestion}
              arrow
            >
              <div
                style={{
                  color: "#6d8291",
                }}
                onClick={() => {
                  const payload = {
                    entity_type: selectedEntity.type,
                    section_name: sectionData.title,
                  };

                  getAlphaNumricIDForNewQue(payload);
                }}
              >
                <AddCircleIcon fontSize="large" />
              </div>
            </Tooltip>
          )}
      </div>

      <div>
        {filteredPolicies.length === 0 &&
          handleQuestionProps()?.questions?.map((questionData, index) => {
            return (
              <CardTemplateQuestionData
                sectionId={sectionData.section_id}
                index={index}
                questionData={questionData}
                isEditActive={isEditActive}
                policyData={policyData}
                handleTemplateEdit={handleTemplateEdit}
                editedQuestion={editedState[0]?.questions[index]}
                key={questionData.template_question_id}
                selectedEntity={selectedEntity}
                accessDataByRole={accessDataByRole}
              />
            );
          })}

        {filteredPolicies.length > 0 &&
          filteredQuestions.map((questionData, index) => {
            return (
              <CardTemplateQuestionData
                sectionId={sectionData?.section_id}
                index={index}
                questionData={questionData}
                isEditActive={isEditActive}
                policyData={policyData}
                handleTemplateEdit={handleTemplateEdit}
                editedQuestion={editedState[0]?.questions[index]}
                key={questionData.template_question_id}
                selectedEntity={selectedEntity}
                accessDataByRole={accessDataByRole}
              />
            );
          })}
      </div>
    </div>
  );
};

export default CardTemplateSectionData;
