import React, { useState, useEffect } from "react";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { Close } from "@mui/icons-material";
import "./Cards.css";
import { useGetAllPolicies } from "../../api/readApiFunctions";
import { PolicyNameConst } from "../../constants/policyConstants";

/**
 * Component : FilterIcon
 *
 * Description : This component is responsible for showing the icon of filtering policies.
 * While clicking , it opens the policies accordion. Selected policies save on parent component.
 *
 * Props : isEditDatasetSection - state shows whether card is editable mode or not.
                                  In editable mode, policies are not able to filetred,
           cardFilterWithPolicy - state for showing the filtered policies,
           cardFilterFunction   - function for saving the selected policies, it's a call back function to parent, 
 *  */

const AccordionSummaryDrop = styled((props) => (
  <AccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
}));

const FilterIcon = ({
  isEditDatasetSection,
  cardFilterWithPolicy,
  cardFilterFunction,
}) => {
  const [isShowFilterDropDown, setIsShowFilterDropDown] = useState(false);
  const [policy_data, setPolicyData] = useState([]); //state to maintain policies list coming from database

  const {
    data: allPolicy,
    error: allPolicyError,
    isLoading: allPolicyLoading,
  } = useGetAllPolicies("card_modal", "crud", true);

  /**  Below useEffects is used to set policy data coming from database*/
  useEffect(() => {
    if (allPolicy) {
      const filteredRows = allPolicy?.data?.data?.score_type_config.filter(
        (row) => {
          return (
            row.score_name !== "Human Impact" &&
            row.score_name !== "Konfer Confidence" &&
            row.status.toUpperCase() === "PUBLISHED"
          );
        }
      );
      setPolicyData(filteredRows);
    }
  }, [allPolicy]);

  return (
    <>
      <FilterAltOutlinedIcon
        onClick={() => {
          !isEditDatasetSection &&
            setIsShowFilterDropDown(!isShowFilterDropDown);
        }}
        className="icon_background "
        style={{
          height: "20px",
          width: "20px",
          marginTop: "8px",
          marginLeft: "-10px",
          background: cardFilterWithPolicy.length > 0 ? "#BEC9D1" : "",
          color: isEditDatasetSection ? "#bcc1c2" : "#000000",
        }}
      />

      {!isEditDatasetSection && isShowFilterDropDown && (
        <Accordion
          sx={{
            position: "absolute",
            top: cardFilterWithPolicy.length > 0 ? 80 : 70,
            zIndex: 1,
            right: 150,
          }}
          defaultExpanded={true}
          className="filterBoxCard"
        >
          <AccordionSummaryDrop
            // expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            sx={{
              background: "#F6F6F6",
            }}
          >
            <Typography sx={{ marginLeft: "16px" }}>
              {PolicyNameConst.policy}
            </Typography>
            <Close
              sx={{
                marginLeft: "245px",
                width: "18px",
                marginTop: "-10px",
              }}
              onClick={() => {
                setIsShowFilterDropDown(false);
              }}
            />
          </AccordionSummaryDrop>

          <AccordionDetails>
            <Typography sx={{ marginTop: "-20px", marginLeft: "-10px" }}>
              {policy_data.map((policyName) => {
                return (
                  <div className="policyFilterName">
                    <Checkbox
                      checked={
                        cardFilterWithPolicy?.includes(policyName.score_name)
                          ? true
                          : false
                      }
                      onClick={() => {
                        cardFilterFunction(policyName.score_name);
                      }}
                      color="default"
                    />
                    {policyName.score_name}
                  </div>
                );
              })}
            </Typography>
          </AccordionDetails>
        </Accordion>
      )}
    </>
  );
};

export default FilterIcon;
