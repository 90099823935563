import React from "react";
import { useAtom } from "jotai";
import "./ControlSelector.css";
import UncheckedIcon from "../../assets/images/graphView/Unchecked.svg";
import CheckedIcon from "../../assets/images/graphView/Checked.svg";
import ZoomIn from "../../assets/images/graphView/zoomIn.svg";
import ZoomInDisabled from "../../assets/images/graphView/ZoomInDisabled.svg";
import ZoomOut from "../../assets/images/graphView/zoomOut.svg";
import ZoomOutDisabled from "../../assets/images/graphView/ZoomOutDisabled.svg";
import fontIcon from "../../assets/images/detail/A.svg";
import fontIncreaseIcon from "../../assets/images/detail/AZ.svg";
import FontIncreaseDisabled from "../../assets/images/detail/FontIncreaseDisabled.svg";
import FontDecreaseDisabled from "../../assets/images/detail/FontDecreaseDisabled.svg";
import SearchIcon from "../../assets/images/detail/Search.svg";

// mui imports
import {
  KeyboardArrowDownOutlined,
  KeyboardArrowLeftOutlined,
  KeyboardArrowRightOutlined,
  KeyboardArrowUpOutlined,
} from "@mui/icons-material";
import { Checkbox, FormControlLabel, IconButton } from "@mui/material";
import InternalSearch from "../InternalSearch/InternalSearch";
import { useLocation } from "react-router-dom";
import { isGraphviewChild, isSidebar } from "../../atom/jotai";
import { useState } from "react";
import { findElementInGraphviewAtom } from "../../atom/jotai";
import { PolicyNameConst } from "../../constants/policyConstants";

const ControlSelector = ({
  isVisible,
  checkedKonferScore,
  setCheckedKonferScore,
  checkedShowLabel,
  setCheckedShowLabel,
  nodeLabelFont,
  setNodeLabelFont,
  nodeDetailsExpandView,
  setNodeDeatilExpandView,
  zoomInAndOut,
  setZoomInAndOut,
  limitForZoomIn,
  chartHeight,
  setChartHeight,
  orphanNodes,
  setOrphanNodes,
  graphViewNodes,
  findElementInNodeDetails,
  setFindElementInNodeDetails,
  totalCountForNodeDetails,
  setTotalCountForNodeDetails,
  activeCountForNodeDetails,
  setActiveCountForNodeDetails,
  isSummary,
}) => {
  // temporary fix
  const openSidebar = false;
  const location = useLocation();
  const [sidebar] = useAtom(isSidebar);
  const [isInputField, setInputField] = useState(false);
  const currentLocation = location?.pathname.split("/")[1];

  const [findElementInGraphview] = useAtom(findElementInGraphviewAtom);
  const [graphviewChild] = useAtom(isGraphviewChild);

  return (
    <div
      id="controls"
      className={
        graphViewNodes ? "GraphDetailsBarCssOrphanNode" : "GraphDetailsBarCss"
      }
      style={isSummary && { marginTop: "-3px" }}
    >
      <div
        id="controlSelector"
        className={
          openSidebar
            ? "GraphDetailsBarCheckBoxOpenSidebar"
            : "GraphDetailsBarCheckBox"
        }
      >
        <div className="CheckBoxContainer" id="CheckboxContainer">
          <div id="konferConfidenceCheckboxDiv" className="CheckBoxLabel">
            <Checkbox
              sx={{
                color: "#6D8291",
                padding: "0px",
                ":hover": { background: "none" },
              }}
              checked={checkedKonferScore}
              color="default"
              onChange={(e) => {
                setCheckedKonferScore(!checkedKonferScore);
              }}
            />
            <span style={{ marginLeft: "5px" }}>
              {PolicyNameConst.konfer_confidence_wo_tm}
            </span>
          </div>
          <div
            id="showLabelCheckboxDiv"
            className="CheckBoxLabel"
            style={{
              marginLeft: "10px",
            }}
          >
            <Checkbox
              sx={{
                color: "#6D8291",
                padding: "0px",
                ":hover": { background: "none" },
              }}
              checked={checkedShowLabel}
              color="default"
              onChange={(e) => {
                if (isVisible === "Node Detail") {
                  if (findElementInNodeDetails === "") {
                    setCheckedShowLabel(!checkedShowLabel);
                  }
                } else {
                  if (findElementInGraphview === "") {
                    setCheckedShowLabel(!checkedShowLabel);
                  }
                }
              }}
            />
            <span style={{ marginLeft: "5px" }}>Labels</span>
          </div>
          {graphViewNodes && (
            <div
              id="unusedAssetsCheckboxDiv"
              className="CheckBoxLabel"
              disabled={findElementInGraphview !== ""}
            >
              <Checkbox
                sx={{
                  color: "#6D8291",
                  padding: "0px",
                  ":hover": { background: "none" },
                }}
                checked={orphanNodes}
                disabled={findElementInGraphview !== ""}
                color="default"
                onChange={(e) => {
                  setCheckedShowLabel(true);
                  setCheckedKonferScore(false);
                  if (findElementInGraphview === "") {
                    setOrphanNodes(!orphanNodes);
                  }
                }}
              />
              <span
                style={{
                  marginLeft: "5px",
                }}
              >
                Show Unused Assets
              </span>
            </div>
          )}
        </div>
        <div style={{ marginLeft: "10px" }}>
          <IconButton
            id="fontDecreaseIconButton"
            className="fontDecreaseButton"
            onClick={() => {
              checkedShowLabel &&
                nodeLabelFont >= 14 &&
                setNodeLabelFont(nodeLabelFont - 2);
            }}
            disabled={checkedShowLabel && nodeLabelFont < 14}
          >
            <img
              src={
                !checkedShowLabel || nodeLabelFont < 14
                  ? FontDecreaseDisabled
                  : fontIcon
              }
              style={{ width: "13px" }}
              alt="fontDecrease"
            />
          </IconButton>

          <IconButton
            id="fontIncreaseIconButton"
            sx={{ padding: "6px" }}
            className={
              openSidebar
                ? "fontIncreaseButtonForOpenSidebar"
                : "fontIncreaseButton"
            }
            onClick={() => {
              checkedShowLabel &&
                nodeLabelFont <= 18 &&
                setNodeLabelFont(nodeLabelFont + 2);
            }}
            disabled={checkedShowLabel && nodeLabelFont > 18}
          >
            <img
              src={
                !checkedShowLabel || nodeLabelFont > 18
                  ? FontIncreaseDisabled
                  : fontIncreaseIcon
              }
              style={{ width: "17px" }}
              alt="fontIncrease"
            />
          </IconButton>
        </div>

        <div style={{ marginLeft: "10px" }}>
          <IconButton
            id="zoomInIconButton"
            className="fontZoomOutButton"
            aria-label="delete"
            onClick={() => {
              zoomInAndOut < limitForZoomIn && setChartHeight(chartHeight + 1);
            }}
          >
            <img
              onClick={() =>
                zoomInAndOut < limitForZoomIn &&
                setZoomInAndOut(zoomInAndOut + 0.1)
              }
              src={zoomInAndOut < limitForZoomIn ? ZoomIn : ZoomInDisabled}
              style={{ width: "35px", marginTop: "4px" }}
              alt="Zoom In"
            />
          </IconButton>
          <IconButton
            id="zoomOutIconButton"
            className="fontZoomInButton"
            aria-label="delete"
            onClick={() => {
              zoomInAndOut > 0.7 && setChartHeight(chartHeight - 1);
            }}
          >
            <img
              onClick={() =>
                zoomInAndOut > 0.7 && setZoomInAndOut(zoomInAndOut - 0.1)
              }
              src={zoomInAndOut > 0.7 ? ZoomOut : ZoomOutDisabled}
              style={{ width: "35px", marginTop: "4px" }}
              alt="Zoom Out"
            />
          </IconButton>
        </div>
      </div>
      {isVisible && (
        <div
          id="searchInputOnControlSelector"
          style={{
            marginRight: "10px",
            marginTop: !sidebar || window.innerWidth > 1450 ? "-23px" : "-60px",
            position: "relative",
            marginBottom: "-23px",
          }}
        >
          {
            <div style={{ display: "flex" }}>
              {isInputField && (
                <div className="isInputField">
                  <InternalSearch
                    isInputField={isInputField}
                    findElementInNodeDetails={findElementInNodeDetails}
                    setFindElementInNodeDetails={setFindElementInNodeDetails}
                    totalCountForNodeDetails={totalCountForNodeDetails}
                    setTotalCountForNodeDetails={setTotalCountForNodeDetails}
                    activeCountForNodeDetails={activeCountForNodeDetails}
                    setActiveCountForNodeDetails={setActiveCountForNodeDetails}
                  />
                </div>
              )}

              <IconButton
                id="searchIconOnControlSelector"
                className="detailPageSearch"
                aria-label="delete"
                onClick={() => setInputField(!isInputField)}
              >
                <img
                  src={SearchIcon}
                  style={{
                    width: "19px",
                    marginBottom: "33px",
                    padding: "5px",
                    borderRadius: "6px",
                    border: "0.25px solid #58595b",
                    background: "#fff",
                    boxShadow: "0px 0px 4px 0px rgba(0, 0, 0, 0.25)",
                  }}
                  alt="search icon"
                />
              </IconButton>
            </div>
            // )
          }

          {isVisible && (
            <div
              className={"accordionBetweenDetailsAndGraphnodes"}
              id="accordionButtonDiv"
            >
              <IconButton
                id="accordionButton"
                fontSize="small"
                sx={{
                  background: "white",
                  padding: "0px",
                  marginTop: sidebar && window.innerWidth < 1450 && "31px",
                }}
                onClick={() => {
                  setNodeDeatilExpandView(!nodeDetailsExpandView);
                }}
              >
                {!nodeDetailsExpandView ? (
                  <KeyboardArrowRightOutlined />
                ) : (
                  <KeyboardArrowLeftOutlined />
                )}
              </IconButton>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default ControlSelector;
