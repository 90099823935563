import React, { useState, useEffect } from "react";
import {
  IconButton,
  Dialog,
  DialogTitle,
  Typography,
  DialogContent,
  MenuItem,
  TextField,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { toast } from "react-toastify";
import CancelIcon from "../../../assets/images/adminPanel/CancelIcon.svg";
import "./CreateNewRegion.css";
import { useMutation, useQueryClient } from "react-query";
import { analyticServices } from "../../../services/analyticServices";

const CreateNewRegion = ({
  openNewBusinessunitDialog,
  setOpenNewBusinessunitDialog,
  setCreateRegion,
}) => {
  // form validation rules
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required").trim(),
    description: Yup.string().optional().trim(),
  });
  const formOptions = { resolver: yupResolver(validationSchema) };

  //User Form
  const {
    register,
    handleSubmit,
    reset,
    setError,
    getValues,
    formState: { errors },
  } = useForm(formOptions);

  //Create New region
  const handleRegistration = (data) => {
    if (data.description.length > 500) {
      setError("description", {
        type: "custom",
        message: `Length should not exceed 500 characters`,
      });
    } else {
      setCreateRegion(data);
      setOpenNewBusinessunitDialog(!openNewBusinessunitDialog);
      toast.success("Region created");
      analyticServices(` ${getValues("name")}, region created`);
    }
  };

  const handleReset = () => {
    setOpenNewBusinessunitDialog(!openNewBusinessunitDialog);
    // reset({});
  };
  return (
    <>
      <Dialog
        id="createBusinessunitDialog"
        open={openNewBusinessunitDialog}
        onClose={() => {
          setOpenNewBusinessunitDialog(!openNewBusinessunitDialog);
        }}
        aria-labelledby="new-user-dialog"
        aria-describedby="new-user-dialog"
        sx={{
          "& .MuiDialog-paper": {
            boxShadow: "0px 6px 10px rgba(0, 0, 0, 0.3)",
            borderRadius: "8px",
            width: "500.4px",
            height: "320.96px",
          },
        }}
      >
        <DialogTitle id="new-user-dialog" sx={{ m: 0, p: 3 }}>
          <Typography variant="h5" component="div" className="create-heading">
            Create a New Region
          </Typography>
          <IconButton
            id="closeBusinessunitDialog"
            aria-label="close"
            onClick={() => {
              setOpenNewBusinessunitDialog(false);
            }}
            sx={{
              position: "absolute",
              right: 16,
              top: 27,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <img src={CancelIcon} alt="Delete" />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ position: "relative", p: 3 }}>
          <form
            id="createBusinessunitform"
            onSubmit={handleSubmit(handleRegistration)}
            autoComplete="off"
            style={{ marginTop: "10px" }}
          >
            <div className="info-row" id="createBusineeUnitName">
              <label className="info-label"> Name*</label>
              <TextField
                className="info-input"
                sx={{ "& .MuiInputBase-input": { padding: "0px" } }}
                id="businessunit_name"
                // label="Name"
                variant="standard"
                {...register("name")}
              />
            </div>
            {errors?.name && errors.name.message && (
              <div className="info-row-erros" id="createBusineeUnitNameEr">
                <div></div>
                <small className="text-danger">
                  {errors?.name && errors.name.message}
                </small>
              </div>
            )}

            <div className="info-row" id="createBusineeUnitDesc">
              <label className="user-info-label">Description</label>
              <TextField
                className="info-input"
                sx={{ "& .MuiInputBase-root": { padding: "0px" } }}
                id="businessunit_description"
                maxRows={4}
                variant="standard"
                multiline
                {...register("description")}
              />
            </div>
            {errors?.description && errors.description.message && (
              <div className="info-row-erros" id="createBusineeUnitDescErr">
                <div></div>
                <small className="text-danger">
                  {errors?.description && errors.description.message}
                </small>
              </div>
            )}
            <button
              type="button"
              style={{
                marginBottom: "-18px",
                cursor: "pointer",
              }}
              className="cancel-button"
              id="cancelBusineeunitButton"
              onClick={handleReset}
              value="Reset"
            >
              <span>Cancel</span>
            </button>

            <button
              type="submit"
              style={{
                marginBottom: "-18px",
                cursor: "pointer",
              }}
              className="create-button"
              id="createBusineeunitButton"
            >
              <span>Create</span>
            </button>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default CreateNewRegion;
