import React, { useEffect, useState } from "react";
import { useAtom } from "jotai";
import {
  CloseOutlined,
  KeyboardArrowDownOutlined,
  KeyboardArrowUpOutlined,
} from "@mui/icons-material";
import { IconButton } from "@mui/material";
import "./GraphviewInput.css";
import {
  findElementInGraphviewAtom,
  totalCountForGraphViewAtom,
  activeCountForGraphViewAtom,
  matchElementUIDInGraphviewAtom,
} from "../../atom/jotai";

const GraphviewInput = (x) => {
  const [findElementInGraphview, setFindElementInGraphview] = useAtom(
    findElementInGraphviewAtom
  );
  const [totalCountForGraphView, setTotalCountForGraphView] = useAtom(
    totalCountForGraphViewAtom
  );
  const [activeCountForGraphView, setActiveCountForGraphView] = useAtom(
    activeCountForGraphViewAtom
  );
  const [matchElementUIDInGraphview, setMatchElementUIDInGraphview] = useAtom(
    matchElementUIDInGraphviewAtom
  );

  const handleDown = () => {
    setActiveCountForGraphView(activeCountForGraphView + 1);
    // if (activeCountForGraphView > 4) {
    //   document.getElementById("query_search_data_container").scrollTo({
    //     top: 100 * activeCountForGraphView,
    //     behavior: "smooth",
    //   });
    // }
  };

  const handleUp = () => {
    setActiveCountForGraphView(activeCountForGraphView - 1);

    // if (activeCountForGraphView <= 4) {
    //   document.getElementById("query_search_data_container").scrollTo({
    //     top: -500,
    //     behavior: "smooth",
    //   });
    // }
  };

  return (
    <div className="graphview_input" id="graphviewSearch">
      <input
        className=""
        placeholder="Find"
        type="text"
        //value={findElementInGraphview}
        id="graphview_input"
        defaultValue={findElementInGraphview}
        onChange={(e) => {
          if (e.target.value === "") {
            setFindElementInGraphview(e.target.value);
          }
        }}
        onKeyPress={(ev) => {
          if (ev.key === "Enter") {
            setFindElementInGraphview(ev.target.value);
          }
        }}
        // onBlur={(e) =>{
        //     setFindElementInGraphview(e.target.value)
        // }}
      />
      <span style={{ marginLeft: "-10px" }} id="graphviewSearchCountSpan">
        {findElementInGraphview === ""
          ? "0/0"
          : `${activeCountForGraphView}/${totalCountForGraphView}`}
      </span>
      <span style={{ marginLeft: "10px" }}>|</span>
      <IconButton
        id="graphviewSearchDownArrowIcon"
        aria-label="downArrow"
        style={{ padding: "0px", marginTop: "3px" }}
        title="Down"
        onClick={handleDown}
        disabled={
          activeCountForGraphView >= totalCountForGraphView ||
          findElementInGraphview === ""
        }
      >
        <KeyboardArrowDownOutlined />
      </IconButton>
      <IconButton
        id="graphviewSearchUpArrowIcon"
        aria-label="upArrow"
        style={{ padding: "0px", marginTop: "3px" }}
        title="Up"
        onClick={handleUp}
        disabled={
          activeCountForGraphView === 0 || findElementInGraphview === ""
        }
      >
        <KeyboardArrowUpOutlined />
      </IconButton>
      <IconButton
        id="graphviewSearchCancelIcon"
        type="submit"
        style={{ padding: "0px", marginTop: "3px" }}
        disabled={findElementInGraphview === ""}
        onClick={(e) => {
          setFindElementInGraphview(() => "");
          document.getElementById("graphview_input").value = "";
          setActiveCountForGraphView(0);
        }}
      >
        {" "}
        <CloseOutlined fontSize="small" />
      </IconButton>
    </div>
  );
};

export default GraphviewInput;
