export const nodeSizes = {
  rootNodeSize: 500,
  firstChildSize: 500,
  secondChildSize: 100,
  thirdChildSize: 50,
};

export const NodeFillColors = {
  paleCyan: "#7ad6ff",
  blueCrayola: "#1d6bf0",
  blueBolt: "#00b0ff",
  aliceBlue: "#ebf9ff",
  littleBoyBlue: "#6794dc",
  brilliantAzure: "#4093ff",
  whiteColor: "#ffffff",
};

export const ScoreValue = {
  lowest_limit_red_score: 49,
  lowest_limit_yellow_score: 70,
};

export const ScoreValueNew = {
  highest_limit_red_score: 49,
  lowest_limit_red_score_new: 0,
};

export const ScoreColors = {
  redColor: "#C12424",
  yellowColor: "#FFCA3A",
  greenColor: "#74B1BC",
  greenC: "#049547",
  blackC: "#000000",
};

export const ScoreTypes = {
  konfer_composite_score: "konfer_composite_score",
  ethics_score: "ethics_score",
  bias_score: "bias_score",
};

// riskFactor column color

export const riskColumnColors = {
  scoreBelowLimit: "#c12424",
  scoreAboveLimit: "#049547",
};

//the mchartSeriesConfig use formating the graph
export const mchartSeriesConfig = {
  singleBranchOnly: false,
  // downDepth: 1,
  initialDepth: 1,
  // topDepth: 0,
  //node id use for size
  valueField: "val",

  // text value or node label
  categoryField: "names",
  name: "text",
  // node children
  childDataField: "children",
  manyBodyStrength: -70,
  // minRadius: 37,
  // maxRadius: 47,
  minRadius: 35,
  maxRadius: 47,
  // maxLevels: 1,
  xField: "x",
  yField: "y",
  // background:""
  // velocityDecay: ,
  nodePadding: 50,
};

//the mchartSeriesConfig use formating the graphView Single node
export const mchartSeriesConfigGraphView = {
  singleBranchOnly: false,
  // downDepth: 1,
  initialDepth: 1,
  // topDepth: 0,
  //node id use for size
  valueField: "val",

  // text value or node label
  categoryField: "names",
  name: "text",
  // node children
  childDataField: "children",
  manyBodyStrength: -70,
  // minRadius: 37,
  // maxRadius: 47,
  minRadius: 25,
  maxRadius: 25,
  // maxLevels: 1,
  xField: "x",
  yField: "y",
  // background:""
  // velocityDecay: ,
  nodePadding: 50,
};

export const scoreColorsNew = {
  redColr: " #F25757",
  greenColr: "#049547",
};
