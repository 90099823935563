import { NodeFillColors, ScoreColors, ScoreValue } from "../constants/amcharts";
import appSvg from "../assets/images/graphView/figma-gear.svg";
import dataSetSvg from "../assets/images/graphView/figma-data-set.svg";
import modelSvg from "../assets/images/graphView/figma-model-icon.svg";
import { typeNames } from "../constants/dataTypes";
import { truncateName } from "./listviewServices";
export const getAppIdsArray = (appsDetails, modelsDetails, datasetsDetails) => {
  const totalAppIds = [];

  const appData = appsDetails;
  const modelData = modelsDetails;
  const datasetData = datasetsDetails;

  if (appData.length > 0) {
    for (let i = 0; i < appData.length; i++) {
      const element = appData[i];
      totalAppIds.push(element.app_id);
    }
  }

  for (let i = 0; i < modelData.length; i++) {
    const element = modelData[i];
    const appsInModel = element.apps_models;

    for (let i = 0; i < appsInModel.length; i++) {
      const element = appsInModel[i].app;
      totalAppIds.push(element.app_id);
    }
  }

  for (let i = 0; i < datasetData.length; i++) {
    const element = datasetData[i];

    const modelsInDataset = element.models_input_data_tables;

    for (let i = 0; i < modelsInDataset.length; i++) {
      const element = modelsInDataset[i].model;

      const appsInModel = element.apps_models;

      for (let i = 0; i < appsInModel.length; i++) {
        const element = appsInModel[i].app;
        totalAppIds.push(element.app_id);
      }
    }
  }

  const uniqueAppIds = [...new Set(totalAppIds)];

  return uniqueAppIds;
};

export const formatSearchData = (searchData) => {
  const data = searchData.data;
  const searchFormatted = {
    apps: [],
    models: [],
    datasets: [],
  };

  for (let i = 0; i < data.length; i++) {
    const element = data[i];

    if (element._index === "postgres.public.model") {
      const dataSource = element._source;
      const name = `${dataSource.name}-${dataSource.model_id}`;
      searchFormatted.models.push(name);
    }

    if (element._index === "postgres.public.app") {
      const dataSource = element._source;
      const name = `${dataSource.name}-${dataSource.app_id}`;
      searchFormatted.apps.push(name);
    }

    if (element._index === "postgres.public.input_data_table") {
      const dataSource = element._source;
      const name = `${dataSource.name}-${dataSource.input_data_table_id}`;
      searchFormatted.datasets.push(name);
    }
  }

  return searchFormatted;
};

export const convertDataToTreeFormat = (
  appsDetails,
  checkedKonferScore,
  isSummaryView
) => {
  const formattedData = [];
  const modelArrNames = [];
  const dataArrNames = [];

  let appLength = 0;
  let modelLength = 0;
  let dataLength = 0;
  const resData = appsDetails;
  // loop through the resData
  for (let i = 0; i < resData.length; i++) {
    const element = resData[i];

    // in loop for the apps get the app detail by app id from api call.
    const appData = element;
    let appOuterRingColor = NodeFillColors.blueCrayola;

    appLength = appLength + 1;
    // create a app root
    const app = {};
    // consider display name instead of name
    app.name = appData.display_name
      ? truncateName(appData.display_name)
      : truncateName(appData.name);
    app.fullName = appData.display_name ? appData.display_name : appData.name;
    app.value = `${appData.__typename}${appData.app_id}`;
    app.route_name = appData.display_name ? appData.display_name : appData.name;
    app.route_id = appData.app_id;
    app.app_id = appData.app_id;
    app.__typename = appData.__typename;
    app.link = [];
    app.children = [];
    app.image = appSvg;
    app.card_id = appData.card_id || null;
    app.description = appData.description || null;
    app.display_name = appData.display_name || null;
    app.version = appData.version || null;
    app.created_at = appData.created_at || null;
    app.card = appData.card;
    app.card_template_version = appData?.card?.latest_version;
    app.card_latest_version = appData?.card?.template_version;
    app.konfer_composite_score_value =
      appData?.risk_factors_data?.length > 0
        ? appData?.risk_factors_data[0].konfer_composite_score
        : null;
    app.contributor = appData.contributor || null;
    app.nodeSettings = {
      // fill: "#1D6BF0",
      fill:
        appData?.risk_factors_data?.length > 0
          ? appData?.risk_factors_data[0].konfer_composite_score <=
              ScoreValue.lowest_limit_red_score && checkedKonferScore
            ? ScoreColors.redColor
            : NodeFillColors.blueCrayola
          : checkedKonferScore
          ? ScoreColors.redColor
          : NodeFillColors.blueCrayola,
    };

    // get all the models used by apps to set it as children
    if (appData?.apps_models.length > 0) {
      appData.apps_models.map((modelData) => {
        const model = modelData.model;
        let modelOuterRingColor = NodeFillColors.paleCyan;

        if (
          model.risk_factors_data?.length > 0 &&
          model.risk_factors_data[0].konfer_composite_score <=
            ScoreValue.lowest_limit_red_score
        ) {
          appOuterRingColor = ScoreColors.redColor;
        }
        // consider display name instead of name
        const modelName = `${
          model.display_name
            ? truncateName(model.display_name)
            : truncateName(model.name)
        }${model.model_id}`;

        /**If model.in_use is true means, the model is on production.
         * And we are showing only those models which are on production*/
        if (model.in_use === true) {
          // check if the model name is present in the models name array
          if (modelArrNames.includes(modelName)) {
            // if true that means that the models is used by some other app already
            // so we dont want to add it as children instead just link it
            app.link.push(modelName);
          } else {
            // this means that the model is used first time by the app so it will add
            // it as children. and push the model name in models array for other apps to know that its used
            const dataFormat = {};
            dataFormat.link = [];
            dataFormat.children = [];
            modelArrNames.push(modelName);
            modelLength = modelLength + 1;

            // get the data_sets for the models

            /**############################################### */
            /**for the demo only, comment the follwing code to hide the dataset */

            // if (model?.models_input_data_tables.length > 0) {
            //   model.models_input_data_tables.map((data) => {
            //     const inputDT = data.input_data_table;

            //     // consider display name instead of name
            //     const inputName = `${
            //       inputDT.display_name
            //         ? truncateName(inputDT.display_name)
            //         : truncateName(inputDT.name)
            //     }${inputDT.input_data_table_id}`;

            //     if (
            //       inputDT?.risk_factors_data?.length > 0 &&
            //       inputDT?.risk_factors_data[0].konfer_composite_score <=
            //         ScoreValue.lowest_limit_red_score
            //     ) {
            //       modelOuterRingColor = ScoreColors.redColor;
            //     }

            //     // if data set existin model then it means its alreay used so we have to link it
            //     if (dataArrNames.includes(inputName)) {
            //       dataFormat.link.push(inputName);
            //     } else {
            //       // else add as a child to model data
            //       dataArrNames.push(inputName);
            //       dataFormat.children.push({
            //         name: inputName,
            //         fullName: inputDT.display_name
            //           ? inputDT.display_name
            //           : inputDT.name,

            //         route_name: inputDT.display_name
            //           ? inputDT.display_name
            //           : inputDT.name,
            //         route_id: inputDT.input_data_table_id,
            //         input_data_table_id: inputDT.input_data_table_id,
            //         dataset_data_id: inputDT.input_data_table_id,
            //         display_name: inputDT.display_name,
            //         card_template_version: inputDT?.card?.latest_version,
            //         card_latest_version: inputDT?.card?.template_version,
            //         source: inputDT.source,
            //         source_type: inputDT.source_type,
            //         usage_type: inputDT.usage_type,
            //         contributor: inputDT.contributor,
            //         __typename: inputDT.__typename,
            //         image: dataSetSvg,
            //         card_id: inputDT?.card_id || null,
            //         card: inputDT?.card,
            //         konfer_composite_score_value:
            //           inputDT?.risk_factors_data === null ||
            //           inputDT?.risk_factors_data?.length === 0
            //             ? null
            //             : inputDT?.risk_factors_data[0].konfer_composite_score,
            //         children: [
            //           {
            //             name: "", //name: "Root", changed to blank
            //             value: "", //name: "Root", changed to blank
            //             nodeSettings: {
            //               fill: "#ffffff",
            //             },
            //             nodeSettings1: {
            //               strokeOpacity: 8,
            //               stroke: "#ffffff",
            //             },
            //             dataSet: {
            //               forceHidden: true,
            //             },
            //           },
            //         ],
            //         nodeSettings: {
            //           fill:
            //             inputDT?.risk_factors_data?.length > 0
            //               ? inputDT?.risk_factors_data[0]
            //                   .konfer_composite_score <=
            //                   ScoreValue.lowest_limit_red_score &&
            //                 checkedKonferScore
            //                 ? ScoreColors.redColor
            //                 : NodeFillColors.paleCyan
            //               : checkedKonferScore
            //               ? ScoreColors.redColor
            //               : NodeFillColors.paleCyan,
            //         },
            //         nodeSettings1: {
            //           stroke: NodeFillColors.whiteColor,
            //         },
            //         rootLinkColor: {
            //           stroke: "#ffffff",
            //           strokeOpacity: 0,
            //         },
            //       });
            //       dataLength = dataLength + 1;
            //     }
            //   });
            // }

            /**############################################### */

            app.children.push({
              name: modelName,
              fullName: model.display_name ? model.display_name : model.name,
              route_name: model.display_name ? model.display_name : model.name,
              route_id: model.model_id,
              model_id: model.model_id,
              __typename: model.__typename,
              children: dataFormat.children,
              link: dataFormat.link,
              image: modelSvg,
              version: model.version,
              model_type: model.model_type,
              external_model_dependency: model.external_model_dependency,
              description: model.description,
              display_name: model.display_name,
              contributor: model.contributor,
              card_template_version: model?.card?.latest_version,
              card_latest_version: model?.card?.template_version,

              algorithm_type: model.algorithm_type,
              created_at: model.created_at,
              card_id: model.card_id || null,
              card: model.card,
              konfer_composite_score_value:
                model?.risk_factors_data === null ||
                model?.risk_factors_data?.length === 0
                  ? null
                  : model?.risk_factors_data[0].konfer_composite_score,
              outerRingColor: modelOuterRingColor,
              nodeSettings: {
                // fill: "#7AD6FF",
                fill:
                  model?.risk_factors_data?.length > 0
                    ? model?.risk_factors_data[0].konfer_composite_score <=
                        ScoreValue.lowest_limit_red_score && checkedKonferScore
                      ? ScoreColors.redColor
                      : NodeFillColors.blueBolt
                    : checkedKonferScore
                    ? ScoreColors.redColor
                    : NodeFillColors.blueBolt,
              },
              nodeSettings1: {
                // stroke: "#7AD6FF",
                stroke: checkedKonferScore
                  ? model?.risk_factors_data?.length > 0 &&
                    model?.risk_factors_data[0].konfer_composite_score <=
                      ScoreValue.lowest_limit_red_score
                    ? ScoreColors.redColor
                    : modelOuterRingColor
                  : NodeFillColors.blueBolt,
              },
            });
          }
        }
      });
    }

    app.nodeSettings1 = {
      stroke: checkedKonferScore
        ? appData?.risk_factors_data?.length > 0 &&
          appData?.risk_factors_data[0].konfer_composite_score <=
            ScoreValue.lowest_limit_red_score
          ? ScoreColors.redColor
          : appOuterRingColor
        : NodeFillColors.blueCrayola,
    };
    app.outerRingColor = appOuterRingColor;

    formattedData.push(app);
  }

  let orphanNodeFormattedData = [];
  let nodeFormattedData = [];
  let apporphanNodeLength = 0;
  let modelorphanNodeLength = 0;
  let dataorphanNodeLength = 0;

  formattedData.map((child) => {
    if (child.children?.length == 0) {
      orphanNodeFormattedData.push(child);
      apporphanNodeLength = apporphanNodeLength + 1;
    } else {
      nodeFormattedData.push(child);
    }
  });

  //  if data  have only one child means it create two node
  let alignFormattedData;

  const testDataFormatted = [
    {
      name: "main1",
      value: "app",

      children: [{ name: "main2", value: "app1" }],
      // children: [],
    },
  ];

  // rename once seed data added
  // ------------------- testDataFormatted to formattedData

  if (formattedData?.length == 1) {
    formattedData?.map((childValue) => {
      if (
        childValue?.children?.length == 1 &&
        childValue?.children[0]?.children.length == 0
      ) {
        if (childValue.__typename != typeNames.apps) {
          alignFormattedData = [
            // added dummy child in leftEnd
            {
              name: "",
              value: "",
              nodeSettings: {
                fill: "#ffffff",
              },
              nodeSettings1: {
                strokeOpacity: 8,
                stroke: "#ffffff",
              },
              rootLinkColor: {
                stroke: "#ffffff",
                strokeOpacity: 0,
              },
              dataSet: {
                forceHidden: true,
              },
              children: formattedData,
            },
          ];
        }
        // added dummy child in rightEnd
        childValue.children[0].children = [
          {
            name: "",
            val: "",
            nodeSettings: {
              fill: "#ffffff",
            },
            nodeSettings1: {
              strokeOpacity: 8,
              stroke: "#ffffff",
            },
            dataSet: {
              forceHidden: true,
            },
          },
        ];

        childValue.children[0].rootLinkColor = {
          stroke: "#ffffff",
          strokeOpacity: 0,
        };
        childValue.children[0].childAlign = true;
      }
      //  single node that  is not related to any other nodes
      else if (childValue?.children?.length == 0) {
        if (childValue.__typename != typeNames.apps) {
          alignFormattedData = [
            // added dummy child in leftEnd
            {
              name: "",
              value: "",
              nodeSettings: {
                fill: "#ffffff",
              },
              nodeSettings1: {
                strokeOpacity: 8,
                stroke: "#ffffff",
              },
              rootLinkColor: {
                stroke: "#ffffff",
                strokeOpacity: 0,
              },
              dataSet: {
                forceHidden: true,
              },
              children: formattedData,
            },
          ];
        }

        if (childValue.__typename == typeNames.apps) {
          // added dummy child in rightEnd
          childValue.children = [
            {
              name: "",
              val: "",
              nodeSettings: {
                fill: "#ffffff",
              },

              nodeSettings1: {
                strokeOpacity: 8,
                stroke: "#ffffff",
              },
              dataSet: {
                forceHidden: true,
              },
              rootLinkColor: {
                stroke: "#ffffff",
                strokeOpacity: 0,
              },
              childAlign: true,
              children: [
                {
                  name: "",
                  value: "",
                  nodeSettings: {
                    fill: "#ffffff",
                  },
                  nodeSettings1: {
                    strokeOpacity: 8,
                    stroke: "#ffffff",
                  },
                  rootLinkColor: {
                    stroke: "#ffffff",
                    strokeOpacity: 0,
                  },
                  dataSet: {
                    forceHidden: true,
                  },
                },
              ],
            },
          ];
          childValue.childAlign = true;
        }

        childValue.rootLinkColor = {
          stroke: "#ffffff",
          strokeOpacity: 0,
        };
      }
    });
  } else {
    formattedData?.map((childValue, index) => {
      if (childValue?.children?.length == 0) {
        // appTypename
        if (childValue.__typename == "app") {
          childValue.rootLinkColor = {
            stroke: "#ffffff",
            strokeOpacity: 0,
          };
          childValue.childAlign = true;

          childValue.children = [
            {
              name: "",
              value: "",
              nodeSettings: {
                fill: "#ffffff",
              },
              nodeSettings1: {
                strokeOpacity: 8,
                stroke: "#ffffff",
              },
              rootLinkColor: {
                stroke: "#ffffff",
                strokeOpacity: 0,
              },
              dataSet: {
                forceHidden: true,
              },
              children: [
                {
                  name: "",
                  value: "",
                  nodeSettings: {
                    fill: "#ffffff",
                  },
                  nodeSettings1: {
                    strokeOpacity: 8,
                    stroke: "#ffffff",
                  },
                  rootLinkColor: {
                    stroke: "#ffffff",
                    strokeOpacity: 0,
                  },
                  dataSet: {
                    forceHidden: true,
                  },
                },
              ],
            },
          ];
        }
        if (childValue.__typename == "model") {
          childValue.rootLinkColor = {
            stroke: "#ffffff",
            strokeOpacity: 0,
          };
          childValue.childAlign = true;
          childValue.children = [
            {
              name: "",
              value: "",
              nodeSettings: {
                fill: "#ffffff",
              },
              nodeSettings1: {
                strokeOpacity: 8,
                stroke: "#ffffff",
              },
              rootLinkColor: {
                stroke: "#ffffff",
                strokeOpacity: 0,
              },
              dataSet: {
                forceHidden: true,
              },
            },
          ];
        }
      }
    });
  }

  formattedData.forEach((val) => {
    if (val.__typename == "app" && val?.children.length > 0) {
      val?.children.map((childVal) => {
        if (childVal?.__typename == "model" && childVal?.children.length == 0) {
          childVal.rootLinkColor = {
            stroke: "#ffffff",
            strokeOpacity: 0,
          };
          childVal.childAlign = false;

          childVal.children = [
            {
              name: "",
              value: "",
              nodeSettings: {
                fill: "#ffffff",
              },
              nodeSettings1: {
                strokeOpacity: 8,
                stroke: "#ffffff",
              },
              rootLinkColor: {
                stroke: "#ffffff",
                strokeOpacity: 0,
              },
              dataSet: {
                forceHidden: true,
              },
              children: [
                {
                  name: "",
                  value: "",
                  nodeSettings: {
                    fill: "#ffffff",
                  },
                  nodeSettings1: {
                    strokeOpacity: 8,
                    stroke: "#ffffff",
                  },
                  rootLinkColor: {
                    stroke: "#ffffff",
                    strokeOpacity: 0,
                  },
                  dataSet: {
                    forceHidden: true,
                  },
                },
              ],
            },
          ];
        }
      });
    }
  });

  const finalFormat = [
    {
      name: "Root",
      value: "Root",
      nodeSettings: {
        fill: "#ffffff",
      },
      nodeSettings1: {
        strokeOpacity: 8,
        stroke: "#ffffff",
      },
      rootLinkColor: {
        stroke: "#ffffff",
        strokeOpacity: 0,
      },
      dataSet: {
        forceHidden: true,
      },

      children: alignFormattedData
        ? alignFormattedData
        : isSummaryView
        ? formattedData
        : nodeFormattedData,
      // isOrphanNodes: orphanNodeFormattedData?.length > 0 ? true : false,
      childAlign: alignFormattedData ? true : false,
    },
  ];

  const finalFormat1 = [
    {
      name: "Root",
      value: "Root",
      nodeSettings: {
        fill: "#ffffff",
      },
      nodeSettings1: {
        strokeOpacity: 8,
        stroke: "#ffffff",
      },
      rootLinkColor: {
        stroke: "#ffffff",
        strokeOpacity: 0,
      },
      dataSet: {
        forceHidden: true,
      },
      children: orphanNodeFormattedData,

      isOrphanNodes: orphanNodeFormattedData?.length > 0 ? true : false,
      childAlign: alignFormattedData ? true : false,
    },
  ];

  return {
    finalFormat,
    finalFormat1,
    info: {
      appLength,
      modelLength,
      dataLength,
      apporphanNodeLength,
      modelorphanNodeLength,
      dataorphanNodeLength,
    },
  };
};
