import React, { useEffect, useState } from "react";
import { useTheme } from "@mui/material/styles";
import {
  Paper,
  TableRow,
  TableHead,
  TableContainer,
  TableCell,
  TableBody,
  Table,
  TableFooter,
  TablePagination,
  Box,
  IconButton,
} from "@mui/material";

import {
  KeyboardArrowLeft,
  FirstPage,
  LastPage,
  KeyboardArrowRight,
} from "@mui/icons-material";

import { dateTimeFormat } from "../../utils/basicUtils";

const ObservabilityTable = ({ lineChartData, id, indexname, tableData }) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(4);

  let windowHeight = window.screen.height;

  useEffect(() => {
    windowHeight <= 810 && setRowsPerPage(2);
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  function TablePaginationActions(props) {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
      onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
      onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
      onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
      <Box
        sx={{ flexShrink: 0, ml: 2.5 }}
        id="observabilityTablePaginationActions"
      >
        <IconButton
          id="paginationFirstBtnClick"
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          {theme.direction === "rtl" ? <LastPage /> : <FirstPage />}
        </IconButton>
        <IconButton
          id="paginationBackBtnClick"
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowRight />
          ) : (
            <KeyboardArrowLeft />
          )}
        </IconButton>
        <IconButton
          id="paginationNextBtnClick"
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowLeft />
          ) : (
            <KeyboardArrowRight />
          )}
        </IconButton>
        <IconButton
          id="paginationLastBtnClick"
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          {theme.direction === "rtl" ? <FirstPage /> : <LastPage />}
        </IconButton>
      </Box>
    );
  }
  return (
    <>
      <TableContainer
        component={Paper}
        className="table-head"
        id="observabilityTableContainer"
      >
        <Table
          sx={{ minWidth: 650 }}
          aria-label="simple table"
          id="observabilityTable"
        >
          <TableHead>
            <TableRow>
              <TableCell style={{ fontWeight: "700" }}>
                {" "}
                TimeStamp (UTC)
              </TableCell>
              {lineChartData.observabilities.map((val, index) => (
                <TableCell
                  align="right"
                  key={index}
                  style={{ fontWeight: "700" }}
                >
                  {val.key}
                </TableCell>
              ))}

              {/* <TableCell align="right">Protein&nbsp;(g)</TableCell> */}
            </TableRow>
          </TableHead>
          <TableBody>
            {(rowsPerPage > 0
              ? tableData.slice(
                  page * rowsPerPage,
                  page * rowsPerPage + rowsPerPage
                )
              : tableData
            ).map((row, index) => (
              <TableRow
                id={`observabilityTableRow${index}`}
                key={index}
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                  height: "5px",
                }}
              >
                <TableCell component="th" scope="row">
                  {dateTimeFormat(row.timeStamp)}
                </TableCell>
                 {lineChartData.observabilities.map((val, index) => (
                  <TableCell
                    align="right"
                    key={index}
                    style={{ fontWeight: "700" }}
                  >
                    {row[val.key]}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
          <TableFooter sx={{ height: "10px" }}>
            <TableRow>
              <TablePagination
                // rowsPerPageOptions={[5, 10]}
                rowsPerPageOptions={[3]}
                colSpan={3}
                count={tableData.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: {
                    "aria-label": "rows per page",
                  },
                  native: true,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </>
  );
};

export default ObservabilityTable;
