import React, { useEffect, useLayoutEffect, useState } from "react";
import * as am5 from "@amcharts/amcharts5";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import * as am5hierarchy from "@amcharts/amcharts5/hierarchy";
import {
  mchartSeriesConfigGraphView,
  NodeFillColors,
  ScoreColors,
} from "../../constants/amcharts";
import {
  mchartDataFormat,
  setStrikeColor,
} from "../../services/detailsServices";
import { selectedNode } from "../../atom/jotai";
import { useAtom } from "jotai";
import {
  colorSetForOriginalColor,
  colorStrike,
  getUniqueAmchartId,
} from "../../utils/amchartUtils";
import { Box } from "@mui/system";
import { isEmpty } from "lodash";
import { ColorIndexNames } from "../../constants/dataTypes";

const AmachartUnusedModelDataset = ({
  checkedKonferScore,
  checkedShowLabel,
  selectedData,
  height = "87vh",
  width = "100%",
  nodeLabelFont,
  zoomInAndOut,
  handleRightClickOnNode,
  findElementInNodeDetails,
  setFindElementInNodeDetails,
  totalCountForNodeDetails,
  setTotalCountForNodeDetails,
  activeCountForNodeDetails,
  setActiveCountForNodeDetails,
  setIsUnUsedNodeDataCount,
  orphanDataCount,
  setOrphanDataCount,
}) => {
  // update selected node state
  const [nodeData, setSelectedNodeData] = useAtom(selectedNode);
  // give unique id to amchart canvas to avoid duplicate id error
  const uniqueDivId = getUniqueAmchartId();

  // This state is used to store the reference of series object used in amchart to show graph nodes/relationships
  const [series, setSeries] = useState();

  const [nodeColour, setNodeColour] = useState(false);

  const [matchElementUIDInNodeDetails, setMatchElementUIDInNodeDetails] =
    useState([]);

  let i = 0;
  let arrForSavingMatchElement = [];

  /**
   * This useEffect is used to render the node labels
   * It is dependant checkedShowLabel props
   * if checkedShowLabel is true then show labels else hide
   */
  useEffect(() => {
    series?.labels.each(function (node) {
      if (checkedShowLabel) {
        node.set("text", "{names}");
        node.set("fontSize", nodeLabelFont);
      } else {
        node.set("text", "");
      }
    });
  }, [checkedShowLabel, nodeLabelFont]);

  /**
   * This useEffect is used for highlighting the node labels
   * It is dependant on findElementInGraphview props
   * if findElementInGraphview has some value then highlight it
   */
  useEffect(() => {
    if (checkedShowLabel === true) {
      series?.labels.each(function (node) {
        node.set("text", "{names}"); //Re-setting the initial state of label (not highlighted) [eg:- node.set("text", "x[bold]y[/]z")]

        var myRegExp = new RegExp(`${findElementInNodeDetails}`, "gi");
        if (findElementInNodeDetails !== "" && checkedShowLabel === true) {
          const temp = node
            .getText()
            .replace(myRegExp, function (match, capture) {
              return `[#FFD700][bold]` + match + `[/]`;
            });
          node.set("text", temp);
        }

        var match1 = node.getText().match(myRegExp);
        if (match1) {
          i = i + 1; //counting the number of occurrence
          arrForSavingMatchElement.push(node._parent.uid);
        }
      });
      // setTotalCountForNodeDetails(i); // total count of all occurrence
      setMatchElementUIDInNodeDetails(arrForSavingMatchElement);
    }
  }, [findElementInNodeDetails]);

  /**
   * This useEffect is used for highlighting the occurence node
   * It is dependant on activeCountForGraphView props.
   * When the drop arrow is pressed from Find Input, this useEffect is called.
   */
  useEffect(() => {
    series?.circles.each(function (node, ev) {
      node.states.apply("default");
      if (
        node._parent.uid ===
        matchElementUIDInNodeDetails[activeCountForNodeDetails - 1]
      ) {
        node.states.apply("FindHighlight");
      }
    });
  }, [activeCountForNodeDetails]);

  /**
   * This useEffect is used for selecting the occurence from highlighted node labels
   * It is dependant on activeCountForGraphView props.
   * When the drop arrow is pressed from Find Input, this useEffect is called.
   */
  //need to change the logic, and try to merge with activeCountForGraphView dependant useEffect() of highlighting circle
  useEffect(() => {
    var myRegExp = new RegExp(`${findElementInNodeDetails}`, "gi");
    series?.labels.each(function (node) {
      if (
        node._parent.uid ===
        matchElementUIDInNodeDetails[activeCountForNodeDetails - 1]
      ) {
        if (findElementInNodeDetails !== "") {
          const temp = node
            .getText()
            .replace(myRegExp, function (match, capture) {
              return `[#FF8C00][bold]` + match + `[/]`;
            });
          node.set("text", temp);
        }
      } else {
        if (findElementInNodeDetails !== "") {
          const temp = node
            .getText()
            .replace(myRegExp, function (match, capture) {
              return `[#FFD700][bold]` + match + `[/]`;
            });
          node.set("text", temp);
        }
      }
    });
  }, [activeCountForNodeDetails]);

  /**
   * This useEffect is called when checkedKonferScore is set to true which means
   *  to show the colours as per the score else show the original colours of nodes
   */
  useEffect(() => {
    series?.circles.each(function (node) {
      if (checkedKonferScore) {
        node.dataItem.dataContext.nodeMinScoreColor &&
        node.dataItem.dataContext.nodeMinScoreColor !== ScoreColors.greenColor
          ? node.dataItem
              .get("circle")
              .set(
                "fill",
                am5.color(node.dataItem.dataContext.nodeMinScoreColor)
              )
          : colorSetForOriginalColor(node);

        node.dataItem.dataContext.minScoreColor &&
        node.dataItem.dataContext.minScoreColor !== ScoreColors.greenColor
          ? node.dataItem
              .get("outerCircle")
              .set("stroke", am5.color(node.dataItem.dataContext.minScoreColor))
          : colorSetForOriginalColor(node);
      } else {
        colorSetForOriginalColor(node);
      }
    });
  }, [checkedKonferScore]);

  //while highlight set the default border color of node
  useEffect(() => {
    series &&
      //Outer Circle color
      series.outerCircles.template.setAll({
        templateField: "nodeSettings1",
      });
  }, [nodeColour]);

  useLayoutEffect(() => {
    let root = am5.Root.new(`${uniqueDivId}`);

    root.setThemes([am5themes_Animated.new(root)]);

    var container = root.container.children.push(
      am5.Container.new(root, {
        width: am5.percent(100),
        height: am5.percent(100),
        layout: root.verticalLayout,
      })
    );
    root.interfaceColors.set("grid", am5.color(0xff0000));

    var series = container.children.push(
      am5hierarchy.ForceDirected.new(root, mchartSeriesConfigGraphView)
    );
    setSeries(series);
    setIsUnUsedNodeDataCount(true);

    series.events.on("inited", function () {
      series.animate({ property: "velocityDecay", to: 1 }, 3000);
    });

    series.nodes.template.setAll({
      draggable: false,
      // toggleKey: "none",
    });
    series.nodes.template.setAll({
      // toggleKey: "none",
      expandAll: false,
    });

    // networkSeries.nodes.template.expandAll = false;

    // Tooltip
    series.nodes.template.setAll({
      tooltipText: "{display_name}",
      width: am5.percent(90),
      tooltipY: am5.percent(90),
      oversizedBehavior: "wrap",

      //Uncomment the above code and comment the below code, if we want to show the tooltip
      // tooltipText: "", //Temp: For not showing tooltip
      // width: am5.percent(0), //Temp: For not showing tooltip
    });
    // -----------------------------------------------------------------label
    // node label text
    series.labels.template.setAll({
      fontSize: nodeLabelFont,
      fill: am5.color(0x000000),
      y: -40,
      text: checkedShowLabel ? "{names}" : " ",
      oversizedBehavior: "none",
    });

    // var tooltip = am5.Tooltip.new(root, {
    //   getFillFromSprite: false,
    //   getStrokeFromSprite: false,
    //   autoTextColor: false,
    //   getLabelFillFromSprite: true,
    // });

    // tooltip.get("background").setAll({
    //   fill: am5.color(0xffffff),
    //   stroke: am5.color(0x000000),
    //   strokeOpacity: 0.8,
    // });

    // tooltip.label.setAll({
    //   fill: am5.color(0x000000),
    // });

    // series.set("tooltip", tooltip);

    series.showOnInit = false;
    let nodeClickStatus = false;

    // setNodeData(); // To rerender the Details section of Node Page

    // set node info on click
    series.nodes.template.events.on("click", function (ev) {
      setSelectedNodeData(ev.target._dataItem.dataContext);
    });

    //Disabling built-in context menu
    root.addDisposer(
      am5.utils.addEventListener(root.dom, "contextmenu", function (ev) {
        ev.preventDefault();
      })
    );
    //Right-Click event
    series.nodes.template.events.on("rightclick", function (ev) {
      handleRightClickOnNode(ev);
    });

    // Add an icon/Image to node
    series.nodes.template.setup = function (target) {
      target.events.on("dataitemchanged", function (ev) {
        let icon = target.children.push(
          am5.Picture.new(root, {
            width: 25,
            height: 25,
            centerX: am5.percent(50),
            centerY: am5.percent(50),
            src: ev.target.dataItem.dataContext.image,
          })
        );
      });
    };

    series.links.template.setAll({
      strokeWidth: 1.5,
    });

    series.links.template.adapters.add("stroke", function (fill, target) {
      return am5.color(0x68c7fc);
    });

    // node color
    series.circles.template.setAll({
      templateField: "nodeSettings",
    });

    //Highlighting the node
    series.circles.template.states.create("FindHighlight", {
      strokeOpacity: 8,
      stroke: am5.color("#FF8C00"),
      strokeWidth: 6,
    });

    // ----------------------------------------------------

    //set data for   mchart
    series.data.setAll(mchartDataFormat(selectedData, checkedKonferScore));
    return () => {
      root.dispose();
    };
  }, []);

  return (
    <div
      id={`${uniqueDivId}`}
      style={{
        width: "150px",
        height: "120px",
        marginLeft: "-23px",
        marginTop: "-28px",
        marginBottom: "53px",
        transform: `scale(${zoomInAndOut})`,
      }}
    ></div>
  );
};

export default AmachartUnusedModelDataset;
