// images
import appChip from "../assets/images/listview/app-chip.svg";
import modelChip from "../assets/images/listview/model-chip.svg";
import datasetChip from "../assets/images/adminPanel/Dataset.svg";
import { uniqueArrayValues } from "../utils/basicUtils";
import {
  APIIndexNames,
  tableDataTypes,
  typeNames,
} from "../constants/dataTypes";
import { ANNOTATION_TYPES } from "../constants/detailConstants";
import { queryKeyConstants } from "../constants/basicConstants";

export const mapBusinessUnits = (data, key, val) => {
  if (val) {
    const keyMap = `${key}s_business_units`;
    let mappedIds = [];

    data.region.map((item) => {
      item.business_unit?.map((businessUnits) => {
        const dataToMap = businessUnits[keyMap];
        dataToMap.map((units) => {
          const id = units[`${key}_id`];
          mappedIds.push(id);
        });
      });
    });

    return mappedIds;
  } else {
    const keyMap = `${key}s_business_units`;

    const dataToMap = data[keyMap];

    let mappedIds = [];

    if (dataToMap.length > 0) {
      dataToMap.map((units) => {
        const id = units[`${key}_id`];
        mappedIds.push(id);
      });
    }

    return mappedIds;
  }
};

export const uniqueRegionFinalIds = (mainDataIds, regionIds) => {
  const finalResp = mainDataIds.filter((value) => regionIds.includes(value));
  return finalResp;
};

export const getFormatedData = (appIds, modelIds, dataSetIds) => {
  const finalFormatData = [];

  if (appIds.length > 0) {
    finalFormatData.push({
      name: "Applications",
      count: appIds.length,
      type: "apps",
      image: appChip,
    });
  }

  if (modelIds.length > 0) {
    finalFormatData.push({
      name: "Models",
      count: modelIds.length,
      type: "models",
      image: modelChip,
    });
  }

  if (dataSetIds.length > 0) {
    finalFormatData.push({
      name: "Datasets",
      count: dataSetIds.length,
      type: "datasets",
      image: datasetChip,
    });
  }

  return finalFormatData;
};

export const getDataIds = (res) => {
  let appIds = [];
  let modelIds = [];
  let dataSetIds = [];

  const mainSearchData = res.data;

  const searchData = mainSearchData.filter((data) => {
    return (
      data?._source?.app_status ==
        queryKeyConstants.searchDataAppStatusCompleted ||
      data?._source?.app_status == undefined
    );
  });

  searchData.map((data) => {
    if (data._index === APIIndexNames.app) {
      appIds.push(data._source.app_id);
    }

    if (data._index === APIIndexNames.model) {
      modelIds.push(data._source.model_id);
    }

    if (data._index === APIIndexNames.input_data_table) {
      dataSetIds.push(data._source.input_data_table_id);
    }

    // get ids from annotations if present
    if (data._index === APIIndexNames.annotation) {
      if (
        data._source.type === ANNOTATION_TYPES.APP &&
        (data?._source?.app_status == undefined ||
          data?._source?.app_status ===
            queryKeyConstants.searchDataAppStatusCompleted)
      ) {
        appIds.push(data._source.type_id);
      }

      if (data._source.type === ANNOTATION_TYPES.MODEL)
        modelIds.push(data._source.type_id);
      if (data._source.type === ANNOTATION_TYPES.INPUT_DATA_TABLE)
        dataSetIds.push(data._source.type_id);
    }

    if (data._index === APIIndexNames.cards) {
      if (
        data._source.card_type === typeNames.apps &&
        data?._source?.app_status ===
          queryKeyConstants.searchDataAppStatusCompleted
      ) {
        appIds.push(data._source.reference_id);
      }
      if (data._source.card_type === typeNames.models)
        modelIds.push(data._source.reference_id);
      if (data._source.card_type === typeNames.datasets)
        dataSetIds.push(data._source.reference_id);
    }
  });

  appIds = uniqueArrayValues(appIds);
  modelIds = uniqueArrayValues(modelIds);
  dataSetIds = uniqueArrayValues(dataSetIds);
  const idsFormatData = {
    appIds,
    modelIds,
    dataSetIds,
  };
  return idsFormatData;
};

export const getSeletedDataInfo = (key) => {
  let img;

  if (key === tableDataTypes.apps) {
    img = appChip;
  }

  if (key === tableDataTypes.models) {
    img = modelChip;
  }

  if (key === tableDataTypes.dataSets) {
    img = datasetChip;
  }

  return img;
};
