export const modelFields = [
  "name",
  "version",
  "model_type",
  "external_model_dependency",
  "display_name",
  "description",
  "algorithm_type",
  "created_at",
];

export const datasetCardFields = [
  "name",
  "display_name",
  "source",
  // "algorithm_type",
  // "Organization",
  "source_type",
  "usage_type",
  "contributor",

  // "intended_use",
  // "created_at",
];

export const cardConstant = {
  model: [
    "name",
    "display_name",
    "description",
    "version",
    "created_at",
    "model_type",
    "external_model_dependency",
    "algorithm_type",
    "contributor",
    "models_hyperparameters_tables",
  ],
  input_data_table: [
    "name",
    "display_name",
    "description",
    "source",
    "source_type",
    "usage_type",
    "contributor",
  ],
  app: [
    "name",
    "display_name",
    "description",
    "version",
    "created_at",
    "contributor",
  ],
};

export const openAiQueArr = [
  "Are there metrics for measuring technical robustness?",
  "Is the system tested for bias?",
  "Does the design document provide design details of Technical Robustness, with respect to the intended uses?",
];
