import React from 'react'
import Login from '../../components/Login/Login'


const LoginPage = () => {
  return (
   <Login/>
  )
}

export default LoginPage