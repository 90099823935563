import React, { useState } from "react";
import { IconButton } from "@mui/material";
import { CloseOutlined, Search } from "@mui/icons-material";
import "./Searchbox.css";
import { useNavigate, useParams } from "react-router-dom";
import {
  deslugifyText,
  formateText,
  slugifyText,
} from "../../utils/basicUtils";
import { useAtom } from "jotai";
import { isComplianceViewAtom, searchedWord } from "../../atom/jotai";
import { useEffect } from "react";
import { analyticServices } from "../../services/analyticServices";

const Searchbox = () => {
  const openSidebar = false;
  const param = useParams();
  const userSearchText = localStorage.getItem("userSearchText");

  const [searchText, setSearchText] = useState("");
  const navigate = useNavigate();
  const [, setSearchState] = useAtom(searchedWord);
  const [, setIsComplianceView] = useAtom(isComplianceViewAtom);

  const handleSearch = () => {
    const sluggedSearch = String(searchText);

    if (searchText?.trim().length > 0) {
      const searchTextInArray = searchText.split(" "); // converting string into array

      /**If the searched value length in less than 3 then redirect to elastic (back end).
       * If it's greater than 3 then NLQ */
      if (searchTextInArray.length < 3) {
        localStorage.setItem(
          "userSearchText",
          searchText.length == 0 ? param?.searchText : searchText
        );
        navigate(`/result/listview/${searchText == "*" ? "*" : sluggedSearch}`);
        analyticServices(`SEARCH  : ${searchText}`);
        setIsComplianceView(false);
      } else {
        navigate(`/nlp-query-result/${slugifyText(String(searchText))}`, {
          state: { originalSearch: searchText },
        });
        analyticServices(`NLP SEARCH  : ${searchText}`);
      }
    }
  };

  //While pressing cancel on main search, always show the result for all (*)
  const handleCancel = () => {
    localStorage?.setItem("userSearchText", "konfer-confidence");
    navigate(`/result/listview/konfer-confidence`);
  };

  useEffect(() => {
    let searchTextDataFormate =
      param?.searchText?.split("-")?.join(" ") === "konfer confidence"
        ? ""
        : param?.searchText?.split("-")?.join(" ");
    setSearchText(searchTextDataFormate);

    setSearchState(searchTextDataFormate);
  }, [userSearchText]);

  return (
    <>
      <div
        className={openSidebar ? "queryInput_sidebarOpen" : "queryInput"}
        id="appbarSearchDiv"
      >
        <input
          id="appbarSearchInput"
          className="queryInputFiled"
          type="text"
          placeholder={`Search`}
          value={
            searchText == "konfer-confidence"
              ? ""
              : searchText == "*"
              ? "*"
              : searchText
          }
          onKeyPress={(ev) => {
            if (ev.key === "Enter") {
              handleSearch();
            }
          }}
          onChange={(e) => {
            setSearchText(e.target.value);
          }}
        />

        {searchText !== "konfer-confidence" && searchText && (
          <IconButton
            id="appBarSearchCancelIconButton"
            type="submit"
            style={{ marginRight: "-27px" }}
            className="cancel_querypage_searchIcons"
            onClick={() => {
              setSearchText("");
              handleCancel();
            }}
            aria-label="cancel"
          >
            <CloseOutlined fontSize="medium" />
          </IconButton>
        )}

        <span className="searchIcons" id="appBarSearchIconButton">
          <Search
            className="searchIconResult"
            onClick={() => handleSearch()}
            id="appBarSearchIcon"
          />
        </span>
      </div>
    </>
  );
};

export default Searchbox;
