import React, { useEffect, useState } from "react";
// import AmchartGraphview from '../AmchartComponents/AmchartGraphview'
import "./GraphviewChart.css";

// mui imports
import { CircularProgress } from "@mui/material";
import {
  convertDataToTreeFormat,
  formatSearchData,
  getAppIdsArray,
} from "../../services/graphviewServices";
import AmchartGraphview from "../AmchartComponents/AmchartGraphview";
import {
  useGetAppsDetails,
  useGetDatasetsDetails,
  useGetModelsDetails,
  useGetSearchDetails,
} from "../../api/readApiFunctions";
import { useAtom } from "jotai";
import { isGraphviewChild, unusedModelDatasetListData } from "../../atom/jotai";
import AmachartUnusedModelDataset from "../AmchartComponents/AmachartUnusedModelDataset";
import AmchartThumbnailNew from "../AmchartComponents/AmchartThumbnailNew";
import { mchartDataFormat } from "../../services/detailsServices";

const GraphviewChart = ({
  appIds,
  modelIds,
  datasetIds,
  checkedShowLabel,
  checkedKonferScore,
  setDataCount,
  nodeLabelFont,
  zoomInAndOut,
  chartHeight,
  handleRightClickOnNode,
  orphanNodes,
  setUnUsedNode,
  setOrphanDataCount,
  orphanDataCount,
  setCount,
  count,
  index,
  setOrphanNodes,
  unUsedCount,
  setUnusedCount,
  nodeCount,
  setNodeCount,
}) => {
  const [formatedData, setFormatedData] = useState(null);
  const [graphviewChild, setGraphviewChild] = useAtom(isGraphviewChild);
  const [formattedAppIds, setFormattedAppIds] = useState({
    fetch: false,
    ids: null,
  });

  const [unUsedNodeData, setUnUsedNodeData] = useState({});
  const [isUnUsedNodeData, setIsUnUsedNodeDataCount] = useState(false);

  const [unUsedNodeDataCount, setUnUsedNodeDataCount] = useState({
    app: 0,
    model: 0,
    dataset: 0,
  });

  // get app details
  const {
    data: appsDetails,
    error: appsDetailsError,
    isLoading: appsLoading,
  } = useGetAppsDetails(appIds, true, null , null , "asset_view" , "graph_view");

  // get model details
  const {
    data: modelsDetails,
    error: modelsDetailsError,
    isLoading: modelsLoading,
  } = useGetModelsDetails(modelIds, true , null , null , "asset_view" , "graph_view");

  // get dataset details
  const {
    data: datasetsDetails,
    error: datasetDetailsError,
    isLoading: datasetsLoading,
  } = useGetDatasetsDetails(datasetIds, true,null , null , "asset_view" , "graph_view");

  // this api is responsible to get app details for app ids inside the models and datasets
  const {
    data: allAppDetails,
    error: allAppError,
    isLoading: allAppLoading,
  } = useGetAppsDetails(formattedAppIds.ids, formattedAppIds.fetch , null , null , "asset_view" , "graph_view");

  // to get the app ids for graphview logic
  useEffect(() => {
    if (appsDetails && modelsDetails && datasetsDetails) {
      const arrayOfIds = getAppIdsArray(
        appsDetails,
        modelsDetails,
        datasetsDetails
      );
      setFormattedAppIds({
        fetch: true,
        ids: arrayOfIds,
      });

      setUnUsedNodeData({
        appsDetails: appsDetails,
        modelsDetails: modelsDetails,
        datasetsDetails: datasetsDetails,
      });
    }
  }, [appsDetails, modelsDetails, datasetsDetails]);

  // get the formatted data for rendering graphview
  useEffect(() => {
    if (allAppDetails) {
      const data = convertDataToTreeFormat(
        allAppDetails,
        checkedKonferScore,
        false
      );
      setFormatedData(data);
      orphanNodes && setUnUsedNode(data);

      setDataCount({
        app: data.info.appLength,
        model: data.info.modelLength,
        dataset: data.info.dataLength,
      });

      setOrphanDataCount({
        app: data.info.apporphanNodeLength,
        model: data.info.modelorphanNodeLength,
        dataset: data.info.dataorphanNodeLength,
      });
    }
  }, [allAppDetails]);

  useEffect(() => {
    let unUsedAppCount = [];
    let UnusedModelCount = [];
    let UnusedDatasetCount = [];

    let appCount = [];
    let modelCount = [];
    let datasetCount = [];

    unUsedNodeData?.appsDetails?.map((datasetData, index) => {
      const formateData = mchartDataFormat([datasetData], true);
      formateData[0]["__typename"] === "app" &&
        (formateData[0]?.children?.length < 1 ||
        formateData[0]?.children == undefined
          ? unUsedAppCount.push(index)
          : appCount.push(index));
    });
    unUsedNodeData?.modelsDetails?.map((modelData, index) => {
      const formateData = mchartDataFormat([modelData], true);

      formateData[0]["__typename"] === "model" &&
        (formateData[0]?.children?.length < 1 ||
        formateData[0]?.children == undefined
          ? UnusedModelCount.push(index)
          : modelCount.push(index));
    });
    unUsedNodeData?.datasetsDetails?.map((datasetData, index) => {
      const formateData = mchartDataFormat([datasetData], true);

      formateData[0]["__typename"] === "input_data_table" &&
        (formateData[0]?.children?.length < 1 ||
        formateData[0]?.children == undefined
          ? UnusedDatasetCount.push(index)
          : datasetCount.push(index));
    });

    setUnusedCount({
      app: unUsedAppCount.length,
      model: UnusedModelCount.length,
      dataset: UnusedDatasetCount.length,
    });

    setNodeCount({
      app: appCount.length + unUsedAppCount.length,
      model: modelCount.length + UnusedModelCount.length,
      dataset: datasetCount.length + UnusedDatasetCount.length,
    });
  }, [unUsedNodeData]);

  return (
    <div
      className={"query_search_data_container"}
      id="query_search_data_container"
    >
      {formatedData ? (
        <div
          className={!orphanNodes ? "graphview_node_unused" : "graphview_node"}
          id="graphviewTreeChart"
        >
          {!orphanNodes && (
            <div>
              <AmchartGraphview
                seriesData={formatedData.finalFormat}
                seriesOrphanNodeData={formatedData.finalFormat1}
                formatInfo={formatedData.info}
                checkedShowLabel={checkedShowLabel}
                nodeLabelFont={nodeLabelFont}
                zoomInAndOut={zoomInAndOut}
                handleRightClickOnNode={handleRightClickOnNode}
                chartHeight={chartHeight}
                orphanNodes={orphanNodes}
                checkedKonferScore={checkedKonferScore}
                isUnUsedNodeData={isUnUsedNodeData}
              />
            </div>
          )}
          {orphanNodes && (
            <>
              <div className={!orphanNodes ? "modelNode" : "appUnusedNode "}>
                {unUsedNodeData?.appsDetails?.map((addData) => {
                  if (
                    mchartDataFormat([addData], true)[0]?.children ==
                      undefined ||
                    mchartDataFormat([addData], true)[0]?.children?.length < 1
                  ) {
                    return (
                      <div style={{ marginBottom: "25px" }}>
                        <AmachartUnusedModelDataset
                          selectedData={[addData]}
                          checkedShowLabel={checkedShowLabel}
                          checkedKonferScore={checkedKonferScore}
                          handleRightClickOnNode={handleRightClickOnNode}
                          setIsUnUsedNodeDataCount={setIsUnUsedNodeDataCount}
                          setOrphanDataCount={setOrphanDataCount}
                          orphanDataCount={orphanDataCount}
                          index={index}
                          zoomInAndOut={zoomInAndOut}
                          nodeLabelFont={nodeLabelFont}
                        />
                      </div>
                    );
                  }
                })}
              </div>
            </>
          )}
          {orphanNodes && (
            <>
              <div className={!orphanNodes ? "modelNode" : "modelUnusedNode"}>
                {unUsedNodeData?.modelsDetails?.map((modelData) => {
                  if (
                    mchartDataFormat([modelData], true)[0]?.children ==
                      undefined ||
                    mchartDataFormat([modelData], true)[0]?.children?.length < 1
                  ) {
                    return (
                      <div style={{ marginBottom: "25px" }}>
                        <AmachartUnusedModelDataset
                          selectedData={[modelData]}
                          checkedShowLabel={checkedShowLabel}
                          checkedKonferScore={checkedKonferScore}
                          handleRightClickOnNode={handleRightClickOnNode}
                          setIsUnUsedNodeDataCount={setIsUnUsedNodeDataCount}
                          setOrphanDataCount={setOrphanDataCount}
                          orphanDataCount={orphanDataCount}
                          index={index}
                          zoomInAndOut={zoomInAndOut}
                          nodeLabelFont={nodeLabelFont}
                        />
                      </div>
                    );
                  }
                })}
              </div>

              <div
                className={!orphanNodes ? "datasetNode" : "datasetUnusedNode"}
              >
                {unUsedNodeData?.datasetsDetails?.map((datasetData, index) => {
                  if (
                    mchartDataFormat([datasetData], true)[0]?.children ==
                      undefined ||
                    mchartDataFormat([datasetData], true)[0]?.children?.length <
                      1
                  ) {
                    return (
                      // <div style={{ marginLeft: "72vw", padding: "10px" }}>
                      <div style={{ marginBottom: "25px" }}>
                        <AmachartUnusedModelDataset
                          selectedData={[datasetData]}
                          checkedShowLabel={checkedShowLabel}
                          checkedKonferScore={checkedKonferScore}
                          handleRightClickOnNode={handleRightClickOnNode}
                          setIsUnUsedNodeDataCount={setIsUnUsedNodeDataCount}
                          setOrphanDataCount={setOrphanDataCount}
                          orphanDataCount={orphanDataCount}
                          count={count}
                          setCount={setCount}
                          index={index}
                          zoomInAndOut={zoomInAndOut}
                          nodeLabelFont={nodeLabelFont}
                        />
                      </div>
                    );
                  }
                })}
              </div>
            </>
          )}
        </div>
      ) : (
        <div className="graphview-loader" id="graphviewLoader">
          <CircularProgress />
        </div>
      )}
    </div>
  );
};

export default GraphviewChart;
