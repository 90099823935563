import { ConstructionOutlined } from "@mui/icons-material";
import _ from "lodash";
import {
  scoreColorsNew,
  ScoreValue,
  ScoreValueNew,
} from "../constants/amcharts";
import { KONFER_SCORE } from "../constants/basicConstants";
import {
  complianceTagName,
  complianceTagValue,
} from "../constants/complianceConstants";
import { typeNames, tableDataTypes } from "../constants/dataTypes";
import { dateFormate } from "../utils/basicUtils";
import { riskFactorValueCond } from "../constants/riskFactorMonth";
import { include_columns_for_internal_find } from "../constants/listviewConstants";

const endpoint = `${process.env.REACT_APP_GRAPHQL_API}v1/graphql`;

// this function  sortDataByKonferScore used for sort data by asc or desc order by the konfer composite score
// the  sortDataByKonferScore function  take inputs ass konfer data ,keyame and order=asc/dsc
export const sortDataByKonferScore = (Data, keyName, order) => {
  let sortedData = _.orderBy(Data, keyName, order);

  const sortedDataWithNullValue = Data.filter((val) => {
    return val.risk_factors_data[0].konfer_composite_score == null;
  });
  const sortedDataWithoutNull = sortedData.filter((val) => {
    return val.risk_factors_data[0].konfer_composite_score !== null;
  });

  return order[0] == "asc"
    ? [...sortedDataWithNullValue, ...sortedDataWithoutNull]
    : [...sortedDataWithoutNull, ...sortedDataWithNullValue];
};

// takes the search word to check weather the user has searched konfer score or not
export const isKonferScore = (searchedWord) => {
  if (searchedWord === KONFER_SCORE) {
    return true;
  } else {
    return false;
  }
};

export const setActiveChip = (formatData, dataTypes) => {
  const showChipActive = [];

  formatData.map((data) => {
    if (data.type === dataTypes.apps) {
      showChipActive.push(dataTypes.apps);
    } else if (data.type === dataTypes.models) {
      showChipActive.push(dataTypes.models);
    } else {
      showChipActive.push(dataTypes.datasets);
    }
  });

  return showChipActive;
};

//function is used to find wheather the sub string is present in string
export const tempName = (
  searchString,
  mainString,
  data,
  selectedRegion,
  selectedBUnit,
  selectedData,
  ind,
  scoresSelectedPolicy
) => {
  let result = {};
  let arraytemp;
  let positionOfSubstringFinal = {};
  let count = 0;
  let returnData;
  let columnArray = [];

  //Adding konferConfidence
  result.konferConfidence =
    data?.risk_factors_data?.length > 0
      ? data?.risk_factors_data[0].konfer_composite_score
      : null;

  //Adding name or display_name
  data.display_name === null || data.display_name === undefined
    ? (result.name = truncateName(data?.name))
    : (result.name = truncateName(data?.display_name));

  //Addinh Human Impact
  // data[`${typeNames[selectedData]}s_risk_factors`].length > 0
  //   ? (result.humanImpact =
  //       data[`${typeNames[selectedData]}s_risk_factors`][0].value)
  //   : (result.humanImpact = null);
  result.humanImpact = data?.humanImpact;

  //Adding Policy
  result.policyScore = data[scoresSelectedPolicy];

  //Adding version
  if (
    data?.__typename === typeNames.apps ||
    data?.__typename === typeNames.models
  ) {
    result.version = data?.version;
  }

  //Adding Deployment Region
  result.aws_region = data?.aws_region;

  //Adding region
  if (selectedRegion === complianceTagName.AllRegionsTag) {
    data[`${typeNames[selectedData]}s_business_units`]?.map((unit, index) => {
      if (index < 1) {
        result.region = unit.business_unit?.region?.name;
      }
    });
  }

  //Adding business unit
  if (
    selectedBUnit === undefined ||
    selectedBUnit === complianceTagValue.AllBusinessUnis
  ) {
    let bunit = "";
    data[`${typeNames[selectedData]}s_business_units`]?.map((unit, index) => {
      if (
        index + 1 ===
        data[`${typeNames[selectedData]}s_business_units`]?.length
      ) {
        bunit = bunit + unit.business_unit.name;
      } else {
        bunit = bunit + `${unit.business_unit.name}, `;
      }
    });
    result.business_unit = bunit;
  }

  //Adding algorithm_type
  if (data?.__typename === typeNames.models) {
    result.algorithm_type = data?.algorithm_type;
  }

  //Adding source_type and usage_type
  if (data?.__typename === typeNames.datasets) {
    result.source_type = data?.source_type;
    result.usage_type = data?.usage_type;
  }

  // //Adding created_at
  // result.created_at =
  //   data?.created_at !== undefined ? dateFormate(data?.created_at) : "";

  var regex = new RegExp(searchString, "gi");

  // let array1;
  // let positionOfSubstring = [];

  // while ((array1 = regex.exec(mainString))) {
  //   positionOfSubstring.push(array1.index); //Index starts with 0
  // }

  //************ */

  for (const key in result) {
    if (result.hasOwnProperty(key)) {
      let positionOfSubstringTemp = [];
      while ((arraytemp = regex.exec(result[key]))) {
        arraytemp !== null &&
          positionOfSubstringTemp.push(arraytemp.index) &&
          count++; //Index starts with 0
      }
      positionOfSubstringFinal[`${key}`] = positionOfSubstringTemp;
    }
  }

  returnData = {
    positionOfSubstringFinal: positionOfSubstringFinal,
    count: count,
  };
  return returnData;
};

//Function is used for creating table data in listview
export const setTableData = (data, selectedData, ind, scoresSelectedPolicy) => {
  let result = {};
  result.__typename = data?.__typename; // Adding __typename

  //Adding _id
  data?.__typename === typeNames.apps
    ? (result.app_id = data?.app_id)
    : data?.__typename === typeNames.models
    ? (result.model_id = data?.model_id)
    : (result.input_data_table_id = data?.input_data_table_id);

  //Adding name or display_name
  !data.display_name
    ? (result.name = truncateName(data?.name))
    : (result.name = truncateName(data?.display_name));

  //Adding full display name (without truncate) for tooltip
  !data.display_name
    ? (result.originalName = data?.name)
    : (result.originalName = data?.display_name);

  result.display_name = data.display_name;
  result.description = data.description;

  //Adding contributor

  result.contributor = data?.contributor;

  //Adding borderColor
  const konferScore =
    data?.risk_factors_data?.length > 0
      ? data?.risk_factors_data[0]?.konfer_composite_score
      : null;
  let borderColor = "border_color_below_limt";
  if (
    (konferScore <= ScoreValueNew.highest_limit_red_score &&
      konferScore >= ScoreValueNew.lowest_limit_red_score_new) ||
    konferScore === null
  ) {
    borderColor = "border_color_below_limt";
  } else if (
    konferScore >= ScoreValueNew.highest_limit_red_score ||
    konferScore === Number(riskFactorValueCond.ntAplicabl)
  ) {
    borderColor = "border_color_above_limt";
  }
  result.borderColor = borderColor;

  //Adding konferConfidence
  result.konferConfidence =
    data?.risk_factors_data?.length > 0
      ? data?.risk_factors_data[0]?.konfer_composite_score
      : null;

  //Addinh Human Impact
  // data[`${typeNames[selectedData]}s_risk_factors`]?.length > 0
  //   ? (result.humanImpact =
  //       data[`${typeNames[selectedData]}s_risk_factors`][0].value)
  //   : (result.humanImpact = null);
  result.humanImpact = data?.humanImpact;

  //Adding Policy
  result.policyScore = data[scoresSelectedPolicy];

  //Adding version
  if (
    data?.__typename === typeNames.apps ||
    data?.__typename === typeNames.models
  ) {
    result.version = data?.version;
  }

  //Adding Deployment Region
  result.aws_region = data?.aws_region;

  //Adding region

  data[`${typeNames[selectedData]}s_business_units`]?.map((unit, index) => {
    if (index < 1) {
      result.region = unit.business_unit?.region?.name;
      result.region_id = unit.business_unit?.region?.region_id;
    }
  });

  //Adding business unit
  let bunit = "";
  data[`${typeNames[selectedData]}s_business_units`]?.map((unit, index) => {
    if (
      index + 1 ===
      data[`${typeNames[selectedData]}s_business_units`]?.length
    ) {
      bunit = bunit + unit.business_unit.name;
    } else {
      bunit = bunit + `${unit.business_unit.name}, `;
    }
  });
  result.business_unit = bunit;

  if (data?.__typename === typeNames.apps) {
    result.business_unit_id =
      data?.apps_business_units[0]?.business_unit?.business_unit_id;
  }

  //Adding algorithm_type
  if (data?.__typename === typeNames.models) {
    result.algorithm_type = data?.algorithm_type;
  }

  //Adding source_type and usage_type
  if (data?.__typename === typeNames.datasets) {
    result.source_type = data?.source_type;
    result.usage_type = data?.usage_type;
  }

  //Adding created_at
  result.created_at =
    data?.created_at !== undefined && dateFormate(data?.created_at);

  //Adding card_id and card information
  result.card_id = data.card_id;
  if (data.hasOwnProperty("card") && data.card !== null) {
    result.card_latest_version = data.card.latest_version;
    result.card_template_version = data.card.template_version;
  } else {
    result.card_latest_version = null;
    result.card_template_version = null;
  }

  let array1;
  let positionOfSubstring = [];
  let positionOfSubstring1 = [];
  var regex = new RegExp("a", "gi");

  for (const key in result) {
    if (result.hasOwnProperty(key)) {
      while ((array1 = regex.exec(result[key]))) {
        positionOfSubstring.push(array1.index); //Index starts with 0
      }
    }
  }

  return result;
};

const getBorderColor = (element) => {
  const konferConfidence =
    element?.risk_factors_data?.score_data[0]?.konfer_composite_score;
  const konferScore = element?.minScoreValue;
  let borderColor = "border_color_above_limt";
  if (konferScore) {
    if (konferScore > 0 && konferScore <= ScoreValue.lowest_limit_red_score) {
      borderColor = "border_color_below_limt";
    }
  }
  return { konferConfidence, borderColor };
};

export const truncateName = (str) => {
  return str?.length > 20 ? str.substring(0, 20) + "..." : str;
};

export const formattedData = (data, type) => {
  data &&
    data[`${type}`].map((item) => {
      item.risk_factors_data = [
        {
          konfer_composite_score: null,
        },
      ];
      item.humanImpact = null;
      data.confidence_factors.length > 0 &&
        data.confidence_factors.map((factorsData) => {
          if (factorsData[`${type}_id`] === item[`${type}_id`]) {
            if (
              factorsData.score_type_config.score_name === "Konfer Confidence"
            ) {
              item.risk_factors_data = [
                {
                  konfer_composite_score: factorsData.value,
                },
              ];
            } else if (
              factorsData.score_type_config.score_name === "Human Impact"
            ) {
              item.humanImpact = factorsData.value;
            }
          }
        });
    });

  return data;
};

//Below functions is used to generate table row data
export const getRowDataForTable = (
  data,
  selected_data_type,
  scores_selected_policy
) => {
  let temp_rows_arr = [];

  data.length > 0 &&
    data.map((mapData, index) => {
      let temp_row = {};

      if (selected_data_type === tableDataTypes.apps) {
        temp_row.app_id = mapData.app_id;
        temp_row.created_at =
          mapData.created_at === null ? "-" : dateFormate(mapData.created_at);
        temp_row.calculate_score = mapData.calculate_score;
      } else if (selected_data_type === tableDataTypes.models) {
        temp_row.model_id = mapData.model_id;
        temp_row.algorithm_type = mapData.algorithm_type;
        temp_row.created_at =
          mapData.created_at === null ? "-" : dateFormate(mapData.created_at);
      } else if (selected_data_type === tableDataTypes.dataSets) {
        temp_row.input_data_table_id = mapData.input_data_table_id;
        temp_row.source_type = mapData.source_type;
        temp_row.usage_type = mapData.usage_type;
      }
      temp_row.id = index;
      temp_row.__typename = mapData.__typename;
      temp_row.description = mapData.description;
      temp_row.name =
        mapData.display_name === null || mapData.display_name === ""
          ? mapData.name
          : mapData.display_name;
      temp_row.calculate_score = mapData.calculate_score;
      temp_row.konfer_confidence =
        mapData.konfer_confidence === null ||
        mapData.konfer_confidence === Number(riskFactorValueCond.ntAplicabl)
          ? "-"
          : mapData.konfer_confidence;
      temp_row.human_impact =
        mapData.human_impact === null ||
        mapData.human_impact === Number(riskFactorValueCond.ntAplicabl)
          ? "-"
          : mapData.human_impact;

      temp_row.policy_score =
        mapData[scores_selected_policy] === null ||
        mapData[scores_selected_policy] ===
          Number(riskFactorValueCond.ntAplicabl)
          ? "-"
          : mapData[scores_selected_policy];
      temp_row.version =
        mapData.version === null
          ? "-"
          : mapData.hasOwnProperty("version")
          ? mapData.version
          : "-";
      temp_row.aws_region =
        mapData.aws_region === null ? "-" : mapData.aws_region;
      temp_row.regions =
        mapData.regions.length === 0 ? "-" : mapData.regions.toString();
      temp_row.business_units =
        mapData.business_units.length === 0
          ? "-"
          : mapData.business_units.toString();

      temp_row.card_id = mapData.card_id;
      temp_row.card_latest_version =
        mapData.card === null ? null : mapData.card.latest_version;
      temp_row.card_template_version =
        mapData.card === null ? null : mapData.card.template_version;

      if (
        mapData.hasOwnProperty("contributor") &&
        mapData.contributor.length > 0
      ) {
        temp_row.contributor = mapData.contributor;
      }

      temp_row.row_left_border =
        mapData.konfer_confidence === null ||
        mapData.konfer_confidence === Number(riskFactorValueCond.ntAplicabl) ||
        (mapData.konfer_confidence <= ScoreValueNew.highest_limit_red_score &&
          mapData.konfer_confidence >= ScoreValueNew.lowest_limit_red_score_new)
          ? scoreColorsNew.redColr
          : scoreColorsNew.greenColr;

      temp_row.region_id = mapData?.region_id;
      temp_row.bui_id = mapData?.business_unit_ids?.[0]
        ? mapData?.business_unit_ids?.[0]
        : null;

      temp_rows_arr.push(temp_row);
    });

  const filteredArrayWithoutDash = _.filter(
    temp_rows_arr,
    (obj) => obj.konfer_confidence !== "-"
  );
  const filteredArrayWithDash = _.filter(
    temp_rows_arr,
    (obj) => obj.konfer_confidence === "-"
  );
  const filterDataByKonferConfidence = _.orderBy(
    filteredArrayWithoutDash,
    ["konfer_confidence"],
    ["desc"]
  );

  return [...filterDataByKonferConfidence, ...filteredArrayWithDash];
};

//function is used to find wheather the sub string is present in string
export const findPositionOfSubString = (resultData, searchString, colData) => {
  let arraytemp;
  let position_of_substring_final = {};
  let count = 0;
  let returnData;
  var regex = new RegExp(searchString, "gi");

  for (const key in resultData) {
    if (
      resultData.hasOwnProperty(key) &&
      include_columns_for_internal_find.includes(key)
    ) {
      colData.map((colInfo) => {
        if (colInfo.field === key && colInfo.checked === true) {
          let positionOfSubstringTemp = [];
          while ((arraytemp = regex.exec(resultData[key]))) {
            arraytemp !== null &&
              positionOfSubstringTemp.push(arraytemp.index) &&
              count++; //Index starts with 0
          }
          position_of_substring_final[`${key}`] = positionOfSubstringTemp;
        }
      });
    }
  }

  returnData = {
    position_of_substring_final: position_of_substring_final,
    count: count,
  };
  return returnData;
};

//Below function is used to navidate to details page
export const listviewRowClick = (current_row_data) => {
  let link;
  if (current_row_data.__typename === typeNames.apps) {
    link = `/detail/app/${current_row_data.app_id}`;
  }

  if (current_row_data.__typename === typeNames.models) {
    link = `/detail/model/${current_row_data.model_id}`;
  }

  if (current_row_data.__typename === typeNames.datasets) {
    link = `/detail/input_data_table/${current_row_data.input_data_table_id}`;
  }
  return link;
};

//Below function is used to sort data in ascending/descending order as per the column name
export const sortData = (Data, keyName, order) => {
  let sortedData = _.orderBy(Data, keyName, order[0]);

  const sortedDataWithNullValue = sortedData.filter((val) => {
    return val[keyName] == null || val[keyName] === "-";
  });

  const sortedDataWithoutNull = sortedData.filter((val) => {
    return val[keyName] !== null && val[keyName] !== "-";
  });

  return order[0] == "asc"
    ? [...sortedDataWithNullValue, ...sortedDataWithoutNull]
    : [...sortedDataWithoutNull, ...sortedDataWithNullValue];
};

/*Below function is used to calculate the red and green count of assets on the basis of konfer konfidence value.
It sets red when the value is less than or equal to 49 or it is null*/
export const getAssetsCountByScore = (Data) => {
  let redScore = 0;
  let blueScore = 0;

  Data.length > 0 &&
    Data.map((data) => {
      let konferScore = data.konfer_confidence;
      if (
        konferScore === "-" ||
        (konferScore >= ScoreValueNew.lowest_limit_red_score_new &&
          konferScore <= ScoreValueNew.highest_limit_red_score)
      ) {
        redScore = redScore + 1;
      } else {
        blueScore = blueScore + 1;
      }
    });
  const res = {
    redScore,
    blueScore,
  };
  return res;
};
