export const awsConfigRequirment = [
  "AWS Access Key",
  "AWS Secret Key",
  "Region name separated by (,)",
  "Extraction Start Date Time",
  "Schedule Start Date",
  "Schedule Time of Day",
  "Schedule Frequency",
];

export const azureConfigRequirment = [
  // "AWS Access Key",
  // "AWS Secret Key",
  // "Region name separated by (,)",
  // "Extraction Start Date Time",

  "Workspace Name",
  "Resource Group",
  "Client Secret Key",
  "Tenant ID",
  "Client ID",
  "Subscription ID",

  "Schedule Start Date",
  "Schedule Time of Day",
  "Schedule Frequency",
];

export const AdminConfigurationTest = {
  hyphen: "-",
};

export const AssignBUTypes = {
  apps: "apps",
  models: "models",
  input_data_tables: "input_data_tables",
};

export const AssignBUUniqueKeys = {
  app: "apps",
  model: "models",
  inputDataTable: "input_data_tables",
};

export const AssignBUKeyName = {
  apps: "app_id",
  models: "model_id",
  input_data_tables: "input_data_table_id",
  input_data_column: "input_data_column_id",
  contributor: "contributor_id",
};

export const AssignBUDataTypes = {
  apps: "app",
  models: "model",
  input_data_tables: "input_data_table",
};

export const adminRiskFactor = {
  HumanImpact: "Human Impact",
};
