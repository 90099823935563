import { PolicyNameConst } from "./policyConstants";

export const db_keys_constaants = {
  //General
  updatedAt: "Updated At",
  value: "Value",
  quarter_id: "Quarter Id",
  summary_value: "Summary Value",
  name: "Name",
  display_name: "Display Name",
  description: "Description",
  title: "Title",
  version: "Version",
  id: "Id",
  createdBy: "Created By",
  createdAt: "Created At",
  isActive: "Is Active",
  app: "Apploications",
  model: "Models",
  input_data_table: "Datasets",

  //App
  app_id: "App Id",
  app_name: "App Name",
  created_at: "Created At",
  aws_region: "Aws Region",
  source: "Source",
  calculate_score: "Calculate Score",
  app_status: "App Status",

  // Model
  model_id: "Model Id",
  model_name: "Model Name",
  model_type: "Model Type",
  algorithm_type: "Algorithm Type",
  external_model_dependency: "External Model Dependency",
  in_use: "In Use",
  container: "Container",
  dependent_model: "Dependent Model",
  model_registered: "Model Registered",
  resolution_time: "Resolution Time",
  model_x_trainingjob: "Model X Training Job",

  //Input Data Table
  input_data_table_id: "Input Data Table Id",
  input_data_table_name: "Dataset Name",
  is_gdpr_compliant: "Is Gdpr Compliant",
  source_type: "Source type",
  usage_type: "Usage Type",
  data_source: "Data Source",

  //Contributor
  contributor_id: "Contributor Id",
  role: "Role",
  email: "Email",
  password: "Password",
  first_login: "First Login",
  last_login: "Last Login",
  createdBy_contributor_id: "CreatedBy Contributor Id",
  terms_accepted: "Terms Accepted",
  contributed_apps: "Contributed Apps",
  contributed_input_data_tables: "Contributed Datasets",
  contributed_models: "Contributed Models",

  //Roles
  roles_id: "Roles Id",

  //Roles Access
  read: "Read",
  write: "Write",
  update: "Update",
  delete: "Delete",
  execution: "Execution",

  //Scores Type Config
  score_id: "Score Id",
  score_name: "Score Name",
  is_applies_to_app: "Is Applies To App",
  is_applies_to_model: "Is Applies To Model",
  is_applies_to_input_data_table: "Is Applies To Dataset",
  url: "Url",
  status: "Status",
  policy: `${PolicyNameConst.policy}`,
  Policy: `${PolicyNameConst.policy}`,

  //Cards
  subsection_id: "Subsection Id",
  display_order: "Display Order",
  section_id: "Section Id",
  text_data: "Text Data",
  text_data_id: "Text Data Id",
  file_name: "File Name",
  extension: "Extension",
  file_data: "File Data",
  card_id: "Card Id",
  card_type: "Card Type",

  latest_version: "Latest Version",
  template_version: "Template Version",
  review_statuses: "Review Statuses",
  generate_response_status: "Generate Response Status",

  //Questions
  question: "Question",
  text: "Text",
  is_visible: "Is Visible",
  is_bold: "Is Bold",
  is_italic: "Is Italic",
  is_required: "Is Required",
  is_underline: "Is Underline",
  template_question_id: "Template Question Id",
  policy_tag: `${PolicyNameConst.policy} Tag`,
  answer_type: "Answer Type",
  desired_response: "Desired Response",
  dataset_data_id: "Dataset Data Id",
  evidence_snippet: "Evidence Snippet",
  generated_answer: "Generated Answer",
  not_answered_count: "Not Answered Count",

  //Region, Business unit, Organization
  region_id: "Region Id",
  organization_id: "Organization Id",
  business_unit_id: "Business Unit Id",
  business_unit_type: "Business Unit Type",
  show_org_name: "Show Org Name",

  //confidence_factors_config (Risk Factor)
  confidence_factors_config_id: "Confidence Factors Config Id",
  factor_name: "Factor Name",
  app_weightage: "App Weightage",
  model_weightage: "Model Weightage",
  input_data_table_weightage: "Dataset Weightage",
  konfer_confidence: "konfer Confidence",
  human_impact: "Human Impact",

  //annotation
  annotation_id: "Annotation Id",
  hyper_parameter_id: "Hyper Parameter Id",
  text_name: "Text Name",
  type: "Type",
  type_id: "Type Id",

  //aws_sm_endpoint_details
  endpoint_id: "Endpoint Id",
  endpoint_name: "Endpoint Name",
  endpoint_arn: "Endpoint Arn",
  data_capture_config_enabled: "Data Capture Config Enabled",
  aws_region: "Aws Region",

  //aws_sm_poject_details
  project_id: "Project Id",
  aws_project_id: "Aws Project Id",
  aws_project_arn: "Aws Project Arn",

  //details_editable
  details_editable_id: "Details Editable Id",
  table_name: "Table Name",
  field_name: "Field Name",
  is_disabled: "Is Disabled",
};
