import React from "react";
import { riskFactorValueCond } from "../../../constants/riskFactorMonth";
import "./ConfidenceFactorsListview.css";
import { Confidence_Factor_Name } from "../../../constants/complianceConstants";

const ConfidenceFactorsListview = ({ listviewConFactorsData }) => {
  return (
    <div className="confidence-factors-listview">
      <div className="average">
        {/* //avgSummary */}
        <span>
          {`${
            listviewConFactorsData?.avgSummary?.value === null ||
            listviewConFactorsData?.avgSummary?.value ===
              riskFactorValueCond.ntAplicabl ||
            listviewConFactorsData?.avgSummary?.value === undefined
              ? "-"
              : listviewConFactorsData?.avgSummary?.value
          } /100`}
        </span>
      </div>
      <div className="factors-list">
        {listviewConFactorsData &&
          listviewConFactorsData !== undefined &&
          listviewConFactorsData?.confidence_factor?.length > 0 &&
          listviewConFactorsData?.confidence_factor?.map((item, index) => {
            return (
              Confidence_Factor_Name.includes(item.riskName) && (
                <div key={index} className="factors-info">
                  <span className="factors-info-name">
                    {item.riskName}
                    {item.value !== null &&
                      item.value !== riskFactorValueCond.ntAplicabl &&
                      ":"}{" "}
                  </span>
                  <span className="factors-info-value">
                    {item.value !== null &&
                    item.value !== riskFactorValueCond.ntAplicabl
                      ? item.value
                      : "-"}
                  </span>
                </div>
              )
            );
          })}
      </div>
    </div>
  );
};

export default ConfidenceFactorsListview;
