import { FormControl, Input, InputBase, NativeSelect } from "@mui/material";
import { Box } from "@mui/system";
import { useAtom } from "jotai";
import { useLocation } from "react-router-dom";
import React, { useEffect, useState } from "react";
import {
  useAdminConfigurationLoadData,
  useAdminConfigurationExtract,
  useAdminConfigurationSetConfig,
} from "../../api/createApiFunctions";
import {
  isSidebar,
  extractStarted,
  azureExtractionInProgress,
  authUser,
  accessDataByRoleAtom,
} from "../../atom/jotai";
import { azureConfigRequirment } from "../../constants/adminConfigConstant";
import "./AdminAzureConfiguration.css";
import { toast } from "react-toastify";

import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useAdminConfigurationGetConfig } from "../../api/readApiFunctions";

import { CircularProgress } from "@mui/material";
import { analyticServices } from "../../services/analyticServices";
import { useResetDatabase } from "../../api/deleteApiFunctions";
import { adminConfigurationPage } from "../../constants/toasterMsgConstants";
import { isAccessProvided } from "../../services/assetManagementService";
import {
  accessConstants,
  actionConstants,
  moduleConstants,
  subModuleConstants,
} from "../../constants/accessManagementConstants";

const AdminAzureConfiguration = () => {
  const [value, setValue] = React.useState(new Date());

  const [workspaceName, setWorkspaceName] = useState("");
  const [resourceGroup, setResourceGroup] = useState("");
  const [clientSecretKey, setClientSecretKey] = useState("");
  const [tenantID, setTenantID] = useState("");
  const [clientID, setClientID] = useState("");
  const [subscriptionID, setSubscriptionID] = useState("");
  const [accessKey, setAccessKey] = useState("");

  const [secretKey, setSecretKey] = useState("");
  const [regionName, setRegionName] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [sidebar] = useAtom(isSidebar);
  const [extractStat, setExtractStat] = useAtom(extractStarted);
  const [azureExtractInprogress, setAzureExtractInprogress] = useAtom(
    azureExtractionInProgress
  );

  const [azureTime, setAzureTime] = useState("");
  const [azureDate, setAzureDate] = useState("");

  const [extractionStartDateTime, setExtractionStartDateTime] = useState("");

  const ariaLabel = { "aria-label": "description" };
  const location = useLocation();
  //global states maintain in jotai
  const [user] = useAtom(authUser);

  const { data: getConfigRes, isLoading: getConfigLoading } =
    useAdminConfigurationGetConfig(
      user?.contributor_id,
      "azure",
      location.pathname.split("/")[1] == "discovery" &&
        location.pathname.split("/")[1] !== undefined
        ? true
        : false
    );

  const [showAccessKey, setShowAccessKey] = useState(false);
  const [showSubscriptionID, setShowSubscriptionID] = useState(false);
  const [showClientID, setShowClientID] = useState(false);
  const [showTenantID, setShowTenantID] = useState(false);
  const [showClientSecretKey, setShowClientSecretKey] = useState(false);
  const [accessDataByRole, setAccessDataByRole] = useAtom(accessDataByRoleAtom);

  const {
    mutate: resetDatabase,
    data: resetResult,
    isSuccess: resetIsSuccess,
    error: resetError,
    isError: resetIsError,
    isLoading: resetLoading,
  } = useResetDatabase();
  const {
    mutate: loadDataRes,
    data: loadResult,
    isLoading: LoadDataLoading,
  } = useAdminConfigurationLoadData();
  const {
    mutate: extractData,
    data: extractRes,
    isLoading: extractLoading,
    status: extractStatus,
    error: extractError,
    isError: extractIsError,
  } = useAdminConfigurationExtract();
  const {
    mutate: configData,
    data: configRes,
    error: azureConfigErrorMessage,
    isLoading: configLoading,
  } = useAdminConfigurationSetConfig();

  const resetDb = () => {
    resetDatabase({
      source: "azure",
      subModInvoker: "microsoft_azure_ml_reset",
    });
    analyticServices(`Data is reset`);
  };

  const LoadData = () => {
    loadDataRes();
  };

  const extractNow = () => {
    setExtractStat(true);
    setAzureExtractInprogress(true);
    const payload = {
      tenant_id: tenantID,
      client_id: clientID,
      client_secret: clientSecretKey,
      ws_name: workspaceName,
      subscription_id: subscriptionID,
      resource_group: resourceGroup,
      source: "azure",
      schedule_start_date: azureDate,
      schedule_time_day: azureTime,
      contributor_id: user?.contributor_id,
      // schedule_frequency,
    };
    extractData(payload);
    analyticServices(`Data is extracted`);
  };

  const setConfig = () => {
    if (
      workspaceName !== "" &&
      resourceGroup != "" &&
      clientSecretKey != "" &&
      tenantID != "" &&
      clientID != "" &&
      subscriptionID != ""
    ) {
      if (azureDate.split("-")[0].length >= 5) {
        toast.error("Year should be 4 digit");
      } else {
        const payload = {
          tenant_id: tenantID,
          client_id: clientID,
          client_secret: clientSecretKey,
          ws_name: workspaceName,
          subscription_id: subscriptionID,
          resource_group: resourceGroup,
          source: "azure",
          schedule_start_date: azureDate,
          schedule_time_day: azureTime,
          // schedule_frequency,
          schedule_frequency: "Once",
        };

        configData(payload);
        analyticServices(`Configuration is set`);
      }
    } else {
      toast.error("please enter all mandatory fields");
    }
  };

  useEffect(() => {
    if (getConfigRes) {
      setTenantID(getConfigRes.tenant_id);
      setClientID(getConfigRes.client_id);
      setClientSecretKey(getConfigRes.client_secret);
      setWorkspaceName(getConfigRes.ws_name);
      setSubscriptionID(getConfigRes.subscription_id);
      setResourceGroup(getConfigRes.resource_group);
      setAzureTime(getConfigRes.schedule_time_day);
      setAzureDate(getConfigRes.schedule_start_date);
    }
  }, [getConfigRes]);

  useEffect(() => {
    if (extractStatus === "success" || extractStatus === "error") {
      setExtractStat(false);
      setAzureExtractInprogress(false);
    }
  }, [extractStatus]);

  useEffect(() => {
    loadResult?.data?.data?.insert_app?.returning?.length > 0
      ? loadResult && toast.success(`Data loaded`)
      : loadResult &&
        toast.error(`Could not complete your request, please try again`);
  }, [loadResult?.data]);

  useEffect(() => {
    if (extractRes) {
      toast.success(extractRes?.data?.message);
    }
  }, [extractRes]);

  useEffect(() => {
    if (extractIsError) {
      toast.error(extractError?.response?.data?.message);
    }
  }, [extractError]);

  useEffect(() => {
    if (configRes) {
      configRes?.data?.success === true
        ? toast.success(configRes?.data?.message)
        : toast.error(configRes?.data?.message);
    }
  }, [configRes]);

  useEffect(() => {
    if (azureConfigErrorMessage) {
      toast.error(azureConfigErrorMessage?.response?.data?.error);
    }
  }, [azureConfigErrorMessage]);

  // handle reset notifcation
  useEffect(() => {
    if (resetIsSuccess) {
      toast.success(adminConfigurationPage.resetDBSucess);
    }
  }, [resetResult]);

  useEffect(() => {
    if (resetIsError) {
      toast.error(resetError?.response?.data?.error);
    }
  }, [resetIsError]);

  useEffect(() => {
    setIsLoading(
      LoadDataLoading ||
        extractLoading ||
        resetLoading ||
        configLoading ||
        getConfigLoading
    );
  }, [
    LoadDataLoading,
    extractLoading,
    resetLoading,
    configLoading,
    getConfigLoading,
  ]);

  return (
    <div className="azure_container_extract">
      <Box
        id="sageMakerBoxContainer"
        sx={{ position: "relative", cursor: isLoading && "not-allowed" }}
        className="AdminConfiguration_container"
      >
        {isLoading && (
          <Box
            id="sageMakerLoadingBox"
            sx={{
              display: "flex",
              position: "absolute ",
              marginTop: "20%",
              marginLeft: "50%",
            }}
          >
            <CircularProgress />
          </Box>
        )}
        <Box>
          {azureConfigRequirment.map((awsConfigType, index) => {
            return (
              <>
                <p
                  className="AdminConfiguration_container_heading"
                  id={`${awsConfigType}`}
                >
                  {awsConfigType}
                  <span style={{ color: "red", marginLeft: "5px" }}>
                    {index < 6 && `*`}
                  </span>
                </p>
              </>
            );
          })}
        </Box>

        <Box className="AdminConfiguration_data" id="smData">
          <Input
            id="smInputRegionKey"
            placeholder={"Enter Workspace Name"}
            className="AdminConfiguration_data_item"
            sx={{
              "&::after": {
                borderBottom: "none",
              },
            }}
            text="text"
            value={workspaceName}
            inputProps={ariaLabel}
            onChange={(e) => setWorkspaceName(e.target.value)}
            //error={workspaceName === ""}
          />
          <Input
            id="smInputRegionKey"
            placeholder={"Enter Resource Group"}
            className="AdminConfiguration_data_item"
            sx={{
              "&::after": {
                borderBottom: "none",
              },
            }}
            value={resourceGroup}
            text="text"
            inputProps={ariaLabel}
            onChange={(e) => setResourceGroup(e.target.value)}
            // error={resourceGroup === ""}
          />
          <div
            className="access_key_input_div"
            // style={
            //   clientSecretKey === "" ? { borderBottom: "2px solid red" } : {}
            // }
          >
            <InputBase
              id="smInputAccessKey"
              inputProps={ariaLabel}
              placeholder={"Enter Client Secret Key"}
              type={showClientSecretKey ? "text" : "password"}
              className="access_key_input"
              value={clientSecretKey}
              onChange={(e) => setClientSecretKey(e.target.value)}
            />
            {showClientSecretKey ? (
              <VisibilityOff
                id="visibilityOff"
                fontSize="small"
                className="visibility_icon"
                onClick={() => {
                  setShowClientSecretKey(!showClientSecretKey);
                }}
              />
            ) : (
              <Visibility
                id="visibilityOn"
                fontSize="small"
                className="visibility_icon"
                onClick={() => {
                  setShowClientSecretKey(!showClientSecretKey);
                }}
              />
            )}
          </div>{" "}
          <div
            className="access_key_input_div"
            // style={tenantID === "" ? { borderBottom: "2px solid red" } : {}}
          >
            <InputBase
              id="smInputAccessKey"
              inputProps={ariaLabel}
              placeholder={"Enter Tenant ID"}
              value={tenantID}
              type={showTenantID ? "text" : "password"}
              className="access_key_input"
              // defaultValue={`${getConfigRes?.aws_access_key || ""}`}
              onChange={(e) => setTenantID(e.target.value)}
            />
            {showTenantID ? (
              <VisibilityOff
                id="visibilityOff"
                fontSize="small"
                className="visibility_icon"
                onClick={() => {
                  setShowTenantID(!showTenantID);
                }}
              />
            ) : (
              <Visibility
                id="visibilityOn"
                fontSize="small"
                className="visibility_icon"
                onClick={() => {
                  setShowTenantID(!showTenantID);
                }}
              />
            )}
          </div>{" "}
          <div
            className="access_key_input_div"
            // style={clientID === "" ? { borderBottom: "2px solid red" } : {}}
          >
            <InputBase
              id="smInputAccessKey"
              inputProps={ariaLabel}
              placeholder={"Enter Client ID"}
              value={clientID}
              type={showClientID ? "text" : "password"}
              className="access_key_input"
              // defaultValue={`${getConfigRes?.aws_access_key || ""}`}
              onChange={(e) => setClientID(e.target.value)}
            />
            {showClientID ? (
              <VisibilityOff
                id="visibilityOff"
                fontSize="small"
                className="visibility_icon"
                onClick={() => {
                  setShowClientID(!showClientID);
                }}
              />
            ) : (
              <Visibility
                id="visibilityOn"
                fontSize="small"
                className="visibility_icon"
                onClick={() => {
                  setShowClientID(!showClientID);
                }}
              />
            )}
          </div>{" "}
          <div
            className="access_key_input_div"
            // style={
            //   subscriptionID === "" ? { borderBottom: "2px solid red" } : {}
            // }
          >
            <InputBase
              id="smInputAccessKey"
              inputProps={ariaLabel}
              value={subscriptionID}
              placeholder={"Enter Subscription ID"}
              type={showSubscriptionID ? "text" : "password"}
              className="access_key_input"
              // defaultValue={`${getConfigRes?.aws_access_key || ""}`}
              onChange={(e) => setSubscriptionID(e.target.value)}
            />
            {showSubscriptionID ? (
              <VisibilityOff
                id="visibilityOff"
                fontSize="small"
                className="visibility_icon"
                onClick={() => {
                  setShowSubscriptionID(!showSubscriptionID);
                }}
              />
            ) : (
              <Visibility
                id="visibilityOn"
                fontSize="small"
                className="visibility_icon"
                onClick={() => {
                  setShowSubscriptionID(!showSubscriptionID);
                }}
              />
            )}
          </div>
          <Input
            className="AdminConfiguration_data_item"
            id="date"
            label="Birthday"
            type="date"
            value={azureDate}
            onChange={(ev) => setAzureDate(ev.target.value)}
            sx={{ width: 250 }}
            InputLabelProps={{
              shrink: true,
            }}
            error={azureDate.split("-")[0].length >= 5}
          />
          <Input
            className="AdminConfiguration_data_item"
            id="time"
            label="Alarm clock"
            type="time"
            value={azureTime}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={(ev) => setAzureTime(ev.target.value)}
            sx={{ width: 250 }}
          />
          <Box
            className="AdminConfiguration_data_item"
            sx={{ minWidth: 120 }}
            id="smScheduleData"
          >
            <FormControl fullWidth>
              <NativeSelect
                inputProps={{
                  name: "age",
                  id: "uncontrolled-native",
                }}
              >
                <option value={20}>Once</option>
                <option value={30}>Monthly</option>
                <option value={"Daily"}>Daily</option>
              </NativeSelect>
            </FormControl>
          </Box>
        </Box>
      </Box>

      <Box
        id="smActionButtonBox"
        className={
          !sidebar
            ? "admin_azur_configration_btn"
            : "admin_azur_configration_btn_open_sidebar"
        }
      >
        {isAccessProvided(
          "extraction",
          "microsoft_azure_ml",
          null,
          "write",
          accessDataByRole
        ) && (
          <button
            id="smSetConfigButton"
            className={` ${
              isLoading
                ? "userlist-createNewUser-button_disable"
                : "userlist-createNewUser-button"
            }`}
            onClick={() => !isLoading && setConfig()}
          >
            Set Config
          </button>
        )}
        {isAccessProvided(
          "extraction",
          "microsoft_azure_ml",
          null,
          "write",
          accessDataByRole
        ) && (
          <button
            id="smSetScheduleButton"
            className={` ${
              isLoading
                ? "userlist-createNewUser-button_disable"
                : "userlist-createNewUser-button_disable"
            }`}
          >
            Set Schedule
          </button>
        )}
        {process.env.REACT_APP_ENABLE_RESET_DB === "true" &&
          isAccessProvided(
            "extraction",
            "microsoft_azure_ml_reset",
            "reset_button",
            "execution",
            accessDataByRole
          ) && (
            <button
              id="smResetDatabaseButton"
              className={` ${
                extractStat
                  ? "userlist-createNewUser-button_disable"
                  : "userlist-createNewUser-button"
              }`}
              onClick={!isLoading && resetDb}
              disabled={extractStat}
            >
              Reset Database
            </button>
          )}
        {isAccessProvided(
          moduleConstants.extraction,
          subModuleConstants.extract,
          actionConstants.extraction_button,
          accessConstants.execution,
          accessDataByRole
        ) && (
          <button
            id="smExtractNowButton"
            className={` ${
              extractStat
                ? "userlist-createNewUser-button_disable"
                : "userlist-createNewUser-button"
            }`}
            onClick={() => !isLoading && extractNow()}
            disabled={extractStat}
          >
            Start
          </button>
        )}
      </Box>
    </div>
  );
};

export default AdminAzureConfiguration;
