import React from "react";
import Box from "@mui/material/Box";
import Tabs, { tabsClasses } from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { color, lineHeight } from "@mui/system";
import { buttonBaseClasses } from "@mui/material";
import { useEffect } from "react";

/**
 * Component : SectionTabs
 *
 * Description : This component is responsible for showing section tabs.
 * For this Mui Tabs is use.
 *
 * Props : sectionData - card sections options
           selectedSection - state for showing the selected section,
           setSelectedSection - setter function for setting selected section
           handleSelectedSection - call back te set the section
 *  */

const SectionTabs = ({
  sectionData,
  setSelectedSection,
  selectedSection,
  handleSelectedSection,
}) => {
  const [value, setValue] = React.useState(0);

  useEffect(() => {
    if (sectionData?.length > 0) {
      sectionData.map((secData, index) => {
        if (selectedSection.title === secData.title) {
          setSelectedSection(secData);
          setValue(index);
        }
      });
    }
  }, [sectionData]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box
      sx={{
        width: "100%",
        bgcolor: "#E7EDF0",
        borderRight: "0.25px solid #6D8291",
        borderLeft: "0.25px solid #6D8291",
      }}
    >
      <Tabs
        value={value}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons
        aria-label="visible arrows tabs example"
        sx={{
          [`& .${tabsClasses.scrollButtons}`]: {
            "&.Mui-disabled": { opacity: 1.3 },
            margin: "-8px",
            background: "#FFFFFF",
            border: "1px solid #6D8291",
            boxShadow: "-1px 0px 4px rgba(0, 0, 0, 0.25);",
          },
          [`& .${tabsClasses.indicator}`]: {
            backgroundColor: "#3F5667;",
          },

          [`& .${buttonBaseClasses.root}`]: {
            color: "#6D8291;",
            fontFamily: "Lato",
            fontStyle: " normal",
            fontWeight: 500,
            fontSize: {
              xs: "15px",
              sm: "15px",
              md: "17px",
              lg: "17px",
              xl: "18px",
            },
            margin: "0px -4px",
            textTransform: "unset",
            "&.Mui-selected": {
              color: "#3F5667",
              background: "#FFFFFF",
              fontWeight: 700,
              border: "0.5px solid #6D8291",
              marginLeft: "0px",
            },
          },
        }}
      >
        {sectionData.map((section, index) => {
          return (
            <Tab
              key={index}
              label={`${section.title}`}
              onClick={() => {
                // setSelectedSection(section);
                handleSelectedSection(section);
              }}
            />
            // )
          );
        })}
      </Tabs>
    </Box>
  );
};

export default SectionTabs;
