import React from "react";
import {
  Button,
  CircularProgress,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";

import MoreVertIcon from "@mui/icons-material/MoreVert";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import HighLighter from "../Highlighter/HightLigthter";
import { include_columns_for_internal_find } from "../../constants/listviewConstants";
import WarningIconForCard from "../../assets/images/listview/WarningIconForCard.svg";
import MuiSelectForPolicy from "../MuiSelect/MuiSelectForPolicy";
import { useNavigate } from "react-router-dom";
import { listviewTableTooltip } from "../../constants/tooltipConstants";
import { isAccessProvided } from "../../services/assetManagementService";
import { useAtom } from "jotai";
import { accessDataByRoleAtom } from "../../atom/jotai";
import {
  accessConstants,
  actionConstants,
  moduleConstants,
  subModuleConstants,
} from "../../constants/accessManagementConstants";

/**
 * Component : MuiTable
 *
 * Description : This component is responsible for displaying mui table. 
 * It also highlights data if we find something is searchbox.
 * As per the column keys, it provide the functionality.

props: column data, row data, all the handle functions for the appropriate functionality
 */

const MuiTable = ({
  table_columns_data,
  table_rows_data,
  handleManageColumns,
  find_element,
  handleRowClick,
  handleCardButton,
  handleSort,
  handleSelectPolicy,
  policy_data,
  scores_selected_policy,
  searchText,
  page,
  handleScreInclusionColumnClk,
  isScrIncLoading,
  rowIdWhrScoreIncClicked,
}) => {
  let assetTableHeight =
    window.innerHeight <= 700
      ? 370
      : window.innerHeight <= 760
      ? 410
      : window.innerHeight <= 900
      ? 510
      : 680;

  const profileTableHeight =
    window.innerHeight <= 700
      ? 250
      : window.innerHeight <= 760
      ? 310
      : window.innerHeight <= 840
      ? 348
      : window.innerHeight <= 900
      ? 484
      : 484;

  const nlpTableHeight =
    window.innerHeight <= 700
      ? 445
      : window.innerHeight <= 760
      ? 500
      : window.innerHeight <= 800
      ? 586
      : window.innerHeight <= 850
      ? 660
      : window.innerHeight <= 910
      ? 690
      : 750;

  const [accessDataByRole, setAccessDataByRole] = useAtom(accessDataByRoleAtom);

  const navigate = useNavigate();

  return (
    <Paper
      sx={{
        overflowX: "auto",
        border: "1px solid #6D8291",
        borderRadius: "5px 5px 5px 8px",
      }}
    >
      <TableContainer
        sx={
          page === "profile"
            ? { maxHeight: profileTableHeight }
            : page === "nlp"
            ? { maxHeight: nlpTableHeight }
            : { maxHeight: assetTableHeight }
        }
      >
        <Table stickyHeader>
          <TableHead
            sx={{
              "& .MuiTableCell-root": {
                background: " #DDE6EA",
                fontFamily: "var( --fontFamilyText) !important",
                fontSize: "15px",
                fontWeight: "600",
                lineHeight: "1rem",
                "@media screen and (max-height: 780px)": {
                  fontSize: "12px",
                },
              },
            }}
          >
            <TableRow>
              {table_columns_data.map((column) => {
                return (
                  column.checked === true && (
                    <TableCell
                      style={{
                        minWidth: column.minWidth,
                        padding: "8px 5px",
                      }}
                    >
                      {column.sortable === false ? (
                        column.dropDown === false ? (
                          <div
                            style={{
                              width: "min-content",
                            }}
                          >
                            {column.headerName}
                          </div>
                        ) : (
                          <div>
                            <MuiSelectForPolicy
                              minWidthVal={80}
                              data={policy_data && policy_data}
                              value={scores_selected_policy}
                              handleChange={handleSelectPolicy}
                            />
                          </div>
                        )
                      ) : column.dropDown === false ? (
                        <div
                          style={{
                            display: "flex",
                          }}
                        >
                          <span> {column.headerName}</span>
                          <IconButton
                            sx={{
                              padding: "0px",
                              marginTop:
                                "-5px" /** For the demo purpose added marginTop */,
                            }}
                            onClick={() => {
                              handleSort(
                                column.field,
                                !column.descending,
                                column.descending === false ? "desc" : "asc"
                              );
                            }}
                          >
                            {column.descending === false ? (
                              <ArrowUpwardIcon />
                            ) : (
                              <ArrowDownwardIcon />
                            )}
                          </IconButton>
                        </div>
                      ) : (
                        <div
                          style={{
                            display: "flex",
                          }}
                        >
                          <MuiSelectForPolicy
                            minWidthVal={80}
                            data={policy_data && policy_data}
                            value={scores_selected_policy}
                            handleChange={handleSelectPolicy}
                          />
                          <IconButton
                            sx={{ padding: "0px" }}
                            onClick={() => {
                              handleSort(
                                column.field,
                                !column.descending,
                                column.descending === false ? "desc" : "asc"
                              );
                            }}
                          >
                            {column.descending === false ? (
                              <ArrowUpwardIcon />
                            ) : (
                              <ArrowDownwardIcon />
                            )}
                          </IconButton>
                        </div>
                      )}
                    </TableCell>
                  )
                );
              })}
              {page !== "nlp" && (
                <TableCell
                  width={1}
                  style={{ position: "sticky", padding: "8px 5px" }}
                >
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <span></span>
                    <IconButton
                      onClick={(e) => handleManageColumns(e)}
                      disabled={find_element !== "" && find_element !== null}
                      sx={{ padding: "0px", marginRight: "-5px" }}
                    >
                      <MoreVertIcon />
                    </IconButton>
                  </div>
                </TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody
            sx={{
              "& .MuiTableCell-root": {
                background: " #DDE6EA",
                fontFamily: "var( --fontFamilyText) !important",
                fontSize: "15px",
                fontWeight: "500",
                background: "#ffffff",
                lineHeight: "1rem",
                "@media screen and (max-height: 780px)": {
                  fontSize: "12px",
                },
              },
            }}
          >
            {table_rows_data.map((row, index) => {
              return (
                <TableRow key={index} id={`${index}scrollToHighlighter`}>
                  {table_columns_data.map((column) => {
                    const value = row[column.field];
                    if (column.field === "profile") {
                      return (
                        <TableCell style={{ padding: "8px 5px" }}>
                          <Button
                            sx={{
                              border: " 0.5px solid #6D8291",
                              boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)",
                              borderRadius: "20px",
                              padding: "3px 28px",
                              textTransform: "none",
                              position: "relative",
                              color: "#595959",
                            }}
                            onClick={(e) => {
                              navigate(
                                `/compliance-result/compliance-summary/${searchText}/${row.region_id}/${row.bui_id}/${row.app_id}`
                              );
                            }}
                          >
                            <span
                              style={{
                                marginLeft: "-2px",
                                marginTop: "1px",
                              }}
                            >
                              Profile
                            </span>
                          </Button>
                        </TableCell>
                      );
                    }
                    if (column.field === "calculate_score" && page !== "nlp") {
                      return (
                        column.checked === true && (
                          <TableCell style={{ padding: "8px 5px" }}>
                            {isScrIncLoading &&
                            rowIdWhrScoreIncClicked.includes(row.app_id) ? (
                              <CircularProgress />
                            ) : (
                              <div
                                className="scoreInclusionCont"
                                id="scoreInclusionButtonsDiv"
                              >
                                <Tooltip
                                  title={listviewTableTooltip.scoreInclude}
                                  followCursor
                                >
                                  <div
                                    id="scoreInclusionButton"
                                    className={
                                      row.calculate_score
                                        ? "scoreInclusionBtn scoreInclusionActive"
                                        : "scoreInclusionBtn"
                                    }
                                    onClick={() => {
                                      if (
                                        row.calculate_score !== true &&
                                        isAccessProvided(
                                          "score_inclusion",
                                          "crud",
                                          null,
                                          "write",
                                          accessDataByRole
                                        )
                                      ) {
                                        handleScreInclusionColumnClk(
                                          true,
                                          index
                                        );
                                      }
                                    }}
                                  >
                                    <span
                                      className={
                                        row.calculate_score
                                          ? "scoreInclusionBtnTextActive"
                                          : " scoreInclusionBtnText"
                                      }
                                    >
                                      YES
                                    </span>
                                  </div>
                                </Tooltip>

                                <Tooltip
                                  title={listviewTableTooltip.scoreExclude}
                                  followCursor
                                >
                                  <div
                                    id="resultGraphviewButton"
                                    className={
                                      row.calculate_score
                                        ? "scoreInclusionBtn"
                                        : "scoreInclusionBtn scoreInclusionActive"
                                    }
                                    onClick={() => {
                                      if (
                                        row.calculate_score !== false &&
                                        isAccessProvided(
                                          "score_inclusion",
                                          "crud",
                                          null,
                                          "write",
                                          accessDataByRole
                                        )
                                      ) {
                                        handleScreInclusionColumnClk(
                                          false,
                                          index
                                        );
                                      }
                                    }}
                                  >
                                    <span
                                      className={
                                        row.calculate_score
                                          ? "scoreInclusionBtnText"
                                          : "scoreInclusionBtnTextActive"
                                      }
                                    >
                                      NO
                                    </span>
                                  </div>
                                </Tooltip>
                              </div>
                            )}
                          </TableCell>
                        )
                      );
                    }

                    if (column.field === "calculate_score" && page === "nlp") {
                      return (
                        <TableCell style={{ padding: "8px 5px" }}>
                          {value}
                        </TableCell>
                      );
                    }

                    if (column.field === "card") {
                      return (
                        <TableCell style={{ padding: "8px 5px" }}>
                          <Button
                            sx={{
                              border: " 0.5px solid #6D8291",
                              boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)",
                              borderRadius: "20px",
                              padding: "3px 28px",
                              textTransform: "none",
                              position: "relative",
                            }}
                            onClick={(e) => handleCardButton(e, row)}
                          >
                            <span
                              style={{
                                marginLeft: "-2px",
                                marginTop: "1px",
                              }}
                              onClick={(e) => handleCardButton(e, row)}
                            >
                              <span
                                style={{
                                  marginLeft: "-2px",
                                  marginTop: "1px",
                                }}
                              >
                                Card
                              </span>
                              {row.card_latest_version !== null &&
                                row.card_template_version !== null &&
                                row.card_latest_version >
                                  row.card_template_version && (
                                  <img
                                    src={WarningIconForCard}
                                    alt=""
                                    className="warning_icon"
                                  />
                                )}
                            </span>
                          </Button>
                        </TableCell>
                      );
                    }

                    return (
                      column.checked === true && (
                        <TableCell
                          key={column.field}
                          style={
                            column.field === "name"
                              ? {
                                  borderLeft: `6px solid ${row.row_left_border}`,
                                  padding: "8px 5px",
                                }
                              : { padding: "8px 5px" }
                          }
                          onClick={(e) => {
                            handleRowClick(e, row);
                          }}
                        >
                          {find_element === null ? (
                            value
                          ) : include_columns_for_internal_find.includes(
                              column.field
                            ) ? (
                            <HighLighter
                              text={value}
                              highlight={find_element}
                              columnName={column.field}
                              index={index}
                              findElement={find_element}
                            />
                          ) : (
                            value
                          )}
                        </TableCell>
                      )
                    );
                  })}
                  {page !== "nlp" && (
                    <TableCell
                      width={1}
                      style={{ padding: "8px 5px" }}
                    ></TableCell>
                  )}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default MuiTable;
