import {
  moduleConstants,
  subModuleConstants,
  accessConstants,
} from "../constants/accessManagementConstants";
import { isAccessProvided } from "./assetManagementService";

/**Below function is to find whether any of admin component has any access */
export const switchToAdminLocation = (accessDataByRole) => {
  let locationUrl = "";

  if (
    isAccessProvided(
      moduleConstants.admin_view,
      subModuleConstants.users,
      null,
      accessConstants.read,
      accessDataByRole
    )
  ) {
    locationUrl = "/admin/userlist";
  } else if (
    isAccessProvided(
      moduleConstants.admin_view,
      subModuleConstants.regions,
      null,
      accessConstants.read,
      accessDataByRole
    )
  ) {
    locationUrl = "/admin/regions";
  } else if (
    isAccessProvided(
      moduleConstants.admin_view,
      subModuleConstants.business_units,
      null,
      accessConstants.read,
      accessDataByRole
    )
  ) {
    locationUrl = "/admin/businessunit";
  } else if (
    isAccessProvided(
      moduleConstants.policy,
      subModuleConstants.policy_view,
      null,
      accessConstants.read,
      accessDataByRole
    )
  ) {
    locationUrl = "/admin/policy";
  } else if (
    isAccessProvided(
      moduleConstants.admin_view,
      subModuleConstants.asset_metadata,
      null,
      accessConstants.read,
      accessDataByRole
    )
  ) {
    locationUrl = " /admin/details";
  } else if (
    isAccessProvided(
      moduleConstants.admin_view,
      subModuleConstants.risk_factors,
      null,
      accessConstants.read,
      accessDataByRole
    )
  ) {
    locationUrl = "/admin/riskfactors";
  } else if (
    isAccessProvided(
      moduleConstants.admin_view,
      subModuleConstants.business_units_assignment,
      null,
      accessConstants.read,
      accessDataByRole
    )
  ) {
    locationUrl = "/admin/node-businessunit-relationship";
  } else if (
    isAccessProvided(
      moduleConstants.card_templates,
      subModuleConstants.crud,
      null,
      accessConstants.read,
      accessDataByRole
    )
  ) {
    locationUrl = "/admin/cardTemplate";
  } else if (
    isAccessProvided(
      moduleConstants.admin_view,
      subModuleConstants.role_management,
      null,
      accessConstants.read,
      accessDataByRole
    )
  ) {
    locationUrl = "/admin/roleManagement";
  }

  return locationUrl;
};

/**Below function is to find whether any of non-admin component has any access */
export const handleSwitchToAppAccess = (accessDataByRole) => {
  let switchToApp = false;

  if (
    isAccessProvided(
      moduleConstants.asset_view,
      subModuleConstants.crud,
      null,
      accessConstants.read,
      accessDataByRole
    ) ||
    isAccessProvided(
      moduleConstants.profile_view,
      subModuleConstants.crud,
      null,
      accessConstants.read,
      accessDataByRole
    ) ||
    isAccessProvided(
      moduleConstants.extraction,
      subModuleConstants.crud,
      null,
      accessConstants.read,
      accessDataByRole
    )
  ) {
    switchToApp = true;
  }
  return switchToApp;
};

/**Below function is to give location for non-admin component has any access */
export const handleSwitchToAppLocation = (accessDataByRole) => {
  let locationUrl = "";

  if (
    isAccessProvided(
      moduleConstants.asset_view,
      subModuleConstants.crud,
      null,
      accessConstants.read,
      accessDataByRole
    )
  ) {
    locationUrl = "/result/listview/konfer-confidence";
  } else if (
    isAccessProvided(
      moduleConstants.profile_view,
      subModuleConstants.crud,
      null,
      accessConstants.read,
      accessDataByRole
    )
  ) {
    locationUrl = " /compliance-result/compliance-listview/konfer-confidence";
  } else if (
    isAccessProvided(
      moduleConstants.extraction,
      subModuleConstants.crud,
      null,
      accessConstants.read,
      accessDataByRole
    )
  ) {
    locationUrl = "/discovery";
  }

  return locationUrl;
};
