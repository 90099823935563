export const complianceTagName = {
  AllBusinessUnitsTag: "All Business Units",
  AllRegionsTag: "All Regions",
  AllApplicationTag: "All Applications",
};

//Hardcoded this value, in data base id is 0 for AllApplicatios and AllBusinessunits
export const complianceTagValue = {
  AllApplications: 0,
  AllBusinessUnis: 0,
  AllRegions: 0,
  DefaultValue: -1,
};

export const annotationTypeName = {
  Organization: "ORG",
  Region: "REGION",
  BusinessUnit: "BUSINESS_UNIT",
  App: "APP_SUMMARY",
  AppSummary: "APPSUMMARY",
};

export const riskfactorCategory = {
  Confidence_Factor: "Konfer Confidence",
  Human_Impact: "Human Impact",
};

export const riskfactorCategoryType = {
  Confidence_Factor: "Confidence_Factor",
  Human_Impact: "Human_Impact",
};

export const riskfactorCategoryArray = ["Konfer Confidence", "Human Impact"];

export const getType = (
  regionID,
  selectedBuId,
  appIdForOpeningSummarView,
  scoreType
) => {
  let typeName;
  regionID === complianceTagValue.AllRegions &&
  regionID !== complianceTagValue.DefaultValue
    ? (typeName = annotationTypeName.Organization)
    : selectedBuId[0] !== undefined &&
      selectedBuId[0] === complianceTagValue.AllBusinessUnis &&
      selectedBuId.length > 0
    ? (typeName = annotationTypeName.Region)
    : appIdForOpeningSummarView === complianceTagValue.AllApplications &&
      appIdForOpeningSummarView !== complianceTagValue.DefaultValue
    ? (typeName = annotationTypeName.BusinessUnit)
    : (typeName = annotationTypeName.AppSummary);

  return scoreType === riskfactorCategory.Confidence_Factor
    ? typeName
    : `${typeName}`;
};

export const getTypeID = (
  regionID,
  selectedBuId,
  appIdForOpeningSummarView,
  organizationID
) => {
  let typeID;

  regionID === complianceTagValue.AllRegions &&
  regionID !== complianceTagValue.DefaultValue
    ? (typeID = organizationID)
    : selectedBuId[0] === complianceTagValue.AllBusinessUnis &&
      selectedBuId.length > 0 &&
      selectedBuId[0] !== undefined
    ? (typeID = regionID)
    : appIdForOpeningSummarView === complianceTagValue.AllApplications &&
      appIdForOpeningSummarView !== complianceTagValue.DefaultValue
    ? (typeID = selectedBuId[0])
    : (typeID = appIdForOpeningSummarView);

  return typeID;
};

export const Confidence_Factor_Name = [
  "Member Assessment",
  "Trade Controls",
  "Operations",
  "Sanctions",
  "Record Keeping",
];
