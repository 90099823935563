import { Circle, FormatListBulleted, Share } from "@mui/icons-material";
import { Divider, Tooltip } from "@mui/material";
import { useAtom } from "jotai";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import {
  businessUnits,
  businessUnitName,
  isGraphview,
  selectedBusinessUnit,
  appIdForOpeningSummarViewAtom,
  isComplianceSummaryView,
  regionIDAtom,
  regionNameAtom,
} from "../../../atom/jotai";

import "./ComplianceResultview.css";
import { deslugifyText } from "../../../utils/basicUtils";
import { KONFER_SCORE } from "../../../constants/basicConstants";
import { analyticServices } from "../../../services/analyticServices";
import MuiSelect from "../../MuiSelect/MuiSelect";
import {
  complianceTagName,
  complianceTagValue,
} from "../../../constants/complianceConstants";
import { riskFactorValueCond } from "../../../constants/riskFactorMonth";
import { ScoreValueNew } from "../../../constants/amcharts";
import { PolicyNameConst } from "../../../constants/policyConstants";

const CpoSummaryResultview = ({
  businessUnitData,
  businessData,
  setBusinessData,
  count,
  appsNameData,
  setAppsNameData,
  regionData,
}) => {
  const navigate = useNavigate();

  const [selectedBuId, setSelectedBusiness] = useAtom(selectedBusinessUnit);
  const [businessName, setBusinessUnitName] = useAtom(businessUnitName);
  const [appIdForOpeningSummarView, setAppIdForOpeningSummarView] = useAtom(
    appIdForOpeningSummarViewAtom
  );

  const [region, setRegion] = useState(1);
  // const [appsNameData, setAppsNameData] = useState([]);
  const [isSummaryView, setIsSummaryView] = useAtom(isComplianceSummaryView);
  const location = useLocation();

  const { appId, searchText, businessId, regionId } = useParams();

  const [regionID, setRegionID] = useAtom(regionIDAtom);
  const [regionName, setRegionName] = useAtom(regionNameAtom);

  const handleBusinessUnits = (value) => {
    let tempArrforAppData = [];
    setSelectedBusiness([value]);
    businessData.map((data) => {
      if (value === data.id) {
        setBusinessUnitName(data.name);
        analyticServices(`SEARCH  : konfer-confidence-profile ${data.name}`);
      }
    });
    setRegionID(regionID);
    setIsSummaryView(false);
    navigate(`/compliance-result/compliance-listview/${searchText}`);
  };

  const handleRegion = (value) => {
    setRegionID(value);
    let tempArrforBUData = [];
    // regionData.map((data) => {
    //   if (value === data.id) {
    //     setRegionName(data.name);
    //   }
    // });
    businessUnitData.data.data.regionData?.map((item) => {
      //setting the region name name for selected region as per the id
      item.regionID === value && setRegionName(item.regionName);

      item.regionID === value &&
        analyticServices(
          `SEARCH  : konfer-confidence-profile ${item.regionName}`
        );

      //For setting business dropdown data
      if (
        value === item.regionID &&
        item.regionName !== complianceTagName.AllRegionsTag
      ) {
        item.businessUnitsData?.map((buData) => {
          tempArrforBUData.push({
            id: buData.businessUnitID,
            name: buData.businessUnitName,
          });

          //For showing All Business units by default
          buData.businessUnitName === complianceTagName.AllBusinessUnitsTag &&
            setSelectedBusiness([buData.businessUnitID]) &&
            setBusinessUnitName(buData.businessUnitName);
        });
      } else if (item.regionName === complianceTagName.AllRegionsTag) {
        setSelectedBusiness([]);
        setBusinessUnitName(null);
        setAppIdForOpeningSummarView(-1);
      }
    });
    setBusinessData(tempArrforBUData);
    setIsSummaryView(false);
    navigate(`/compliance-result/compliance-listview/${searchText}`);
  };

  const handleApplicationName = (value) => {
    //If we are choosing All Application , this means we are navigating to CPO listvew page
    if (value === complianceTagValue.AllApplications) {
      setAppIdForOpeningSummarView(value);
      setIsSummaryView(false);
      setSelectedBusiness(businessId);
      setRegionID(regionID);
      navigate(`/compliance-result/compliance-listview/${searchText}`);
      analyticServices(
        `SEARCH  : konfer-confidence-profile ${complianceTagName.AllApplicationTag}`
      );
    } else {
      setAppIdForOpeningSummarView(value);
      navigate(
        `/compliance-result/compliance-summary/${searchText}/${regionID}/${selectedBuId}/${value}`
      );
      appsNameData.map((data) => {
        data.id === value &&
          analyticServices(
            `SEARCH  : konfer-confidence-profile-summary ${data.name}`
          );
      });
    }
  };

  return (
    <div className="result-title-lists">
      <h2 className="result-title-lists_heading">
        {PolicyNameConst.konfer_confidence}
      </h2>
      <Divider
        orientation="vertical"
        variant="middle"
        flexItem
        className="seperator"
      />
      <MuiSelect
        defaultValue={regionID}
        data={regionData}
        onChange={handleRegion}
      />
      <Divider
        orientation="vertical"
        variant="middle"
        flexItem
        className="seperator"
      />
      <MuiSelect
        defaultValue={selectedBuId && selectedBuId}
        data={businessData}
        onChange={handleBusinessUnits}
      />

      <Divider
        orientation="vertical"
        variant="middle"
        flexItem
        className="seperator"
      />
      <MuiSelect
        defaultValue={appIdForOpeningSummarView}
        data={appsNameData}
        onChange={handleApplicationName}
      />

      {(count || count === ScoreValueNew.lowest_limit_red_score_new) && (
        <>
          <Circle
            className={
              count <= ScoreValueNew.highest_limit_red_score ||
              count === riskFactorValueCond.ntAplicabl
                ? "score_ball_color_below_limit_CPO"
                : "score_ball_color_above_limit_CPO"
            }
          />

          <p className="score_ball_count_CPO">
            {count === null ||
            count === riskFactorValueCond.ntAplicabl ||
            count === undefined
              ? "-"
              : count}
          </p>
        </>
      )}
    </div>
  );
};

export default CpoSummaryResultview;
