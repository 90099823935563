import {
  Box,
  IconButton,
  Modal,
  TextField,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useAtom } from "jotai";
import {
  useCreateGenAiTemplateResponse,
  useSearchQueInOpenAI,
} from "../../api/createApiFunctions";
import CancelIcon from "../../assets/images/adminPanel/CancelIcon.svg";
import { toast } from "react-toastify";
import pdfIcon from "../../assets/images/icons/pdf-icon.svg";
import "./Cards.css";
import { getFileIcon } from "../../services/cardServices";
import StopIcon from "../../assets/images/dataSetCard/StopIcon.svg";
import {
  useSearchQueInOpenAIForBulk,
  useSearchQueInOpenAIForSingle,
} from "../../api/readApiFunctions";
import { bulkQueResGeneratedAtom } from "../../atom/jotai";

import { capitalize } from "lodash";
import SimpleBackdrop from "../SimpleBackdrop/SimpleBackdrop";
import { genAiToasts } from "../../constants/toasterMsgConstants";

/**
 * Component : GenAIResGenerator
 *
 * Description : This component is responsible for opening modal for finding the answer in open ai.
 *
 *  Props : open modal variable and it's setter function,
 *          question for open ai and it's setter function,
 *          data : card data,
 *          type: prop shows modal is calleed for template question
 *          questionId: template question id
 *
 *      question for open ai and it's setter function
 */

const GenAIResGenerator = ({
  openAIModal,
  setOpenAIModal,
  questionTextForOpenAI,
  data,
  type,
  questionId,
  cardResDataRefetch,
}) => {
  let a =
    window.innerHeight <= 760 ? 100 : window.innerHeight <= 900 ? 120 : 180;

  //State for showing the progress of bulk question
  const [bulkQueResGenerated, setBulkQueResGenerated] = useAtom(
    bulkQueResGeneratedAtom
  );
  const [documentId, setDocumentId] = useState(null);
  const [documentName, setDocumentName] = useState(null);
  const [questionText, setQuestionText] = useState("");

  const [answerDataForSingleCustomQue, setAnswerDataForSingleCustomQue] =
    useState(null);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "65%",
    height: window.innerHeight <= 730 ? "75%" : "55%",
    bgcolor: "background.paper",
    borderRadius: "8px",
    boxShadow: "0px 6px 10px 0px rgba(0, 0, 0, 0.30)",
    p: 2,
  };

  const resWaitingMsg = {
    allQueMsg:
      "*You will get a notification when all the questions are answered",
    singleQue: "*Wait for the response to be generated",
  };

  const genAiQueTypes = [
    {
      name: "Single Question",
      value: "single_question",
    },
    {
      name: "All Questions",
      value: "all_que_of_card",
    },
  ];
  const [selectedTab, setSelectedTab] = useState(genAiQueTypes[0]);

  const {
    mutate: getAnswerForSingleCustomQue,
    data: answerDataForSingleCustomQueData,
    isLoading: isLoadingForSingleCustomQue,
    isSuccess: isSuccessForSingleCustomQue,
    isError: isErrorForSingleCustomQue,
    error: errorForSingleCustomQue,
  } = useSearchQueInOpenAIForSingle();

  const {
    mutate: getAnswerForBulkQue,
    data: answerDataForBulkQue,
    isLoading: isLoadingForBulkQue,
    isError: isErrorForBulkQue,
    error: errorForBulkQue,
  } = useSearchQueInOpenAIForBulk();

  const {
    mutate: getAnswerForTemplateQuestion,
    data: answerDataForTemplateQuestion,
    isLoading: isLoadingForTemplateQuestion,
    isSuccess: isSuccessForTemplateQuestion,
    isError: isErrorForTemplateQuestion,
    error: errorForTemplateQuestion,
  } = useCreateGenAiTemplateResponse();

  /** Below useEffect is used for getting document id and documnet name */
  useEffect(() => {
    if (data) {
      data?.sections?.length > 0 &&
        data?.sections?.map((sectionData) => {
          sectionData.title === "Details" &&
            sectionData.subsections?.length > 0 &&
            sectionData.subsections?.map((subSectionData) => {
              if (subSectionData.title === "Document") {
                subSectionData.text_data !== "[]" &&
                  subSectionData.text_data?.length > 0 &&
                  subSectionData.text_data?.map((textData) => {
                    let tempTextData = JSON.parse(textData?.text_data)[0];
                    setDocumentId(tempTextData?.id);
                    setDocumentName(tempTextData?.file_name);
                  });
              }
            });
        });
    }
  }, [data]);

  //Below uesEffect() is used for setting the open ai answer for custom question
  useEffect(() => {
    if (isSuccessForSingleCustomQue) {
      setAnswerDataForSingleCustomQue(answerDataForSingleCustomQueData);
      toast.success(genAiToasts.GenAICustomQueSuccessMsg);
    }
  }, [answerDataForSingleCustomQueData]);

  //Below uesEffect() is used for showing an error while connection the open ai for custom question
  useEffect(() => {
    if (isErrorForSingleCustomQue) {
      if (errorForSingleCustomQue?.response?.data === undefined) {
        toast.error(errorForSingleCustomQue?.message);
        setOpenAIModal(false);
      } else {
        toast.error(errorForSingleCustomQue?.response?.data?.message);
        setOpenAIModal(false);
      }
    }
  }, [errorForSingleCustomQue]);

  //Below uesEffect() is used for showing an error while connection the open ai for bulk question
  useEffect(() => {
    if (isErrorForBulkQue) {
      if (errorForBulkQue?.response?.data === undefined) {
        toast.error(errorForBulkQue?.message);
        setOpenAIModal(false);
      } else {
        toast.error(errorForBulkQue?.response?.data?.message);
        setOpenAIModal(false);
      }
    }
  }, [errorForBulkQue]);

  useEffect(() => {
    if (isSuccessForTemplateQuestion && answerDataForTemplateQuestion) {
      toast.success(genAiToasts.genAITemplateQuestionSuccessToast);
      cardResDataRefetch();
      setOpenAIModal(false);
    }

    if (isErrorForTemplateQuestion) {
      if (errorForTemplateQuestion?.response?.data === undefined) {
        toast.error(errorForTemplateQuestion?.message);
        setOpenAIModal(false);
      } else {
        toast.error(errorForTemplateQuestion?.response?.data?.message);
        setOpenAIModal(false);
      }
    }
  }, [
    isSuccessForTemplateQuestion,
    answerDataForTemplateQuestion,
    isErrorForTemplateQuestion,
  ]);

  const handleTabClick = (tab) => {
    setSelectedTab(tab);
  };

  /**Below function is used for giving css to "Get Answer button" and also allow it for api call */
  const handleGetAnswerButton = () => {
    let buttonCss = "dataset_btn_inactive";
    let callAPI = false;
    if (documentId) {
      buttonCss = "dataset_btn_active";
      callAPI = false;
      if (
        selectedTab.value === "all_que_of_card" ||
        (selectedTab.value === "single_question" && questionText !== "") ||
        type === "template_question"
      ) {
        buttonCss = "dataset_btn_active";
        callAPI = true;
      } else {
        buttonCss = "dataset_btn_inactive";
      }
    }
    return { buttonCss, callAPI };
  };

  //Below function is used to search answer in open ai
  const handleSearchInOpenAI = () => {
    if (handleGetAnswerButton().callAPI) {
      if (
        selectedTab.value === "single_question" &&
        type !== "template_question"
      ) {
        const payload = {
          card_id: data?.card_id,
          file_id: documentId,
          question: questionText,
          type: "Custom",
        };
        getAnswerForSingleCustomQue(payload);
      } else if (type === "template_question") {
        const payload = {
          card_id: data?.card_id,
          file_id: documentId,
          question_id: questionId,
          question: questionTextForOpenAI,
          type: "template",
        };
        getAnswerForTemplateQuestion(payload);
      } else {
        // call bulk here
        const payload = {
          card_id: data?.card_id,
          file_id: documentId,
        };
        getAnswerForBulkQue(payload);
        setBulkQueResGenerated(true);
        setOpenAIModal(false);
      }
    }
  };

  /**Below code is for showing the css of attached/not_attached document */
  const attachmentDesc = () => {
    return (
      <div style={{ display: "flex", margin: "20px 0px " }}>
        <span className="genAIFieldName">Attached Document:</span>
        {!documentId ? (
          <span className="genAINoDocAva">
            <img
              src={StopIcon}
              style={{ width: "17px", margin: "1px 0px 0px 12px" }}
            />
            <span style={{ margin: "3px 0px 0px 4px" }}>
              No document attached. Please attach a document in details section
              to generate response.
            </span>
          </span>
        ) : (
          <span className="genAIFieldValue" style={{ display: "flex" }}>
            <img
              src={
                documentName.split(".").length > 0
                  ? getFileIcon(documentName.split(".")[1])
                  : pdfIcon
              }
              style={{
                marginLeft: "2px",
                width: "19px",
              }}
            />
            <span className="genAIDocName">{documentName}</span>
          </span>
        )}
      </div>
    );
  };

  return (
    <Modal
      open={openAIModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <div style={{ display: "flex", justifyContent: "end" }}>
          <IconButton
            onClick={() => {
              setOpenAIModal(false);
            }}
          >
            <img src={CancelIcon} width="14px" />
          </IconButton>
        </div>
        <div
          style={{
            padding: "0px 10px",
            position: "relative",
            marginTop: "-10px",
          }}
        >
          <Typography id="modal-modal-title" className="genAIModalHeading">
            GenAI Response Generator
          </Typography>

          {/* Tab Code */}
          {type !== "template_question" && (
            <div className="tabDiv">
              {genAiQueTypes.map((tab) => {
                return (
                  <div
                    className={
                      selectedTab.value === tab.value
                        ? "tab selectedTab"
                        : "tab"
                    }
                    onClick={() => handleTabClick(tab)}
                  >
                    <p style={{ margin: "9px" }}>{tab.name}</p>
                  </div>
                );
              })}
            </div>
          )}

          {type === "template_question" && attachmentDesc()}

          {type === "template_question" && (
            <p className="genai_template_question_text">
              <span className="genai_template_question_question">
                Question :{" "}
              </span>{" "}
              {questionTextForOpenAI}
            </p>
          )}

          {selectedTab.name === "Single Question" &&
            type !== "template_question" && (
              <div>
                {attachmentDesc()}
                <TextField
                  id="open_ai_qusetion"
                  label="Enter Question"
                  variant="outlined"
                  multiline
                  maxRows={2}
                  sx={{
                    "& .MuiInputBase-input": {
                      fontFamily: "var(--fontFamilyText)",
                      fontSize: "18px",
                      color: "#000",
                    },
                  }}
                  style={{ width: "100%" }}
                  value={questionText && questionText}
                  onChange={(e) => {
                    setQuestionText(e.target.value);
                    setAnswerDataForSingleCustomQue(null);
                  }}
                />
                <div style={{ display: "flex", margin: "20px 0px" }}>
                  <span className="genAIFieldName">Generated Answer:</span>
                  <span className="genAIFieldValue">
                    {" "}
                    {capitalize(
                      answerDataForSingleCustomQue?.data?.answer
                    )}{" "}
                  </span>
                </div>
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "auto 1fr",
                    margin: "20px 0px",
                  }}
                >
                  <span className="genAIFieldName">Supporting Snippet:</span>
                  <span
                    className="genAIFieldValue"
                    style={{ maxHeight: "130px", overflowY: "auto" }}
                  >
                    {
                      answerDataForSingleCustomQue?.data?.answer_snippet?.split(
                        "Generated at:"
                      )[0]
                    }
                  </span>
                </div>
              </div>
            )}

          {selectedTab.name !== "Single Question" &&
            type !== "template_question" && (
              <div>
                <p style={{ fontSize: "18px" }}>
                  GenAI for all the Boolean questions in the card
                </p>
                {attachmentDesc()}
              </div>
            )}
        </div>
        <div className="genAiModalButton">
          <button
            className={handleGetAnswerButton().buttonCss}
            onClick={handleSearchInOpenAI}
          >
            Get Answer
          </button>
          <button
            className="dataset_btn_inactive"
            onClick={() => {
              setOpenAIModal(false);
            }}
          >
            Cancel
          </button>
        </div>
        <p className="genAiResWaitMsg">
          {selectedTab.value === "all_que_of_card"
            ? resWaitingMsg.allQueMsg
            : resWaitingMsg.singleQue}
        </p>
        <SimpleBackdrop
          openStat={
            type === "template_question"
              ? isLoadingForTemplateQuestion
              : isLoadingForSingleCustomQue
          }
        />
      </Box>
    </Modal>
  );
};

export default GenAIResGenerator;
