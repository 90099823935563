import { FormControl, Input, InputBase, NativeSelect } from "@mui/material";
import { Box } from "@mui/system";
import { useAtom } from "jotai";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  useAdminConfigurationLoadData,
  useAdminConfigurationExtract,
  useAdminConfigurationSetConfig,
} from "../../api/createApiFunctions";
import {
  isSidebar,
  extractStarted,
  awsExtractionInProgress,
  authUser,
  accessDataByRoleAtom,
} from "../../atom/jotai";
import { awsConfigRequirment } from "../../constants/adminConfigConstant";
import "./AdminConfiguration.css";
import { toast } from "react-toastify";
// MUI Imports
import {
  Container,
  Grid,
  Button,
  IconButton,
  InputAdornment,
  Typography,
  Tooltip,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useAdminConfigurationGetConfig } from "../../api/readApiFunctions";

import { CircularProgress } from "@mui/material";
import { analyticServices } from "../../services/analyticServices";
import { adminConfigurationPage } from "../../constants/toasterMsgConstants";
import { useResetDatabase } from "../../api/deleteApiFunctions";
import { isAccessProvided } from "../../services/assetManagementService";
import {
  accessConstants,
  actionConstants,
  moduleConstants,
  subModuleConstants,
} from "../../constants/accessManagementConstants";

const AdminConfiguration = () => {
  const [value, setValue] = React.useState(new Date());

  const [accessKey, setAccessKey] = useState("");
  const [secretKey, setSecretKey] = useState("");
  const [regionName, setRegionName] = useState("");
  const [extractionStartDateTime, setExtractionStartDateTime] = useState("");
  const [scheduleStartDate, setScheduleStartDate] = useState("");
  const [scheduleTimeDay, setScheduleTimeDay] = useState("");
  const [scheduleFrequency, setScheduleFrequency] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const [sidebar] = useAtom(isSidebar);
  const [extractStat, setExtractStat] = useAtom(extractStarted);
  const [awsExtractInprogress, setAwsExtractInprogress] = useAtom(
    awsExtractionInProgress
  );

  const ariaLabel = { "aria-label": "description" };
  const location = useLocation();
  //global states maintain in jotai
  const [user] = useAtom(authUser);

  const { data: getConfigRes, isLoading: getConfigLoading } =
    useAdminConfigurationGetConfig(
      user?.contributor_id,
      "aws",
      location.pathname.split("/")[1] == "discovery" &&
        location.pathname.split("/")[1] !== undefined
        ? true
        : false
    );

  const [showAccessKey, setShowAccessKey] = useState(false);
  const [showSecretKey, setShowSecretKey] = useState(false);
  const [accessDataByRole, setAccessDataByRole] = useAtom(accessDataByRoleAtom);

  const {
    mutate: resetDatabase,
    data: resetResult,
    isSuccess: resetIsSuccess,
    error: resetError,
    isError: resetIsError,
    isLoading: resetLoading,
  } = useResetDatabase();
  const {
    mutate: loadDataRes,
    data: loadResult,
    isLoading: LoadDataLoading,
  } = useAdminConfigurationLoadData();
  const {
    mutate: extractData,
    data: extractRes,
    isLoading: extractLoading,
    status: extractStatus,
    error: extractError,
    isError: extractIsError,
  } = useAdminConfigurationExtract();
  const {
    mutate: configData,
    data: configRes,
    error : awsConfigErrorMessage,
    isLoading: configLoading,
  } = useAdminConfigurationSetConfig();

  const resetDb = () => {
    resetDatabase({
      source: "aws",
      subModInvoker: "amazon_sagemaker_reset",
    });
    analyticServices(`Data is reset`);
  };

  const LoadData = () => {
    loadDataRes();
  };

  const extractNow = () => {
    setExtractStat(true);
    setAwsExtractInprogress(true);
    const payload = {
      aws_access_key: accessKey,
      aws_secret_key: secretKey,
      region_name_list: regionName
        ? `['${regionName.replaceAll(",", "','")}']`.replaceAll(" ", "")
        : [],
      extraction_start_datetime: extractionStartDateTime,
      schedule_start_date: scheduleStartDate,
      schedule_time_day: scheduleTimeDay,
      schedule_frequency: scheduleFrequency,
      source: "aws",
      contributor_id: user?.contributor_id,
    };
    extractData(payload);
    toast.success("Extraction Started");
    analyticServices(`Data is extracted`);
  };

  const setConfig = () => {
    if (
      accessKey !== "" &&
      secretKey != "" &&
      regionName != "" &&
      extractionStartDateTime != ""
    ) {
      if (
        scheduleStartDate.split("-")[0].length >= 5 ||
        extractionStartDateTime.split("-")[0].length >= 5
      ) {
        toast.error("Year should be 4 digit");
      } else {
        const payload = {
          aws_access_key: accessKey,
          aws_secret_key: secretKey,
          region_name_list: regionName
            ? `['${regionName.replaceAll(",", "','")}']`.replaceAll(" ", "")
            : [],
          extraction_start_datetime: extractionStartDateTime,
          schedule_start_date: scheduleStartDate,
          schedule_time_day: scheduleTimeDay,
          schedule_frequency: scheduleFrequency,
          source: "aws",
        };
        configData(payload);
        analyticServices(`Configuration is set`);
      }
    } else {
      toast.error("please enter all mandatory fields");
    }
  };

  useEffect(() => {
    if (extractStatus === "success" || extractStatus === "error") {
      setExtractStat(false);
      setAwsExtractInprogress(false);
    }
  }, [extractStatus]);

  useEffect(() => {
    if (awsConfigErrorMessage) {
      toast.error(awsConfigErrorMessage?.response?.data?.error)
    }
  }, [awsConfigErrorMessage])

  useEffect(() => {
    if (getConfigRes) {
      setAccessKey(getConfigRes?.aws_access_key);
      setSecretKey(getConfigRes?.aws_secret_key);
      setRegionName(
        getConfigRes?.region_name_list &&
          eval(getConfigRes?.region_name_list).toString()
      );
      setExtractionStartDateTime(getConfigRes?.extraction_start_datetime);
      setScheduleStartDate(getConfigRes?.schedule_start_date);
      setScheduleTimeDay(getConfigRes?.schedule_time_day);
      setScheduleFrequency(getConfigRes?.schedule_frequency);
    }
  }, [getConfigRes]);

  useEffect(() => {
    loadResult?.data?.data?.insert_app?.returning?.length > 0
      ? loadResult && toast.success(`Data loaded`)
      : loadResult && toast.error(adminConfigurationPage.adminRequestFailMsg);
  }, [loadResult?.data]);

  useEffect(() => {
    if (extractRes) {
      toast.success(extractRes?.data?.message);
    }
  }, [extractRes]);

  useEffect(() => {
    if (extractIsError) {
      toast.error(extractError?.response?.data?.message);
    }
  }, [extractError]);

  useEffect(() => {
    if (configRes) {
      configRes?.data?.success === true
        ? toast.success(configRes?.data?.message)
        : toast.error(configRes?.data?.message);
    }
  }, [configRes]);

  // handle reset notifcation
  useEffect(() => {
    if (resetIsSuccess) {
      toast.success(adminConfigurationPage.resetDBSucess);
    }
  }, [resetResult]);

  useEffect(() => {
    if (resetIsError) {
      toast.error(resetError?.response?.data?.error);
    }
  }, [resetIsError]);

  useEffect(() => {
    setIsLoading(
      LoadDataLoading ||
        extractLoading ||
        resetLoading ||
        configLoading ||
        getConfigLoading
    );
  }, [
    LoadDataLoading,
    extractLoading,
    resetLoading,
    configLoading,
    getConfigLoading,
  ]);
  return (
    <div className="aws_extract_container">
      <Box
        id="sageMakerBoxContainer"
        sx={{ position: "relative", cursor: isLoading && "not-allowed" }}
        className="AdminConfiguration_container"
      >
        {isLoading && (
          <Box
            id="sageMakerLoadingBox"
            sx={{
              display: "flex",
              position: "absolute ",
              marginTop: "20%",
              marginLeft: "50%",
            }}
          >
            <CircularProgress />
          </Box>
        )}
        <Box>
          {awsConfigRequirment.map((awsConfigType, index) => {
            return (
              <p
                className="AdminConfiguration_container_heading"
                id={`${awsConfigType}`}
              >
                {awsConfigType}
                <span style={{ color: "red", marginLeft: "5px" }}>
                  {index < 4 && `*`}
                </span>
              </p>
            );
          })}
        </Box>

        <Box className="AdminConfiguration_data" id="smData">
          <div
            className="access_key_input_div"
            style={accessKey === "" ? { borderBottom: "2px solid red" } : {}}
          >
            <InputBase
              id="smInputAccessKey"
              inputProps={ariaLabel}
              placeholder={"Enter access key"}
              type={showAccessKey ? "text" : "password"}
              className="access_key_input"
              value={accessKey}
              onChange={(e) => setAccessKey(e.target.value)}
            />
            {showAccessKey ? (
              <VisibilityOff
                id="visibilityOff"
                fontSize="small"
                className="visibility_icon"
                onClick={() => {
                  setShowAccessKey(!showAccessKey);
                }}
              />
            ) : (
              <Visibility
                id="visibilityOn"
                fontSize="small"
                className="visibility_icon"
                onClick={() => {
                  setShowAccessKey(!showAccessKey);
                }}
              />
            )}
          </div>

          <div
            className="access_key_input_div"
            style={secretKey === "" ? { borderBottom: "2px solid red" } : {}}
          >
            <InputBase
              id="smInputSecretKey"
              placeholder={"Enter secret key"}
              type={showSecretKey ? "text" : "password"}
              className="access_key_input"
              inputProps={ariaLabel}
              value={secretKey}
              onChange={(e) => setSecretKey(e.target.value)}
            />
            {showSecretKey ? (
              <VisibilityOff
                id="visibilityOff"
                fontSize="small"
                className="visibility_icon"
                onClick={() => {
                  setShowSecretKey(!showSecretKey);
                }}
              />
            ) : (
              <Visibility
                id="visibilityOn"
                fontSize="small"
                className="visibility_icon"
                onClick={() => {
                  setShowSecretKey(!showSecretKey);
                }}
              />
            )}
          </div>

          <Input
            id="smInputRegionKey"
            placeholder={"Enter region"}
            className="AdminConfiguration_data_item"
            value={regionName}
            inputProps={ariaLabel}
            onChange={(e) => setRegionName(e.target.value)}
            error={regionName === ""}
          />

          <Input
            onChange={(e) => {
              setExtractionStartDateTime(e.target.value);
            }}
            className="AdminConfiguration_data_item"
            id="datetime-local"
            label="Next appointment"
            type="datetime-local"
            value={extractionStartDateTime}
            sx={{ width: 250, marginTop: "4px" }}
            InputLabelProps={{
              shrink: true,
            }}
            error={
              extractionStartDateTime === "" ||
              extractionStartDateTime.split("-")[0].length >= 5
            }
          />
          <Input
            className="AdminConfiguration_data_item"
            id="date"
            label="Birthday"
            type="date"
            value={scheduleStartDate}
            sx={{ width: 250 }}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={(e) => setScheduleStartDate(e.target.value)}
            error={scheduleStartDate.split("-")[0].length >= 5}
          />
          <Input
            className="AdminConfiguration_data_item"
            id="time"
            label="Alarm clock"
            type="time"
            value={scheduleTimeDay}
            InputLabelProps={{
              shrink: true,
            }}
            sx={{ width: 250 }}
            onChange={(e) => setScheduleTimeDay(e.target.value)}
          />

          <Box
            className="AdminConfiguration_data_item"
            sx={{ minWidth: 120 }}
            id="smScheduleData"
          >
            <FormControl fullWidth>
              <NativeSelect
                value={scheduleFrequency || 30}
                inputProps={{
                  name: "age",
                  id: "uncontrolled-native",
                }}
                onChange={(e) => setScheduleFrequency(e.target.value)}
              >
                <option value={20}>Once</option>
                <option value={30}>Monthly</option>
                <option value={"Daily"}>Daily</option>
              </NativeSelect>
            </FormControl>
          </Box>
        </Box>
      </Box>

      <Box
        id="smActionButtonBox"
        className={
          !sidebar
            ? "admin_configration_btn"
            : "admin_configration_btn_open_sidebar"
        }
      >
        {isAccessProvided("extraction" ,"amazon_sagemaker", null, "write" , accessDataByRole) && <button
          id="smSetConfigButton"
          className={` ${
            isLoading
              ? "userlist-createNewUser-button_disable"
              : "userlist-createNewUser-button"
          }`}
          onClick={() => !isLoading && setConfig()}
        >
          Set Config
        </button>}
        {isAccessProvided("extraction" ,"amazon_sagemaker", null, "write" , accessDataByRole) && <button
          id="smSetScheduleButton"
          className={` ${
            isLoading
              ? "userlist-createNewUser-button_disable"
              : "userlist-createNewUser-button_disable"
          }`}
        >
          Set Schedule
        </button>}
        {process.env.REACT_APP_ENABLE_RESET_DB === "true" &&
          isAccessProvided(
            moduleConstants.extraction,
            subModuleConstants.amazon_sagemaker_reset,
            actionConstants.reset_button,
            accessConstants.execution,
            accessDataByRole
          ) && (
            <button
              id="smResetDatabaseButton"
              className={` ${
                extractStat
                  ? "userlist-createNewUser-button_disable"
                  : "userlist-createNewUser-button"
              }`}
              onClick={!isLoading && resetDb}
              disabled={extractStat}
            >
              Reset Database
            </button>
          )}
        {/* <button
          id="smLoadDataButton"
          className={` ${
            extractStat
              ? "userlist-createNewUser-button_disable"
              : "userlist-createNewUser-button"
          }`}
          onClick={() => !isLoading && LoadData()}
          disabled={extractStat}
        >
          Load Data
        </button> */}
        {isAccessProvided(
          moduleConstants.extraction,
          subModuleConstants.extract,
          actionConstants.extraction_button,
          accessConstants.execution,
          accessDataByRole
        ) && (
          <button
            id="smExtractNowButton"
            className={` ${
              extractStat
                ? "userlist-createNewUser-button_disable"
                : "userlist-createNewUser-button"
            }`}
            onClick={() => !isLoading && extractNow()}
            disabled={extractStat}
          >
            Start
          </button>
        )}
      </Box>
    </div>
  );
};

export default AdminConfiguration;
