import { Circle, FormatListBulleted, Share } from "@mui/icons-material";
import { Divider, Tooltip } from "@mui/material";
import { useAtom } from "jotai";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { analyticServices } from "../../../services/analyticServices";

import {
  businessUnitName,
  selectedBusinessUnit,
  appIdForOpeningSummarViewAtom,
  isComplianceSummaryView,
  regionIDAtom,
  regionNameAtom,
  indexOfSearchedKeywordAtom,
  totalCountOfHighlightWord,
  appsNameDataComp,
} from "../../../atom/jotai";

import "./ComplianceResultview.css";
import MuiSelect from "../../MuiSelect/MuiSelect";
import {
  complianceTagName,
  complianceTagValue,
} from "../../../constants/complianceConstants";
import { riskFactorValueCond } from "../../../constants/riskFactorMonth";
import { ScoreValueNew } from "../../../constants/amcharts";
import { PolicyNameConst } from "../../../constants/policyConstants";

const ComplianceResultview = ({
  businessUnitData,
  count,
  regionData,
  setprevHighlightedLOC,
  setCurrentCount,
  setFindElement,
}) => {
  const navigate = useNavigate();

  const [selectedBuId, setSelectedBusiness] = useAtom(selectedBusinessUnit);
  const [businessName, setBusinessUnitName] = useAtom(businessUnitName);
  const [appIdForOpeningSummarView, setAppIdForOpeningSummarView] = useAtom(
    appIdForOpeningSummarViewAtom
  );

  const [region, setRegion] = useState(1);
  const [appsNameData, setAppsNameData] = useAtom(appsNameDataComp);
  const [isSummaryView, setIsSummaryView] = useAtom(isComplianceSummaryView);
  const { searchText } = useParams();
  const [regionID, setRegionID] = useAtom(regionIDAtom);
  const [regionName, setRegionName] = useAtom(regionNameAtom);
  const [businessData, setBusinessData] = useState([]);
  const [, setTotalCountOfHighlitedWord] = useAtom(totalCountOfHighlightWord);
  const [, setIndexOfSearchedKeyword] = useAtom(indexOfSearchedKeywordAtom);

  //This useEffect is for setting business unit data, it is mainly using when coming from summary view
  useEffect(() => {
    if (regionName !== complianceTagName.AllRegionsTag && regionName != "") {
      if (businessUnitData) {
        let tempArr = [];
        let tempAppArr = [];
        businessUnitData?.data?.data?.regionData?.map((item, index) => {
          regionID === item.regionID &&
            item.businessUnitsData?.map((data, i) => {
              tempArr.push({
                id: data.businessUnitID,
                name: data.businessUnitName,
              });

              if (
                selectedBuId.includes(complianceTagValue.AllBusinessUnis) &&
                data.businessUnitName === complianceTagName.AllBusinessUnitsTag
              ) {
                setBusinessUnitName(data.businessUnitName);
                setSelectedBusiness([data.businessUnitID]);
              }

              if (
                data.businessUnitID == selectedBuId &&
                selectedBuId !== complianceTagValue.AllBusinessUnis
              ) {
                data.appData.map((apps, index) => {
                  setAppIdForOpeningSummarView(
                    complianceTagValue.AllApplications
                  );
                  tempAppArr.push({
                    id: apps.appID,
                    name: apps.appName,
                  });
                });
              }
            });
        });
        setBusinessData(tempArr);
        setAppsNameData(tempAppArr);
      }
    }
  }, []);

  //This functiona is called when we select from Business unit drop down
  const handleBusinessUnits = (value) => {
    let tempArrforAppData = [];
    setSelectedBusiness([value]);

    //This logic is for opening application dropdown
    businessUnitData?.data.data.regionData?.map((item, index) => {
      //Only setting data for seleted region
      regionID === item.regionID &&
        item.businessUnitsData?.map((data) => {
          //setting the business name for selected business unit as per the id
          data.businessUnitID === value &&
            setBusinessUnitName(data.businessUnitName);

          data.businessUnitID === value &&
            analyticServices(
              `SEARCH  : konfer-confidence-profile ${data.businessUnitName}`
            );

          //For setting application dropdown data
          if (
            value === data.businessUnitID &&
            data.businessUnitName !== complianceTagName.AllBusinessUnitsTag
          ) {
            data.appData.map((apData) => {
              tempArrforAppData.push({
                id: apData.appID,
                name: apData.appName,
              });

              //For showing All applications by default
              apData.appName === complianceTagName.AllApplicationTag &&
                setAppIdForOpeningSummarView(apData.appID);
            });
          } else if (
            data.businessUnitName === complianceTagName.AllBusinessUnitsTag
          ) {
            setAppIdForOpeningSummarView(-1);
          }
        });
    });
    setAppsNameData(tempArrforAppData);

    setFindElement("");
    setTotalCountOfHighlitedWord(0);
    setIndexOfSearchedKeyword(-1);
    setCurrentCount(0);
    setprevHighlightedLOC([]);
  };

  //Below function is called when we select region  from  drop down
  const handleRegion = (value) => {
    let tempArrforBUData = [];
    setRegionID(value);
    businessUnitData?.data.data.regionData?.map((item, index) => {
      //setting the region name name for selected region as per the id
      item.regionID === value && setRegionName(item.regionName);

      item.regionID === value &&
        analyticServices(
          `SEARCH  : konfer-confidence-profile ${item.regionName}`
        );

      //For setting business dropdown data
      if (
        value === item.regionID &&
        item.regionName !== complianceTagName.AllRegionsTag
      ) {
        item.businessUnitsData?.map((buData) => {
          tempArrforBUData.push({
            id: buData.businessUnitID,
            name: buData.businessUnitName,
          });

          //For showing All Business units by default
          buData.businessUnitName === complianceTagName.AllBusinessUnitsTag &&
            setSelectedBusiness([buData.businessUnitID]) &&
            setBusinessUnitName(buData.businessUnitName);
        });
      } else if (item.regionName === complianceTagName.AllRegionsTag) {
        setSelectedBusiness([]);
        setBusinessUnitName(null);
        setAppIdForOpeningSummarView(-1);
      }
    });

    setBusinessData(tempArrforBUData);
    setFindElement("");
    setTotalCountOfHighlitedWord(0);
    setIndexOfSearchedKeyword(-1);
    setCurrentCount(0);
    setprevHighlightedLOC([]);
  };

  //This functiona is called when we select from Application drop down
  const handleApplicationName = (value) => {
    setAppIdForOpeningSummarView(value);
    if (value !== complianceTagValue.AllApplications) {
      setIsSummaryView(true);
      navigate(
        `/compliance-result/compliance-summary/${searchText}/${regionID}/${selectedBuId}/${value}`
      );
      appsNameData.map((data) => {
        data.id === value &&
          analyticServices(
            `SEARCH  : konfer-confidence-profile-summary ${data.name}`
          );
      });
    }
    setFindElement("");
    setTotalCountOfHighlitedWord(0);
    setIndexOfSearchedKeyword(-1);
    setCurrentCount(0);
    setprevHighlightedLOC([]);
    // else {
    //   setIsSummaryView(false);
    //   // compliance-result/compliance-listview/:searchText
    //   navigate(`/compliance-result/compliance-listview/${searchText}`);
    // }
  };

  return (
    <div className="result-title-lists">
      <h2 className="result-title-lists_heading">
        {PolicyNameConst.konfer_confidence}
      </h2>
      <Divider
        orientation="vertical"
        variant="middle"
        flexItem
        className="seperator"
      />

      {/* handle region select */}
      <MuiSelect
        defaultValue={regionID && regionID}
        data={regionData}
        onChange={handleRegion}
      />

      {/* hamdle BUI select */}
      {regionName !== "" && regionName !== complianceTagName.AllRegionsTag && (
        <>
          <Divider
            orientation="vertical"
            variant="middle"
            flexItem
            className="seperator"
          />
          <MuiSelect
            defaultValue={selectedBuId && selectedBuId}
            data={businessData}
            onChange={handleBusinessUnits}
          />
        </>
      )}

      {/* hamdle app select */}
      {regionName !== complianceTagName.AllRegionsTag &&
        businessName !== null &&
        businessName !== complianceTagName.AllBusinessUnitsTag && (
          <>
            <Divider
              orientation="vertical"
              variant="middle"
              flexItem
              className="seperator"
            />
            <MuiSelect
              defaultValue={appIdForOpeningSummarView}
              data={appsNameData}
              onChange={handleApplicationName}
            />
          </>
        )}
      {(count || count === ScoreValueNew.lowest_limit_red_score_new) && (
        <>
          <Circle
            className={
              count <= ScoreValueNew.highest_limit_red_score ||
              count === riskFactorValueCond.ntAplicabl
                ? "score_ball_color_below_limit_CPO"
                : "score_ball_color_above_limit_CPO"
            }
          />

          <p className="score_ball_count_CPO">
            {count === null ||
            count === riskFactorValueCond.ntAplicabl ||
            count === undefined
              ? "-"
              : count}
          </p>
        </>
      )}
    </div>
  );
};

export default ComplianceResultview;
