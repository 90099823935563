import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

// Mui imports
import { Button, Grid, InputBase, Box, IconButton } from "@mui/material";

// Custom imports
import konferlogo from "../../assets/images/konferLogo/konferlogo.svg";

import { CloseOutlined, NestCamWiredStand, Search } from "@mui/icons-material";
import Popup from "../Popup/Popup";
import "./Home.css";
import { slugifyText } from "../../utils/basicUtils";
import Navbar from "../Navbar/Navbar";
import { useAtom } from "jotai";
import {
  isSwitchToAdminPanelAtom,
  isComplianceViewAtom,
  authUser,
  regionIDAtom,
  regionNameAtom,
  selectedBusinessUnit,
  accessDataByRoleAtom,
} from "../../atom/jotai";
import { analyticServices } from "../../services/analyticServices";
import _ from "lodash";
import {
  complianceTagName,
  complianceTagValue,
} from "../../constants/complianceConstants";
import QuerySearchField from "../QuerySearchField/QuerySearchField";
import { searchBasicConstant } from "../../constants/parameterizingQueriesConstant";
import { isAccessProvided } from "../../services/assetManagementService";
import { PolicyNameConst } from "../../constants/policyConstants";

const Home = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [user] = useAtom(authUser);
  const [searchText, setSearchText] = useState("");
  const [konferScore, setKonferScore] = useState(true);
  const [popupOpen, setPopupOpen] = useState(false);
  const [, setIsSwitchToAdminPanel] = useAtom(isSwitchToAdminPanelAtom);
  const [, setIsComplianceView] = useAtom(isComplianceViewAtom);
  const [inputFilter, setiInputFilter] = useState();
  const [isSearch, setIsSearch] = useState(false);
  const [queryText, setQueryText] = useState();
  const [updatedField, setUpdatedField] = useState({
    value: "",
    typeName: "",
    type: "",
  });
  const [, setRegionID] = useAtom(regionIDAtom);
  const [, setRegionName] = useAtom(regionNameAtom);
  const [inputFiledQueryText, setInputFiledQueryText] = useState({
    inputFieldText: "",
    subInputFieldText: "",
    textFiled: "1",
    isContainSection: "is",
    isContain: "is",
    subInputFieldDropdown: "",
  });
  const [selectedBuId, setSelectedBusiness] = useAtom(selectedBusinessUnit);
  const [isProfile, setIsProfile] = useState();
  const [accessDataByRole, setAccessDataByRole] = useAtom(accessDataByRoleAtom);
  const [nlpSearchQuery, setNlpSearchQuery] = useState("");

  useEffect(() => {
    /** switch to admin panel is always set to true on home , for showing "switch to admin" label rather than "switch to app" */
    setIsSwitchToAdminPanel(true);
    setIsComplianceView(false); //set the CPO view always false
  }, []);

  const searchItem = async (searchValue) => {
    const textSearchArray = [];
    searchValue.split(" ").map((text, index) => {
      if (searchBasicConstant[text] !== undefined) {
        textSearchArray.push(searchBasicConstant[text]);
      } else if (text == "inputFieldText") {
        // textSearchArray.push(inputFiledQueryText.inputFieldText);

        textSearchArray.push(
          `${
            inputFiledQueryText.isContainSection +
            (inputFiledQueryText.isContainSection == "contains" ? "'s" : "")
          }` +
            " " +
            inputFiledQueryText.inputFieldText
        );
      } else if (text == "subInputFieldText") {
        textSearchArray.push(
          `${
            inputFiledQueryText.isContain +
            (inputFiledQueryText.isContain == "contains" ? "'s" : "")
          }` +
            " " +
            inputFiledQueryText.subInputFieldText
        );
      } else if (
        updatedField.textType == "subTextFiled" &&
        (text == "is" || text == "contains")
      ) {
        textSearchArray.push("");
      } else {
        textSearchArray.push(text);
      }
    });

    if (textSearchArray.length !== 0) {
      const newSearch = textSearchArray.join(" "); // converting array into string

      const sluggedSearch = slugifyText(String(newSearch)); // converting string in "-" format

      /**If the searched value length in less than 3 then redirect to elastic (back end).
       * If it's greater than 3 then NLQ */
      if (textSearchArray.length < 3) {
        if (newSearch?.trim().length > 0) {
          navigate(
            `/result/listview/${
              newSearch == "*" ? "*" : sluggedSearch !== "" && sluggedSearch
            }`
          );
          analyticServices(`Elastic SEARCH  : ${newSearch}`);
        }
      } else {
        /**If nlpSearchQuery === "" then Parameterized query otherwise free form query */
        if (nlpSearchQuery !== "") {
          navigate(`/nlp-query-result/${slugifyText(String(nlpSearchQuery))}`, {
            state: { originalSearch: nlpSearchQuery },
          });
          analyticServices(`NLQ SEARCH  : ${nlpSearchQuery}`);
        } else if (newSearch?.trim().length > 0) {
          navigate(
            `/nlp-query-result/${
              newSearch == "*" ? "*" : sluggedSearch !== "" && sluggedSearch
            }`,
            { state: { originalSearch: newSearch } }
          );
          analyticServices(
            `NLQ SEARCH  : ${
              newSearch == "*" ? "*" : sluggedSearch !== "" && sluggedSearch
            }`
          );
        }
      }
    }
  };

  const cancelSearch = () => {
    setSearchText("");
    setNlpSearchQuery("");
    if (inputFilter !== undefined && inputFilter === true) {
      window.location.reload();
    }
  };

  useEffect(() => {
    let updatedInputSearchField =
      searchText.split(" ").splice(0, updatedField?.indexId).join(" ") +
      `${
        updatedField?.typeName !== undefined
          ? ` ${updatedField?.typeName} `
          : " version "
      }is ${updatedField?.value}`;

    updatedField?.typeName?.length > 0 &&
      setSearchText(
        searchText.replace(
          "version" ||
            "name" ||
            "description" ||
            "display_name" ||
            "contributors",
          updatedField?.typeName
        )
      );
    updatedField?.typeNameDescription?.length &&
      setSearchText(
        searchText.replace(
          searchText.split(" ")[5],
          updatedField?.typeNameDescription
        )
      );

    updatedField?.value?.length && setSearchText(updatedInputSearchField);
  }, [updatedField]);

  useEffect(() => {
    updatedField?.type &&
      setSearchText(
        searchText.replace(
          searchText.split(" ")[updatedField?.typeIndex],
          updatedField?.type
        )
      );
  }, [updatedField.type]);

  //Below useEffect is used for giving title to current page
  useEffect(() => {
    document.title = "Konfer Home Page";
  });

  const searchAllItem = () => {
    navigate(
      `/result/listview/konfer-confidence`
      // , { state: location.state }
    );
    setIsComplianceView(false);
    analyticServices(`SEARCH  : konfer-confidence`);
    setRegionID(-1);
    setSelectedBusiness([]);
  };

  const searchAllItemProfileView = () => {
    location.state !== null && location.state.route
      ? navigate(
          `/compliance-result/compliance-summary/konfer-confidence/${location.state.regionID}/${location.state.selectedBuId[0]}/${location.state.appIdForOpeningSummarView}`
        )
      : navigate(`/compliance-result/compliance-listview/konfer-confidence`);
    setIsComplianceView(true);
    analyticServices(`SEARCH  : konfer-confidence-profile`);
    localStorage?.setItem("view", "profileView");
  };

  const inputRef = useRef(null); // Variable for foucusing the input field when clicking on nlp queries
  // console.log("_____", updatedField, "---search--", searchText);

  useEffect(() => {
    const viewPageTab = localStorage.getItem("view");
    viewPageTab !== null && viewPageTab == "profileView" && setIsProfile(true);
    // used to clear local storage
    localStorage.removeItem("view");
  }, []);
  return (
    <>
      <Navbar />
      <div className="homePage" id="homePageDiv">
        <Grid container className="mainContainer">
          <Grid item xs={12}>
            <img src={konferlogo} alt="konfer-logo" className="searchImg" />
          </Grid>

          <Grid
            id="searchBarDivOnHome"
            style={{ margin: "auto" }}
            item
            container
            xs={12}
            md={
              updatedField.subTypeNameDescription
                ? window.innerWidth < 1320
                  ? 7.5
                  : 8.3
                : updatedField.typeNameDescription
                ? window.innerWidth < 1320
                  ? 6.5
                  : 8
                : 5
            }
            lg={
              updatedField.subTypeNameDescription
                ? window.innerWidth < 1320
                  ? 8.3
                  : inputFiledQueryText?.subInputFieldDropdown !== undefined &&
                    inputFiledQueryText?.subInputFieldDropdown?.length > 1
                  ? 6.7
                  : 6.8
                : updatedField.typeNameDescription
                ? window.innerWidth < 1320
                  ? 6.4
                  : inputFiledQueryText?.subInputFieldDropdown !== undefined &&
                    inputFiledQueryText?.subInputFieldDropdown?.length > 1
                  ? 4.6
                  : 4.6
                : 4.0
            }
            className="searchBar"
          >
            {!inputFilter && (
              <InputBase
                id="searchInput"
                className="input"
                placeholder="Search"
                value={searchText}
                inputProps={{ "aria-label": "search" }}
                autoFocus={true}
                inputRef={inputRef}
                onChange={(e) => {
                  setSearchText(e.target.value);
                  setNlpSearchQuery(e.target.value);
                  setKonferScore(true);
                }}
                onKeyPress={(ev) => {
                  if (ev.key === "Enter") {
                    searchItem(searchText);
                  }
                }}
              />
            )}
            {inputFilter && (
              <div
                style={{
                  display: "flex",
                  textAlign: "center",
                  alignItems: "center",
                  marginLeft: "13px",
                  flex: "1",
                }}
              >
                <QuerySearchField
                  searchText={searchText}
                  setSearchText={setSearchText}
                  updatedField={updatedField}
                  setUpdatedField={setUpdatedField}
                  isSearch={isSearch}
                  setIsSearch={setIsSearch}
                  queryText={queryText}
                  setQueryText={setQueryText}
                  popupOpen={popupOpen}
                  inputFiledQueryText={inputFiledQueryText}
                  setInputFiledQueryText={setInputFiledQueryText}
                />
              </div>
            )}

            <Box>
              {searchText && (
                <IconButton
                  id="cancelSearchIcon"
                  type="submit"
                  onClick={cancelSearch}
                  className="iconButton"
                  aria-label="cancel"
                >
                  <CloseOutlined
                    fontSize="medium"
                    className="home_page_search_cancel_btn"
                  />
                </IconButton>
              )}

              <IconButton
                id="submitSearchIcon"
                type="submit"
                onClick={() => {
                  setIsSearch(true);
                  searchItem(searchText);
                }}
                className="iconButton"
                aria-label="search"
              >
                <Search />
              </IconButton>
            </Box>
          </Grid>
          <Grid item xs={12} className="searchSuggestionBotton">
            <Box>
              <Button
                id="presetSearchButton"
                sx={{
                  marginRight: "30px",
                  marginTop: "0px",
                  color: "#000000",
                  fontSize: "16px",
                  textTransform: "none",
                  marginLeft: "30px",
                }}
              >
                Preset searches
              </Button>
              <Button
                id="WhatCanISearchButton"
                sx={{
                  marginRight: "30px",
                  marginTop: "0px",
                  color: "#000000",
                  fontSize: "16px",
                  textTransform: "none",
                }}
                onClick={() => {
                  setPopupOpen(!popupOpen);
                }}
              >
                What can I search?
              </Button>
            </Box>
            {popupOpen && (
              <Popup
                setPopupOpen={setPopupOpen}
                popupOpen={setPopupOpen}
                setSearchText={setSearchText}
                inputRef={inputRef}
                setiInputFilter={setiInputFilter}
                setUpdatedField={setUpdatedField}
                queryText={queryText}
                setQueryText={setQueryText}
              />
            )}
          </Grid>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <p
              id="konferConfidenceButtonOnHome"
              style={{
                color: "#58595b",
                fontWeight: "bold",
                fontSize: "18px",
                marginRight: "20px",
              }}
            >
              {PolicyNameConst.konfer_confidence}
            </p>
            {/* Assets */}
            {isAccessProvided(
              "profile_view",
              "crud",
              null,
              "read",
              accessDataByRole
            ) && (
              <p
                className={"selected_konferType"}
                style={{
                  marginRight: "15px",
                }}
                onClick={() => {
                  setIsProfile(true);
                  searchAllItemProfileView();
                  localStorage?.setItem("userSearchText", "konfer-confidence");
                }}
              >
                Profile
              </p>
            )}
            {isAccessProvided(
              "asset_view",
              "crud",
              "",
              "read",
              accessDataByRole
            ) && (
              <p
                className={"selected_konferType"}
                onClick={() => {
                  searchAllItem();
                  setIsProfile(false);

                  localStorage?.setItem("userSearchText", "konfer-confidence");
                  localStorage?.setItem("view", "assetsView");
                }}
              >
                Assets
              </p>
            )}

            {!konferScore && (
              <Box className="noResult_onsearch" id="noResultBoxOnHome">
                No result found
              </Box>
            )}
          </div>
        </Grid>
      </div>
    </>
  );
};

export default Home;
