import React, { useEffect, useState } from "react";

// Mui imports
import { Select, MenuItem, FormControl, TextField } from "@mui/material";

import _, { join } from "lodash";
import {
  parameterizeQueryContributor,
  parameterizeQueryType,
  parameterizeQueryTypeName,
  paramQueryTypeNameDescription,
} from "../../constants/parameterizingQueriesConstant";

const QuerySearchField = ({
  searchText,
  setSearchText,
  updatedField,
  setUpdatedField,
  popupOpen,
  isSearch,
  setIsSearch,
  queryText,
  setQueryText,
  inputFiledQueryText,
  setInputFiledQueryText,
}) => {
  // make popUp text constants and compare here below to instead of length
  const [subDropDown, setSubDropDown] = useState([]);
  const [isSubDropDown, setIsSubDropDown] = useState(false);

  useEffect(() => {
    const updatedSearchText =
      searchText.split(" ").splice(0, 7).join(" ") +
      " " +
      inputFiledQueryText?.inputFieldText;

    if (updatedField?.textLength === 8) {
      setSearchText(updatedSearchText);
    }

    if (updatedField?.textLength == 7) {
      setSearchText(
        searchText.split(" ").splice(0, 6).join(" ") +
          " " +
          inputFiledQueryText?.textFiled
      );
    }
  }, [inputFiledQueryText]);

  useEffect(() => {
    if (popupOpen) {
      setSearchText(queryText);
    }
  }, [popupOpen]);
  const secondDropDown = (selectedVal, index) => {
    setUpdatedField({
      ...updatedField,
      typeName: selectedVal,
      indexId: index,
    });

    return selectedVal;
  };
  // console.log("searchText", searchText.split(" ").splice("6"));
  // console.log(inputFiledQueryText.inputFieldText.split(" ").length);

  const secondDropDownForInputFiled = (selectedVal, index) => {
    setUpdatedField({
      ...updatedField,
      typeName: selectedVal,
      indexId: index,
    });

    setSearchText(
      searchText.split(" ").splice(0, 4).join(" ") +
        " " +
        selectedVal +
        " " +
        searchText.split(" ").splice(5).join(" ")
    );
    return selectedVal;
  };

  // paramQueryTypeNameDescription[`${updatedField.typeName}`];
  useEffect(() => {
    let subDropDownArray = [];
    if (updatedField.textLength === 12 && updatedField?.typeName) {
      paramQueryTypeNameDescription[`${updatedField.typeName}`].map(
        (ContributorType) => {
          updatedField?.typeNameDescription !== ContributorType &&
            subDropDownArray.push(ContributorType);
        }
      );
    }
    setSubDropDown(subDropDownArray);
  }, [updatedField]);

  return (
    <>
      {searchText.split(" ").map((val, index) => {
        if (
          parameterizeQueryTypeName.includes(val) &&
          updatedField.typeIndex == index
        ) {
          return (
            <>
              <FormControl
                variant="standard"
                sx={{
                  m: 0,
                  minWidth: 50,
                  marginRight: 0,
                  marginLeft: 0.5,
                }}
              >
                <Select
                  style={{
                    color: "blue",
                    marginTop: "4px",
                    borderBottom: "1px solid blue",
                    height: "25px",
                    fontStyle: "italic",
                  }}
                  labelId="demo-simple-select-standard-label"
                  id="demo-simple-select-standard"
                  value={val}
                  onChange={(e) => {
                    {
                      setUpdatedField({
                        ...updatedField,
                        type: e.target.value,
                        typeIndex: index,
                        indexId: 4,
                      });

                      setSearchText(
                        searchText.split(" ").splice(0, 2).join(" ") +
                          " " +
                          e.target.value +
                          " " +
                          searchText.split(" ").splice(3).join(" ")
                      );
                      setIsSubDropDown(false);
                    }
                  }}
                  label="Age"
                >
                  {parameterizeQueryTypeName.map((datasetsType) => {
                    if (datasetsType !== "contributors") {
                      return (
                        <MenuItem value={datasetsType}>{datasetsType}</MenuItem>
                      );
                    }
                  })}
                </Select>
              </FormControl>
            </>
          );
        }
        // -----------is- contain--------------

        if ((val == "is" || val == "contains") && index < 7) {
          return (
            <>
              <FormControl
                variant="standard"
                sx={{
                  m: 0,
                  minWidth: 50,
                  marginRight: 0,
                  marginLeft: 0.5,
                }}
              >
                <Select
                  style={{
                    color: "blue",
                    marginTop: "4px",
                    borderBottom: "1px solid blue",
                    height: "25px",
                    fontStyle: "italic",
                  }}
                  labelId="demo-simple-select-standard-label"
                  id="demo-simple-select-standard"
                  value={val}
                  onChange={(e) => {
                    {
                      setInputFiledQueryText({
                        ...inputFiledQueryText,
                        isContainSection: e.target.value,
                      });
                      setSearchText(
                        searchText.split(" ").splice(0, index).join(" ") +
                          " " +
                          e.target.value +
                          " " +
                          searchText
                            .split(" ")
                            .splice(index + 1)
                            .join(" ")
                      );
                      setIsSubDropDown(false);
                    }
                  }}
                  label="Age"
                >
                  <MenuItem value={"is"}>is</MenuItem>
                  <MenuItem value={"contains"}>contains</MenuItem>
                </Select>
              </FormControl>
            </>
          );
        }

        if (
          parameterizeQueryType.includes(val) &&
          updatedField.indexId == index
        ) {
          return (
            <>
              <FormControl
                variant="standard"
                sx={{
                  m: 0,
                  minWidth: 50,
                  marginRight: 0,
                  marginLeft: 0.5,
                  border: "none",
                }}
                key={index}
                onChange={(e) => {
                  {
                    setUpdatedField({
                      ...updatedField,
                      typeName: e.target.value,
                    });
                  }
                  setIsSubDropDown(false);
                }}
              >
                <Select
                  style={{
                    color: "blue",
                    marginTop: "4px",
                    borderBottom: "1px solid blue",
                    height: "25px",
                    fontStyle: "italic",
                  }}
                  labelId="demo-simple-select-standard-label"
                  id="demo-simple-select-standard"
                  value={
                    !paramQueryTypeNameDescription[
                      `${updatedField.type}`
                    ].includes(updatedField?.typeName)
                      ? secondDropDown(
                          paramQueryTypeNameDescription[
                            `${updatedField.type}`
                          ][0],
                          index
                        )
                      : updatedField?.typeName
                  }
                  onChange={(e) => {
                    setUpdatedField({
                      ...updatedField,
                      typeName: e.target.value,
                      indexId: index,
                    });
                    setIsSubDropDown(false);
                  }}
                  label="Age"
                >
                  {updatedField?.type &&
                    paramQueryTypeNameDescription[`${updatedField.type}`].map(
                      (ContributorType) => {
                        return (
                          <MenuItem value={ContributorType}>
                            {ContributorType}
                          </MenuItem>
                        );
                      }
                    )}
                </Select>
              </FormControl>
            </>
          );
        }

        // new param 2nd one

        if (parameterizeQueryContributor.hasOwnProperty(val)) {
          return (
            <>
              <FormControl
                variant="standard"
                sx={{
                  m: 0,
                  minWidth: 50,

                  marginRight: 0,
                  marginLeft: 0.5,
                  border: "none",
                }}
                key={index}
              >
                <Select
                  style={{
                    color: "blue",
                    marginTop: "4px",
                    borderBottom: "1px solid blue",
                    height: "25px",
                    fontStyle: "italic",
                  }}
                  labelId="demo-simple-select-standard-label"
                  id="demo-simple-select-standard"
                  value={
                    parameterizeQueryContributor[
                      `${updatedField.type}`
                    ].includes(updatedField?.typeName)
                      ? updatedField?.typeName
                      : secondDropDownForInputFiled(
                          parameterizeQueryContributor[
                            `${updatedField.type}`
                          ][0],
                          index
                        )
                  }
                  onChange={(e) => {
                    setUpdatedField({
                      ...updatedField,
                      typeName: e.target.value,
                      indexId: index,
                    });

                    setSearchText(
                      searchText.replace(
                        searchText.split(" ")[updatedField?.indexId],
                        e.target.value
                      )
                    );
                    setIsSubDropDown(false);
                  }}
                  label="Age"
                >
                  {updatedField.type &&
                    parameterizeQueryContributor[updatedField.type].map(
                      (ContributorType) => {
                        return (
                          <MenuItem value={ContributorType}>
                            {ContributorType}
                          </MenuItem>
                        );
                      }
                    )}
                </Select>
              </FormControl>
            </>
          );
        }
        // for name dependent

        if (
          parameterizeQueryType.includes(val) &&
          updatedField.typeNameDescriptionIndex == index
        ) {
          return (
            <>
              <FormControl
                variant="standard"
                sx={{
                  m: 0,
                  minWidth: 50,
                  marginRight: 0,
                  marginLeft: 0.5,
                  border: "none",
                }}
                key={index}
              >
                <Select
                  style={{
                    color: "blue",
                    marginTop: "4px",
                    borderBottom: "1px solid blue",
                    height: "25px",
                    fontStyle: "italic",
                  }}
                  labelId="demo-simple-select-standard-label"
                  id="demo-simple-select-standard"
                  value={
                    updatedField?.typeNameDescription
                      ? updatedField?.typeNameDescription
                      : updatedField.typeName
                      ? paramQueryTypeNameDescription[
                          `${updatedField.typeName}`
                        ][0]
                      : "name"
                  }
                  onChange={(e) => {
                    setUpdatedField({
                      ...updatedField,
                      typeNameDescription: e.target.value,
                      indexDescriptionId: index,
                    });
                    setIsSubDropDown(false);
                  }}
                  label="Age"
                >
                  {updatedField?.typeName &&
                    paramQueryTypeNameDescription[
                      `${updatedField.typeName}`
                    ].map((ContributorType) => {
                      return (
                        <MenuItem value={ContributorType}>
                          {ContributorType}
                        </MenuItem>
                      );
                    })}
                </Select>
              </FormControl>
            </>
          );
        }

        // subTypeNameDescription =version
        if (
          parameterizeQueryType.includes(val) &&
          updatedField.subTypeNameDescriptionIndex == index
        ) {
          return (
            <>
              <FormControl
                variant="standard"
                sx={{
                  m: 0,
                  minWidth: 50,
                  marginRight: 0,
                  marginLeft: 0.5,
                  border: "none",
                }}
                key={index}
              >
                <Select
                  style={{
                    color: "blue",
                    marginTop: "4px",
                    borderBottom: "1px solid blue",
                    height: "25px",
                    fontStyle: "italic",
                  }}
                  labelId="demo-simple-select-standard-label"
                  id="demo-simple-select-standard"
                  value={
                    isSubDropDown
                      ? `${updatedField?.subTypeNameDescription}`
                      : `${subDropDown[0]}`
                  }
                  onChange={(e) => {
                    setSearchText(
                      searchText.replace(
                        searchText.split(" ")[9],
                        e.target.value
                      )
                    );
                    setIsSubDropDown(true);
                    setInputFiledQueryText({
                      ...inputFiledQueryText,
                      subInputFieldDropdown: e.target.value,
                    });
                    setUpdatedField({
                      ...updatedField,
                      subTypeNameDescription: e.target.value,
                    });
                  }}
                  label="Age"
                >
                  {subDropDown.map((ContributorType) => {
                    return (
                      <MenuItem value={ContributorType}>
                        {ContributorType}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </>
          );
        }

        // for inputField 1st

        if (index === updatedField?.inputFieldIndex) {
          return (
            <div key={index} style={{ margin: "3px", display: "flex" }}>
              <div>
                {updatedField?.inputFieldIndex < 8 && (
                  <TextField
                    id="standard-basic"
                    style={{
                      marginRight: "-3px",
                      background: "white",
                    }}
                    // value={updatedField.value}
                    defaultValue={updatedField.textLength == 7 ? "1" : ""}
                    className="editable_textFiled"
                    variant="standard"
                    onChange={(e) => {
                      if (updatedField?.textLength == 7) {
                        setInputFiledQueryText({
                          textFiled: e.target.value,
                        });
                      }

                      updatedField?.textLength == 8 &&
                        setInputFiledQueryText({
                          inputFieldText: e.target.value,
                        });

                      updatedField?.textLength == 12 &&
                        setInputFiledQueryText({
                          ...inputFiledQueryText,
                          inputFieldText: e.target.value,
                        });
                    }}
                  />
                )}
              </div>
              {updatedField.textLength == 12 && (
                <span style={{ marginLeft: "10px", marginTop: "7px" }}>
                  and
                </span>
              )}
            </div>
          );
        }
        // console.log(index, updatedField?.inputFieldIndex);
        if (
          // index === updatedField?.inputFieldIndex ||
          index === updatedField?.subInputFieldIndex
        ) {
          return (
            <div key={index} style={{ margin: "3px", position: "relative" }}>
              <div>
                {updatedField.textLength == 12 && (
                  <p
                    style={{
                      position: "absolute",
                      marginLeft: "-5px",
                      marginTop: "1px",
                      marginRight: "5px",
                    }}
                  >
                    <FormControl
                      variant="standard"
                      sx={{
                        m: 0,
                        minWidth: 50,
                        marginRight: 0,
                        marginLeft: 0.5,
                      }}
                    >
                      <Select
                        style={{
                          color: "blue",
                          marginTop: "4px",
                          borderBottom: "1px solid blue",
                          height: "25px",
                          fontStyle: "italic",
                        }}
                        labelId="demo-simple-select-standard-label"
                        id="demo-simple-select-standard"
                        defaultValue={"is"}
                        onChange={(e) => {
                          updatedField?.textLength == 12 &&
                            setInputFiledQueryText({
                              ...inputFiledQueryText,
                              isContain: e.target.value,
                            });
                        }}
                      >
                        <MenuItem value={"is"}>is</MenuItem>
                        <MenuItem value={"contains"}>contains</MenuItem>
                      </Select>
                    </FormControl>
                  </p>
                )}
                <TextField
                  id="standard-basic"
                  style={{
                    marginRight: "-3px",
                    background: "white",
                    marginLeft: "70px",
                  }}
                  className="editable_textFiled"
                  variant="standard"
                  onChange={(e) => {
                    updatedField?.textLength == 12 &&
                      setInputFiledQueryText({
                        ...inputFiledQueryText,
                        subInputFieldText: e.target.value,
                      });
                  }}
                />
              </div>
            </div>
          );
        } else {
          return (
            <>
              <div style={{ display: "flex" }}>
                {/* {index < updatedField.textLength && (
                  <div key={index} style={{ margin: "3px" }}>
                    {val}
                  </div>
                )} */}

                {index < 8 && (
                  <div key={index} style={{ margin: "3px" }}>
                    {updatedField.textLength == 7 ? index < 7 && val : val}
                  </div>
                )}
              </div>
            </>
          );
        }
      })}
    </>
  );
};

export default QuerySearchField;
