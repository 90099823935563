import React, { useEffect, useLayoutEffect, useState } from "react";
import * as am5 from "@amcharts/amcharts5";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import * as am5xy from "@amcharts/amcharts5/xy";

import { Box } from "@mui/system";

const BarChart = ({ data }) => {
  const uniqueDivId = Math.floor(Math.random() * 800000);

  useLayoutEffect(() => {
    // Create root element
    let root = am5.Root.new(`${uniqueDivId}`);

    // Set themes
    root.setThemes([am5themes_Animated.new(root)]);

    // Create chart
    var chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panX: false,
        panY: false,
        wheelX: "none",
        wheelY: "none",
      })
    );

    /* Show chart title */
    chart.topAxesContainer.children.push(
      am5.Label.new(root, {
        text: "counts|purpose",
        fontSize: 18,
        fontWeight: "400",
        x: am5.p50,
        centerX: am5.p50,
      })
    );

    /* Show label for container */
    chart.children.push(
      am5.Label.new(root, {
        text: "GoodCredit",
        fontSize: 16,
        fontWeight: "400",
        y: am5.percent(10),
        x: am5.percent(100),
        centerX: am5.percent(100),
      })
    );

    // Create axes
    var yRenderer = am5xy.AxisRendererY.new(root, {
      minGridDistance: 30,
    });

    yRenderer.grid.template.set("location", 1);

    var yAxis = chart.yAxes.push(
      am5xy.CategoryAxis.new(root, {
        maxDeviation: 0,
        categoryField: "label",
        renderer: yRenderer,
        tooltip: am5.Tooltip.new(root, { themeTags: ["axis"] }),
      })
    );

    /* Show title on yAxis */
    yAxis.children.unshift(
      am5.Label.new(root, {
        rotation: -90,
        text: "purpose",
        y: am5.p50,
        center: am5.p50,
      })
    );

    var xAxis = chart.xAxes.push(
      am5xy.ValueAxis.new(root, {
        maxDeviation: 0,
        min: 0,
        extraMax: 0.1,
        renderer: am5xy.AxisRendererX.new(root, {
          strokeOpacity: 0.1,
        }),
      })
    );

    /* Show title on xAxis */
    xAxis.children.push(
      am5.Label.new(root, {
        text: "residency_since",
        x: am5.p50,
        centerX: am5.p50,
      })
    );

    // Add series
    var series = chart.series.push(
      am5xy.ColumnSeries.new(root, {
        name: "1",
        xAxis: xAxis,
        yAxis: yAxis,
        valueXField: "value",
        categoryYField: "label",
        fill: "#2596be",
        tooltip: am5.Tooltip.new(root, {
          pointerOrientation: "left",
          labelText: "{valueX}",
        }),
      })
    );

    // Rounded corners for columns
    series.columns.template.setAll({
      cornerRadiusTR: 0,
      cornerRadiusBR: 0,
      strokeOpacity: 0,
    });

    yAxis.data.setAll(data);
    series.data.setAll(data);

    chart.set(
      "cursor",
      am5xy.XYCursor.new(root, {
        behavior: "none",
        xAxis: xAxis,
        yAxis: yAxis,
      })
    );

    /* Create legend container */
    var legend = root.container.children.push(
      am5.Legend.new(root, {
        y: am5.percent(18),
        x: am5.percent(100),
        centerX: am5.percent(100),
        layout: root.verticalLayout,
      })
    );

    legend.data.push(series);

    return () => {
      root.dispose();
    };
  }, []);

  return (
    <div>
      <Box
        id={uniqueDivId}
        className="custom-chart"
        style={{
          height: "50vh",
          width: "80%",
        }}
      ></Box>
    </div>
  );
};
export default BarChart;
