import { Checkbox } from "@mui/material";
import { Box } from "@mui/system";
import { useAtom } from "jotai";
import React, { useEffect, useState } from "react";
import { useUpdateRiskVisibility } from "../../api/updateApiFunctions";
import { isAdminRiskFactorUpdate } from "../../atom/jotai";
import {
  actionConstants,
  moduleConstants,
  subModuleConstants,
} from "../../constants/accessManagementConstants";
import { isAccessProvided } from "../../services/assetManagementService";

const AdminRiskTableData = ({
  riskName,
  riskVisibility,
  riskId,
  type,
  setSelectedCheckboxes,
  selectedCheckboxes,
  selectedScore,
  accessDataByRole,
  lastIteration,
}) => {
  const [checked, setChecked] = useState(riskVisibility);
  const [, setRiskFactorUpdate] = useAtom(isAdminRiskFactorUpdate);

  const handleCheckClick = (e) => {
    const checkboxVal = e.target.checked;
    let fieldName;

    if (type === "app") {
      fieldName = "is_applies_to_app";
    } else if (type === "model") {
      fieldName = "is_applies_to_model";
    } else {
      fieldName = "is_applies_to_input_data_table";
    }

    const payload = {
      confidence_factors_config_id: riskId,
      [fieldName]: checkboxVal,
      score_id: selectedScore,
      type,
    };
    setSelectedCheckboxes((arr) => [...arr, payload]);
    setChecked(!checked);
    setRiskFactorUpdate(true);
  };

  return (
    <Box
      className={
        // type == "app"
        //   ? "admin_data_type_container_app"
        //   :
        "admin_data_type_container"
      }
      style={
        lastIteration === true ? { borderBottom: "1px solid #6d8291" } : {}
      }
    >
      <Box
        className={
          "admin_data_type_name"
          // riskVisibility
          //   ? "admin_data_type_name"
          //   : "admin_data_type_name_disable"
        }
      >
        {riskName}
      </Box>
      <Box className="admin_data_type_condition">
        {isAccessProvided(
          moduleConstants.admin_view,
          subModuleConstants.risk_factors,
          actionConstants.visibility,
          "execution",
          accessDataByRole
        ) === true ? (
          <Checkbox
            id="adminRiskFactrosCheckbox"
            checked={checked}
            sx={{
              color: "#6D8291",
              ":hover": { background: "none" },
            }}
            color="default"
            onChange={handleCheckClick}
          />
        ) : (
          <Checkbox
            id="adminRiskFactrosCheckbox"
            sx={{ color: "#999999" }}
            disabled
            checked={checked}
            color="default"
            onChange={handleCheckClick}
          />
        )}
      </Box>
    </Box>
  );
};

export default AdminRiskTableData;
