import React, { useEffect, useState } from "react";
import "./ExtractionHistory.css";
import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { useLocation } from "react-router-dom";
import {
  handleGetExtractHistoryErrLogFile,
  useGetExtractHistoryData,
} from "../../api/readApiFunctions";
import NoResult from "../NoResult/NoResult";
import DownloadIcon from "../../assets/images/adminPanel/Download.svg";
import DownloadDisabledIcon from "../../assets/images/adminPanel/DownloadDisabled.svg";
import { dateFormate, timeFormate } from "../../utils/basicUtils";
import ListPopover from "../ListPopover/ListPopover";

const ExtractionHistory = () => {
  const location = useLocation();
  const { data: getExtractHistoryData } = useGetExtractHistoryData(
    location.pathname.split("/")[1] == "discovery" &&
      location.pathname.split("/")[1] !== undefined
      ? true
      : false
  );

  const [openRegionListPopover, setOpenRegionListPopover] = useState();
  const [displayData, setDisplayData] = useState();
  const [isRegionFromExtraction, setIsRegionFromExtraction] = useState(false);

  return (
    <div
      className="extraction_history_container"
      id="extractHistTableContainer"
    >
      {getExtractHistoryData && getExtractHistoryData.length > 0 ? (
        <div className="extraction_history_table" id="extractHistTableDiv">
          <Table
            aria-label="simple table"
            sx={{ tableLayout: "fixed" }}
            stickyHeader
            id="extractHistTable"
          >
            <TableHead>
              <TableRow className="extraction_history_row_headings">
                <TableCell className="extraction_history_coloumn_headings">
                  Extraction ID
                </TableCell>
                <TableCell className="extraction_history_coloumn_headings">
                  Region
                </TableCell>
                <TableCell className="extraction_history_coloumn_headings">
                  Source
                </TableCell>
                <TableCell className="extraction_history_coloumn_headings">
                  Status
                </TableCell>
                <TableCell className="extraction_history_coloumn_headings">
                  User
                </TableCell>
                <TableCell className="extraction_history_coloumn_headings">
                  Start Date
                </TableCell>
                <TableCell className="extraction_history_coloumn_headings">
                  Start Time
                </TableCell>
                <TableCell className="extraction_history_coloumn_headings">
                  Error Log
                </TableCell>
                {/**Temp commented this code as discovery log functionality is not yet created */}
                {/* <TableCell className="extraction_history_coloumn_headings">
                  Discovery Log
                </TableCell> */}
              </TableRow>
            </TableHead>
            <TableBody>
              {getExtractHistoryData.length > 0 &&
                getExtractHistoryData.map((extractData, index) => (
                  <>
                    <TableRow
                      id={`extractHistTableRow${index}`}
                      key={extractData.history_id}
                    >
                      <TableCell className="extraction_history_coloumn">
                        {extractData?.history_id}
                      </TableCell>

                      <TableCell className="extraction_history_coloumn">
                        {/* /{extractData.regions} */}
                        {extractData.regions?.split(",").map((tags, index) => {
                          return (
                            <>
                              {(() => {
                                if (index < 1) {
                                  return (
                                    <span className="extraction_chip_with_name">
                                      {" "}
                                      {`  ${tags}`}{" "}
                                    </span>
                                  );
                                }
                              })()}
                              {(() => {
                                if (
                                  index + 1 ===
                                    extractData.regions?.split(",").length &&
                                  extractData.regions?.split(",").length > 1
                                ) {
                                  return (
                                    <span
                                      className="extraction_chip_without_name"
                                      onClick={(e) => {
                                        setOpenRegionListPopover(
                                          e.currentTarget
                                        );
                                        setDisplayData(
                                          extractData.regions.split(",")
                                        );
                                        setIsRegionFromExtraction(true);
                                      }}
                                    >
                                      +
                                      {extractData.regions?.split(",").length -
                                        1}
                                    </span>
                                  );
                                }
                              })()}
                            </>
                          );
                        })}
                      </TableCell>

                      <TableCell className="extraction_history_coloumn">
                        {extractData.source}
                      </TableCell>

                      <TableCell className="extraction_history_coloumn">
                        {extractData.status}
                      </TableCell>

                      <TableCell className="extraction_history_coloumn">
                        {extractData.contributor?.name}
                      </TableCell>

                      <TableCell className="extraction_history_coloumn">
                        {dateFormate(extractData.start_datetime)}
                      </TableCell>

                      <TableCell className="extraction_history_coloumn">
                        {timeFormate(extractData.start_datetime)}
                      </TableCell>

                      <TableCell className="extraction_history_coloumn">
                        <IconButton
                          id={`extractHistDwnldErrLgButton${index}`}
                          type="submit"
                          aria-label="Download"
                          onClick={() => {
                            handleGetExtractHistoryErrLogFile(
                              extractData?.history_id
                            );
                          }}
                        >
                          <img
                            src={DownloadIcon}
                            alt="Download"
                            className="icon_background"
                          />
                        </IconButton>
                      </TableCell>

                      {/**Temp commented this code as discovery log functionality is not yet created */}
                      {/* <TableCell className="extraction_history_coloumn">
                        <IconButton
                          id={`extractHistDwnldDiscovLgButton${index}`}
                          type="submit"
                          aria-label="Downlaod"
                          onClick={() => {}}
                        >
                          <img
                            src={DownloadIcon}
                            alt="Download"
                            className="icon_background"
                          />
                        </IconButton>
                      </TableCell> */}
                    </TableRow>
                  </>
                ))}
            </TableBody>
          </Table>
        </div>
      ) : (
        <div className="extraction_history_table" id="noAExtractHistoryData">
          <NoResult title={"No History Available"} />
        </div>
      )}

      {openRegionListPopover && (
        <ListPopover
          openRegionListPopover={openRegionListPopover}
          setOpenRegionListPopover={setOpenRegionListPopover}
          displayData={displayData}
          isRegionFromExtraction={isRegionFromExtraction}
        />
      )}
    </div>
  );
};

export default ExtractionHistory;
