import React from "react";
import { getSeletedDataInfo } from "../../services/searchServices";
import { Divider, Typography } from "@mui/material";
import HighlightText from "../HOC/HighlightText";

const EntityCard = ({ displayCardInfo, handleOpenEntityDetails }) => {
  return (
    <div className="nlp-result-entity-card" id="nlp-query-results-entity-card">
      <div className="nlp-card-info-div">
        <div style={{ display: "flex" }}>
          <img
            src={getSeletedDataInfo(displayCardInfo?.__typename)}
            alt={displayCardInfo?.__typename}
            width={20}
            className="nlp-card-img"
            // id={`${key}FilterChipImage`}
          />
        </div>
        <div style={{ marginLeft: "7px" }}>
          <Typography
            sx={{
              fontSize: "20px",
              fontStyle: "normal",
              fontWeight: "600",
              lineHeight: "normal",
            }}
          >
            {displayCardInfo.name}
          </Typography>
          <div className="nlp-card-entity-info-div">
            <span>Version {displayCardInfo.version}</span>
            <Divider
              orientation="vertical"
              variant="middle"
              flexItem
              className="nlp-card-entity-info-seperator"
            />
            <span>{displayCardInfo.regions}</span>
            <Divider
              orientation="vertical"
              variant="middle"
              flexItem
              className="nlp-card-entity-info-seperator"
            />
            <span>{displayCardInfo.business_units}</span>
          </div>
        </div>
      </div>
      <p
        className="nlp-card-link-text"
        onClick={() => {
          {
            handleOpenEntityDetails(displayCardInfo);
          }
        }}
      >
        {displayCardInfo.related_info}
        {/* <HighlightText text={displayCardInfo.related_info} keywords={"John"} /> */}
      </p>
      <p className="nlp-card-text">{displayCardInfo.info}</p>
    </div>
  );
};

export default EntityCard;
