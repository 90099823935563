import { CircularProgress } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useAtom } from "jotai";
import { useGetAllPolicies } from "../../api/readApiFunctions";
import AdminPolicyTable from "../../components/AdminPolicy/AdminPolicyTable";
import NewPolicy from "../../components/AdminPolicy/NewPolicy";
import AdminUsetlistSearch from "../../components/AdminUsetlistSearch/AdminUsetlistSearch";
import NoResult from "../../components/NoResult/NoResult";
import Layout from "../../layout/Layout";
import "./AdminPolicyPage.css";
import { accessDataByRoleAtom } from "../../atom/jotai";
import { isAccessProvided } from "../../services/assetManagementService";
import {
  moduleConstants,
  subModuleConstants,
} from "../../constants/accessManagementConstants";
import { PolicyNameConst } from "../../constants/policyConstants";

/**
 * Component : AdminPolicyPage
 *
 * Description : This is the parent component of Policy page.
 * The component is responsible for defining & declaring the layout of policy page
 * Here we called the get Policies API to get all the policies.
 *
 */

const AdminPolicyPage = () => {
  //state for managing access management system golbal value
  const [accessDataByRole, SetAccessDataByRole] = useAtom(accessDataByRoleAtom);

  const location = useLocation();
  // get all policies
  const {
    data: allPolicy,
    error: allPolicyError,
    isLoading: allPolicyLoading,
  } = useGetAllPolicies(
    "policy",
    "policy_view",
    location.pathname.split("/")[2] == "policy" &&
      location.pathname.split("/")[2] !== undefined &&
      accessDataByRole &&
      isAccessProvided(
        moduleConstants.policy,
        subModuleConstants.policy_view,
        null,
        "read",
        accessDataByRole
      )
      ? true
      : false
  );

  const [policyData, setPolicyData] = useState([]); //state to maintain policies list coming from database
  const [openNewPolicyDialog, setOpenNewPolicyDialog] = useState(false); // to show a create new businessunit dialog

  useEffect(() => {
    // allPolicy && setPolicyData(allPolicy?.data?.data?.score_type_config);
    if (allPolicy) {
      const filteredRows = allPolicy?.data?.data?.score_type_config.filter(
        (row) => {
          return (
            row.score_name !== "Human Impact" &&
            row.score_name !== "Konfer Confidence"
          );
        }
      );
      setPolicyData(filteredRows);
    }
  }, [allPolicy]);

  //Below useEffect is used for giving title to current page
  useEffect(() => {
    document.title = `${PolicyNameConst.policy} Page`;
  });

  return (
    <>
      <div className="policy-heading1" id="policyHeading">
        {PolicyNameConst.policies}
      </div>

      {isAccessProvided(
        moduleConstants.policy,
        subModuleConstants.policy_view,
        null,
        "read",
        accessDataByRole
      ) === true ? (
        <div className="policyList" id="businessunit">
          {allPolicy && (
            <div
              className="policyList-search-and-create paddingTRL30px"
              id="businessunitSearch"
            >
              <AdminUsetlistSearch
                allPolicy={allPolicy}
                policyData={policyData}
                setPolicyData={setPolicyData}
                isPolicy={true}
              />

              {isAccessProvided(
                moduleConstants.policy,
                subModuleConstants.policy_view,
                null,
                "write",
                accessDataByRole
              ) === true && (
                <button
                  id="createPolicy"
                  className="policyList-createNewPolicy-button"
                  onClick={() => {
                    setOpenNewPolicyDialog(true);
                  }}
                >
                  New {PolicyNameConst.policy}
                </button>
              )}
            </div>
          )}
          {allPolicyLoading ? (
            <CircularProgress style={{ margin: "100px 50px" }} />
          ) : policyData && policyData?.length === 0 ? (
            <div className="hgt userlist-users-table" id="policyNoResult">
              <NoResult title={"No Data Available"} />
            </div>
          ) : (
            <div className="userlist-users-table" id="policyTableMainDiv">
              <AdminPolicyTable
                policyData={policyData}
                accessDataByRole={accessDataByRole}
              />
            </div>
          )}
        </div>
      ) : (
        <div className="hgt userlist-users-table" id="noAccessToRoleManagment">
          {accessDataByRole && <NoResult title={"No Access to Policies"} />}
        </div>
      )}

      {openNewPolicyDialog && (
        <NewPolicy
          openNewPolicyDialog={openNewPolicyDialog}
          setOpenNewPolicyDialog={setOpenNewPolicyDialog}
          edit={false}
        />
      )}
    </>
  );
};

export default AdminPolicyPage;
