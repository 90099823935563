export const detailsFields = [
  // "__typename", //For demo Only,, we commented the code
  // "app_id",
  // "model_id",
  "name",
  "display_name",
  "calculate_score",
  "in_use",
  "model_type",
  "description",
  "created_at",
  "version",
  "algorithm_type",
  "external_model_dependency",
  // "input_data_table_id",
  "source",
  "source_type",
  "usage_type",
  "contributor",
];

export const disabledEditFields = [
  "__typename",
  "app_id",
  "model_id",
  "input_data_table_id",
  "created_at",
  "name",
];

export const detailsFieldsNames = {
  All: "All",
  __typename: "Type",
  // app_id: "App Id",
  input_data_table: "input_data_table",
  in_use: "In Use",
  model: "model",
  // model_id: "Model Id",
  name: "Name",
  display_name: "Display Name",
  calculate_score: "Score Inclusion",
  // model_type: "Model Type",//For demo
  model_type: "Members Type",
  description: "Description",
  created_at: "Created At",
  version: "Version",
  bias_score: "Bias Score",
  ethics_score: "Ethics Score",
  konfer_composite_score: "Konfer Confidence™",
  "Konfer composite score": "Konfer Confidence™",
  algorithm_type: "Algorithm",
  external_model_dependency: "External Model Dependency",
  // input_data_table_id: "Input Data Table Id",
  source: "Source",
  source_type: "Source Type",
  usage_type: "Usage Type",
  contributor: "Contributor",
  contributors: "Contributors",
  is_gdpr_compliant: "GDPR Compliant",
  models_hyperparameters_tables: "Hyper-Parameters",
};

export const detailsTypeNamesCapitalized = {
  __typename: "__typename",
  created_at: "created_at",
};

export const ANNOTATION_TYPES = {
  APP: "APP",
  MODEL: "MODEL",
  INPUT_DATA_TABLE: "INPUT_DATA_TABLE",
  INPUT_DATA_COLUMN: "INPUT_DATA_COLUMN",
  APP_SUMMARY: "APP_SUMMARY",
  BUSINESS_UNIT: "BUSINESS_UNIT",
  REGION: "REGION",
  ORG: "ORG",
};
