import React, { useEffect, useState, useCallback } from "react";
import { useAtom } from "jotai";
// import { useParams } from "react-router-dom";
import graphAppSvg from "../../../assets/images/listview/app-chip.svg";
import graphDataSetSvg from "../../../assets/images/adminPanel/Dataset.svg";
import graphModelSvg from "../../../assets/images/listview/model-chip.svg";
// import { KONFER_SCORE } from "../../constants/basicConstants";
// import { deslugifyText } from "../../utils/basicUtils";
// import GraphviewChart from "../GraphviewChart/GraphviewChart";
import "./SummaryPage.css";

import ControlSelector from "../../ControlSelector/ControlSelector";
import PopOver from "../../PopOver/PopOver";
import GraphviewInput from "../../GraphviewInput/GraphviewInput";
import { useGetAppsDetails } from "../../../api/readApiFunctions";
import { convertDataToTreeFormat } from "../../../services/graphviewServices";
import { CircularProgress } from "@mui/material";
import AmchartGraphview from "../../AmchartComponents/AmchartGraphview";
import { useParams } from "react-router-dom";
import { entityNameConst, typeNames } from "../../../constants/dataTypes";
import { useCreateDatasetCardFromTemplate } from "../../../api/createApiFunctions";
import WarningDialogForCard from "../../WarningDialogForCard/WarningDialogForCard";
import Cards from "../../Cards/CardsNew";
import { isAccessProvided } from "../../../services/assetManagementService";
import { accessDataByRoleAtom } from "../../../atom/jotai";

const SummaryPage = ({
  dataCount,
  setDataCount,
  graphviewConFactorsRefetch,
}) => {
  const { appId } = useParams();
  // state for controlling Konfer Score click
  const [checkedKonferScore, setCheckedKonferScore] = useState(false);
  // state for controlling Show Label click
  const [checkedShowLabel, setCheckedShowLabel] = useState(true); // state for controlling Show Label click
  const [nodeLabelFont, setNodeLabelFont] = useState(16); //State For increasing or decreasing graph nodes label, initial font size is 16
  const [zoomInAndOut, setZoomInAndOut] = useState(1.0); //State for maintaining Zoom in and Zoom out effect ,Initial value sets to 1.0 (means 100%)
  const [chartHeight, setChartHeight] = useState(1);
  const [openWarningDialogForCard, setOpenWarningDialogForCard] =
    useState(false);
  const limitForZoomInGraphView = 1.5; //150% zoom canvas
  const [positionOfMenuboxOnRightClick, setPositionOfMenuboxOnRightClick] =
    useState(null); //State for storing right clicked node position
  const open = Boolean(positionOfMenuboxOnRightClick);
  const [dataToNavigating, setDataForNavigating] = useState(null); //State for transferring data to node Right click popover
  const [idsState, setIdsState] = useState(null);
  const [callCreateCardAPI, setCallCreateCardAPI] = useState(false);

  const [formatedData, setFormatedData] = useState(null);
  const [showCard, setShowCard] = useState(false);
  const [accessDataByRole, setAccessDataByRole] = useAtom(accessDataByRoleAtom);

  // get app details
  const {
    data: appsDetails,
    error: appsDetailsError,
    isLoading: appsLoading,
  } = useGetAppsDetails(
    appId,
    true,
    null,
    null,
    "profile_view",
    "summary_view"
  );

  const {
    mutate: createDatasetCardFromTemplate,
    isSuccess,
    data: datasetCardTemplateRes,
  } = useCreateDatasetCardFromTemplate();

  //Funtion is used to disable built-in context menu
  const handleToControlLeftAndRightClick = useCallback((event) => {
    // prevent context menu from opening on right-click
    event.preventDefault();
  }, []);

  useEffect(() => {
    if (callCreateCardAPI === true) {
      let id;
      if (dataToNavigating?.__typename === typeNames.apps) {
        id =
          dataToNavigating?.route_id !== undefined
            ? dataToNavigating?.route_id
            : dataToNavigating.app_id;
      }

      if (dataToNavigating?.__typename === typeNames.models) {
        id =
          dataToNavigating?.route_id !== undefined
            ? dataToNavigating?.route_id
            : dataToNavigating.model_id;
      }

      if (dataToNavigating?.__typename === typeNames.datasets) {
        id =
          dataToNavigating?.route_id !== undefined
            ? dataToNavigating?.route_id
            : dataToNavigating.input_data_table_id;
      }
      createDatasetCardFromTemplate({
        id: id,
        type: dataToNavigating?.__typename,
      });
      setCallCreateCardAPI(false);
      setOpenWarningDialogForCard(false);
      setShowCard(true);
      setPositionOfMenuboxOnRightClick(null);
    }
  }, [callCreateCardAPI]);

  const handleCallDatasetApi = () => {
    let id;
    if (
      dataToNavigating.card === null ||
      dataToNavigating.card?.latest_version === null ||
      dataToNavigating.card?.template_version === null
    ) {
      if (dataToNavigating?.__typename === typeNames.apps) {
        id =
          dataToNavigating?.route_id !== undefined
            ? dataToNavigating?.route_id
            : dataToNavigating.app_id;
      }

      if (dataToNavigating?.__typename === typeNames.models) {
        id =
          dataToNavigating?.route_id !== undefined
            ? dataToNavigating?.route_id
            : dataToNavigating.model_id;
      }

      if (dataToNavigating?.__typename === typeNames.datasets) {
        id =
          dataToNavigating?.route_id !== undefined
            ? dataToNavigating?.route_id
            : dataToNavigating.input_data_table_id;
      }

      createDatasetCardFromTemplate({
        id: id,
        type: dataToNavigating?.__typename,
      });
      setShowCard(true);
      setOpenWarningDialogForCard(false);
      setPositionOfMenuboxOnRightClick(null);
    } else {
      if (
        dataToNavigating.card?.latest_version ===
        dataToNavigating.card?.template_version
      ) {
        setShowCard(true);
        setOpenWarningDialogForCard(false);
        setPositionOfMenuboxOnRightClick(null);
      } else {
        setShowCard(false);
        setOpenWarningDialogForCard(true);
        setPositionOfMenuboxOnRightClick(null);
      }
    }
    setShowCard(true);
  };

  // get the formatted data for rendering graphview
  useEffect(() => {
    if (appsDetails) {
      const data = convertDataToTreeFormat(
        appsDetails,
        checkedKonferScore,
        true
      );
      setFormatedData(data);

      setDataCount({
        app: data.info.appLength,
        model: data.info.modelLength,
        dataset: data.info.dataLength,
      });
    }
  }, [appsDetails]);

  if (appsLoading) {
    return <h2>Loading</h2>;
  }

  /* Below functions is call back function which stores the position cordinates of graph node. From that coordinates, right click
  constext menu is displayed   */
  const handleRightClickOnNode = (e) => {
    setPositionOfMenuboxOnRightClick(
      positionOfMenuboxOnRightClick === null
        ? {
            X: e.originalEvent.clientX,
            Y: e.originalEvent.clientY,
          }
        : null
    );
    setDataForNavigating(e.target._dataItem.dataContext); // Set the necessary data of node.
  };

  const handleRightClickMenuClose = () => {
    setPositionOfMenuboxOnRightClick(null);
    setDataForNavigating(null);
  };

  const handleSetCurrentData = (currentVal) => {
    setDataForNavigating((prevState) => ({
      ...prevState,
      cardData: currentVal,
    }));
  };

  return (
    <div
      style={{
        marginTop: "-20px",
      }}
      onContextMenu={handleToControlLeftAndRightClick}
    >
      {/* Show controls */}

      <h3 className="GraphHeading">Knowledge Graph</h3>

      <div className="graphView-controlSelector-container">
        <ControlSelector
          checkedKonferScore={checkedKonferScore}
          setCheckedKonferScore={setCheckedKonferScore}
          checkedShowLabel={checkedShowLabel}
          setCheckedShowLabel={setCheckedShowLabel}
          nodeLabelFont={nodeLabelFont}
          setNodeLabelFont={setNodeLabelFont}
          zoomInAndOut={zoomInAndOut}
          chartHeight={chartHeight}
          setChartHeight={setChartHeight}
          setZoomInAndOut={setZoomInAndOut}
          limitForZoomIn={limitForZoomInGraphView}
          // setOrphanNodes={setOrphanNodes}
          // orphanNodes={orphanNodes}
          graphViewNodes={false}
          isSummary={true}
        />
        <GraphviewInput />
      </div>
      {/* {data && ( */}
      <div className="cpo-graphview_container">
        {/* {data && ( */}
        <div className="cpo-head_container">
          <div className="label_headings">
            <div className="headings">
              <img src={graphAppSvg} alt="graphAppSvg" className="heads_img" />
              <h2 className="dataHeading">
                {entityNameConst.applications} ({dataCount.app})
              </h2>
            </div>
            <div className="headings">
              <img
                src={graphModelSvg}
                alt="graphAppSvg"
                className="heads_img"
              />
              <h2 className="dataHeading">
                {entityNameConst.models} ({dataCount.model})
              </h2>
            </div>
            {/** For the demo purpose commented the below code */}
            {/* <div className="headings">
              <img
                src={graphDataSetSvg}
                alt="graphAppSvg"
                className="heads_img"
              />
              <h2 className="dataHeadings">{entityNameConst.datasets}({dataCount.dataset})</h2>
            </div> */}
          </div>
        </div>

        <div className="wraperSection">
          {formatedData ? (
            <AmchartGraphview
              seriesData={formatedData.finalFormat}
              formatInfo={formatedData.info}
              checkedShowLabel={checkedShowLabel}
              nodeLabelFont={nodeLabelFont}
              zoomInAndOut={zoomInAndOut}
              handleRightClickOnNode={handleRightClickOnNode}
              chartHeight={chartHeight}
              orphanNodes={false}
              checkedKonferScore={checkedKonferScore}
            />
          ) : (
            <div className="graphview-loader">
              <CircularProgress />
            </div>
          )}
        </div>

        <PopOver
          open={open}
          positionOfMenuboxOnRightClick={positionOfMenuboxOnRightClick}
          handleRightClickMenuClose={handleRightClickMenuClose}
          // searchTextQuery={searchTextQuery}
          dataToNavigating={dataToNavigating}
          popoverContent={[
            "Share Details",
            isAccessProvided(
              "card_modal",
              "crud",
              null,
              "read",
              accessDataByRole
            ) && "Open Card",
            isAccessProvided(
              "details",
              "crud",
              null,
              "read",
              accessDataByRole
            ) && "Show Details",
          ]}
          setShowCard={setShowCard}
          handleCallDatasetApi={handleCallDatasetApi}

          //modelCardView={modelCardView}
        />
        {showCard && (
          <Cards
            cardId={dataToNavigating?.card_id}
            showCard={showCard}
            setShowCard={setShowCard}
            datasetCardTemplateRes={datasetCardTemplateRes}
            selectedRiskFactor={null}
            isSuccess={isSuccess}
            page="graphview"
            graphviewConFactorsRefetch={graphviewConFactorsRefetch}
            handleSetCurrentData={handleSetCurrentData}
          />
        )}
      </div>
    </div>
  );
};

export default SummaryPage;
