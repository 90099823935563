import { Circle } from "@mui/icons-material";
import React, { useEffect, useState } from "react";

import "../../ListviewFilter/ListviewFilter.css";
import InternalSearchBox from "../../InternalSearchBox/InternalSearchBox";
import { entityNameConst } from "../../../constants/dataTypes";

const ListviewComplianceFilter = ({
  scoreCount,
  findElement,
  setFindElement,
  handleDownHighlightedSelection,
  handleUpHighlightedSelection,
  prevHighlightedLOC,
  setprevHighlightedLOC,
  currentCount,
  setCurrentCount,
}) => {
  return (
    <>
      {/* search box */}
      <div className=".mb30px filter_score_field_container">
        {/* to show score */}

        <div className="score_counter-wrapper">
          <div className="score_text">
            <p>{entityNameConst.applications} by score: </p>
          </div>
          <div className="score_counter">
            <div className="score_ball score_blue">
              <Circle className="score_ball_color_below_limit" />
              <p className="score_ball-count">{scoreCount?.redScore}</p>
            </div>
            <div className="score_ball">
              <Circle className="score_ball_color_above_limit" />
              <p className="score_ball-count">{scoreCount?.blueScore}</p>
            </div>
          </div>
        </div>

        <InternalSearchBox
          findElement={findElement}
          setFindElement={setFindElement}
          currentCount={currentCount}
          setCurrentCount={setCurrentCount}
          prevHighlightedLOC={prevHighlightedLOC}
          setprevHighlightedLOC={setprevHighlightedLOC}
          handleDownHighlightedSelection={handleDownHighlightedSelection}
          handleUpHighlightedSelection={handleUpHighlightedSelection}
        />
      </div>
    </>
  );
};

export default ListviewComplianceFilter;
