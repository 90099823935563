import { atom } from "jotai";
import { riskfactorCategory } from "../constants/complianceConstants";
import { extractDataFromToken } from "../services/authServices";
import { getLocalTokens } from "../utils/authUtils";

//  jotai auth states

const token = getLocalTokens();
export const authUser = atom(
  token?.accessToken ? extractDataFromToken(token?.accessToken) : null
);

export const authTokens = atom(
  localStorage.getItem("tokens")
    ? JSON.parse(localStorage.getItem("tokens"))
    : null
);

export const isGraphview = atom(true);
export const isGraphviewChild = atom(false);

export const searchedWord = atom("");
export const selectedNode = atom(null);

export const businessUnits = atom(null);
export const businessUnitName = atom(null);
export const selectedBusinessUnit = atom([]);
export const appsNameDataComp = atom([]);

export const isSidebar = atom(false);
export const totalCountOfHighlightWord = atom(0); //Total count of highlited items

export const activeCountOfHighlightWord = atom(0); //Current position from all the highlighted items

export const positionOfHighlightWord = atom([]); //This is used to save all the highlighted items position/index

export const finalMapping = atom([]);

export const indexOfSearchedKeywordAtom = atom(-1);

export const totalCountForGraphViewAtom = atom(0);

export const activeCountForGraphViewAtom = atom(0);

export const findElementInGraphviewAtom = atom("");

export const matchElementUIDInGraphviewAtom = atom([]);
export const isSwitchToAdminPanelAtom = atom(false);
export const isAdmin = atom(true);
export const isAdminRiskFactorUpdate = atom(false);

export const isComplianceViewAtom = atom(true);
export const isComplianceSummaryView = atom(false);

export const appIdForOpeningSummarViewAtom = atom(-1);
export const extractStarted = atom(false);
export const orgNameAtom = atom("");

export const regionIDAtom = atom(-1);
export const regionNameAtom = atom("");

export const organizationIDAtom = atom(-1);
export const optionalOpenAnnotationAtom = atom(true);
export const feedBackCard = atom(false);

export const openCPOAnnotationsAtom = atom(false);

export const colNameForListviewFindAtom = atom("");
export const openSideBarAtom = atom(false);
export const unusedModelDatasetData = atom([]);
export const unusedModelDatasetListData = atom({});
export const azureExtractionInProgress = atom(false);
export const awsExtractionInProgress = atom(false);
export const detailBreadcrumbsData = atom([]);
export const observabilityBreadcrumbsData = atom([]);
export const uploadedExtractFiles = atom([]);

export const riskFactorCategoryNameAtom = atom(
  riskfactorCategory.Confidence_Factor
);

export const cardData = atom(null);

// state to maintain the new uploaded files and existing ones
export const documentFileUpload = atom([]);

//global state to maintain access management system data as per role for all pages
export const accessDataByRoleAtom = atom(null);

/**global state to maintain response getting from web socket ,
 * which informs user that card with new responses is ready.
 * Responses:  When card's all the boolean questions searched in open ai for particular document    */
export const bulkQueResGeneratedAtom = atom(false);


export const isTermsAndConditionsAccepted = atom(false);

