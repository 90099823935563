import mixpanel from "mixpanel-browser";

export const analyticServices = (ActivityName) => {
  MixpanelTracking();
  MixPanelActivity(ActivityName);
};

// mixpanel set user extra info
export const setUserAnalyticsInfo = (email) => {
  mixpanel.people.set({
    name: `${email.split("@")[0]}`,
    $email: `${email}`,
  });
};

//mixpanel trcking
export const MixpanelTracking = () => {
  mixpanel.init(`${process.env.REACT_APP_MIXPANEL_PROJECT_TOKEN}`, {
    debug: true,
    ignore_dnt: true,
  });
};

//mixpanel activity  trcking
export const MixPanelActivity = (ActivityName) => {
  let userId = localStorage.getItem("user");
  mixpanel.track(ActivityName, {});

  if(userId){
    mixpanel.identify(`${JSON.parse(userId)?.contributor_id}`);
  }  
  // mixpanel.identify(`8777`);
};
