import { CircularProgress, Modal } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { Close } from "@mui/icons-material";
import "./WarningModal.css";
import MigrationTable from "../MigrationTable/MigrationTable.jsx";
import { PDFDownloadLink } from "@react-pdf/renderer";
import PDFGenerator from "../PDFGeneration/PDFGenerator";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

const WarningModal = ({
  showMigrationModal,
  setShowMigrationModal,
  migrationDifference,
  cardResData,
  onUpgradeClick,
}) => {
  // mui styles
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "80%",
    // height: "60%",
    // bgcolor: "#E7EDF0",
    bgcolor: "#ffffff",
    boxShadow: 24,
    p: 4,
    borderRadius: "8px",
  };

  const [openPdf, setOpenPdf] = useState(false);

  const convertCardDataToPdfData = (cardResData) => {
    let tempData = [];
    cardResData &&
      cardResData?.sections?.length > 0 &&
      cardResData?.sections?.map((data) => {
        data.title !== "Details" &&
          data.subsections?.map((subSectionData, index) => {
            if (subSectionData.title === "Questionnaire") {
              let questionList = [];
              let answerList = [];

              subSectionData.questions?.length > 0 &&
                subSectionData?.questions?.map((question) => {
                  questionList.push(question.question);
                  if (
                    question.answer_field == null ||
                    question.answer_field == undefined
                  ) {
                    if (question.answer_type === "boolean") {
                      answerList.push("No");
                      tempData.push({
                        title: data.title,
                        question: question.question,
                        answer: "No",
                      });
                    } else if (
                      question.answer_type === "file" ||
                      question.answer_type === "text"
                    ) {
                      answerList.push("-");
                      tempData.push({
                        title: data.title,
                        question: question.question,
                        answer: "-",
                      });
                    }
                  } else {
                    answerList.push("Yes");
                    tempData.push({
                      title: data.title,
                      question: question.question,
                      answer: question.text,
                    });
                  }
                });
            }
          });
      });

    if (tempData?.length > 0) {
      return tempData;
    } else {
      tempData = [
        {
          title: "No data available",
          question: "No data available",
          answer: "No data available",
        },
      ];
      return tempData;
    }
  };

  const handleUpgradeClick = () => {
    // download the pdf
    setOpenPdf(true);
    // call the create card api
    onUpgradeClick();
  };

  return (
    <>
      <Modal
        id="warningDialog"
        sx={{
          marginLeft: 23,
          marginRight: 23,
        }}
        open={showMigrationModal}
        onClose={() => {
          setShowMigrationModal(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} id="modalCardBox">
          {migrationDifference ? (
            <>
              <div
                id="modalCardCloseButton"
                className="close_button"
                onClick={() => {
                  setShowMigrationModal(false);
                }}
              >
                <Close sx={{ "&.MuiIconButton-root": { width: "22px" } }} />
              </div>

              {/* show heading */}
              <div
                onClick={() => {
                  setShowMigrationModal(false);
                }}
              >
                {
                  <h2 className="migration_heading">
                    Upgrading from v{cardResData?.template_version}{" "}
                    {<ArrowForwardIcon />} v{cardResData?.latest_version}
                  </h2>
                }
              </div>

              {/* show table */}
              <MigrationTable migrationDifference={migrationDifference} />

              <div className="templateCardBtn">
                <div
                  className="templatePublishBtn"
                  onClick={handleUpgradeClick}
                >
                  Upgrade
                </div>
                <div
                  className="templateBtn"
                  onClick={() => {
                    setShowMigrationModal(false);
                  }}
                >
                  Cancel
                </div>
              </div>
            </>
          ) : (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <CircularProgress />
            </div>
          )}

          {openPdf && (
            <PDFDownloadLink
              document={
                <PDFGenerator
                  dataForTable={convertCardDataToPdfData(cardResData)}
                />
              }
              fileName="old-card-data"
            >
              {({ blob, url, loading, error }) =>
                blob &&
                window.open(URL.createObjectURL(blob), "createPdf") &&
                setOpenPdf(false)
              }
            </PDFDownloadLink>
          )}
        </Box>
      </Modal>
    </>
  );
};

export default WarningModal;
