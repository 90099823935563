import React from "react";
import { useAtom } from "jotai";
import {
  totalCountOfHighlightWord,
  activeCountOfHighlightWord,
  positionOfHighlightWord,
  indexOfSearchedKeywordAtom,
  finalMapping,
  colNameForListviewFindAtom,
} from "../../atom/jotai";
import { yellow } from "@mui/material/colors";
import "./HightLigthter.css";
import HighlightText from "../HOC/HighlightText";

const HighLighter = ({ text, highlight, columnName, index, findElement }) => {
  // const parts = text.split(new RegExp(`(${highlight})`, "gi"));
  const [activeCountOfHighlightedWord, setActiveCountOfHighlitedWord] = useAtom(
    activeCountOfHighlightWord
  );
  const [positionOfHighlightedWord, setPositionOfHighlightedWord] = useAtom(
    positionOfHighlightWord
  );
  const [indexOfSearchedKeyword, setIndexOfSearchedKeyword] = useAtom(
    indexOfSearchedKeywordAtom
  );
  const [finalPosition, setFinalPosition] = useAtom(finalMapping);
  const [colNameForListviewFind, setColNameForListviewFind] = useAtom(
    colNameForListviewFindAtom
  );

  var reg = new RegExp(`(${highlight})`, "gi");
  var final_str =
    text !== null &&
    text !== undefined &&
    text.toString().replace(reg, function (str, arg1, arg2, arg3) {
      if (
        index == activeCountOfHighlightedWord &&
        arg2 == indexOfSearchedKeyword &&
        columnName === colNameForListviewFind
      ) {
        return "<span>" + str + "</span>"; // orange color
      }
      return findElement !== null &&
        highlight !== null &&
        findElement &&
        highlight &&
        findElement?.toLowerCase() === highlight?.toLowerCase()
        ? `<mark>${str}</mark>`
        : str;
    });
  final_str = { __html: final_str };

  // console.log(text, highlight, columnName, index, findElement);

  return (
    <>
      <div
        dangerouslySetInnerHTML={final_str}
        className="highLight"
        id="resultTableItemHighlightingName"
      />
    </>
  );
};

export default HighLighter;
