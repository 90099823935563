import React, { useEffect, useState } from "react";
import { disabledEditFields } from "../../constants/detailConstants";
import AdminCheckbox from "../AdminCheckbox/AdminCheckbox";
import "./AdminSettingBox.css";

const AdminSettingBox = ({
  title,
  imageData,
  data,
  action,
  type,
  setSelectedCheckboxes,
  setAllSelected,
  allSelected,
  accessDataByRole,
}) => {
  return (
    <div className="box_container" id="dsBoxContainer">
      {/* title */}
      <div className="box_title" id="dsBoxTitle">
        <img src={imageData} />
        <h2>{title}</h2>
      </div>

      {/* Column headings */}
      <div className="colums_headings" id="dsBoxHeading">
        <h3>Field Name</h3>
        <h3>{action}</h3>
      </div>

      {/* Render checklists */}
      <div className="checkbox_lists" id="dsBoxCheckbox">
        <AdminCheckbox
          name="All"
          val={false}
          type={type}
          setSelectedCheckboxes={setSelectedCheckboxes}
          setAllSelected={setAllSelected}
          accessDataByRole={accessDataByRole}
        />
        {allSelected[type] === false &&
          data.map((val, index) => {
            return (
              <AdminCheckbox
                key={index}
                name={val.field_name}
                val={val.value}
                type={type}
                setSelectedCheckboxes={setSelectedCheckboxes}
                accessDataByRole={accessDataByRole}
              />
            );
          })}
        {allSelected[type] === true &&
          data.map((val, index) => {
            // the code used for not allow change default value
            if (!disabledEditFields.includes(val.field_name)) {
              val.value = true;
            }

            return (
              <AdminCheckbox
                key={index}
                name={val.field_name}
                val={val.value}
                type={type}
                setSelectedCheckboxes={setSelectedCheckboxes}
                accessDataByRole={accessDataByRole}
              />
            );
          })}
      </div>
    </div>
  );
};

export default AdminSettingBox;
