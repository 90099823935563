import React, { useEffect, useLayoutEffect, useState } from "react";
import * as am5 from "@amcharts/amcharts5";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import * as am5xy from "@amcharts/amcharts5/xy";
import "./HeatmapChart.css"

import { Box } from "@mui/system";

const HeatmapChart = ({data}) => {
  const uniqueDivId = Math.floor(Math.random() * 800000);

  useLayoutEffect(() => {
    let root = am5.Root.new(`${uniqueDivId}`);

    root.setThemes([am5themes_Animated.new(root)]);

    let chart = root.container.children.push(am5xy.XYChart.new(root, {
        panX: false,
        panY: false,
        wheelX: "none",
        wheelY: "none",
        layout: root.verticalLayout
      }));


      let yRenderer = am5xy.AxisRendererY.new(root, {
        visible: false,
        minGridDistance: 20,
        inversed: true
      });

    yRenderer.grid.template.set("visible", false);

    let yAxis = chart.yAxes.push(am5xy.CategoryAxis.new(root, {
    maxDeviation: 0,
    renderer: yRenderer,
    categoryField: "yAxisData"
    }));

    let xRenderer = am5xy.AxisRendererX.new(root, {
        visible: false,
        minGridDistance: 30,
        opposite:true
      });
      
      xRenderer.grid.template.set("visible", false);
      
      let xAxis = chart.xAxes.push(am5xy.CategoryAxis.new(root, {
        renderer: xRenderer,
        categoryField: "xAxisData"
      }));

      let series = chart.series.push(am5xy.ColumnSeries.new(root, {
        calculateAggregates: true,
        stroke: am5.color(0xffffff),
        clustered: false,
        xAxis: xAxis,
        yAxis: yAxis,
        categoryXField: "xAxisData",
        categoryYField: "yAxisData",
        valueField: "value"
      }));
      
      series.columns.template.setAll({
        tooltipText: "{value}",
        strokeOpacity: 1,
        strokeWidth: 2,
        width: am5.percent(100),
        height: am5.percent(100)
      });
      
      series.columns.template.events.on("pointerover", function(event) {
        let di = event.target.dataItem;
        if (di) {
          heatLegend.showValue(di.get("value", 0));
        }
      });

      series.events.on("datavalidated", function() {
        heatLegend.set("startValue", series.getPrivate("valueLow"));
        heatLegend.set("endValue", series.getPrivate("valueHigh"));
      });

      series.set("heatRules", [{
        target: series.columns.template,
        min: am5.color(0xf7fbff),
        max: am5.color(0x08306b),
        dataField: "value",
        key: "fill"
      }]);


      let heatLegend = chart.rightAxesContainer.children.push(am5.HeatLegend.new(root, {
        orientation: "vertical",
        endColor: am5.color(0x08306b),
        startColor: am5.color(0xf7fbff)
      }));

      // let data = [{
      //   xAxisData: "1",
      //   yAxisData: "1",
      //   value: 153
      // }, {
      //   xAxisData: "0",
      //   yAxisData: "1",
      //   value: 19
      // }, {
      //   xAxisData: "0",
      //   yAxisData: "0",
      //   value: 59
      // }, {
      //   xAxisData: "1",
      //   yAxisData: "0",
      //   value: 39
      // }]
      
      series.data.setAll(data.finalData);


      yAxis.data.setAll([
        { yAxisData: "1" },
        { yAxisData: "0" },

      ]);


      xAxis.data.setAll([
        { xAxisData: "1" },
        { xAxisData: "0" }
      ]);


      chart.appear(1000, 100);


    return () => {
      root.dispose();
    };
  }, []);

  return (
    <div>
      <Box
        id={uniqueDivId}
        style={{
          height: "30vh",
          width: "50%",
        }}
      ></Box>
      <div className="table_container" >
        {data && data.tableData && (
          <table className="table_border" >
              <tr  className="table_border">
                  {data.tableData.map(t => <th  className="table_border">{t.heading}</th>)}
              </tr>
              <tr  className="table_border">
                  {data.tableData.map(t => <th className="table_border">{t.data}</th>)}
              </tr>
          </table>
        )}
      </div>
    </div>
  );
};
export default HeatmapChart;
