import {
  CheckOutlined,
  CloseOutlined,
  Highlight,
  MoreVert,
} from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  IconButton,
  Menu,
  MenuItem,
} from "@mui/material";
import React, { useState } from "react";
import EditIcon from "../../assets/images/detail/edit_annotation.svg";
import DeleteIcon from "../../assets/images/detail/delete_annotation.svg";
import MoreVertIcon from "../../assets/images/annoation/more-vert.svg";

import "./AnnotationCard.css";
import HighlightText from "../HOC/HighlightText";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDeleteAnnotations } from "../../api/deleteApiFunctions";
import { useUpdateAnnotation } from "../../api/updateApiFunctions";
import { useAtom } from "jotai";
import { dateFormate, timeFormate } from "../../utils/basicUtils";
import Timer from "../../assets/images/annoation/Timer.svg";
import moment from "moment";
import { analyticServices } from "../../services/analyticServices";
import { accessDataByRoleAtom, authUser } from "../../atom/jotai";

import { MentionsInput, Mention } from "react-mentions";
import defaultStyle from "../../assets/css/mentionsStyle.js";
import { useCreateSlackAnnotation } from "../../api/createApiFunctions";
import { isAccessProvided } from "../../services/assetManagementService";
import {
  accessConstants,
  moduleConstants,
  subModuleConstants,
} from "../../constants/accessManagementConstants";

const AnnotationCard = ({ data, annotaionIndex, slackUsers, dataInfo }) => {
  // to store edit input
  const [editAnnotation, setEditAnnotation] = useState(data?.name);
  const [showEditInput, setShowEditInput] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [user] = useAtom(authUser);
  const [accessDataByRole, setAccessDataByRole] = useAtom(accessDataByRoleAtom);

  const open = Boolean(anchorEl);

  // react -query muatations
  const { mutate: updateAnnotation } = useUpdateAnnotation();
  const { mutate: deleteAnnotation } = useDeleteAnnotations();

  // to show a dialog box for a confirmation to delete an annotation
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  const { mutate: createSlackAnnotation } = useCreateSlackAnnotation();

  const handleEditAnnotations = (id) => {
    const annotationToString = handleAnnotationsRender(
      editAnnotation,
      slackUsers
    );
    if (editAnnotation) {
      const payload = {
        id,
        name: editAnnotation,
        text_name: annotationToString,
        subModInvoker: "detail",
      };

      const slackPayload = {
        annotationMessage: editAnnotation,
        url: `${window.location.protocol}//${window.location.host}/detail/${
          dataInfo?.__typename
        }/${dataInfo?.[`${dataInfo?.__typename}_id`]}`,
        subModInvoker: "detail",
      };
      analyticServices(`Annotation Edited :-  ${editAnnotation}`);
      updateAnnotation(payload);
      createSlackAnnotation(slackPayload);
      setShowEditInput(false);
      handleClose();
      toast.success("Annotation updated");
    }
  };

  const handleDeleteAnnotations = (annotationId) => {
    const payload = {
      id: annotationId.annotation_id,
      subModInvoker: "detail",
    };
    deleteAnnotation(payload);
    setShowEditInput(false);
    setOpenDeleteDialog(false);
    toast.success("Annotation deleted");
    analyticServices(`Annotation deleted : ${annotationId.name}`);

    // handleClose()
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCancelEdit = () => {
    setEditAnnotation(data.name);
    setShowEditInput(false);
    handleClose();
  };

  const handleMentionChange = (e) => {
    setEditAnnotation(e.target.value);
  };

  const handleDisplayTransform = (id) => {
    const user = slackUsers.find((user) => user.id === id);
    if (user) {
      return user.display;
    } else {
      return "";
    }
  };

  const handleAnnotationsRender = () => {
    // divide the sentence in array of words
    const splitText = data?.name.split(" ");
    let formattedText = [];

    if (splitText.length > 0) {
      splitText.map((word) => {
        let newWord = "";
        if (word.startsWith("<@")) {
          // find the user with that id and replace the word with that user name
          const userId = word.split("@")[1].split(">")[0];
          const user = slackUsers.find((user) => user.id === userId);
          newWord = `@${user?.display}` || "@slack-user";
        } else {
          // else keep the same
          newWord = word;
        }

        formattedText.push(newWord);
      });

      // join that array and return it in string
      return formattedText.join(" ");
    } else {
      return "NO-ANNOTATION-VALUE";
    }
  };

  console.log("data", data);

  return (
    <div
      className="annotation_card-wrapper"
      id={`annotationCardWrapper${annotaionIndex}`}
    >
      {showEditInput === false && (
        <div
          id={`annotationCard${annotaionIndex}`}
          className={
            annotaionIndex % 2 == 0 ? "annotation_card" : "annotation_card_odd"
          }
        >
          <div
            className="annotation_card_border"
            id={`annotationCard${annotaionIndex}Data`}
          >
            <p style={{ margin: "0px" }}>
              <div className="cardHeading">
                <span className="cardAuthorName mRight">
                  {data?.contributor?.name}
                </span>
                <span className="cardCreationDate mRight">
                  {dateFormate(data?.created_at)}
                </span>
                <span className="cardCreationDate">
                  {timeFormate(data?.created_at)}
                </span>
              </div>

              <HighlightText text={handleAnnotationsRender()} />
            </p>
          </div>
          {user.contributor_id == data.contributor_id &&
            (isAccessProvided(
              moduleConstants.annotation,
              subModuleConstants.detail,
              null,
              accessConstants.update,
              accessDataByRole
            ) ||
              isAccessProvided(
                "annotation",
                "detail",
                null,
                "delete",
                accessDataByRole
              )) && (
              <div
                id={`annotationCard${annotaionIndex}ActionDiv`}
                style={{ marginTop: "-5px" }}
              >
                <Button
                  id="basic-button"
                  aria-controls={open ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  onClick={handleClick}
                  sx={{ marginTop: "5px" }}
                >
                  <img
                    src={MoreVertIcon}
                    alt="move-art"
                    style={{ width: "5px" }}
                  />
                  {/* <MoreVert /> */}
                </Button>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                >
                  {isAccessProvided(
                    moduleConstants.annotation,
                    subModuleConstants.detail,
                    null,
                    accessConstants.update,
                    accessDataByRole
                  ) && (
                    <MenuItem onClick={() => setShowEditInput(true)}>
                      <img src={EditIcon} />
                    </MenuItem>
                  )}
                  {isAccessProvided(
                    moduleConstants.annotation,
                    subModuleConstants.detail,
                    null,
                    accessConstants.delete,
                    accessDataByRole
                  ) && (
                    <MenuItem
                      // onClick={() => handleDeleteAnnotations(data.annotation_id)}
                      onClick={() => setOpenDeleteDialog(true)}
                    >
                      <img src={DeleteIcon} width="14px" />
                    </MenuItem>
                  )}

                  <Dialog
                    id="deleteAnnotationDialog"
                    open={openDeleteDialog}
                    onClose={!openDeleteDialog}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                  >
                    <DialogTitle id="alert-dialog-title">
                      {"Delete this Annotation?"}
                    </DialogTitle>
                    <DialogActions>
                      <Button
                        id="deleteAnnotationDialogConfirmation"
                        value="YES"
                        onClick={() => handleDeleteAnnotations(data)}
                      >
                        YES
                      </Button>
                      <Button
                        id="deleteAnnotationDialogCancel"
                        value="NO"
                        onClick={() => {
                          setOpenDeleteDialog(false);
                        }}
                        autoFocus
                      >
                        NO
                      </Button>
                    </DialogActions>
                  </Dialog>
                </Menu>
              </div>
            )}
        </div>
      )}

      {showEditInput && (
        <div className="edit_wrapper" id="editAnnotations">
          {slackUsers && (
            <MentionsInput
              style={defaultStyle}
              className="annotation_input"
              value={editAnnotation}
              onChange={handleMentionChange}
              singleLine={true}
            >
              <Mention
                trigger="@"
                data={slackUsers}
                markup="<@__id__>"
                displayTransform={handleDisplayTransform}
                appendSpaceOnAdd={true}
              />
            </MentionsInput>
          )}
          <div className="edit_actions" id="editAnnotationsActionBtns">
            <Button
              id="addUpdateAnnotationIcon"
              sx={{
                // marginRight: "-20px",
                // marginLeft: "-18px",
                minWidth: "20px",
                padding: "0px",
              }}
              onClick={() => handleEditAnnotations(data.annotation_id)}
            >
              <CheckOutlined className="tick_action" />
            </Button>
            <Button
              onClick={handleCancelEdit}
              id="cancelUpdateAnnotation"
              sx={{ minWidth: "20px", padding: "0px" }}
            >
              <CloseOutlined />
            </Button>
          </div>
        </div>
      )}

      <ToastContainer />
    </div>
  );
};

export default AnnotationCard;
