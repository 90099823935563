import React from "react";
import { Grid, Typography, Chip, IconButton, Tooltip } from "@mui/material";
import VectorUp from "../../assets/images/riskFactor/VectorUp.svg";
import VectorDown from "../../assets/images/riskFactor/VectorDown.svg";
import "./RiskFactor.css";
import CircularProgress from "@mui/material/CircularProgress";
import AmchartRiskFactor from "../AmchartComponents/AmchartRiskFactor";

const RiskFactors = ({ riskFactorsData, isLoading }) => {
  return (
    <>
      {isLoading ? (
        <div className="riskFactorLoder" id="riskFactorLoader">
          <CircularProgress color="success" />
        </div>
      ) : (
        <div
          className={
            riskFactorsData !== undefined &&
            riskFactorsData?.data?.data &&
            riskFactorsData?.data?.data.length > 0 &&
            "riskfactor_container"
          }
          id="riskFactorContainer"
        >
          <Grid
            container
            spacing={1}
            className="riskfactor_table_container"
            id="riskFactorTableContainer"
          >
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              sx={{ display: "inline-grid" }}
              className="riskfactor_inner_container"
              id="riskFactorInnerContainer"
            >
              {riskFactorsData !== undefined &&
                riskFactorsData?.data?.data &&
                riskFactorsData?.data?.data.length > 0 &&
                riskFactorsData?.data?.data.map((risk, index) => {
                  if (
                    risk.risk_factors === "Konfer composite score" ||
                    risk.risk_factors === "Konfer Confidence"
                  ) {
                    return;
                  }
                  return (
                    <>
                      <div
                        className="riskfactor_grid"
                        id={`riskFactor${index}`}
                      >
                        <Typography>
                          <Tooltip
                            title={`${risk.risk_factors}`}
                            placement="top-start"
                          >
                            <div
                              className="riskfactor_name_column"
                              id="riskFactorsName"
                            >
                              {risk.risk_factors == "Konfer-Confidence"
                                ? " Konfer Confidence™"
                                : risk.risk_factors}
                            </div>
                          </Tooltip>
                        </Typography>
                        <div
                          style={{
                            display: "flex",
                            width: "100%",
                          }}
                          id="riskFactorChartDiv"
                        >
                          <div
                            className="riskfactor_chart_container"
                            id={`riskFactorChart${index}`}
                          >
                            <AmchartRiskFactor data={risk} />
                          </div>
                        </div>

                        <div
                          id={`riskFactorScoreChip${risk.id}`}
                          className={"riskfactor_chip_container"}
                          key={risk.id}
                        >
                          {risk?.quarter_data.map((item, index) => {
                            if (item.quarter.quarter === "current_month") {
                              return (
                                <>
                                  <Chip
                                    id={`riskFactorScoreChip${index}`}
                                    label={item.value}
                                    className={`riskfactor_chip ${
                                      item.value <= 5
                                        ? "chipcolor_belowlimit"
                                        : "chipcolor_abovelimit"
                                    }`}
                                  />

                                  <div
                                    className="riskfactor_icon_container"
                                    id={`riskFactorIcon${item.quarter.quarter_id}`}
                                    key={item.quarter.quarter_id}
                                  >
                                    <IconButton className="riskfactor_icon">
                                      {item.value <= 5 ? (
                                        <img src={VectorDown} alt="" />
                                      ) : (
                                        <img src={VectorUp} alt="" />
                                      )}
                                    </IconButton>
                                  </div>
                                </>
                              );
                            }
                          })}
                        </div>
                      </div>
                    </>
                  );
                })}
            </Grid>
          </Grid>
        </div>
      )}
    </>
  );
};

export default RiskFactors;
