import request from "graphql-request";
import axios from "axios";
import { useMutation, useQueryClient } from "react-query";
import { queryKeyConstants } from "../constants/basicConstants";
import { useAtom } from "jotai";
import { selectedNode } from "../atom/jotai";
import nodeApiInstance from "./instance/nodeApiInstance";
import {
  updateCardAnswerQuery,
  updateRegion,
  updateSubsectionTextDataQuery,
  createSubsectionTextDataQuery,
} from "../graphql/mutations";
import { dataTypes, typeNames } from "../constants/dataTypes";

const endpoint = `${process.env.REACT_APP_GRAPHQL_API}v1/graphql`;

export const useUpdateAnnotation = () => {
  const queryClient = useQueryClient();

  const updateAnnotation = async (payload) => {
    const { id, name, subModInvoker } = payload;
    const { data } = await nodeApiInstance.put(
      "/annotation/update-annotation",
      {
        name,
        annotation_id: id,
        subModInvoker,
      }
    );

    return data;
  };

  return useMutation(updateAnnotation, {
    onSuccess: () => {
      queryClient.invalidateQueries(queryKeyConstants.getAnnotation);
    },
  });
};

export const useUpdateNodeDetailsInfo = () => {
  const queryClient = useQueryClient();

  const [nodeData, setSelectedNode] = useAtom(selectedNode);

  const updateNodeDetailsInfo = (payload) => {
    return nodeApiInstance.patch("/details/update-details", payload);
  };

  return useMutation(updateNodeDetailsInfo, {
    onSuccess: (data) => {
      let key;
      const updatedData = data.data.data;

      if (updatedData.__typename == "app") {
        key = queryKeyConstants.appData;
      }
      if (updatedData.__typename == "model") {
        key = queryKeyConstants.modelData;
      }
      if (updatedData.__typename == "input_data_table") {
        key = queryKeyConstants.datasetData;
      }

      queryClient.invalidateQueries({
        queryKey: key,
      });

      const newData = { ...nodeData, ...updatedData };
      setSelectedNode(newData);
    },
  });
};

export const useUpdateRiskVisibility = () => {
  const updateRiskVisibility = async (data) => {
    const response = await nodeApiInstance.put(
      "/risk_factors/update-risk-factor-visibility",
      {
        data: data,
      }
    );

    return response;
  };

  return useMutation(updateRiskVisibility);
};

export const useUpdateEditDetails = () => {
  const queryClient = useQueryClient();

  const updateEditDetails = async (payload) => {
    const data = await nodeApiInstance.post(
      "/details/update-editable-fields",
      payload
    );
    return data;
  };

  return useMutation(updateEditDetails, {
    onSuccess: () => {
      queryClient.invalidateQueries(queryKeyConstants.getEditableFields);
    },
  });
};

export const useUpdateDataSetCardAnswer = () => {
  const queryClient = useQueryClient();

  const updateCardAnswer = (payload) => {
    const { card_question_id } = payload;
    const query = updateCardAnswerQuery(card_question_id);

    return axios.post(
      endpoint,
      JSON.stringify({
        query,
        variables: {
          input: payload,
        },
      })
    );
  };

  return useMutation(updateCardAnswer, {
    onSuccess: () => {
      queryClient.invalidateQueries(queryKeyConstants.modelCardData);
      queryClient.invalidateQueries(queryKeyConstants.getConfidenceFactors);
      queryClient.invalidateQueries(queryKeyConstants.appData);
      queryClient.invalidateQueries(queryKeyConstants.modelData);
      queryClient.invalidateQueries(queryKeyConstants.datasetData);
    },
  });
};

/* Update subsection text data */
export const useUpdateSubsectionTextData = () => {
  const queryClient = useQueryClient();

  const updateSubsectionTextData = (payload) => {
    const { text_data_id } = payload;

    const query = updateSubsectionTextDataQuery(text_data_id);

    return axios.post(
      endpoint,
      JSON.stringify({
        query,
        variables: {
          input: payload,
        },
      })
    );
  };

  return useMutation(updateSubsectionTextData, {
    onSuccess: () => {
      queryClient.invalidateQueries(queryKeyConstants.updateTextData);
    },
  });
};

/* insert subsection text data */
export const useCreateSubsectionTextFunc = () => {
  const queryClient = useQueryClient();

  const createSubsectionTextData = (payload) => {
    const query = createSubsectionTextDataQuery(payload);

    return axios.post(
      endpoint,
      JSON.stringify({
        query,
        variables: {
          input: payload,
        },
      })
    );
  };

  return useMutation(createSubsectionTextData, {
    onSuccess: () => {
      queryClient.invalidateQueries(queryKeyConstants.createTextData);
    },
  });
};

export const useUpdateRegion = () => {
  const queryClient = useQueryClient();

  const updateRegion = async (payload) => {
    const updatedData = {
      id: payload.region_id,
      name: payload.name,
      description: payload.description,
    };
    return nodeApiInstance.post("details/update-region", updatedData);
  };

  return useMutation(updateRegion, {
    onSuccess: () => {
      queryClient.invalidateQueries(queryKeyConstants.getRoleRegionBuData);
    },
  });
};

//CPO Update Annotation useMutation
export const useUpdateCPOAnnotation = () => {
  const queryClient = useQueryClient();

  const updateCPOAnnotation = async (payload) => {
    const { id, name } = payload;

    const { data } = await nodeApiInstance.put(
      "/annotation/update-annotation",
      {
        name,
        annotation_id: id,
        subModInvoker: "profile",
      }
    );

    return data;
  };

  return useMutation(updateCPOAnnotation, {
    onSuccess: (s) => {
      queryClient.invalidateQueries(queryKeyConstants.getCPOAnnotation);
    },
  });
};

export const useUpdateTermsAndCondAcceptance = () => {
  const updateTermsAndCondition = (payload) => {
    return nodeApiInstance.patch("/auth/update-user", payload);
  };

  return useMutation(updateTermsAndCondition, {
    onSuccess: (data) => {},
    onError: (error) => {},
  });
};

export const useUpdateRole = () => {
  const queryClient = useQueryClient();

  const updateRole = (payload) => {
    return nodeApiInstance.put("/role/update-role", payload);
  };

  return useMutation(updateRole);
};

export const useUpdateSubsectionTextDataAPI = () => {
  const queryClient = useQueryClient();
  const riskFactorCalculate = async (payload) => {
    const data = await nodeApiInstance.put(
      "/card/update-subsection-text-data",
      payload
    );
    return data;
  };

  return useMutation(riskFactorCalculate, {
    onSuccess: () => {
      queryClient.invalidateQueries(queryKeyConstants.riskfactors);
      queryClient.invalidateQueries(queryKeyConstants.appData);
      queryClient.invalidateQueries(queryKeyConstants.modelData);
      queryClient.invalidateQueries(queryKeyConstants.datasetData);
      queryClient.invalidateQueries(queryKeyConstants.modelCardData);
    },
  });
};

export const useUpdateScoreInclusion = () => {
  const queryClient = useQueryClient();
  const updateScoreInclusion = async (payload) => {
    const data = await nodeApiInstance.put(
      "/risk_factors/update-score-calculation-for-entity",
      payload
    );
    return data;
  };
  return useMutation(updateScoreInclusion, {
    onSuccess: () => {
      queryClient.invalidateQueries(queryKeyConstants.appData);
      queryClient.invalidateQueries(queryKeyConstants.modelData);
      queryClient.invalidateQueries(queryKeyConstants.datasetData);
    },
  });
};
