import React, { useState } from "react";
import Popover from "@mui/material/Popover";
import Button from "@mui/material/Button";
import "./GenAIPopOver.css";
import crossIcon from "../../assets/images/icons/cancel.svg";

const GenAIPopOver = ({ answer, snippet, handleClose, anchorEl }) => {
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const renderAnswer = (answer) => {
    let answerText = answer;
    if (answer === "I don't know") {
      answerText = "-";
    }
    if (!answer) {
      answerText = "Not Available";
    }
    return answerText;
  };

  const renderSnippet = (snippetText) => {
    let text = snippetText?.split("Generated at: ")[0];
    return text;
  };

  const renderSnippetGeneratedDate = (snippetText) => {
    let generatedDate = snippetText?.split("Generated at: ")[1];
    const d = new Date(generatedDate);
    return d?.toLocaleString();
  };

  return (
    <div>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        sx={{
          "& .MuiPopover-paper": {
            boxShadow: "0px 5px 10px rgba(0, 0, 0, 0.25)",
            borderRadius: "4px",
            border: "0.5px solid #6D8291 ",
            height: "221px",
            width: "30%",
            padding: "0px 0px 15px 15px",
            boxShadow: "0px 0px 8px 0px #00000040",
          },
        }}
      >
        <>
          <div
            className="gen_ai_popoup_cross"
            style={{ cursor: "pointer" }}
            onClick={handleClose}
          >
            <img src={crossIcon} />
          </div>
          <p className="gen_res_value">
            Generated Response: {renderAnswer(answer)}
          </p>

          {snippet && (
            <p className="gen_res_snippet">{renderSnippet(snippet)}</p>
          )}

          {snippet && (
            <p className="gen_res_date">
              {renderSnippetGeneratedDate(snippet)}
            </p>
          )}
        </>
      </Popover>
    </div>
  );
};

export default GenAIPopOver;
