import React, { useEffect } from "react";
import Layout from "../../layout/Layout";

const ReleaseInfoComponent = (props) => {
  let a =
    window.innerHeight <= 760 ? 400 : window.innerHeight <= 900 ? 550 : 720;

  //Below useEffect is used for giving title to current page
  useEffect(() => {
    document.title = "Konfer Release Info Page";
  });

  return (
    <div
      style={{
        padding: "25px",
      }}
    >
      <h2
        style={{
          paddingBottom: "15px",
        }}
      >
        Release Information
      </h2>
      <div style={{ maxHeight: `${a}px`, overflowY: "auto " }}>
      <div>
          <h3>Release Version - 6.1.2 </h3>
          <div>
            <ul>
              <li
                style={{
                  marginBottom: "15px",
                }}
              >
                Welcome our all new GenAI!
              </li>
              <li
                style={{
                  marginBottom: "15px",
                }}
              >
                We have externalized the prompts to make it more configurable and quicker to deploy
              </li>
              <li
                style={{
                  marginBottom: "15px",
                }}
              >
                Enhanced the GenAI evidence snippet when the model doesn't know the answer
              </li>
              <li
                style={{
                  marginBottom: "15px",
                }}
              >
                We are now tracking time taken for each task in the API by adding logs for document loading, embedding generation, answer generation
              </li>
              <li
                style={{
                  marginBottom: "15px",
                }}
              >
                Added support for tracking the cost behind each API call (embedding and GenAI model).
              </li>
              <li
                style={{
                  marginBottom: "15px",
                }}
              >
                The overlapping API call issue is fixed by changing the thread count to 1
              </li>
              <li
                style={{
                  marginBottom: "15px",
                }}
              >
                UI enhancements to support pt. no. 3
              </li>
            </ul>
          </div>
        </div>
        <hr style={{ margin: "40px 0px" }} />
      <div>
          <h3>Release Version - 6.1.1 </h3>
          <div>
            <ul>
              <li
                style={{
                  marginBottom: "15px",
                }}
              >
                Ignore all duplicate rows in the CSV itself apart from the 1st occurrence
              </li>
              <li
                style={{
                  marginBottom: "15px",
                }}
              >
                Overwrite/Append duplicate question data in the database from the one available in the CSV
              </li>
              <li
                style={{
                  marginBottom: "15px",
                }}
              >
                Questions can now be deleted by policy tag even if template is in draft mode
              </li>
              <li
                style={{
                  marginBottom: "15px",
                }}
              >
                Query caching support added in NLQ for cost optimization
              </li>
              <li
                style={{
                  marginBottom: "15px",
                }}
              >
                Reduced worker processes for NLQ from 3 to 1
              </li>
            </ul>
          </div>
        </div>
        <hr style={{ margin: "40px 0px" }} />
      <div>
          <h3>Release Version - 6.1 </h3>
          <div>
            <ul>
              <li
                style={{
                  marginBottom: "15px",
                }}
              >
                Support for Natural Language Query added
              </li>
              <li
                style={{
                  marginBottom: "15px",
                }}
              >
                Implemented Swagger for API documentation & usage
              </li>
              <li
                style={{
                  marginBottom: "15px",
                }}
              >
                Support to add organization logo through API
              </li>
              <li
                style={{
                  marginBottom: "15px",
                }}
              >
                Modified from 'Yes to all' to 'Respond to all' that works based on the desired response
              </li>
              <li
                style={{
                  marginBottom: "15px",
                }}
              >
                Schema Change: Template questions are now stored in the table instead of a JSON column
              </li>
              <li
                style={{
                  marginBottom: "15px",
                }}
              >
                UI Enhancements
              </li>
              <li
                style={{
                  marginBottom: "15px",
                }}
              >
                Bug Fixes
              </li>
            </ul>
          </div>
        </div>
        <hr style={{ margin: "40px 0px" }} />
      <div>
          <h3>Release Version - 6.0.1 </h3>
          <div>
            <ul>
              <li
                style={{
                  marginBottom: "15px",
                }}
              >
                Added "Sign Out" button in error pages
              </li>
              <li
                style={{
                  marginBottom: "15px",
                }}
              >
                Enhancement in the Profile view table
              </li>
              <li
                style={{
                  marginBottom: "15px",
                }}
              >
                Bugfix: GenAI document caching issue between requests
              </li>
              <li
                style={{
                  marginBottom: "15px",
                }}
              >
                Bugfix: Application freeze upon docunent deletion in cardws
              </li>
            </ul>
          </div>
        </div>
        <hr style={{ margin: "40px 0px" }} />
      <div>
          <h3>Release Version - 6.0 </h3>
          <div>
            <ul>
              <li
                style={{
                  marginBottom: "15px",
                }}
              >
                All new IAM based authentication & authorization
                <ul>
                  <li>You can now create tailormade roles according to your needs for your organization</li>
                  <li>This enables you to have more control over who has access to what information</li>
                  <li>Create, Clone, Update & Delete roles from the 'Role Management' screen in Admin Panel</li>
                  <li>Assign role to your user according to the requiremnt</li>
                </ul>
              </li>
              <li
                style={{
                  marginBottom: "15px",
                }}
              >
                All new GenAI response generator! - Get answers to your policy questions automatically via our GenAI response generator
                <ul>
                  <li>You can generate answers for entire card via our GenAI feature OR</li>
                  <li>You can also get response for a single question by using the bulb icon right beside the question</li>
                  <li>What about custom question? We have you covered there too!</li>
                </ul>
              </li>
              <li
                style={{
                  marginBottom: "15px",
                }}
              >
                Enhancements in import card template CSV
              </li>
              <li
                style={{
                  marginBottom: "15px",
                }}
              >
                Support for Organization logo is enhanced
              </li>
              <li
                style={{
                  marginBottom: "15px",
                }}
              >
                Now track your extraction history on our 'Extraction History' tab - You can also download info or error logs
              </li>
              <li
                style={{
                  marginBottom: "15px",
                }}
              >
                UI Enhancements
              </li>
              <li
                style={{
                  marginBottom: "15px",
                }}
              >
                Bug Fixes
              </li>
            </ul>
          </div>
        </div>
        <hr style={{ margin: "40px 0px" }} />
      <div>
          <h3>Release Version - 5.2 </h3>
          <div>
            <ul>
              <li
                style={{
                  marginBottom: "15px",
                }}
              >
                Now all errors and requests for the server are logged in a file
              </li>
              <li
                style={{
                  marginBottom: "15px",
                }}
              >
                Fix auto increment primary key in PostgreSQL
              </li>
              <li
                style={{
                  marginBottom: "15px",
                }}
              >
                Import questionnaire responses from CSV
              </li>
              <li
                style={{
                  marginBottom: "15px",
                }}
              >
                Card template modifications
              </li>
              <li
                style={{
                  marginBottom: "15px",
                }}
              >
                Shift reset database functionality in the backend
              </li>
              <li
                style={{
                  marginBottom: "15px",
                }}
              >
                Remove manual passing of primary key in API
              </li>
              <li
                style={{
                  marginBottom: "15px",
                }}
              >
                Score inclusion/exclusion functionality on asset & profile tables
              </li>
              <li
                style={{
                  marginBottom: "15px",
                }}
              >
                Card template refactoring
              </li>
              <li
                style={{
                  marginBottom: "15px",
                }}
              >
                API to get extraction history (more coming in future release)
              </li>
              <li
                style={{
                  marginBottom: "15px",
                }}
              >
                UI Enhancements
              </li>
              <li
                style={{
                  marginBottom: "15px",
                }}
              >
                Bug Fixes
              </li>
            </ul>
          </div>
        </div>
        <hr style={{ margin: "40px 0px" }} />
      <div>
          <h3>Release Version - 5.1 </h3>
          <div>
            <ul>
              <li
                style={{
                  marginBottom: "15px",
                }}
              >
                Download card responses in CSV format
              </li>
              <li
                style={{
                  marginBottom: "15px",
                }}
              >
                Enhancements for Safari browser
              </li>
              <li
                style={{
                  marginBottom: "15px",
                }}
              >
                Converted some GraphQL queries to REST APIs
              </li>
              <li
                style={{
                  marginBottom: "15px",
                }}
              >
                Shifted Code Analysis UI to card's "Details" section
              </li>
              <li
                style={{
                  marginBottom: "15px",
                }}
              >
                Question ID changed to alphanumeric
              </li>
              <li
                style={{
                  marginBottom: "15px",
                }}
              >
                UI Enhancements
              </li>
              <li
                style={{
                  marginBottom: "15px",
                }}
              >
                Bug Fixes
              </li>
            </ul>
          </div>
        </div>
        <hr style={{ margin: "40px 0px" }} />
        <div>
          <h3>Release Version - 5.0 </h3>
          <div>
            <ul>
              <li
                style={{
                  marginBottom: "15px",
                }}
              >
                Introducing all new policy based scoring!
                <ul>
                  <li>Every policy now has its own score</li>
                  <li>Available in Asset View & Policy View Table</li>
                  <li>
                    To use this feature create a policy, tag it in template and,
                    answer the questions!
                  </li>
                  <li>Summary level upto Org is also supported!</li>
                </ul>
              </li>
              <li
                style={{
                  marginBottom: "15px",
                }}
              >
                Multi-response type question support - Powerful addition to the
                Entity cards!
                <ul>
                  <li>Combinations of the three response type are possible!</li>
                  <li>The three response types are boolean, text and file</li>
                  <li>A total of 4 combos of the above are possible</li>
                </ul>
              </li>
              <li
                style={{
                  marginBottom: "15px",
                }}
              >
                Negative desired response support added
              </li>
              <li
                style={{
                  marginBottom: "15px",
                }}
              >
                Highly requested feature to exclude entities scoring is now
                available! (Available in Details View)
              </li>
              <li
                style={{
                  marginBottom: "15px",
                }}
              >
                Introducing all powerful Card Migration feature!
                <ul>
                  <li>
                    Don't worry, we won't let your responses disappear now!
                  </li>
                  <li>
                    Wanna see what has changed since the last template? we have
                    you covered!
                  </li>
                  <li>Check what are the major changes via the new UI</li>
                </ul>
              </li>
              <li
                style={{
                  marginBottom: "15px",
                }}
              >
                Upload files for the document header in the card's details
                section.
              </li>
              <li
                style={{
                  marginBottom: "15px",
                }}
              >
                Card Refactoring
              </li>
              <li
                style={{
                  marginBottom: "15px",
                }}
              >
                Profile View Table Refactoring
              </li>
              <li
                style={{
                  marginBottom: "15px",
                }}
              >
                UI Enhancements
              </li>
              <li
                style={{
                  marginBottom: "15px",
                }}
              >
                Bug Fixes
              </li>
            </ul>
          </div>
        </div>
        <hr style={{ margin: "40px 0px" }} />
        <div>
          <h3>Release Version - 4.5 </h3>
          <div>
            <ul>
              <li
                style={{
                  marginBottom: "15px",
                }}
              >
                Now you can edit sub-section text in entity cards
              </li>
              <li
                style={{
                  marginBottom: "15px",
                }}
              >
                Role access management through admin panel
                <ul>
                  <li>Add a role</li>
                  <li>List roles</li>
                  <li>Update a role</li>
                  <li>Delete a role</li>
                  <li>Clone a role</li>
                </ul>
              </li>
              <li
                style={{
                  marginBottom: "15px",
                }}
              >
                Hyperparameters can now be seens in the 'details' section of
                card
              </li>
              <li
                style={{
                  marginBottom: "15px",
                }}
              >
                Extraction Credentials are now stored in the database
              </li>
              <li
                style={{
                  marginBottom: "15px",
                }}
              >
                Support for generic file extraction
              </li>
              <li
                style={{
                  marginBottom: "15px",
                }}
              >
                UI Enhancements
              </li>
              <li
                style={{
                  marginBottom: "15px",
                }}
              >
                Bug Fixes
              </li>
            </ul>
          </div>
        </div>
        <hr style={{ margin: "40px 0px" }} />
        <div>
          <h3>Release Version - 4.4 </h3>
          <div>
            <ul>
              <li
                style={{
                  marginBottom: "15px",
                }}
              >
                Policy Based Filtering Support in cards, template and report
              </li>
              <li
                style={{
                  marginBottom: "15px",
                }}
              >
                Policy management through admin panel
                <ul>
                  <li>Add a policy</li>
                  <li>List policies</li>
                  <li>Update a policy</li>
                </ul>
                Note: Integration of Policies is not yet completed for the whole
                product.
              </li>
              <li
                style={{
                  marginBottom: "15px",
                }}
              >
                UI Refactoring for Asset List View/Page
              </li>
              <li
                style={{
                  marginBottom: "15px",
                }}
              >
                Human Impact score calculation for
                <ul>
                  <li>App Summary Level</li>
                  <li>Business Unit Level</li>
                  <li>Region Level</li>
                  <li>Organization Level</li>
                </ul>
              </li>
              <li
                style={{
                  marginBottom: "15px",
                }}
              >
                UI Enhancements
              </li>
              <li
                style={{
                  marginBottom: "15px",
                }}
              >
                Bug Fixes
              </li>
            </ul>
          </div>
        </div>
        <hr style={{ margin: "40px 0px" }} />
        <div>
          <h3>Release Version - 4.3.1 </h3>
          <div>
            <ul>
              <li
                style={{
                  marginBottom: "15px",
                }}
              >
                Sidebar is now by default in collapse mode
              </li>
              <li
                style={{
                  marginBottom: "15px",
                }}
              >
                Bug - Multiple options in sidebar were getting selected
              </li>
              <li
                style={{
                  marginBottom: "15px",
                }}
              >
                Bug - Rendering issue in template - Latest template not showing
                after publishing
              </li>
            </ul>
          </div>
        </div>
        <hr style={{ margin: "40px 0px" }} />
        <div>
          <h3>Release Version - 4.3 </h3>
          <div>
            <ul>
              <li
                style={{
                  marginBottom: "15px",
                }}
              >
                Konfer Confidence scoring is now using min logic instead of avg
              </li>
              <li
                style={{
                  marginBottom: "15px",
                }}
              >
                New score tab UI in details page
              </li>
              <li
                style={{
                  marginBottom: "15px",
                }}
              >
                Support for importing template from CSV
              </li>
              <li
                style={{
                  marginBottom: "15px",
                }}
              >
                New and improved cards UI
              </li>
              <li
                style={{
                  marginBottom: "15px",
                }}
              >
                Lots of UI enhancements
              </li>
              <li
                style={{
                  marginBottom: "15px",
                }}
              >
                Bug fixes
              </li>
            </ul>
          </div>
        </div>
        <hr style={{ margin: "40px 0px" }} />
        <div>
          <h3>Release Version - 4.2 </h3>
          <div>
            <ul>
              <li
                style={{
                  marginBottom: "15px",
                }}
              >
                Human Impact support in application
              </li>
              <li
                style={{
                  marginBottom: "15px",
                }}
              >
                Support for Human Impact score
              </li>
              <li
                style={{
                  marginBottom: "15px",
                }}
              >
                Upload Files now for file type questions
              </li>
              <li
                style={{
                  marginBottom: "15px",
                }}
              >
                Full support for policy tagging of questions
              </li>
              <li
                style={{
                  marginBottom: "15px",
                }}
              >
                Support for showing "function call graph" for app in details
                panel (Demo specific)
              </li>
              <li
                style={{
                  marginBottom: "15px",
                }}
              >
                Support for showing imported libraries or dependencies for an
                app in details screen (Demo specific)
              </li>
              <li
                style={{
                  marginBottom: "15px",
                }}
              >
                Support for showing ideal entity card in details panel (Demo
                specific)
              </li>
              <li
                style={{
                  marginBottom: "15px",
                }}
              >
                UI enhancements
              </li>
              <li
                style={{
                  marginBottom: "15px",
                }}
              >
                Bug fixes
              </li>
            </ul>
          </div>
        </div>
        <hr style={{ margin: "40px 0px" }} />
        <div>
          <h3>Release Version - 4.1 </h3>
          <div>
            <ul>
              <li
                style={{
                  marginBottom: "15px",
                }}
              >
                Entity card report generation
              </li>
              <li
                style={{
                  marginBottom: "15px",
                }}
              >
                Policy tagging support for questions
              </li>
              <li
                style={{
                  marginBottom: "15px",
                }}
              >
                Text type answer support for entity card
              </li>
              <li
                style={{
                  marginBottom: "15px",
                }}
              >
                Enhanced stage-wise extraction progress
              </li>
              <li
                style={{
                  marginBottom: "15px",
                }}
              >
                Azure ML extraction support added
              </li>
              <li
                style={{
                  marginBottom: "15px",
                }}
              >
                UI enhancements
              </li>
              <li
                style={{
                  marginBottom: "15px",
                }}
              >
                Bug fixes
              </li>
            </ul>
          </div>
        </div>
        <hr style={{ margin: "40px 0px" }} />
        <div>
          <h3>Release Version - 4.0 </h3>
          <div>
            <ul>
              <li
                style={{
                  marginBottom: "15px",
                }}
              >
                T&C Agreement for first yime logged-in user
              </li>
              <li
                style={{
                  marginBottom: "15px",
                }}
              >
                Application card template support
              </li>
              <li
                style={{
                  marginBottom: "15px",
                }}
              >
                Save draft functionality support in template
              </li>
              <li
                style={{
                  marginBottom: "15px",
                }}
              >
                Application card with questionnaire support
              </li>
              <li
                style={{
                  marginBottom: "15px",
                }}
              >
                New scoring mechanism for risk factors
              </li>
              <li
                style={{
                  marginBottom: "15px",
                }}
              >
                Card upgradation/migration to latest version w.r.t template
              </li>
              <li
                style={{
                  marginBottom: "15px",
                }}
              >
                Support for downloading card as PDF while upgrading
              </li>
              <li
                style={{
                  marginBottom: "15px",
                }}
              >
                Added 'Deployment Region' support in list view
              </li>
              <li
                style={{
                  marginBottom: "15px",
                }}
              >
                Added 'In-use' field support for models in details view
              </li>
              <li
                style={{
                  marginBottom: "15px",
                }}
              >
                UI enhancements
              </li>
              <li
                style={{
                  marginBottom: "15px",
                }}
              >
                Bug fixes
              </li>
            </ul>
          </div>
        </div>
        <hr style={{ margin: "40px 0px" }} />
        <div>
          <h3>Release Version - 3.5 </h3>
          <div>
            <ul>
              <li
                style={{
                  marginBottom: "15px",
                }}
              >
                Model Card Template
              </li>
              <li
                style={{
                  marginBottom: "15px",
                }}
              >
                Truncate long labels of nodes in graph
              </li>
              <li
                style={{
                  marginBottom: "15px",
                }}
              >
                Integration of Graphs/Charts on Model Card
              </li>
              <li
                style={{
                  marginBottom: "15px",
                }}
              >
                Enhancement in Model Card
              </li>
              <li
                style={{
                  marginBottom: "15px",
                }}
              >
                Bug fixes
              </li>
            </ul>
          </div>
        </div>
        <hr style={{ margin: "40px 0px" }} />
        <div>
          <h3>Release Version - 3.4 </h3>
          <div>
            <ul>
              <li
                style={{
                  marginBottom: "15px",
                }}
              >
                Dataset Card Template
              </li>
              <li
                style={{
                  marginBottom: "15px",
                }}
              >
                Compliance View - Annotation
                <ul>
                  <li>Adding an Annotation</li>
                  <li>Deleting an Annotation</li>
                  <li>Modifying an Annotation</li>
                </ul>
              </li>
              <li
                style={{
                  marginBottom: "15px",
                }}
              >
                Generation of Dataset Card from Template
              </li>
              <li
                style={{
                  marginBottom: "15px",
                }}
              >
                Show Extraction is in progress or not
              </li>
              <li
                style={{
                  marginBottom: "15px",
                }}
              >
                Asset View - Details Screen - Make Contributor field editable
              </li>
              <li
                style={{
                  marginBottom: "15px",
                }}
              >
                Admin Panel - Extraction Screen - Ability to save SageMaker
                Configuration
              </li>
              <li
                style={{
                  marginBottom: "15px",
                }}
              >
                Bug fixes
              </li>
            </ul>
          </div>
        </div>
        <hr style={{ margin: "40px 0px" }} />
        <div>
          <h3>Release Version - 3.3 </h3>
          <div>
            <ul>
              <li
                style={{
                  marginBottom: "15px",
                }}
              >
                Added support for Region in the application.
              </li>
              <li
                style={{
                  marginBottom: "15px",
                }}
              >
                Region Management Screen
                <ul>
                  <li>Adding a Region</li>
                  <li>Deleting a Region</li>
                  <li>Modifying a Region</li>
                  <li>Assigning of Region to Bussiness Unit</li>
                </ul>
              </li>
              <li
                style={{
                  marginBottom: "15px",
                }}
              >
                Bug fixes
              </li>
            </ul>
          </div>
        </div>
        <hr style={{ margin: "40px 0px" }} />
        <div>
          <h3>Release Version - 3.2.1 </h3>
          <div>
            <ul>
              <li
                style={{
                  marginBottom: "15px",
                }}
              >
                Minor CSS changes in Search Screen
              </li>
              <li
                style={{
                  marginBottom: "15px",
                }}
              >
                Removal of certain features from Data Set Card (To be later
                supported through Template screen)
                <ul>
                  <li>Adding a question</li>
                  <li>Deleting a question</li>
                  <li>Modifying a question</li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
        <hr style={{ margin: "40px 0px" }} />
        <div>
          <h3>Release Version - 3.2</h3>
          <div>
            <ul>
              <li
                style={{
                  marginBottom: "15px",
                }}
              >
                Compliance View - Now the user can see the confidence factors of
                applications on a Business Unit Level
                <ul>
                  <li>Overview of Confidence Factors on Business Unit Level</li>
                  <li>
                    Application Summary - Visualize Confidence factors and
                    application relations
                  </li>
                </ul>
              </li>
              <li
                style={{
                  marginBottom: "15px",
                }}
              >
                Manage Data Set Card
                <ul>
                  <li>Add Questions in the Data Card</li>
                  <li>Add Answers in the Data Card</li>
                  <li>Update Questions & Answers in the Data Card</li>
                  <li>Hide Questions in the Data Card</li>
                  <li>Delete Questions in the Data Card</li>
                </ul>
              </li>
              <li
                style={{
                  marginBottom: "15px",
                }}
              >
                Annotation Integration with Slack
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReleaseInfoComponent;
