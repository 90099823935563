import {
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import { color } from "@mui/system";
import React from "react";
import RedWarningIcon from "../../assets/images/detail/RedWarningIcon.svg";
import { ScoreColors, ScoreValueNew } from "../../constants/amcharts";
import {
  ntAplicablValue,
  riskFactorValueCond,
} from "../../constants/riskFactorMonth";
import { isRed } from "../../services/detailsServices";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    fontSize: 16,
    fontWeight: 600,
    paddingBottom: "0px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 16,
    fontWeight: 400,
    paddingRight: "0px",
    paddingLeft: "35px",
    [theme.breakpoints.up("sm")]: {
      fontSize: 14,
    },
    [theme.breakpoints.up("md")]: {
      fontSize: 14,
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: 15,
    },
    [theme.breakpoints.up("xl")]: {
      fontSize: 16,
    },
  },
}));

const StyledTableBodyCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    fontWeight: 400,
    "&:hover": {
      cursor: "pointer",
      color: "#0094FF !important",
    },
  },
}));

const RiskFactorsNew = ({
  setShowCardFromScores,
  setSelectedRiskFactor,
  sectionScores,
  scoresSelectedPolicy,
  calculatedScore,
}) => {
  let a =
    window.innerHeight <= 760 ? 375 : window.innerHeight <= 900 ? 500 : 600;

  const handleCellClick = (e, riskFactorName) => {
    setSelectedRiskFactor(riskFactorName);
    setShowCardFromScores(true);
  };
  return (
    // <Paper
    //   sx={{
    //     width: "100%",
    //     overflow: "hidden",
    //     // padding: "2px",
    //     // backgroundColor: "#E7EDF0",
    //     // boxShadow: "none",
    //   }}
    // >
    //  <TableContainer sx={{ maxHeight: 500 }}>

    <Table sx={{ tableLayout: "fixed" }}>
      <TableBody>
        {sectionScores !== undefined &&
          sectionScores.length > 0 &&
          sectionScores.map((scoreInfo, index) => {
            return (
              <>
                <TableRow
                  key={index}
                  id={`${scoreInfo.risk_factor_name}`}
                  style={{ background: "#ffffff" }}
                  onClick={(e) => {
                    if (
                      Number(scoreInfo["Konfer Confidence"]) !==
                        riskFactorValueCond.ntAplicabl ||
                      Number(scoreInfo["Human Impact"]) !==
                        riskFactorValueCond.ntAplicabl ||
                      (scoreInfo[scoresSelectedPolicy] !== undefined &&
                        Number(scoreInfo[scoresSelectedPolicy]) !==
                          riskFactorValueCond.ntAplicabl)
                    ) {
                      handleCellClick(e, scoreInfo.risk_factor_name);
                    }
                  }}
                >
                  <Tooltip title={scoreInfo.risk_factor_name} followCursor>
                    <StyledTableCell
                      className="scores_text-ellipsis"
                      style={{ width: "40%" }}
                    >
                      {scoreInfo.risk_factor_name}
                    </StyledTableCell>
                  </Tooltip>
                  <StyledTableBodyCell
                    align="center"
                    style={{
                      width: "20%",
                      color: isRed(scoreInfo["Konfer Confidence"]),
                      cursor:
                        Number(scoreInfo["Konfer Confidence"]) ===
                          riskFactorValueCond.ntAplicabl ||
                        calculatedScore === false
                          ? "not-allowed"
                          : "",
                    }}
                    // onClick={(e) => {
                    //   if (
                    //     e.target.textContent !== riskFactorValueCond.ntAplicabl
                    //   ) {
                    //     handleCellClick(e, scoreInfo.risk_factor_name);
                    //   }
                    // }}
                  >
                    {scoreInfo["Konfer Confidence"] !==
                    riskFactorValueCond.nullCondition ? (
                      Number(scoreInfo["Konfer Confidence"]) !==
                        riskFactorValueCond.ntAplicabl &&
                      scoreInfo["Konfer Confidence"] !== undefined ? (
                        scoreInfo["Konfer Confidence"].toLocaleString("en-US", {
                          minimumIntegerDigits: 2,
                          useGrouping: false,
                        })
                      ) : calculatedScore === false ? (
                        "-"
                      ) : (
                        ntAplicablValue
                      )
                    ) : (
                      <Tooltip
                        title="Mandatory Questions not answered"
                        followCursor
                      >
                        <img src={RedWarningIcon} />
                      </Tooltip>
                    )}
                  </StyledTableBodyCell>

                  {/* /**commented for cme */}

                  {/* <StyledTableBodyCell
                    align="center"
                    style={{
                      width: "20%",
                      color: isRed(scoreInfo["Human Impact"]),
                      cursor:
                        Number(scoreInfo["Human Impact"]) ===
                          riskFactorValueCond.ntAplicabl ||
                        calculatedScore === false
                          ? "not-allowed"
                          : "",
                    }}
                    // onClick={(e) => {
                    //   if (
                    //     e.target.textContent !== riskFactorValueCond.ntAplicabl
                    //   ) {
                    //      handleCellClick(e, scoreInfo.risk_factor_name);
                    //   }
                    // }}
                  >
                    {scoreInfo["Human Impact"] !==
                    riskFactorValueCond.nullCondition ? (
                      Number(scoreInfo["Human Impact"]) !==
                        riskFactorValueCond.ntAplicabl &&
                      scoreInfo["Human Impact"] !== undefined ? (
                        scoreInfo["Human Impact"].toLocaleString("en-US", {
                          minimumIntegerDigits: 2,
                          useGrouping: false,
                        })
                      ) : calculatedScore === false ? (
                        "-"
                      ) : (
                        ntAplicablValue
                      )
                    ) : (
                      <Tooltip
                        title="Mandatory Questions not answered"
                        followCursor
                      >
                        <img src={RedWarningIcon} />
                      </Tooltip>
                    )}
                  </StyledTableBodyCell> */}

                  <StyledTableBodyCell
                    align="center"
                    style={{
                      width: "20%",
                      color: scoreInfo.hasOwnProperty(`${scoresSelectedPolicy}`)
                        ? isRed(scoreInfo[scoresSelectedPolicy])
                        : ScoreColors.blackC,
                      cursor:
                        (scoreInfo.hasOwnProperty(`${scoresSelectedPolicy}`) &&
                          Number(scoreInfo[scoresSelectedPolicy]) ===
                            riskFactorValueCond.ntAplicabl) ||
                        calculatedScore === false
                          ? "not-allowed"
                          : "",
                    }}
                    onClick={(e) => {
                      // if (
                      //   e.target.textContent !== riskFactorValueCond.ntAplicabl
                      // ) {
                      //   // handleCellClick(e, scoreInfo.risk_factor_name);
                      // }
                    }}
                  >
                    {!scoreInfo.hasOwnProperty(`${scoresSelectedPolicy}`) ? (
                      calculatedScore === false ? (
                        "-"
                      ) : (
                        ntAplicablValue
                      )
                    ) : scoreInfo[scoresSelectedPolicy] !==
                      riskFactorValueCond.nullCondition ? (
                      Number(scoreInfo[scoresSelectedPolicy]) !==
                        riskFactorValueCond.ntAplicabl &&
                      scoreInfo[scoresSelectedPolicy] !== undefined ? (
                        scoreInfo[scoresSelectedPolicy].toLocaleString(
                          "en-US",
                          {
                            minimumIntegerDigits: 2,
                            useGrouping: false,
                          }
                        )
                      ) : calculatedScore === false ? (
                        "-"
                      ) : (
                        ntAplicablValue
                      )
                    ) : (
                      <Tooltip
                        title="Mandatory Questions not answered"
                        followCursor
                      >
                        <img src={RedWarningIcon} />
                      </Tooltip>
                    )}
                  </StyledTableBodyCell>
                </TableRow>
              </>
            );
          })}
      </TableBody>
    </Table>

    //  </TableContainer>
    // </Paper>
  );
};

export default RiskFactorsNew;
