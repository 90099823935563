import PNGFileIcon from "../assets/images/dataSetCard/PNGFileIcon.svg";
import CSVFileIcon from "../assets/images/dataSetCard/CSVFileIcon.svg";
import XLSXFileIcon from "../assets/images/dataSetCard/XLSXFileIcon.svg";
import JSONFileIcon from "../assets/images/dataSetCard/JSONFileIcon.svg";
import PDFFileIcon from "../assets/images/dataSetCard/PDFFileIcon.svg";

const compareArrays = (a, b) => {
  if (a.length !== b.length) return false;
  const elements = new Set([...a, ...b]);
  for (const x of elements) {
    const count1 = a.filter((e) => e === x).length;
    const count2 = b.filter((e) => e === x).length;
    if (count1 !== count2) return false;
  }
  return true;
};

export const getAnsCombination = (ansSuportComb, val) => {
  let tempArr = {};
  if (compareArrays(ansSuportComb, ["boolean"])) {
    let tempArr = {
      boolean:
        val === null || !val.hasOwnProperty("boolean") ? "" : val.boolean,
    };
    return tempArr;
  } else if (compareArrays(ansSuportComb, ["text"])) {
    let tempArr = {
      text: val === null || !val.hasOwnProperty("text") ? "" : val.text,
    };
    return tempArr;
  } else if (compareArrays(ansSuportComb, ["file"])) {
    let tempArr = {
      file: val === null || !val.hasOwnProperty("file") ? [] : val.file,
    };
    return tempArr;
  } else if (compareArrays(ansSuportComb, ["boolean", "file"])) {
    let tempArr = {
      boolean:
        val === null || !val.hasOwnProperty("boolean") ? "" : val.boolean,
      file: val === null || !val.hasOwnProperty("file") ? [] : val.file,
    };
    return tempArr;
  } else if (compareArrays(ansSuportComb, ["text", "file"])) {
    let tempArr = {
      text: val === null || !val.hasOwnProperty("text") ? "" : val.text,
      file: val === null || !val.hasOwnProperty("file") ? [] : val.file,
    };
    return tempArr;
  } else if (compareArrays(ansSuportComb, ["boolean", "text"])) {
    let tempArr = {
      boolean:
        val === null || !val.hasOwnProperty("boolean") ? "" : val.boolean,
      text: val === null || !val.hasOwnProperty("text") ? "" : val.text,
    };
    return tempArr;
  } else if (compareArrays(ansSuportComb, ["boolean", "text", "file"])) {
    let tempArr = {
      boolean:
        val === null || !val.hasOwnProperty("boolean") ? "" : val.boolean,
      text: val === null || !val.hasOwnProperty("text") ? "" : val.text,
      file: val === null || !val.hasOwnProperty("file") ? [] : val.file,
    };
    return tempArr;
  }
};

export const getFileIcon = (type) => {
  switch (type) {
    case "pdf":
      return PDFFileIcon;
      break;
    case "png":
      return PNGFileIcon;
      break;
    case "csv":
      return CSVFileIcon;
      break;
    case "xlsx":
      return XLSXFileIcon;
      break;
    case "xls":
      return XLSXFileIcon;
      break;
    case "json":
      return JSONFileIcon;
      break;
    default:
      return PDFFileIcon;
      break;
  }
};
