import React from "react";

const CardTemplateDescription = ({
  descriptionData,
  isEditActive,
  type,
  handleTemplateEdit,
  editedDescription,
  sectionId,
}) => {
  const descriptionFormat = () => {
    if (editedDescription) {
      return editedDescription?.description === "null"
        ? ""
        : editedDescription?.description;
    } else {
      return descriptionData?.description === "null"
        ? ""
        : descriptionData?.description;
    }
  };

  return (
    <>
      <div
        style={{
          height: "25px",
          alignItems: "center",
          marginBottom: "-7px",

          paddingTop: "13px",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            textAlign: "center",
            alignItems: "center",
          }}
        >
          <strong
            style={{
              marginLeft: "10px",
              fontSize: "17px",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            {descriptionData?.title}
          </strong>
        </div>
      </div>

      <div style={{ marginTop: "20px", marginBottom: "-20px" }}>
        {!isEditActive && (
          <p
            style={{
              marginRight: "20px",
              marginLeft: "11px",
              marginBottom: "20PX",
              //   height: "90px",
            }}
          >
            {descriptionData?.description == undefined ||
            descriptionData?.description === "null" ||
            descriptionData?.description === null ||
            descriptionData?.description?.length == 0 ? (
              <p style={{ marginLeft: "0px", color: "#AAAAAA" }}>
                No description available
              </p>
            ) : (
              descriptionData?.description
            )}
          </p>
        )}

        {isEditActive && (
          <div
            className="dataset_card_question"
            style={{
              marginRight: "20px",
              marginLeft: "10px",
              marginTop: "13px",
              marginBottom: "20PX",
            }}
          >
            <div className="dataset_answer">
              <textarea
                className="datasets_card_container_answer_input_description"
                placeholder="Enter description here..."
                type="text"
                defaultValue={
                  descriptionData?.description === "null"
                    ? ""
                    : descriptionData?.description
                }
                // defaultValue={descriptionFormat()}
                value={descriptionFormat()}
                wrap="soft"
                onChange={(e) => {
                  handleTemplateEdit(
                    sectionId,
                    type,
                    "description",
                    e.target.value,
                    type === "subsectionDescription"
                      ? descriptionData.subsection_id
                      : descriptionData.section_id
                  );
                }}
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default CardTemplateDescription;
