import React from "react";
import "./CardsSubsection.css";
import { TextField } from "@mui/material";
import Charts from "../../Subsection/Charts/Charts";

/**
 * Component : DisplaySections
 *
 * Description : This component is responsible for displaying sections data except questionnaire.
 *
 * Props : sectionData - sections infromation.
 *         isEditDatasetSection - shows the current mode of card (edited/display),
 *         editedTextData - State to store the edited text data information,
 *         handlEditedTextData - handler function for editedTextData
 *  */

const DisplaySections = ({
  sectionData,
  isEditDatasetSection,
  editedTextData,
  handlEditedTextData,
}) => {
  // show the default text data or from the state if edited
  const showDefaultTextData = (id, text) => {
    const textEdited = editedTextData.filter((editedText) => {
      if (id === editedText.text_data_id) {
        return editedText.text_data;
      }
    });

    if (textEdited.length > 0) {
      return textEdited[0]?.text_data;
    } else {
      return text;
    }
  };
  return (
    <>
      {sectionData.description !== null &&
        sectionData.description !== "null" &&
        sectionData.description !== "" && (
          <p className="sub_desc">{sectionData.description}</p>
        )}

      {sectionData.subsections?.map((subSections) => {
        return (
          subSections.title !== "Questionnaire" && (
            <>
              <p className="sub_title">{subSections.title}</p>

              {subSections.text_data?.length === 0 &&
                subSections?.chart_data.length === 0 &&
                (subSections.description === "" ||
                  subSections.description === "null" ||
                  subSections.description === null) && (
                  <div className="sub_desc_no_information">
                    No Information Available
                  </div>
                )}

              {subSections.description !== "" &&
                subSections.description !== "null" &&
                subSections.description !== null && (
                  <p className="sub_desc">{subSections.description}</p>
                )}

              {subSections?.chart_data.length > 0 &&
                subSections?.chart_data.map((chartData) => {
                  return (
                    <>
                      {subSections?.text_data?.length > 0 &&
                        subSections?.text_data.map((textData) => {
                          if (
                            textData.text_data_id === chartData.text_data_id
                          ) {
                            if (isEditDatasetSection) {
                              return (
                                <TextField
                                  id={textData.text_data_id}
                                  multiline
                                  fullWidth
                                  placeholder="Text"
                                  maxRows={4}
                                  key={textData.text_data_id}
                                  sx={{
                                    "& .MuiInputBase-root": {
                                      padding: "2px 5px",
                                      height: "100px",
                                      alignItems: "start",
                                    },
                                  }}
                                  defaultValue={showDefaultTextData(
                                    textData.text_data_id,
                                    textData.text_data
                                  )}
                                  value={
                                    editedTextData.find(
                                      (eText) =>
                                        eText.text_data_id ===
                                        textData.text_data_id
                                    )?.[0]?.text_data
                                  }
                                  onChange={(e) => {
                                    handlEditedTextData(
                                      e.target.value,
                                      textData.section_id,
                                      textData.subsection_id,
                                      textData.text_data_id
                                    );
                                  }}
                                />
                              );
                            } else {
                              return (
                                <p className="sub_desc">{textData.text_data}</p>
                              );
                            }
                          }
                        })}
                      <br></br>
                      {chartData.chart_data !== "" ? (
                        <>
                          <Charts chartData={chartData} />
                        </>
                      ) : (
                        <div>
                          <img
                            src={chartData?.chart_data_image}
                            height="339px"
                          />
                        </div>
                      )}
                    </>
                  );
                })}
            </>
          )
        );
      })}
    </>
  );
};

export default DisplaySections;
