import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useAtom } from "jotai";
import AdminSettingBox from "../../components/AdminChecklist/AdminSettingBox";
import Layout from "../../layout/Layout";
import appSvg from "../../assets/images/listview/app-chip.svg";
import dataSetSvg from "../../assets/images/adminPanel/Dataset.svg";
import modelSvg from "../../assets/images/listview/model-chip.svg";
import { useGetEditableFields } from "../../api/readApiFunctions";
import "./AdminDetailPage.css";
import {
  dataTypes,
  entityNameConst,
  typeNames,
} from "../../constants/dataTypes";
import { ToastContainer, toast } from "react-toastify";
import { useUpdateEditDetails } from "../../api/updateApiFunctions";
import { accessDataByRoleAtom, isSidebar } from "../../atom/jotai";
import { analyticServices } from "../../services/analyticServices";
import { isAccessProvided } from "../../services/assetManagementService";
import {
  actionConstants,
  moduleConstants,
  subModuleConstants,
} from "../../constants/accessManagementConstants";
import NoResult from "../../components/NoResult/NoResult";

const AdminDetailPage = () => {
  //state for managing access management system golbal value
  const [accessDataByRole] = useAtom(accessDataByRoleAtom);
  const location = useLocation();

  const { data, isLoading } = useGetEditableFields(
    location.pathname.split("/")[2] == "details" &&
      location.pathname.split("/")[2] !== undefined &&
      accessDataByRole &&
      isAccessProvided(
        moduleConstants.admin_view,
        subModuleConstants.asset_metadata,
        actionConstants.modify,
        "execution",
        accessDataByRole
      )
      ? true
      : false
  );

  const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);
  const [sidebar] = useAtom(isSidebar);

  const [allSelected, setAllSelected] = useState({
    apps: false,
    models: false,
    datasets: false,
  });
  const { mutate: updateEditDetails } = useUpdateEditDetails();

  const handleDetailsEditable = () => {
    let isToast = false;

    let tempArr = [];

    // isToast = true;
    //-------------------
    if (allSelected.apps === true) {
      data.app_details_editable.map((obj, index) => {
        const element = {
          type: typeNames.apps,
          name: obj.field_name,
          value: obj.is_disabled ? obj.value : true,
        };
        tempArr.push(element);
      });
    }

    if (allSelected.models === true) {
      data.model_details_editable.map((obj, index) => {
        const element = {
          type: typeNames.models,
          name: obj.field_name,
          value: obj.is_disabled ? obj.value : true,
        };
        tempArr.push(element);
      });
    }

    if (allSelected.datasets === true) {
      data.input_data_table_details_editable.map((obj, index) => {
        const element = {
          type: typeNames.datasets,
          name: obj.field_name,
          value: obj.is_disabled ? obj.value : true,
        };
        tempArr.push(element);
      });
    }

    if (selectedCheckboxes.length > 0) {
      for (let i = 0; i < selectedCheckboxes.length; i++) {
        const element = selectedCheckboxes[i];
        let eleType;
        if (element.type === "apps") {
          eleType = "app";
        } else if (element.type === "models") {
          eleType = "model";
        } else if (element.type === "datasets") {
          eleType = "input_data_table";
        }

        const tempElement = {
          type: eleType,
          name: element.name,
          value: element.val,
        };
        tempArr.push(tempElement);
        setAllSelected({
          apps: false,
          models: false,
          datasets: false,
        });
      }
      // setIsBlocking(true);
    }

    if (tempArr.length > 0) {
      const payload = {
        editedFields: tempArr,
      };
      updateEditDetails(payload);
      isToast = true;
    }

    if (isToast === true) {
      setSelectedCheckboxes([]);
      toast.success("Details Settings updated");
      analyticServices(`Detail editable fields updated sucessfully `);
    }
  };

  //Below useEffect is used for giving title to current page
  useEffect(() => {
    document.title = "Admin Details Setting Page";
  });

  return (
    <>
      <div className="userList-heading1" id="DetailsSettingsHeading">
        Details Settings
      </div>

      {isAccessProvided(
        moduleConstants.admin_view,
        subModuleConstants.asset_metadata,
        actionConstants.modify,
        "execution",
        accessDataByRole
      ) === true ? (
        <div
          className={!sidebar ? "userlist" : "userlist_sidebar"}
          id="DetailsSettings"
        >
          <div className="setting_box" id="DetailsSettingsBox">
            {data && (
              <AdminSettingBox
                title={`${entityNameConst.applications}`}
                imageData={appSvg}
                data={data.app_details_editable}
                action="Editable"
                type={dataTypes.apps}
                setSelectedCheckboxes={setSelectedCheckboxes}
                setAllSelected={setAllSelected}
                allSelected={allSelected}
                accessDataByRole={accessDataByRole}
              />
            )}
            {data && (
              <AdminSettingBox
                title={`${entityNameConst.models}`}
                imageData={modelSvg}
                data={data.model_details_editable}
                action="Editable"
                type={dataTypes.models}
                setSelectedCheckboxes={setSelectedCheckboxes}
                setAllSelected={setAllSelected}
                allSelected={allSelected}
                accessDataByRole={accessDataByRole}
              />
            )}
            {/** For the demo purpose commented the below code */}
            {/* {data && (
              <AdminSettingBox
                title={`${entityNameConst.datasets}`}
                imageData={dataSetSvg}
                data={data.input_data_table_details_editable}
                action="Editable"
                type={dataTypes.datasets}
                setSelectedCheckboxes={setSelectedCheckboxes}
                setAllSelected={setAllSelected}
                allSelected={allSelected}
                accessDataByRole={accessDataByRole}
              />
            )} */}
          </div>

          {isAccessProvided(
            moduleConstants.admin_view,
            subModuleConstants.asset_metadata,
            actionConstants.modify,
            "execution",
            accessDataByRole
          ) === true && (
            <div style={{ marginTop: "-20px" }} id="dsUpdateBtnDiv">
              <div className="table_risk_btn" id="dsUpdateButtonDiv">
                <button
                  id="dsUpdateBtn"
                  className={`${
                    selectedCheckboxes.length > 0 ||
                    allSelected.apps === true ||
                    allSelected.models === true ||
                    allSelected.datasets === true
                      ? "userlist-createNewUser-button-enable"
                      : "userlist-createNewUser-button"
                  } table_risk_btn`}
                  onClick={handleDetailsEditable}
                >
                  Update
                </button>
              </div>
            </div>
          )}
        </div>
      ) : (
        <div className="hgt userlist-users-table" id="noAccessToRoleManagment">
          {accessDataByRole && (
            <NoResult title={"No Access to Details Settings"} />
          )}
        </div>
      )}

      <ToastContainer />
    </>
  );
};

export default AdminDetailPage;
