import React, { useEffect, useState } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";

// Package imports
import { QueryCache, QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";

// Custom Imports
import LoginPage from "./pages/login/LoginPage";
import ProtectedRoute from "./components/HOC/ProtectedRoute";
import Homepage from "./pages/home/Homepage";
import ReleaseInfoComponent from "./pages/release-info/release-info.component";
import GraphviewPage from "./pages/graphview-page/GraphviewPage";
import "./App.css";
import DetailPage from "./pages/detail/DetailPage";
import ObservabilityPage from "./pages/observability/ObservabilityPage";
import NotFound from "./pages/not-found/NotFound";
import { ToastContainer, toast } from "react-toastify";
import AdminUserlistPage from "./pages/admin-userlist-page/AdminUserlistPage";
import AdminDetailPage from "./pages/admin-detail-page/AdminDetailPage";
import AdminRiskfactorsPage from "./pages/admin-riskfactors-page/AdminRiskfactorsPage";
import AdminRoleManagementPage from "./pages/admin-role-management-page/AdminRoleManagementPage";
import AdminConfigrationTest from "./pages/test/AdminConfigrationTest";
import AdminBusinessUnitsPage from "./pages/admin-businessUnit-page/AdminBusinessUnitsPage";
import ConfirmEmail from "./components/Login/ConfirmEmail";
import ResetPassword from "./components/Login/ResetPassword";
import ListviewCompliancePage from "./pages/compliance-page/listview-compliance-page/ListviewCompliancePage";
import GraphviewCompliancePage from "./pages/compliance-page/graphview-compliance-page/GraphviewCompliancePage";
import AdminNodeBusinessunitRel from "./pages/admin-node-businessunit-relationship-page/AdminNodeBusinessunitRel";
import AdminRegionsPage from "./pages/admin-regions-page/AdminRegionsPage";
import CardTemplatePage from "./pages/card-template-page/CardTemplatePage";

//import { socket } from "./services/socketService";
import io from "socket.io-client";
import { useAtom } from "jotai";
import AssetsListviewWrapperPage from "./pages/listview-page/AssetsListviewWrapperPage";
import AdminPolicyPage from "./pages/admin-policy-page/AdminPolicyPage";
import Layout from "./layout/Layout";
import DiscoveryPage from "./pages/discovery/DiscoveryPage";
import { getAccessDataByRole } from "./api/readApiFunctions";
import {
  accessDataByRoleAtom,
  authTokens,
  authUser,
  isSwitchToAdminPanelAtom,
} from "./atom/jotai";
import { analyticServices } from "./services/analyticServices";
import { getLocalTokens, logoutUser } from "./utils/authUtils";
import {
  accessConstants,
  actionConstants,
  moduleConstants,
  subModuleConstants,
} from "./constants/accessManagementConstants";
import { handleAxiosResponseInterceptors } from "./api/instance/nodeApiInstance";
import ErrorPage403 from "./pages/403-error-page/ErrorPage403";
import ErrorPage404 from "./pages/404-error-page/ErrorPage404";
import NLPQueryResultsWrapperPage from "./pages/nlp-queries-result-page/NLPQueryResultsWrapperPage";

export const socket = io(process.env.REACT_APP_SOCKET_ID, {
  reconnectionDelayMax: 1000,
  path: "/socket.io",
  transports: ["websocket", "polling"],
  secure: true,
});

socket.on("connect", () => {
  console.log("socket connected", socket.id); // x8WIv7-mJelg7on_ALbx
});

socket.on("disconnect", (reason) => {
  console.log("socket disconnet reson", reason);
});

socket.on("connect_error", (reason) => {
  console.log("Error while socket connection ", reason);
});

const App = () => {
  const queryClient = new QueryClient();
  const navigate = useNavigate();
  const [user] = useAtom(authUser);
  const [tokens] = useAtom(authTokens);
  //state for managing access management system
  const [accessDataByRole, setAccessDataByRole] = useAtom(accessDataByRoleAtom);
  const [isLoaded, setIsLoaded] = useState(false);

  const handleLogout = () => {
    logoutUser();
    navigate(`/login`);
    analyticServices(`sign_out`);
  };

  /**Below useEffect is used for getting and setting accesses as per the role of user */
  useEffect(() => {
    const getAccessData = async () => {
      const token = getLocalTokens();
      if (!accessDataByRole && token?.accessToken) {
        const data = await getAccessDataByRole(
          user?.contributor_id,
          user?.role
        );
        setAccessDataByRole(data);
      }
    };

    getAccessData();
  }, [tokens]);

  useEffect(() => {
    if (!tokens?.accessToken) {
      handleLogout();
    }
  }, [tokens]);

  if (!isLoaded) {
    setIsLoaded(true);
    handleAxiosResponseInterceptors(navigate);
  }

  // //Socket client
  // socket.on("connect", () => {
  //   console.log("socket connected");
  // });

  return (
    <QueryClientProvider client={queryClient}>
      <Routes>
        <Route path="/login" element={<LoginPage />} />
        <Route path="/confirm-email" element={<ConfirmEmail />} />
        <Route path="/reset-password" element={<ResetPassword />} />

        <Route
          path="/"
          element={
            <ProtectedRoute>
              <Homepage />
            </ProtectedRoute>
          }
        />
        <Route element={<Layout />}>
          <Route
            path="/whats-new"
            element={
              <ProtectedRoute>
                <ReleaseInfoComponent />
              </ProtectedRoute>
            }
          />
          <Route
            path="/admin/userlist"
            element={
              <ProtectedRoute
                accessDataByRole={accessDataByRole}
                module={moduleConstants.admin_view}
                subModule={subModuleConstants.users}
                action={null}
                accessType={accessConstants.read}
              >
                <AdminUserlistPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/admin/details"
            element={
              <ProtectedRoute
                accessDataByRole={accessDataByRole}
                module={moduleConstants.admin_view}
                subModule={subModuleConstants.asset_metadata}
                action={actionConstants.modify}
                accessType={accessConstants.execution}
              >
                <AdminDetailPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/admin/regions"
            element={
              <ProtectedRoute
                accessDataByRole={accessDataByRole}
                module={moduleConstants.admin_view}
                subModule={subModuleConstants.regions}
                action={null}
                accessType={accessConstants.read}
              >
                <AdminRegionsPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/admin/businessunit"
            element={
              <ProtectedRoute
                accessDataByRole={accessDataByRole}
                module={moduleConstants.admin_view}
                subModule={subModuleConstants.business_units}
                action={null}
                accessType={accessConstants.read}
              >
                <AdminBusinessUnitsPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/admin/policy"
            element={
              <ProtectedRoute
                accessDataByRole={accessDataByRole}
                module={moduleConstants.policy}
                subModule={subModuleConstants.policy_view}
                action={null}
                accessType={accessConstants.read}
              >
                <AdminPolicyPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/admin/node-businessunit-relationship"
            element={
              <ProtectedRoute
                accessDataByRole={accessDataByRole}
                module={moduleConstants.admin_view}
                subModule={subModuleConstants.business_units_assignment}
                action={null}
                accessType={accessConstants.read}
              >
                <AdminNodeBusinessunitRel />
              </ProtectedRoute>
            }
          />
          <Route
            path="/admin/cardTemplate"
            element={
              <ProtectedRoute
                accessDataByRole={accessDataByRole}
                module={moduleConstants.card_templates}
                subModule={subModuleConstants.crud}
                action={null}
                accessType={accessConstants.read}
              >
                <CardTemplatePage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/admin/roleManagement"
            element={
              <ProtectedRoute
                accessDataByRole={accessDataByRole}
                module={moduleConstants.admin_view}
                subModule={subModuleConstants.role_management}
                action={null}
                accessType={accessConstants.read}
              >
                <AdminRoleManagementPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/admin/riskfactors"
            element={
              <ProtectedRoute
                accessDataByRole={accessDataByRole}
                module={moduleConstants.admin_view}
                subModule={subModuleConstants.risk_factors}
                action={actionConstants.visibility}
                accessType={accessConstants.execution}
              >
                <AdminRiskfactorsPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/result/listview/:searchText"
            element={
              <ProtectedRoute
                accessDataByRole={accessDataByRole}
                module={moduleConstants.asset_view}
                subModule={subModuleConstants.crud}
                action={null}
                accessType={accessConstants.read}
              >
                <AssetsListviewWrapperPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/result/graphview/:searchText"
            element={
              <ProtectedRoute
                accessDataByRole={accessDataByRole}
                module={moduleConstants.asset_view}
                subModule={subModuleConstants.graph_view}
                action={actionConstants.graph_view_button}
                accessType={accessConstants.read}
              >
                <GraphviewPage />
              </ProtectedRoute>
            }
          />

          <Route
            path="/ami-deployment"
            element={
              <ProtectedRoute>
                <AdminConfigrationTest />
              </ProtectedRoute>
            }
          />
          <Route
            path="/compliance-result/compliance-listview/:searchText"
            element={
              <ProtectedRoute
                accessDataByRole={accessDataByRole}
                module={moduleConstants.profile_view}
                subModule={subModuleConstants.crud}
                action={null}
                accessType={accessConstants.read}
              >
                <ListviewCompliancePage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/compliance-result/compliance-summary/:searchText/:regionId/:businessId/:appId"
            element={
              <ProtectedRoute
                accessDataByRole={accessDataByRole}
                module={moduleConstants.summary_view}
                subModule={subModuleConstants.crud}
                action={null}
                accessType={accessConstants.read}
              >
                <GraphviewCompliancePage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/observability/:nodeType/:nodeId"
            element={
              <ProtectedRoute
                accessDataByRole={accessDataByRole}
                module={moduleConstants.details}
                subModule={subModuleConstants.detail_info}
                action={actionConstants.observability_button}
                accessType={accessConstants.execution}
              >
                <ObservabilityPage />
              </ProtectedRoute>
            }
          />

          <Route
            path="/detail/:nodeType/:nodeId"
            element={
              <ProtectedRoute
                accessDataByRole={accessDataByRole}
                module={moduleConstants.details}
                subModule={subModuleConstants.crud}
                action={null}
                accessType={accessConstants.read}
              >
                <DetailPage />
              </ProtectedRoute>
            }
          />

          <Route
            path="/nlp-query-result/:nlpQuery"
            element={
              <ProtectedRoute>
                <NLPQueryResultsWrapperPage />
              </ProtectedRoute>
            }
          />

          <Route
            path="/discovery"
            element={
              <ProtectedRoute
                accessDataByRole={accessDataByRole}
                module={moduleConstants.extraction}
                subModule={subModuleConstants.crud}
                action={null}
                accessType={accessConstants.read}
              >
                <DiscoveryPage />
              </ProtectedRoute>
            }
          />
        </Route>

        <Route
          path="/403-error"
          element={
            <ProtectedRoute>
              <ErrorPage403 />
            </ProtectedRoute>
          }
        />

        <Route
          path="/404-error"
          element={
            <ProtectedRoute>
              <ErrorPage404 />
            </ProtectedRoute>
          }
        />

        <Route path="*" element={<ErrorPage404 />} />
      </Routes>
      {/* <ReactQueryDevtools initialIsOpen={false} position="bottom-right" /> */}
      <ToastContainer />
    </QueryClientProvider>
  );
};

export default App;
