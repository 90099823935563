import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { useState } from "react";
import { useEffect } from "react";

import HumanImpactIcon from "../../assets/images/adminPanel/HumanImpact.svg";
import { PolicyNameConst } from "../../constants/policyConstants";

const columns = [
  { id: "sectionName", label: "Section Name", minWidth: 150 },
  { id: "change", label: "Changes", minWidth: 100 },
];

export default function MigrationTable({ migrationDifference }) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [formattedData, setFormattedData] = useState([]);

  // format the hyperparamets to match the table structure
  useEffect(() => {
    if (migrationDifference) {
      const arrFormatted = [];
      migrationDifference.map((val, i) => {
        let queData = [];

        // handle created
        if (val.questionStatus === "created") {
          queData.push(val?.questionText);

          // handle deleted
        } else if (val.questionStatus === "deleted") {
          queData.push(<s>{val?.questionText}</s>);
        } else {
          // handle visibility updated
          if (
            val.isVisibleStatus === "updated" &&
            val.isVisibleValue === false
          ) {
            queData.push(<s>{val?.questionText}</s>);
          } else {
            queData.push(val?.questionText);
          }

          // handle mandatory updated
          if (val?.mandatoryStatus === "updated") {
            if (val?.mandatoryValue === false) {
              queData.push(
                <>
                  <span> </span>
                  <s>(Mandatory)</s>
                </>
              );
            } else if (val?.mandatoryValue === true) {
              queData.push(
                <span
                  style={{
                    color: "#F25757",
                    marginLeft: "2px",
                    fontSize: "18px",
                  }}
                >
                  *
                </span>
              );
            }
          }

          // handle human impact updated
          if (val?.humanImpactStatus === "updated") {
            if (val?.humanImpactValue === false) {
              queData.push(
                <>
                  <span> </span>
                  <s>(Human Impact) </s>
                </>
              );
            } else if (val?.humanImpactValue === true) {
              queData.push(<img src={HumanImpactIcon} />);
            }
          }

          // handle policy status updated
          if (val?.policyStatus === "updated" && val?.policyValue?.length > 0) {
            queData.push(<p>{PolicyNameConst.policies} : </p>);
            val?.policyValue.map((policy, index) => {
              if (policy.policyStatus === "created") {
                queData.push(
                  <span>
                    {policy.policyName === "NULL" || policy.policyName === ""
                      ? "-"
                      : policy.policyName}{" "}
                    {index + 1 !== val?.policyValue?.length && ", "}
                  </span>
                );
              } else if (policy.policyStatus === "deleted") {
                queData.push(
                  <span>
                    <s>{policy.policyName}</s>
                    {index + 1 !== val?.policyValue?.length && ", "}
                  </span>
                );
              }
            });
          }
        }

        arrFormatted.push({
          sectionName: val?.sectionName,
          change: <p style={{ color: "#000" }}>{queData}</p>,
        });
      });
      setFormattedData(arrFormatted);
    }
  }, [migrationDifference]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <TableContainer sx={{ maxHeight: 440 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{
                    minWidth: column.minWidth,
                    backgroundColor: "#dde6ea",
                    fontSize: "18px",
                    color: "#000000",
                  }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {formattedData &&
              formattedData.length > 0 &&
              formattedData
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={row.value}
                    >
                      {columns.map((column) => {
                        const value = row[column.id];
                        return (
                          <TableCell key={column.id} align={column.align}>
                            {value}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5]}
        component="div"
        count={formattedData?.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
}
