import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import React, { useState, useEffect } from "react";

import "./WarningDialogForCard.css";
import CancelIcon from "../../assets/images/adminPanel/CancelIcon.svg";
import PDFGenerator from "../PDFGeneration/PDFGenerator";
import { pdf, PDFDownloadLink } from "@react-pdf/renderer";
import { useGetModalCardData } from "../../api/readApiFunctions";

const WarningDialogForCard = ({
  openWarningDialogForCard,
  setOpenWarningDialogForCard,
  listCardData,
  listCardId,
  setShowCard,
  setCallCreateCardAPI,

  isPdfGenarate,
}) => {
  const {
    data: cardResData,
    isLoading,
    error,
  } = useGetModalCardData(listCardId, true);

  const generatePDFDocument = async (data) => {
    const blob = await pdf(<PDFGenerator cardTemplateRes={data} />).toBlob();

    const pdfURL = URL.createObjectURL(blob);
    window.open(pdfURL, "_blank");
  };

  const [dataForTable, setDataForTable] = useState([]);
  const [openPDFQuestionnaireTable, setOpenPDFQuestionnaireTable] =
    useState(false);

  const [isPdf, setIsPdf] = useState(false);

  const jsonParse = (data) => {
    let stringAns = data?.replace(/'/g, '"');

    if (stringAns) {
      let parseAns = JSON.parse(stringAns);

      if (parseAns.hasOwnProperty("text")) {
        return parseAns.text;
      }
      if (parseAns.hasOwnProperty("boolean")) {
        return parseAns.boolean;
      }
    } else {
      return null;
    }
  };

  useEffect(() => {
    let tempData = [];
    cardResData &&
      cardResData?.sections?.length > 0 &&
      cardResData?.sections?.map((data) => {
        data.title !== "Details" &&
          data.subsections?.map((subSectionData, index) => {
            if (subSectionData.title === "Questionnaire") {
              let questionList = [];
              let answerList = [];
              subSectionData.questions?.length > 0 &&
                subSectionData?.questions?.map((question) => {
                  questionList.push(question.question);
                  let ansObj = {};
                  let ansData = eval("(" + question.text + ")");

                  if (
                    question.answer_type.includes("boolean") &&
                    ansData !== null &&
                    ansData.hasOwnProperty("boolean")
                  ) {
                    ansObj["boolean"] = ansData.boolean;
                  }

                  if (
                    question.answer_type.includes("text") &&
                    ansData !== null &&
                    ansData.hasOwnProperty("text")
                  ) {
                    ansObj["text"] = ansData.text;
                  }

                  if (
                    question.answer_type.includes("file") &&
                    ansData !== null &&
                    ansData.hasOwnProperty("file") &&
                    ansData.file.length > 0
                  ) {
                    ansObj["file"] = ansData.file;
                  }

                  tempData.push({
                    title: data.title,
                    question: question.question,
                    answer: ansObj,
                  });
                });
            }
          });
      });

    tempData?.length > 0
      ? setDataForTable(tempData)
      : setDataForTable([
          {
            title: "No data available",
            question: "No data available",
            answer: "No data available",
          },
        ]);
  }, [cardResData]);

  return (
    <>
      <Dialog
        id="createWarningDialogForCard"
        open={openWarningDialogForCard}
        onClose={() => {
          setOpenWarningDialogForCard(!openWarningDialogForCard);
        }}
        aria-labelledby="warning-dialog-for-card"
        aria-describedby="warning-dialog-for-card"
        sx={{
          "& .MuiDialog-paper": {
            boxShadow: "0px 6px 10px rgba(0, 0, 0, 0.3)",
            borderRadius: "8px",
            width: "500.29px",
            height: "300.2px",
          },
        }}
      >
        <DialogTitle id="warning-dialog-for-card-title" sx={{ m: 0, p: 3 }}>
          <IconButton
            id="closeWarningDialogForCard"
            aria-label="close"
            onClick={() => {
              setOpenWarningDialogForCard(!openWarningDialogForCard);
            }}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <img src={CancelIcon} alt="Delete" />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Typography
            variant="h5"
            component="div"
            className="warning-dialog-for-card-message-one"
          >
            {listCardData.hasOwnProperty("card")
              ? `This card is based on an older version ${listCardData?.card?.template_version} of the template. Would
            you like to upgrade this card to the latest ${listCardData?.card?.latest_version} version?`
              : `This card is based on an older version ${listCardData?.card_template_version} of the template. Would
            you like to upgrade this card to the latest ${listCardData?.card_latest_version} version?`}
          </Typography>

          <Typography
            variant="h5"
            component="div"
            className="warning-dialog-for-card-message-two"
          >
            All the responses in card will be discarded if you choose to
            upgrade. Current card will be downloaded in PDF on your device.
          </Typography>
          {openPDFQuestionnaireTable &&
            dataForTable &&
            dataForTable.length > 0 && (
              <>
                <PDFDownloadLink
                  document={<PDFGenerator dataForTable={dataForTable} />}
                  fileName="aaaaaa"
                >
                  {({ blob, url, loading, error }) =>
                    blob &&
                    window.open(URL.createObjectURL(blob), "createPdf") &&
                    setCallCreateCardAPI(true) &&
                    setOpenPDFQuestionnaireTable(false)
                  }
                </PDFDownloadLink>
              </>
            )}
        </DialogContent>
        <DialogActions sx={{ justifyContent: "center", marginBottom: "20px" }}>
          <Button
            id="shiftToNewVersion"
            className="shift-to-new-version"
            value="YES"
            onClick={() => {
              setOpenPDFQuestionnaireTable(true);
            }}
          >
            Yes, Upgrade
          </Button>

          <Button
            id="DontChangeCardVersion"
            className="no-to-new-version"
            value="NO"
            onClick={() => {
              setOpenPDFQuestionnaireTable(false);
              setOpenWarningDialogForCard(false);
              setShowCard(true);
            }}
            autoFocus
          >
            No, Continue
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default WarningDialogForCard;
