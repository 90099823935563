import React, { useEffect, useState } from "react";
import * as yup from "yup";
import { useFormik } from "formik";
import { Container, Grid, TextField, Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import "./Login.css";
import konferlogo from "../../assets/images/konferLogo/konferlogo.svg";

const ConfirmEmail = () => {
  const navigate = useNavigate();

  //Below useEffect is used for giving title to current page
  useEffect(() => {
    document.title = "Konfer Confirm Email Page";
  });

  const validationSchema = yup.object({
    email: yup
      .string("Enter your email")
      .email("Enter a valid email")
      .required("Email is required"),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      // pass formik values to confirm email function
      handleConfirmEmailSubmit(values);
    },
  });

  const handleConfirmEmailSubmit = (values) => {
    const { email } = values;
    navigate("/reset-password", { state: { email: email } });
  };

  return (
    <div className="login_Container" id="confirmEmailMainContainer">
      <div className="login-page" id="confirmEmailDiv">
        <Grid sx={{ "& .MuiContainer-root": { padding: "0px" } }}>
          <Container>
            <Grid item sm={12} lg={12} md={12} xl={12} xs={12}>
              <div className="login-logo" id="logoOnconfirmEmail">
                <img src={konferlogo} alt="konfer-logo"></img>
              </div>

              {/* <Typography
                variant="h5"
                component="div"
                className="forgot-password-heading"
              >
                Forgot Password?
              </Typography> */}

              <div className="login_form" id="confirmEmailFormDiv">
                <form
                  noValidate
                  autoComplete="off"
                  onSubmit={formik.handleSubmit}
                >
                  <div
                    className="email-filed"
                    id="confirmEmailText"
                    style={{ marginTop: "115px" }}
                  >
                    <TextField
                      fullWidth
                      // style={{ backgroundColor: "white" }}
                      className="login_textField"
                      label="Email Id"
                      variant="outlined"
                      name="email"
                      id="confirm_email"
                      value={formik.values.email}
                      // onChange={(e) => setEmail(e.target.value)}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.email && Boolean(formik.errors.email)
                      }
                      helperText={formik.touched.email && formik.errors.email}
                    />
                  </div>

                  <div
                    className="submit-btn"
                    id="SubmitButtonOnConfirmEmailDiv"
                  >
                    <Button
                      className="login-btn"
                      type="submit"
                      id="SubmitButtonOnConfirmEmail"
                    >
                      Next
                    </Button>
                    <div id="buttonConfirmEmailDiv"></div>
                  </div>
                </form>
              </div>
            </Grid>
          </Container>
        </Grid>
      </div>
    </div>
  );
};

export default ConfirmEmail;
