export default {
  "&singleLine": {
    input: {
      padding: 1,
      width: "100%",
    },
  },

  suggestions: {
    list: {
      backgroundColor: "white",
      fontSize: 14,
    },
    item: {
      padding: "5px 15px",
      borderBottom: "1px solid rgba(0,0,0,0.50)",
    },
  },
};
