export const TermsDataOne = [
  "Welcome to Konfer and the Konfer Confidence Cloud. By accessing the Service (defined below), or by checking or selecting an “I agree” or similarly worded box or button indicating your acceptance of these Terms, you (“<b>User</b>”) agree to be bound by these Terms. If you are accepting these Terms on behalf of a company or other entity, you represent that you have the authority to, and do hereby, bind such entity to these Terms, and references to “Customer,” “you” or “your” will refer to such entity. If you do not have such authority, or if you do not agree with these Terms, you must not accept these Terms and neither you nor such entity may use the Service.",
];

export const TermsDataTwo = [
  "In these Terms (the “<b>Terms</b>”), references to “Company,” “we,” “us,” and “our” refer to Konfer, Inc., a Delaware corporation, and references to the “<b>Service</b>” refer to the online service(s) accessed by you and made available by us, which may include premium features or other related services, and including any offline components and any content, software, data and information provided by us or our licensors through or in connection with the foregoing. We and you are each sometimes referred to herein as a “<b>party</b>”.",
];

export const AccessServiceOne = [
  "<i>Age Requirement; Responsibility</i>. You represent that you are old enough to form a legally binding contract. You may not access or use the Service unless you are at least 18 years old. You are responsible for maintaining the confidentiality of your account and password and for restricting access to your computer or other device. You are responsible for your use of the Service and for all activities that occur under your account or password. You agree to notify us immediately of any known or suspected breach of these Terms or unauthorized access to or use of the Service.",
];

export const AccessServiceTwo = [
  "<i>Registration</i>. You are required to maintain all information provided during your registration/sign-up process so that it remains true, accurate, up-to-date and complete at all times. You represent and warrant that any information you provide us is truthful and accurate to the best of your knowledge. We reserve the right to revoke your particular username, or to require you to choose another username or password. The Service may permit you to register through certain third-party services, such as Facebook Connect or Google (“<b>Authentication Service</b>”). By registering with or logging into the Service using an Authentication Service, you agree that we may access and use your information on the Authentication Service. We are not affiliated with any Authentication Service and you are solely responsible for your interactions with the Authentication Service.",
];

export const AccessServiceThree = [
  "<i>License</i>. Subject to these Terms, we hereby grant you a limited, non-exclusive, non-sublicensable, non-transferable license to access and use the Service solely for your internal business purposes in accordance with these Terms and any guidelines, instructions or other documentation made available by us relating to the Service (“<b>Documentation</b>”). We reserve the right to terminate this license and your access to the Service at any time, for any or no reason and with or without notice. We may update, upgrade or otherwise modify the Service at any time with or without notice.",
];

export const UseOfService = [
  "<i>General</i>. You may use the Service only in accordance with these Terms, the Documentation, and all applicable laws and regulations.",
];

export const UseOfServiceNotToAgree = [
  "<i>Prohibited Conduct</i>. You agree <b>not</b> to:",
];

export const UseOfServiceOne = [
  "use the Service for any purpose other than its intended purposes as set forth in the Service and the Documentation;",
];
export const UseOfServiceTwo = [
  "use the Service in a manner that could damage, disable, overburden or impair the Service, or interfere with any other user’s ability to use the Service;",
];
export const UseOfServiceThree = [
  "harvest or otherwise collect or disseminate personal information about others without their consent, or harass or “stalk” another;",
];
export const UseOfServiceFour = [
  "impersonate any person or entity, or forge headers or otherwise manipulate identifiers in order to disguise the origin of messages, or create any user account by automated means or under false pretenses;",
];
export const UseOfServiceFive = [
  "gain or attempt to gain unauthorized access to the Service, or other accounts, computer systems or networks connected to the Service, through password mining or any other means;",
];
export const UseOfServiceSix = [
  "export technical data or other materials in violation of any applicable law;",
];
export const UseOfServiceSeven = [
  "use any automated software, device, or process (including without limitation any “web crawler” or “screen scraper”) to extract or index any data or other content from the Service (including without limitation information about users of the Service);",
];
export const UseOfServiceEight = [
  "interfere or attempt to interfere with the proper working of the Service or any activities conducted on the Service, including without limitation the use of framing techniques to enclose any content, and the placement of pop-up windows over web pages;",
];
export const UseOfServiceNine = [
  "authorize, aid, or encourage anyone to act in violation of these Terms;",
];
export const UseOfServiceTen = [
  "access or use the Service in order to build a competitive product or service; or",
];
export const UseOfServiceEleven = [
  "upload, transmit or otherwise make available data, information or material that:",
];
export const UseOfServiceElevenA = [
  "is unlawful, harassing, libelous, invasive of another's privacy, abusive, threatening, harmful, vulgar, obscene, or otherwise objectionable;",
];
export const UseOfServiceElevenB = [
  "infringes any intellectual property right or other right of any third party, including but not limited to any patent, trademark, service mark, trade secret or copyright;",
];
export const UseOfServiceElevenC = [
  "constitutes the unauthorized or unlawful disclosure of confidential or other proprietary or nonpublic information;",
];
export const UseOfServiceElevenD = [
  "constitutes unsolicited or unauthorized advertising or promotional materials, “chain letters,” “junk mail,” “spam,” “pyramid schemes” or any other form of disruptive messages;",
];
export const UseOfServiceElevenE = [
  "contains viruses, trojan horses, worms or any other harmful or disruptive computer code, files or programs; or",
];
export const UseOfServiceElevenF = [
  "is fraudulent, misleading or otherwise intentionally inaccurate.",
];

export const FeesOne = [
  "In order to use certain portions of the Service, you may be required to purchase a subscription or pay another form of fee (“<b>Fees</b>”). Unless otherwise stated, all Fees are payable in U.S. Dollars. You agree to pay all Fees incurred by you or anyone else using your account, and you hereby authorize us and our third-party payment service providers to collect such Fees using the credit card or other payment method you provide to us. We reserve the right to institute new Fees or change the amount of, or basis for determining, any Fees.",
];
export const FeesTwo = [
  "You must keep the payment method and other billing information you provide to us current at all times. You can access and change your payment method and billing information via the Service. Any such change will not affect charges we submit via the prior payment method and billing information before we reasonably could act on the change.",
];
export const FeesThree = [
  "Fees do not include any taxes, levies, duties or other governmental assessments of any nature, including but not limited to value-added, sales, use or withholding taxes, imposed or assessed by any authority or jurisdiction (collectively, “<b>Taxes</b>”). You are responsible for the payment of all Taxes (other than Taxes assessable against us based on our income) associated with Fees payable or your subscription to and use of the Service.",
];

export const ProprietaryRightsOne = [
  "<i>General</i>. Notwithstanding any other provision of these Terms, we (or our licensors) are the sole owner of the Service and the systems and software used to provide the Service, and all modifications, enhancements, upgrades and updates thereto, and all copyrights, trademarks, service marks, trade secrets, patents and other intellectual property rights therein (registered or unregistered). You will not acquire any rights in the foregoing other than as expressly set forth herein. You may not reproduce, modify, copy, create derivative works of, sell or distribute any portion of the Service, use of the Service or access to the Service. You may not reverse engineer, reverse assemble, decompile or otherwise attempt to determine or derive the source code of the Service or any portion thereof.",
];
export const ProprietaryRightsTwo = [
  "<i>Feedback<i>. The Service may allow you to submit feedback, annotations, or similar comments or notes about the Service or your use of the Service (“<b>Feedback</b>”). Konfer, Inc. will own, and you hereby assign to Konfer, Inc., all right, title and interest in and to such Feedback, including all intellectual property rights therein and the right to retain and use such Feedback for any purpose.",
];
export const ProprietaryRightsThree = [
  "Anonymized, Aggregated Data. We may anonymize and aggregate any data generated by the Service (including Outputs) or submitted to the Service (including Your Content). So long as such data is in an anonymized, aggregated form, we may collect and use such data, and share such data with third parties, for any purpose.",
];
export const ProprietaryRightsFour = [
  "Usage Data. We collect information and data on how the Service is used by users (“<b>Usage Data</b>”). We may use the Usage Data for our internal business purposes, and share the Usage Data with third parties in an anonymized, aggregated form.",
];
export const ProprietaryRightsFive = [
  "<i>Trademarks</i>. “Konfer”, “Konfer Confidence Cloud”, and all related logos, graphics and icons are service marks or trademarks of Konfer, Inc. All other trademarks, service marks, product names and company names or logos that appear on this site are the property of their respective owners, and their presence in the Service does not represent an endorsement of the Service. ",
];

export const ContentAndRelatedOutputsOne = [
  "Your Content. You are responsible for all content you upload or otherwise submit to the Service (“<b>Your Content</b>”). You warrant that you have all rights necessary to submit Your Content to the Service, and that the use of Your Content by us in accordance with these Terms and the Documentation will not violate the rights of any third party. We use commercially reasonable methods to store Your Content; however, we cannot guarantee that such stored content will never be lost or damaged, and you should keep your own copy of Your Content to the extent you deem necessary. We may store and process Your Content within or outside of the United States of America.",
];
export const ContentAndRelatedOutputsTwo = [
  "<i>Outputs</i>. As a result of your use of the Service in accordance with these Terms, the Service may provide you with certain outputs relating specifically to Your Content and produced in the ordinary course of the Service (“<b>Outputs</b>”). Outputs may include models and knowledge graphs. Such Outputs will be owned by you.",
];

export const ContentAndRelatedOutputsThree = [
  "<i>Our Right to Use Your Content</i>. In connection with our provision of the Service to you, you hereby grant us a non-exclusive, worldwide, royalty-free, perpetual, irrevocable, sub-licensable, transferable license to use, reproduce, modify, translate, display, perform, create derivative works of, and publish Your Content and any related Outputs.",
];
export const ContentAndRelatedOutputsFour = [
  "<i>Deletion of Your Content Upon Termination</i>. <b>If you terminate your account with the Service, or if your access to the Service is otherwise terminated by us or by you, we reserve the right to delete Your Content and any related Outputs from our systems</b>. If you would like us to delete Your Content and related Outputs from our systems, please notify us via the email address set forth at the end of these Terms under “Contact”.",
];

export const NoWarrantiesOne = [
  "YOUR USE OF THE SERVICE IS AT YOUR SOLE RISK. YOU AGREE TO BEAR ALL RISKS ASSOCIATED WITH YOUR USE OF THE SERVICE AND YOUR RELIANCE ON ANY OUTPUTS. THE SERVICE IS PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS. KONFER, INC. AND ITS AFFILIATES, OFFICERS, EMPLOYEES, AGENTS, PARTNERS AND LICENSORS EXPRESSLY DISCLAIM ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT. KONFER, INC. AND ITS AFFILIATES, OFFICERS, EMPLOYEES, AGENTS, PARTNERS AND LICENSORS MAKE NO REPRESENTATION OR WARRANTY: (i) THAT THE SERVICE WILL MEET YOUR REQUIREMENTS; (ii) THAT THE SERVICE WILL BE UNINTERRUPTED, TIMELY, SECURE, OR ERROR-FREE; (iii) THAT THE OUTPUTS OBTAINED FROM THE USE OF THE SERVICE (INCLUDING WITHOUT LIMITATION ANY MODELS OR KNOWLEDGE GRAPHS) WILL BE ACCURATE OR RELIABLE; OR (iv) THAT THE RESULTS OF ANY ACTIONS OR OMISSIONS TAKEN IN RELIANCE ON THE OUTPUTS WILL MEET YOUR EXPECTATIONS. ",
];
export const NoWarrantiesTwo = ["Limitation of Liability"];
export const NoWarrantiesThree = [
  "KONFER, INC. AND ITS AFFILIATES, OFFICERS, EMPLOYEES, AGENTS, PARTNERS AND LICENSORS SHALL NOT BE LIABLE TO YOU FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL OR EXEMPLARY DAMAGES, INCLUDING WITHOUT LIMITATION DAMAGES FOR LOSS OF PROFITS, GOODWILL, USE OR DATA OR OTHER INTANGIBLE LOSSES, RESULTING FROM OR INCURRED IN CONNECTION WITH ANY OF THE FOLLOWING (EVEN IF ADVISED OF THE POSSIBILITY OF SUCH DAMAGES): (i) THE USE OR THE INABILITY TO USE THE SERVICE, OR TERMINATION OF THE SERVICE; (ii) ANY GOODS, DATA, INFORMATION OR SERVICES PURCHASED OR OBTAINED OR MESSAGES RECEIVED OR TRANSACTIONS ENTERED INTO THROUGH OR FROM THE SERVICE; (iii) UNAUTHORIZED ACCESS TO OR ALTERATION OF YOUR TRANSMISSIONS OR DATA; (iv) STATEMENTS OR CONDUCT OF ANY THIRD PARTY ON THE SERVICE OR ACCESSED THROUGH THE SERVICE; OR (v) ANY OTHER MATTER RELATING TO THE SERVICE. WITHOUT LIMITING THE FOREGOING, THE LIABILITY OF KONFER, INC. AND ITS AFFILIATES, OFFICERS, EMPLOYEES, AGENTS, PARTNERS AND LICENSORS FOR DAMAGES WITH RESPECT TO THIS AGREEMENT AND/OR YOUR USE OF THE SERVICE (WHETHER IN CONTRACT, STRICT LIABILITY, NEGLIGENCE, TORT OR OTHERWISE) SHALL NOT EXCEED AN AMOUNT EQUAL TO THE GREATER OF (A) $100 OR (B) THE FEES PAID BY YOU TO US UNDER THIS AGREEMENT DURING THE TWELVE (12) MONTHS PRIOR TO THE DATE OF THE INITIAL EVENT CAUSING OR RESULTING IN SUCH LIABILITY.",
];
export const NoWarrantiesFour = ["Exclusions and Limitations"];
export const NoWarrantiesFive = [
  "THE LAWS OF SOME STATES DO NOT ALLOW THE DISCLAIMER OF IMPLIED WARRANTIES OR THE EXCLUSION OR LIMITATION OF LIABILITY FOR INCIDENTAL OR CONSEQUENTIAL DAMAGES. ACCORDINGLY, SOME OF THE ABOVE DISCLAIMERS, EXCLUSIONS OR LIMITATIONS MAY NOT APPLY TO YOU.",
];
export const NoWarrantiesSix = ["Individual Actions"];
export const NoWarrantiesSeven = [
  "Only YOU AND WE AGREE THAT EACH OF US MAY BRING CLAIMS OR ACTIONS AGAINST THE OTHER ONLY ON AN INDIVIDUAL BASIS AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS OR REPRESENTATIVE ACTION OR PROCEEDING. CLASS ACTIONS, PRIVATE ATTORNEY GENERAL ACTIONS, AND CONSOLIDATION WITH OTHER CLAIMS OR ACTIONS ARE NOT PERMITTED.",
];
export const NoWarrantiesEight = ["Indemnification"];
export const NoWarrantiesNine = [
  "To the fullest extent permitted by law, you agree to indemnify, defend and hold harmless Konfer, Inc. and its affiliates, officers, directors, employees, agents, partners and licensors from and against all claims, damages, liabilities, losses and expenses (including without limitation reasonable attorneys’ fees) arising out of or incurred in connection with any claim by any third party where such claim is due to or arises out of your use of the Service, Your Content or related Outputs, or your violation of these Terms, applicable law or the rights of any third party.",
];

export const GeneralOne = [
  "<i>Notices</i>. We may provide you with notices, including those regarding changes to these Terms, by email, regular mail or postings on the Service.",
];
export const GeneralTwo = [
  "<i>Choice of Law and Forum</i>. These Terms, your use of the Service and the relationship between you and Konfer, Inc., and any disputes, claims or causes of action related to the foregoing, shall be governed by the law of the State of California, United States, without regard to its conflict of laws principles, and any such dispute, claim or cause of action shall be brought only in federal or state court in the County of Santa Clara, California, United States, and you and Konfer, Inc. hereby generally and unconditionally submit to and accept the jurisdiction of such courts.",
];
export const GeneralThree = [
  "<i>Injunctive Relief</i>. With respect to the protection of our confidential information or intellectual property, we may seek to enforce our rights hereunder through temporary or permanent injunctive relief, which shall be in addition to any other available relief and which shall not require a bond or security. You acknowledges that any breach by you of your obligations hereunder with respect to our confidential information or intellectual property rights might constitute immediate, irreparable harm for which monetary damages would be an inadequate remedy.",
];
export const GeneralFour = [
  "<i>Assignment</i>. These Terms, including without limitation any rights or obligations provided for herein, may not be assigned, delegated or otherwise transferred by you. Any assignment, delegation or transfer attempted to be made in violation of these Terms shall be null and void.",
];
export const GeneralFive = [
  "<i>Waiver and Severability</i>. Our failure to exercise or enforce any right or provision of these Terms shall not constitute a waiver of such right or provision by us. Any provision of these Terms which is held invalid or unenforceable in any jurisdiction will, as to that jurisdiction, be ineffective only to the extent of such invalidity or unenforceability and without rendering invalid or unenforceable the remaining provisions of these Terms or affecting the validity or enforceability of any of the provisions of these Terms in any other jurisdiction, and the court or tribunal so holding will be empowered to substitute, to the extent enforceable, provisions similar to said provision, or other provisions, so as to provide to the parties the benefits intended by said provision to the fullest extent permitted by applicable law. If any provision of these Terms is so broad as to be unenforceable, the provision will be interpreted to be only so broad as is enforceable.",
];
export const GeneralSix = [
  "<i>U.S. Government Restricted Rights</i>. The Service and any software used to provide the Service are commercial computer software subject to RESTRICTED RIGHTS. In accordance with 48 CFR 12.212 (Computer software) or DFARS 227.7202 (Commercial computer software and commercial computer software documentation), as applicable, the use, duplication, and disclosure of the Service and such software by the United States of America, its agencies or instrumentalities is subject to the restrictions set forth in these Terms.",
];
export const GeneralSeven = [
  "<i>Limitations Period for Claims</i>. Regardless of any statute or law to the contrary, any claim or cause of action by you arising out of or related to these Terms or the use of the Service must be filed within one (1) year after such claim or cause of action arose or be forever barred.",
];
export const GeneralEight = [
  "<i>Amendments to these Terms</i>. We may amend these Terms at any time by posting the amended Terms on this page or otherwise notifying you in writing of the amendments. The amendments shall be effective upon such posting or notification. You agree to review these Terms periodically so that you are aware of any amendments. Your continued access or use of the Service after such amendments constitutes your agreement to be bound by the amended Terms.",
];
export const GeneralNine = [
  "<i>Contact</i>. If you have questions about the Service, please contact us via email at info@konfer.ai.",
];

export const GeneralEntireAgreement = [
  "<i>Entire Agreement</i>. These Terms represent the entire agreement between you and Konfer, Inc. with regard to their subject matter, they supersede all prior agreements and representations, and they may not be modified except by Konfer, Inc. in writing (including modifications by us to this page).",
];
