import React, { useState, useEffect } from "react";
import { Checkbox, CircularProgress, TextField } from "@mui/material";
import _ from "lodash";
import { useLocation } from "react-router-dom";
import { useGetAccessList } from "../../api/readApiFunctions";
import "./RoleCreateComp.css";
import { createNewRoleMsg } from "../../constants/roleManagmenetMsg";
import { accessesNamesConst } from "../../constants/accesslistConstants";

/**
 * Component : RoleCreateComp
 *
 * Description : This component is responsible for showing the create/Upadte/Clone screen with accesses.
 * Also call the api to display the page list options.
 *
 * Props : roleCreateData setRoleCreateData - payload state,
 *         roleEditTile -  title for create/update/clone,
 *         setCreateRole - setter which decides to show create screen of table view,
 *         setIsCreateRole - setter function which indicates to call the create mutation,
 *         setIsEditRole - setter function which indicates to call the update mutation,
 *  */

const RoleCreateComp = ({
  setCreateRole,
  setIsCreateRole,
  roleCreateData,
  setRoleCreateData,
  roleEditTitle,
  setIsEditRole,
}) => {
  const location = useLocation();
  //Maintain the all page list and their accesses
  const [access_list, setAccessList] = useState(null);
  //Maintain the checked state of all view access
  const [is_all_access_view, setIsAllAccessView] = useState(false);
  //Maintain the checked state of all access (read/write/update/delete)
  const [is_all_access, setIsAllAccess] = useState(false);

  //Create page list and their accesses as per the checkbox
  const [access_view, setAccessView] = useState([]);

  //get accessList
  const {
    data: getAccessList,
    error: getAccessListError,
    isLoading: isGetAccessListLoading,
  } = useGetAccessList(
    location.pathname.split("/")[2] == "roleManagement" &&
      location.pathname.split("/")[2] !== undefined
      ? true
      : false
  );

  //Below useEffect is used to store the access list in particula state
  useEffect(() => {
    if (getAccessList) {
      if (roleEditTitle === createNewRoleMsg) {
        if (
          getAccessList.hasOwnProperty("roles_access") &&
          getAccessList.roles_access.length > 0
        ) {
          setAccessList(getAccessList.roles_access[0]);
        }
      } else {
        let tempObjectForAccessLst = {};
        roleCreateData?.data?.length > 0 &&
          roleCreateData?.data?.map((moduleData) => {
            tempObjectForAccessLst[Object.keys(moduleData)[0]] =
              Object.values(moduleData)[0];
          });

        setAccessList(tempObjectForAccessLst);
      }
    }
  }, [getAccessList]);

  /**Below useEffect is used to create the list and convert the data according checkbox,
   * means full_access, view_access as per read, write, delete, exucute & update*/
  useEffect(() => {
    let tempListArr = [];

    access_list &&
      Object.entries(access_list).map(([key, val], i) => {
        let tempList = {};
        tempList.module = key;
        tempList.view_access = false;
        tempList.full_access = false;
        val.map((submodeulaData) => {
          if (
            submodeulaData.read === true &&
            submodeulaData.delete === false &&
            submodeulaData.execution === false &&
            submodeulaData.update === false &&
            submodeulaData.write === false
          ) {
            tempList.view_access = true;
          } else tempList.view_access = false;

          if (
            submodeulaData.read === true &&
            submodeulaData.delete === true &&
            submodeulaData.execution === true &&
            submodeulaData.update === true &&
            submodeulaData.write === true
          ) {
            tempList.full_access = true;
          } else tempList.full_access = false;
        });
        tempListArr.push(tempList);
      });

    let sortedArray = _.sortBy(tempListArr, ["module"]);

    setAccessView(sortedArray);
  }, [access_list]);

  /**Below useEffect is used to handle the states of all full & view checkbox*/
  useEffect(() => {
    if (access_view) {
      let all_full_access = false;
      let all_view_access = false;

      for (var i = 0; i < access_view.length; i++) {
        if (access_view[i].view_access) {
          all_view_access = true;
        } else {
          all_view_access = false;
          break;
        }
      }

      for (var i = 0; i < access_view.length; i++) {
        if (access_view[i].full_access) {
          all_full_access = true;
        } else {
          all_full_access = false;
          break;
        }
      }

      setIsAllAccessView(all_view_access);
      setIsAllAccess(all_full_access);
    }
  }, [access_view]);

  // Below function in called when all checkbox of full access is checked/unchecked
  const handleAllFullAccess = () => {
    let updatedData = access_view.map((list) => ({
      ...list,
      view_access: false,
      full_access: !is_all_access,
    }));
    setAccessView(updatedData);
  };

  // Below function in called when all checkbox of view access is checked/unchecked
  const handleAllViewAccess = () => {
    let updatedData = access_view.map((list) => ({
      ...list,
      view_access: !is_all_access_view,
      full_access: false,
    }));
    setAccessView(updatedData);
  };

  // Below function in called when single checkbox of view access is checked/unchecked
  const handleViewAccess = (module, viewAccess) => {
    const filterData = access_view.filter((val) => val.module !== module);

    let updatedData = [];
    updatedData = [
      ...filterData,
      {
        module: module,
        view_access: !viewAccess,
        full_access: false,
      },
    ];

    let sortedArray = _.sortBy(updatedData, ["module"]);
    setAccessView(sortedArray);
  };

  // Below function in called when single checkbox of full access is checked/unchecked
  const handleFullAccess = (module, fullAccess) => {
    const filterData = access_view.filter((val) => val.module !== module);
    let updatedData = [];
    updatedData = [
      ...filterData,
      {
        module: module,
        view_access: false,
        full_access: !fullAccess,
      },
    ];
    let sortedArray = _.sortBy(updatedData, ["module"]);
    setAccessView(sortedArray);
  };

  const handleCreate = () => {
    let finalData = [];
    access_list &&
      Object.entries(access_list).map(([key, val], i) => {
        val.map((submodeulaData) => {
          let tempFinalData = {};
          access_view &&
            access_view.map((accessViewData) => {
              if (accessViewData.module === key) {
                tempFinalData.access_id = submodeulaData.access_id;

                if (
                  submodeulaData.hasOwnProperty("roles_access_id") &&
                  roleEditTitle === "Edit Role"
                ) {
                  tempFinalData.roles_access_id =
                    submodeulaData.roles_access_id;
                }

                tempFinalData.module = submodeulaData.module;
                tempFinalData.sub_module = submodeulaData.sub_module;
                tempFinalData.action = submodeulaData.action;
                tempFinalData.read =
                  accessViewData.full_access === true
                    ? true
                    : accessViewData.view_access;
                tempFinalData.write = accessViewData.full_access;
                tempFinalData.update = accessViewData.full_access;
                tempFinalData.delete = accessViewData.full_access;
                tempFinalData.execution = accessViewData.full_access;
              }
            });
          finalData.push(tempFinalData);
        });
      });

    setRoleCreateData({
      ...roleCreateData,
      data: finalData,
    });

    roleCreateData?.name?.trim()?.length > 0 &&
      (roleEditTitle == createNewRoleMsg || roleEditTitle == "Clone Role"
        ? setIsCreateRole(true)
        : setIsEditRole(true));
  };

  return (
    <>
      <div
        className="userList-heading1"
        id="businessunitHeading"
        style={{ marginLeft: "35px" }}
      >
        {roleEditTitle && roleEditTitle}
      </div>
      <div className="role_input_container">
        <div className="roleList" id="roleUnit">
          <div className="roleCreateList" id="roleUnit">
            <div
              className="role_inputField"
              style={{
                display: "grid",
                gridTemplateColumns: "200px 1fr",
                paddingBottom: "30px",
              }}
            >
              <label className="info_label_role">Role Name</label>
              <TextField
                className="info-input"
                sx={{ "& .MuiInputBase-input": { padding: "0px" } }}
                id="role_name"
                variant="standard"
                defaultValue={roleCreateData.name}
                // value={roleCreateData.name}
                onChange={(e) => {
                  setRoleCreateData({
                    ...roleCreateData,
                    name: e.target.value.trim(),
                  });
                }}
              />
            </div>
            <div
              className="role_inputField"
              style={{
                display: "grid",
                gridTemplateColumns: "200px 1fr",
              }}
            >
              <label className="info_label_role">Description</label>
              <TextField
                className="info-input"
                id="description_name"
                sx={{ "& .MuiInputBase-root": { padding: "0px" } }}
                defaultValue={roleCreateData.description}
                onChange={(e) => {
                  setRoleCreateData({
                    ...roleCreateData,
                    description: e.target.value.trim(),
                  });
                }}
                maxRows={2}
                variant="standard"
                multiline
              />
            </div>
          </div>
          <div
            className="roleAccess"
            style={{ overflowY: "auto" }}
            id="roleUnit"
            write
          >
            <div className="roleAccess_title">
              <p>Access</p>
              <p className="role_checkbox_container">
                <Checkbox
                  sx={{ padding: "0px", marginRight: "13px" }}
                  checked={is_all_access_view}
                  color="default"
                  onClick={handleAllViewAccess}
                />
                <span>All</span>
              </p>
              <p className="role_checkbox_container">
                <Checkbox
                  sx={{ padding: "0px", marginRight: "13px" }}
                  checked={is_all_access}
                  color="default"
                  onClick={handleAllFullAccess}
                />
                <span>All</span>
              </p>
            </div>
            {isGetAccessListLoading ? (
              <CircularProgress />
            ) : (
              access_view?.map((roleData, index) => {
                return (
                  <div className="roleAccess_data">
                    <p>{accessesNamesConst[roleData?.module]}</p>
                    <p className="role_checkbox_container">
                      <Checkbox
                        sx={{ padding: "0px", marginRight: "13px" }}
                        checked={roleData.view_access}
                        color="default"
                        id={index}
                        onClick={() => {
                          handleViewAccess(
                            roleData.module,
                            roleData.view_access
                          );
                        }}
                      />
                      <span>View Access</span>
                    </p>
                    <p className="role_checkbox_container">
                      <Checkbox
                        sx={{ padding: "0px", marginRight: "13px" }}
                        checked={roleData.full_access}
                        color="default"
                        id={index}
                        onClick={() => {
                          handleFullAccess(
                            roleData.module,
                            roleData.full_access
                          );
                        }}
                      />
                      <span>Full Access</span>
                    </p>
                  </div>
                );
              })
            )}
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            height: "80px",
            textAlign: "center",
          }}
        >
          <button
            className="roleBtn"
            onClick={() => {
              setIsCreateRole(false);
              setCreateRole(false);
              setRoleCreateData({});
              setIsEditRole(false);
            }}
          >
            Cancel
          </button>
          <button
            className={
              roleCreateData?.name?.trim()?.split("")?.length > 0
                ? "roleBtnActive"
                : "roleBtn"
            }
            onClick={() => {
              roleCreateData?.name?.trim()?.length > 0 && handleCreate();
            }}
          >
            {roleEditTitle == createNewRoleMsg || roleEditTitle == "Clone Role"
              ? "Create"
              : "Save"}
          </button>
        </div>
      </div>
    </>
  );
};

export default RoleCreateComp;
