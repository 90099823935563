import { IconButton } from "@mui/material";
import { useAtom } from "jotai";
import React, { useEffect } from "react";
import { useState } from "react";
import { accessDataByRoleAtom, authUser, selectedNode } from "../../atom/jotai";
import { MutationKeyName } from "../../constants/dataTypes";
import AnnotationCard from "../AnnotationCard/AnnotationCard";

import AddIcon from "../../assets/images/detail/AddIcon.svg";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import "./Annotations.css";
import {
  useCreateAnnotation,
  useCreateSlackAnnotation,
} from "../../api/createApiFunctions";
import {
  useGetAnnotations,
  useGetSlackUsers,
} from "../../api/readApiFunctions";
import { analyticServices } from "../../services/analyticServices";
import { MentionsInput, Mention } from "react-mentions";
import defaultStyle from "../../assets/css/mentionsStyle.js";
import { handleAnnotationsRender } from "../../services/detailsServices";
import _ from "lodash";
import { isAccessProvided } from "../../services/assetManagementService";
import {
  accessConstants,
  moduleConstants,
  subModuleConstants,
} from "../../constants/accessManagementConstants";

const Annotations = ({ selectedData, subModInvoker }) => {
  // holds node clicked data
  const [nodeData, setSelectedNode] = useAtom(selectedNode);
  // get logged in user data
  const [user] = useAtom(authUser);

  // set a common data to use in component since data changes on node click
  const [dataInfo, setDataInfo] = useState(null);
  const [mention, setMention] = useState("");
  // to store new annotation input
  const { mutate: createSlackAnnotation } = useCreateSlackAnnotation();
  const { data: slackUsers } = useGetSlackUsers("detail");
  const [accessDataByRole, setAccessDataByRole] = useAtom(accessDataByRoleAtom);

  // set data to show i.e between default selected and node selected
  useEffect(() => {
    if (nodeData) {
      setDataInfo(nodeData);
      // setSelectedNode(selectedData[0]);
    } else {
      setDataInfo(selectedData[0]);
      setSelectedNode(selectedData[0]);
    }
  }, [nodeData]);

  // react query to get annotations
  const { data, error, isLoading } = useGetAnnotations(
    dataInfo?.[`${dataInfo?.__typename}_id`],
    dataInfo?.__typename?.toUpperCase(),
    dataInfo,
    subModInvoker
  );

  // eact query to add annotations
  const { mutate: addAnnotation } = useCreateAnnotation();

  // loader
  if (isLoading) {
    return <h2>loading</h2>;
  }

  // handle error [pending]
  if (error) {
    console.log("Annotations ERROR");
  }

  const handleAddAnnotations = (nodeId, nodeName, userId) => {
    if (mention.trim().length > 0) {
      const annotationToString = handleAnnotationsRender(mention, slackUsers);

      const payload = {
        name: mention,
        contributor_id: userId,
        text_name: annotationToString,
        type: nodeName.toUpperCase(),
        type_id: nodeId,
        subModInvoker: "detail",
      };
      analyticServices(` Annotation created :- ${mention} `);
      addAnnotation(payload);
      const slackPayload = {
        annotationMessage: mention,
        url: `${window.location.protocol}//${window.location.host}/detail/${
          dataInfo?.__typename
        }/${dataInfo?.[`${dataInfo?.__typename}_id`]}`,
        subModInvoker: "detail",
      };
      createSlackAnnotation(slackPayload);
      setMention("");
      toast.success("Annotation added");
    }
  };

  const handleMentionChange = (e) => {
    setMention(e.target.value);
  };

  const handleDisplayTransform = (id) => {
    const user = slackUsers.find((user) => user.id === id);
    return user.display;
  };

  return (
    <div className="annotation_wrapper" id="nodeDetailsAnnotationWrapper">
      <div className="annotaion_header" id="annotationHeader">
        <div className="title">
          <p>Annotations</p>
        </div>
        {isAccessProvided(
          moduleConstants.annotation,
          subModuleConstants.detail,
          null,
          accessConstants.write,
          accessDataByRole
        ) && (
          <div className="add_input-wrapper" id="addAnnotationWrapper">
            <MentionsInput
              id="addAnnotationInput"
              style={defaultStyle}
              className="annotation_input"
              value={mention}
              onChange={handleMentionChange}
              singleLine={true}
              placeholder="Mention people using '@'"
              onKeyPress={(ev) => {
                if (ev.key === "Enter") {
                  handleAddAnnotations(
                    dataInfo?.[`${dataInfo?.__typename}_id`],
                    dataInfo?.__typename,
                    user.contributor_id
                  );
                }
              }}
            >
              <Mention
                trigger="@"
                data={slackUsers}
                markup="<@__id__>"
                displayTransform={handleDisplayTransform}
                appendSpaceOnAdd={true}
              />
            </MentionsInput>
            <div
              id="addAnnotationIconButton"
              onClick={(e) => {
                handleAddAnnotations(
                  dataInfo?.[`${dataInfo?.__typename}_id`],
                  dataInfo?.__typename,
                  user.contributor_id
                );
              }}
              className="add_input-icon"
            >
              <img
                src={AddIcon}
                alt="add-icon"
                id="addAnnotationIconImage"
                width={30}
              />
            </div>
          </div>
        )}
      </div>

      {slackUsers && data && data?.annotation?.length > 0 ? (
        data.annotation.map((data, index) => {
          return (
            <AnnotationCard
              data={data}
              annotaionIndex={index}
              key={data.annotation_id}
              slackUsers={slackUsers && slackUsers}
              dataInfo={dataInfo}
            />
          );
        })
      ) : (
        <p className="annotation_card">No Annotations to show</p>
      )}
      <ToastContainer />
    </div>
  );
};

export default Annotations;
