import React, { useEffect, useRef, useState } from "react";
import { useAtom } from "jotai";
import {
  Box,
  CircularProgress,
  FormControl,
  IconButton,
  MenuItem,
  Select,
  Tooltip,
} from "@mui/material";
import "../../ListviewTable/ListviewTable.css";

import sortUpArrow from "../../../assets/images/listview/sortUpArrow.svg";
import sortDownArrow from "../../../assets/images/listview/sortDownArrow.svg";
import _ from "lodash";

import ListviewComplianceCard from "../ListviewComplianceCard/ListviewComplianceCard";
import {
  useGetAllPolicies,
  useGetAppsDetails,
} from "../../../api/readApiFunctions";
import {
  activeCountOfHighlightWord,
  businessUnitName,
  finalMapping,
  indexOfSearchedKeywordAtom,
  positionOfHighlightWord,
  regionNameAtom,
  selectedBusinessUnit,
  totalCountOfHighlightWord,
} from "../../../atom/jotai";
import { mchartDataFormat } from "../../../services/detailsServices";
import NoResult from "../../NoResult/NoResult";
import { tempName } from "../../../services/listviewServices";
import {
  complianceTagName,
  complianceTagValue,
} from "../../../constants/complianceConstants";
import { ScoreValueNew } from "../../../constants/amcharts";
import { dataTypes } from "../../../constants/dataTypes";
import MuiSelectForPolicy from "../../MuiSelect/MuiSelectForPolicy";
import { useLocation } from "react-router-dom";

const ListviewComplianceTable = ({
  appIds,
  setScoreCount,
  konferScoreSelected,
  findElement,
  keywords,
  policyData,
}) => {
  // handle konfer sort state
  const [sortArrowUp, setSortArrowUp] = useState(false);

  const {
    data: appsDetails,
    error: appsDetailsError,
    isLoading: appsLoading,
  } = useGetAppsDetails(appIds, true, konferScoreSelected, sortArrowUp , "" , "");

  //Jotai states
  const [totalCountOfHighlightedWord, setTotalCountOfHighlitedWord] = useAtom(
    totalCountOfHighlightWord
  );
  const [activeCountOfHighlightedWord, setActiveCountOfHighlitedWord] = useAtom(
    activeCountOfHighlightWord
  );
  const [positionOfHighlightedWord, setPositionOfHighlightedWord] = useAtom(
    positionOfHighlightWord
  );
  const [finalPosition, setFinalPosition] = useAtom(finalMapping);
  const [indexOfSearchedKeyword, setIndexOfSearchedKeyword] = useAtom(
    indexOfSearchedKeywordAtom
  );

  const [businessName] = useAtom(businessUnitName);
  const [regionName] = useAtom(regionNameAtom);
  const [selectedBuId] = useAtom(selectedBusinessUnit);
  const [scoresSelectedPolicy, setScoresSelectedPolicy] = useState("EU AI Act");

  const getScoreCount = (data) => {
    let redScore = 0;
    let blueScore = 0;

    for (let i = 0; i < data.length; i++) {
      const element = data[i];
      const mcharFormatted = mchartDataFormat([element], true)[0];

      const konferScore =
        element?.risk_factors_data?.length > 0
          ? element?.risk_factors_data?.konfer_composite_score
          : null;

      if (
        konferScore ||
        konferScore == ScoreValueNew.lowest_limit_red_score_new
      ) {
        if (
          konferScore >= ScoreValueNew.lowest_limit_red_score_new &&
          konferScore <= ScoreValueNew.highest_limit_red_score
        ) {
          redScore = redScore + 1;
        } else {
          blueScore = blueScore + 1;
        }
      } else if (konferScore == null) {
        redScore = redScore + 1;
      }
    }
    const res = {
      redScore,
      blueScore,
    };
    return res;
  };

  useEffect(() => {
    let scoreObj;

    // get the score value
    if (appsDetails) {
      scoreObj = getScoreCount(appsDetails);
    }
    setScoreCount(scoreObj);
  }, [appsDetails]);

  /**Below usedEffect is responsible for checking wheather the Find keyword is available or not ,
   * and if yes then to set its count */
  useEffect(() => {
    if (_.isEmpty(findElement)) return; //If the input field is empty, there is no need to set all these states.
    if (appsDetails) {
      let count = 0;
      let position = {
        positionOfSubstringFinal: {},
        count: 0,
      };
      let finalMappingOfHighlitedElements = [];
      appsDetails.map((appData, index) => {
        position = tempName(
          findElement,
          appData.name,
          appData,
          regionName,
          selectedBuId[0],
          dataTypes.apps,
          scoresSelectedPolicy
        );

        count = count + position.count;
        finalMappingOfHighlitedElements.push(position.positionOfSubstringFinal);
      });
      setFinalPosition(finalMappingOfHighlitedElements);
      setTotalCountOfHighlitedWord(count);
    }
  }, [findElement, appsDetails]);

  const handleSelectPolicy = (e) => {
    setScoresSelectedPolicy(e.target.value);
  };

  if (appsLoading) {
    return <h2>Loading apps</h2>;
  }

  if (appsDetails?.length === 0) {
    return (
      <>
        <NoResult />
      </>
    );
  }

  return (
    <>
      <div className={"table-container-cpo"}>
        <div className="table-header-container" style={{ marginRight: "30px" }}>
          {/* <div className="table_item_chart table-heading__name">
            <span
            // style={{
            //   marginLeft: "20px",
            // }}
            >
              Preview
            </span>
          </div> */}

          <div
            className="table_item table-heading__name"
            style={{
              marginLeft: "25px",
            }}
          >
            Name
          </div>

          <div
            className="table_item_score"
            id="konferConfidenceTableHeadingDiv"
            style={{
              fontWeight: "bold",
              color: "black",
              // fontSize: "10px",
            }}
          >
            <span>Konfer Confidence</span>
            {konferScoreSelected && (
              <span className="filter_icon">
                <IconButton
                  id="resultKonferConfidenceSortingButton"
                  aria-label="cancel"
                  style={{ padding: "0px" }}
                  onClick={() => {
                    setSortArrowUp(!sortArrowUp);
                  }}
                >
                  {sortArrowUp ? (
                    <img
                      src={sortUpArrow}
                      alt="sortUpArrow"
                      className="sort-arrow"
                    />
                  ) : (
                    <img
                      src={sortDownArrow}
                      alt="sortDownArrow"
                      className="sort-arrow"
                    />
                  )}
                </IconButton>
              </span>
            )}
          </div>

          <div
            className="table_item table-heading__name"
            style={{
              marginLeft: "25px",
            }}
          >
            <MuiSelectForPolicy
              minWidthVal={80}
              data={policyData && policyData}
              value={scoresSelectedPolicy}
              handleChange={handleSelectPolicy}
            />
          </div>

          <div
            className="table_item table-heading__name"
            style={{
              marginLeft: "25px",
            }}
          >
            Human Impact
          </div>

          <div
            className="table_item table-heading__name"
            style={{
              display: "flex",
              justifyContent: "center",
              textAlign: "center",
              alignItems: "center",
              // marginLeft: "16px",
            }}
          >
            Version
          </div>

          <div className="table_item table-heading__name">
            Deployment Region
          </div>

          {regionName === complianceTagName.AllRegionsTag && (
            <div className="table_item table-heading__name">Region</div>
          )}

          {(selectedBuId[0] === undefined ||
            selectedBuId[0] === complianceTagValue.AllBusinessUnis) && (
            <div className="table_item table-heading__name">Business Unit</div>
          )}

          {/* -------------- */}

          {/* <div className="table_item table-heading__name">Date Created</div> */}

          <div className="table_item"></div>
          <div className="table_item"></div>
        </div>

        <div className="table-cards-cpo">
          {appsDetails && appsDetails?.length > 0 ? (
            appsDetails.map((appData, index) => (
              <>
                <ListviewComplianceCard
                  key={appData.app_id}
                  appData={appData}
                  index={index}
                  findElement={findElement}
                  keywords={keywords}
                  scoresSelectedPolicy={scoresSelectedPolicy}
                />
              </>
            ))
          ) : (
            <NoResult />
          )}
        </div>
      </div>
    </>
  );
};

export default ListviewComplianceTable;
