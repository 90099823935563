import React, { useState, useEffect } from "react";
import {
  KeyboardDoubleArrowLeft,
  KeyboardDoubleArrowRight,
} from "@mui/icons-material";
import { useAtom } from "jotai";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { CircularProgress, IconButton } from "@mui/material";
import {
  accessDataByRoleAtom,
  appIdForOpeningSummarViewAtom,
  authUser,
  businessUnitName,
  openCPOAnnotationsAtom,
  openSideBarAtom,
  orgNameAtom,
  organizationIDAtom,
  regionIDAtom,
  regionNameAtom,
  riskFactorCategoryNameAtom,
  selectedBusinessUnit,
} from "../../../atom/jotai";

import ConfidenceFactors from "../../../components/ComplianceComponents/ConfidenceFactors/ConfidenceFactors";
import Layout from "../../../layout/Layout";
import {
  useGetAllPolicies,
  useGetBusinessUnitsNew,
  useGetConfidenceFactors,
} from "../../../api/readApiFunctions";
import "../listview-compliance-page/ListviewCompliancePage.css";
import ComplianceAnnotation from "../../../components/ComplianceComponents/ComplianceAnnotation/ComplianceAnnotation";
import SummaryPage from "../../../components/ComplianceComponents/SummaryPage/SummaryPage";
import CpoSummaryResultview from "../../../components/ComplianceComponents/complianceResultview/CpoSummaryResultview";
import { getType } from "@amcharts/amcharts5/.internal/core/util/Type";
import { isAccessProvided } from "../../../services/assetManagementService";
import NoBusinessUnitsAssignedModal from "../../../components/NoBusinessUnitsAssignedModal/NoBusinessUnitsAssignedModal";

const GraphviewCompliancePage = () => {
  const [user] = useAtom(authUser);
  // const [open, setOpen] = useState(true); //State for maintaing Annotations on compliance open or close
  const [selectedBuId, setSelectedBusiness] = useAtom(selectedBusinessUnit);
  const [businessName, setBusinessUnitName] = useAtom(businessUnitName);
  const [businessData, setBusinessData] = useState([]);
  const { appId, regionId, businessId, searchText } = useParams();
  const location = useLocation();
  const [, setOrgName] = useAtom(orgNameAtom);
  const [, setOrganizationID] = useAtom(organizationIDAtom);
  const [regionID, setRegionID] = useAtom(regionIDAtom);
  const [regionName, setRegionName] = useAtom(regionNameAtom);
  const [regionData, setRegionData] = useState([]);
  const [appsNameData, setAppsNameData] = useState([]);
  const [appIdForOpeningSummarView, setAppIdForOpeningSummarView] = useAtom(
    appIdForOpeningSummarViewAtom
  );
  const [openCPOAnnotations, setOpenCPOAnnotations] = useAtom(
    openCPOAnnotationsAtom
  ); //State for maintaing Annotations on compliance open or close
  const [openSideBar, setOpenSideBar] = useAtom(openSideBarAtom); //State for maintaing sidebar open or close
  const [riskFactorCategoryName, setRiskFactorCategoryName] = useAtom(
    riskFactorCategoryNameAtom
  ); //State to maintain risk factor score type
  const [policyData, setPolicyData] = useState(); //state to maintain policies list coming from database
  const [accessDataByRole] = useAtom(accessDataByRoleAtom);

  const {
    data: businessUnitData,
    isError,
    isLoading,
  } = useGetBusinessUnitsNew(
    user?.role,
    user?.contributor_id,
    location.pathname.split("/")[2] == "compliance-summary" ? true : false,
    "summary_view"
  );

  //Getting Policies
  const {
    data: allPolicy,
    error: allPolicyError,
    isLoading: allPolicyLoading,
  } = useGetAllPolicies(
    "summary_view",
    "crud",
    location.pathname.split("/")[2] == "compliance-summary" ? true : false
  );

  // store the nodes count to show in the label
  const [dataCount, setDataCount] = useState({
    app: 0,
    model: 0,
    dataset: 0,
  });

  //Below useEffect is used for giving title to current page
  useEffect(() => {
    document.title = "Konfer Compliance Summary Page";
  });

  /**  Below useEffects is used to set policy data coming from database*/
  useEffect(() => {
    allPolicy && setPolicyData(allPolicy?.data?.data?.score_type_config);
    if (allPolicy) {
      const filteredRows = allPolicy?.data?.data?.score_type_config.filter(
        (row) => {
          return row.status.toUpperCase() === "PUBLISHED";
        }
      );
      setPolicyData(filteredRows);
    }
  }, [allPolicy]);

  useEffect(() => {
    if (businessUnitData) {
      let tempArr = [];
      let tempAppArr = [];
      let tempArrForRegion = [];
      businessUnitData?.data?.data?.regionData?.map((item, index) => {
        tempArrForRegion.push({
          id: item.regionID,
          name: item.regionName,
        });
        if (item.regionID == regionId) {
          setRegionName(item.regionName);
          setRegionID(item.regionID);
        }

        item.regionID == regionId &&
          item.businessUnitsData?.map((data) => {
            tempArr.push({
              id: data.businessUnitID,
              name: data.businessUnitName,
            });

            if (data.businessUnitID == businessId) {
              // setBusinessUnitName(data.businessUnitName);
              // setSelectedBusiness([data.businessUnitID]);

              data.appData.map((apps, index) => {
                tempAppArr.push({
                  id: apps.appID,
                  name: apps.appName,
                });
              });
            }
          });
      });
      setRegionData(tempArrForRegion);
      setBusinessData(tempArr);
      setSelectedBusiness([businessId]);
      setAppsNameData(tempAppArr);
      setAppIdForOpeningSummarView(appId);
      setOrgName(
        businessUnitData?.data?.data?.organizationDetail[0]?.organizationName
      );
      setOrganizationID(
        businessUnitData?.data?.data?.organizationDetail[0]?.organizationID
      );
    }
  }, [businessUnitData]);

  const {
    data: summaryCFactorsData,
    isError: summaryCFactorsError,
    isLoading: summaryCFactorsLoading,
    refetch,
  } = useGetConfidenceFactors(
    location.pathname.split("/")[2] == "compliance-summary"
      ? "APPSUMMARY"
      : getType(regionID, selectedBuId, appIdForOpeningSummarView),
    appId,
    riskFactorCategoryName,
    businessUnitData && location.pathname.split("/")[2] == "compliance-summary"
      ? true
      : false
  );

  const handleCompAnnotations = () => {
    setOpenCPOAnnotations(!openCPOAnnotations);
    if (openSideBar === true) {
      setOpenSideBar(!openSideBar);
    }
  };

  return (
    <>
      <div
        id="complianceViewWrapper"
        className="compliance-listview-wrapper"
        style={openCPOAnnotations ? {} : { gridTemplateColumns: "1fr 50px" }}
      >
        <div>
          {businessUnitData && (
            <CpoSummaryResultview
              businessUnitData={businessUnitData}
              businessData={businessData}
              setBusinessData={setBusinessData}
              // round of and coverting 2 digit
              count={
                summaryCFactorsData &&
                summaryCFactorsData?.appFinalRiskAvgSummary
              }
              appsNameData={appsNameData}
              setAppsNameData={setAppsNameData}
              regionData={regionData}
            />
          )}
          {summaryCFactorsLoading ? (
            <CircularProgress style={{ marginLeft: "20px" }} />
          ) : (
            summaryCFactorsData !== undefined &&
            summaryCFactorsData &&
            summaryCFactorsData?.riskfactors?.length !== undefined &&
            summaryCFactorsData?.riskfactors?.length > 0 && (
              <ConfidenceFactors
                isSummary={true}
                dataCount={dataCount}
                riskFactorsSummaryData={summaryCFactorsData?.riskfactors}
                riskFactorCategoryName={riskFactorCategoryName}
                setRiskFactorCategoryName={setRiskFactorCategoryName}
                policyData={policyData}
              />
            )
          )}
          <SummaryPage
            dataCount={dataCount}
            setDataCount={setDataCount}
            graphviewConFactorsRefetch={refetch}
          />
        </div>
        {isAccessProvided(
          "annotation",
          "profile",
          null,
          "read",
          accessDataByRole
        ) && (
          <div className="compliance-annoatation-box">
            <IconButton
              onClick={handleCompAnnotations}
              style={{ padding: "0px" }}
            >
              {openCPOAnnotations ? (
                <KeyboardDoubleArrowRight />
              ) : (
                <KeyboardDoubleArrowLeft />
              )}
            </IconButton>
            {openCPOAnnotations && <ComplianceAnnotation />}
          </div>
        )}
      </div>
      {/**Below Code is for when no bussiness unit is attached to the logged in user */}
      <NoBusinessUnitsAssignedModal
        accessDataByRole={accessDataByRole}
        moduleInvoker={"summary_view"}
      />
    </>
  );
};

export default GraphviewCompliancePage;
