import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  useGetAppData,
  useGetDatasetData,
  useGetModelData,
} from "../../api/readApiFunctions";
import Annotations from "../../components/Annotations/Annotations";
import { Breadcrumbs } from "../../components/Breadcrumbs/Breadcrumbs";
import DetailInfo from "../../components/DetailInfo/DetailInfo";
import NodeDetail from "../../components/NodeDetail/NodeDetail";
import { typeNames } from "../../constants/dataTypes";
import Layout from "../../layout/Layout";
import "./DetailPage.css";
import Split from "react-split";
import { capitalize } from "lodash";
import { useAtom } from "jotai";
import {
  accessDataByRoleAtom,
  detailBreadcrumbsData,
  isSidebar,
} from "../../atom/jotai";
import { truncateName } from "../../services/listviewServices";
import { isAccessProvided } from "../../services/assetManagementService";
import NoBusinessUnitsAssignedModal from "../../components/NoBusinessUnitsAssignedModal/NoBusinessUnitsAssignedModal";

/**
 * Component : DetailPage
 * Description : This is the main layout page (parent component) of node details,
 * which get data for the nodes, display node representation, details & scores , and annotations
 */
const DetailPage = () => {
  const { nodeType, nodeId } = useParams();
  const [sidebar] = useAtom(isSidebar);
  const location = useLocation();
  const [accessDataByRole] = useAtom(accessDataByRoleAtom);

  const [selectedData, setSelectedData] = useState(null);

  const [detailBreadcrumbs, setDetailBreadCrumbs] = useAtom(
    detailBreadcrumbsData
  );

  const [nodeDetailsExpandView, setNodeDeatilExpandView] = useState(false); //State for maintaing Node Details accordian expand
  // get detail api call
  const {
    data: appData,
    isLoading: appLoading,
    error: appError,
    refetch: appRefetch,
  } = useGetAppData(nodeId, nodeType === typeNames.apps);
  const {
    data: modelData,
    isLoading: modelLoading,
    error: modelError,
    refetch: modelRefetch,
  } = useGetModelData(nodeId, nodeType === typeNames.models);
  const {
    data: datasetData,
    isLoading: datasetLoading,
    error: datasetError,
    refetch: datasetRefetch,
  } = useGetDatasetData(nodeId, nodeType === typeNames.datasets);
  const [rfrshDAftrUpdtScr, setRfrshDAftrUpdtScr] = useState(false);

  /** Below useEffect() is used for getting the data of selected node (when the particular node is clicked)  */
  useEffect(() => {
    if (nodeType === typeNames.apps && appData) {
      setSelectedData(appData.app);
    }
    if (nodeType === typeNames.models && modelData) {
      setSelectedData(modelData.model);
    }
    if (nodeType === typeNames.datasets && datasetData) {
      setSelectedData(datasetData.input_data_table);
    }
  }, [appData, modelData, datasetData, nodeType]);

  //Below useEffect is used for giving title to current page
  useEffect(() => {
    document.title = "Konfer Nodes Details Page";
  });

  //  the used used for set detail page breadcrumbs
  useEffect(() => {
    if (selectedData !== null) {
      setDetailBreadCrumbs([
        { names: "Search results" },
        {
          names:
            selectedData &&
            `${capitalize(selectedData[0]?.__typename)} : ${
              selectedData[0]?.display_name
                ? truncateName(selectedData[0]?.display_name)
                : truncateName(selectedData[0]?.name)
            }`,
        },
      ]);
    }
  }, [selectedData]);

  return (
    <>
      <div className={"detailsWrapper"} id="nodesDetailsView">
        <Split
          id="splitBetNodesAndDetails"
          sizes={[60, 40]}
          minSize={
            nodeDetailsExpandView
              ? []
              : window.innerWidth > 1450
              ? sidebar
                ? [800, 520]
                : [850, 530]
              : sidebar
              ? [500, 450]
              : [780, 455]
          }
          className="wrap"
          direction="horizontal"
          cursor="ew-resize"
          onDragStart={(e) => setNodeDeatilExpandView(false)}
        >
          <div
            id="nodesDetailsContainerDiv"
            className="node_detail_container"
            style={nodeDetailsExpandView ? { width: "96%" } : { width: "50%" }}
          >
            <NodeDetail
              selectedData={selectedData}
              nodeDetailsExpandView={nodeDetailsExpandView}
              setNodeDeatilExpandView={setNodeDeatilExpandView}
              rfrshDAftrUpdtScr={rfrshDAftrUpdtScr}
              setRfrshDAftrUpdtScr={setRfrshDAftrUpdtScr}
            />
          </div>
          <div
            id="nodeDetailsInfoDiv"
            className="details_container"
            style={
              nodeDetailsExpandView ? { display: "none" } : { width: "50%" }
            }
          >
            <Split
              id="splitBetDetailInfoAndANnotations"
              sizes={[80, 20]}
              className="wrap1 mainDetailsBox"
              direction="vertical"
              cursor="row-resize"
            >
              <div
                className={
                  isAccessProvided(
                    "annotation",
                    "detail",
                    null,
                    "read",
                    accessDataByRole
                  ) && "detail_vertical_container"
                }
                id="detailsInfoDiv"
              >
                {selectedData && selectedData.length > 0 && (
                  <DetailInfo
                    selectedData={selectedData}
                    rfrshDAftrUpdtScr={rfrshDAftrUpdtScr}
                    setRfrshDAftrUpdtScr={setRfrshDAftrUpdtScr}
                    appRefetch={appRefetch}
                    modelRefetch={modelRefetch}
                    datasetRefetch={datasetRefetch}
                  />
                )}
              </div>

              {isAccessProvided(
                "annotation",
                "detail",
                null,
                "read",
                accessDataByRole
              ) && (
                <div className="annotaion_vertical_container">
                  {selectedData && (
                    <Annotations
                      selectedData={selectedData}
                      subModInvoker="detail"
                    />
                  )}
                </div>
              )}
            </Split>
          </div>
        </Split>
      </div>
      {/**Below Code is for when no bussiness unit is attached to the logged in user */}
      <NoBusinessUnitsAssignedModal
        accessDataByRole={accessDataByRole}
        moduleInvoker={"home"}
      />
    </>
  );
};

export default DetailPage;
