import React, { useState, useEffect } from "react";
import { useAtom } from "jotai";
import AdminUsetlistSearch from "../../components/AdminUsetlistSearch/AdminUsetlistSearch";
import Layout from "../../layout/Layout";
import "../admin-userlist-page/AdminUserlistPage.css";
import "../../components/ListviewFilter/ListviewFilter.css";
import NoResult from "../../components/NoResult/NoResult";
import {
  useGetAllNodeBuRelData,
  useGetRoleRegionBuData,
} from "../../api/readApiFunctions";
import NodeBusinessunitRelTable from "../../components/AdminNodeBusinessunitRel/NodeBusinessunitRelTable/NodeBusinessunitRelTable";
import { AssignBUTypes } from "../../constants/adminConfigConstant";
import { useLocation } from "react-router-dom";
import ApplicationIcon from "../../assets/images/adminPanel/Application.svg";
import ModelIcon from "../../assets/images/adminPanel/Model.svg";
import DatasetIcon from "../../assets/images/adminPanel/Dataset.svg";
import { accessDataByRoleAtom } from "../../atom/jotai";
import { isAccessProvided } from "../../services/assetManagementService";
import {
  moduleConstants,
  subModuleConstants,
} from "../../constants/accessManagementConstants";
import { entityNameConst } from "../../constants/dataTypes";

const AdminNodeBusinessunitRel = () => {
  //state for managing access management system golbal value
  const [accessDataByRole, SetAccessDataByRole] = useAtom(accessDataByRoleAtom);
  const location = useLocation();

  const [selectedType, setSelectedType] = useState(AssignBUTypes.apps);

  //Below code is for getting apps,Models and datasets details
  const {
    data: allNodeBuRelData,
    error: allNodeBuRelDataError,
    isLoading: allNodeBuRelDataLoading,
  } = useGetAllNodeBuRelData(
    location.pathname.split("/")[2] == "node-businessunit-relationship" &&
      location.pathname.split("/")[2] !== undefined &&
      accessDataByRole &&
      isAccessProvided(
        moduleConstants.admin_view,
        subModuleConstants.business_units_assignment,
        null,
        "read",
        accessDataByRole
      )
      ? true
      : false
  );

  const [nodeData, setNodeData] = useState([]); //state to maintain all apps data coming from database
  const [regionData, setRegionData] = useState([]);

  //get all regions
  const {
    data: allRegionData,
    error: allRegionError,
    isLoading: allRegionLoading,
  } = useGetRoleRegionBuData(
    "region",
    "business_units_assignment",
    location.pathname.split("/")[2] == "node-businessunit-relationship" &&
      location.pathname.split("/")[2] !== undefined &&
      accessDataByRole &&
      isAccessProvided(
        moduleConstants.admin_view,
        subModuleConstants.business_units_assignment,
        null,
        "read",
        accessDataByRole
      )
  );

  useEffect(() => {
    allRegionData && allRegionData.length > 0 && setRegionData(allRegionData);
  }, [allRegionData]);

  useEffect(() => {
    if (selectedType == "apps") {
      setNodeData(allNodeBuRelData?.appDetails);
    } else if (selectedType == "models") {
      setNodeData(allNodeBuRelData?.modelDetails);
    } else if (selectedType == AssignBUTypes.input_data_tables) {
      setNodeData(allNodeBuRelData?.input_dataDetails);
    }
  }, [allNodeBuRelData, selectedType]);

  //Below useEffect is used for giving title to current page
  useEffect(() => {
    document.title = "Admin Business unit Assignment Page";
  });

  return (
    <>
      {/* here First I will call API for User DAta and business unit */}
      <div className="userList-heading1">Business Unit Assignment</div>

      {isAccessProvided(
        moduleConstants.admin_view,
        subModuleConstants.business_units_assignment,
        null,
        "read",
        accessDataByRole
      ) === true ? (
        <div className="userlist">
          {/* <div className="userlist-heading paddingTRL30px">
          
        </div> */}
          <div className="userlist-search-and-create paddingTRL30px">
            <AdminUsetlistSearch
              allAppsDetailsWithBU={allNodeBuRelData?.appDetails}
              nodeData={nodeData}
              setNodeData={setNodeData}
              isNodeBUAssignee={true}
              allModelsDetailsWithBU={allNodeBuRelData?.modelDetails}
              allDatasetsDetailsWithBU={allNodeBuRelData?.input_dataDetails}
              selectedType={selectedType}
            />
          </div>

          <div
            className="filter-container"
            style={{ margin: "25px 15px 0px 15px" }}
          >
            <div
              className={`chip-button ${
                selectedType === "apps" && "chip-button-active"
              }`}
              onClick={() => {
                setSelectedType(AssignBUTypes.apps);
              }}
            >
              <img src={ApplicationIcon} alt="" className="filter-image" />
              <h3 className="filter-name">{entityNameConst.applications}</h3>
              <p>
                (
                {selectedType === "apps"
                  ? nodeData?.length
                  : allNodeBuRelData?.appDetails?.length}
                )
              </p>
            </div>

            <div
              className={`chip-button ${
                selectedType === "models" && "chip-button-active"
              }`}
              onClick={() => {
                setSelectedType(AssignBUTypes.models);
              }}
            >
              <img src={ModelIcon} alt="" className="filter-image" />
              <h3 className="filter-name">{entityNameConst.models}</h3>
              <p>
                (
                {selectedType === "models"
                  ? nodeData?.length
                  : allNodeBuRelData?.modelDetails?.length}
                )
              </p>
            </div>

            {/** For the demo purpose commented the below code */}
            {/* <div
              className={`chip-button ${
                selectedType === AssignBUTypes.input_data_tables &&
                "chip-button-active"
              }`}
              onClick={() => {
                setSelectedType(AssignBUTypes.input_data_tables);
              }}
            >
              <img src={DatasetIcon} alt="" className="filter-image" />
              <h3 className="filter-name">{entityNameConst.datasets}</h3>
              <p>
                (
                {selectedType === AssignBUTypes.input_data_tables
                  ? nodeData?.length
                  : allNodeBuRelData?.input_dataDetails?.length}
                )
              </p>
            </div> */}
          </div>

          {nodeData && nodeData?.length === 0 ? (
            <div className="hgt userlist-users-table">
              <NoResult title={"No Data Available"} />
            </div>
          ) : (
            <div className="userlist-users-table marginT0 borderR">
              <NodeBusinessunitRelTable
                nodeData={nodeData}
                selectedType={selectedType}
                regionData={regionData}
                accessDataByRole={accessDataByRole}
              />
            </div>
          )}
        </div>
      ) : (
        <div className="hgt userlist-users-table" id="noAccessToRoleManagment">
          {accessDataByRole && (
            <NoResult title={"No Access to Business unit Assignment Page"} />
          )}
        </div>
      )}
    </>
  );
};

export default AdminNodeBusinessunitRel;
