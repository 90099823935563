import axios from "axios";
import request from "graphql-request";
import { useMutation, useQueryClient } from "react-query";
import { queryKeyConstants } from "../constants/basicConstants";
import { createDatasetQueAnswer } from "../graphql/mutations";
import {
  createCPOAnnotationQuery,
  getAllBusinessUnits,
  getDataSetLastQueId,
  getQuestionByIdQuery,
} from "../graphql/queries";
import { loadDefaultSeedData } from "../graphql/seedDataQueries";
import nodeApiInstance from "./instance/nodeApiInstance";
import { useUpdateDataSetCardAnswer } from "./updateApiFunctions";
import { toast } from "react-toastify";
import { useAtom } from "jotai";
import { authUser } from "../atom/jotai";

const endpoint = `${process.env.REACT_APP_GRAPHQL_API}v1/graphql`;

export const useCreateAnnotation = () => {
  const queryClient = useQueryClient();

  const addAnnotation = async (payload) => {
    const { data } = await nodeApiInstance.post(
      "/annotation/create-annotation",
      payload
    );
    return data;
  };

  return useMutation(addAnnotation, {
    onSuccess: () => {
      queryClient.invalidateQueries(queryKeyConstants.getAnnotation);
    },
  });
};

// used for crete region
export const useCreateRegion = () => {
  const queryClient = useQueryClient();

  const addRegion = (data) => {
    return nodeApiInstance.post("details/create-region", data);
  };

  return useMutation(addRegion, {
    onSuccess: () => {
      queryClient.invalidateQueries(queryKeyConstants.getRoleRegionBuData);
    },
  });
};

//  admin configuration load default seed data into database.
export const useAdminConfigurationLoadData = () => {
  const query = loadDefaultSeedData();

  const loadData = () => {
    return axios.post(
      endpoint,
      JSON.stringify({
        query,
      })
    );
  };
  return useMutation(loadData);
};

//  admin configuration extract
export const useAdminConfigurationExtract = () => {
  const extractData = (payload) => {
    return axios.post(
      `${process.env.REACT_APP_ADMIN_CONFIGURATION}/run`,
      payload,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  };
  return useMutation(extractData);
};

//Create New cardTemplate API
export const useCreateCardTemplate = () => {
  const queryClient = useQueryClient();

  const addCardTemplate = (payload) => {
    return nodeApiInstance.post(`/details/update-template-details`, payload);
  };
  return useMutation(addCardTemplate, {
    onSuccess: () => {
      queryClient.invalidateQueries(queryKeyConstants.getTemplateData);
    },
  });
};

//  set configuration
export const useAdminConfigurationSetConfig = () => {
  const queryClient = useQueryClient();
  const configData = (payload) => {
    return nodeApiInstance.post(
      "details/extraction-store-credentials",
      payload
    );
  };
  return useMutation(configData, {
    onSuccess: () => {
      queryClient.invalidateQueries(queryKeyConstants.getConfigData);
    },
    onError: (error) => {
      toast.error(error?.response?.data?.message);
      queryClient.invalidateQueries(queryKeyConstants.getConfigData);
    },
  });
};

//  get configuration
export const useAdminConfigurationGetConfig = () => {
  const getConfigData = () => {
    return axios.get(`${process.env.REACT_APP_ADMIN_CONFIGURATION}/config`);
  };
  return useMutation(getConfigData);
};

//Create New USer API
export const useCreateNewUser = () => {
  const queryClient = useQueryClient();

  const addUser = async (payload) => {
    const data = await nodeApiInstance.post("/auth/register", payload);
    return data;
  };

  return useMutation(addUser, {
    onSuccess: () => {
      queryClient.invalidateQueries(queryKeyConstants.userlist);
    },
  });
};

//Create New USer API
//Create New USer API
//Create New USer API

export const useCreateDataSetQueAnswer = () => {
  const queryClient = useQueryClient();

  const addDataSetQueAnswer = async (payload) => {
    // const query = createDatasetQueAnswer();

    const datasetQueId = await request(endpoint, getDataSetLastQueId());
    const updatedData = {
      ...payload,
      card_question_id: datasetQueId?.questions[0]?.card_question_id + 1,
    };
    const { subsection_id, answer_type, question, text, card_question_id } =
      updatedData;

    return await request(
      endpoint,
      createDatasetQueAnswer(
        subsection_id,
        answer_type,
        question,
        text,
        card_question_id
      )
    );
  };

  return useMutation(addDataSetQueAnswer, {
    onSuccess: () => {
      queryClient.invalidateQueries(queryKeyConstants.modelCardData);
    },
  });
};

//Create feedback
export const useCreateNewFeedback = () => {
  const submitFeedback = async (payload) => {
    const data = await nodeApiInstance.post("/details/feedback", payload);
    return data;
  };

  return useMutation(submitFeedback);
};

export const useCreateSlackAnnotation = () => {
  const slackAnnotation = async (payload) => {
    const data = await nodeApiInstance.post(
      "/details/slack-annotations",
      payload
    );
    return data;
  };

  return useMutation(slackAnnotation);
};

// Roles list API
export const useCreateRoles = () => {
  const queryClient = useQueryClient();

  const createRoles = async (payload) => {
    const data = await nodeApiInstance.post("/role/create-role", payload);

    return data;
  };

  return useMutation(createRoles, {
    onSuccess: () => {
      queryClient.invalidateQueries(queryKeyConstants.getAllRoles);
    },
  });
};

export const useCreateCPOAnnotation = () => {
  const query = createCPOAnnotationQuery();
  const queryClient = useQueryClient();

  const addCPOAnnotation = async (payload) => {
    const { data } = await nodeApiInstance.post(
      "/annotation/create-annotation",
      payload
    );
    return data;
  };

  return useMutation(addCPOAnnotation, {
    onSuccess: () => {
      queryClient.invalidateQueries(queryKeyConstants.getCPOAnnotation);
    },
  });
};

export const useCreateDatasetCardFromTemplate = () => {
  const queryClient = useQueryClient();

  const createDatasetCardFromTemplate = async (payload) => {
    const data = await nodeApiInstance.post("/details/create-card", payload);
    return data.data;
  };

  return useMutation(createDatasetCardFromTemplate, {
    onSuccess: () => {
      queryClient.invalidateQueries(queryKeyConstants.appData);
      queryClient.invalidateQueries(queryKeyConstants.modelData);
      queryClient.invalidateQueries(queryKeyConstants.datasetData);
      queryClient.invalidateQueries(queryKeyConstants.getListviewTableData);
    },
  });
};

export const useRiskFactorCalculate = () => {
  const queryClient = useQueryClient();

  const riskFactorCalculate = async (payload) => {
    const data = await nodeApiInstance.post(
      "/risk_factors/create-risk-factor",
      payload
    );
    return data;
  };

  return useMutation(riskFactorCalculate, {
    onSuccess: () => {
      queryClient.invalidateQueries(queryKeyConstants.riskfactors);
      queryClient.invalidateQueries(queryKeyConstants.modelCardData);
    },
  });
};

export const useUploadFiles = () => {
  const queryClient = useQueryClient();

  const { mutate: updateCardAnswer } = useUpdateDataSetCardAnswer();

  const uploadFile = async (payload) => {
    const { filePayload, questionId } = payload;
    const newData = [];
    let textData = "";
    const data = await nodeApiInstance.post(
      "/card/upload-answer-files",
      filePayload
    );

    if (data?.data?.data && questionId) {
      // get the answer for the question id of type file.
      const answerRes = await request(
        endpoint,
        getQuestionByIdQuery(questionId)
      );
      const { text } = answerRes.questions[0];

      // check if there is any data and is array
      if (text && text !== null) {
        const parsedText = JSON.parse(text);
        if (Array.isArray(parsedText)) {
          // then push the new data in that array
          data.data.data.map((urlResponse) => {
            parsedText.push(urlResponse);
          });
          textData = JSON.stringify(parsedText);
        }
      } else {
        // set the existing data as test
        textData = JSON.stringify(data?.data?.data);
      }

      const payload = {
        card_question_id: questionId,
        text: textData,
      };

      updateCardAnswer(payload);
    }

    if (data?.data?.data) {
      const filesRes = data?.data?.data;

      for (let i = 0; i < filesRes.length; i++) {
        const file = filesRes[i];
        file.questionId = questionId;
        newData.push(file);
      }
    }

    return newData;
  };

  return useMutation(uploadFile, {
    onSuccess: () => {
      queryClient.invalidateQueries(queryKeyConstants.modelCardData);
    },
  });
};

export const useImportCSVFiles = () => {
  const importCSVFile = async (payload) => {
    const { filePayload } = payload;

    const data = await nodeApiInstance.post(
      `/details/import-csv-for-card-template`,
      filePayload
    );
    return data.data.data;
  };

  return useMutation(importCSVFile, {
    onSuccess: () => {},
    onError: (err) => {},
  });
};

export const useFileExtraction = () => {
  const [user] = useAtom(authUser);
  const fileBasedExtraction = async (fileData) => {
    const { selectedFiles: file, sampleFileSelect: selectedExtraction } =
      fileData;

    const formdata = new FormData();
    formdata.append("JSON Structure", file, "chatbot_seed.json");
    formdata.append("Contributor_ID", user?.contributor_id);

    let data = null;

    try {
      const response = await axios({
        method: "post",
        url: `${process.env.REACT_APP_ADMIN_CONFIGURATION}/json_structure/${selectedExtraction}`,
        data: formdata,
        headers: { "Content-Type": "multipart/form-data" },
      });

      if (response?.data?.status_code === 200) {
        toast.success(response?.data?.message);
      }
      data = response.data;
    } catch (e) {
      console.log("e", e);
      toast.error(e.response.data.message);
    }

    return data;
  };

  return useMutation(fileBasedExtraction);
};

export const useCreateSubsectionTextData = () => {
  const queryClient = useQueryClient();
  const riskFactorCalculate = async (payload) => {
    const data = await nodeApiInstance.post(
      "/card/create-subsection-text-data",
      payload
    );
    return data;
  };

  return useMutation(riskFactorCalculate, {
    onSuccess: () => {
      queryClient.invalidateQueries(queryKeyConstants.riskfactors);
      queryClient.invalidateQueries(queryKeyConstants.appData);
      queryClient.invalidateQueries(queryKeyConstants.modelData);
      queryClient.invalidateQueries(queryKeyConstants.datasetData);
      queryClient.invalidateQueries(queryKeyConstants.modelCardData);
    },
  });
};

export const useCreatAmiInstance = () => {
  const createAmiInstance = async (payload) => {
    // since we get the error message in successfull lambda response.
    const response = {
      isApiSuccess: false,
      message: "Something went wrong!",
    };

    const { data } = await axios.post(
      `${process.env.REACT_APP_LAMBDA_ORGANISATION_URL}`,
      payload
    );
    if (data?.Error_Message) {
      response.message = data?.Error_Message;
    } else {
      response.isApiSuccess = true;
      response.message =
        "Thank you for showing your interest in Konfer.AI , The demo credentials will be sent via email to you shortly!";
    }

    return response;
  };

  return useMutation(createAmiInstance, {
    onSuccess: (amiResponse) => {
      if (amiResponse?.isApiSuccess === true) {
        toast.success(amiResponse?.message, {
          autoClose: false,
        });
      }
      if (amiResponse?.isApiSuccess === false) {
        toast.error(amiResponse?.message, {
          autoClose: false,
        });
      }
    },
    onError: (error) => {
      if (error?.response?.data?.Error_Message) {
        toast.error(error?.response?.data?.Error_Message, {
          autoClose: false,
        });
      } else if (error?.message) {
        toast.error(error?.message, {
          autoClose: false,
        });
      } else {
        toast.error("Something went wrong!!", {
          autoClose: false,
        });
      }
    },
  });
};

//below function is used for getting answer fron open ai
export const useSearchQueInOpenAI = (document, question) => {
  const getAnswerFromOpenAI = async () => {
    const response = await axios.post(
      "https://api.openai.com/v1/completions",
      {
        model: "text-davinci-003",
        prompt: `Based on the following Document: ${document}\n\nAnswer the following Question: ${question}\nA:`,
        temperature: 0,
        max_tokens: 1000,
        top_p: 1,
        frequency_penalty: 0,
        presence_penalty: 0,
        stop: ["\n"],
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `${process.env.REACT_APP_OPEN_API_TOKEN}`,
        },
      }
    );
    return response.data.choices;
  };

  return useMutation(getAnswerFromOpenAI, {
    onSuccess: () => {},
    onError: (err) => {},
  });
};

export const useFileUploadForDocument = () => {
  const uploadFile = async (payload) => {
    const { filePayload } = payload;
    const data = await nodeApiInstance.post(
      "/card/upload-document-subsection-files",
      filePayload
    );

    return data?.data?.data;
  };

  return useMutation(uploadFile);
};
// get migration response
export const useGetMigrationDifference = () => {
  const getMigrationDifference = async (payload) => {
    const data = await nodeApiInstance.post(
      "/card/get-migration-details",
      payload
    );
    return data?.data?.data;
  };

  return useMutation(getMigrationDifference);
};
export const useUploadCardFiles = () => {
  const uploadFile = async (payload) => {
    const { filePayload } = payload;

    const data = await nodeApiInstance.post(
      "/card/upload-answer-files",
      filePayload
    );

    return data.data;
  };

  return useMutation(uploadFile, {
    onSuccess: () => {},
  });
};

//Api for uploading crad response in csv format
export const useImprtCSVFilsFrCrdResponces = () => {
  const queryClient = useQueryClient();
  const imprtCSVFilsFrCrdResponces = async (payload) => {
    const { filePayload, cardId, cardType, cardTypeId } = payload;

    const data = await nodeApiInstance.post(
      `/details/import-answer?cardId=${cardId}&type=${cardType}&typeId=${cardTypeId}`,
      filePayload
    );
    return data;
  };

  return useMutation(imprtCSVFilsFrCrdResponces, {
    onSuccess: () => {
      queryClient.invalidateQueries(queryKeyConstants.modelCardData);
    },
  });
};

//Api for uploading crad response in csv format
export const useCreateGenAiTemplateResponse = () => {
  const queryClient = useQueryClient();
  const createGenAiTemplateResponse = async (payload) => {
    const { data } = await nodeApiInstance.post(
      `/card/get-single-generated-response`,
      payload
    );
    return data;
  };

  return useMutation(createGenAiTemplateResponse, {
    onSuccess: () => {
      queryClient.invalidateQueries(queryKeyConstants.modelCardData);
    },
  });
};
