import React, { useEffect, useState } from "react";
import { IconButton } from "@mui/material";
import { CloseOutlined, Search } from "@mui/icons-material";
import "./AdminUsetlistSearch.css";
import { AssignBUTypes } from "../../constants/adminConfigConstant";

const AdminUsetlistSearch = ({
  userlist,
  userlistData,
  setUserlistData,
  allBusinessUnits,
  businessUnitsData,
  setBusinessUnitsData,
  isBusinessunit,
  isNodeBUAssignee,
  allAppsDetailsWithBU,
  nodeData,
  setNodeData,
  allModelsDetailsWithBU,
  allDatasetsDetailsWithBU,
  selectedType,
  getRolesData,
  rolesData,
  setRolesData,
  isRoleManagement,
  allPolicy,
  policyData,
  setPolicyData,
  isPolicy,
  allRegionData,
  regionData,
  setRegionData,
  isRegion,
}) => {
  const [searched, setSearched] = useState("");

  const handleSearch = () => {
    if (isBusinessunit) {
      //If coming from business unit
      const filteredRows = businessUnitsData.filter((row) => {
        return (
          row.name.toLowerCase().includes(searched.toLowerCase()) ||
          row.region.name.toLowerCase().includes(searched.toLowerCase())
        );
      });
      setBusinessUnitsData(filteredRows);
    } else if (isNodeBUAssignee) {
      //If coming from Node Businee unit assignee
      const filteredRows = nodeData.filter((row) => {
        let regionName = "";
        row.apps_business_units?.map((item, index) => {
          if (index < 1) {
            regionName = item.business_unit?.region?.name.toLowerCase();
          }
        });

        return (
          (row.display_name === null ? row.name : row.display_name)
            .toLowerCase()
            ?.includes(searched.toLowerCase()) ||
          row.version?.includes(searched) ||
          (regionName !== "" && regionName.includes(searched.toLowerCase()))
        );
      });
      setNodeData(filteredRows);
    } else if (isRoleManagement) {
      const filteredRows = rolesData.filter((row) => {
        return row.name.toLowerCase().includes(searched.toLowerCase());
      });
      setRolesData(filteredRows);
    } else if (isPolicy) {
      const filteredRows = policyData.filter((row) => {
        return (
          row.score_name.toLowerCase().includes(searched.toLowerCase()) ||
          row.status.toLowerCase().includes(searched.toLowerCase())
        );
      });
      setPolicyData(filteredRows);
    } else if (isRegion) {
      const filteredRows = regionData.filter((row) => {
        return row.name.toLowerCase().includes(searched.toLocaleLowerCase());
      });
      setRegionData(filteredRows);
    } else {
      const filteredRows = userlistData.filter((row) => {
        return (
          row.name.toLowerCase().includes(searched.toLowerCase()) ||
          row.email.toLowerCase().includes(searched.toLowerCase())
        );
      });
      setUserlistData(filteredRows);
    }
  };

  useEffect(() => {
    setSearched("");
  }, [
    userlist,
    allBusinessUnits,
    allAppsDetailsWithBU,
    allModelsDetailsWithBU,
    allDatasetsDetailsWithBU,
    selectedType,
    getRolesData,
    allPolicy,
    allRegionData,
  ]);

  return (
    <div className="admin-userlist-search" id="adminSearch">
      <input
        id="adminSearchInput"
        className="admin-userlist-input"
        placeholder="Search"
        type="text"
        value={searched}
        onChange={(e) => {
          if (e.target.value) {
            setSearched(e.target.value);
          } else {
            setSearched("");
            if (isBusinessunit) {
              setBusinessUnitsData(allBusinessUnits);
            } else if (isNodeBUAssignee) {
              selectedType === AssignBUTypes.apps &&
                setNodeData(allAppsDetailsWithBU);
              selectedType === AssignBUTypes.models &&
                setNodeData(allModelsDetailsWithBU);
              selectedType === AssignBUTypes.input_data_tables &&
                setNodeData(allDatasetsDetailsWithBU);
            } else if (isRoleManagement) {
              setRolesData(getRolesData);
            } else if (isPolicy) {
              if (allPolicy) {
                const filteredRows =
                  allPolicy?.data?.data?.score_type_config?.filter((row) => {
                    return (
                      row.score_name !== "Human Impact" &&
                      row.score_name !== "Konfer Confidence"
                    );
                  });
                setPolicyData(filteredRows);
              }
            } else if (isRegion) {
              allRegionData && setRegionData(allRegionData);
            } else {
              setUserlistData(userlist);
            }
          }
        }}
        onKeyPress={(ev) => {
          if (ev.key === "Enter") {
            handleSearch();
          }
        }}
      />

      {searched !== "" && searched && (
        <IconButton
          id="adminSearchCancel"
          type="submit"
          style={{ marginRight: "-27px" }}
          // className="cancel_querypage_searchIcons"
          onClick={() => {
            setSearched("");
            if (isBusinessunit) {
              setBusinessUnitsData(allBusinessUnits);
            } else if (isNodeBUAssignee) {
              selectedType === AssignBUTypes.apps &&
                setNodeData(allAppsDetailsWithBU);
              selectedType === AssignBUTypes.models &&
                setNodeData(allModelsDetailsWithBU);
              selectedType === AssignBUTypes.input_data_tables &&
                setNodeData(allDatasetsDetailsWithBU);
            } else if (isRoleManagement) {
              setRolesData(getRolesData);
            } else if (isPolicy) {
              if (allPolicy) {
                const filteredRows =
                  allPolicy?.data?.data?.score_type_config?.filter((row) => {
                    return (
                      row.score_name !== "Human Impact" &&
                      row.score_name !== "Konfer Confidence"
                    );
                  });
                setPolicyData(filteredRows);
              }
            } else if (isRegion) {
              allRegionData && setRegionData(allRegionData);
            } else {
              setUserlistData(userlist);
            }
          }}
          aria-label="cancel"
        >
          <CloseOutlined fontSize="medium" />
        </IconButton>
      )}

      <span className="admin-userlist-searchIcons">
        <Search
          id="adminSearchSubmit"
          className="admin-userlist-searchIconsResult"
          onClick={handleSearch}
        />
      </span>
    </div>
  );
};

export default AdminUsetlistSearch;
