import React, { useEffect, useState } from "react";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  PDFViewer,
} from "@react-pdf/renderer";
import PDFQuestionnaireTableHeader from "../PDFQuestionnaireTable/PDFQuestionnaireTableHeader";
import PDFQuestionnaireTableRow from "../PDFQuestionnaireTable/PDFQuestionnaireTableRow";

// Create styles
const styles = StyleSheet.create({
  page: {
    backgroundColor: "#ffffff",
    color: "white",
  },
  section: {
    margin: 10,
    padding: 10,
  },
  tableContainer: {
    flexDirection: "row",
    flexWrap: "wrap",
    overflowWrap: "anywhere",
    marginTop: 24,
    borderWidth: 1,
    borderColor: "#bff0fd",
    color: "#000000",
    fontSize: "12px",
  },
  viewer: {
    width: window.innerWidth, //the pdf viewer will take up all of the width and height
    height: window.innerHeight,
  },
});

const PDFGenerator = ({ dataForTable }) => {
  return (
    // <PDFViewer style={styles.viewer}>
    <Document style={styles.viewer}>
      <Page size="A4" style={styles.page}>
        <View style={styles.tableContainer}>
          {dataForTable && dataForTable.length > 0 && (
            <PDFQuestionnaireTableHeader />
          )}
          {dataForTable && dataForTable.length > 0 && (
            <PDFQuestionnaireTableRow dataForTable={dataForTable} />
          )}
        </View>
      </Page>
    </Document>
    // </PDFViewer>
  );
};

export default PDFGenerator;
