import React, { useEffect, useState } from "react";
import StackedBarChart from "./StackedBarChart/StackedBarChart";
import DivergentBarChart from "./DivergentBarChart/DivergentBarChart";
import HeatmapChart from "./HeatmapChart/HeatmapChart";
import LineChart from "./LineChart/LineChart";
import BarChart from "./BarChart/BarChart";
import GroupBarChart from "./GroupBarChart/GroupBarChart";
import GroupColumnChart from "./GroupColumnChart/GroupColumnChart";
import {
  createHeatmapFormat,
  createLineChartFormat,
  createStackedBarChartFormat,
} from "../../../services/graphChartFormatServices";

const Charts = ({ chartData }) => {
  const [formattedData, setFormattedData] = useState(null);
  const [chartDataPresent, setChartDataPresent] = useState(false);

  const components = {
    stacked_bar: StackedBarChart,
    divergent_stacked_bar: DivergentBarChart,
    Heat_Map_with_Legend: HeatmapChart,
    line_graph: LineChart,
    bar_chart: BarChart,
    group_bar_chart: GroupBarChart,
    group_column_chart: GroupColumnChart,
  };

  const createDataForAmchart = (chartData, chartType) => {
    let finalData = null;
    switch (chartType) {
      case "Heat Map with Legend":
        finalData = createHeatmapFormat(JSON.parse(chartData));
        break;
      case "line_graph":
        finalData = createLineChartFormat(JSON.parse(chartData));
        break;
      case "stacked_bar":
        finalData = createStackedBarChartFormat(JSON.parse(chartData));
        break;
      default:
        finalData = JSON.parse(chartData);
        break;
    }
    setFormattedData(finalData);
  };

  const ComponentName = components[chartData.chart_type?.replaceAll(" ", "_")];

  useEffect(() => {
    if (chartData) {
      createDataForAmchart(chartData.chart_data, chartData.chart_type);
    }

    if (
      (chartData && chartData.chart_type === "stacked_bar") ||
      chartData.chart_type === "divergent_stacked_bar" ||
      chartData.chart_type === "Heat Map with Legend" ||
      chartData.chart_type === "line_graph" ||
      chartData.chart_type === "bar_chart" ||
      chartData.chart_type === "group_bar_chart" ||
      chartData.chart_type === "group_column_chart"
    ) {
      setChartDataPresent(true);
    }
  }, [chartData]);

  return (
    <div>
      {chartDataPresent && formattedData && (
        <ComponentName data={formattedData} />
      )}
    </div>
  );
};
export default Charts;
