import React, { useEffect, useState } from "react";
import { useAtom } from "jotai";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { useGetBusinessUnitsNew } from "../../api/readApiFunctions";
import { KONFER_SCORE } from "../../constants/basicConstants";
import { deslugifyText } from "../../utils/basicUtils";
import { complianceTagName } from "../../constants/complianceConstants";
import { Box, CircularProgress, Divider, Tooltip } from "@mui/material";
import ListviewIcon from "../../assets/images/listview/Listview.svg";
import GraphIcon from "../../assets/images/listview/GraphView.svg";
import {
  authUser,
  businessUnitName,
  businessUnits,
  indexOfSearchedKeywordAtom,
  isComplianceViewAtom,
  isGraphview,
  orgNameAtom,
  organizationIDAtom,
  regionIDAtom,
  regionNameAtom,
  selectedBusinessUnit,
  totalCountOfHighlightWord,
} from "../../atom/jotai";
import MuiSelect from "../MuiSelect/MuiSelect";
import { analyticServices } from "../../services/analyticServices";
import AssetsListviewTable from "./AssetsListviewTable";
import "./Listview.css";
import { pageNameConstants } from "../../constants/componentsPageNameConstants";
import { isAccessProvided } from "../../services/assetManagementService";
import { PolicyNameConst } from "../../constants/policyConstants";

/**
 * Component : AssetsListview
 *
 * Description : This component is responsible for displaying breadcrumbs data (regions, business units in dropdown), 
 * for getting data api is called.
 * It also displays result bar and switches icon between listview and graphview.
 * It calls the Assets listview tables
 
 */

const AssetsListview = ({ accessDataByRole }) => {
  //local states
  const location = useLocation();
  const { searchText } = useParams();
  const navigate = useNavigate();
  const [is_list_view, setIsListView] = useState(
    location.pathname.includes("listview") ? true : false
  );
  const [prev_highlighted_loc, setPrevHighlightedLOC] = useState([]);
  const [current_count, setCurrentCount] = useState(0);
  const [find_element, setFindElement] = useState(null);
  const [region_data, setRegionData] = useState([]);
  const [isGraphViewToggle, setIsGraphViewToggle] = useState(true);

  //global states maintain in jotai
  const [user] = useAtom(authUser);
  const [, setOrgName] = useAtom(orgNameAtom);
  const [business_data, setBusinessData] = useAtom(businessUnits);
  const [selected_businessunit, setSelectedBusiness] =
    useAtom(selectedBusinessUnit);
  const [, setBusinessUnitName] = useAtom(businessUnitName);
  const [region_id, setRegionID] = useAtom(regionIDAtom);
  const [region_name, setRegionName] = useAtom(regionNameAtom);
  const [is_compliance_view, setIsComplianceView] =
    useAtom(isComplianceViewAtom);
  const [is_graph_view, setIsGraphView] = useAtom(isGraphview);
  const [, setTotalCountOfHighlitedWord] = useAtom(totalCountOfHighlightWord);
  const [, setIndexOfSearchedKeyword] = useAtom(indexOfSearchedKeywordAtom);
  const [, setOrganizationID] = useAtom(organizationIDAtom);

  if (location?.pathname.split("/")[1] === "result") {
    setIsComplianceView(false);
  }

  const {
    data: business_unit_data,
    isError,
    isLoading,
  } = useGetBusinessUnitsNew(
    user?.role,
    user?.contributor_id,
    location.pathname.split("/")[2] == "listview" &&
      location.pathname.split("/")[2] !== undefined
      ? true
      : false,
    "asset_view"
  );

  //Below useEffect is used for setting breadcrumbs dropdown value and global values of region and business unit data
  useEffect(() => {
    if (business_unit_data) {
      let temp_arr_for_region = [];
      business_unit_data?.data?.data?.regionData?.map((businessData) => {
        temp_arr_for_region.push({
          id: businessData.regionID,
          name: businessData.regionName,
        });
        if (
          region_id === -1 &&
          businessData.regionName == complianceTagName.AllRegionsTag
        ) {
          setRegionName(businessData.regionName);
          setRegionID(businessData.regionID);
        }
      });
      setRegionData(temp_arr_for_region);
      setOrgName(
        business_unit_data?.data?.data?.organizationDetail[0]?.organizationName
      );
      setOrganizationID(
        business_unit_data?.data?.data?.organizationDetail[0]?.organizationID
      );
    }
  }, [business_unit_data]);

  //Below useEffect is used for giving title to current page
  useEffect(() => {
    document.title = pageNameConstants.Konfer_result_page;
  });

  //This functiona is called when we select from Region drop down
  const handleRegionData = (value) => {
    let tempArrForBD = [];
    setRegionID(value);

    region_data?.map((data) => {
      data.id == value && setRegionName(data.name);

      data.id === value &&
        analyticServices(`SEARCH  : konfer-confidence ${data.name}`);
    });

    business_unit_data.data.data.regionData?.map((businessData) => {
      //setting the region name name for selected region as per the id
      businessData.regionID === value && setRegionName(businessData.regionName);

      //For setting business dropdown data
      if (
        businessData.regionID == value &&
        businessData.regionName !== complianceTagName.AllRegionsTag
      ) {
        businessData.businessUnitsData?.map((unit) => {
          tempArrForBD.push({
            id: unit.businessUnitID,
            name: unit.businessUnitName,
          });
          //For showing All Business units by default
          if (unit.businessUnitName === complianceTagName.AllBusinessUnitsTag) {
            setBusinessUnitName(unit.businessUnitName);
            setSelectedBusiness([unit.businessUnitID]);
          }
        });
      } else if (businessData.regionName === complianceTagName.AllRegionsTag) {
        setSelectedBusiness([]);
      }
    });

    setBusinessData(tempArrForBD);
    setFindElement("");
    setTotalCountOfHighlitedWord(0);
    setIndexOfSearchedKeyword(-1);
    setCurrentCount(0);
    setPrevHighlightedLOC([]);
  };

  //This functiona is called when we select from Business unit drop down
  const handleBusinessUnits = (value) => {
    setSelectedBusiness([value]);

    business_data?.map((data) => {
      data.id == value && setBusinessUnitName(data.name);

      data.id === value &&
        analyticServices(`SEARCH  : konfer-confidence ${data.name}`);
    });

    setFindElement("");
    setTotalCountOfHighlitedWord(0);
    setIndexOfSearchedKeyword(-1);
    setCurrentCount(0);
    setPrevHighlightedLOC([]);
  };

  useEffect(() => {
    if (is_list_view) {
      analyticServices("List View Page Visit");
    } else {
      analyticServices("Graph View Page Visit");
    }
  }, [is_list_view]);

  return isLoading ? (
    <Box sx={{ display: "flex", marginTop: "20%", marginLeft: "50%" }}>
      <CircularProgress />
    </Box>
  ) : (
    <div style={{ margin: "0px 20px" }}>
      <div className="result_title_listview" id="resultTitle">
        <div className="result_title" id="resultTitleHeadings">
          <h2 className="result_title_text">
            {searchText === KONFER_SCORE
              ? `${PolicyNameConst.konfer_confidence}`
              : ` Results for " ${
                  searchText == "*" ? "*" : deslugifyText(searchText)
                }" `}
          </h2>

          {is_list_view && (
            <>
              <Divider
                orientation="vertical"
                variant="middle"
                flexItem
                className="seperator-resultview"
              />
              <MuiSelect
                defaultValue={region_id && region_id}
                data={region_data && region_data}
                onChange={handleRegionData}
              />

              {region_name !== "" &&
                region_name !== complianceTagName.AllRegionsTag && (
                  <>
                    <Divider
                      orientation="vertical"
                      variant="middle"
                      flexItem
                      className="seperator-resultview"
                    />
                    <MuiSelect
                      defaultValue={
                        selected_businessunit && selected_businessunit
                      }
                      data={business_data && business_data}
                      onChange={handleBusinessUnits}
                    />
                  </>
                )}
            </>
          )}
        </div>

        {is_graph_view && isGraphViewToggle && (
          <div className="listview-cont" id="resultButtonsDiv">
            {isAccessProvided(
              "asset_view",
              "list_view",
              "list_view_button",
              "read",
              accessDataByRole
            ) && (
              <div
                id="resultListviewButton"
                className={
                  !is_list_view ? "listview-btn" : "listview-btn list-active"
                }
                onClick={() => setIsListView(true)}
              >
                <Tooltip title="List View">
                  <img
                    src={ListviewIcon}
                    alt=""
                    onClick={() => {
                      navigate(`/result/listview/${searchText}`);
                    }}
                    style={{ width: "20px", height: "20px" }}
                  />
                </Tooltip>
              </div>
            )}

            {isAccessProvided(
              "asset_view",
              "graph_view",
              "graph_view_button",
              "read",
              accessDataByRole
            ) && (
              <div
                id="resultGraphviewButton"
                className={
                  is_list_view ? "listview-btn" : "listview-btn list-active"
                }
                onClick={() => setIsListView(false)}
              >
                <Tooltip title="Graph View">
                  <img
                    src={GraphIcon}
                    alt=""
                    onClick={() => {
                      navigate(`/result/graphview/${searchText}`);
                    }}
                    style={{ width: "25px", height: "20px" }}
                  />
                </Tooltip>
              </div>
            )}
          </div>
        )}
      </div>
      {/*  the props which is used to disable graphView toggle btn in case of no data */}
      <AssetsListviewTable
        setIsGraphViewToggle={setIsGraphViewToggle}
        page="listview"
      />
    </div>
  );
};

export default AssetsListview;
