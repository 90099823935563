import React, { useEffect, useState } from "react";
import { Typography } from "@mui/material";
import { useAtom } from "jotai";
import { toast } from "react-toastify";
import ComplianceAnnotationCrad from "../ComplianceAnnotationCrad/ComplianceAnnotationCrad";
// import AddAnnotationIcon from "../../../assets/images/annoation/AddAnnotation.svg";
import AddAnnotationIcon from "../../../assets/images/detail/AddIcon.svg";
import "./ComplianceAnnotation.css";
import {
  accessDataByRoleAtom,
  appIdForOpeningSummarViewAtom,
  authUser,
  organizationIDAtom,
  regionIDAtom,
  selectedBusinessUnit,
} from "../../../atom/jotai";
import {
  annotationTypeName,
  complianceTagValue,
} from "../../../constants/complianceConstants";
import {
  useGetCPOAnnotations,
  useGetSlackUsers,
} from "../../../api/readApiFunctions";
import { SolarPower } from "@mui/icons-material";
import { useLocation } from "react-router-dom";
import {
  useCreateCPOAnnotation,
  useCreateSlackAnnotation,
} from "../../../api/createApiFunctions";
import { analyticServices } from "../../../services/analyticServices";
import { Mention, MentionsInput } from "react-mentions";
import defaultStyle from "../../../assets/css/mentionsStyle";
import { handleAnnotationsRender } from "../../../services/detailsServices";
import { isAccessProvided } from "../../../services/assetManagementService";

const ComplianceAnnotation = () => {
  const location = useLocation();
  const [user] = useAtom(authUser);
  const [regionID, setRegionID] = useAtom(regionIDAtom);
  const [selectedBuId, setSelectedBusiness] = useAtom(selectedBusinessUnit);
  const [appIdForOpeningSummarView, setAppIdForOpeningSummarView] = useAtom(
    appIdForOpeningSummarViewAtom
  );
  const [organizationID] = useAtom(organizationIDAtom);
  const [accessDataByRole, setAccessDataByRole] = useAtom(accessDataByRoleAtom);

  const [mention, setMention] = useState("");
  const [annotationData, setAnnotationData] = useState(null);
  const { data: slackUsers } = useGetSlackUsers("profile");

  const getTypeName = () => {
    let typeName;

    regionID === complianceTagValue.AllRegions &&
    regionID !== complianceTagValue.DefaultValue
      ? (typeName = annotationTypeName.Organization)
      : selectedBuId[0] === complianceTagValue.AllBusinessUnis &&
        selectedBuId.length > 0 &&
        selectedBuId[0] !== undefined
      ? (typeName = annotationTypeName.Region)
      : appIdForOpeningSummarView === complianceTagValue.AllApplications &&
        appIdForOpeningSummarView !== complianceTagValue.DefaultValue
      ? (typeName = annotationTypeName.BusinessUnit)
      : (typeName = annotationTypeName.App);

    return typeName;
  };

  const getTypeId = () => {
    let typeID;

    regionID === complianceTagValue.AllRegions &&
    regionID !== complianceTagValue.DefaultValue
      ? (typeID = organizationID)
      : selectedBuId[0] === complianceTagValue.AllBusinessUnis &&
        selectedBuId.length > 0 &&
        selectedBuId[0] !== undefined
      ? (typeID = regionID)
      : appIdForOpeningSummarView === complianceTagValue.AllApplications &&
        appIdForOpeningSummarView !== complianceTagValue.DefaultValue
      ? (typeID = selectedBuId[0])
      : (typeID = appIdForOpeningSummarView);

    return typeID;
  };

  //react query to get annotations
  const {
    data,
    isSuccess,
    error,
    isLoading,
    refetch: getAnnotaionRefetch,
  } = useGetCPOAnnotations(
    getTypeName(),
    getTypeId(),
    location.pathname.split("/")[2] == "compliance-listview" ||
      location.pathname.split("/")[2] == "compliance-summary"
      ? true
      : false,
    "profile"
  );

  // react query to add annotations
  const { mutate: addCPOAnnotation } = useCreateCPOAnnotation();
  const { mutate: createSlackAnnotation } = useCreateSlackAnnotation();

  useEffect(() => {
    if (isSuccess) {
      setAnnotationData(data);
    }
  }, [data]);

  // loader
  if (isLoading) {
    return <h2>loading</h2>;
  }

  // handle error [pending]
  if (error) {
    console.log("Annotations ERROR");
  }

  const handleAddAnnotations = (userId) => {
    if (mention.trim() !== "") {
      const annotationToString = handleAnnotationsRender(mention, slackUsers);

      const payload = {
        name: mention.trim(),
        contributor_id: userId,
        type: getTypeName(),
        type_id: getTypeId(),
        text_name: annotationToString,
        subModInvoker: "profile",
      };
      analyticServices(` Cpo Annotation created :- ${mention} `);
      addCPOAnnotation(payload);
      const slackPayload = {
        annotationMessage: `
      ${mention}

      Type : ${getTypeName()}`,

        url: window.location.href,
        subModInvoker: "profile",
      };
      createSlackAnnotation(slackPayload);

      setMention("");
      toast.success("Annotation added");
    }
  };

  const handleMentionChange = (e) => {
    setMention(e.target.value);
  };

  const handleDisplayTransform = (id) => {
    const user = slackUsers.find((user) => user.id === id);
    return user.display;
  };

  return (
    <div className="complianceAnnotationsParentDiv">
      <Typography
        variant="h5"
        component="div"
        className="complianceAnnotationsParentHeading"
      >
        Annotation
      </Typography>

      {annotationData && annotationData?.annotation?.length > 0 ? (
        <div className="complianceAnnotationData">
          {annotationData?.annotation?.map((data, index) => {
            return (
              <ComplianceAnnotationCrad
                data={data}
                annotaionIndex={index}
                key={data.annotation_id}
                slackUsers={slackUsers}
                getTypeName={getTypeName}
                getAnnotaionRefetch={getAnnotaionRefetch}
              />
            );
          })}
        </div>
      ) : (
        <p className="cpo_noAnnotations">No Annotations to show</p>
      )}
      {isAccessProvided(
        "annotation",
        "profile",
        null,
        "write",
        accessDataByRole
      ) && (
        <div className="complianceAddAnnotationDiv">
          <MentionsInput
            id="addAnnotationInput"
            style={defaultStyle}
            className="annotation_input"
            value={mention}
            onChange={handleMentionChange}
            // className="AddAnnotations"
            singleLine={true}
            placeholder="Mention people using '@'"
            forceSuggestionsAboveCursor={true}
            onKeyPress={(ev) => {
              if (ev.key === "Enter") {
                handleAddAnnotations(user.contributor_id);
              }
            }}
          >
            <Mention
              trigger="@"
              data={slackUsers}
              markup="<@__id__>"
              displayTransform={handleDisplayTransform}
              appendSpaceOnAdd={true}
            />
          </MentionsInput>
          <button
            onClick={(e) => {
              handleAddAnnotations(user.contributor_id);
            }}
            id="AddCPOAnnotationsButton"
            className="AddCPOAnnotationsButton"
            disabled={mention == ""}
          >
            <img src={AddAnnotationIcon} alt="AddAnnotation" />
          </button>
        </div>
      )}
    </div>
  );
};

export default ComplianceAnnotation;
