import React from "react";
import { cardConstant, datasetCardFields } from "../../constants/cardsConstant";
import { detailsFieldsNames } from "../../constants/detailConstants";
import { dateTimeFormat, jsonParse } from "../../utils/basicUtils";
import Charts from "../Subsection/Charts/Charts";
import "./GenerateReport.css";
import Policy from "../../assets/images/adminPanel/Policy.svg";
import { riskFactorValueCond } from "../../constants/riskFactorMonth";
import ReportAnswerIcon from "../../assets/images/report/ReportAnswerIcon.svg";
import { PolicyNameConst } from "../../constants/policyConstants";

/**
 * Component : GenerateReport
 *
 * Description : This component is responsible for creating report format of cards.
 * component is creating data in the way which is required by jsPDF library. We are using renderToString() , which is converting the
 * react componet to basic html. SO, this component is passed as a argument to renderToString() method
 *
 * Props : Card sections data, scores data of sections
           listCardData - informative data for which app/model/dataset card is openened,
 */
const GenerateReport = (props) => {
  const displayDetails = (cardDetailKey) => {
    var value = "";

    if (cardDetailKey === "created_at") {
      value = dateTimeFormat(props.listCardData[cardDetailKey]);
    } else if (cardDetailKey === "contributor") {
      props.listCardData.hasOwnProperty("contributor") &&
        props.listCardData[cardDetailKey]?.map((val, index) => {
          if (index === 0) {
            value = value + val?.contributor?.name;
          } else {
            value = value + ", " + val?.contributor?.name;
          }
        });
    } else if (cardDetailKey === datasetCardFields[0]) {
      value = props.data?.title;
    } else if (props.listCardData[cardDetailKey]?.length > 0) {
      value = props.listCardData[cardDetailKey];
    } else if (typeof props.listCardData[cardDetailKey] == "number") {
      value = props.listCardData[cardDetailKey];
    } else {
      value = "-";
    }

    if (value === null || value === "N/A" || value === "") {
      value = "-";
    }
    return value;
  };

  const displayScores = (value) => {
    if (
      value === null ||
      value === riskFactorValueCond.ntAplicabl ||
      value === undefined
    ) {
      return "-";
    } else {
      return value;
    }
  };

  const showAnswer = (answerData) => {
    let textData = eval("(" + answerData + ")");

    return (
      <ul>
        {textData.hasOwnProperty("boolean") && textData.boolean !== "" && (
          <li style={{ textTransform: "capitalize" }}>{textData.boolean}</li>
        )}
        {textData.hasOwnProperty("text") && textData.text !== "" && (
          <li>{textData.text}</li>
        )}
        {textData.hasOwnProperty("file") && textData.file.length > 0 && (
          <li>
            {" "}
            File :
            <ol>
              {textData.file.map((fileData) => {
                return (
                  <li style={{ marginLeft: "-15px" }}>{fileData.file_name}</li>
                );
              })}
            </ol>
          </li>
        )}
      </ul>
    );
  };

  return (
    <div className="report">
      <div className="report_height report_front">
        <h1>
          {!props.data.display_name || props.data.display_name !== ""
            ? props.data.name
            : props.data.display_name}
        </h1>
        <p>Version: {props.listCardData.version}</p>
        <p>Card Version: {props.data.template_version}</p>

        <p>
          {` ${PolicyNameConst.konfer_confidence_wo_tm} : 
          ${displayScores(props.riskFactorData.Scores["Konfer Confidence"])}`}
        </p>
        {/* /**For CME */}
        {/* <p>
          {` ${PolicyNameConst.human_impact} : 
          ${displayScores(props.riskFactorData.Scores["Human Impact"])}`}
        </p> */}

        {props.cardFilterWithPolicy.length > 0 ? (
          <div>
            {props.cardFilterWithPolicy.map((policy) => {
              return (
                <p>
                  {policy} :{" "}
                  {displayScores(props.riskFactorData.Scores[policy])}{" "}
                </p>
              );
            })}
          </div>
        ) : (
          <div>
            {Object.entries(props.riskFactorData.Scores).map(
              ([key, val]) =>
                key !== "risk_factor_name" &&
                key !== "Konfer Confidence" &&
                key !== "Human Impact" &&
                Number(val) !== riskFactorValueCond.ntAplicabl &&
                val !== riskFactorValueCond.nullCondition && (
                  <p key={key}>
                    {key}: {val}
                  </p>
                )
            )}
          </div>
        )}
      </div>

      <div className="report_height ">
        <h5 className="report_title">Details</h5>
        <table style={{ border: "1px solid #DEDEDE" }}>
          {cardConstant[props.cardType]?.map((cardDetailKey, index) => {
            if (cardDetailKey !== "models_hyperparameters_tables") {
              return (
                <tr key={index} id={`report_details:${index}`}>
                  <td className="report_td">
                    {" "}
                    {detailsFieldsNames[cardDetailKey]}
                  </td>
                  <td className="report_td">{displayDetails(cardDetailKey)}</td>
                </tr>
              );
            }
          })}
        </table>

        {/* show hyper parameters */}
        <p className="report_subtitle">Hyper Parameters</p>
        <table style={{ border: "1px solid #DEDEDE" }}>
          {props?.listCardData &&
            props?.listCardData.__typename === "model" &&
            props?.listCardData?.models_hyperparameters_tables?.map(
              (hyperParams, index) => {
                return (
                  <tr key={index} id={`report_details:${index}`}>
                    <td className="report_td">
                      {" "}
                      {hyperParams?.hyper_parameter?.name}
                    </td>
                    <td className="report_td">
                      {hyperParams?.hyper_parameter?.value}
                    </td>
                  </tr>
                );
              }
            )}
        </table>
      </div>
      {props.data.sections.map((sectionData, index) => {
        return sectionData.title !== "Details" ? (
          <div key={index} className="report_section">
            <h5 className="report_title" style={{ display: "flex" }}>
              {sectionData?.title}
            </h5>

            {props.riskFactorData !== undefined &&
              props.riskFactorData !== null &&
              props.riskFactorData?.riskfactor !== undefined &&
              props.riskFactorData?.riskfactor !== null &&
              props.riskFactorData?.riskfactor?.length > 0 &&
              props.riskFactorData?.riskfactor?.map((riskData) => {
                return (
                  riskData.risk_factor_name === sectionData?.title && (
                    <>
                      {/* /** For cme */}
                      {/* <p className="report_subtitle_score">
                        {" "}
                        {`${PolicyNameConst.konfer_confidence_wo_tm} : 
                        ${displayScores(
                          riskData["Konfer Confidence"]
                        )} and Human
                        Impact: 
                        ${displayScores(riskData["Human Impact"])}`}
                      </p> */}

                      <p className="report_subtitle_score">
                        {" "}
                        {`${PolicyNameConst.konfer_confidence_wo_tm} : 
                        ${displayScores(riskData["Konfer Confidence"])}`}
                      </p>

                      {props.cardFilterWithPolicy.length > 0 ? (
                        <div>
                          {props.cardFilterWithPolicy.map((policy) => {
                            return (
                              <p className="report_subtitle_desc">
                                {policy} : {displayScores(riskData[policy])}{" "}
                              </p>
                            );
                          })}
                        </div>
                      ) : (
                        <div>
                          {Object.entries(riskData).map(
                            ([key, val]) =>
                              key !== "risk_factor_name" &&
                              key !== "Konfer Confidence" &&
                              key !== "Human Impact" &&
                              Number(val) !== riskFactorValueCond.ntAplicabl &&
                              val !== riskFactorValueCond.nullCondition && (
                                <p className="report_subtitle_desc" key={key}>
                                  {key}: {val}
                                </p>
                              )
                          )}
                        </div>
                      )}
                    </>
                  )
                );
              })}

            {sectionData?.description?.length > 0 &&
              sectionData.description !== "" &&
              sectionData.description !== "null" &&
              sectionData.description !== null && (
                <p className="report_subtitle_desc">
                  {sectionData?.description}
                </p>
              )}

            {sectionData?.subsections?.length > 0 &&
              sectionData?.subsections?.map((subSectionData, i) => {
                return (
                  <div key={i}>
                    <p className="report_subtitle">{subSectionData?.title}</p>
                    {subSectionData?.description?.length > 0 &&
                    subSectionData.description !== "" &&
                    subSectionData.description !== "null" &&
                    subSectionData.description !== null ? (
                      <p className="report_subtitle_desc">
                        {subSectionData?.description}
                      </p>
                    ) : (
                      subSectionData.title !== "Questionnaire" &&
                      subSectionData?.text_data?.length === 0 && (
                        <p className="report_subtitle_desc">No Information</p>
                      )
                    )}

                    {subSectionData?.text_data?.length > 0 && (
                      <p className="report_subtitle_desc">
                        {subSectionData?.text_data[0]?.text_data}
                      </p>
                    )}
                    {/* <p> */}
                    {subSectionData?.questions?.length > 0 && (
                      <table style={{ border: "1px solid #DEDEDE" }}>
                        <thead>
                          <tr>
                            <th
                              className="report_td"
                              style={{ width: "50%", textAlign: "center" }}
                            >
                              Question
                            </th>
                            <th
                              className="report_td"
                              style={{ width: "50%", textAlign: "center" }}
                            >
                              Answer
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {subSectionData?.questions?.map(
                            (questionData, index) => {
                              if (
                                props.cardFilterWithPolicy.length > 0
                                  ? questionData.policy_tag !== null &&
                                    questionData.policy_tag !== "" &&
                                    questionData.policy_tag
                                      .split(",")
                                      .some((val) =>
                                        props.cardFilterWithPolicy.includes(val)
                                      )
                                  : true
                              ) {
                                return (
                                  <>
                                    {questionData &&
                                      questionData.is_visible &&
                                      questionData.is_visible === true && (
                                        <tr
                                          id={`reportGen${index}`}
                                          key={index}
                                        >
                                          <td
                                            className="report_td"
                                            style={{
                                              width: "50%",
                                              textAlign: "start",
                                            }}
                                          >
                                            <span>
                                              {questionData.is_required ? (
                                                <span style={{ color: "red" }}>
                                                  *
                                                </span>
                                              ) : (
                                                ""
                                              )}
                                              <span>
                                                {questionData.question}
                                              </span>
                                              <span
                                                style={{
                                                  // display: "block",
                                                  color: "#555555",
                                                }}
                                              >
                                                {questionData.policy_tag !==
                                                  null &&
                                                  questionData.policy_tag !==
                                                    "" &&
                                                  questionData.policy_tag !==
                                                    "NULL" &&
                                                  `${PolicyNameConst.policies} : ${questionData.policy_tag}`}
                                              </span>
                                            </span>
                                          </td>
                                          <td
                                            className="report_td"
                                            style={{ width: "50%" }}
                                            id={`question${index}`}
                                          >
                                            {questionData.answer_field ===
                                              null ||
                                            questionData.answer_field === ""
                                              ? "-"
                                              : showAnswer(
                                                  questionData.answer_field
                                                )}
                                          </td>
                                        </tr>
                                      )}
                                  </>
                                );
                              }
                            }
                          )}
                        </tbody>
                      </table>
                    )}
                    {/* </p> */}
                  </div>
                );
              })}
          </div>
        ) : (
          <div key={index} className="report_section">
            {sectionData?.subsections?.length > 0 &&
              sectionData?.subsections?.map((subSectionData, i) => {
                return (
                  <div key={i}>
                    {subSectionData?.title !== "Questionnaire" && (
                      <p className="report_subtitle">{subSectionData?.title}</p>
                    )}
                    {subSectionData?.title !== "Questionnaire" &&
                      subSectionData?.description?.length > 0 &&
                      subSectionData.description !== "" &&
                      subSectionData.description !== "null" &&
                      subSectionData.description !== null && (
                        <p className="report_subtitle_desc">
                          {subSectionData?.description}
                        </p>
                      )}

                    {subSectionData?.title === "Document" &&
                      subSectionData?.text_data[0]?.text_data &&
                      JSON.parse(subSectionData?.text_data[0]?.text_data).map(
                        (file) => (
                          <p className="report_subtitle_desc">
                            {file.file_name}
                          </p>
                        )
                      )}

                    {/* {subSectionData?.title === "Questionnaire" && (
                      <p className="report_subtitle_desc">No Information</p>
                    )} */}

                    {subSectionData?.title !== "Questionnaire" && (
                      <p className="report_subtitle_desc">
                        {subSectionData?.text_data[0]?.text_data
                          ? subSectionData?.text_data[0]?.text_data
                          : "No Information"}
                      </p>
                    )}
                  </div>
                );
              })}
          </div>
        );
      })}
    </div>
  );
};

export default GenerateReport;
