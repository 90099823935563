import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import React from "react";
import { complianceTagName } from "../../constants/complianceConstants";

const MuiSelect = ({ data, onChange, defaultValue }) => {
  const handleChange = (e) => {
    onChange(e.target.value);
  };
  return (
    <div>
      <FormControl sx={{ m: 1 }} id="complianceListviewSelect">
        <Select
          sx={{
            "& .MuiSelect-select": {
              padding: "5px",
              fontSize: "22px",
              fontWeight: "600",
              marginTop: "13px",
              background: "none",
              "@media screen and (max-height: 780px)": {
                fontSize: "18px",
                marginTop: "5px",
              },
            },
            "&::before": {
              borderBottom: "none",
            },
            "&::after": {
              background: "none",
            },
          }}
          variant="standard"
          id="complianceSelect"
          value={defaultValue}
          disableUnderline={true}
          onChange={(e) => {
            handleChange(e);
          }}
          autoWidth
          // inputProps={{ disableUnderline: true }}
          MenuProps={{
            PaperProps: {
              sx: {
                border: "0.5px solid #6D8291",
                boxShadow: "0px 5px 10px rgba(0, 0, 0, 0.25)",
                borderRadius: "4px",
                marginTop: "-5px",

                "& .MuiMenuItem-root": {
                  // padding: 1,
                  fontFamily: "var( --fontFamilyText) !important",
                  lineHeight: "1rem",
                  "@media screen and (max-height: 780px)": {
                    fontSize: "14px",
                  },
                },
              },
            },
          }}
        >
          {data?.map((item, index) => {
            return (
              item.name !== "Admin" && (
                <MenuItem key={index} value={item.id} id="selectMenuItem">
                  {item.name === complianceTagName.AllBusinessUnitsTag
                    ? "All Business Units"
                    : item.name}
                </MenuItem>
              )
            );
          })}
        </Select>
      </FormControl>
    </div>
  );
};

export default MuiSelect;
