import { NodeFillColors, ScoreColors, ScoreValue } from "../constants/amcharts";
import { ColorIndexNames } from "../constants/dataTypes";
import * as am5 from "@amcharts/amcharts5";

import AppLogo from "../assets/images/detail/AppLogo.svg";
import DataSetLogo from "../assets/images/detail/DataSetLogo.svg";
import ModelLogo from "../assets/images/detail/ModelLogo.svg";

export const colorStrike = (nodeElement) => {
  if (nodeElement.minScoreColor !== ScoreColors.greenColor) {
    //set outer ring color.

    nodeElement.minScoreValue <= ScoreValue.lowest_limit_red_score
      ? (nodeElement.nodeSettings1 = {
          strokeOpacity: 8,
          stroke: am5.color(nodeElement.minScoreColor),
        })
      : (nodeElement.nodeSettings1 = {
          strokeOpacity: 8,
          stroke: am5.color(NodeFillColors.paleCyan),
        });

    // set node fill color on the basis of node scores.
    nodeElement.nodeMinScoreColor &&
      nodeElement.nodeMinScoreColor !== ScoreColors.greenColor &&
      (nodeElement.nodeSettings = {
        fill: am5.color(nodeElement.nodeMinScoreColor),
      });

    // //set node fill color if the node having max score value.
    // nodeElement?.bgID &&
    //   nodeElement?.bgTypeName &&
    //   (nodeElement.nodeSettings = {
    //     fill: am5.color(nodeElement.minScoreColor),
    //   });
  }
};

//node textLength value size
export const nodeText = (nodeName, nodeNameLength) => {
  // nodeName.text = nodeName.name;
  if (nodeName.name && nodeName.name.length > 20) {
    nodeName.names = nodeName?.display_name?.substring(0, 20) + "...";
    // nodeName.names = nodeName.display_name.slice(0, 15) + "..";
    nodeName.text = nodeName.names;
    // nodeName.names =
    //   nodeName.name.slice(0, 5) +
    //   "..." +
    // nodeName.name.slice(nodeName.name.length - 15);
    // nodeName.names = nodeName.name;
  } else {
    nodeName.names = nodeName.display_name;
    nodeName.text = nodeName.names;
  }

  //Commented above part for showing full name on label
  // nodeName.text = nodeName.name;
  // nodeName.names = nodeName.name;
};

//node color function
export const colorSet = (nodeElement) => {
  const colorTest = am5.color(NodeFillColors.blueCrayola);

  if (nodeElement.__typename === ColorIndexNames.inputDataTable) {
    nodeElement.nodeSettings1 = {
      strokeOpacity: 8,
      stroke: am5.color(NodeFillColors.paleCyan),
    };
    nodeElement.nodeSettings = {
      fill: am5.color(NodeFillColors.paleCyan),
    };
  }
  if (nodeElement.__typename === ColorIndexNames.app) {
    nodeElement.nodeSettings1 = {
      stroke: am5.color(colorTest),
      strokeOpacity: 8,
    };

    nodeElement.nodeSettings = {
      fill: colorTest,
    };
  }
  if (nodeElement.__typename === ColorIndexNames.model) {
    nodeElement.nodeSettings1 = {
      stroke: am5.color(NodeFillColors.blueBolt),
      strokeOpacity: 8,
    };

    nodeElement.nodeSettings = {
      fill: am5.color(NodeFillColors.blueBolt),
    };
  }
  if (nodeElement.__typename === ColorIndexNames.observability) {
    nodeElement.nodeSettings1 = {
      strokeOpacity: 8,
      stroke: am5.color(NodeFillColors.aliceBlue),
    };

    nodeElement.nodeSettings = {
      fill: am5.color(NodeFillColors.aliceBlue),
    };
  }
  if (nodeElement.__typename === ColorIndexNames.inputDataColumn) {
    nodeElement.nodeSettings1 = {
      strokeOpacity: 8,
      stroke: am5.color(NodeFillColors.littleBoyBlue),
    };

    nodeElement.nodeSettings = {
      fill: am5.color(NodeFillColors.littleBoyBlue),
    };
  }
  if (nodeElement.__typename === ColorIndexNames.contributor) {
    nodeElement.nodeSettings1 = {
      strokeOpacity: 8,
      stroke: am5.color(NodeFillColors.brilliantAzure),
    };
    nodeElement.nodeSettings = {
      fill: am5.color(NodeFillColors.brilliantAzure),
    };
  }
};

/* Set Image for Nodes */
export const setImageForNodes = (typeOfNode) => {
  switch (typeOfNode) {
    case "app":
      return AppLogo;
    case "model":
      return ModelLogo;
    case "input_data_table":
      return DataSetLogo;
    case "input_data_column":
      return DataSetLogo;
  }
};

export const getUniqueAmchartId = () => {
  const id = Math.floor(Math.random() * 800000);
  return id;
};

export const colorSetForOriginalColor = (node, graphView) => {
  if (node.dataItem.dataContext.__typename === ColorIndexNames.app) {
    node.dataItem
      .get("circle")
      .set("fill", am5.color(NodeFillColors.blueCrayola));
    node.dataItem
      .get("outerCircle")
      .set("stroke", am5.color(NodeFillColors.blueCrayola));
  } else if (node.dataItem.dataContext.__typename === ColorIndexNames.model) {
    node.dataItem.get("circle").set("fill", am5.color(NodeFillColors.blueBolt));
    node.dataItem
      .get("outerCircle")
      .set("stroke", am5.color(NodeFillColors.blueBolt));
  } else if (
    node.dataItem.dataContext.__typename === ColorIndexNames.inputDataTable
  ) {
    node.dataItem.get("circle").set("fill", am5.color(NodeFillColors.paleCyan));
    node.dataItem
      .get("outerCircle")
      .set(
        "stroke",
        am5.color(
          graphView ? NodeFillColors.whiteColor : NodeFillColors.paleCyan
        )
      );
  } else if (
    node.dataItem.dataContext.__typename === ColorIndexNames.contributor
  ) {
    node.dataItem
      .get("circle")
      .set("fill", am5.color(NodeFillColors.brilliantAzure));
    node.dataItem
      .get("outerCircle")
      .set("stroke", am5.color(NodeFillColors.brilliantAzure));
  } else if (
    node.dataItem.dataContext.__typename === ColorIndexNames.inputDataColumn
  ) {
    node.dataItem
      .get("circle")
      .set("fill", am5.color(NodeFillColors.littleBoyBlue));
    node.dataItem
      .get("outerCircle")
      .set("stroke", am5.color(NodeFillColors.littleBoyBlue));
  }
};
