import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableFooter,
  TablePagination,
  Tooltip,
  tooltipClasses,
} from "@mui/material";
import {
  IconButton,
  Box,
  useTheme,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import {
  FirstPage,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  LastPage,
} from "@mui/icons-material";
import request from "graphql-request";
import { useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";

import "../../AdminUsetlistUserTable/AdminUsetlistUserTable.css";
import Edit from "../../../assets/images/adminPanel/Edit.svg";
import Delete from "../../../assets/images/adminPanel/Delete.svg";
import CancelIcon from "../../../assets/images/adminPanel/CancelIcon.svg";
import { queryKeyConstants } from "../../../constants/basicConstants";
import UpdateNewBusinessunit from "../UpdateNewBusinessunit/UpdateNewBusinessunit";
import { analyticServices } from "../../../services/analyticServices";
import nodeApiInstance from "../../../api/instance/nodeApiInstance";
import { adminBusinessUnitPageError } from "../../../constants/toasterMsgConstants";
import { isAccessProvided } from "../../../services/assetManagementService";
import {
  moduleConstants,
  subModuleConstants,
} from "../../../constants/accessManagementConstants";

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#FFFFFF",
    color: "#666666",
    border: " 0.5px solid #6D8291",
    boxShadow: "0px 5px 10px rgba(0, 0, 0, 0.25)",
    borderRadius: "4px",
    fontSize: 16,
    fontFamily: "Lato",
    fontWeight: 400,
  },
}));

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        id="buFirst"
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPage /> : <FirstPage />}
      </IconButton>
      <IconButton
        id="buBack"
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        id="buNext"
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        id="buLast"
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPage /> : <LastPage />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const BusinessunitTable = ({ businessUnitsData, accessDataByRole }) => {
  let a = window.innerHeight <= 760 ? 6 : window.innerHeight <= 900 ? 8 : 10;
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(a);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false); // to show a confirmation dialog to delete a businessunit
  const [openUpdateBusinessunitDialog, setOpenUpdateBusinessunitDialog] =
    useState(false); // update Businessunit dialog
  const [deleteBusinessunitID, setDeleteBusinessunitID] = useState(null);
  const [dataForEditbusinessunit, setDataForEditbusinessunit] = useState(null);
  const queryClient = useQueryClient();
  const [isBUAlreadyAssigned, setIsBuAlreadyAssigned] = useState(false);

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0
      ? Math.max(0, (1 + page) * rowsPerPage - businessUnitsData?.length)
      : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleDeleteConfirmationDialog = (ID) => {
    setDeleteBusinessunitID(ID);
    setOpenDeleteDialog(true);
  };

  const handleEditBusinessunit = (data) => {
    setOpenUpdateBusinessunitDialog(true);
    setDataForEditbusinessunit(data);
  };

  //details/delete-bu
  //API call for deleting business unit in the database
  const deleteBusinessunitMutation = useMutation(
    async (payload) => {
      const updatePaylod = {
        id: payload,
      };
      return await nodeApiInstance.post("details/delete-bu", updatePaylod);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(queryKeyConstants.getAllBusinessUnits);
        toast.success("Business Unit deleted");
        setOpenDeleteDialog(false);
        setIsBuAlreadyAssigned(false);
        businessUnitsData.map((data) => {
          deleteBusinessunitID === data.business_unit_id &&
            analyticServices(` ${data.name}, business unit is deleted`);
        });
      },
      onError: (error) => {
        if (
          error.response.data.message.includes(
            adminBusinessUnitPageError.buAlreadyAssigned
          )
        ) {
          setIsBuAlreadyAssigned(true); //To open the alraedy alloted popup
        } else {
          toast.error(`${error.message.slice(0, 23)}`);
          setOpenDeleteDialog(false);
          setIsBuAlreadyAssigned(false);
        }
      },
    }
  );

  const handleDeleteBusinessunit = () => {
    deleteBusinessunitMutation.mutate(deleteBusinessunitID);
  };

  return (
    <div>
      <Table
        aria-label="simple table"
        sx={{ tableLayout: "fixed" }}
        id="buTable"
      >
        <TableHead>
          <TableRow className="usertable-row-headings">
            <TableCell className="usertable-coloumn-headings">
              Business Name
            </TableCell>
            <TableCell className="usertable-coloumn-headings">Region</TableCell>
            <TableCell
              className="usertable-coloumn-headings"
              sx={{ width: "50%" }}
            >
              Description
            </TableCell>

            {(isAccessProvided(
              moduleConstants.admin_view,
              subModuleConstants.business_units,
              null,
              "update",
              accessDataByRole
            ) === true ||
              isAccessProvided(
                moduleConstants.admin_view,
                subModuleConstants.business_units,
                null,
                "delete",
                accessDataByRole
              ) === true) && (
              <TableCell
                className="usertable-coloumn-headings"
                sx={{ width: "10%" }}
              ></TableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {businessUnitsData &&
            businessUnitsData?.length > 0 &&
            (rowsPerPage > 0
              ? businessUnitsData.slice(
                  page * rowsPerPage,
                  page * rowsPerPage + rowsPerPage
                )
              : businessUnitsData
            ).map((data, index) => (
              <>
                <TableRow
                  id={`buTableRow${index}`}
                  key={data.business_unit_id}
                  sx={{
                    "&:hover": {
                      cursor: "pointer",
                      backgroundColor: "var(--bacgroundColor) !important",
                    },
                  }}
                >
                  <LightTooltip title={data?.name} followCursor>
                    <TableCell className="usertable-coloumn text-ellipsis">
                      {data.name}
                    </TableCell>
                  </LightTooltip>
                  <TableCell className="usertable-coloumn">
                    {data.region.name}
                  </TableCell>
                  <LightTooltip
                    title={
                      data.description.length === 0 ? "-" : data.description
                    }
                    followCursor
                  >
                    <TableCell className="usertable-coloumn text-ellipsis business_unit">
                      {data.description.length === 0 ? "-" : data.description}
                    </TableCell>
                  </LightTooltip>

                  {(isAccessProvided(
                    moduleConstants.admin_view,
                    subModuleConstants.business_units,
                    null,
                    "update",
                    accessDataByRole
                  ) === true ||
                    isAccessProvided(
                      moduleConstants.admin_view,
                      subModuleConstants.business_units,
                      null,
                      "delete",
                      accessDataByRole
                    ) === true) && (
                    <TableCell
                      sx={{
                        display: "flex",
                      }}
                      className="usertable-coloumn"
                    >
                      {isAccessProvided(
                        moduleConstants.admin_view,
                        subModuleConstants.business_units,
                        null,
                        "update",
                        accessDataByRole
                      ) === true && (
                        <IconButton
                          id={`buEditIcon${index}`}
                          type="submit"
                          aria-label="Edit"
                          style={{
                            padding: "5px",
                            marginLeft: "-15px",
                          }}
                          onClick={() => {
                            handleEditBusinessunit(data);
                          }}
                        >
                          <img
                            src={Edit}
                            alt="Edit"
                            style={{ width: "28px" }}
                          />
                        </IconButton>
                      )}

                      {isAccessProvided(
                        moduleConstants.admin_view,
                        subModuleConstants.business_units,
                        null,
                        "delete",
                        accessDataByRole
                      ) === true && (
                        <IconButton
                          id={`buDeleteIconIcon${index}`}
                          type="submit"
                          aria-label="Delete"
                          style={{ padding: "5px" }}
                          onClick={() => {
                            handleDeleteConfirmationDialog(
                              data.business_unit_id
                            );
                          }}
                        >
                          <img
                            src={Delete}
                            alt="Delete"
                            style={{ width: "28px" }}
                          />
                        </IconButton>
                      )}
                    </TableCell>
                  )}
                </TableRow>
              </>
            ))}
        </TableBody>
        <TableFooter>
          <TableRow
            sx={{
              "&:last-child td, &:last-child th": {
                border: 0,
                paddingRight: "50px",
              },
            }}
          >
            <TablePagination
              rowsPerPageOptions={[a]}
              colSpan={3}
              count={businessUnitsData?.length || 0}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />
          </TableRow>
        </TableFooter>
      </Table>

      <Dialog
        id="buDeleteDialog"
        open={openDeleteDialog}
        onClose={() => {
          setOpenDeleteDialog(!openDeleteDialog);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <IconButton
            id="buCloseDeleteDialog"
            aria-label="close"
            onClick={() => {
              setOpenDeleteDialog(false);
              setIsBuAlreadyAssigned(false);
            }}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <img src={CancelIcon} alt="Delete" />
          </IconButton>
          {isBUAlreadyAssigned ? (
            <p className="bu-already-allotes-msg">
              {
                "This Business Unit is associated with one or more Objects. Delete all associated Objects before deleting this Business Unit."
              }
            </p>
          ) : (
            <p> {"Are you sure you want to delete this user?"} </p>
          )}
        </DialogTitle>
        <DialogActions
          sx={
            isBUAlreadyAssigned && {
              "&.MuiDialogActions-root": { justifyContent: "center" },
            }
          }
        >
          {isBUAlreadyAssigned ? (
            <Button
              id="errorMessageForAlreadyAlloted"
              className="okay-button"
              value="Delete"
              onClick={() => {
                setOpenDeleteDialog(false);
                setIsBuAlreadyAssigned(false);
              }}
            >
              <span>Okay</span>
            </Button>
          ) : (
            <>
              <Button
                id="buCancelDeleteDialog"
                className="cancel-deleteUser-dialog"
                value="Cancel"
                onClick={() => {
                  setOpenDeleteDialog(false);
                  setIsBuAlreadyAssigned(false);
                }}
                autoFocus
              >
                <span className="deleteUser-dialog-title">Cancel</span>
              </Button>
              <Button
                id="buConfrimDelete"
                className="delete-deleteUser-dialog"
                value="Delete"
                onClick={() => {
                  handleDeleteBusinessunit();
                }}
              >
                <span className="deleteUser-dialog-title">Delete</span>
              </Button>
            </>
          )}
        </DialogActions>
      </Dialog>

      {openUpdateBusinessunitDialog && (
        <UpdateNewBusinessunit
          openUpdateBusinessunitDialog={openUpdateBusinessunitDialog}
          setOpenUpdateBusinessunitDialog={setOpenUpdateBusinessunitDialog}
          dataForEditbusinessunit={dataForEditbusinessunit}
        />
      )}
    </div>
  );
};

export default BusinessunitTable;
