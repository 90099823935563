import { Box, Modal } from "@mui/material";
import { useAtom } from "jotai";
import { useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";

import { authUser, isSwitchToAdminPanelAtom } from "../../atom/jotai";
import "./NoBusinessUnitsAssignedModal.css";
import WarningYellowIcon from "../../assets/images/home/WarningYellow.svg";
import { logoutUser } from "../../utils/authUtils";
import { analyticServices } from "../../services/analyticServices";
import {
  accessConstants,
  moduleConstants,
  subModuleConstants,
} from "../../constants/accessManagementConstants";
import { isAccessProvided } from "../../services/assetManagementService";
import { useGetBusinessUnitsNew } from "../../api/readApiFunctions";
import { switchToAdminLocation } from "../../services/adminServices";

/**
 * Component : NoBusinessUnitsAssignedModal
 *
 * Description : This component is responsible for displaying a modal of no business unit is
 * attached to logged in user. 
 * 
   Props : accessDataByRole - access data object for logged in user
           moduleInvoker - On which page the mopdal is called
 */

const NoBusinessUnitsAssignedModal = ({ accessDataByRole, moduleInvoker }) => {
  const [user] = useAtom(authUser);
  const [, setIsSwitchToAdminPanel] = useAtom(isSwitchToAdminPanelAtom);
  const navigate = useNavigate();
  const style = {
    position: "absolute",
    top: "160px",
    right: "-185px",
    width: "354px",
    height: "170px",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    borderRadius: "8px",
    boxShadow: "0px 6px 10px 0px rgba(0, 0, 0, 0.30)",
    p: 2,
    outline: "none",
  };

  const {
    data: business_unit_data,
    isError,
    isLoading,
  } = useGetBusinessUnitsNew(
    user?.role,
    user?.contributor_id,
    true,
    moduleInvoker
  );

  const [userHasBusinessUnit, setUserHasBusinessUnit] = useState(true);
  const [navigateToAdminLocationUrl, setNavigateToAdminLocationUrl] =
    useState("");
  const [showButton, setShowButton] = useState({
    signOutBtn: true,
    SwitchToAdminBtn: false,
  });

  useEffect(() => {
    if (business_unit_data) {
      let businessUnitsPresent = false;

      business_unit_data.data?.data?.regionData?.length > 0 &&
        business_unit_data.data?.data?.regionData?.map((regionInfo) => {
          if (
            regionInfo.businessUnitsData?.length > 0 &&
            regionInfo.businessUnitsData?.length >= 2
          ) {
            businessUnitsPresent = true;
          } else {
            businessUnitsPresent = false;
          }
        });
      setUserHasBusinessUnit(businessUnitsPresent);
    }
  }, [business_unit_data]);

  useEffect(() => {
    if (accessDataByRole) {
      let showButtonObj = {
        signOutBtn: true,
        SwitchToAdminBtn: false,
      };
      //Below logic is checking whether the logged in user has any of the assets/profile access
      if (
        isAccessProvided(
          moduleConstants.asset_view,
          subModuleConstants.crud,
          null,
          accessConstants.read,
          accessDataByRole
        ) ||
        isAccessProvided(
          moduleConstants.profile_view,
          subModuleConstants.crud,
          null,
          accessConstants.read,
          accessDataByRole
        )
      ) {
        showButtonObj.signOutBtn = true;
      }
      //Below logic is checking whether the logged in user has any of the admin side access, if yes then save the url
      let locationUrl = switchToAdminLocation(accessDataByRole);
      if (locationUrl !== "") {
        showButtonObj.SwitchToAdminBtn = true;
        setNavigateToAdminLocationUrl(locationUrl);
        setIsSwitchToAdminPanel(false);
      }
      setShowButton(showButtonObj);
    }
  }, [accessDataByRole]);

  const handleSignOut = () => {
    logoutUser();
    navigate(`/login`);
    analyticServices(`sign_out`);
  };

  const handleSwitchToAdmin = () => {
    navigate(navigateToAdminLocationUrl);
  };

  return (
    <Modal
      open={userHasBusinessUnit === false}
      //  onClose={() => {
      //    setUserHasBusinessUnit(false);
      //  }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <div className="noBusinessAssignedDiv">
          <div className="noBusinessAssignedimageDiv">
            <img src={WarningYellowIcon} width={28} />
          </div>
          <p className="noBusinessAssignedMessage">
            You are not assigned a Business Unit
          </p>
          {/* {user?.role !== "Admin" || user?.role !== "SuperAdmin" ? ( */}

          <div className="noBusinessAssignedimageDiv marginTop">
            {showButton.signOutBtn === true ? (
              <button
                className="noBusinessAssignedSignOut"
                style={{ marginRight: "15px" }}
                onClick={handleSignOut}
              >
                Sign Out
              </button>
            ) : (
              ""
            )}
            {showButton.SwitchToAdminBtn && (
              <button
                className="noBusinessAssignedSwitchToAdmin"
                onClick={handleSwitchToAdmin}
              >
                Switch to Admin
              </button>
            )}
          </div>
          {/* ) : (
            <div className="noBusinessAssignedimageDiv marginTop">
              <button
                className="noBusinessAssignedSignOut"
                onClick={handleSignOut}
              >
                Sign Out
              </button>
            </div>
          )} */}
        </div>
      </Box>
    </Modal>
  );
};

export default NoBusinessUnitsAssignedModal;
