import React from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  CircularProgress,
  FormControlLabel,
  FormGroup,
  Menu,
  MenuItem,
  Switch,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { tablelHedrsKeyVal } from "../../constants/listviewConstants";

/**
 * Component : ManageColumns
 *
 * Description : This component is responsible for displaying menu for column management. 
 * It also shows the options for column visibility

props: opening and closing the menu with it's anchor(position), column header info, and it's handle function
 */

const ManageColumns = ({
  anchor_mange_col_mnu_opt,
  setAnchorMangColMnuOpt,
  open_managing_col_menu,
  handleCOlManagement,
  visible_table_headers,
  handleColVisibility,
}) => {
  return (
    <Menu
      id="colManagement"
      anchorEl={anchor_mange_col_mnu_opt}
      open={open_managing_col_menu}
      onClose={() => {
        setAnchorMangColMnuOpt(null);
      }}
      MenuListProps={{
        "aria-labelledby": "basic-button",
      }}
      sx={{ "& .MuiAccordion-root": { boxShadow: "none" } }}
    >
      <Accordion
        onClick={(e) => {
          handleCOlManagement(e);
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography> Column Management </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <FormGroup>
            {visible_table_headers.length > 0 &&
              visible_table_headers.map((header) => {
                return (
                  header.field !== "card" &&
                  header.field !== "profile" && (
                    <FormControlLabel
                      disabled={
                        header.field === "name" ||
                        header.field === "konfer_confidence"
                          ? true
                          : false
                      }
                      sx={{ padding: "1px" }}
                      control={
                        <Switch
                          sx={{ marginLeft: "5px" }}
                          checked={header.checked}
                          onChange={(e) => {
                            handleColVisibility(e);
                          }}
                          name={header.field}
                        />
                      }
                      label={tablelHedrsKeyVal[header.field]}
                    />
                  )
                );
              })}
          </FormGroup>
        </AccordionDetails>
      </Accordion>
    </Menu>
  );
};

export default ManageColumns;
