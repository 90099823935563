export const dataTypes = {
  apps: "apps",
  models: "models",
  datasets: "datasets",
};

export const typeNames = {
  apps: "app",
  models: "model",
  datasets: "input_data_table",
};

export const ColorIndexNames = {
  app: "app",
  model: "model",
  inputDataTable: "input_data_table",
  inputDataColumn: "input_data_column",
  observability: "observability",
  contributor: "contributor",
};

export const MutationKeyName = {
  app: "app_id",
  model: "model_id",
  input_data_table: "input_data_table_id",
  input_data_column: "input_data_column_id",
  contributor: "contributor_id",
};

export const scoreByName = {
  apps: "Applications",
  models: "Models",
  datasets: "Datasets",
};

export const userRoles = {
  ADMIN: "Admin",
  SUPER_ADMIN: "SuperAdmin",
  CPO: "CPO",
  USER: "User",
  ORG_ADMIN: "Org Admin",
};

export const APIIndexNames = {
  app: "postgres.public.app",
  model: "postgres.public.model",
  input_data_table: "postgres.public.input_data_table",
  annotation: "postgres.public.annotation",
  cards: "postgres.public.cards",
  section: "postgres.public.section",
  subsection: "postgres.public.subsection",
  chartdata: "postgres.public.chart_data",
  textdata: "postgres.public.text_data",
};

export const selectedTemplate = {
  app: "app",
  model: "model",
  input_data_table: "input_data_table",
};

export const userRole = {
  ds: "ds",
  superAdmin: "superadmin",
  admin: "admin",
};

export const tableDataTypes = {
  apps: "appData",
  models: "modelData",
  dataSets: "dataSetData",
};

export const tableDataTypesName = {
  /** For the demo purpose, change the Application and Models to Bourses and Members */
  // appData: "Application",
  // modelData: "Models",
  appData: "Bourses",
  modelData: "Members",
  dataSetData: "Datasets",
};

export const tableDataTypesforId = {
  appData: "app_id",
  modelData: "model_id",
  dataSetData: "input_data_table_id",
};

export const entityNameConst = {
  applications: "Bourses",
  models: "Members",
  datasets: "Datasets",
};
