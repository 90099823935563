import React, { useState } from "react";
import { Tooltip, IconButton, Popover, Typography } from "@mui/material";
import LightbulbOutlinedIcon from "@mui/icons-material/LightbulbOutlined";
import HumanImpactIcon from "../../../assets/images/adminPanel/HumanImpact.svg";
import PolicyIcon from "../../../assets/images/adminPanel/Policy.svg";
import { openAiQueArr } from "../../../constants/cardsConstant";
import OpenAIModal from "../../Subsection/OpenAIModal";
import SectionsQuestionnaireAnswer from "./SectionsQuestionnaireAnswer";
import "./CardsSubsection.css";
import { isAccessProvided } from "../../../services/assetManagementService";
import { accessDataByRoleAtom } from "../../../atom/jotai";
import { useAtom } from "jotai";
import GenAIResGenerator from "../GenAIResGenarator";
import { PolicyNameConst } from "../../../constants/policyConstants";

/**
 * Component : SectionsQuestionnaire
 *
 * Description : This component is responsible for displaying section questionnaire questions and its answers.
 * For displaying answer it called answer data
 *
 * Props : questionData - qach question detailed infromation,
           index - index number of question,
           isEditDatasetSection- state which explain edit mode status
           updatedFiles- holds the list of files which are newly added against question in edited mode,
           fileList-  holds the list of files which are already added against question and coming from db,
           handlEditedQuestionData- handler function for edited mode,
           handleUpdateFilesWhileDeleted- handler function for deleted files,
           handleUpdateFiles-handler function for updated files,
           showQueTextValue- handler function for setting text and boolean value,
 *  */

const SectionsQuestionnaire = ({
  questionData,
  index,
  isEditDatasetSection,
  handlEditedQuestionData,
  updatedFiles,
  fileList,
  handleUpdateFilesWhileDeleted,
  handleUpdateFiles,
  showQueTextValue,
  data,
  cardResDataRefetch,
}) => {
  //state for opening Gen Ai Modal
  const [openAIModal, setOpenAIModal] = useState(false);
  //state for opening Gen Ai Modal with particular question
  const [questionTextForOpenAI, setQusetionTextForOpenAI] = useState("");
  //state to show a policy list
  const [openPolicyCardList, setOpenPolicyCardList] = useState(null);
  const open = Boolean(openPolicyCardList);
  const [accessDataByRole, setAccessDataByRole] = useAtom(accessDataByRoleAtom);

  return (
    <>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr",
          gap: "1rem",
          borderBottom: "1px solid #D0DCE2",
        }}
      >
        <div>
          <span>
            {index + 1}
            {". "}
          </span>

          {questionData.is_required && <span style={{ color: "red" }}>* </span>}

          <span>{questionData.question}</span>

          {/* /**For CME Purpose */}
          {/* {questionData.human_impact && (
            <Tooltip title="Human Impact" placement="top">
              <img src={HumanImpactIcon} alt="HI" style={{ width: "18px" }} />
            </Tooltip>
          )} */}

          {questionData?.answer_type?.includes("boolean") && (
            <Tooltip title="GenAI Response">
              <IconButton
                sx={{
                  "&.MuiIconButton-root": {
                    padding: "0px",
                    marginTop: "-10px",
                  },
                  "&.MuiSvgIcon-root": { width: "22px" },
                }}
                onClick={() => {
                  setOpenAIModal(true);
                  setQusetionTextForOpenAI(questionData.question);
                }}
              >
                <LightbulbOutlinedIcon />
              </IconButton>
            </Tooltip>
          )}

          <GenAIResGenerator
            openAIModal={openAIModal}
            setOpenAIModal={setOpenAIModal}
            type="template_question"
            data={data}
            questionTextForOpenAI={questionData.question}
            questionId={questionData.card_question_id}
            cardResDataRefetch={cardResDataRefetch}
          />

          {questionData?.policy_tag !== "NULL" &&
            questionData?.policy_tag !== "" &&
            questionData?.policy_tag?.split(",").length > 0 && (
              <span
                className="policy_Tagging_Heading"
                style={{ display: "block", marginLeft: "25px" }}
              >
                <img
                  src={PolicyIcon}
                  alt=""
                  style={{
                    marginRight: "5px",
                  }}
                />
                {PolicyNameConst.policies}:
                {questionData?.policy_tag?.split(",").map((tags, index) => {
                  return (
                    <>
                      {(() => {
                        if (index < 1) {
                          return (
                            <span className="policy_Tagging_Value">
                              {" "}
                              {`  ${tags}`}{" "}
                            </span>
                          );
                        }
                      })()}
                      {(() => {
                        if (
                          index + 1 ===
                            questionData?.policy_tag?.split(",").length &&
                          questionData?.policy_tag?.split(",").length > 1
                        ) {
                          return (
                            <span
                              className="policy_Tagging_Hidden_Value"
                              onClick={(e) => {
                                setOpenPolicyCardList(e.currentTarget);
                              }}
                            >
                              +{questionData?.policy_tag?.split(",").length - 1}
                            </span>
                          );
                        }
                      })()}
                    </>
                  );
                })}
              </span>
            )}
          <Popover
            sx={{
              "& .MuiPopover-paper": {
                boxShadow: "none",
                border: "0.5px solid #6D8291 ",
                width: "325px",
                minHeight: "0px",
                color: "#666666",
              },
            }}
            id={questionData.card_question_id}
            open={open}
            anchorEl={openPolicyCardList}
            onClose={() => setOpenPolicyCardList(null)}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
          >
            {questionData?.policy_tag != "NULL" &&
              questionData?.policy_tag?.split(",").length > 0 &&
              questionData?.policy_tag?.split(",").map((tags, index) => {
                return (
                  <Typography
                    sx={{
                      margin: "5px 10px 5px 10px",
                    }}
                    key={index}
                  >
                    {" "}
                    {tags}
                  </Typography>
                );
              })}
          </Popover>
        </div>

        <div>
          <SectionsQuestionnaireAnswer
            questionData={questionData}
            isEditDatasetSection={isEditDatasetSection}
            handlEditedQuestionData={handlEditedQuestionData}
            updatedFiles={updatedFiles}
            fileList={fileList}
            handleUpdateFilesWhileDeleted={handleUpdateFilesWhileDeleted}
            handleUpdateFiles={handleUpdateFiles}
            showQueTextValue={showQueTextValue}
          />
        </div>
      </div>
    </>
  );
};

export default SectionsQuestionnaire;
