import React, { useEffect, useState } from "react";
import AmchartDetail from "../AmchartComponents/AmchartDetail";
import ControlSelector from "../ControlSelector/ControlSelector";
import PopOver from "../PopOver/PopOver";
import "./NodeDetail.css";
import { useCreateDatasetCardFromTemplate } from "../../api/createApiFunctions";
import { typeNames } from "../../constants/dataTypes";
import WarningDialogForCard from "../WarningDialogForCard/WarningDialogForCard";
import { Box, CircularProgress } from "@mui/material";
import Cards from "../Cards/CardsNew";
import { isAccessProvided } from "../../services/assetManagementService";
import { accessDataByRoleAtom } from "../../atom/jotai";
import { useAtom } from "jotai";

const NodeDetail = ({
  selectedData,
  nodeDetailsExpandView,
  setNodeDeatilExpandView,
}) => {
  //State for checked  or unChecked to show konfer score
  const [checkedKonferScore, setCheckedKonferScore] = useState(true);
  //State for show konfer score label
  const [checkedShowLabel, setCheckedShowLabel] = useState(true);
  const [nodeLabelFont, setNodeLabelFont] = useState(16); //State For increasing or decreasing graph nodes label, initial font size is 16
  const [zoomInAndOut, setZoomInAndOut] = useState(0.8); //State for maintaining Zoom in and Zoom out effect ,Initial value sets to 0.8 (means 80%)
  const limitForZoomInNodePage = 1.0; //zooom canvas till 100%
  const [positionOfMenuboxOnRightClick, setPositionOfMenuboxOnRightClick] =
    useState(null); //State for storing right clicked node position
  const open = Boolean(positionOfMenuboxOnRightClick);
  const [dataToNavigating, setDataForNavigating] = useState(null); //State for transferring data to node Right click popover
  // to show modal card
  const [showCard, setShowCard] = useState(false);

  const [findElementInNodeDetails, setFindElementInNodeDetails] = useState("");
  const [totalCountForNodeDetails, setTotalCountForNodeDetails] = useState(0);
  const [activeCountForNodeDetails, setActiveCountForNodeDetails] = useState(0);
  const [openWarningDialogForCard, setOpenWarningDialogForCard] =
    useState(false);
  const [callCreateCardAPI, setCallCreateCardAPI] = useState(false);
  const {
    mutate: createDatasetCardFromTemplate,
    isSuccess,
    data: datasetCardTemplateRes,
  } = useCreateDatasetCardFromTemplate();
  const [accessDataByRole, setAccessDataByRole] = useAtom(accessDataByRoleAtom);

  const [flag, setflag] = useState(false);
  useEffect(() => {
    var timer = setTimeout(() => {
      setflag(true);
    }, 100);
  }, []);

  useEffect(() => {
    if (callCreateCardAPI === true) {
      let id;
      if (dataToNavigating?.__typename === typeNames.apps) {
        id = dataToNavigating.app_id;
      }

      if (dataToNavigating?.__typename === typeNames.models) {
        id = dataToNavigating.model_id;
      }

      if (dataToNavigating?.__typename === typeNames.datasets) {
        id = dataToNavigating.input_data_table_id;
      }
      createDatasetCardFromTemplate({
        id: id,
        type: dataToNavigating?.__typename,
      });
      setCallCreateCardAPI(false);
      setOpenWarningDialogForCard(false);
      setShowCard(true);
      setPositionOfMenuboxOnRightClick(null);
    }
  }, [callCreateCardAPI]);

  const handleCallDatasetApi = () => {
    let id;
    if (
      dataToNavigating.card === null ||
      dataToNavigating.card?.latest_version === null ||
      dataToNavigating.card?.template_version === null
    ) {
      if (dataToNavigating?.__typename === typeNames.apps) {
        id = dataToNavigating.app_id;
      }

      if (dataToNavigating?.__typename === typeNames.models) {
        id = dataToNavigating.model_id;
      }

      if (dataToNavigating?.__typename === typeNames.datasets) {
        id = dataToNavigating.input_data_table_id;
      }

      createDatasetCardFromTemplate({
        id: id,
        type: dataToNavigating?.__typename,
      });
      setShowCard(true);
      setOpenWarningDialogForCard(false);
      setPositionOfMenuboxOnRightClick(null);
    } else {
      if (
        dataToNavigating.card?.latest_version ===
        dataToNavigating.card?.template_version
      ) {
        setShowCard(true);
        setOpenWarningDialogForCard(false);
        setPositionOfMenuboxOnRightClick(null);
      } else {
        setShowCard(false);
        setOpenWarningDialogForCard(true);
        setPositionOfMenuboxOnRightClick(null);
      }
    }
    setShowCard(true);
  };

  /* Below functions is call back function which stores the position cordinates of graph node. From that coordinates, right click
  constext menu is displayed   */
  const handleRightClickOnNode = (e) => {
    setPositionOfMenuboxOnRightClick(
      positionOfMenuboxOnRightClick === null
        ? {
            X: e.originalEvent.clientX,
            Y: e.originalEvent.clientY,
          }
        : null
    );
    setDataForNavigating(e.target._dataItem.dataContext); // Set the necessary data of node.
  };

  const handleRightClickMenuClose = () => {
    setPositionOfMenuboxOnRightClick(null);
    setDataForNavigating(null);
  };

  //Funtion is used to disable built-in context menu
  const handleToControlLeftAndRightClick = React.useCallback((event) => {
    // prevent context menu from opening on right-click
    event.preventDefault();
  }, []);

  if (!selectedData) {
    return (
      <Box sx={{ display: "flex", marginTop: "20%", marginLeft: "50%" }}>
        <CircularProgress />
      </Box>
    );
  }

  const handleSetCurrentData = (currentVal) => {
    setDataForNavigating((prevState) => ({
      ...prevState,
      cardData: currentVal,
    }));
  };

  return (
    <div id="nodeDetailsControlsDiv">
      <ControlSelector
        checkedKonferScore={checkedKonferScore}
        setCheckedKonferScore={setCheckedKonferScore}
        setCheckedShowLabel={setCheckedShowLabel}
        checkedShowLabel={checkedShowLabel}
        isVisible="Node Detail"
        nodeDetailsExpandView={nodeDetailsExpandView}
        setNodeDeatilExpandView={setNodeDeatilExpandView}
        nodeLabelFont={nodeLabelFont}
        setNodeLabelFont={setNodeLabelFont}
        zoomInAndOut={zoomInAndOut}
        setZoomInAndOut={setZoomInAndOut}
        limitForZoomIn={limitForZoomInNodePage}
        graphViewNodes={false}
        findElementInNodeDetails={findElementInNodeDetails}
        setFindElementInNodeDetails={setFindElementInNodeDetails}
        totalCountForNodeDetails={totalCountForNodeDetails}
        setTotalCountForNodeDetails={setTotalCountForNodeDetails}
        activeCountForNodeDetails={activeCountForNodeDetails}
        setActiveCountForNodeDetails={setActiveCountForNodeDetails}
      />

      <div
        id="nodeDetailsGraph"
        className="amchart_detail_container"
        onContextMenu={handleToControlLeftAndRightClick}
      >
        <AmchartDetail
          selectedData={selectedData}
          checkedShowLabel={checkedShowLabel}
          checkedKonferScore={checkedKonferScore}
          nodeLabelFont={nodeLabelFont}
          zoomInAndOut={zoomInAndOut}
          handleRightClickOnNode={handleRightClickOnNode}
          findElementInNodeDetails={findElementInNodeDetails}
          setFindElementInNodeDetails={setFindElementInNodeDetails}
          totalCountForNodeDetails={totalCountForNodeDetails}
          setTotalCountForNodeDetails={setTotalCountForNodeDetails}
          activeCountForNodeDetails={activeCountForNodeDetails}
          setActiveCountForNodeDetails={setActiveCountForNodeDetails}
        />

        <PopOver
          open={open}
          positionOfMenuboxOnRightClick={positionOfMenuboxOnRightClick}
          handleRightClickMenuClose={handleRightClickMenuClose}
          dataToNavigating={dataToNavigating}
          setShowCard={setShowCard}
          popoverContent={[
            "Share Details",
            isAccessProvided(
              "card_modal",
              "crud",
              null,
              "read",
              accessDataByRole
            ) && "Open Card",
          ]}
          handleCallDatasetApi={handleCallDatasetApi}
        />

        {showCard && (
          <Cards
            cardId={dataToNavigating?.card_id}
            showCard={showCard}
            setShowCard={setShowCard}
            datasetCardTemplateRes={datasetCardTemplateRes}
            selectedRiskFactor={null}
            isSuccess={isSuccess}
            handleSetCurrentData={handleSetCurrentData}
          />
        )}
      </div>
    </div>
  );
};

export default NodeDetail;
