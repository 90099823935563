import axios from "axios";
import { getLocalTokens, updateTokenData } from "../../utils/authUtils";

let authTokens = getLocalTokens();

const nodeApiInstance = axios.create({
  baseURL: `${process.env.REACT_APP_REST_API}`,
  headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer ${authTokens?.accessToken}`,
  },
});

// attach auth token to axios req cycle
nodeApiInstance.interceptors.request.use(
  (config) => {
    authTokens = getLocalTokens();
    config.headers["Authorization"] = "Bearer " + authTokens?.accessToken;

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export const handleAxiosResponseInterceptors = (navigate) => {
  // handle axios response cycle
  nodeApiInstance.interceptors.response.use(
    (res) => {
      return res;
    },
    async (err) => {
      const originalConfig = err.config;
      if (originalConfig.url !== "/auth/login" && err.response) {
        // handle for access control error
        if (
          err.response.status === 403 &&
          err?.response?.data?.error === "Forbidden Error"
        ) {
          navigate("/403-error");
        }
        if (err.response.status === 404) {
          navigate("/404-error");
        }
        // Access Token was expired
        if (err.response.status === 401 && !originalConfig._retry) {
          originalConfig._retry = true;
          authTokens = getLocalTokens();
          try {
            const res = await nodeApiInstance.post("/auth/refresh-token", {
              refreshToken: authTokens.refreshToken,
            });
            // save new tokens
            updateTokenData(res.data);
            // once the tokens are updated then continue with original request again
            return nodeApiInstance(originalConfig);
          } catch (_error) {
            // once the original request fails then remove the tokens to logout the user
            localStorage.removeItem("tokens");
            localStorage.removeItem("user");
            window.location.reload(true);
            return Promise.reject(_error);
          }
        }
      }
      return Promise.reject(err);
    }
  );
};

export default nodeApiInstance;
