export const slugifyText = (string) => {
  const data = string.trim().replace(/[\s-]+/g, "-");

  return data;
};

export const deslugifyText = (string) => {
  const data = string.trim().replace(/[\s-]+/g, " ");

  return data;
};
export const formateText = (string) => {
  const data = string.replace(/-/gi, " ");

  return data;
};

export const getMinute = (minute) => {
  return minute.toString().length === 1 ? `0${minute}` : `${minute}`;
};

// format : Day Month Date Year ,Time  (Thu Apr 28 2022, 9:00)
export const dateTimeFormat = (timestamp) => {
  const d = new Date(timestamp);
  const date =
    d.toDateString() + ", " + d.getHours() + ":" + getMinute(d.getMinutes());
  return date;
};

// format : Year-month-date (2022-04-28)
export const dateFormate = (timestamp) => {
  const d = new Date(timestamp);
  const date = d.toISOString().slice(0, 10);
  return date;
};

// format : hour:minute(PM/AM)
export const timeFormate = (timestamp) => {
  const d = new Date(timestamp);
  const time = `${d.getHours()}:${d.getMinutes()}`;
  return time;
};

export const uniqueArrayValues = (data) => {
  const setData = new Set(data);
  const uniqueArr = Array.from(setData);
  return uniqueArr;
};

export const isDataPresent = (data) => {
  if (data.length > 0) {
    return true;
  } else {
    return false;
  }
};

export const capitalizedFirstLetter = (string, type) => {
  const str = string;
  const capitalizedString = str.charAt(0).toUpperCase() + str.slice(1);
  return capitalizedString;
};

// Accepts the array and key
export const groupBy = (array, key) => {
  // Return the end result
  return array.reduce((result, currentValue) => {
    // If an array already present for key, push it to the array. Else create an array and push the object
    (result[currentValue[key]] = result[currentValue[key]] || []).push(
      currentValue
    );
    // Return the current iteration `result` value, this will be taken as next iteration `result` value and accumulate
    return result;
  }, {}); // empty object is the initial value for result object
};

export const formatBytes = (bytes, decimals = 2) => {
  if (!+bytes) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = [
    "Bytes",
    "KiB",
    "MiB",
    "GiB",
    "TiB",
    "PiB",
    "EiB",
    "ZiB",
    "YiB",
  ];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  // return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
  return {
    number: `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))}`,
    size: `${sizes[i]}`,
  };
};

export const downloadFile = ({ data, fileName, fileType }) => {
  // Create a blob with the data we want to download as a file
  const blob = new Blob([data], { type: fileType });
  // Create an anchor element and dispatch a click event on it
  // to trigger a download
  const a = document.createElement("a");
  a.download = fileName;
  a.href = window.URL.createObjectURL(blob);
  const clickEvt = new MouseEvent("click", {
    view: window,
    bubbles: true,
    cancelable: true,
  });
  a.dispatchEvent(clickEvt);
  a.remove();
};

export const jsonParse = (data) => {
  if (data === null) {
    return null;
  } else {
    let stringAns = data?.replace(/'/g, '"');

    if (stringAns) {
      let parseAns = JSON.parse(stringAns);
      if (parseAns.hasOwnProperty("text")) {
        return parseAns.text;
      }
      if (parseAns.hasOwnProperty("boolean")) {
        return parseAns.boolean;
      }
    } else {
      return null;
    }
  }
};

export const jsonParser = (data, type) => {
  let stringAns = data?.replace(/'/g, '"');

  if (stringAns) {
    let parseAns = JSON.parse(stringAns);

    if (parseAns.hasOwnProperty(type)) {
      return parseAns[type];
    } else {
      return null;
    }
  } else {
    return null;
  }
};
