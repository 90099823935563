import React, { useEffect, useState } from "react";

// Package Imports
import { useAtom } from "jotai";
import {
  authUser,
  authTokens,
  accessDataByRoleAtom,
  isSwitchToAdminPanelAtom,
  isTermsAndConditionsAccepted,
} from "../../atom/jotai";
import * as yup from "yup";
import { useFormik } from "formik";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useLocation, useNavigate } from "react-router-dom";

// MUI Imports
import {
  Container,
  Grid,
  TextField,
  Button,
  IconButton,
  InputAdornment,
  Typography,
  Tooltip,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";

// Custom Imports
import { getLocalTokens } from "../../utils/authUtils";
import "./Login.css";
import nodeApiInstance from "../../api/instance/nodeApiInstance";
import konferlogo from "../../assets/images/konferLogo/konferlogo.svg";
import mixpanel from "mixpanel-browser";
import {
  analyticServices,
  setUserAnalyticsInfo,
} from "../../services/analyticServices";
import TermsConditionPage from "../../pages/TermsConditionPage/TermsConditionPage";
import {
  handleDefaultUserAccess,
  isAccessProvided,
} from "../../services/assetManagementService";
import {
  accessConstants,
  moduleConstants,
  subModuleConstants,
} from "../../constants/accessManagementConstants";
import { switchToAdminLocation } from "../../services/adminServices";

const Login = () => {
  const [showPass, setShowPass] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const [openTermsCondModal, setOpenTermsCondModal] = useState(false);
  const [accessDataByRole, setAccessDataByRole] = useAtom(accessDataByRoleAtom);
  const [, setIsSwitchToAdminPanel] = useAtom(isSwitchToAdminPanelAtom);

  // Jotai states
  const [userState, setUser] = useAtom(authUser);
  const [tokens, setTokens] = useAtom(authTokens);
  const [terms, setTerms] = useAtom(isTermsAndConditionsAccepted);

  const validationSchema = yup.object({
    email: yup
      .string("Enter your username")
      .email("Enter a valid username")
      .required("Username is required"),
    password: yup
      .string("Enter your password")
      .min(6, "Password should be of minimum 6 characters length")
      .required("Password is required"),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      // pass formik values to login function
      handleLoginSubmit(values);
    },
  });

  const handleClickShowPassword = () => {
    setShowPass(!showPass);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleLoginSubmit = async (values) => {
    const { email, password } = values;

    try {
      const res = await nodeApiInstance.post("/auth/login", {
        email,
        password,
      });

      setUser(res.data.data.user);
      setTokens(res.data.data.tokens);
      setTerms(res?.data?.data?.user?.terms_accepted);

      /** if terms_accepted === false, means user is login first time, and he/she has to accept the terms and condition.
       * if terms_accepted === true, means it already accept terms, so navigate to home
       *
       */

      if (res?.data?.data?.user?.terms_accepted === false) {
        setOpenTermsCondModal(true);
      }

      localStorage.setItem("tokens", JSON.stringify(res.data.data.tokens));

      //mixpanel function

      analyticServices("Login_user");
      setUserAnalyticsInfo(email);
    } catch (error) {
      const errMsg = error?.response?.data?.error
        ? error.response.data.error
        : "Could not complete your request, please try again";

      // validation response are in array , else all other respose are string
      if (Array.isArray(errMsg)) {
        errMsg.map((e) => {
          toast.error(e);
        });
      } else {
        toast.error(errMsg);
      }
    }
  };

  const handleCredentialResponse = async (response) => {
    const tokenData = response.credential;

    try {
      const res = await nodeApiInstance.post("/auth/google-auth", {
        tokenData,
      });

      setUser(res.data.data.user);
      setTokens(res.data.data.tokens);

      /** if terms_accepted === false, means user is login first time, and he/she has to accept the terms and condition.
       * if terms_accepted === true, means it already accept terms, so navigate to home
       *
       */
      if (res?.data?.data?.user?.terms_accepted === true) {
        navigate("/");
      } else {
        setOpenTermsCondModal(true);
      }

      localStorage.setItem("tokens", JSON.stringify(res.data.data.tokens));
      setUser(res.data.data.user);
    } catch (error) {
      const errMsg = error?.response?.data?.error
        ? error.response.data.error
        : "Something went wrong. Please try again later!";

      // validation response are in array , else all other respose are string
      if (Array.isArray(errMsg)) {
        errMsg.map((e) => {
          toast.error(e);
        });
      } else {
        toast.error(errMsg);
      }
    }
  };

  //Below useEffect is used for giving title to current page
  useEffect(() => {
    document.title = "Konfer Login Page";
  });

  useEffect(() => {
    const token = getLocalTokens();

    if (tokens && userState && terms && token?.accessToken) {
      handleDefaultUserAccess(
        userState.contributor_id,
        userState.role,
        navigate,
        setIsSwitchToAdminPanel,
        setAccessDataByRole
      );
    }
  }, [tokens, userState, terms]);

  useEffect(() => {
    if (process.env.REACT_APP_ENABLE_GOOGLE_AUTH === "true") {
      setTimeout(() => {
        if (window.google) {
          /*global google*/
          google.accounts.id.initialize({
            client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
            callback: handleCredentialResponse,
          });

          /*global google*/
          google.accounts.id.renderButton(
            document.getElementById("googleLoginButtonDiv"),
            {
              size: "large",
              type: "icon",
              shape: "circle",
            }
          );
        }
      }, 1000);
    }
  }, [document.getElementById("googleLoginButtonDiv"), window.google]);

  useEffect(() => {
    const localTokens = getLocalTokens();

    if (localTokens) {
      navigate("/");
    }
  }, []);

  return (
    <div className="login_Container" id="loginContainer">
      <div className="login-page" id="loginPageDiv">
        <Grid sx={{ "& .MuiContainer-root": { padding: "0px" } }}>
          <Container>
            <Grid item sm={12} lg={12} md={12} xl={12} xs={12}>
              <div className="login-logo">
                <img src={konferlogo} alt="konfer-logo"></img>
              </div>

              <div className="login_form" id="loginForm">
                <form
                  noValidate
                  autoComplete="off"
                  onSubmit={formik.handleSubmit}
                >
                  <div className="email-filed" id="emailIDDiv">
                    <TextField
                      sx={{
                        "& .MuiFormHelperText-root": { marginBottom: "-30px" },
                      }}
                      fullWidth
                      // style={{ backgroundColor: "white" }}
                      className="login_textField"
                      label="Username"
                      variant="outlined"
                      name="email"
                      id="username_input"
                      value={formik.values.email}
                      // onChange={(e) => setEmail(e.target.value)}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.email && Boolean(formik.errors.email)
                      }
                      helperText={formik.touched.email && formik.errors.email}
                    />
                  </div>
                  <div id="passwordDiv">
                    <TextField
                      fullWidth
                      className="login_textField"
                      label="Password"
                      id="user_password_input"
                      variant="outlined"
                      type={showPass ? "text" : "password"}
                      name="password"
                      // value={password}
                      // onChange={(e) => setPassword(e.target.value)}
                      value={formik.values.password}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.password &&
                        Boolean(formik.errors.password)
                      }
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {showPass ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      helperText={
                        formik.touched.password && formik.errors.password
                      }
                    />
                  </div>
                  <div className="forgot-password" id="forgotPasswordButtonDiv">
                    <Button
                      className="forgotPassword-btn"
                      id="forget_password_btn"
                      onClick={() => {
                        navigate("/confirm-email");
                      }}
                    >
                      Forgot Password?
                    </Button>
                  </div>
                  <div className="submit-btn" id="LoginButtonDiv">
                    <Button
                      className="login-btn"
                      id="login_button"
                      type="submit"
                    >
                      Login
                    </Button>
                    {/* <div id="buttonDiv"></div> */}
                  </div>
                  <Typography
                    variant="h5"
                    component="div"
                    className="login-sign-in-text"
                  >
                    {process.env.REACT_APP_ENABLE_GOOGLE_AUTH === "true"
                      ? "Or Sign in with"
                      : ""}
                  </Typography>
                  <Tooltip title="Google Login">
                    <div
                      id="googleLoginButtonDiv"
                      className="google-login"
                    ></div>
                  </Tooltip>
                </form>
                {/* <button onClick={handleProtectedSubmit}>Access Private</button> */}
              </div>
            </Grid>
          </Container>
        </Grid>
      </div>

      <ToastContainer />
      {openTermsCondModal && (
        <TermsConditionPage
          openTermsCondModal={openTermsCondModal}
          setOpenTermsCondModal={setOpenTermsCondModal}
        />
      )}
    </div>
  );
};

export default Login;
