//Risk Factor  month
export const RiskFactorMonth = ["Dec", "Mar", "Jun", "Sep"];

export const riskFactorValueCond = {
  nullCondition: null,
  ntAplicabl: -999,
};

export const ntAplicablValue = "N/A";

export const nullConditionValue = "Incomplete";
