import React, { useState, useEffect } from "react";
import {
  IconButton,
  Dialog,
  DialogTitle,
  Typography,
  DialogContent,
  MenuItem,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import _ from "lodash";
import Select from "react-select";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";
import { useMutation, useQueryClient } from "react-query";
import CancelIcon from "../../assets/images/adminPanel/CancelIcon.svg";
import "./AdminNewUserDialog.css";
import { useGetRoleRegionBuData } from "../../api/readApiFunctions";
import nodeApiInstance from "../../api/instance/nodeApiInstance";
import { queryKeyConstants } from "../../constants/basicConstants";
import { analyticServices } from "../../services/analyticServices";
import { useAtom } from "jotai";
import { authUser } from "../../atom/jotai";

const AdminNewUserDialog = ({
  openNewUserDialog,
  setOpenNewUserDialog,
  edit,
  userDataForEditUser,
  userlistRefetch,
}) => {
  const location = useLocation();

  //get role, region ,business units,
  const {
    data: allRoleRegionBuData,
    error: allRoleRegionBuDataError,
    isLoading: allRoleRegionBuDataLoading,
  } = useGetRoleRegionBuData(
    "all",
    "users",
    location.pathname.split("/")[2] == "userlist" ? true : false
  );

  const queryClient = useQueryClient();
  const [selectedBusinessUnit, setSelectedBusinessUnit] = useState([]);
  const [selectedRegions, setSelectedRegions] = useState([]);
  const [selectedRole, setSelectedRole] = useState();
  const [showPassword, setShowPassword] = useState(false);
  const [showCPassword, setShowCPassword] = useState(false);
  const [optionsForBusinessUnit, setOptionsForBusinessUnit] = useState([]);
  const [optionsForRole, setOptionsForRole] = useState([]);
  const [optionsForRegion, setOptionsForRegion] = useState([]);
  const [flagForExitingUser, setFlagForExitingUser] = useState(false);
  const [isMultiRegionsAdd, setMultiRegionsAdd] = useState(false);
  const [user] = useAtom(authUser);

  function getBuiFromContributor(data) {
    const arrayForBusinessUnitId = [];
    const arrayForBusinessUnit = [];

    data?.map((unit) => {
      // arrayForBusinessUnit.push(unit?.business_unit?.name);
      arrayForBusinessUnit.push({
        value: unit?.business_unit?.business_unit_id,
        label: `${unit?.business_unit?.name} (${unit?.business_unit?.region?.name})`,
      });
      arrayForBusinessUnitId.push(unit?.business_unit?.business_unit_id);
    });

    return {
      arrayForBusinessUnitId,
      arrayForBusinessUnit,
    };
  }

  //API call for updating user in the database
  const updateUserMutation = useMutation(
    async (payload) => {
      const data = await nodeApiInstance.patch("/auth/update-user", payload);
      return data;
    },
    {
      onSuccess: () => {
        userlistRefetch();
        queryClient.invalidateQueries(queryKeyConstants.userlist);
        toast.success("User updated");
      },
      onError: (error) => {
        toast.error(error.response.data.error);
      },
    }
  );

  useEffect(() => {
    if (edit === true) {
      if (_.isEmpty(userDataForEditUser)) return;

      // role
      let arrayForRole = {
        value: userDataForEditUser.role,
        label: userDataForEditUser.role,
      };
      setSelectedRole(arrayForRole);
      setValue("role", userDataForEditUser.role);

      // Set Regions Units

      const regionId = [];
      const regionData = [];

      if (userDataForEditUser?.contributerOnRegion.length > 0) {
        userDataForEditUser.contributerOnRegion.map((regionD) => {
          regionId.push(regionD.region.region_id);
          regionData.push({
            value: regionD.region.region_id,
            label: regionD.region.name,
          });
        });
      }

      setValue("regions", regionId);
      setSelectedRegions(regionData);

      const { arrayForBusinessUnit, arrayForBusinessUnitId } =
        getBuiFromContributor(userDataForEditUser?.contributors_business_units);

      setSelectedBusinessUnit(arrayForBusinessUnit);
      setValue("business_units", arrayForBusinessUnitId);
    }
  }, [edit, userDataForEditUser]);

  const getUniqueIdsForBui = (data) => {
    let uniqueObjArray = [
      ...new Map(data.map((item) => [item["value"], item])).values(),
    ];

    return uniqueObjArray;
  };

  useEffect(() => {
    if (selectedRole) {
      const validRoles = ["Admin", "SuperAdmin", "CPO", "Org Admin"];
      if (validRoles.includes(selectedRole.value)) {
        setMultiRegionsAdd(true);
        // Pending
        // setSelectedRegions([])
      } else {
        setMultiRegionsAdd(false);
        // setSelectedRegions([])
      }
    }
  }, [selectedRole?.value]);

  // if the selected region changes the businessunit will also change based on if the region has that BU assigned or not
  useEffect(() => {
    const optionBU = [];
    const { arrayForBusinessUnit } = getBuiFromContributor(
      userDataForEditUser?.contributors_business_units
    );

    const selectedBU = edit ? arrayForBusinessUnit : [];

    selectedRegions.map((regionName) => {
      allRoleRegionBuData &&
        allRoleRegionBuData?.region?.map((regionD) => {
          if (regionName?.label === regionD.name) {
            regionD.business_unit.map((regionBU) => {
              optionBU.push({
                value: regionBU.business_unit_id,
                label: `${regionBU.name} (${regionD?.name})`,
              });
            });
          }
        });
    });

    // get the selected BU's and check that the selected BU is present in the assigned Bu array
    for (let i = 0; i < selectedBusinessUnit.length; i++) {
      const elementI = selectedBusinessUnit[i];
      if (optionBU.some((data) => data.label === elementI.label)) {
        selectedBU.push(elementI);
      }
    }

    // if not present then remove that BU from the array and new  state of assigned BU
    setOptionsForBusinessUnit(optionBU);
    // set new state of BU options
    // setSelectedBusinessUnit(selectedBU);
  }, [
    selectedRegions,
    edit,
    userDataForEditUser?.contributors_business_units,
    allRoleRegionBuData,
    // regionData,
  ]);

  // React Hook useEffect has missing dependencies: 'regionData', 'selectedBusinessUnit', and 'userDataForEditUser?.contributors_business_units'.
  // Either include them or remove the dependency array

  //convert role, region and business unit data in the format of react select options
  useEffect(() => {
    //role data
    let op = [];
    allRoleRegionBuData &&
      allRoleRegionBuData?.role?.length > 0 &&
      allRoleRegionBuData?.role?.map((role, index) => {
        op.push({
          value: role.name,
          label: role.name,
        });
      });
    setOptionsForRole(op);

    //region data
    let regionOptions = [];
    allRoleRegionBuData &&
      allRoleRegionBuData?.region?.length > 0 &&
      allRoleRegionBuData?.region?.map((region, index) => {
        regionOptions.push({
          value: region.region_id,
          label: region.name,
        });
      });
    setOptionsForRegion(regionOptions);

    //business unit data
    let opBU = [];

    /**While doing edit, if already region is selected then in business unit dropdown
     * show only selected region business unit */
    selectedRegions.length > 0 &&
      selectedRegions.map((regionName) => {
        allRoleRegionBuData &&
          allRoleRegionBuData?.region?.map((regionD) => {
            if (regionName?.label === regionD.name) {
              regionD.business_unit.map((regionBU) => {
                opBU.push({
                  value: regionBU.business_unit_id,
                  label: `${regionBU.name} (${regionD?.name})`,
                });
              });
            }
          });
      });

    /**While doing edit, if region is not selected then in business unit dropdown
     * show all options of business unit */
    selectedRegions.length === 0 &&
      allRoleRegionBuData &&
      allRoleRegionBuData?.business_unit?.length > 0 &&
      allRoleRegionBuData?.business_unit?.map((businessUnit, index) => {
        opBU.push({
          value: businessUnit.business_unit_id,
          label: `${businessUnit.name} (${businessUnit?.region?.name})`,
        });
      });
    setOptionsForBusinessUnit(opBU);
  }, [allRoleRegionBuData]);

  // form validation rules
  const validationSchemaForCreate = Yup.object().shape({
    name: Yup.string()
      .required("Name is required")
      .min(3, "Name should be minimun 3 characters.")
      .trim(),
    email: Yup.string().required("Email is required").email(),
    password: Yup.string()
      .required("Password is required")
      .min(6, "Password should be minimun 6 characters."),
    confirmPassword: Yup.string()
      .required("Confirm Password is required")
      .oneOf([Yup.ref("password")], "Passwords must match"),
    role: Yup.string().required("Role is required"),
    business_units: Yup.array().optional(),
    regions: Yup.array().required("Regions is required"),
  });

  const validationSchemaForUpdate = Yup.object().shape({
    role: Yup.string().required("Role is required"),
    business_units: Yup.array().optional(),
    regions: Yup.array().required("Regions is required"),
  });
  const formOptions = {
    resolver: yupResolver(
      edit ? validationSchemaForUpdate : validationSchemaForCreate
    ),
  };

  //User Form
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    setError,
    clearErrors,
  } = useForm(formOptions);

  //API call for adding user in the database
  const createUserMutation = useMutation(
    async (payload) => {
      const data = await nodeApiInstance.post("/auth/register", payload);
      return data;
    },
    {
      onSuccess: () => {
        userlistRefetch();
        queryClient.invalidateQueries(queryKeyConstants.userlist);
        toast.success("User added");
        setOpenNewUserDialog(false);
      },

      onError: (error) => {
        // toast.error(error.response.data.error);
        setError("email", {
          type: "custom",
          message: `${error.response.data.error}`,
        });
      },
    }
  );

  //Create New User
  const handleRegistration = (data) => {
    setValue("name", data.name.trim());
    delete data.confirmPassword;
    data.createdBy_contributor_id = user?.contributor_id;
    data.createdBy_contributor_role = user?.role;
    if (!data.hasOwnProperty("business_units")) {
      //if none of the business unit is selected
      data.business_units = [];
    }
    createUserMutation.mutate(data);
    edit !== true && analyticServices(` ${getValues("name")}, user created`);
  };

  const handleUpdate = (data) => {
    delete data.name;
    delete data.email;

    if (!data.hasOwnProperty("business_units")) {
      //if none of the business unit is selected
      data.business_units = [];
    }

    updateUserMutation.mutate({
      ...data,
      contributerId: userDataForEditUser?.contributor_id,
      modInvoker: "admin_view",
      subModInvoker: "users",
    });
    setOpenNewUserDialog(false);
  };

  const handleError = (errors) => {};

  //Business unit selection function
  const handleChange = (values, type) => {
    let arrayForBusinessUnit = [];
    //For setting Business ID for saving in database
    values.map((val) => {
      arrayForBusinessUnit.push(val.value);
    });

    if (type === "businessUnit") {
      setSelectedBusinessUnit(values);
      setValue("business_units", arrayForBusinessUnit);
    }

    if (type === "region") {
      if (isMultiRegionsAdd === false && selectedRegions.length === 1) {
        setError("regions", {
          type: "custom",
          message: `Selected role can have only one region.`,
        });
        setSelectedBusinessUnit([]);
      }

      if (isMultiRegionsAdd) {
        clearErrors("regions");
        setSelectedRegions(values);
        setValue("regions", arrayForBusinessUnit);

        /** below logic is used for deleting business units according to selectd regions */
        let tempArray = [];
        values.map((item) => {
          tempArray =
            selectedBusinessUnit.length > 0 &&
            selectedBusinessUnit.filter((businessUnit) => {
              return businessUnit.label.includes(item.label);
            });
        });
        tempArray !== false && setSelectedBusinessUnit(tempArray);
      } else {
        setSelectedRegions([values[0]]);
        setValue("regions", [values[0]?.value]);
      }
    }
  };

  return (
    <>
      <Dialog
        id="adminUserlistCreateDialog"
        open={openNewUserDialog}
        onClose={() => {
          setOpenNewUserDialog(!openNewUserDialog);
        }}
        aria-labelledby="new-user-dialog"
        aria-describedby="new-user-dialog"
        sx={{
          "& .MuiDialog-paper": {
            boxShadow: "0px 6px 10px rgba(0, 0, 0, 0.3)",
            borderRadius: "8px",
            width: "675.4px",
            height: "530.96px",
          },
        }}
      >
        <DialogTitle id="new-user-dialog" sx={{ m: 0, p: 2 }}>
          <Typography
            variant="h5"
            component="div"
            className="create-newUser-heading"
          >
            {edit ? "Edit User" : "Create New User"}
          </Typography>
          <IconButton
            id="adminUserlistcloseDialog"
            aria-label="close"
            onClick={() => {
              setOpenNewUserDialog(false);
            }}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <img src={CancelIcon} alt="Delete" />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <form
            onSubmit={handleSubmit(
              edit ? handleUpdate : handleRegistration,
              handleError
            )}
            autoComplete="off"
            style={{ marginTop: "10px" }}
            id="adminUserlistCreateForm"
          >
            <div className="user-info-row" id="adminUserlistCreateUsernameRow">
              <label className="user-info-label">Username</label>
              <input
                id="adminUserlistCreateUsernameInput"
                className="user-info-input"
                name="name"
                defaultValue={edit ? userDataForEditUser?.name : ""}
                disabled={edit && true}
                type="search"
                autoComplete="new-name"
                {...register("name")}
              />
            </div>
            {errors?.name && errors.name.message && (
              <div
                className="user-info-row-erros"
                id="adminUserlistCreateUsernameRowError"
              >
                <div></div>
                <small className="text-danger">
                  {errors?.name && errors.name.message}
                </small>
                {flagForExitingUser && (
                  <small className="text-danger">
                    Email Id is already existing.
                  </small>
                )}
              </div>
            )}

            <div className="user-info-row" id="adminUserlistCreateEmailRow">
              <label className="user-info-label">Email ID</label>
              <input
                id="adminUserlistCreateEmailInput"
                className="user-info-input"
                type="email"
                defaultValue={edit ? userDataForEditUser?.email : ""}
                disabled={edit && true}
                name="email"
                {...register("email")}
              />
            </div>
            {errors?.email && errors.email.message && (
              <div
                className="user-info-row-erros"
                id="adminUserlistCreateEmailRowError"
              >
                <div></div>
                <small className="text-danger">
                  {errors?.email && errors.email.message}
                </small>
              </div>
            )}

            {!edit && (
              <div
                className="user-info-row"
                id="adminUserlistCreatePasswordRow"
              >
                <label className="user-info-label">Password</label>
                <div className="user-info-input">
                  <input
                    id="adminUserlistCreatePasswordInput"
                    className="createuser-input"
                    type={showPassword ? "text" : "password"}
                    autoComplete="off"
                    name="password"
                    {...register("password")}
                  />
                  {showPassword ? (
                    <VisibilityOff
                      id="visibilityOff"
                      fontSize="small"
                      onClick={() => {
                        setShowPassword(!showPassword);
                      }}
                    />
                  ) : (
                    <Visibility
                      id="visibilityOn"
                      fontSize="small"
                      onClick={() => {
                        setShowPassword(!showPassword);
                      }}
                    />
                  )}
                </div>
              </div>
            )}
            {errors?.password && errors.password.message && (
              <div
                className="user-info-row-erros"
                id="adminUserlistCreatePasswordRowError"
              >
                <div></div>
                <small className="text-danger">
                  {errors?.password && errors.password.message}
                </small>
              </div>
            )}

            {!edit && (
              <div
                className="user-info-row"
                id="adminUserlistCreateCPasswordRow"
              >
                <label className="user-info-label">Confirm Password</label>
                <div className="user-info-input">
                  <input
                    id="adminUserlistCreateCPasswordInput"
                    className="createuser-input"
                    type={showCPassword ? "text" : "password"}
                    autoComplete="off"
                    name="confirmPassword"
                    {...register("confirmPassword")}
                  />
                  {showCPassword ? (
                    <VisibilityOff
                      id="confirmVisibiltyOff"
                      fontSize="small"
                      onClick={() => {
                        setShowCPassword(!showCPassword);
                      }}
                    />
                  ) : (
                    <Visibility
                      id="confirmVisibiltyOn"
                      fontSize="small"
                      onClick={() => {
                        setShowCPassword(!showCPassword);
                      }}
                    />
                  )}
                </div>
              </div>
            )}
            {errors?.confirmPassword && errors.confirmPassword.message && (
              <div
                className="user-info-row-erros"
                id="adminUserlistCreateCPasswordRowError"
              >
                <div></div>
                <small className="text-danger">
                  {errors?.confirmPassword && errors.confirmPassword.message}
                </small>
              </div>
            )}

            <div className="user-info-row" id="createUserRoleRow">
              <label className="user-info-label">Role</label>
              <Select
                id="createUser-role"
                value={selectedRole && selectedRole}
                isClearable={false}
                styles={{
                  container: (base) => ({
                    ...base,
                    focusVisible: {
                      outline: "none",
                    },
                  }),
                  control: (base) => ({
                    ...base,
                    marginTop: "-8px",
                    border: "none",
                    borderBottom: " 1px solid #6d8291",
                    borderRadius: "2px",
                  }),
                }}
                maxMenuHeight="100px"
                onChange={(event) => {
                  clearErrors("role");

                  setSelectedRole(event);
                  if (event.label) {
                    setSelectedRegions([]);
                    setValue("regions", []); //For from value
                    setSelectedBusinessUnit([]);
                    setValue("business_units", []); //For from value
                    setValue("role", event.label);
                    clearErrors("regions");
                  } else {
                    setValue("role", "");
                  }
                }}
                options={optionsForRole}
              />
            </div>
            {errors?.role && errors.role.message && (
              <div className="user-info-row-erros" id="createUserRoleRowError">
                <div></div>
                <small className="text-danger">
                  {errors?.role && errors.role.message}
                </small>
              </div>
            )}

            <div className="user-info-row" id="createUser-businessUnits-row">
              <label className="user-info-label">Regions</label>
              <Select
                id="createUser-regions"
                value={selectedRegions && selectedRegions}
                isMulti
                isClearable={false}
                styles={{
                  multiValue: (base) => ({
                    ...base,
                    background: " #FFFFFF",
                    border: " 0.2px solid #6D8291",
                    boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.25)",
                    borderRadius: "13px",
                  }),
                  control: (base) => ({
                    ...base,
                    marginTop: "-8px",
                    border: "none",
                    borderBottom: " 1px solid #6d8291",
                    borderRadius: "2px",
                  }),
                }}
                maxMenuHeight="100px"
                onChange={(event) => {
                  handleChange(event, "region");
                }}
                options={selectedRole ? optionsForRegion : []}
              />
            </div>

            {errors?.regions && errors.regions.message && (
              <div
                className="user-info-row-erros"
                id="adminUserlistRegionsError"
              >
                <div></div>
                <small className="text-danger">
                  {errors?.regions && errors.regions.message}
                </small>
              </div>
            )}

            <div className="user-info-row" id="createUser-businessUnits-row">
              <label className="user-info-label">Business Units</label>
              <Select
                id="createUser-businessUnits"
                value={getUniqueIdsForBui(selectedBusinessUnit)}
                isMulti
                isClearable={false}
                styles={{
                  multiValue: (base) => ({
                    ...base,
                    background: " #FFFFFF",
                    border: " 0.2px solid #6D8291",
                    boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.25)",
                    borderRadius: "13px",
                  }),
                  control: (base) => ({
                    ...base,
                    marginTop: "-8px",
                    border: "none",
                    borderBottom: " 1px solid #6d8291",
                    borderRadius: "2px",
                  }),
                }}
                maxMenuHeight="100px"
                onChange={(event) => {
                  handleChange(event, "businessUnit");
                }}
                options={selectedRegions ? optionsForBusinessUnit : []}
              />
            </div>
            {errors?.businessUnits && errors.businessUnits.message && (
              <div
                className="user-info-row-erros"
                id="createUser-businessUnits-row-error"
              >
                <div></div>
                <small className="text-danger">
                  {errors?.businessUnits && errors.businessUnits.message}
                </small>
              </div>
            )}

            <button className="create-user-button" id="create-user-button">
              <span>{edit ? "Update" : "Create"}</span>
            </button>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default AdminNewUserDialog;
