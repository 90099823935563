export const PolicyNameConst = {
  konfer_confidence: "Konfer Confidence™",
  // konfer_confidence_wo_tm: "Konfer Confidence",  /** For the demo purpose commented the below code and changed the options */
  // konfer_confidence: "CME Confidence™",
  konfer_confidence_wo_tm: "CME Confidence",

  //########

  human_impact: "Human Impact",
  policy: "Regulation",

  policies: "Regulations",
};
