import {
  Box,
  IconButton,
  Modal,
  TextField,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSearchQueInOpenAI } from "../../api/createApiFunctions";
import CancelIcon from "../../assets/images/adminPanel/CancelIcon.svg";
import { toast } from "react-toastify";

/**
 * Component : OpenAIModal
 *
 * Description : This component is responsible for opening modal for finding the answer in open ai.
 *
 *  Props : open modal variable and it's setter function,
 *      question for open ai and it's setter function
 */

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: window.innerHeight <= 720 ? "60%" : "65%",
  height: window.innerHeight <= 720 ? "50%" : "46%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 1,
};

const OpenAIModal = ({
  openAIModal,
  setOpenAIModal,
  questionTextForOpenAI,
  setQusetionTextForOpenAI,
}) => {
  let a =
    window.innerHeight <= 760 ? 100 : window.innerHeight <= 900 ? 120 : 180;
  const [documentForAISrch, setDocumentForAISrch] = useState("");
  const [openAIAnswer, setOpenAIAnswer] = useState("");

  const {
    mutate: getAnswerFromOpenAI,
    data: answerData,
    isLoading,
    isError: answerDataError,
    error,
  } = useSearchQueInOpenAI(
    documentForAISrch,
    `${questionTextForOpenAI} Respond only with a Yes or No.`
  );

  //Below function is used to search answer in open ai
  const handleSearchInOpenAI = () => {
    getAnswerFromOpenAI();
  };

  //Below uesEffect() is used for setting the open ai answer
  useEffect(() => {
    if (answerData && answerData.length > 0) {
      setOpenAIAnswer(answerData[0].text);
    }
  }, [answerData]);

  //Below uesEffect() is used for showing an error while connection the open ai
  useEffect(() => {
    if (answerDataError) {
      toast.error(error.message);
    }
  }, [answerDataError]);

  return (
    <Modal
      open={openAIModal}
      onClose={() => {
        setOpenAIModal(false);
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <div style={{ display: "flex", justifyContent: "end" }}>
          <IconButton
            onClick={() => {
              setOpenAIModal(false);
            }}
          >
            <img src={CancelIcon} width="12px" />
          </IconButton>
        </div>
        <div
          style={{
            padding: "0px 10px",
            position: "relative",
            marginTop: "-10px",
          }}
        >
          <Typography id="modal-modal-title" variant="h5" component="h2">
            GenAI Response
          </Typography>

          <TextareaAutosize
            aria-label="open_ai_document"
            placeholder="Document"
            style={{
              width: "99%",
              height: a,
              resize: "none",
              marginTop: "10px",
              padding: "3px 5px",
              overflow: "auto",
            }}
            onChange={(e) => {
              if (e.target.value !== "") {
                setDocumentForAISrch(e.target.value);
              }
            }}
          />

          <TextField
            id="open_ai_qusetion"
            placeholder="Question"
            defaultValue={
              questionTextForOpenAI === ""
                ? ""
                : `${questionTextForOpenAI} Respond only with a Yes or No.`
            }
            variant="outlined"
            multiline
            maxRows={1}
            style={{ marginTop: "10px", width: "100%" }}
            onChange={(e) => {
              if (e.target.value !== "") {
                setQusetionTextForOpenAI(e.target.value);
              }
            }}
          />

          <TextField
            id="open_ai_answer"
            placeholder="Answer"
            multiline
            maxRows={1}
            value={isLoading ? "Loading..." : openAIAnswer}
            variant="outlined"
            style={{ marginTop: "10px", width: "100%" }}
            InputProps={{
              readOnly: true,
            }}
          />

          <div className="open_ai_modal_btn">
            <button
              className="dataset_btn_active"
              onClick={handleSearchInOpenAI}
            >
              Get Answer
            </button>
            <button
              className="dataset_btn_inactive"
              onClick={() => {
                setOpenAIModal(false);
              }}
            >
              Close
            </button>
          </div>
        </div>
      </Box>
    </Modal>
  );
};

export default OpenAIModal;
