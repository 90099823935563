import React, { useEffect, useState } from "react";
import "./CardsSubsection.css";
import { dateTimeFormat } from "../../../utils/basicUtils";
import MUITable from "../../MUITable/MUITable";
import { TextField } from "@mui/material";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  CircularProgress,
  Link,
} from "@mui/material";
import SubsectionFileUpload from "../../SubsectionFileUpload/SubsectionFileUpload";
import { documentFileUpload } from "../../../atom/jotai";
import { useAtom } from "jotai";
import { useGetCodeAnalysisData } from "../../../api/readApiFunctions.js";
import ZoomImage from "../../ZoomImage/ZoomImage";

/**
 * Component : DisplayDetailsInfo
 *
 * Description : This component is responsible for displaying "details" section data.
 *
 * Props : detailsData - section details infromation.
           isEditDatasetSection - state which explain edit mode status,
           editedTextData - State to store the edited text data information,
           handlEditedTextData - handler function for editedTextData
 *  */

const DisplayDetailsInfo = ({
  detailsData,
  isEditDatasetSection,
  handlEditedTextData,
  editedTextData,
  cardId,
  setEditedTextData,
}) => {
  const [allDocumentFiles, setAllDocumentFiles] = useAtom(documentFileUpload);

  const [codeAnalysisData, setCodeAnalysisData] = useState([]);
  const [tRows, setTRows] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [isImageLoaded, setImageLoaded] = useState(false);

  const {
    data: codeAnalysisResponse,
    isLoading,
    refetch,
  } = useGetCodeAnalysisData(codeAnalysisData , "details");

  // show the default text data or from the state if edited
  const showDefaultTextData = (id, text) => {
    const textEdited = editedTextData.filter((editedText) => {
      if (id === editedText.text_data_id) {
        return editedText.text_data;
      }
    });

    if (textEdited.length > 0) {
      return textEdited[0]?.text_data;
    } else {
      return text[0]?.text_data;
    }
  };

  useEffect(() => {
    detailsData.subsections.map((subSection) => {
      if (subSection.title === "Document") {
        if (allDocumentFiles.length === 0) {
          const documentFiles = subSection?.text_data[0]?.text_data
            ? JSON.parse(subSection?.text_data[0]?.text_data)
            : [];
          setAllDocumentFiles(documentFiles);
        }
      } else if (subSection.title === "Code Analysis") {
        let tempData = [];
        if (subSection.text_data && subSection.text_data.length > 0) {
          subSection.text_data.map((textData) => {
            tempData.push(textData);
          });
        }
        setCodeAnalysisData(tempData);
      }
    });
  }, [detailsData, isEditDatasetSection]);

  useEffect(() => {
    /* Below logic is used to formate dependency json data into table formate */
    if (codeAnalysisResponse && codeAnalysisResponse.length) {
      let tableRows = [];
      const fileData = JSON.parse(codeAnalysisResponse[0].chart_data);
      fileData?.import?.import &&
        fileData?.import?.import.length > 0 &&
        fileData?.import?.import.forEach((item) => {
          tableRows.push(createData(item, "-"));
        });

      fileData?.from_import?.extract &&
        fileData?.from_import?.extract.length > 0 &&
        fileData?.from_import?.extract.forEach((item) => {
          tableRows.push(createData(item.from, item.module.toString()));
        });

      setTRows(tableRows);
    }
  }, [codeAnalysisResponse]);

  const createData = (PackageName, ImportedModule) => {
    return { PackageName, ImportedModule };
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  /* Below function is used to check image is completely load or not. */
  const handleImageLoad = () => {
    setImageLoaded(true);
  };

  return (
    <>
      {/**Name */}
      <p className="sub_title">Name</p>
      <p className="sub_desc">{detailsData.entity?.name}</p>

      {/**Display Name */}
      <p className="sub_title">Display Name</p>
      <p className="sub_desc">
        {detailsData.entity?.display_name === null ||
        detailsData.entity?.display_name === ""
          ? "-"
          : detailsData.entity?.display_name}
      </p>

      {/**Description */}
      <p className="sub_title">Description</p>
      <p className="sub_desc">
        {detailsData.entity?.description === null ||
        detailsData.entity?.description === "null" ||
        detailsData.entity?.description === ""
          ? "-"
          : detailsData.entity?.description}
      </p>

      {(detailsData.entity?.__typename === "app" ||
        detailsData.entity?.__typename === "model") && (
        <>
          {/**Version */}
          <p className="sub_title">Version</p>
          <p className="sub_desc">
            {detailsData.entity?.version === null ||
            detailsData.entity?.version === ""
              ? "-"
              : detailsData.entity?.version}
          </p>

          {/**Created at */}
          <p className="sub_title">Created at</p>
          <p className="sub_desc">
            {detailsData.entity?.created_at === null ||
            detailsData.entity?.created_at === ""
              ? "-"
              : dateTimeFormat(detailsData.entity?.created_at)}
          </p>
        </>
      )}

      {detailsData.entity?.__typename === "model" && (
        <>
          {/**Model Type */}
          <p className="sub_title">Model Type</p>
          <p className="sub_desc">
            {detailsData.entity?.model_type === null ||
            detailsData.entity?.model_type === ""
              ? "-"
              : detailsData.entity?.model_type}
          </p>

          {/**External Model Dependency */}
          <p className="sub_title">External Model Dependency</p>
          <p className="sub_desc">
            {detailsData.entity?.external_model_dependency === null ||
            detailsData.entity?.external_model_dependency === ""
              ? "-"
              : detailsData.entity?.external_model_dependency}
          </p>

          {/**Algorithm */}
          <p className="sub_title">Algorithm</p>
          <p className="sub_desc">
            {detailsData.entity?.algorithm_type === null ||
            detailsData.entity?.algorithm_type === ""
              ? "-"
              : detailsData.entity?.algorithm_type}
          </p>
        </>
      )}

      {detailsData.entity?.__typename === "input_data_table" && (
        <>
          {/**Source */}
          <p className="sub_title">Source</p>
          <p className="sub_desc">
            {detailsData.entity?.source === null ||
            detailsData.entity?.source === ""
              ? "-"
              : detailsData.entity?.source}
          </p>

          {/**Source Type */}
          <p className="sub_title">Source Type</p>
          <p className="sub_desc">
            {detailsData.entity?.source_type === null ||
            detailsData.entity?.source_type === ""
              ? "-"
              : detailsData.entity?.source_type}
          </p>

          {/**Usage Type    */}
          <p className="sub_title">Usage Type </p>
          <p className="sub_desc">
            {detailsData.entity?.cusage_type === null ||
            detailsData.entity?.cusage_type === ""
              ? "-"
              : detailsData.entity?.usage_type}
          </p>
        </>
      )}

      {/**Contributor */}
      <p className="sub_title">Contributor</p>
      {detailsData.entity?.contributor === null ||
      detailsData.entity?.contributor.length < 0 ? (
        <p className="sub_desc">"-"</p>
      ) : (
        <div style={{ display: "flex" }}>
          {detailsData.entity?.contributor.map((contributorData) => {
            return (
              <p className="sub_desc">
                {contributorData?.contributor?.name} ,{" "}
              </p>
            );
          })}
        </div>
      )}

      {detailsData.entity?.__typename === "model" && (
        <>
          {/**Hyper-Parameters */}
          <p className="sub_title">Hyper-Parameters</p>
          {detailsData.entity?.models_hyperparameters_tables?.length > 0 ? (
            <MUITable
              hyperParamsArr={detailsData.entity.models_hyperparameters_tables}
            />
          ) : (
            <p className="sub_desc">"-"</p>
          )}
        </>
      )}

      {detailsData.subsections.map((subSection) => {
        if (
          subSection.title !== "Questionnaire" &&
          subSection.title !== "Document" &&
          subSection.title !== "Code Analysis"
        ) {
          return (
            <>
              <p className="sub_title">{subSection.title}</p>

              {subSection.description !== "" &&
                subSection.description !== "null" &&
                subSection.description !== null && (
                  <p className="sub_desc">{subSection.description}</p>
                )}

              {isEditDatasetSection ? (
                subSection.title === "Summary" ? (
                  <TextField
                    id="outlined-multiline-static"
                    multiline
                    fullWidth
                    placeholder="Text"
                    maxRows={4}
                    sx={{
                      "& .MuiInputBase-root": {
                        padding: "2px 5px",
                        height: "100px",
                        alignItems: "start",
                      },
                    }}
                    defaultValue={showDefaultTextData(
                      subSection?.text_data.length > 0
                        ? subSection?.text_data[0]?.text_data_id
                        : null,
                      subSection?.text_data.length > 0
                        ? subSection?.text_data
                        : ""
                    )}
                    value={
                      editedTextData.find((eText) =>
                        eText.text_data_id === subSection?.text_data.length > 0
                          ? subSection?.text_data[0]?.text_data_id
                          : null
                      )?.[0]?.text_data
                    }
                    onChange={(e) => {
                      handlEditedTextData(
                        e.target.value,
                        detailsData.section_id,
                        subSection.subsection_id,
                        subSection?.text_data.length > 0
                          ? subSection?.text_data[0]?.text_data_id
                          : null
                      );
                    }}
                  />
                ) : subSection.text_data.length === 0 ||
                  subSection.text_data === null ? (
                  <p className="sub_desc_no_information">
                    No Information Available
                  </p>
                ) : (
                  <p className="sub_desc sub_desc_text_data">
                    {subSection?.text_data[0]?.text_data}
                  </p>
                )
              ) : subSection.text_data.length === 0 ||
                subSection.text_data === null ? (
                <p className="sub_desc_no_information">
                  No Information Available
                </p>
              ) : (
                <p className="sub_desc sub_desc_text_data">
                  {subSection?.text_data[0]?.text_data}
                </p>
              )}
            </>
          );
        }

        if (subSection.title === "Document") {
          return (
            <>
              <p className="sub_title">{subSection.title}</p>
              {!isEditDatasetSection && subSection.text_data.length === 0 && (
                <p className="sub_desc_no_information">
                  No Information Available
                </p>
              )}

              <SubsectionFileUpload
                section_id={detailsData?.section_id}
                cardId={cardId}
                subsectionId={subSection?.subsection_id}
                textDataId={
                  subSection?.text_data[0]?.text_data_id
                    ? subSection?.text_data[0]?.text_data_id
                    : null
                }
                setEditedTextData={setEditedTextData}
                editedTextData={editedTextData}
                isEditDatasetSection={isEditDatasetSection}
              />
            </>
          );
        }

        if (subSection.title === "Code Analysis") {
          return (
            <>
              <p className="sub_title">{subSection.title}</p>

              {subSection.text_data && subSection.text_data.length > 0 ? (
                isLoading && <CircularProgress />
              ) : (
                <p className="sub_desc_no_information">
                  No Information Available
                </p>
              )}

              {codeAnalysisResponse &&
                codeAnalysisResponse.length > 0 &&
                codeAnalysisResponse.map((textData) => {
                  return (
                    <>
                      {textData?.chart_type === "Dependencies" ? (
                        <div>
                          <p
                            className="sub_desc sub_desc_text_data"
                            style={{ marginBottom: "5px" }}
                          >
                            {textData?.chart_type}
                          </p>
                          {isLoading ? (
                            <Box
                              id="sageMakerLoadingBox"
                              sx={{
                                display: "flex",
                                position: "absolute ",
                                marginTop: "20%",
                                marginLeft: "50%",
                              }}
                            >
                              <CircularProgress />
                            </Box>
                          ) : (
                            <>
                              <Paper sx={{ width: "100%", overflow: "hidden" }}>
                                <TableContainer sx={{ maxHeight: 440 }}>
                                  <Table stickyHeader aria-label="sticky table">
                                    <TableHead>
                                      <TableRow
                                        style={{ background: "#DDE6EA" }}
                                      >
                                        <TableCell
                                          key="package"
                                          style={{
                                            minWidth: "100",
                                            backgroundColor: "#dde6ea",
                                          }}
                                        >
                                          Package
                                        </TableCell>
                                        <TableCell
                                          key="module"
                                          style={{
                                            minWidth: "100",
                                            backgroundColor: "#dde6ea",
                                          }}
                                        >
                                          Module
                                        </TableCell>
                                      </TableRow>
                                    </TableHead>
                                    <TableBody>
                                      {tRows
                                        .slice(
                                          page * rowsPerPage,
                                          page * rowsPerPage + rowsPerPage
                                        )
                                        .map((row) => (
                                          <TableRow
                                            key={row.name}
                                            sx={{
                                              "&:last-child td, &:last-child th":
                                                {
                                                  border: 0,
                                                },
                                            }}
                                          >
                                            <TableCell
                                              align="left"
                                              component="th"
                                              scope="row"
                                            >
                                              {row.PackageName}
                                            </TableCell>
                                            <TableCell align="left">
                                              {row.ImportedModule}
                                            </TableCell>
                                          </TableRow>
                                        ))}
                                    </TableBody>
                                  </Table>
                                </TableContainer>
                                <TablePagination
                                  rowsPerPageOptions={[5]}
                                  component="div"
                                  count={tRows.length}
                                  rowsPerPage={rowsPerPage}
                                  page={page}
                                  onPageChange={handleChangePage}
                                  onRowsPerPageChange={handleChangeRowsPerPage}
                                />
                              </Paper>
                              <br></br>
                            </>
                          )}
                        </div>
                      ) : (
                        <div>
                          <p
                            className="sub_desc sub_desc_text_data"
                            style={{ marginBottom: "5px" }}
                          >
                            {textData?.chart_type}
                          </p>
                          <div
                            style={{
                              maxWidth: "100%",
                              border: "0.2px solid #6D8291",
                              marginBottom: "25px",
                            }}
                          >
                            <ZoomImage
                              image={textData?.chart_data_image}
                              id={"modalSubSectionsMainDiv"}
                            />
                          </div>
                        </div>
                      )}
                    </>
                  );
                })}
            </>
          );
        }
      })}
      {}
    </>
  );
};

export default DisplayDetailsInfo;
