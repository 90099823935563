import React, { useEffect, useState } from "react";
import { Popover, Tooltip, Typography } from "@mui/material";
import { useCopyToClipboard } from "react-use"; //React library to provide the hook for copying texts.
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./PopOver.css";
import { ColorIndexNames, MutationKeyName } from "../../constants/dataTypes";
import { analyticServices } from "../../services/analyticServices";
import WarningIconForCard from "../../assets/images/listview/WarningIconForCard.svg";

const PopOver = ({
  open,
  positionOfMenuboxOnRightClick,
  handleRightClickMenuClose,
  dataToNavigating,
  popoverContent,
  setShowCard,
  searchTextQuery,
  handleCallDatasetApi,
}) => {
  const [state, copyToClipboard] = useCopyToClipboard(); // using useCopyToClipboard hook for copying url on os clipboard
  const navigate = useNavigate();

  //Below function is to find wheather the selected node is app/model/dataTable/dataColoumn, and return the respective id
  const getId = (typeName) => {
    var id = null;
    if (typeName === ColorIndexNames.app) {
      return (id = MutationKeyName.app);
    } else if (typeName === ColorIndexNames.model) {
      return (id = MutationKeyName.model);
    } else if (typeName === ColorIndexNames.inputDataTable) {
      return (id = MutationKeyName.input_data_table);
    } else if (typeName === ColorIndexNames.inputDataColumn) {
      return (id = MutationKeyName.input_data_column);
    }
    return id;
  };
  const handlePopupMenu = (popupContent) => {
    switch (popupContent) {
      case "Share Details":
        if (
          window.location.pathname.split("/")[1] === "result" ||
          window.location.pathname.split("/")[1] === "compliance-result"
        ) {
          //Graph view page
          copyToClipboard(
            `${window.location.host}/detail/${dataToNavigating?.__typename}/${dataToNavigating?.route_id}`
          );
        } else {
          //Node details page
          var id = getId(dataToNavigating?.__typename);
          copyToClipboard(
            `${window.location.host}/detail/${dataToNavigating?.__typename}/${dataToNavigating[id]}`
          );
        }
        toast.success("Link copied");
        break;
      case "Open Card":
        // setShowCard(true);
        dataToNavigating !== null &&
          dataToNavigating.card !== null &&
          handleCallDatasetApi();
        break;
      // case "Show Impact":
      //   break;
      case "Show Details":
        navigate(
          `/detail/${dataToNavigating?.__typename}/${dataToNavigating?.route_id}`
        );
        analyticServices(
          `Selected Type : ${dataToNavigating?.__typename}/${dataToNavigating?.name} `
        );
        break;
      default:
        break;
    }
  };

  return (
    <Popover
      id="rightClickPopover"
      sx={{
        "& .MuiPopover-paper": {
          boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.25)",
          borderRadius: "4px",
          border: "0.5px solid #6D8291 ",
        },
      }}
      open={open}
      anchorEl={positionOfMenuboxOnRightClick}
      onClose={handleRightClickMenuClose}
      anchorOrigin={{
        vertical: positionOfMenuboxOnRightClick?.Y,
        horizontal: positionOfMenuboxOnRightClick?.X,
      }}
    >
      {popoverContent.map((content, index) => {
        let cardValue;

        cardValue = (
          <Typography
            id={`content${index}`}
            key={index}
            className={
              content === "Open Card" &&
              dataToNavigating !== null &&
              dataToNavigating.card == null
                ? "rightClickPopOverContentDisabled"
                : "rightClickPopOverContent"
            }
            onClick={() => {
              handlePopupMenu(content);
            }}
          >
            {content}

            {content === "Open Card" &&
              dataToNavigating !== null &&
              dataToNavigating.card !== null &&
              dataToNavigating.card?.latest_version !== null &&
              dataToNavigating.card?.template_version !== null &&
              dataToNavigating.card?.latest_version >
                dataToNavigating.card?.template_version && (
                <img
                  src={WarningIconForCard}
                  alt="WarningIconForCard"
                  // className="warning-icon-for-card"
                  style={{ width: "14px", margin: "5px 0px 0px 5px" }}
                />
              )}
          </Typography>
        );
        return <>{cardValue}</>;
      })}
    </Popover>
  );

  //   }
};

export default PopOver;
