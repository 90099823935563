import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  useGetAllPolicies,
  useGetCardTemplates,
} from "../../api/readApiFunctions";
import { entityNameConst, selectedTemplate } from "../../constants/dataTypes";
import "../../pages/card-template-page/CardTemplatePage.css";
import {
  Accordion,
  AccordionDetails,
  Checkbox,
  Typography,
  AccordionSummary,
  styled,
  Tooltip,
} from "@mui/material";
import {
  ArrowForwardIosSharp,
  Close,
  FilterAltOutlined,
} from "@mui/icons-material";
import EditIcon from "../../assets/images/detail/edit_annotation.svg";
import EditDisabledIcon from "../../assets/images/adminPanel/EditDisabledNew.svg";
import SectionTabs from "../modelCard/Tabs";
import CardTemplateSectionData from "../CardTemplateSectionData/CardTemplateSectionData";
import { useCreateCardTemplate } from "../../api/createApiFunctions";
import SimpleBackdrop from "../SimpleBackdrop/SimpleBackdrop";
import { toast } from "react-toastify";
import { cardTemplateDataTooltips } from "../../constants/tooltipConstants";
import { isAccessProvided } from "../../services/assetManagementService";
import {
  actionConstants,
  moduleConstants,
  subModuleConstants,
} from "../../constants/accessManagementConstants";
import { PolicyNameConst } from "../../constants/policyConstants";

const AccordionSummaryDrop = styled((props) => (
  <AccordionSummary
    expandIcon={<ArrowForwardIosSharp sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
}));

const CardTemplateData = ({
  selectedEntity,
  isEditActive,
  handleEditState,
  handleRefetch,
  importCSVFileSuccess,
  accessDataByRole,
}) => {
  const [templateData, setTemplateData] = useState(null);
  const [filteredPolicies, setFilteredPolicies] = useState([]);
  const [showPolicyDropdown, setShowPolicyDropdown] = useState(false);
  const [selectedTemplateStatus, setSelectedTemplateStatus] = useState(null);
  const [selectedSection, setSelectedSection] = useState({ title: "Details" });
  const [editedState, setEditedState] = useState([]);
  const [policyData, setPolicyData] = useState([]); //state to maintain policies list coming from database
  const [isMajorChange, setIsMajorChange] = useState(false);
  const [newQueAdded, setNewQueAdded] = useState(false);
  const [successToasterMessage, setSuccessToasterMessage] = useState("");
  const location = useLocation();

  const {
    mutate: addCardTemplate,
    data,
    isSuccess,
    isLoading,
  } = useCreateCardTemplate();

  // get card template data
  const {
    data: cardTemplateRes,
    isSuccess: isCardTemplateSuccess,
    isLoading: cardTemplateLoading,
    refetch,
  } = useGetCardTemplates(
    selectedEntity?.type,
    selectedEntity?.type === selectedTemplate[selectedEntity?.type]
  );
  // get policy data
  const {
    data: allPolicy,
    error: allPolicyError,
    isLoading: allPolicyLoading,
  } = useGetAllPolicies(
    "card_templates",
    "crud",
    location.pathname.split("/")[2] == "cardTemplate" &&
      location.pathname.split("/")[2] !== undefined &&
      accessDataByRole &&
      isAccessProvided(
        moduleConstants.card_templates,
        subModuleConstants.crud,
        null,
        "read",
        accessDataByRole
      )
      ? true
      : false
  );

  useEffect(() => {
    handleRefetch(refetch);
  }, [importCSVFileSuccess]);

  const selectTemplateData = () => {
    if (cardTemplateRes) {
      if (cardTemplateRes.length === 1) {
        // format the data to support SectionTabs component
        cardTemplateRes[0]?.template_question.map((templateSection) => {
          return templateSection;
        });

        setSelectedTemplateStatus(cardTemplateRes[0]?.status);
        setTemplateData(cardTemplateRes[0]);
      } else {
        cardTemplateRes.map((template) => {
          template?.template_question.map((templateSection) => {
            if (template.status === "draft") {
              setSelectedTemplateStatus(template.status);
              setTemplateData(template);
            }

            return templateSection;
          });
        });
      }
    }
  };

  useEffect(() => {
    selectTemplateData();
  }, [cardTemplateRes]);

  useEffect(() => {
    if (allPolicy) {
      const filteredRows = allPolicy?.data?.data?.score_type_config.filter(
        (row) => {
          return (
            row.score_name !== "Human Impact" &&
            row.score_name !== "Konfer Confidence" &&
            row.status.toUpperCase() === "PUBLISHED"
          );
        }
      );

      setPolicyData(filteredRows);
    }
  }, [allPolicy]);

  useEffect(() => {
    if (isSuccess) {
      toast.success(successToasterMessage);
      handleCancelClick();
      refetch();
    }
  }, [isSuccess]);

  const handleEditButtonClick = () => {
    handleEditState(true);
  };

  const setTemplateToggleClassName = (buttonType) => {
    let classes = "templateToggleBtnDisabled";

    if (buttonType === "draft") {
      if (cardTemplateRes?.length > 1) {
        classes = "templateToggleBtnClickEnabled";
      }
      if (selectedTemplateStatus === "draft") {
        classes = "templateToggleBtnEnabled";
      }
    }

    if (buttonType === "published") {
      if (cardTemplateRes?.length > 1) {
        classes = "templateToggleBtnClickEnabled";
      }
      if (selectedTemplateStatus === "published") {
        classes = "templateToggleBtnEnabled";
      }
    }

    return classes;
  };

  const handleTemplateToggleButton = (buttonType) => {
    if (buttonType === "draft" && cardTemplateRes?.length > 1) {
      setSelectedTemplateStatus("draft");
    }

    if (buttonType === "published") {
      setSelectedTemplateStatus("published");
    }
  };

  const handleSelectedSection = (selectedSectionValue) => {
    setSelectedSection(selectedSectionValue);
  };

  /*Below function is used to convert an array into string array format,
   Eg., ["boolean"]  --> "["boolean"]"*/
  const arrayToStringWithSingleQuotes = (arr) => {
    const stringifiedArray = arr.map((value) => `'${value}'`).join(",");
    return `[${stringifiedArray}]`;
  };

  const handleTemplateEdit = (sectionId, type, fieldName, value, id) => {
    let selectedSectionData;
    let isEdited = false;

    // there are other fields too which are not present in the array ,
    // please check setIsMajorChange occurances to understand where do major change occour.
    const majorChangeFields = [
      "is_required",
      "is_human_impact",
      "is_desired_response",
      "policy",
      "question_text",
    ];

    // check for major changes
    if (majorChangeFields.includes(fieldName)) {
      setIsMajorChange(true);
    }

    const checkBooleanMajorChange = (prevValue, newValue) => {
      let isMajorChange = false;

      // handle Non-Boolean --> Boolean
      // check if the prevValue does not includes boolean and newValue includes boolean
      if (!prevValue.includes("boolean") && newValue.includes("boolean")) {
        isMajorChange = true;
      }
      // handle Boolean --> Non-Boolean
      // check if the prevValue  includes boolean and newValue does not includes boolean
      if (prevValue.includes("boolean") && !newValue.includes("boolean")) {
        isMajorChange = true;
      }
      return isMajorChange;
    };

    // check if the subsection with the same id exits in edited state.
    const editedSectionData = editedState.filter((edited) => {
      if (edited.section_id === sectionId) {
        return edited;
      }
    });

    if (editedSectionData.length > 0) {
      isEdited = true;
      selectedSectionData = editedSectionData;
    } else {
      // else get section data from the template and update the value
      const sectionData = templateData.template_question.filter(
        (sectionDataVal) => {
          if (sectionDataVal.section_id === sectionId) {
            return sectionDataVal;
          }
        }
      );

      selectedSectionData = sectionData;
      isEdited = false;
    }

    // perform the value updatations
    if (type === "question") {
      selectedSectionData[0]?.questions.map((que) => {
        if (que.template_question_id === id) {
          if (fieldName === "answer_type") {
            if (checkBooleanMajorChange(que["answer_type"], value)) {
              setIsMajorChange(true);
            }

            // if value does not include boolean then deslect Humam Impact ,mandatory and policy
            if (!value.includes("boolean")) {
              que["is_required"] = false;
              que["is_human_impact"] = false;
              que["policy"] = null;
            }

            // anywer type cannot be empty
            if (value.length === 0) {
              value = arrayToStringWithSingleQuotes(["boolean"]);
            }
          }

          if (fieldName === "answer_type") {
            que[fieldName] = arrayToStringWithSingleQuotes(value);
          } else {
            que[fieldName] = value;
          }
        }
      });
    }

    if (type === "questionDelete") {
      const filteredQue = selectedSectionData[0].questions.filter((que) => {
        if (que.template_question_id !== id) {
          return que;
        }
      });

      selectedSectionData[0].questions = filteredQue;

      setIsMajorChange(true);
    }

    if (type === "sectionDescription") {
      selectedSectionData[0].description = value;
    }

    if (type === "subsectionDescription") {
      selectedSectionData[0].subsections.map((subsection) => {
        if (subsection.subsection_id === id) {
          subsection.description = value;
        }
      });
    }

    // create new question
    if (type === "createQuestion") {
      const data = {
        question_text: "",
        is_required: true,
        is_visible: true,
        answer_type: arrayToStringWithSingleQuotes(["boolean"]),
        template_question_id: value,
        is_desired_response: true,
        // for now we have added this default value for is_human_impact and policy
        is_human_impact: false,
        policy: null,
        sectionNames: selectedSectionData[0]?.title,
        section_id: selectedSectionData[0].section_id,
        template_type: selectedEntity.type,
      };

      selectedSectionData[0].questions.push(data);
      setNewQueAdded(true);
      setIsMajorChange(true);
    }

    // update the new state based
    //check here
    if (isEdited) {
      setEditedState((prevState) => {
        const updatedState = [...prevState];
        const index = updatedState.findIndex(
          (section) => section.section_id === selectedSectionData[0].section_id
        );

        if (index !== -1) {
          updatedState[index] = selectedSectionData[0];
        }

        return updatedState;
      });
    } else {
      setEditedState((prevState) => [...prevState, ...selectedSectionData]);
    }
  };

  const handleCancelClick = () => {
    handleEditState(false);
    setEditedState([]);
    setIsMajorChange(false);
    refetch();
  };

  const formatCardTemplateData = (templateData, editedData) => {
    const finalTemplateQue = [];

    const templateQuestions = templateData?.template_question;

    for (let i = 0; i < templateQuestions.length; i++) {
      const section = templateQuestions[i];
      const editedSectionData = editedData.filter((sec) => {
        if (section.title === sec.title) {
          return sec;
        }
      });

      if (editedSectionData.length > 0) {
        finalTemplateQue.push(editedSectionData[0]);
      } else {
        finalTemplateQue.push(section);
      }
    }

    return finalTemplateQue;
  };

  const updateTemplatePayload = () => {
    const questionsData = formatCardTemplateData(templateData, editedState);
    const payload = {
      major_change_flag: isMajorChange === true ? "true" : "false",
      status: selectedTemplateStatus,
      template_question: questionsData,
      type: selectedEntity.type,
    };
    return payload;
  };
  const handleSaveChangesClick = () => {
    if (editedState.length > 0) {
      setSuccessToasterMessage("Changes Saved");
      const data = updateTemplatePayload();
      addCardTemplate(data);
    }
  };

  const handlePublishClick = () => {
    setSuccessToasterMessage("Template Published");
    const data = updateTemplatePayload();
    data.status = "published";
    addCardTemplate(data);
  };

  const handleSaveDraftClick = () => {
    if (editedState.length > 0) {
      setSuccessToasterMessage("Template Saved");
      const data = updateTemplatePayload();
      data.status = "draft";
      addCardTemplate(data);
    }
  };

  const handleEditButtonVisibility = () => {
    let showEdit = false;
    if (cardTemplateRes?.length > 1) {
      if (selectedTemplateStatus === "draft") {
        showEdit = true;
      }
    } else {
      if (selectedTemplateStatus === "published") {
        showEdit = true;
      }
    }
    return showEdit;
  };

  useEffect(() => {
    setSelectedSection({ title: "Details" });
    setFilteredPolicies([]);
  }, [selectedEntity]);

  const handleNewQueAdd = (value) => {
    setNewQueAdded(value);
  };

  const handleFilterPolicyOnEdit = (value) => {
    setFilteredPolicies(value);
    setShowPolicyDropdown(false);
  };

  const handleTooltipForCardTemplateData = (tooltipType) => {
    let tooltipText = "";
    switch (tooltipType) {
      case "filterButton":
        tooltipText = cardTemplateDataTooltips.filterButton;
        if (isEditActive) {
          tooltipText = cardTemplateDataTooltips.filterInEditMode;
        }
        break;
      case "editButton":
        if (!isEditActive) {
          tooltipText = cardTemplateDataTooltips.editButton;
        }
        break;
      case "draftButton":
        tooltipText = cardTemplateDataTooltips.draftButton;
        if (cardTemplateRes?.length === 1) {
          tooltipText = cardTemplateDataTooltips.noDraft;
        }
        break;
      case "publishedButton":
        tooltipText = cardTemplateDataTooltips.publishedButton;
        break;
      case "saveDraftButton":
        tooltipText = cardTemplateDataTooltips.saveDraftButton;
        if (!isMajorChange) {
          tooltipText = cardTemplateDataTooltips.saveButtonNoMajorChange;
        }
        break;
      case "publishButton":
        tooltipText = cardTemplateDataTooltips.publishButton;
        break;
      case "saveChangeButton":
        tooltipText = cardTemplateDataTooltips.saveChangeButton;
        if (editedState.length <= 0) {
          tooltipText = cardTemplateDataTooltips.saveChangeNoChange;
        }
        break;
      case "cancelButton":
        tooltipText = cardTemplateDataTooltips.cancelButton;
        break;
      default:
        tooltipText = "";
        break;
    }

    return tooltipText;
  };

  return (
    <div
      className={"cardTemplate_sidebar"}
      style={{
        paddingLeft: "20px",
        paddingTop: "1px ",
        height: "71%",
      }}
      id="adminRiskFactorTable"
    >
      {templateData && (
        <div className="templateVersionTitle">
          <p className="templateVersionTitleLabel">
            {selectedEntity.name === "Applications"
              ? `${entityNameConst.applications} `
              : selectedEntity.name === "Models"
              ? `${entityNameConst.models} `
              : `${entityNameConst.datasets} `}
            {/* {selectedEntity?.name}  */}
            Card Template
            <span
              style={{
                color: "#58595b",
                fontSize: "18px",
                fontWeight: "normal",
                marginLeft: "10px",
              }}
            >
              {`v ${templateData.template_version}`}
            </span>
            <span className="templateVersionTitleVersion">
              {templateData.created_at.split("T")[0]}
            </span>
          </p>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Tooltip
              placement="top"
              title={handleTooltipForCardTemplateData("filterButton")}
              arrow
            >
              <div
                className="icon_background "
                style={{
                  height: "21px",
                  marginTop: "-3px",
                  color: isEditActive ? "#bcc1c2" : "",
                  background: filteredPolicies?.length > 0 ? "#BEC9D1" : "",
                }}
                onClick={() => {
                  setShowPolicyDropdown(!showPolicyDropdown);
                }}
              >
                <FilterAltOutlined sx={{ marginBottom: "-5px" }} />
              </div>
            </Tooltip>
            {policyData && showPolicyDropdown && !isEditActive && (
              <Accordion
                className="filterBox"
                sx={{
                  position: "absolute",
                  top: 110,
                  right: 60,
                  zIndex: 999,
                }}
                defaultExpanded={true}
              >
                <AccordionSummaryDrop
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  sx={{
                    background: "#F6F6F6",
                  }}
                >
                  <Typography sx={{ marginLeft: "16px" }}>
                    {PolicyNameConst.policy}
                  </Typography>
                  <Close
                    sx={{
                      marginLeft: "245px",
                      width: "18px",
                      marginTop: "-10px",
                    }}
                    onClick={() => {
                      setShowPolicyDropdown(false);
                    }}
                  />
                </AccordionSummaryDrop>
                <AccordionDetails>
                  <Typography sx={{ marginTop: "-20px", marginLeft: "-10px" }}>
                    {policyData.map((policy) => {
                      if (policy[`is_applies_to_${selectedEntity.type}`]) {
                        return (
                          <div
                            className="policyFilterName"
                            key={policy.score_name}
                          >
                            <Checkbox
                              // checked={filterCheckBox(policy)}
                              checked={
                                filteredPolicies.indexOf(policy.score_name) > -1
                              }
                              onClick={() => {
                                setFilteredPolicies((prevState) => {
                                  if (!prevState.includes(policy.score_name)) {
                                    return [...prevState, policy.score_name];
                                  } else {
                                    return prevState.filter(
                                      (pol) => pol !== policy.score_name
                                    );
                                  }
                                });
                              }}
                              color="default"
                            />
                            {policy.score_name}
                          </div>
                        );
                      }
                    })}
                  </Typography>
                </AccordionDetails>
              </Accordion>
            )}

            {isAccessProvided(
              moduleConstants.card_templates,
              subModuleConstants.crud,
              null,
              "update",
              accessDataByRole
            ) === true &&
              handleEditButtonVisibility() && (
                <Tooltip
                  placement="top"
                  title={handleTooltipForCardTemplateData("editButton")}
                  arrow
                >
                  <img
                    className="icon_background"
                    style={{
                      width: "20px",
                      height: "20px",
                      marginTop: "-2px",
                    }}
                    onClick={handleEditButtonClick}
                    src={isEditActive ? EditDisabledIcon : EditIcon}
                    alt="edit icon"
                  />
                </Tooltip>
              )}

            {!isEditActive && (
              <Tooltip
                placement="top"
                title={handleTooltipForCardTemplateData("templateStatus")}
                arrow
              >
                <p
                  className={"publishStatusBtn"}
                  style={{
                    backgroundColor: "white",
                    width: "173px",
                    border: "1px solid #6D8291",
                    borderRadius: "20px",
                    padding: "3px",
                  }}
                >
                  <>
                    <Tooltip
                      placement="top"
                      title={handleTooltipForCardTemplateData("draftButton")}
                      arrow
                    >
                      <button
                        onClick={() => {
                          handleTemplateToggleButton("draft");
                          cardTemplateRes?.map((template) => {
                            if (template.status === "draft") {
                              setTemplateData(template);
                            }
                          });
                        }}
                        className={setTemplateToggleClassName("draft")}
                      >
                        Draft
                      </button>
                    </Tooltip>
                    <Tooltip
                      placement="top"
                      title={handleTooltipForCardTemplateData(
                        "publishedButton"
                      )}
                      arrow
                    >
                      <button
                        onClick={() => {
                          handleTemplateToggleButton("published");
                          cardTemplateRes?.map((template) => {
                            if (template.status === "published") {
                              setTemplateData(template);
                            }
                          });
                        }}
                        className={setTemplateToggleClassName("published")}
                      >
                        Published
                      </button>
                    </Tooltip>
                  </>
                </p>
              </Tooltip>
            )}
          </div>
        </div>
      )}

      {!isEditActive && filteredPolicies.length > 0 && (
        <div
          style={{
            display: "inline-block",
            alignItems: "center",
            textAlign: "center",
            marginTop: "-20px",
            fontSize: "14px",
            background: "#E7EDF0",
            borderRadius: "30px",
            paddingLeft: "10px",
            paddingRight: "10px",
            marginBottom: "10px",
            color: "#3F5667",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              height: "28px",
            }}
          >
            {PolicyNameConst.policy}:
            {filteredPolicies?.map((val, index) => {
              return (
                <p
                  style={{
                    marginLeft: "8px",
                  }}
                  key={index}
                >
                  {val}
                  {filteredPolicies?.length - 1 !== index ? "," : ""}
                </p>
              );
            })}
          </div>
        </div>
      )}

      {templateData?.template_question && (
        <div className="sections_container" id="modalSectionsContainer">
          <div className="sections" id="modalSectionsDiv">
            <SectionTabs
              sectionData={templateData.template_question}
              setSelectedSection={handleSelectedSection}
              selectedSection={selectedSection}
              handleSelectedSection={handleSelectedSection}
            />
          </div>
        </div>
      )}

      {templateData?.template_question && (
        <CardTemplateSectionData
          sectionData={selectedSection}
          selectedEntity={selectedEntity}
          isEditActive={isEditActive}
          policyData={policyData}
          handleTemplateEdit={handleTemplateEdit}
          filteredPolicies={filteredPolicies}
          newQueAdded={newQueAdded}
          handleNewQueAdd={handleNewQueAdd}
          handleFilterPolicyOnEdit={handleFilterPolicyOnEdit}
          editedState={editedState.filter((editSec) => {
            if (editSec.title === selectedSection.title) {
              return editSec;
            }
          })}
          accessDataByRole={accessDataByRole}
        />
      )}

      {isEditActive && (
        <div className={"templateCardBtn"}>
          {isAccessProvided(
            moduleConstants.card_templates,
            subModuleConstants.card_template_view,
            actionConstants.draft,
            "execution",
            accessDataByRole
          ) === true && (
            <Tooltip
              placement="top"
              title={handleTooltipForCardTemplateData("saveDraftButton")}
              arrow
            >
              <div
                className={
                  editedState.length > 0
                    ? "templatePublishBtn"
                    : "templatePublishBtnHide"
                }
                onClick={handleSaveDraftClick}
              >
                Save Draft
              </div>
            </Tooltip>
          )}

          {isAccessProvided(
            moduleConstants.card_templates,
            subModuleConstants.card_template_view,
            actionConstants.publish,
            "execution",
            accessDataByRole
          ) === true &&
            (isMajorChange || cardTemplateRes?.length > 1) && (
              <Tooltip
                placement="top"
                title={handleTooltipForCardTemplateData("publishButton")}
                arrow
              >
                <div
                  className={"templatePublishBtn"}
                  onClick={handlePublishClick}
                >
                  Publish
                </div>
              </Tooltip>
            )}

          {isAccessProvided(
            moduleConstants.card_templates,
            subModuleConstants.card_template_view,
            actionConstants.publish,
            "execution",
            accessDataByRole
          ) === true &&
            !isMajorChange &&
            cardTemplateRes?.length <= 1 && (
              <Tooltip
                placement="top"
                title={handleTooltipForCardTemplateData("saveChangeButton")}
                arrow
              >
                <div
                  className={
                    editedState.length > 0
                      ? "templatePublishBtn"
                      : "templatePublishBtnHide"
                  }
                  onClick={handleSaveChangesClick}
                >
                  Save Changes
                </div>
              </Tooltip>
            )}

          <Tooltip
            placement="top"
            title={handleTooltipForCardTemplateData("cancelButton")}
            arrow
          >
            <div
              className="templateBtn"
              style={{ cursor: "pointer" }}
              onClick={handleCancelClick}
            >
              Cancel
            </div>
          </Tooltip>
        </div>
      )}

      <SimpleBackdrop openStat={isLoading || cardTemplateLoading} />
    </div>
  );
};

export default CardTemplateData;
