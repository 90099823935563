export const updateRiskFactorVisibilityQuery = (id, fieldName, value) => {
  const query = `mutation {
        update_risk_factors(where: {risk_factors_id: {_eq: ${id}}}, _set: {${fieldName}: ${value}}) {
          returning {
            is_app
            is_app_visible
            is_input_data_table
          }
        }
      }
      `;

  return query;
};

export const createDatasetQueAnswer = (
  subsection_id,
  answer_type,
  question,
  text,
  card_question_id
) => {
  const query = `mutation {
  insert_questions(objects: {subsection_id: ${subsection_id}, answer_type: ${answer_type}, question: ${question}, text: ${text}, card_question_id: ${card_question_id}}) {
    returning {
      answer_type
      card_question_id
      is_visible
      question
      subsection_id
      text
    }
  }
}`;

  return query;
};
export const updateCardAnswerQuery = (id) => {
  const query = `mutation updateDatacard($input: questions_set_input){
  update_questions(_set: $input,where: {card_question_id: {_eq: ${id}}}) {
    returning {
      answer_type
      card_question_id
      is_visible
      question
      text
    }
  }
}`;

  return query;
};

/* Below query used to update text data by using text data id */
export const updateSubsectionTextDataQuery = (textDataId) => {
  const query = `mutation ($input: text_data_set_input) {
      update_text_data(_set: $input, where: {text_data_id: {_eq: ${textDataId}}}) {
        returning {
          text_data_id
          text_data
          section_id
          subsection_id
          card_id
        }
      }
    }  
  `;

  return query;
};

/* Below query used to insert text data */
export const createSubsectionTextDataQuery = () => {
  const query = `mutation ($input: [text_data_insert_input!]!) {
    insert_text_data(objects: $input) {
        returning {
          text_data_id
          text_data
          section_id
          subsection_id
          card_id
        }
      }
    }
  `;

  return query;
};

export const deleteBusinessunit = (business_unit_id) => {
  const query = `
  mutation {
    delete_business_unit(where: {business_unit_id: {_eq: ${business_unit_id}}}) {
      returning {
        business_unit_id
        business_unit_type
        description
        name
      }
    }
  }  
    `;

  return query;
};

export const updateRegion = (id) => {
  const query = `
mutation createRegion($input: region_set_input!) {
	  update_region(where: {region_id: {_eq: ${id}}}, _set: $input) {
		returning {
		  description
		  name
		}
	  }
	}

  `;
  return query;
};
