import React, { useLayoutEffect } from "react";

import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import "../../pages/observability/ObservabilityPage.css"

function NodeLineChart({ data }) {
  const unqId = Math.floor(Math.random() * 800000);

  // render chart
  function createAxisAndSeries(name, data, root, chart, xAxis) {
    let yRenderer = am5xy.AxisRendererY.new(root, {
      opposite: false,
    });

    let yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        maxDeviation: 1,
        renderer: yRenderer,
      })
    );

    if (chart.yAxes.indexOf(yAxis) > 0) {
      yAxis.set("syncWithAxis", chart.yAxes.getIndex(0));
    }

    // Add series
    // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
    let series = chart.series.push(
      am5xy.LineSeries.new(root, {
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: "value",
        valueXField: "date",
        name,
        tooltip: am5.Tooltip.new(root, {
          pointerOrientation: "horizontal",
          labelText: "{valueY}",
        }),
      })
    );

    //series.fills.template.setAll({ fillOpacity: 0.2, visible: true });
    series.strokes.template.setAll({ strokeWidth: 1 });

    yRenderer.grid.template.set("strokeOpacity", 0.05);
    yRenderer.labels.template.set("fill", series.get("fill"));
    yRenderer.setAll({
      stroke: series.get("fill"),
      strokeOpacity: 1,
      opacity: 1,
    });

    // Set up data processor to parse string dates
    // https://www.amcharts.com/docs/v5/concepts/data/#Pre_processing_data
    series.data.processor = am5.DataProcessor.new(root, {
      dateFormat: "yyyy-MM-dd HH:mm:ss",
      dateFields: ["date"],
    });

    series.data.setAll(data);

    // this below event tells us weather the series is visible or not
    series.on("visible", function (visible, target) {
      // hence when the value of visible is true
      // we make the axis visible also
      if (visible) {
        yAxis.set("visible", true);
      } else {
        // hence when the value of visible is false
        // we hide the axis

        let isVisible = true;
        chart.series.each(function (seriesData) {
          if (seriesData === target) {
            isVisible = false;
          }

          yAxis.set("visible", isVisible);
        });
      }
    });
  }

  useLayoutEffect(() => {
    if (data && data?.observabilities?.length > 0) {
      let root = am5.Root.new(`${unqId}`);

      root.setThemes([am5themes_Animated.new(root)]);

      let chart = root.container.children.push(
        am5xy.XYChart.new(root, {
          focusable: true,
          panX: true,
          panY: true,
          wheelX: "panX",
          wheelY: "zoomX",
          pinchZoomX: true,
          layout: root.verticalLayout,
        })
      );

      chart.set(
        "scrollbarX",
        am5.Scrollbar.new(root, {
          orientation: "horizontal",
        })
      );

      let easing = am5.ease.linear;
      chart.get("colors").set("step", 3);

      // Create axes
      // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
      let xAxis = chart.xAxes.push(
        am5xy.GaplessDateAxis.new(root, {
          maxDeviation: 0.1,
          groupData: false,
          baseInterval: {
            timeUnit: "second",
            count: 1,
          },
          renderer: am5xy.AxisRendererX.new(root, {}),
          tooltip: am5.Tooltip.new(root, {}),
        })
      );

      // Add cursor
      // https://www.amcharts.com/docs/v5/charts/xy-chart/cursor/
      let cursor = chart.set(
        "cursor",
        am5xy.XYCursor.new(root, {
          xAxis: xAxis,
          behavior: "none",
        })
      );
      cursor.lineY.set("visible", false);
      root.dateFormatter.setAll({
        dateFormat: "yyyy-MM-dd HH:mm:ss",
        dateFields: ["date"],
      });

      data.observabilities.map((value) => {
        createAxisAndSeries(value.key, value.data, root, chart, xAxis);
      });

      // show legend
      let legend = chart.children.push(am5.Legend.new(root, {}));

      legend.data.setAll(chart.series.values);

      // Make stuff animate on load
      chart.appear(1000, 100);

      return () => {
        root.dispose();
      };
    }
  }, [data, unqId]);

  return <div id={`${unqId}`} className="node_line_chart"></div>;
}
export default NodeLineChart;
