import request from "graphql-request";
import { useQuery } from "react-query";
import { useMutation, useQueryClient } from "react-query";
import { queryKeyConstants } from "../constants/basicConstants";
import { deleteDataSetCardQuery } from "../graphql/queries";
import nodeApiInstance from "./instance/nodeApiInstance";

const endpoint = `${process.env.REACT_APP_GRAPHQL_API}v1/graphql`;

export const useDeleteAnnotations = () => {
  const queryClient = useQueryClient();

  const deleteAnnotation = (payload) => {
    const { id, subModInvoker } = payload;
    const { data } = nodeApiInstance.delete("/annotation/delete-annotation", {
      params: {
        annotation_id: id,
        subModInvoker,
      },
    });

    return data;
  };

  return useMutation(deleteAnnotation, {
    onSuccess: () => {
      queryClient.invalidateQueries(queryKeyConstants.getAnnotation);
    },
  });
};

//Delete User API
export const useDeleteUser = () => {
  const queryClient = useQueryClient();

  const deleteUser = async (payload) => {
    const data = await nodeApiInstance.delete("/auth/delete-user", {
      data: payload,
    });
    return data;
  };

  return useMutation(deleteUser, {
    onSuccess: () => {
      queryClient.invalidateQueries(queryKeyConstants.userlist);
    },
  });
};

//delete region
export const useDeleteRegion = () => {
  const queryClient = useQueryClient();

  const deleteRegion = async (payload) => {
    const updatedData = {
      id: payload,
    };
    return nodeApiInstance.post("details/delete-region", updatedData);
  };

  return useMutation(deleteRegion, {
    onSuccess: () => {
      queryClient.invalidateQueries(queryKeyConstants.getRoleRegionBuData);
    },
  });
};

// API call for delete dataset card question and answer

export const useDeleteDataSetCardData = () => {
  const queryClient = useQueryClient();

  const deleteDataSetCardData = async (payload) => {
    const data = await request(endpoint, deleteDataSetCardQuery(payload));
    return data;
  };

  return useMutation(deleteDataSetCardData, {
    onSuccess: () => {
      queryClient.invalidateQueries(queryKeyConstants.modelCardData);
    },
  });
};

//CPO delete annotation useMutation
export const useDeleteCPOAnnotations = () => {
  const queryClient = useQueryClient();

  const deleteCPOAnnotation = (payload) => {
    const { id } = payload;

    const { data } = nodeApiInstance.delete("/annotation/delete-annotation", {
      params: {
        annotation_id: id,
        subModInvoker: "profile",
      },
    });

    return data;
  };

  return useMutation(deleteCPOAnnotation);
};

// delete Role API
export const useDeleteRole = () => {
  const queryClient = useQueryClient();
  const roleDeleteById = async (roleId) => {
    const data = await nodeApiInstance.post("/role/delete-role", {
      roles_id: roleId,
    });
    return data;
  };
  return useMutation(roleDeleteById, {
    onSuccess: () => {
      queryClient.invalidateQueries(queryKeyConstants.getAllRoles);
    },
  });
};

export const useDeleteFiles = () => {
  const queryClient = useQueryClient();

  const deleteFiles = async (payload) => {
    const data = await nodeApiInstance.delete("/card/delete-answer-files", {
      data: {
        deletedQuestionArray: payload,
      },
    });

    return data;
  };

  return useMutation(deleteFiles, {
    onSuccess: () => {
      queryClient.invalidateQueries(queryKeyConstants.riskfactors);
      queryClient.invalidateQueries(queryKeyConstants.appData);
      queryClient.invalidateQueries(queryKeyConstants.modelData);
      queryClient.invalidateQueries(queryKeyConstants.datasetData);
      queryClient.invalidateQueries(queryKeyConstants.modelCardData);
    },
  });
};

export const useDeleteDocumentFiles = () => {
  const deleteFiles = async (payload) => {
    const data = await nodeApiInstance.delete(
      "/card/delete-document-subsection-files",
      {
        data: {
          fileArray: payload,
        },
      }
    );

    return data?.data?.data;
  };

  return useMutation(deleteFiles);
};

//  Reset Database, as payload or input the function taking configurationType like aws/azure/generic
export const useResetDatabase = () => {
  const resetDatabase = async (payload) => {
    const { source, subModInvoker } = payload;
    const data = await nodeApiInstance.delete(
      `/details/reset-data?source=${source}&subModInvoker=${subModInvoker}`
    );
    return data;
  };

  return useMutation(resetDatabase, {
    onSuccess: () => {},
  });
};
