import React, { useState, useEffect } from "react";
import { CSVLink } from "react-csv";

import DownloadIcon from "../../assets/images/adminPanel/Download.svg";
import DownloadDisabledIcon from "../../assets/images/adminPanel/DownloadDisabled.svg";
import "./Cards.css";
import { PolicyNameConst } from "../../constants/policyConstants";

/**
 * Component : GenerateCSV
 *
 * Description : This component is responsible for showing the icon of generate csv.
 * While clicking , it downloads the csv for the current cards.
 * For generating csv, react-csv library is used
 *
 * Props : data - Actual response of a card getting from database,
           isEditDatasetSection - shows the current mode of card (edited/display),
 *  */

const GenerateCSV = ({ data, isEditDatasetSection }) => {
  const [csvData, setCsvData] = useState([]);
  const [csvFileName, setCSVFileName] = useState("SampleName.csv");
  const headers = [
    { label: "Section", key: "section" },
    { label: "Question Text", key: "question" },
    { label: "Mandatory", key: "is_required" },
    { label: "Human Impact", key: "human_impact" },
    { label: `${PolicyNameConst.policy}`, key: "policy_tag" },
    { label: "Answer Type", key: "answer_type" },
    { label: "Answer Text", key: "answer_field" },
    { label: "Template Question Id", key: "template_question_id" },
  ];

  /** below useEffect is used to save the data in the form of arrays of objects,
   * which is required by react-csv to display the data  */
  useEffect(() => {
    let dataArr = [];
    data?.sections?.length > 0 &&
      data?.sections?.map((sectionData) => {
        sectionData.title !== "Details" &&
          sectionData.subsections?.map((subSectionData, index) => {
            subSectionData.questions?.length > 0 &&
              subSectionData?.questions?.map((questionData) => {
                let dataArrObj = {};
                dataArrObj.section = sectionData.title; //Section name

                dataArrObj.question = questionData.question; //question text //`"${questionData.question}"`
                dataArrObj.is_required = questionData.is_required; //show mandatory
                dataArrObj.human_impact = questionData.human_impact; //show human impact

                //show policy tag
                dataArrObj.policy_tag =
                  questionData.policy_tag === "NULL" ||
                  questionData?.policy_tag !== ""
                    ? "-"
                    : questionData?.policy_tag;

                dataArrObj.answer_type = questionData.answer_type.toString(); //show ans type
                dataArrObj.answer_field = questionData.answer_field; //answer text
                // question temaplate id
                dataArrObj.template_question_id =
                  questionData.template_question_id;

                dataArr.push(dataArrObj);
              });
          });

        if (sectionData.title === "Details") {
          //Ex. if version is 10.1 then it is replaced by 10-1, to avoid "." extension
          let versionStr = sectionData?.entity?.hasOwnProperty("version")
            ? sectionData?.entity?.version.toString().includes(".")
              ? sectionData?.entity?.version.toString().replace(".", "-")
              : sectionData.entity?.version
            : "";

          //this is used to save the file name, which is combination of name/display name and version
          setCSVFileName(
            `${
              sectionData.entity?.display_name === null ||
              sectionData.entity?.display_name === ""
                ? sectionData.entity?.name
                : sectionData.entity?.display_name
            } ${versionStr}`
          );
        }
      });
    setCsvData(dataArr);
  }, [data]);

  return (
    <>
      {isEditDatasetSection ? (
        <img
          src={DownloadDisabledIcon}
          alt="Download Disabled"
          className="icon_background"
          style={{
            height: "20px",
            width: "20px",
            marginTop: "8px",
            marginLeft: "10px",
            color: "#bcc1c2",
          }}
        />
      ) : (
        <CSVLink
          data={csvData}
          headers={headers}
          filename={csvFileName}
          target="_blank"
        >
          <img
            src={DownloadIcon}
            alt="Download"
            className="icon_background"
            style={{
              height: "20px",
              width: "20px",
              marginTop: "10px",
              marginLeft: "10px",
            }}
          />
        </CSVLink>
      )}
    </>
  );
};

export default GenerateCSV;
