import React from "react";
import CFGraphviewBarChart from "../CFGraphviewBarChart/CFGraphviewBarChart";
import "./ConfidenceFactorsGraphview.css";
import { Confidence_Factor_Name } from "../../../constants/complianceConstants";

const ConfidenceFactorsGraphview = ({ riskFactorsSummaryData }) => {
  return (
    <div className="confidence-factors-graphview">
      {riskFactorsSummaryData !== undefined &&
        riskFactorsSummaryData?.map((factors, index) => {
          return (
            Confidence_Factor_Name.includes(factors.riskName) &&
            factors.riskName !== "Human Impact" && (
              <div className="graph-factors-list">
                <span>{factors.riskName.replace("-Human Impact", "")} : </span>
                <div style={{ margin: "0px 3px 3px 3px" }}>
                  <CFGraphviewBarChart factors={factors} />
                </div>
              </div>
            )
          );
        })}
    </div>
  );
};

export default ConfidenceFactorsGraphview;
