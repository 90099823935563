import React, { useState, useEffect } from "react";
import { useAtom } from "jotai";
import { useGetAllBusinessUnits } from "../../api/readApiFunctions";
import AdminUsetlistSearch from "../../components/AdminUsetlistSearch/AdminUsetlistSearch";
import Layout from "../../layout/Layout";
import NoResult from "../../components/NoResult/NoResult";
import CreateNewBusinessunit from "../../components/AdminBusinessunit/CreateNewBusinessUnit/CreateNewBusinessunit";
import BusinessunitTable from "../../components/AdminBusinessunit/BusinessunitTable/BusinessunitTable";
import { useLocation } from "react-router-dom";
import { accessDataByRoleAtom } from "../../atom/jotai";
import {
  moduleConstants,
  subModuleConstants,
} from "../../constants/accessManagementConstants";
import { isAccessProvided } from "../../services/assetManagementService";

const AdminBusinessUnitsPage = () => {
  //state for managing access management system golbal value
  const [accessDataByRole] = useAtom(accessDataByRoleAtom);

  const location = useLocation();
  // get all business units
  const {
    data: allBusinessUnits,
    error: allBusinessUnitsError,
    isLoading: allBusinessUnitsLoading,
  } = useGetAllBusinessUnits(
    location.pathname.split("/")[2] == "businessunit" &&
      location.pathname.split("/")[2] !== undefined &&
      accessDataByRole &&
      isAccessProvided(
        moduleConstants.admin_view,
        subModuleConstants.business_units,
        null,
        "read",
        accessDataByRole
      )
      ? true
      : false
  );

  const [businessUnitsData, setBusinessUnitsData] = useState([]); //state to maintain all users list coming from database
  const [openNewBusinessunitDialog, setOpenNewBusinessunitDialog] =
    useState(false); // to show a create new businessunit dialog

  useEffect(() => {
    setBusinessUnitsData(allBusinessUnits);
  }, [allBusinessUnits]);

  //Below useEffect is used for giving title to current page
  useEffect(() => {
    document.title = "Admin Businessunit Page";
  });

  return (
    <>
      <div className="userList-heading1" id="businessunitHeading">
        Business Units
      </div>
      {isAccessProvided(
        moduleConstants.admin_view,
        subModuleConstants.business_units,
        null,
        "read",
        accessDataByRole
      ) === true ? (
        <div className="userlist" id="businessunit">
          <div
            className="userlist-search-and-create paddingTRL30px"
            id="businessunitSearch"
          >
            <AdminUsetlistSearch
              allBusinessUnits={allBusinessUnits}
              businessUnitsData={businessUnitsData}
              setBusinessUnitsData={setBusinessUnitsData}
              isBusinessunit={true}
            />

            {isAccessProvided(
              moduleConstants.admin_view,
              subModuleConstants.business_units,
              null,
              "write",
              accessDataByRole
            ) === true && (
              <button
                id="createBusinessunit"
                className="userlist-createNewUser-button"
                onClick={() => {
                  setOpenNewBusinessunitDialog(true);
                }}
              >
                New Business Unit
              </button>
            )}
          </div>
          {businessUnitsData && businessUnitsData?.length === 0 ? (
            <div className="hgt userlist-users-table" id="businessunitNoResult">
              <NoResult title={"No Data Available"} />
            </div>
          ) : (
            <div className="userlist-users-table" id="businessunitTable">
              <BusinessunitTable
                businessUnitsData={businessUnitsData}
                accessDataByRole={accessDataByRole}
              />
            </div>
          )}
        </div>
      ) : (
        <div className="hgt userlist-users-table" id="noAccessToRoleManagment">
          {accessDataByRole && (
            <NoResult title={"No Access to Business units"} />
          )}
        </div>
      )}

      {openNewBusinessunitDialog && (
        <CreateNewBusinessunit
          openNewBusinessunitDialog={openNewBusinessunitDialog}
          setOpenNewBusinessunitDialog={setOpenNewBusinessunitDialog}
          accessDataByRole={accessDataByRole}
        />
      )}
    </>
  );
};

export default AdminBusinessUnitsPage;
