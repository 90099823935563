import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableFooter,
  TablePagination,
  Tooltip,
  tooltipClasses,
} from "@mui/material";
import {
  IconButton,
  Box,
  useTheme,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
} from "@mui/material";
import {
  FirstPage,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  LastPage,
} from "@mui/icons-material";
import request from "graphql-request";
import { useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";

import "../../AdminUsetlistUserTable/AdminUsetlistUserTable.css";
import Edit from "../../../assets/images/adminPanel/Edit.svg";
import Delete from "../../../assets/images/adminPanel/Delete.svg";
import CancelIcon from "../../../assets/images/adminPanel/CancelIcon.svg";
import UpdateNewRegion from "../UpdateNewRegion/UpdateNewRegion";
import "./RegionTable.css";
import styled from "@emotion/styled";
import { analyticServices } from "../../../services/analyticServices";
import {
  moduleConstants,
  subModuleConstants,
} from "../../../constants/accessManagementConstants";
import { isAccessProvided } from "../../../services/assetManagementService";

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#FFFFFF",
    color: "#666666",
    border: " 0.5px solid #6D8291",
    boxShadow: "0px 5px 10px rgba(0, 0, 0, 0.25)",
    borderRadius: "4px",
    fontSize: 16,
    fontFamily: "Lato",
    fontWeight: 400,
  },
}));
function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        id="buFirst"
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPage /> : <FirstPage />}
      </IconButton>
      <IconButton
        id="buBack"
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        id="buNext"
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        id="buLast"
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPage /> : <LastPage />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const RegionTable = ({
  regionData,
  setUpdateAdminRegion,
  setDeleteAdminRegion,
  accessDataByRole,
}) => {
  let a = window.innerHeight <= 760 ? 6 : window.innerHeight <= 900 ? 8 : 10;
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(a);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false); // to show a confirmation dialog to delete a businessunit
  const [openUpdateBusinessunitDialog, setOpenUpdateBusinessunitDialog] =
    useState(false); // update Businessunit dialog
  const [deleteBusinessunitID, setDeleteBusinessunitID] = useState(null);
  const [dataForEditbusinessunit, setDataForEditbusinessunit] = useState(null);
  const [deletRegion, setDeleteRegion] = useState();
  const [isBusinessunitPresent, setIsBusinessunitPresent] = useState();
  const queryClient = useQueryClient();

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - regionData?.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleDeleteConfirmationDialog = (ID, business_unit_present) => {
    setDeleteRegion(ID);
    setIsBusinessunitPresent(business_unit_present);
    setOpenDeleteDialog(true);
  };

  const handleDeleteBusinessunit = () => {
    setDeleteAdminRegion(deletRegion);
    toast.success("Region deleted") &&
      regionData.map((data) => {
        deletRegion === data.region_id &&
          analyticServices(` ${data.name}, region deleted`);
      });
  };

  const handleEditBusinessunit = (data) => {
    setOpenUpdateBusinessunitDialog(true);
    setDataForEditbusinessunit(data);
  };

  return (
    <div>
      <Table
        aria-label="simple table"
        sx={{ tableLayout: "fixed" }}
        id="buTable"
      >
        <TableHead>
          <TableRow className="usertable-row-headings">
            <TableCell className="usertable-coloumn-headings">
              Regions
            </TableCell>
            <TableCell
              className="usertable-coloumn-headings"
              sx={{ width: "60%" }}
            >
              Description
            </TableCell>
            {(isAccessProvided(
              moduleConstants.admin_view,
              subModuleConstants.regions,
              null,
              "update",
              accessDataByRole
            ) === true ||
              isAccessProvided(
                moduleConstants.admin_view,
                subModuleConstants.regions,
                null,
                "delete",
                accessDataByRole
              ) === true) && (
              <TableCell className="usertable-coloumn-headings"></TableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {regionData &&
            regionData?.length > 0 &&
            (rowsPerPage > 0
              ? regionData.slice(
                  page * rowsPerPage,
                  page * rowsPerPage + rowsPerPage
                )
              : regionData
            ).map((data, index) => (
              <>
                <TableRow
                  id={`buTableRow${index}`}
                  key={data.business_unit_id}
                  sx={{
                    "&:hover": {
                      cursor: "pointer",
                      backgroundColor: "var(--bacgroundColor) !important",
                    },
                  }}
                >
                  <LightTooltip title={data?.name}>
                    <TableCell className="usertable-coloumn text-ellipsis">
                      {data.name}
                    </TableCell>
                  </LightTooltip>
                  <TableCell className="usertable-coloumn">
                    {data.description.length === 0 ? "-" : data.description}
                  </TableCell>
                  {(isAccessProvided(
                    moduleConstants.admin_view,
                    subModuleConstants.regions,
                    null,
                    "update",
                    accessDataByRole
                  ) === true ||
                    isAccessProvided(
                      moduleConstants.admin_view,
                      subModuleConstants.regions,
                      null,
                      "delete",
                      accessDataByRole
                    ) === true) && (
                    <TableCell className="usertable-coloumn">
                      {isAccessProvided(
                        moduleConstants.admin_view,
                        subModuleConstants.regions,
                        null,
                        "update",
                        accessDataByRole
                      ) === true && (
                        <IconButton
                          id={`buEditIcon${index}`}
                          type="submit"
                          aria-label="Edit"
                          style={{ padding: "5px" }}
                          onClick={() => {
                            handleEditBusinessunit(data);
                          }}
                        >
                          <img
                            src={Edit}
                            alt="Edit"
                            style={{ width: "28px" }}
                          />
                        </IconButton>
                      )}

                      {isAccessProvided(
                        moduleConstants.admin_view,
                        subModuleConstants.regions,
                        null,
                        "delete",
                        accessDataByRole
                      ) === true && (
                        <IconButton
                          id={`buDeleteIconIcon${index}`}
                          type="submit"
                          aria-label="Delete"
                          style={{ padding: "5px" }}
                          onClick={() => {
                            handleDeleteConfirmationDialog(
                              data.region_id,
                              data.business_unit.length
                            );
                          }}
                        >
                          <img
                            src={Delete}
                            alt="Delete"
                            style={{ width: "28px" }}
                          />
                        </IconButton>
                      )}
                    </TableCell>
                  )}
                </TableRow>
              </>
            ))}
        </TableBody>
        <TableFooter>
          <TableRow
            sx={{
              "&:last-child td, &:last-child th": {
                border: 0,
                paddingRight: "50px",
              },
            }}
          >
            <TablePagination
              rowsPerPageOptions={[a]}
              colSpan={2}
              count={regionData?.length || 0}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />
          </TableRow>
        </TableFooter>
      </Table>

      <Dialog
        id="buDeleteDialog"
        open={openDeleteDialog}
        // onClose={() => {
        //   setOpenDeleteDialog(!openDeleteDialog);
        // }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <IconButton
            id="buCloseDeleteDialog"
            aria-label="close"
            onClick={() => {
              setOpenDeleteDialog(false);
            }}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <img src={CancelIcon} alt="Delete" />
          </IconButton>
          <p
            className={
              isBusinessunitPresent > 0
                ? "regionDeleteMsg"
                : "regionDeleteMsgAllow"
            }
          >
            {isBusinessunitPresent > 0
              ? "This region is associated with one or more business units. Delete all associated business units before deleting this region."
              : "Are you sure you want to delete this region?"}
          </p>
        </DialogTitle>

        {isBusinessunitPresent > 0 ? (
          <DialogActions>
            <Button
              id="buCancelDeleteDialog"
              value="Cancel"
              onClick={() => {
                setOpenDeleteDialog(false);
              }}
              // autoFocus
              className="regionDeleteMsgOkBtn"
            >
              <p className="regionDeleteMsgOkBtntext">okay</p>
            </Button>
          </DialogActions>
        ) : (
          <DialogActions>
            <Button
              id="buCancelDeleteDialog"
              className="cancel-deleteUser-dialog"
              value="Cancel"
              onClick={() => {
                setOpenDeleteDialog(false);
              }}
              autoFocus
            >
              <span className="deleteUser-dialog-title">Cancel</span>
            </Button>
            <Button
              id="buConfrimDelete"
              className="delete-deleteUser-dialog"
              value="Delete"
              onClick={() => {
                handleDeleteBusinessunit();
                setOpenDeleteDialog(false);
              }}
            >
              <span className="deleteUser-dialog-title">Delete</span>
            </Button>
          </DialogActions>
        )}
      </Dialog>

      {openUpdateBusinessunitDialog && (
        <UpdateNewRegion
          openUpdateBusinessunitDialog={openUpdateBusinessunitDialog}
          setOpenUpdateBusinessunitDialog={setOpenUpdateBusinessunitDialog}
          dataForEditbusinessunit={dataForEditbusinessunit}
          setUpdateAdminRegion={setUpdateAdminRegion}
        />
      )}
    </div>
  );
};

export default RegionTable;
