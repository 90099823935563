import React, { useEffect, useState } from "react";
import "./Feedback.css";
import feedbackIcon from "../../assets/images/icons/feedback-button-icon.svg";
import { Modal, Popover, Tooltip } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useAtom } from "jotai";
import { authUser } from "../../atom/jotai";
import { useCreateNewFeedback } from "../../api/createApiFunctions";
import { toast, ToastContainer } from "react-toastify";
import { Box } from "@mui/system";
import { useLocation } from "react-router-dom";
import feedBackIcon from "../../assets/images/sidebar/feedBackIcon.svg";

const Feedback = ({ isFeedBack, setIsFeedBack, test }) => {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "33%",
    height: "33%",
    bgcolor: "#ffffff",
    boxShadow: 24,
    p: 4,
    padding: "15px",
    borderRadius: "8px",
  };
  const [showForm, setShowForm] = useState(false);

  const [feedback, setFeedback] = useState("");
  const [user] = useAtom(authUser);
  const location = useLocation();
  const homeLocation = location.pathname;

  const {
    mutate: createFeedback,
    isSuccess,
    isError,
    data,
  } = useCreateNewFeedback();

  const handleButtonClick = () => {
    setShowForm(true);
  };

  const handleFormCancel = () => {
    setShowForm(false);
    // setTest(false);
    setIsFeedBack(false);
    setFeedback("");
    test(false);
  };

  const handleFeedbackSubmit = () => {
    if (feedback) {
      const payload = {
        page: window.location.href,
        feedback,
      };

      createFeedback(payload);
    }
    setFeedback("");
  };

  const handleFeedbackInput = (e) => {
    setFeedback(e.target.value);
  };

  if (isError) {
    toast.error("Feedback submission failed, please try again");
  }

  useEffect(() => {
    if (data && data.data.success === true) {
      setFeedback("");
      toast.success("Feedback submitted");
      setShowForm(false);
    }
  }, [data]);

  return (
    <div className="feedback__container" id="feedbackContainerDiv">
      <div
        onClick={() => {
          setShowForm(true);
        }}
        className={homeLocation !== "/" && "homePageFeedBack"}
      >
        {homeLocation == "/" && <img src={feedBackIcon} alt="BU Assignment" />}
      </div>

      <Modal
        id="modalCard"
        open={showForm || isFeedBack}
        onClose={showForm}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} id="modalCardBox">
          <div id="feedbackForm">
            <div
              className="crossbar"
              onClick={handleFormCancel}
              id="feedbackCloseFormIconDiv"
            >
              <CloseIcon
                id="feedbackCloseFormIcon"
                sx={{
                  fontSize: "24px",
                }}
              />
            </div>
            <div className="form__title">
              <h2>Share your Feedback</h2>
            </div>
            <div className="form_input_container" id="feedbackInputDiv">
              <textarea
                id="feedbackInput"
                // maxRows={4}
                onChange={handleFeedbackInput}
                // value={feedback}
                className={
                  feedback.length > 0 ? "form_input" : "form_input_Default"
                }
                placeholder="Enter your Query/Suggestion"
              />
            </div>
            <div className="form__buttons" id="feedbackButtonDiv">
              <button
                className="form_button"
                onClick={handleFormCancel}
                id="feedbackCancelButton"
              >
                Cancel
              </button>
              <button
                className="form_button_submit"
                onClick={handleFeedbackSubmit}
                id="feedbackSubmitButton"
              >
                Submit
              </button>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default Feedback;
