import React from "react";
import { useAtom } from "jotai";
import { Navigate, useLocation, useNavigate } from "react-router-dom";

// Custom imports
import { authTokens } from "../../atom/jotai";
import { isAccessProvided } from "../../services/assetManagementService";

const ProtectedRoute = ({
  children,
  accessDataByRole,
  module,
  subModule,
  action,
  accessType,
}) => {
  // jotai state handler
  const [tokens] = useAtom(authTokens);
  let location = useLocation();
  const navigate = useNavigate();

  if (!tokens) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  if (accessDataByRole) {
    if (
      !isAccessProvided(module, subModule, action, accessType, accessDataByRole)
    ) {
      navigate("/403-error");
    }
  }
  return children;
};

export default ProtectedRoute;
