import React from "react";
import "./NoResult.css";
import NoResultImg from "../../assets/images/status/NoResult.png";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import { IconButton } from "@mui/material";

const NoResult = ({ title, noImg, failedCase }) => {
  return (
    <div
      className={
        title === "No Data Available" ? "noResultContent1" : "noResultContent"
      }
      style={{
        marginTop: noImg && "-90px",
        marginBottom: noImg && "-60px",
      }}
      id="noResultDiv"
    >
      {noImg ? (
        <div className="resultTag" id="noResultTitle">
          {title ? title : "No Results to show"}
        </div>
      ) : failedCase ? (
        <>
          <IconButton>
            <CancelOutlinedIcon sx={{ fontSize: "40px", color: "red" }} />
          </IconButton>
          <div className="resultTag" id="noResultTitle">
            {title ? title : "No Results to show"}
          </div>
        </>
      ) : (
        <>
          <img
            src={NoResultImg}
            alt="noResult"
            className="NoresultImg"
            id="noResultImageTag"
          />
          <div className="resultTag" id="noResultTitle">
            {title ? title : "No Results to show"}
          </div>
        </>
      )}
    </div>
  );
};

export default NoResult;
