import React, { useEffect, useState, useCallback } from "react";
import { useParams } from "react-router-dom";
import { useGetSearchDetails } from "../../api/readApiFunctions";
import graphAppSvg from "../../assets/images/listview/app-chip.svg";
import graphDataSetSvg from "../../assets/images/adminPanel/Dataset.svg";
import graphModelSvg from "../../assets/images/listview/model-chip.svg";
import {
  KONFER_SCORE,
  queryKeyConstants,
} from "../../constants/basicConstants";
import { deslugifyText } from "../../utils/basicUtils";
import ControlSelector from "../ControlSelector/ControlSelector";
import GraphviewChart from "../GraphviewChart/GraphviewChart";
import PopOver from "../PopOver/PopOver";
import GraphviewInput from "../GraphviewInput/GraphviewInput";
import "./Graphview.css";
import { useAtom } from "jotai";
import {
  dataTypes,
  entityNameConst,
  typeNames,
} from "../../constants/dataTypes";
import { useCreateDatasetCardFromTemplate } from "../../api/createApiFunctions";
import WarningDialogForCard from "../WarningDialogForCard/WarningDialogForCard";
import Cards from "../Cards/CardsNew";
import { isAccessProvided } from "../../services/assetManagementService";
import { accessDataByRoleAtom } from "../../atom/jotai";

const Graphview = () => {
  // state for controlling Konfer Score click
  const [checkedKonferScore, setCheckedKonferScore] = useState(false);
  // state for controlling Show Label click
  const [checkedShowLabel, setCheckedShowLabel] = useState(true); // state for controlling Show Label click
  const [nodeLabelFont, setNodeLabelFont] = useState(16); //State For increasing or decreasing graph nodes label, initial font size is 16
  const [zoomInAndOut, setZoomInAndOut] = useState(1.0); //State for maintaining Zoom in and Zoom out effect ,Initial value sets to 1.0 (means 100%)
  const [chartHeight, setChartHeight] = useState(1);
  const [orphanNodes, setOrphanNodes] = useState(false);

  const limitForZoomInGraphView = 1.4; //150% zoom canvas
  const [positionOfMenuboxOnRightClick, setPositionOfMenuboxOnRightClick] =
    useState(null); //State for storing right clicked node position
  const open = Boolean(positionOfMenuboxOnRightClick);
  const [dataToNavigating, setDataForNavigating] = useState(null); //State for transferring data to node Right click popover
  const [idsState, setIdsState] = useState(null);
  const [unUsedNode, setUnUsedNode] = useState();
  const [unUsedNodeCount, setUnUsedNodeCount] = useState({});
  const [openWarningDialogForCard, setOpenWarningDialogForCard] =
    useState(false);
  const [callCreateCardAPI, setCallCreateCardAPI] = useState(false);
  const [count, setCount] = useState([]);
  const [unUsedCount, setUnusedCount] = useState({
    app: "",
    model: "",
    dataset: "",
  });

  const [nodeCount, setNodeCount] = useState({
    app: "",
    model: "",
    dataset: "",
  });

  // store the nodes count to show in the label
  const [dataCount, setDataCount] = useState({
    app: 0,
    model: 0,
    dataset: 0,
  });
  const [orphanDataCount, setOrphanDataCount] = useState({
    app: 0,
    model: 0,
    dataset: 0,
  });
  const [showCard, setShowCard] = useState(false);
  const [accessDataByRole, setAccessDataByRole] = useAtom(accessDataByRoleAtom);

  useEffect(() => {
    let countType = {};
    unUsedNode?.finalFormat1[0]?.children.map((typeName) => {
      countType[typeName.__typename] = countType.hasOwnProperty(
        typeName.__typename
      )
        ? countType[typeName.__typename] + 1
        : 1;
    });
    setUnUsedNodeCount(countType);
  }, [unUsedNode]);

  const { searchText } = useParams();

  let searchTextQuery;

  if (searchText === KONFER_SCORE) {
    searchTextQuery = "*";
  } else {
    searchTextQuery = deslugifyText(String(searchText));
  }
  const userSearchText = localStorage.getItem("userSearchText");

  // get search data
  const { data, error, isLoading } = useGetSearchDetails(userSearchText);

  const {
    mutate: createDatasetCardFromTemplate,
    isSuccess,
    data: datasetCardTemplateRes,
  } = useCreateDatasetCardFromTemplate();

  useEffect(() => {
    if (callCreateCardAPI === true) {
      let id;
      if (dataToNavigating?.__typename === typeNames.apps) {
        id = dataToNavigating?.app_id;
      }

      if (dataToNavigating?.__typename === typeNames.models) {
        id = dataToNavigating?.model_id;
      }

      if (dataToNavigating?.__typename === typeNames.datasets) {
        id = dataToNavigating?.input_data_table_id;
      }
      createDatasetCardFromTemplate({
        id: id,
        type: dataToNavigating?.__typename,
      });
      setCallCreateCardAPI(false);
      setOpenWarningDialogForCard(false);
      setShowCard(true);
      setPositionOfMenuboxOnRightClick(null);
    }
  }, [callCreateCardAPI]);

  const handleCallDatasetApi = () => {
    let id;
    if (
      dataToNavigating.card === null ||
      dataToNavigating.card?.latest_version === null ||
      dataToNavigating.card?.template_version === null
    ) {
      if (dataToNavigating?.__typename === typeNames.apps) {
        id = dataToNavigating?.app_id;
      }

      if (dataToNavigating?.__typename === typeNames.models) {
        id = dataToNavigating?.model_id;
      }

      if (dataToNavigating?.__typename === typeNames.datasets) {
        id = dataToNavigating?.input_data_table_id;
      }

      createDatasetCardFromTemplate({
        id: id,
        type: dataToNavigating?.__typename,
      });
      setShowCard(true);
      setOpenWarningDialogForCard(false);
      setPositionOfMenuboxOnRightClick(null);
    } else {
      if (
        dataToNavigating.card?.latest_version ===
        dataToNavigating.card?.template_version
      ) {
        setShowCard(true);
        setOpenWarningDialogForCard(false);
        setPositionOfMenuboxOnRightClick(null);
      } else {
        setShowCard(false);
        setOpenWarningDialogForCard(true);
        setPositionOfMenuboxOnRightClick(null);
      }
    }
    setShowCard(true);
  };

  useEffect(() => {
    setDataCount({
      app: dataCount.app,
      model: dataCount.model + orphanDataCount.model,
      dataset: dataCount.dataset + orphanDataCount.dataset,
    });
  }, [orphanDataCount]);

  if (isLoading) {
    return <h2>Loading</h2>;
  }

  //Pending
  // if (data?.data.length <= 0) {
  //   return <NoResult/>
  // }

  /* Below functions is call back function which stores the position cordinates of graph node. From that coordinates, right click
  constext menu is displayed   */
  const handleRightClickOnNode = (e) => {
    setPositionOfMenuboxOnRightClick(
      positionOfMenuboxOnRightClick === null
        ? {
            X: e.originalEvent.clientX,
            Y: e.originalEvent.clientY,
          }
        : null
    );
    setDataForNavigating(e.target._dataItem.dataContext); // Set the necessary data of node.
  };

  const handleRightClickMenuClose = () => {
    setPositionOfMenuboxOnRightClick(null);
    setDataForNavigating(null);
  };

  const countGraphNode = (
    nodeTypeCount,
    nodeType,
    orphanNodes,
    orphanCount
  ) => {
    // setDataCount({
    //   app: dataCount.app,
    //   model: dataCount.model + orphanCount,
    //   model: dataCount.dataset + orphanCount,
    // });
    if (orphanNodes) {
      return orphanCount;
    } else {
      return nodeTypeCount;
    }
  };

  const handleSetCurrentData = (currentVal) => {
    setDataForNavigating((prevState) => ({
      ...prevState,
      cardData: currentVal,
    }));
  };

  return (
    <div
    //onClick={handleToControlLeftAndRightClick} onContextMenu={handleToControlLeftAndRightClick}
    >
      {/* Show controls */}
      <div className="graphView_score_field_container" id="graphviewActions">
        <ControlSelector
          checkedKonferScore={checkedKonferScore}
          checkedShowLabel={checkedShowLabel}
          setCheckedKonferScore={setCheckedKonferScore}
          setCheckedShowLabel={setCheckedShowLabel}
          nodeLabelFont={nodeLabelFont}
          setNodeLabelFont={setNodeLabelFont}
          zoomInAndOut={zoomInAndOut}
          chartHeight={chartHeight}
          setChartHeight={setChartHeight}
          setZoomInAndOut={setZoomInAndOut}
          limitForZoomIn={limitForZoomInGraphView}
          setOrphanNodes={setOrphanNodes}
          orphanNodes={orphanNodes}
          graphViewNodes={true}
        />
        <GraphviewInput orphanNodes={orphanNodes} />
      </div>
      {data && (
        <div className="graphview_container" id="graphviewContainer">
          {data && (
            <div className="head_container">
              <div className="label_heading" id="graphviewLabelHeading">
                <div className="heading">
                  <img
                    src={graphAppSvg}
                    alt="graphAppSvg"
                    className="head_img"
                  />
                  <h2 className="dataHeading">
                    {entityNameConst.applications} (
                    {/* {countGraphNode(
                      dataCount.app,
                      queryKeyConstants.appTypename,
                      orphanNodes,
                      orphanDataCount.app
                    )} */}
                    {!orphanNodes
                      ? nodeCount?.app - unUsedCount?.app
                      : unUsedCount?.app}
                    )
                  </h2>
                </div>
                <div className="heading">
                  <img
                    src={graphModelSvg}
                    alt="graphAppSvg"
                    className="head_img"
                  />
                  <h2 className="dataHeading">
                    {entityNameConst.models} (
                    {!orphanNodes
                      ? dataCount?.model + unUsedCount?.model
                      : unUsedCount?.model}
                    )
                  </h2>
                </div>

                {/** For the demo purpose commented the below code */}
                {/* <div className="heading">
                  <img
                    src={graphDataSetSvg}
                    alt="graphAppSvg"
                    className="head_img"
                  />

                  <h2 className="dataHeading">
                    {entityNameConst.datasets}(
                    {!orphanNodes ? dataCount?.dataset : unUsedCount?.dataset})
                  </h2>
                </div> */}
              </div>
            </div>
          )}
          {/* Render Graph */}
          {data && (
            <GraphviewChart
              appIds={data.appIds}
              modelIds={data.modelIds}
              datasetIds={data.dataSetIds}
              checkedShowLabel={checkedShowLabel}
              searchData={data}
              checkedKonferScore={checkedKonferScore}
              setDataCount={setDataCount}
              nodeLabelFont={nodeLabelFont}
              zoomInAndOut={zoomInAndOut}
              chartHeight={chartHeight}
              setChartHeight={setChartHeight}
              handleRightClickOnNode={handleRightClickOnNode}
              orphanNodes={orphanNodes}
              setUnUsedNode={setUnUsedNode}
              orphanDataCount={orphanDataCount}
              setOrphanDataCount={setOrphanDataCount}
              setCount={setCount}
              count={count}
              setOrphanNodes={setOrphanNodes}
              unUsedCount={unUsedCount}
              setUnusedCount={setUnusedCount}
              nodeCount={nodeCount}
              setNodeCount={setNodeCount}
            />
          )}

          <PopOver
            open={open}
            positionOfMenuboxOnRightClick={positionOfMenuboxOnRightClick}
            handleRightClickMenuClose={handleRightClickMenuClose}
            searchTextQuery={searchTextQuery}
            dataToNavigating={dataToNavigating}
            popoverContent={[
              "Share Details",
              isAccessProvided(
                "card_modal",
                "crud",
                null,
                "read",
                accessDataByRole
              ) && "Open Card",
              isAccessProvided(
                "details",
                "crud",
                null,
                "read",
                accessDataByRole
              ) && "Show Details",
            ]}
            setShowCard={setShowCard}
            handleCallDatasetApi={handleCallDatasetApi}
            //modelCardView={modelCardView}
          />

          {showCard && (
            <>
              <Cards
                cardId={dataToNavigating?.card_id}
                showCard={showCard}
                setShowCard={setShowCard}
                datasetCardTemplateRes={datasetCardTemplateRes}
                selectedRiskFactor={null}
                isSuccess={isSuccess}
                setCallCreateCardAPI={setCallCreateCardAPI}
                handleSetCurrentData={handleSetCurrentData}
              />
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default Graphview;
