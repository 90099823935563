export const getCardTemplateDatasetQuery = (typeName) => {
  const query = `query GET_TEMPLATE {
  card_template(order_by: {template_version: desc}, where: {type: {_eq: ${typeName}}}, limit: 1) {
    template_id
    template_version
    createdBy
    status
    template_question
    created_at
  }
}`;
  return query;
};

export const getCardTemplateCreatedByName = (contributorId) => {
  const query = `query MyQuery {
  contributor(where: {contributor_id: {_eq: ${contributorId}}}) {
    name
    role
  }
}`;
  return query;
};

export const getAppKonferScore = (id) => {
  const appQuery = `
    query getAppKonferScore {
      data: apps_risk_factors_aggregate(where: {app_id: {_eq: ${id}}, quarter_id: {_eq: 5}}) {
        score_data: aggregate {
          konfer_composite_score: min {
            value
          }
        }
      }
    }  
  `;

  return appQuery;
};

export const getModelKonferScore = (id) => {
  const modelQuery = `
    query getModelKonferScore {
      data: models_risk_factors_aggregate(where: {model_id: {_eq: ${id}}, quarter_id: {_eq: 5}}) {
        score_data: aggregate {
          konfer_composite_score: min {
            value
          }
        }
      }
    }    
  `;

  return modelQuery;
};

export const getDatasetKonferScore = (id) => {
  const datasetsQuery = `
    query getDataSetsKonferScore {
      data: input_data_tables_risk_factors_aggregate(where: {input_data_table_id: {_eq: ${id}}, quarter_id: {_eq: 5}}) {
        score_data: aggregate {
          konfer_composite_score: min {
            value
          }
        }
      }
    }  
  `;

  return datasetsQuery;
};
// description;
export const getModalCardData = (id) => {
  const query = `
  query getModalcardData {
  cards(where: {card_id: {_eq: ${id}}}) {
    card_id
    title
    sections(order_by: {section_id: asc}) {
      section_id
      title
      description
      subsections(order_by: {subsection_id: asc}) {
        subsection_id
        title
        display_order
        description
        text_data {
          text_data_id
          text_data
        }
        chart_data {
          chart_data_id
          chart_data
          chart_data_image
          chart_type
        }
        questions(order_by: {card_question_id: asc}) {
          answer_type
          card_question_id
          is_visible
          question
          text
          subsection_id
          is_bold
          is_italic
          is_required
          is_underline
          human_impact
          policy_tag
          template_question_id
        }
      }
    }
  }
}
  `;

  return query;
};

export const deleteUser = (contributerId) => {
  const query = `query deleteUser {
    
  }`;
  return query;
};

export const getDataSetLastQueId = () => {
  const query = `{
  questions(order_by: {card_question_id: desc}) {
    card_question_id
  }
}
`;
  return query;
};

export const getRiskFactors = () => {
  const query = `{
      risk_factors(order_by: {risk_factors_id: asc}, where: {name: {_neq: "Konfer Confidence"}}) {
        is_app
        is_app_visible
        is_input_data_table
        is_input_data_table_visible
        is_model
        is_model_visible
        name
        risk_factors_id
      }
  }`;

  return query;
};

export const getAllBusinessUnits = () => {
  const query = `{
    business_unit(order_by: {business_unit_id: desc}) {
      business_unit_id
      business_unit_type
      description
      name
      organization_id
      region {
        name
        region_id
      }
    }
  }
  `;
  return query;
};

// export const getAllRegion = () => {
//   const query = `	{
// 	  region{
// 	  region_id
//     name
//     description
//     organization_id
//     business_unit {
//       business_unit_id
//       name
//     }
// 	  }
// 	}
//   `;
//   return query;
// };

export const deleteDataSetCardQuery = (card_question_id) => {
  const query = `
mutation {
  delete_questions(where: {card_question_id: {_eq: ${card_question_id}}}) {
    returning {
      answer_type
      card_question_id
      question
      is_visible
      subsection_id
      text
    }
  }
}
`;

  return query;
};

//Get all regions Api
export const getAllRegions = () => {
  const query = `query getAllRegion {
      region{
        region_id
        name
        description
        organization_id
      }
  }
  `;
  return query;
};

//Get all business units for region
export const getAllBusinessUnitsForRegion = (regionId) => {
  const query = `{
    business_unit(where: {region_id: {_eq: 1}}, order_by: {business_unit_id: desc}) {
      name
      business_unit_id
      business_unit_type
      region_id
      region {
        name
      }
      }
  }
  `;
  return query;
};

//CPO get annotation queries
export const getCPOAnnotationsQuery = (typeName, typeId) => {
  const query = `
query MyQuery {
  annotation(where: {type: {_eq: ${typeName}}, type_id: {_eq: ${typeId}}}, order_by: {created_at: desc}) {
    name
    created_at
    annotation_id
    contributor_id
    text_name
    contributor {
     	 name
    }
  }
}
  `;
  return query;
};

//CPO add annotation queries
export const createCPOAnnotationQuery = () => {
  const query = `
  mutation MyMutation($input: [annotation_insert_input!]!) {
    insert_annotation(objects: $input) {
    returning{
    name
    created_at
    annotation_id
    contributor_id
    contributor {
    name
    }
    }
    }
    }
    
`;

  return query;
};

export const getQuestionByIdQuery = (id) => {
  const query = `{
    questions(where: {card_question_id: {_eq: ${id}}}) {
      card_question_id
      answer_type
      card_id
      text
      is_bold
      is_italic
      is_required
      is_underline
      is_visible
      question
      subsection_id
      template_question_id
    }
  }`;
  return query;
};
