import React, { useState, useEffect } from "react";
import { useAtom } from "jotai";
import ApplicationIcon from "../../assets/images/adminPanel/Application.svg";
import ModelIcon from "../../assets/images/listview/model-chip.svg";
import DatasetIcon from "../../assets/images/adminPanel/Dataset.svg";

import "./CardTemplatePage.css";

import ImportCSVIcon from "../../assets/images/adminPanel/ImportCSVICon.svg";
import ImportCSVDisabledIcon from "../../assets/images/adminPanel/ImportCSVDisabled.svg";
import DownloadIcon from "../../assets/images/adminPanel/Download.svg";
import DownloadDisabledIcon from "../../assets/images/adminPanel/DownloadDisabled.svg";
import { handleSampleFileDownload } from "../../api/readApiFunctions.js";

import { Tooltip } from "@mui/material";
import { useImportCSVFiles } from "../../api/createApiFunctions.js";

import {
  cardTemplatePageTooltips,
  cardTemplateTooltip,
} from "../../constants/tooltipConstants.js";
import {
  entityNameConst,
  selectedTemplate,
} from "../../constants/dataTypes.js";

import SimpleBackdrop from "../../components/SimpleBackdrop/SimpleBackdrop.jsx";
import TemplateWarning from "../../components/TemplateWarning/TemplateWarning.jsx";
import CardTemplateData from "../../components/CardTemplateData/CardTemplateData.jsx";
import { toast } from "react-toastify";
import { accessDataByRoleAtom } from "../../atom/jotai";
import { isAccessProvided } from "../../services/assetManagementService";
import {
  actionConstants,
  moduleConstants,
  subModuleConstants,
} from "../../constants/accessManagementConstants";
import NoResult from "../../components/NoResult/NoResult";

const CardTemplatePage = () => {
  //state for managing access management system golbal value
  const [accessDataByRole, SetAccessDataByRole] = useAtom(accessDataByRoleAtom);
  const [isEditActive, setIsEditActive] = useState(false);
  const [selectedEntity, setSelectedEntity] = useState({
    type: selectedTemplate.app,
    name: "Applications",
  });

  const {
    mutate: importCSVFiles,
    isSuccess: importCSVFileSuccess,
    data: importCSVFileResponse,
    isError: importCSVFileIsError,
    error: importCSVFileError,
    isLoading: importCSVFileLoading,
  } = useImportCSVFiles();

  const handleTabSelect = (selectedTabType, selectedTabName) => {
    if (!isEditActive) {
      setSelectedEntity({
        type: selectedTabType,
        name: selectedTabName,
      });
    }
  };

  const handleEditState = (editVal) => {
    setIsEditActive(editVal);
  };

  // handle csv upload
  const handleCSVImport = (e) => {
    const file = e.target.files[0];
    const csvData = new FormData();

    csvData.append(`files`, file);

    importCSVFiles({
      filePayload: csvData,
    });

    e.target.value = "";
  };

  //Below useEffect is used for giving title to current page
  useEffect(() => {
    document.title = "Card Template";
  });

  useEffect(() => {
    if (importCSVFileResponse && importCSVFileSuccess) {
      toast.success("CSV Imported Successfully");
    }
  }, [importCSVFileSuccess]);

  useEffect(() => {
    if (importCSVFileIsError && importCSVFileError !== null) {
      toast.error(`${importCSVFileError?.response?.data?.error}`);
    }
  }, [importCSVFileIsError]);

  const handleRefetch = (refetchFn) => {
    refetchFn();
  };

  const handleTemplatePageTooltip = (tooltipType) => {
    let tooltipText = "";

    switch (tooltipType) {
      case "app_tab":
        tooltipText = cardTemplatePageTooltips.appTabDefault;
        if (selectedEntity.type === selectedTemplate.app) {
          tooltipText = cardTemplatePageTooltips.appTabSelected;
        }
        if (isEditActive && selectedEntity.type !== selectedTemplate.app) {
          tooltipText = cardTemplatePageTooltips.tabEditModeOn;
        }
        break;
      case "model_tab":
        tooltipText = cardTemplatePageTooltips.modelTabDefault;
        if (selectedEntity.type === selectedTemplate.model) {
          tooltipText = cardTemplatePageTooltips.modelTabSelected;
        }
        if (isEditActive && selectedEntity.type !== selectedTemplate.model) {
          tooltipText = cardTemplatePageTooltips.tabEditModeOn;
        }
        break;
      case "dataset_tab":
        tooltipText = cardTemplatePageTooltips.datasetTabDefault;
        if (selectedEntity.type === selectedTemplate.input_data_table) {
          tooltipText = cardTemplatePageTooltips.datasetTabSelected;
        }
        if (
          isEditActive &&
          selectedEntity.type !== selectedTemplate.input_data_table
        ) {
          tooltipText = cardTemplatePageTooltips.tabEditModeOn;
        }
        break;
      default:
        tooltipText = "";
        break;
    }

    return tooltipText;
  };

  return (
    <>
      <div className="header_section">
        <div id="adminRiskFactorHeading">
          <p className="template_header_name">Card Templates</p>
        </div>
        {isAccessProvided(
          moduleConstants.card_templates,
          subModuleConstants.crud,
          null,
          "read",
          accessDataByRole
        ) === true && (
          <div className="header_buttons">
            {isAccessProvided(
              moduleConstants.card_templates,
              subModuleConstants.card_template_view,
              actionConstants.import_csv,
              "execution",
              accessDataByRole
            ) === true && (
              <Tooltip
                title={
                  isEditActive
                    ? cardTemplateTooltip.importCSVInEdit
                    : cardTemplateTooltip.importCSV
                }
                followCursor
              >
                <p style={{ margin: "0px", padding: "0px" }}>
                  <label for="myfile">
                    <img
                      src={isEditActive ? ImportCSVDisabledIcon : ImportCSVIcon}
                      className="icon_background "
                    />
                  </label>

                  {!isEditActive && (
                    <input
                      id="myfile"
                      type="file"
                      name="Click here"
                      accept=".xlsx, .csv, .ods, .xls"
                      onChange={(e) => {
                        handleCSVImport(e);
                      }}
                    />
                  )}
                </p>
              </Tooltip>
            )}

            {/*  below code are for csv code*/}
            {isAccessProvided(
              moduleConstants.card_templates,
              subModuleConstants.card_template_view,
              actionConstants.download_sample_file,
              "execution",
              accessDataByRole
            ) === true && (
              <Tooltip
                title={
                  !isEditActive
                    ? cardTemplateTooltip.dwnldSamplTempltCSV
                    : cardTemplateTooltip.dwnldSampleTemplateCSVInEdit
                }
              >
                <div
                  onClick={() => {
                    handleSampleFileDownload(
                      "Sample_Card_Template.csv",
                      "template",
                      "card_templates"
                    );
                  }}
                >
                  <img
                    src={isEditActive ? DownloadDisabledIcon : DownloadIcon}
                    alt="Download"
                    className="icon_background "
                  />
                </div>
              </Tooltip>
            )}
          </div>
        )}
      </div>

      {isAccessProvided(
        moduleConstants.card_templates,
        subModuleConstants.crud,
        null,
        "read",
        accessDataByRole
      ) === true ? (
        <div
          style={{
            position: "relative",
          }}
        >
          <div className="filter-container-card-template">
            <Tooltip
              placement="top"
              title={handleTemplatePageTooltip("app_tab")}
              arrow
            >
              <div
                className={
                  selectedEntity.type == "app"
                    ? "chip_cardTemplate_btn_active"
                    : "chip_cardTemplate_btn"
                }
                style={{
                  cursor:
                    selectedEntity.type !== "app" &&
                    isEditActive &&
                    "not-allowed",
                }}
                onClick={() => {
                  handleTabSelect(selectedTemplate.app, "Applications");
                }}
              >
                <img src={ApplicationIcon} alt="" className="filter-image" />
                <h3 className="filter-name">{entityNameConst.applications}</h3>
              </div>
            </Tooltip>

            <Tooltip
              placement="top"
              title={handleTemplatePageTooltip("model_tab")}
              arrow
            >
              <div
                className={
                  selectedEntity.type == "model"
                    ? "chip_cardTemplate_btn_active"
                    : "chip_cardTemplate_btn"
                }
                style={{
                  cursor:
                    selectedEntity.type !== "model" &&
                    isEditActive &&
                    "not-allowed",
                }}
                onClick={() => {
                  handleTabSelect(selectedTemplate.model, "Models");
                }}
              >
                <img src={ModelIcon} alt="" className="filter-image" />
                <h3 className="filter-name">{entityNameConst.models}</h3>
              </div>
            </Tooltip>

            {/** For the demo purpose commented the below code */}
            {/* <Tooltip
              placement="top"
              title={handleTemplatePageTooltip("dataset_tab")}
              arrow
            >
              <div
                className={
                  selectedEntity.type == selectedTemplate.input_data_table
                    ? "chip_cardTemplate_btn_active"
                    : "chip_cardTemplate_btn"
                }
                style={{
                  cursor:
                    selectedEntity.type !== selectedTemplate.input_data_table &&
                    isEditActive &&
                    "not-allowed",
                }}
                onClick={() => {
                  handleTabSelect(
                    selectedTemplate.input_data_table,
                    "Datasets"
                  );
                }}
              >
                <img src={DatasetIcon} alt="" className="filter-image" />
                <h3 className="filter-name">{entityNameConst.datasets}</h3>
              </div>
            </Tooltip> */}
          </div>

          <CardTemplateData
            selectedEntity={selectedEntity}
            isEditActive={isEditActive}
            handleEditState={handleEditState}
            handleRefetch={handleRefetch}
            importCSVFileSuccess={importCSVFileSuccess}
            accessDataByRole={accessDataByRole}
          />
        </div>
      ) : (
        <div className="hgt userlist-users-table" id="noAccessToRoleManagment">
          {accessDataByRole && (
            <NoResult title={"No Access to Card Templates"} />
          )}
        </div>
      )}

      {importCSVFileLoading && (
        <SimpleBackdrop openStat={importCSVFileLoading} />
      )}
      {importCSVFileResponse && (
        <TemplateWarning templateResData={importCSVFileResponse} />
      )}
    </>
  );
};

export default CardTemplatePage;
