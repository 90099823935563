import React, { useEffect, useState } from "react";
import "./DiscoveryPage.css";
import AdminAzureConfiguration from "../../components/AdminAzurConfiguration/AdminAzureConfiguration.jsx";
import AdminAWSConfiguration from "../../components/AdminConfiguration/AdminConfiguration.jsx";
import FileExtraction from "../../components/FileExtraction/FileExtraction";
import { isAccessProvided } from "../../services/assetManagementService";
import { accessDataByRoleAtom } from "../../atom/jotai";
import { useAtom } from "jotai";
import { CircularProgress } from "@mui/material";
import ExtractionHistory from "../../components/ExtractionHistory/ExtractionHistory";

const DiscoveryPage = () => {
  const [accessDataByRole, setAccessDataByRole] = useAtom(accessDataByRoleAtom);

  const extractionTypes = [
    {
      name: "File Upload",
      value: "file",
      component: <FileExtraction />,
    },
    isAccessProvided(
      "extraction",
      "microsoft_azure_ml",
      null,
      "read",
      accessDataByRole
    ) && {
      name: "Microsoft Azure ML",
      value: "azure",
      component: <AdminAzureConfiguration />,
    },
    isAccessProvided(
      "extraction",
      "amazon_sagemaker",
      null,
      "read",
      accessDataByRole
    ) && {
      name: "Amazon SageMaker",
      value: "aws",
      component: <AdminAWSConfiguration />,
    },
    {
      name: "Extraction History",
      value: "extractionHistory",
      component: <ExtractionHistory />,
    },
  ];

  const [selectedTab, setSelectedTab] = useState(extractionTypes[0]);

  const handleTabClick = (tab) => {
    setSelectedTab(tab);
  };

  return accessDataByRole ? (
    <div className="discovery__page_container">
      {/* Extraction Tabs*/}
      <div className="tabs_wrapper">
        {extractionTypes.map((tab) => {
          return (
            <div
              className={
                selectedTab.value === tab.value
                  ? "tab__name tab__selected"
                  : "tab__name"
              }
              onClick={() => handleTabClick(tab)}
            >
              <p>{tab.name}</p>
            </div>
          );
        })}
      </div>
      {/* Extraction Screen */}
      <div>{selectedTab.component}</div>
    </div>
  ) : (
    <CircularProgress />
  );
};

export default DiscoveryPage;
