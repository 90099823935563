export const moduleConstants = {
  admin_view: "admin_view",
  annotation: "annotation",
  asset_view: "asset_view",
  card_modal: "card_modal",
  card_templates: "card_templates",
  details: "details",
  extraction: "extraction",
  feedback: "feedback",
  policy: "policy",
  profile_view: "profile_view",
  score_inclusion: "score_inclusion",
  terms_conditions: "terms_conditions",
  summary_view: "summary_view",
};

export const subModuleConstants = {
  users: "users",
  regions: "regions",
  business_units: "business_units",
  business_units_assignment: "business_units_assignment",
  role_management: "role_management",
  risk_factors: "risk_factors",
  asset_metadata: "asset_metadata",
  crud: "crud",
  card_template_view: "card_template_view",
  policy_view: "policy_view",
  profile: "profile",
  detail: "detail",
  list_view: "list_view",
  graph_view: "graph_view",
  list_table: "list_table",
  summary_view: "summary_view",
  detail_info: "detail_info",
  detail_score: "detail_score",
  card_view: "card_view",
  card_answer: "card_answer",
  general_file_upload: "general_file_upload",
  microsoft_azure_ml_file_upload: "microsoft_azure_ml_file_upload",
  amazon_sagemaker_file_upload: "amazon_sagemaker_file_upload",
  amazon_sagemaker: "amazon_sagemaker",
  microsoft_azure_ml: "microsoft_azure_ml",
  general_reset: "general_reset",
  microsoft_azure_ml_reset: "microsoft_azure_ml_reset",
  amazon_sagemaker_reset: "amazon_sagemaker_reset",
  extract: "extract",
  terms_pages: "terms_pages",
};

export const actionConstants = {
  visibility: "visibility",
  modify: "modify",
  draft: "draft",
  import_csv: "import_csv",
  download_sample_file: "download_sample_file",
  list_view_button: "list_view_button",
  graph_view_button: "graph_view_button",
  score_inclusion_bar: "score_inclusion_bar",
  card_button: "card_button",
  profile_button: "profile_button",
  observability_button: "observability_button",
  model_card: "model_card",
  show_python_code_details: "show_python_code_details",
  gen_ai_response: "gen_ai_response",
  download_csv_and_pdf: "download_csv_and_pdf",
  import_response_csv: "import_response_csv",
  reset_button: "reset_button",
  extraction_button: "extraction_button",
  publish: "publish",
};

export const accessConstants = {
  read: "read",
  write: "write",
  update: "update",
  delete: "delete",
  execution: "execution",
};
