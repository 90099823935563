import React, { useEffect, useState } from "react";
import { useAtom } from "jotai";
import Feedback from "../../components/Feedback/Feedback";
import Home from "../../components/Home/Home";
import {
  accessDataByRoleAtom,
  authUser,
  isSwitchToAdminPanelAtom,
} from "../../atom/jotai";
import { analyticServices } from "../../services/analyticServices";
import "./Homepage.css";
import { isAccessProvided } from "../../services/assetManagementService";
import {
  accessConstants,
  moduleConstants,
  subModuleConstants,
} from "../../constants/accessManagementConstants";
import { useNavigate } from "react-router-dom";
import NoBusinessUnitsAssignedModal from "../../components/NoBusinessUnitsAssignedModal/NoBusinessUnitsAssignedModal";
import { switchToAdminLocation } from "../../services/adminServices";

const Homepage = () => {
  const [user] = useAtom(authUser);

  const [accessDataByRole, setAccessDataByRole] = useAtom(accessDataByRoleAtom);
  const [, setIsSwitchToAdminPanel] = useAtom(isSwitchToAdminPanelAtom);
  const navigate = useNavigate();

  useEffect(() => {
    // as per the access navigate accordingly
    if (accessDataByRole) {
      if (
        !isAccessProvided(
          moduleConstants.asset_view,
          subModuleConstants.crud,
          null,
          accessConstants.read,
          accessDataByRole
        )
      ) {
        if (
          isAccessProvided(
            moduleConstants.profile_view,
            subModuleConstants.crud,
            null,
            accessConstants.read,
            accessDataByRole
          )
        ) {
          navigate(`/compliance-result/compliance-listview/konfer-confidence`);
        } else if (
          isAccessProvided(
            moduleConstants.extraction,
            subModuleConstants.crud,
            null,
            accessConstants.read,
            accessDataByRole
          )
        ) {
          navigate(`/discovery`);
        } else {
          let locationUrl = switchToAdminLocation(accessDataByRole);
          if (locationUrl !== "") {
            navigate(locationUrl);
            setIsSwitchToAdminPanel(false);
          } else {
            navigate("/403-error");
          }
        }
      }
    }
  }, [accessDataByRole]);

  return (
    <>
      <Home />
      <div
        onClick={() => {
          analyticServices(`Feedback  : ${user.name}`);
        }}
      >
        {isAccessProvided(
          "feedback",
          "crud",
          null,
          "read",
          accessDataByRole
        ) && <Feedback />}
      </div>
      <div className="software_version_div">
        <span className="software_version_text">
          Konfer Confidence Cloud™ - {process.env.REACT_APP_VERSION}
        </span>
      </div>

      {/**Below Code is for when no bussiness unit is attached to the logged in user */}
      <NoBusinessUnitsAssignedModal
        accessDataByRole={accessDataByRole}
        moduleInvoker={"home"}
      />
    </>
  );
};

export default Homepage;
