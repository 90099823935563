import { PolicyNameConst } from "./policyConstants";

export const cardTemplateTooltip = {
  importCSV: "Import template From CSV",
  importCSVInEdit: "For importing CSV, switch off the edit mode",
  dwnldSamplTempltCSV: "Download sample template",
  dwnldSampleTemplateCSVInEdit:
    "For downlaoding Sample CSV, switch off the edit mode",
};

export const cardsPageTooltip = {
  importCSVEnabled: "Import responses from file",
  importCSVDisabled: "Functionality unavailable in edit mode.",
  dwnldCSVEnabled: "Download responses from card data",
  dwnldCSVDisabled: "Functionality unavailable in edit mode.",
};

export const listviewTableTooltip = {
  scoreInclude: "Include in scoring",
  scoreExclude: "Exclude in scoring",
};

export const detailsTabOnDetailsPage = {
  closeZoomImage: "Close the Zoom Modal",
};

export const cardTemplatePageTooltips = {
  //For demo purpose
  // appTabDefault: "Switch to application template",
  // appTabSelected: "Application template selected",

  // modelTabDefault: "Switch to model template",
  // modelTabSelected: "Model template selected",

  appTabDefault: "Switch to bourses template",
  appTabSelected: "Bourses template selected",

  modelTabDefault: "Switch to members template",
  modelTabSelected: "members template selected",

  datasetTabDefault: "Switch to dataset template",
  datasetTabSelected: "Dataset template selected",

  tabEditModeOn: "Cannot switch since edit mode is on.",
};

export const cardTemplateDataTooltips = {
  filterButton: "Filter questions by policy tags",
  filterInEditMode: "Disable edit mode to filter question ny policy tags",

  editButton: "Edit template",

  draftButton: "Draft Template",
  noDraft: "No Draft Available",

  publishedButton: "Published Template",

  saveDraftButton: "Save template as draft",
  saveButtonNoMajorChange: "No changes to draft",

  publishButton: "Publish template card",

  saveChangeButton: "Save template card changes",
  saveChangeNoChange: "No template card changes to save",

  cancelButton: "Cancel template card changes",
};

export const cardTemplateSectionDataTooltips = {
  addNewQuestion: "Add new question",
};

export const cardTemplateQuestionDataTooltips = {
  human_impact:
    "Human Impact not allowed for non boolean answer types. Include answer type as boolean to enable it.",
  mandatory:
    "Mandatory not allowed for non boolean answer types. Include answer type as boolean to enable it.",
  desired_response:
    "Desired Response not allowed for non boolean answer types. Include answer type as boolean to enable it.",
  visibility:
    "Questions with Mandatory, Human impact, and Policy are required to be visible , remove them to enable visibility.",
  policy: `${PolicyNameConst.policy} not allowed for non boolean answer types. Include answer type as boolean to enable it.`,
};

export const genAIToottips = {
  bulkQuesLoader: "Response generation is in progress.",
};
