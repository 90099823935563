import React, { useState, useEffect } from "react";
import { Circle } from "@mui/icons-material";
import { useAtom } from "jotai";
import _ from "lodash";
import {
  tableDataTypes,
  tableDataTypesName,
  tableDataTypesforId,
  typeNames,
} from "../../constants/dataTypes";
import { getSeletedDataInfo } from "../../services/searchServices";
import InternalSearchBox from "../InternalSearchBox/InternalSearchBox";
import {
  accessDataByRoleAtom,
  activeCountOfHighlightWord,
  authUser,
  awsExtractionInProgress,
  azureExtractionInProgress,
  businessUnitName,
  colNameForListviewFindAtom,
  extractStarted,
  finalMapping,
  indexOfSearchedKeywordAtom,
  regionIDAtom,
  searchedWord,
  selectedBusinessUnit,
  totalCountOfHighlightWord,
} from "../../atom/jotai";
import NoResult from "../NoResult/NoResult";
import MuiTable from "../Table/MuiTable";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { complianceTagValue } from "../../constants/complianceConstants";
import {
  useGetAllPolicies,
  useGetListviewTableData,
} from "../../api/readApiFunctions";
import { CircularProgress } from "@mui/material";
import {
  appsColoumsConstants,
  appsColoumsProfileConstants,
  datasetsColumnConstants,
  modelColumnsConstants,
} from "../../constants/listviewConstants";

import {
  findPositionOfSubString,
  getAssetsCountByScore,
  getRowDataForTable,
  listviewRowClick,
  sortData,
} from "../../services/listviewServices";
import { useCreateDatasetCardFromTemplate } from "../../api/createApiFunctions";
import WarningDialogForCard from "../WarningDialogForCard/WarningDialogForCard";
import { analyticServices } from "../../services/analyticServices";
import ManageColumns from "./ManageColumns";
import Cards from "../Cards/CardsNew";
import { useUpdateScoreInclusion } from "../../api/updateApiFunctions";
import { toast } from "react-toastify";
import { listviewTableToasts } from "../../constants/toasterMsgConstants";
import { isAccessProvided } from "../../services/assetManagementService";
import {
  accessConstants,
  actionConstants,
  moduleConstants,
  subModuleConstants,
} from "../../constants/accessManagementConstants";

/**
 * Component : AssetsListviewTable
 *
 * Description : This component is responsible for displaying tabs (application, models and datasets)
 * For getting data api is called which gives result on the basis on search data,
 * seleted regions and business units, contributor and it's role.
 * It also offers find functionality, and handles it's up/down occurrence.
 * It called the table component to show the info in tabular format.
 * It also generated the table column and rows data as per the requirement on table component.
 * It is also responsible for all the table related functionality
 */

const AssetsListviewTable = ({
  setIsGraphViewToggle,
  page,
  listviewConFactorsRefetch,
  modInvoker,
}) => {
  //global states
  const [finalPosition, setFinalPosition] = useAtom(finalMapping);
  const [indexOfSearchedKeyword, setIndexOfSearchedKeyword] = useAtom(
    indexOfSearchedKeywordAtom
  );
  const [activeCountOfHighlightedWord, setActiveCountOfHighlitedWord] = useAtom(
    activeCountOfHighlightWord
  );
  const [colNameForListviewFind, setColNameForListviewFind] = useAtom(
    colNameForListviewFindAtom
  );
  const [, setTotalCountOfHighlitedWord] = useAtom(totalCountOfHighlightWord);

  const [business_name] = useAtom(businessUnitName);
  const [user] = useAtom(authUser);
  const [region_id] = useAtom(regionIDAtom);
  const [selected_bunits_id] = useAtom(selectedBusinessUnit);
  // state for setting highlighted words if the nlp query is hit
  const [searchStr, setSearchState] = useAtom(searchedWord);

  //local states
  //state for displaying current tab
  const [selected_data_type, setSelectedData] = useState(tableDataTypes.apps);
  const [score_count, setScoreCount] = useState({
    redScore: 0,
    blueScore: 0,
  });

  //state for maintaing table rows data
  const [selected_tab_rows_data, setSelectedTabRowsData] = useState([]);

  //state for maintaing table columns data
  const [selected_tab_col_info, setSelectedTabColInfo] = useState([]);

  //state for maintaing current row data
  const [current_data, setCurrentData] = useState(null);

  //state for opening warning dialog for card's version mismatch
  const [open_warning_dialog_for_card, setOpenWarningDialogForCard] =
    useState(false);
  const [show_card, setShowCard] = useState(false); //state for opening card modal
  const [call_create_card_api, setCallCreateCardAPI] = useState(false); //create card api

  //state for managing columns actions menu
  const [anchor_mange_col_mnu_opt, setAnchorMangColMnuOpt] = useState(null);
  const open_managing_col_menu = Boolean(anchor_mange_col_mnu_opt);

  //state for managing columns visibility
  const [visible_table_headers, setVisibleTableHeaders] = useState([]);
  const location = useLocation();
  const { searchText } = useParams();
  const navigate = useNavigate();

  //State used for maintianing the last position on highlighted words in listview find functionality
  const [prev_highlighted_loc, setprevHighlightedLOC] = useState([]);
  const [current_count, setCurrentCount] = useState(0);
  const [find_element, setFindElement] = useState(null);
  const [isScrIncLoading, setIsScrIncLoading] = useState(false); //Loader for score inclusion
  const [accessDataByRole, setAccessDataByRole] = useAtom(accessDataByRoleAtom);
  //Array of row index for tracking where the score inclusion is hit
  const [rowIdWhrScoreIncClicked, setRowIdWhrScoreIncClicked] = useState([]);
  const [azureExtractInprogress, setAzureExtractInprogress] = useAtom(
    azureExtractionInProgress
  );

  const [awsExtractInprogress, setAwsExtractInprogress] = useAtom(
    awsExtractionInProgress
  );

  const [extractStatus, setExtractStatus] = useAtom(extractStarted);

  const createApiPayload = (
    searchText,
    complianceTagValue,
    region_id,
    units,
    user,
    page
  ) => {
    let payload;

    if (page === "listview") {
      payload = {
        searchText: searchText,
        region_id: region_id == -1 ? complianceTagValue?.AllRegions : region_id,
        business_unit_id: units,
        contributor_id: user?.contributor_id,
        user_role: user?.role,
        view_name: "listview",
        callApi: true,
        modInvoker: "asset_view",
      };
    } else if (page === "profile") {
      payload = {
        searchText: searchText,
        region_id: region_id == -1 ? complianceTagValue?.AllRegions : region_id,
        business_unit_id: units,
        contributor_id: user?.contributor_id,
        user_role: user?.role,
        view_name: "profile",
        callApi: true,
        modInvoker: "profile_view",
      };
    } else {
      payload = {
        callApi: false,
      };
    }

    return payload;
  };

  //State for maintaining selected policy in dropdown
  const [scores_selected_policy, setScoresSelectedPolicy] =
    useState("EU AI Act");

  const [policy_data, setPolicyData] = useState([]); //state to maintain policies list coming from database

  let units;
  region_id == complianceTagValue.AllRegions
    ? (units = 0)
    : (units =
        selected_bunits_id.length == 0
          ? complianceTagValue.AllBusinessUnis
          : selected_bunits_id[0]);

  const {
    data: listview_data,
    isError,
    isLoading: listview_data_loading,
    refetch,
  } = useGetListviewTableData(
    createApiPayload(
      searchText,
      complianceTagValue,
      region_id,
      units,
      user,
      page
    )
  );

  const {
    data: allPolicy,
    error: allPolicyError,
    isLoading: allPolicyLoading,
  } = useGetAllPolicies(
    "asset_view",
    "crud",
    location.pathname.split("/")[2] == "listview" ? true : false
  );

  const {
    mutate: createDatasetCardFromTemplate,
    isSuccess,
    data: dataset_card_template_res,
    isLoading,
  } = useCreateDatasetCardFromTemplate();

  const {
    mutate: updateScoreInclusion,
    data: updateScoreInclusionData,
    isSuccess: updateScoreInclusionIsSuccess,
    isError: updateScoreInclusionIsError,
  } = useUpdateScoreInclusion();

  const handleDataSelect = (key, value) => {
    if (key === tableDataTypes.apps) {
      setSelectedData(tableDataTypes.apps);
    } else if (key === tableDataTypes.models) {
      setSelectedData(tableDataTypes.models);
    } else if (key === tableDataTypes.dataSets) {
      setSelectedData(tableDataTypes.dataSets);
    }
    setCurrentCount(0);
    setprevHighlightedLOC([]);
  };

  /** Below functions is used for internal find in listview table,
   * and it is used for down arrow occurance */
  const handleDownHighlightedSelection = (e) => {
    e.preventDefault();
    let row;
    let start_index;
    let col_name;
    let col_index;

    if (prev_highlighted_loc.length === 0) {
      setprevHighlightedLOC([0, Object.keys(finalPosition[0])[0], 0, 0]);
      row = 0;
      start_index = 0;
      col_index = 0;
      col_name = `${Object.keys(finalPosition[0])[0]}`;
    } else {
      row = prev_highlighted_loc[0];
      start_index = prev_highlighted_loc[2] + 1;
      col_name = prev_highlighted_loc[1];
      col_index = prev_highlighted_loc[3];

      if (finalPosition[row][col_name].length === start_index) {
        col_index = col_index + 1;
        start_index = 0;

        if (col_index === Object.keys(finalPosition[row]).length) {
          row = row + 1;
          start_index = 0;
          col_index = 0;
        }
        col_name = `${Object.keys(finalPosition[row])[col_index]}`;
      }
    }

    for (var i = row; i < finalPosition.length; i++) {
      for (var j = col_index; j < Object.keys(finalPosition[i]).length; j++) {
        var key = Object.keys(finalPosition[i])[j];
        if (finalPosition[i][key].length > 0) {
          setprevHighlightedLOC([i, key, start_index, j]);
          setIndexOfSearchedKeyword(finalPosition[i][key][start_index]);
          setActiveCountOfHighlitedWord(i);
          setColNameForListviewFind(key);
          setCurrentCount(current_count + 1);
          document
            .getElementById(`${i}scrollToHighlighter`)
            .scrollIntoView(false, {
              behavior: "smooth",
              block: "start",
            });
          return;
        }
      }
      col_index = 0;
      document
        .getElementById(`${row + 1}scrollToHighlighter`)
        .scrollIntoView(false, {
          behavior: "smooth",
          block: "start",
        });
    }
  };

  /** Below functions is used for internal find in listview table,
   * and it is used for up arrow occurance */
  const handleUpHighlightedSelection = (e) => {
    e.preventDefault();

    let row = prev_highlighted_loc[0];
    let start_index = prev_highlighted_loc[2];
    let col_name = prev_highlighted_loc[1];
    let col_index = prev_highlighted_loc[3];

    if (start_index === 0) {
      if (col_index === 0 && row !== 0) {
        row = row - 1;
        col_index = Object.keys(finalPosition[row]).length - 1;
      } else {
        col_index = col_index - 1;
      }
      col_name = Object.keys(finalPosition[row])[col_index];
      start_index = finalPosition[row][col_name].length - 1;
    } else {
      start_index = start_index - 1;
    }

    for (var i = row; i >= 0; i--) {
      for (var j = col_index; j >= 0; j--) {
        var key = Object.keys(finalPosition[i])[j];
        if (start_index >= 0) {
          setprevHighlightedLOC([i, key, start_index, j]);
          setIndexOfSearchedKeyword(finalPosition[i][key][start_index]);
          setActiveCountOfHighlitedWord(i);
          setColNameForListviewFind(key);
          setCurrentCount(current_count - 1);
          document
            .getElementById(`${i}scrollToHighlighter`)
            .scrollIntoView(false, {
              behavior: "smooth",
              block: "start",
            });
          return;
        }
        if (j > 0) {
          start_index =
            finalPosition[i][Object.keys(finalPosition[i])[j - 1]].length - 1;
        }
      }
      col_index = Object.keys(finalPosition[i - 1]).length - 1;

      start_index =
        finalPosition[i - 1][Object.keys(finalPosition[i - 1])[col_index]]
          .length - 1;

      document.getElementById(`${i}scrollToHighlighter`).scrollIntoView(false, {
        behavior: "smooth",
        block: "start",
      });
    }
  };

  /**  Below useEffects is used to set policy data coming from database*/
  useEffect(() => {
    //  allPolicy && setPolicyData(allPolicy?.data?.data?.score_type_config);
    if (allPolicy) {
      const filteredRows = allPolicy?.data?.data?.score_type_config.filter(
        (row) => {
          return (
            row.score_name !== "Human Impact" &&
            row.score_name !== "Konfer Confidence" &&
            row.status.toUpperCase() === "PUBLISHED"
          );
        }
      );
      setPolicyData(filteredRows);
    }
  }, [allPolicy]);

  /**  Below useEffects is used to set the seleted tab as per data coming from database
   * (on the basis of search, regions, business unit, contributor id and role)*/
  useEffect(() => {
    if (
      listview_data &&
      (listview_data.data.appData.length > 0 ||
        listview_data.data.modelData.length > 0 ||
        listview_data.data.dataSetData.length > 0)
    ) {
      setIsScrIncLoading(false); //If the score inclusion loader is on, make it off
      setRowIdWhrScoreIncClicked([]); //Clear the row array of score inclusion id
      if (listview_data.data.appData.length > 0) {
        setSelectedData(tableDataTypes.apps);
      } else if (listview_data.data.modelData.length > 0) {
        setSelectedData(tableDataTypes.models);
      } else if (listview_data.data.dataSetData.length > 0) {
        setSelectedData(tableDataTypes.dataSets);
      }
      if (
        listview_data.hasOwnProperty("keywords") &&
        listview_data.keywords.length > 0
      ) {
        setSearchState(`keywords ${listview_data.keywords[0]}`);
      }
    }
  }, [listview_data]);

  /**  Below useEffects is used to set the table rows data as per the seleted tab */
  useEffect(() => {
    if (
      listview_data &&
      (listview_data.data.appData.length > 0 ||
        listview_data.data.modelData.length > 0 ||
        listview_data.data.dataSetData.length > 0)
    ) {
      if (selected_data_type === tableDataTypes.apps) {
        setSelectedTabRowsData(
          getRowDataForTable(
            listview_data.data.appData,
            selected_data_type,
            scores_selected_policy
          )
        );
        setSelectedTabColInfo(
          page === "profile"
            ? appsColoumsProfileConstants(
                isAccessProvided(
                  "profile_view",
                  "summary_view",
                  "profile_button",
                  "read",
                  accessDataByRole
                ) &&
                  isAccessProvided(
                    "summary_view",
                    "crud",
                    null,
                    "read",
                    accessDataByRole
                  )
                  ? true
                  : false,
                isAccessProvided(
                  "score_inclusion",
                  "crud",
                  null,
                  "read",
                  accessDataByRole
                ),
                isAccessProvided(
                  moduleConstants.card_modal,
                  subModuleConstants.crud,
                  null,
                  accessConstants.read,
                  accessDataByRole
                ) &&
                  isAccessProvided(
                    moduleConstants.profile_view,
                    subModuleConstants.list_table,
                    actionConstants.card_button,
                    accessConstants.read,
                    accessDataByRole
                  )
              )
            : appsColoumsConstants(
                isAccessProvided(
                  "score_inclusion",
                  "crud",
                  null,
                  "read",
                  accessDataByRole
                ),
                isAccessProvided(
                  moduleConstants.card_modal,
                  subModuleConstants.crud,
                  null,
                  accessConstants.read,
                  accessDataByRole
                ) &&
                  isAccessProvided(
                    moduleConstants.asset_view,
                    subModuleConstants.list_table,
                    actionConstants.card_button,
                    accessConstants.read,
                    accessDataByRole
                  )
              )
        );
      }
      if (selected_data_type === tableDataTypes.models) {
        setSelectedTabRowsData(
          getRowDataForTable(
            listview_data.data.modelData,
            selected_data_type,
            scores_selected_policy
          )
        );
        setSelectedTabColInfo(
          modelColumnsConstants(
            isAccessProvided(
              moduleConstants.card_modal,
              subModuleConstants.crud,
              null,
              accessConstants.read,
              accessDataByRole
            ) &&
              isAccessProvided(
                moduleConstants.asset_view,
                subModuleConstants.list_table,
                actionConstants.card_button,
                accessConstants.read,
                accessDataByRole
              )
          )
        );
      }
      if (selected_data_type === tableDataTypes.dataSets) {
        setSelectedTabRowsData(
          getRowDataForTable(
            listview_data.data.dataSetData,
            selected_data_type,
            scores_selected_policy
          )
        );
        setSelectedTabColInfo(
          datasetsColumnConstants(
            isAccessProvided(
              moduleConstants.card_modal,
              subModuleConstants.crud,
              null,
              accessConstants.read,
              accessDataByRole
            ) &&
              isAccessProvided(
                moduleConstants.asset_view,
                subModuleConstants.list_table,
                actionConstants.card_button,
                accessConstants.read,
                accessDataByRole
              )
          )
        );
      }

      setFindElement("");
      setTotalCountOfHighlitedWord(0);
      setIndexOfSearchedKeyword(-1);
      setCurrentCount(0);
      setprevHighlightedLOC([]);
    }
  }, [selected_data_type, listview_data, scores_selected_policy]);

  /**  Below useEffects is used to set the scores value of assets on the basis
   * of konfer confidence */
  useEffect(() => {
    if (selected_tab_rows_data) {
      setScoreCount(getAssetsCountByScore(selected_tab_rows_data));
    }
  }, [selected_tab_rows_data]);

  /**Below usedEffect is responsible for checking wheather the Find keyword is available or not ,
   * and if yes then to set its count */
  useEffect(() => {
    //If the input field is empty, there is no need to set all these states.
    if (_.isEmpty(find_element)) return;
    let count = 0;
    let position = {
      position_of_substring_final: {},
      count: 0,
    };
    let final_mapping_of_highlited_elements = [];
    if (selected_tab_rows_data.length > 0 && selected_tab_col_info.length > 0) {
      selected_tab_rows_data.map((row_data, index) => {
        position = findPositionOfSubString(
          row_data,
          find_element,
          selected_tab_col_info
        );
        count = count + position.count;
        final_mapping_of_highlited_elements.push(
          position.position_of_substring_final
        );
      });
    }
    setFinalPosition(final_mapping_of_highlited_elements);
    setTotalCountOfHighlitedWord(count);
  }, [find_element]);

  useEffect(() => {
    if (call_create_card_api && current_data) {
      let id;
      if (current_data.__typename === typeNames.apps) {
        id = current_data.app_id;
      }
      if (current_data.__typename === typeNames.models) {
        id = current_data.model_id;
      }
      if (current_data.__typename === typeNames.datasets) {
        id = current_data.input_data_table_id;
      }

      let tempObj = { ...current_data };
      tempObj.card_template_version = tempObj.card_latest_version;
      setCurrentData(tempObj);

      createDatasetCardFromTemplate({
        id: id,
        type: current_data?.__typename,
      });
      setCallCreateCardAPI(false);
      setOpenWarningDialogForCard(false);
      setShowCard(true);
    }
  }, [call_create_card_api]);

  //Below useEffect is called when score inclusion api upadtion successful, als0 refetch the listview data
  useEffect(() => {
    if (updateScoreInclusionIsSuccess) {
      refetch();
      if (page === "profile") {
        listviewConFactorsRefetch();
      }
      //For removing the row id where the score inclusion  result has come
      setRowIdWhrScoreIncClicked((current) =>
        current.filter(
          (idValue) =>
            idValue !== updateScoreInclusionData.data.data.returning[0].app_id
        )
      );
    }
  }, [updateScoreInclusionData]);

  //Below useEffect is called when score inclusion api hits error
  useEffect(() => {
    if (updateScoreInclusionIsError) {
      toast.error(listviewTableToasts.scoreInclusionApiErr);
      setIsScrIncLoading(false);
    }
  }, [updateScoreInclusionIsError]);

  useEffect(() => {
    refetch();
  }, [awsExtractInprogress, azureExtractInprogress, extractStatus]);

  const handleManageColumns = (e) => {
    setAnchorMangColMnuOpt(e.currentTarget);
  };

  //Below function is called when any row is clicked,and also it redirects the details page
  const handleRowClick = (e, current_row_data) => {
    if (isAccessProvided("details", "crud", null, "read", accessDataByRole)) {
      e.stopPropagation();
      e.preventDefault();
      analyticServices(
        `Selected Type : ${current_row_data?.__typename}/${current_row_data?.name} `
      );
      navigate(listviewRowClick(current_row_data));
    }
  };

  //Below function is called when score inclusion is clicked,and also called the api for upadtion
  const handleScreInclusionColumnClk = (val, rowIndex) => {
    const scorePayload = {
      type: selected_tab_rows_data[rowIndex].__typename,
      id: [
        selected_tab_rows_data[rowIndex][
          tableDataTypesforId[selected_data_type]
        ],
      ],
      score_calculation: val,
    };
    updateScoreInclusion(scorePayload);
    setRowIdWhrScoreIncClicked((oldArray) => [
      ...oldArray,
      selected_tab_rows_data[rowIndex][tableDataTypesforId[selected_data_type]],
    ]);
    //setRowIdWhrScoreIncClicked([rowIndex]);
    setIsScrIncLoading(true);
  };

  //Below function is called when card button is clicked,
  /*It's responsibility to call the create card api if the card is not yet created. 
   If the card is already present then it open the card by checking the the latest and template
    version. If the template version is mismatch it opens a dialog to give option of upgarde.*/
  const handleCardButton = (e, current_row_data) => {
    e.preventDefault();
    setCurrentData(current_row_data);
    let id;

    if (current_row_data?.card_id === null) {
      if (current_row_data.__typename === typeNames.apps) {
        id = current_row_data.app_id;
      }
      if (current_row_data.__typename === typeNames.models) {
        id = current_row_data.model_id;
      }
      if (current_row_data.__typename === typeNames.datasets) {
        id = current_row_data.input_data_table_id;
      }

      createDatasetCardFromTemplate({
        id: id,
        type: current_row_data?.__typename,
      });
      setShowCard(true);
      setOpenWarningDialogForCard(false);
    } else {
      if (
        current_row_data.card_latest_version ===
        current_row_data.card_template_version
      ) {
        setShowCard(true);
        setOpenWarningDialogForCard(false);
      } else {
        // setShowCard(false);
        setOpenWarningDialogForCard(true);
      }
    }
    // set the card true on click [Logic Pending]
    setShowCard(true);
  };

  //Below function is called when column management function is clicked from menu
  //It's responsibility is to set column constants to visible table columns
  const handleCOlManagement = (e) => {
    setVisibleTableHeaders(selected_tab_col_info);
  };

  /*Below function is called when visibility of column in on/off,
  It sets the table columns info in the appropriate state, For this , mainting the checked key
   in column info object*/
  const handleColVisibility = (e) => {
    visible_table_headers !== null &&
      visible_table_headers.map((headers) => {
        if (headers.field === e.target.name) {
          headers.checked = e.target.checked;
          setVisibleTableHeaders([...visible_table_headers]);
        }
      });
    setSelectedTabColInfo(visible_table_headers);
  };

  /*Below function is called to sort the data as per the inputs, 
  and also set the sorted data in table columns and rows data*/
  const handleSort = (colName, descending, order) => {
    setSelectedTabRowsData(sortData(selected_tab_rows_data, colName, [order]));
    setSelectedTabColInfo(
      selected_tab_col_info.map((colInfo) => {
        if (colName === colInfo.field) {
          return { ...colInfo, descending };
        } else {
          return colInfo;
        }
      })
    );
  };

  const handleSelectPolicy = (e) => {
    setScoresSelectedPolicy(e.target.value);
  };

  if (listview_data) {
    //  check if no data exists
    if (
      listview_data?.data?.appData?.length === 0 &&
      listview_data?.data?.modelData?.length === 0 &&
      listview_data?.data?.dataSetData?.length === 0
    ) {
      //setIsGraphView(false);
      if (page === "listview") {
        setIsGraphViewToggle(false);
      }
      return <NoResult />;
    }
  }

  const handleSetCurrentData = (currentVal) => {
    setCurrentData((prevState) => ({
      ...prevState,
      cardData: currentVal,
    }));
  };

  /** listview_data is object which is having keys as appData, modelData, & datasetData & value in array */
  return listview_data_loading ? (
    <CircularProgress />
  ) : listview_data === undefined || listview_data === null ? (
    <NoResult />
  ) : (
    <div>
      {page === "listview" && (
        <div className="filter-container" id="nodetypeFilterContainerDiv">
          {Object.entries(listview_data?.data).map(
            ([key, value]) =>
              /** key !== "dataSetData" , this condition is for hinding the dataset section, for the demo only */
              key !== "dataSetData" &&
              value.length > 0 && (
                <div
                  id={`${key}FilterChip`}
                  key={key}
                  onClick={() => handleDataSelect(key, value)}
                  className={`chip-button ${
                    key === selected_data_type && "chip-button-active"
                  }`}
                >
                  <img
                    src={getSeletedDataInfo(key)}
                    alt={tableDataTypesName[key]}
                    className="filter-image"
                    id={`${key}FilterChipImage`}
                  />
                  <h3 className="filter-name">{tableDataTypesName[key]}</h3>
                  <p id={`${key.length}FilterChipCount`}>({value.length})</p>
                </div>
              )
          )}
        </div>
      )}
      <div
        className="filter_score_field_container"
        id="displayScoreAndSearchDiv"
      >
        {/* to show score */}

        <div className="score_counter-wrapper" id="displayScoreDiv">
          <div className="score_text" id="displayScoreTypeDiv">
            <p className="score_text_name">
              {tableDataTypesName[selected_data_type]} by score:{" "}
            </p>
          </div>
          <div className="score_counter" id="displayScoreColorDiv">
            <div className="score_ball score_blue">
              <Circle
                className="score_ball_color_below_limit"
                sx={{
                  width: "20px",
                  "@media screen and (max-height: 780px)": {
                    marginTop: "2px",
                    width: "18px",
                  },
                }}
              />
              <p className="score_ball-count">{score_count?.redScore}</p>
            </div>
            <div className="score_ball">
              <Circle
                className="score_ball_color_above_limit"
                sx={{
                  width: "20px",
                  "@media screen and (max-height: 780px)": {
                    marginTop: "2px",
                    width: "18px",
                  },
                }}
              />
              <p className="score_ball-count">{score_count?.blueScore}</p>
            </div>
          </div>
        </div>
        {/* )} */}

        <InternalSearchBox
          findElement={find_element}
          setFindElement={setFindElement}
          currentCount={current_count}
          setCurrentCount={setCurrentCount}
          prevHighlightedLOC={prev_highlighted_loc}
          setprevHighlightedLOC={setprevHighlightedLOC}
          handleDownHighlightedSelection={handleDownHighlightedSelection}
          handleUpHighlightedSelection={handleUpHighlightedSelection}
        />
      </div>

      <div style={{ marginTop: "-10px" }}>
        <MuiTable
          table_columns_data={selected_tab_col_info && selected_tab_col_info}
          table_rows_data={selected_tab_rows_data && selected_tab_rows_data}
          handleManageColumns={handleManageColumns}
          find_element={find_element}
          handleRowClick={handleRowClick}
          handleCardButton={handleCardButton}
          handleSort={handleSort}
          handleSelectPolicy={handleSelectPolicy}
          policy_data={policy_data}
          scores_selected_policy={scores_selected_policy}
          searchText={searchText}
          page={page}
          handleScreInclusionColumnClk={handleScreInclusionColumnClk}
          isScrIncLoading={isScrIncLoading}
          rowIdWhrScoreIncClicked={rowIdWhrScoreIncClicked}
        />
      </div>

      <ManageColumns
        anchor_mange_col_mnu_opt={anchor_mange_col_mnu_opt}
        setAnchorMangColMnuOpt={setAnchorMangColMnuOpt}
        open_managing_col_menu={open_managing_col_menu}
        handleCOlManagement={handleCOlManagement}
        visible_table_headers={visible_table_headers}
        handleColVisibility={handleColVisibility}
      />

      {current_data && show_card && (
        <>
          <Cards
            cardId={current_data?.card_id}
            showCard={show_card}
            setShowCard={setShowCard}
            datasetCardTemplateRes={dataset_card_template_res}
            selectedRiskFactor={null}
            isSuccess={isSuccess}
            setCallCreateCardAPI={setCallCreateCardAPI}
            assetsviewRefetch={refetch}
            listviewConFactorsRefetch={listviewConFactorsRefetch}
            page={page}
            handleSetCurrentData={handleSetCurrentData}
          />
        </>
      )}
    </div>
  );
};

export default AssetsListviewTable;
