import React, { useEffect, useLayoutEffect, useState } from "react";
import * as am5 from "@amcharts/amcharts5";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import * as am5xy from "@amcharts/amcharts5/xy";

import { Box } from "@mui/system";

const GroupColumnChart = ({ data }) => {
  const uniqueDivId = Math.floor(Math.random() * 800000);

  useLayoutEffect(() => {
    // Create root element
    let root = am5.Root.new(`${uniqueDivId}`);

    // Set themes
    root.setThemes([am5themes_Animated.new(root)]);

    // Set themes
    root.setThemes([am5themes_Animated.new(root)]);

    // Create chart
    var chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panX: false,
        panY: false,
        layout: root.verticalLayout,
      })
    );

    /* show chart title */ chart.topAxesContainer.children.push(
      am5.Label.new(root, {
        text: "counts|purpose",
        fontSize: 18,
        fontWeight: "400",
        x: am5.p50,
        centerX: am5.p50,
      })
    );

    // Create axes
    var xRenderer = am5xy.AxisRendererX.new(root, {
      minGridDistance: 30,
      cellStartLocation: 0.1,
      cellEndLocation: 0.9,
    });

    xRenderer.labels.template.setAll({
      rotation: -90,
      centerY: am5.p50,
      paddingRight: 5,
    });

    xRenderer.grid.template.set("visible", false);

    var xAxis = chart.xAxes.push(
      am5xy.CategoryAxis.new(root, {
        categoryField: "groupLabel",
        renderer: xRenderer,
        tooltip: am5.Tooltip.new(root, {}),
      })
    );

    xRenderer.grid.template.setAll({
      location: 1,
    });

    /* Show title on xAxis */
    xAxis.children.push(
      am5.Label.new(root, {
        text: "purpose",
        x: am5.p50,
        centerX: am5.p50,
      })
    );

    xAxis.data.setAll(data);

    var yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        extraMax: 0.1,
        renderer: am5xy.AxisRendererY.new(root, {
          strokeOpacity: 0.1,
        }),
      })
    );

    /* Show title on yAxis */
    yAxis.children.unshift(
      am5.Label.new(root, {
        rotation: -90,
        text: "counts",
        y: am5.p50,
        center: am5.p50,
      })
    );

    // Add series
    function makeSeries(name, fieldName, color) {
      var series = chart.series.push(
        am5xy.ColumnSeries.new(root, {
          name: name,
          xAxis: xAxis,
          yAxis: yAxis,
          valueYField: fieldName,
          fill: color,
          categoryXField: "groupLabel",
        })
      );

      series.columns.template.setAll({
        tooltipText: "{name}, {categoryX}:{valueY}",
        width: am5.p100,
        tooltipY: 0,
        strokeOpacity: 0,
      });

      series.data.setAll(data);

      // Make stuff animate on load
      series.appear();

      series.bullets.push(function () {
        return am5.Bullet.new(root, {
          locationY: 0,
          sprite: am5.Label.new(root, {
            text: "{valueY}",
            fill: root.interfaceColors.get("alternativeText"),
            centerY: 0,
            centerX: am5.p50,
            populateText: true,
          }),
        });
      });

      var legend = root.container.children.push(
        am5.Legend.new(root, {
          y: am5.percent(10),
          x: am5.percent(100),
          centerX: am5.percent(100),
          layout: root.verticalLayout,
        })
      );

      legend.data.setAll(chart.series.values);
    }

    makeSeries("GoodCredit", "GoodCredit", am5.color(0x2596be));
    makeSeries("BadCredit", "BadCredit", am5.color(0xff7f0e));

    return () => {
      root.dispose();
    };
  }, []);

  return (
    <div>
      <Box
        id={uniqueDivId}
        className="custom-chart"
        style={{
          height: "60vh",
          width: "80%",
        }}
      ></Box>
    </div>
  );
};
export default GroupColumnChart;
