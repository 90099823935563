import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import './TemplateWarning.css'

const TemplateWarning = ({templateResData}) => {

  // manage modal state
    const [open, setOpen] = useState(false);
    // manage formaatted state
    const [formattedData, setFormattedData] = useState(null);    

    useEffect(() => {   
        if(templateResData){
        const  totalWarnings =  [...templateResData?.appValidation?.Warning , ...templateResData?.datasetValidation?.Warning , ...templateResData?.modelValidation?.Warning ]

        // sort the warnings row wise
        const sortedTexts = totalWarnings.sort((a, b) => a.row - b.row)
        if(sortedTexts.length > 0){
            setOpen(true)
            setFormattedData(sortedTexts)
        }
    }

    }, [templateResData])
    
    const style = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "90%",
        height: "80%",
        bgcolor: "#ffffff",
        boxShadow: 24,
        p: 4,
        borderRadius: "8px",
      };


      // handle html to txt for download
      function createTextFileFromArrayOfObjects(data, filename) {
        const content = data.map(obj => `Row: ${obj?.row}\nEntity: ${obj?.type}\nMessage: ${obj?.message}`).join('\n\n');
        const blob = new Blob([content], { type: 'text/plain' });
        const link = document.createElement('a');
        link.download = filename;
        link.href = window.URL.createObjectURL(blob);
        link.click();
      }


      const handleTextDownload = () => {
        if(formattedData){
            createTextFileFromArrayOfObjects(formattedData, 'warnings.txt');
        }
      }

      const handleCancelClick = () => {
        setOpen(false)
      }

    return (
        <div>
      <Modal
        id="modalCard"
        sx={{
          marginLeft: 23,
          marginRight: 23,
        }}
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      > 
        <Box sx={style} id="modalCardBox">       
                
                <div className='warning_header'>
                    <div>
                        <p className='warning_header_para' >CSV Import Warnings</p>
                    </div>
                </div>
        
                <div className='template_warning_container'>
                {/* show the warnings count */}
                  <div className='template_count'>
                        {/* show the count here */}
                        {templateResData && Object.keys(templateResData).map((oneKey,i)=>{
                            let keyName
                            if(oneKey?.includes("app")){
                              keyName = "Application"
                            }else if(oneKey.includes("model")){
                              keyName = "Model"
                            }else {
                              keyName = "Dataset"
                            }
                            return (
                              
                                <p>{keyName} has {templateResData[oneKey]?.Warning?.length} warnings.</p>
                      
                              )
                          })}
                    </div>
                    {/* show the warnings */}
                    {formattedData && formattedData.map((data)=>{
                        return (
                            <div style={{borderBottom: "1px solid rgb(145, 141, 141)" , margin: "0 10px" }}>
                                <p>Row : {data.row}</p>
                                <p>Entity : {data.type.toUpperCase()}</p>
                                <p className='warning_message'>Message : {data.message}.</p>
                            </div>   
                    )
                })}
                </div>
                <div className='warnings_buttons'>
                    <button
                        id="smSetConfigButton"
                        className="userlist-createNewUser-button"
                        onClick={handleCancelClick}
                        >
                            Cancel
                    </button>

                    <button
                        id="smSetConfigButton"
                        className="userlist-createNewUser-button green_btn"
                        onClick={handleTextDownload}
                        >
                            Download File
                    </button>
                </div>
         

            </Box>
        </Modal>
        </div>
    )
}

export default TemplateWarning