import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  IconButton,
  Menu,
  MenuItem,
} from "@mui/material";
import {
  CheckOutlined,
  CloseOutlined,
  // Highlight,
  // MoreVert,
} from "@mui/icons-material";
import { useAtom } from "jotai";
import { toast } from "react-toastify";
import "./ComplianceAnnotationCrad.css";
import MoreVertIcon from "../../../assets/images/annoation/more-vert.svg";
import { accessDataByRoleAtom, authUser } from "../../../atom/jotai";
import EditIcon from "../../../assets/images/detail/edit_annotation.svg";
import DeleteIcon from "../../../assets/images/detail/delete_annotation.svg";
import { dateFormate, timeFormate } from "../../../utils/basicUtils";
import { useUpdateCPOAnnotation } from "../../../api/updateApiFunctions";
import { useDeleteCPOAnnotations } from "../../../api/deleteApiFunctions";
import { analyticServices } from "../../../services/analyticServices";
import { Mention, MentionsInput } from "react-mentions";
import defaultStyle from "../../../assets/css/mentionsStyle";
import { handleAnnotationsRender } from "../../../services/detailsServices";
import { useCreateSlackAnnotation } from "../../../api/createApiFunctions";
import { isAccessProvided } from "../../../services/assetManagementService";

const ComplianceAnnotationCrad = ({
  data,
  annotaionIndex,
  slackUsers,
  getTypeName,
  getAnnotaionRefetch,
}) => {
  const [user] = useAtom(authUser);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  // to show a dialog box for a confirmation to delete an annotation
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [showEditInput, setShowEditInput] = useState(false);
  // to store edit input
  const [editAnnotation, setEditAnnotation] = useState(data?.name);

  // react -query muatations
  const { mutate: updateCPOAnnotation } = useUpdateCPOAnnotation();
  const {
    mutate: deleteCPOAnnotation,
    isSuccess: deleteCPOAnnotationIsSuccess,
  } = useDeleteCPOAnnotations();
  const { mutate: createSlackAnnotation } = useCreateSlackAnnotation();
  const [accessDataByRole, setAccessDataByRole] = useAtom(accessDataByRoleAtom);

  useEffect(() => {
    if (deleteCPOAnnotationIsSuccess) {
      getAnnotaionRefetch();
    }
  }, [deleteCPOAnnotationIsSuccess]);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleEditAnnotations = (id) => {
    const annotationToString = handleAnnotationsRender(
      editAnnotation,
      slackUsers
    );

    const payload = {
      id,
      name: editAnnotation.trim(),
      text_name: annotationToString,
    };

    updateCPOAnnotation(payload);
    const slackPayload = {
      annotationMessage: `
      ${editAnnotation}

      Type : ${getTypeName()}`,

      url: window.location.href,
      subModInvoker: "profile",
    };
    createSlackAnnotation(slackPayload);
    setShowEditInput(false);
    handleClose();
    analyticServices(`Annotation Edited :-  ${editAnnotation}`);
    toast.success("Annotation updated");
  };

  const handleDeleteAnnotations = (cardData) => {
    const payload = {
      id: cardData.annotation_id,
    };
    deleteCPOAnnotation(payload);
    setShowEditInput(false);
    setOpenDeleteDialog(false);
    handleClose();
    toast.success("Annotation deleted");
    analyticServices(`Annotation deleted : ${cardData.name}`);
  };

  const handleCancelEdit = () => {
    setEditAnnotation(data.name);
    setShowEditInput(false);
    handleClose();
  };

  const handleMentionChange = (e) => {
    setEditAnnotation(e.target.value);
  };

  const handleDisplayTransform = (id) => {
    const user = slackUsers.find((user) => user.id === id);
    if (user) {
      return user.display;
    } else {
      return "";
    }
  };
  return (
    <div className="complianceAnnotationDataDiv">
      {/* {showEditInput === false && ( */}
      <>
        <div>
          <div className="cardHeading">
            <span className="cardAuthorName mRight">
              {data?.contributor?.name}
            </span>
            <span className="cardCreationDate mRight">
              {dateFormate(data?.created_at)}
            </span>
            <span className="cardCreationDate">
              {timeFormate(data?.created_at)}
            </span>
          </div>

          {showEditInput === false ? (
            // <p>{data?.text_name}</p>
            <p>{handleAnnotationsRender(editAnnotation, slackUsers)}</p>
          ) : (
            <div className="edit_card_wrapper" id="editCPOAnnotations">
              {slackUsers && (
                <MentionsInput
                  style={defaultStyle}
                  className="annotation_input"
                  value={editAnnotation}
                  onChange={handleMentionChange}
                  singleLine={true}
                >
                  <Mention
                    trigger="@"
                    data={slackUsers}
                    markup="<@__id__>"
                    displayTransform={handleDisplayTransform}
                    appendSpaceOnAdd={true}
                  />
                </MentionsInput>
              )}

              <div
                className="edit_card_actions"
                id="editCPOAnnotationsActionBtns"
              >
                <Button
                  id="addUpdateCPOAnnotationIcon"
                  sx={{ minWidth: "20px", padding: "0px" }}
                  onClick={() => handleEditAnnotations(data.annotation_id)}
                  disabled={editAnnotation == ""}
                >
                  <CheckOutlined fontSize="small" />
                </Button>
                <Button
                  onClick={handleCancelEdit}
                  id="cancelUpdateCPOAnnotation"
                  sx={{ minWidth: "20px", padding: "0px" }}
                >
                  <CloseOutlined fontSize="small" />
                </Button>
              </div>
            </div>
          )}
        </div>
        {user.contributor_id == data.contributor_id &&
          showEditInput === false &&
          (isAccessProvided(
            "annotation",
            "profile",
            null,
            "update",
            accessDataByRole
          ) ||
            isAccessProvided(
              "annotation",
              "profile",
              null,
              "delete",
              accessDataByRole
            )) && (
            <div>
              <button onClick={handleClick} className="allow_editable_button">
                <img src={MoreVertIcon} alt="move-art" onClick={handleClick} />
              </button>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                {isAccessProvided(
                  "annotation",
                  "profile",
                  null,
                  "update",
                  accessDataByRole
                ) && (
                  <MenuItem onClick={() => setShowEditInput(true)}>
                    <img src={EditIcon} width="14px" />
                  </MenuItem>
                )}
                {isAccessProvided(
                  "annotation",
                  "profile",
                  null,
                  "delete",
                  accessDataByRole
                ) && (
                  <MenuItem
                    // onClick={() => handleDeleteAnnotations(data.annotation_id)}
                    onClick={() => setOpenDeleteDialog(true)}
                  >
                    <img src={DeleteIcon} width="14px" />
                  </MenuItem>
                )}

                <Dialog
                  id="deleteCPOAnnotationDialog"
                  open={openDeleteDialog}
                  onClose={!openDeleteDialog}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogTitle id="alert-dialog-titl">
                    {"Delete this Annotation?"}
                  </DialogTitle>
                  <DialogActions>
                    <Button
                      id="deleteCPOAnnotationDialogConfirmation"
                      value="YES"
                      onClick={() => handleDeleteAnnotations(data)}
                    >
                      YES
                    </Button>
                    <Button
                      id="deleteAnnotationDialogCancel"
                      value="NO"
                      onClick={() => {
                        setOpenDeleteDialog(false);
                        handleClose();
                      }}
                      autoFocus
                    >
                      NO
                    </Button>
                  </DialogActions>
                </Dialog>
              </Menu>
            </div>
          )}
      </>
      {/* )} */}
      {/* {showEditInput && (
        <div className="edit_card_wrapper" id="editCPOAnnotations">
          <input
            className="edit_annotations_input"
            value={editAnnotation}
            onChange={(e) => {
              setEditAnnotation(e.target.value);
            }}
            singleLine={true}
          />

          <div className="edit_card_actions" id="editCPOAnnotationsActionBtns">
            <Button
              id="addUpdateCPOAnnotationIcon"
              sx={{ minWidth: "20px", padding: "0px" }}
              onClick={() => handleEditAnnotations(data.annotation_id)}
              disabled={editAnnotation == ""}
            >
              <CheckOutlined className="tick_card_action" />
            </Button>
            <Button
              onClick={handleCancelEdit}
              id="cancelUpdateCPOAnnotation"
              sx={{ minWidth: "20px", padding: "0px" }}
            >
              <CloseOutlined />
            </Button>
          </div>
        </div>
      )} */}
    </div>
  );
};

export default ComplianceAnnotationCrad;
