import { ArrowForwardIos } from "@mui/icons-material";
import { useAtom } from "jotai";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  detailBreadcrumbsData,
  observabilityBreadcrumbsData,
} from "../../atom/jotai";
import "./Breadcrumbs.css";

export const Breadcrumbs = ({}) => {
  const navigate = useNavigate();
  const location = useLocation();

  //  the atom used for set detail page breadcrumbs
  const [detailBreadcrumbs, setDetailBreadCrumbs] = useAtom(
    detailBreadcrumbsData
  );
  const [breadcrumbData, setBreadcrumbData] = useState([]);

  //  the atom used for set observability page breadcrumbs
  const [observabilityBreadcrumbs] = useAtom(observabilityBreadcrumbsData);

  useEffect(() => {
    if (location.pathname.split("/")[1] == "detail") {
      setBreadcrumbData(detailBreadcrumbs);
    }
    if (location.pathname.split("/")[1] == "observability") {
      setBreadcrumbData(observabilityBreadcrumbs);
    }
  }, [observabilityBreadcrumbs, detailBreadcrumbs]);

  return (
    <div className="breadcrumbs_container" id="breadcrumbDiv">
      {breadcrumbData?.map((item, index) => {
        let navigatePath = -breadcrumbData?.length + 1 + index;

        return (
          <>
            <div
              id={`breadcrumbItem${index}`}
              onClick={() => navigate(navigatePath)}
              className={
                breadcrumbData?.length == index + 1
                  ? "breadcrumbs_name_selected"
                  : "breadcrumbs_name"
              }
            >
              {item?.names}
            </div>
            {breadcrumbData?.length !== index + 1 && (
              <ArrowForwardIos className="breadIcon" />
            )}
          </>
        );
      })}
    </div>
  );
};
