import React, { useLayoutEffect } from "react";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import "../../components/RiskFactors/RiskFactor.css";
import { riskFactorScoreColour } from "../../services/riskFactorScoreColour";

function AmchartRiskFactor(props) {
  let innerWidth = window.innerWidth;
  // let graphTypeTwo = props?.data.graph2.type;
  const unqId = Math.floor(Math.random() * 100000);

  useLayoutEffect(() => {
    let root = am5.Root.new(unqId);

    root.setThemes([am5themes_Animated.new(root)]);

    let chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panX: false,
        panY: false,
        layout: root.verticalLayout,
      })
    );
    //  used for remove zoom icon
    chart.zoomOutButton.set("forceHidden", true);

    let data = [];
    props?.data?.quarter_data &&
      props?.data?.quarter_data.forEach((element) => {
        if (element.quarter.quarter !== "current_month") {
          let tempObj = {
            quarter: element.quarter.quarter,
            value: !element.value ? 0 : element.value,
          };
          data.push(tempObj);
        }
      });

    // Craete Y-axis
    let yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        renderer: am5xy.AxisRendererY.new(root, {
          strokeOpacity: 1,
          strokeWidth: 2,
          stroke: am5.color(0xffffff),
        }),
      })
    );

    yAxis.get("renderer").labels.template.setAll({
      visible: false,
    });
    let yRenderer = yAxis.get("renderer");
    yRenderer.grid.template.setAll({
      stroke: am5.color(0xffffff),
      strokeWidth: 0,
    });

    // Create X-Axis
    let xAxis = chart.xAxes.push(
      am5xy.CategoryAxis.new(root, {
        // maxDeviation: 0.2,
        renderer: am5xy.AxisRendererX.new(root, {
          // strokeOpacity: 1,
          // strokeWidth: 2,
          // stroke: am5.color(0x74b1bc),
        }),
        categoryField: "quarter",
      })
    );

    xAxis.get("renderer").labels.template.setAll({
      visible: false,
    });
    let xRenderer = xAxis.get("renderer");
    xRenderer.grid.template.setAll({
      stroke: am5.color(0xffffff),
      strokeWidth: 0,
    });
    xAxis.data.setAll(riskFactorScoreColour(data));

    // Create series
    let series = chart.series.push(
      am5xy.ColumnSeries.new(root, {
        name: "Series",
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: "value",
        categoryXField: "quarter",
        tooltip: am5.Tooltip.new(root, {}),
      })
    );
    chart.zoomOutButton.disabled = true;
    //tooltip show
    let tooltip = am5.Tooltip.new(root, {
      getFillFromSprite: false,
      getStrokeFromSprite: false,
      autoTextColor: false,
      getLabelFillFromSprite: true,
    });

    tooltip.get("background").setAll({
      fill: am5.color(0xffffff),
      fillOpacity: 0.8,
    });

    series.columns.template.setAll({
      tooltipText: "{value}",
    });

    // series.set("tooltip", tooltip);
    series.data.setAll(data);

    //set column width
    series.columns.template.setAll({
      width: window.innerWidth > 1450 ? 22 : 18,
    });

    //set column colors
    series.columns.template.setAll({
      templateField: "scoreColumnColor",
    });

    return () => {
      root.dispose();
    };
  }, []);

  return (
    <>
      <div className="riskfactor_graph_container" id="riskFactorChartContainer">
        <div id={unqId} className="risk_factor_mchart"></div>
        <div
          style={{
            borderBottom: "0.5px solid #475B69",
            marginTop: "-41px",
            width: "80%",
            overflowX: "hidden",
            marginLeft: innerWidth < 1436 ? "30px" : "30px",
            // width: "90%",
            // overflowX: "hidden",
            // marginLeft: innerWidth < 1436 ? "10px" : "40px",
          }}
        ></div>
      </div>
    </>
  );
}
export default AmchartRiskFactor;
