export const createHeatmapFormat = (data) => {
  const xAxis = ["1", "0", "0", "1"];
  const yAxis = ["1", "1", "0", "0"];

  const finalData = [];
  const tableData = [];

  const tableName = {
    "stats.accuracy": "Accuracy",
    "stats.precision": "Precision",
    "stats.recall": "Recall",
    "stats.f1_score": "F1 Score",
  };

  if (data && data.length > 0) {
    data.map((chartData, index) => {
      if (chartData.label === "group_counts") {
        const dataToRenderChart = chartData.data;
        if (dataToRenderChart && dataToRenderChart.length > 0) {
          dataToRenderChart.map((dataValue, indexData) => {
            finalData.push({
              xAxisData: xAxis[indexData],
              yAxisData: yAxis[indexData],
              value: dataValue,
            });
          });
        }
      }

      if (chartData.label === "stats.accuracy") {
        tableData.push({
          heading: tableName[chartData.label],
          data: chartData.data,
        });
      }
      if (chartData.label === "stats.precision") {
        tableData.push({
          heading: tableName[chartData.label],
          data: chartData.data,
        });
      }
      if (chartData.label === "stats.recall") {
        tableData.push({
          heading: tableName[chartData.label],
          data: chartData.data,
        });
      }
      if (chartData.label === "stats.f1_score") {
        tableData.push({
          heading: tableName[chartData.label],
          data: chartData.data,
        });
      }
    });
  }

  const formattedData = {
    finalData: finalData,
    tableData: tableData,
  };
  return formattedData;
};

export const createLineChartFormat = (data) => {
  const finalData = [];
  const tableData = [];

  let xAxisData = [];
  let yAxisData = [];

  if (data) {
    data?.xAxis?.data.length > 0 &&
      data?.xAxis?.data.map((dataValue, index) => {
        {
          xAxisData[index] = { [data.xAxis.label]: dataValue };
        }
      });
      
      data?.yAxis?.series.length > 0 &&
      data?.yAxis?.series.forEach((ele) => {
        if (ele.label === "TPR") {
          ele.data.map((data, ind) => {
            {
              yAxisData[ind] = { [ele.label]: data };
            }
          });
        } else {
          tableData.push(ele);
        }
      });
      
      xAxisData &&
      yAxisData &&
      xAxisData.length > 0 &&
      xAxisData.map((item, index) => {
        finalData[index] = { ...xAxisData[index], ...yAxisData[index] };
      });  
    }
    
    const formattedData = {
      finalData: finalData,
      tableData: tableData,
    };
    
  return formattedData;
};

export const createStackedBarChartFormat = (data) => {

  const finalData = [];
  let valueData, goodData, badData;
  if(data){
    if(data?.xAxis?.data && data?.xAxis?.data.length > 0 && data?.yAxis?.series && data?.yAxis?.series.length > 0){
      valueData = data?.xAxis?.data;
      data?.yAxis?.series.map((item)=>{
        item.label === "Count_0: Good" ? goodData = item.data : badData = item.data;
      })


      valueData.map((item, index) => {
        finalData.push({ value: item, 1: goodData[index] , 2: badData[index] }); 
      })
    }
  }
    
    const formattedData = {
      finalData: finalData,
      yAxisText: data?.xAxis?.label,
    };
    
  return formattedData;
};