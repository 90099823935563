import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableFooter,
  TablePagination,
  Tooltip,
  tooltipClasses,
} from "@mui/material";
import {
  IconButton,
  Box,
  useTheme,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import {
  FirstPage,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  LastPage,
} from "@mui/icons-material";
import { useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";

import "../AdminUsetlistUserTable/AdminUsetlistUserTable.css";
import LinkIcon from "../../assets/images/adminPanel/LinkIcon.svg";
import Edit from "../../assets/images/detail/edit_annotation.svg";
import Delete from "../../assets/images/detail/delete_annotation.svg";
import CancelIcon from "../../assets/images/adminPanel/CancelIcon.svg";
import NewPolicy from "./NewPolicy";
import nodeApiInstance from "../../api/instance/nodeApiInstance";
import { queryKeyConstants } from "../../constants/basicConstants";
import { policyPage } from "../../constants/toasterMsgConstants";
import "./AdminPolicy.css";
import { dateFormate } from "../../utils/basicUtils";
import { isAccessProvided } from "../../services/assetManagementService";
import {
  moduleConstants,
  subModuleConstants,
} from "../../constants/accessManagementConstants";
import { PolicyNameConst } from "../../constants/policyConstants";

/**
 * Component : AdminPolicyTable
 *
 * Description : This component is responsible for displaying policy data in tabular format.
 * Component is made up of Mui basic Table . Columns are name, description, visibility, last modified date, edit & delete.
 * While clicking delete icon , delete dialog will display & delete the policy.
 * And for editing new component is called, with the pre defined policy information.
 *
 * Props : policyData
 */

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#FFFFFF",
    color: "#666666",
    border: " 0.5px solid #6D8291",
    boxShadow: "0px 5px 10px rgba(0, 0, 0, 0.25)",
    borderRadius: "4px",
    fontSize: 16,
    fontFamily: "Lato",
    fontWeight: 400,
  },
}));

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        id="buFirst"
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPage /> : <FirstPage />}
      </IconButton>
      <IconButton
        id="buBack"
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        id="buNext"
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        id="buLast"
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPage /> : <LastPage />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const AdminPolicyTable = ({ policyData, accessDataByRole }) => {
  let a = window.innerHeight <= 760 ? 6 : window.innerHeight <= 900 ? 8 : 10;
  const queryClient = useQueryClient();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(a);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false); // to show a confirmation dialog to delete a policy
  const [deletePolicyByID, setDeletePolicyByID] = useState(null);
  const [dataForEditPolicy, setDataForEditPolicy] = useState(null);
  const [openUpdatePolicyDialog, setOpenUpdatePolicyDialog] = useState(false); // update policy dialog
  const [isPolicyAlreadyAssigned, setIsPolicyAlreadyAssigned] = useState(false);

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - policyData?.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleDeleteConfirmationDialog = (ID) => {
    setDeletePolicyByID(ID);
    setOpenDeleteDialog(true);
  };

  const handleEditPolicy = (data) => {
    setOpenUpdatePolicyDialog(true);
    setDataForEditPolicy(data);
  };

  const deletePolicyMutation = useMutation(
    async () => {
      const data = await nodeApiInstance.delete(
        `/policy/delete-policy?score_id=${deletePolicyByID}`
      );
      return data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(queryKeyConstants.getAllPolicies);
        toast.success(policyPage.deletePolicySuccessMsg);
        setOpenDeleteDialog(false);
      },

      onError: (error) => {
        toast.error(policyPage.deletePolicyErrorMsg);
        setOpenDeleteDialog(false);
      },
    }
  );

  const handleDeletePolicy = () => {
    const payload = {
      score_id: deletePolicyByID,
    };
    deletePolicyMutation.mutate();
  };

  return (
    <div>
      <Table
        aria-label="simple table"
        sx={{ tableLayout: "fixed" }}
        id="policyTable"
      >
        <TableHead>
          <TableRow className="usertable-row-headings">
            <TableCell
              className="usertable-coloumn-headings"
              sx={{ width: "35%" }}
            >
              Name
            </TableCell>

            <TableCell
              className="usertable-coloumn-headings"
              sx={{ width: "50%" }}
            >
              Description
            </TableCell>

            <TableCell
              className="usertable-coloumn-headings"
              sx={{
                width: "20%", //width :"15%" delete icon
                textAlign: "left",
                // padding: "10px 0px 10px 0px !important",
              }}
            >
              Status
            </TableCell>

            <TableCell
              className="usertable-coloumn-headings"
              sx={{ width: "20%" }} //width :"15%" delete icon
            >
              Modified Date
            </TableCell>

            <TableCell
              className="usertable-coloumn-headings"
              sx={{ width: "15%" }}
            ></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {policyData &&
            policyData?.length > 0 &&
            (rowsPerPage > 0
              ? policyData.slice(
                  page * rowsPerPage,
                  page * rowsPerPage + rowsPerPage
                )
              : policyData
            ).map((data, index) => (
              <>
                <TableRow id={`policyTableRow${index}`} key={data.score_id}>
                  <TableCell className="usertable-coloumn text-ellipsis">
                    <LightTooltip title={data?.score_name} followCursor>
                      <span>{data.score_name}</span>
                    </LightTooltip>
                  </TableCell>

                  <TableCell className="usertable-coloumn text-ellipsis">
                    {data.description === "" || data.description === null ? (
                      "-"
                    ) : (
                      <LightTooltip title={data.description} followCursor>
                        <span>{data.description}</span>
                      </LightTooltip>
                    )}
                  </TableCell>

                  <TableCell className="usertable-coloumn">
                    {data?.status === null ? (
                      <span style={{ marginLeft: "10px" }}>-</span>
                    ) : (
                      <span
                        className={
                          data?.status &&
                          data.status.toUpperCase() === "PUBLISHED"
                            ? "statusPublishedBackground"
                            : "statusDraftBackground"
                        }
                      >
                        {`${data?.status?.toUpperCase()}`}
                      </span>
                    )}
                  </TableCell>

                  <TableCell className="usertable-coloumn">
                    {dateFormate(data.updatedAt)}
                  </TableCell>

                  <TableCell className="usertable-coloumn">
                    <LightTooltip
                      title={
                        data.url !== null && data.url !== ""
                          ? `Link to ${PolicyNameConst.policy} document`
                          : "Not available"
                      }
                      followCursor
                    >
                      <IconButton
                        id={`policyLinkIcon${index}`}
                        type="submit"
                        aria-label="Link"
                        style={{ padding: "4px 6px" }}
                        sx={{
                          border: "0.25px solid #58595b",
                          boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)",
                          borderRadius: "6px",
                          marginLeft: "5px",
                        }}
                        href={data.url}
                        target={"_blank"}
                      >
                        <img
                          src={LinkIcon}
                          alt="Link"
                          style={{ width: "14px" }}
                        />
                      </IconButton>
                    </LightTooltip>

                    {isAccessProvided(
                      moduleConstants.policy,
                      subModuleConstants.policy_view,
                      null,
                      "update",
                      accessDataByRole
                    ) === true && (
                      <LightTooltip title={"Edit"} followCursor>
                        <IconButton
                          id={`policyEditIcon${index}`}
                          type="submit"
                          aria-label="Edit"
                          style={{
                            padding: "4px 6px",
                          }}
                          sx={{
                            border: "0.25px solid #58595b",
                            boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)",
                            borderRadius: "6px",
                            marginLeft: "5px",
                            cursor:
                              data.score_name === "Human Impact" ||
                              data.score_name === "Konfer Confidence"
                                ? "not-allowed !important"
                                : "",
                          }}
                          onClick={() => {
                            data.score_name !== "Human Impact" &&
                              data.score_name !== "Konfer Confidence" &&
                              handleEditPolicy(data);
                          }}
                        >
                          <img
                            src={Edit}
                            alt="Edit"
                            style={{ width: "14px" }}
                          />
                        </IconButton>
                      </LightTooltip>
                    )}

                    {/* TODO: Commented for now, once the delete functionality supports, we will uncomment this */}
                    {/* {isAccessProvided(
                      moduleConstants.policy,
                      subModuleConstants.policy_view,
                      null,
                      "delete",
                      accessDataByRole
                    ) === true && (
                      <LightTooltip title={"Delete"} followCursor>
                        <IconButton
                          id={`policyDeleteIconIcon${index}`}
                          type="submit"
                          aria-label="Delete"
                          style={{ padding: "4px 6px" }}
                          sx={{
                            border: "0.25px solid #58595b",
                            boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)",
                            borderRadius: "6px",
                            marginLeft: "5px",
                          }}
                          onClick={() => {
                            handleDeleteConfirmationDialog(data.score_id);
                          }}
                        >
                          <img
                            src={Delete}
                            alt="Delete"
                            style={{ width: "14px" }}
                          />
                        </IconButton>
                      </LightTooltip>
                    )} */}
                  </TableCell>
                </TableRow>
              </>
            ))}
        </TableBody>
        <TableFooter>
          <TableRow
            sx={{
              "&:last-child td, &:last-child th": {
                border: 0,
                paddingRight: "50px",
              },
            }}
          >
            <TablePagination
              rowsPerPageOptions={[a]}
              colSpan={5}
              count={policyData?.length || 0}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />
          </TableRow>
        </TableFooter>
      </Table>

      <Dialog
        id="buDeleteDialog"
        open={openDeleteDialog}
        onClose={() => {
          setOpenDeleteDialog(!openDeleteDialog);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <IconButton
            id="buCloseDeleteDialog"
            aria-label="close"
            onClick={() => {
              setOpenDeleteDialog(false);
              setIsPolicyAlreadyAssigned(false);
            }}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <img src={CancelIcon} alt="Delete" />
          </IconButton>

          <p> {"Are you sure you want to delete this policy?"} </p>
        </DialogTitle>
        <DialogActions
        // sx={
        //   isBUAlreadyAssigned && {
        //     "&.MuiDialogActions-root": { justifyContent: "center" },
        //   }
        // }
        >
          <>
            <Button
              id="buCancelDeleteDialog"
              className="cancel-deleteUser-dialog"
              value="Cancel"
              onClick={() => {
                setOpenDeleteDialog(false);
              }}
              autoFocus
            >
              <span className="deleteUser-dialog-title">Cancel</span>
            </Button>
            <Button
              id="buConfrimDelete"
              className="delete-deleteUser-dialog"
              value="Delete"
              onClick={() => {
                handleDeletePolicy();
              }}
            >
              <span className="deleteUser-dialog-title">Delete</span>
            </Button>
          </>
        </DialogActions>
      </Dialog>

      {openUpdatePolicyDialog && (
        <NewPolicy
          openNewPolicyDialog={openUpdatePolicyDialog}
          setOpenNewPolicyDialog={setOpenUpdatePolicyDialog}
          edit={true}
          dataForEditPolicy={dataForEditPolicy}
        />
      )}
    </div>
  );
};

export default AdminPolicyTable;
