import React, { useEffect, useLayoutEffect, useState } from "react";
import * as am5 from "@amcharts/amcharts5";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import * as am5xy from "@amcharts/amcharts5/xy";

import { Box } from "@mui/system";

const LineChart = ({ data }) => {
  const uniqueDivId = Math.floor(Math.random() * 800000);
  
  useLayoutEffect(() => {
    // Create root element
    let root = am5.Root.new(`${uniqueDivId}`);

    // Set themes
    root.setThemes([am5themes_Animated.new(root)]);

    // Create chart
    var chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panX: false,
        panY: false,
        pinchZoomX: false,
      })
    );

    // Add cursor
    var cursor = chart.set(
      "cursor",
      am5xy.XYCursor.new(root, {
        behavior: "none",
      })
    );
    cursor.lineY.set("visible", false);

    // Create axes
    var xAxis = chart.xAxes.push(
      am5xy.ValueAxis.new(root, {
        renderer: am5xy.AxisRendererX.new(root, {}),
        tooltip: am5.Tooltip.new(root, {}),
      })
    );

    //Show title on Xaxis
    xAxis.children.push(
      am5.Label.new(root, {
        text: "False Postive Rate",
        x: am5.p50,
        centerX: am5.p50,
      })
    );

    var yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        title: "TPR",
        renderer: am5xy.AxisRendererY.new(root, {}),
      })
    );

    //Show title on Yaxis
    yAxis.children.unshift(
      am5.Label.new(root, {
        rotation: -90,
        text: "True Positve Rate",
        y: am5.p50,
        centerX: am5.p50,
      })
    );

    // Add series
    var series = chart.series.push(
      am5xy.LineSeries.new(root, {
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: "TPR",
        valueXField: "FPR",
        tooltip: am5.Tooltip.new(root, {
          labelText: "FPR:{valueX}, TPR:{valueY}",
        }),
      })
    );

    series.strokes.template.setAll({
      strokeWidth: 3,
    });

    series.data.setAll(data.finalData);

    // Make stuff animate on load
    series.appear(1000);

    return () => {
      root.dispose();
    };
  }, []);

  return (
    <div>
      <Box
        id={uniqueDivId}
        style={{
          height: "50vh",
          width: "100%",
        }}
      ></Box>
      <div className="table_container" >
        {data && data.tableData && (
          <table className="table_border" >
              <tr  className="table_border">
                  {data.tableData.map(t => t.label === "AUC" && <td  className="table_border">{t.label}: {t.data}</td>)}
              </tr>
          </table>
        )}
      </div>
    </div>
  );
};
export default LineChart;
