import React, { useEffect, useState } from "react";
import { useAtom } from "jotai";
import Feedback from "../components/Feedback/Feedback";

// custom imports
import Navbar from "../components/Navbar/Navbar";
import Sidebar from "../components/Sidebar/Sidebar";
import "./Layout.css";
import {
  authUser,
  detailBreadcrumbsData,
  openCPOAnnotationsAtom,
  openSideBarAtom,
} from "../atom/jotai";
import { analyticServices } from "../services/analyticServices";
import { Outlet } from "react-router-dom";

const Layout = ({ children, breadcrumbData }) => {
  // const [open, setOpen] = useState(false); //State for maintaing sidebar open or close
  const [user] = useAtom(authUser);
  const [openSideBar, setOpenSideBar] = useAtom(openSideBarAtom); //State for maintaing sidebar open or close
  const [openCPOAnnotations, setOpenCPOAnnotations] = useAtom(
    openCPOAnnotationsAtom
  ); //State for maintaing Annotations on compliance open or close

  const [detailBreadcrumbs, setDetailBreadCrumbs] = useAtom(
    detailBreadcrumbsData
  );
  const handleDrawerOpen = () => {
    // setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpenSideBar(!openSideBar);
    if (openCPOAnnotations === true) {
      setOpenCPOAnnotations(!openCPOAnnotations);
    }
  };
  // console.log("brrrrrr--", detailBreadcrumbs, breadcrumbData);

  return (
    <div style={{ height: "100vh", overflow: "hidden" }} id="mainLayoutDiv">
      <div className="gridFr1" id="appbarDiv">
        <Navbar breadcrumbData={breadcrumbData} />
      </div>
      <div
        id="sidebarAndChildrenDiv"
        className="gridFr2"
        style={openSideBar ? { gridTemplateColumns: "215px 1fr" } : {}}
      >
        <Sidebar
          open={openSideBar}
          handleDrawerOpen={handleDrawerOpen}
          handleDrawerClose={handleDrawerClose}
        />
        <div id="MAINDIV" style={{ overflowX: "auto" }}>
          {<Outlet />}
        </div>
      </div>
    </div>
  );
};

export default Layout;
