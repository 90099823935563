import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import "./AdminDataFieldTable.css";
// import data from "./data.json";
import riskAppSvg from "../../assets/images/listview/app-chip.svg";

import riskDataSetSvg from "../../assets/images/adminPanel/Dataset.svg";
import riskModelSvg from "../../assets/images/listview/model-chip.svg";
import AdminRiskTableData from "../AdminRiskTableData/AdminRiskTableData";
import {
  useGetRiskFactors,
  useGetRiskFactorsByScoreId,
} from "../../api/readApiFunctions";
import { useUpdateRiskVisibility } from "../../api/updateApiFunctions";
import { ToastContainer, toast } from "react-toastify";
import { useAtom } from "jotai";
import { isAdminRiskFactorUpdate, isSidebar } from "../../atom/jotai";
import { analyticServices } from "../../services/analyticServices";
import { adminRiskFactor } from "../../constants/adminConfigConstant";
import { isAccessProvided } from "../../services/assetManagementService";
import {
  actionConstants,
  moduleConstants,
  subModuleConstants,
} from "../../constants/accessManagementConstants";
import { entityNameConst } from "../../constants/dataTypes";

const AdminDataFieldTable = ({ selectedScore, accessDataByRole }) => {
  let arr = [
    { name: "Applications", img: riskAppSvg },
    { name: "Model", img: riskModelSvg },
    { name: "Datasets", img: riskDataSetSvg },
  ];

  const {
    mutate: updateRiskVisibility,
    isSuccess,
    error,
    data,
  } = useUpdateRiskVisibility();

  const {
    data: riskFactorsByScore,
    isLoading,
    refetch,
  } = useGetRiskFactorsByScoreId(selectedScore);

  const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);

  const [riskFactorUpdate, setRiskFactorUpdate] = useAtom(
    isAdminRiskFactorUpdate
  );

  const handleSaveChanges = () => {
    if (selectedCheckboxes.length > 0) {
      updateRiskVisibility(selectedCheckboxes);
      // setIsBlocking(true);
      analyticServices(`Risk factors updated `);
      setRiskFactorUpdate(false);
      toast.success("Risk Factors updated", {
        toastId: "test1",
      });
      setSelectedCheckboxes([]);
    }
  };

  /**below useEffect is used to clear the updated data
   * which is send in payload,. Only semding 1 policy data at a time
   */
  useEffect(() => {
    setSelectedCheckboxes([]);
  }, [selectedScore]);

  // update the risk factors
  useEffect(() => {
    refetch();
  }, [data]);

  if (error) {
    toast.error(error.response.data.error, {
      toastId: "test3",
    });
  }

  return (
    <>
      <Box
        // className="admin_table_main_container"
        sx={{
          paddingRight: "20px",
          paddingLeft: "20px",
        }}
        id="adminRFConatiner"
      >
        <Box
          // className="admin_table_container"

          id="adminRFTableConatiner"
        >
          <div
            style={{
              display: "grid",
              // gridTemplateColumns: "1fr 1fr 1fr",  {/** For the demo purpose commented the below code */}
              gridTemplateColumns: "1fr 1fr",
              // borderBottom: "1px solid #6d8291",//Commented for cme demo only
            }}
          >
            <div>
              <>
                <div style={{ marginBottom: "60px" }}>
                  <Box className="admin_data_type_heading">
                    <Box className="admin_data_type_heading_content">
                      <img src={riskAppSvg} alt="risImg" className="head_img" />
                      {entityNameConst.applications}
                    </Box>
                    <Box className="admin_inner_data_type_heading">
                      <p className="risk_factor_field">Field Name</p>
                      <p>Visibility</p>
                    </Box>
                  </Box>
                </div>
              </>
              <div
                style={{
                  height: "50vh",
                  overflow: "auto",
                }}
              >
                {riskFactorsByScore?.map((item, index) => {
                  return (
                    <>
                      <div>
                        <AdminRiskTableData
                          riskName={item.factor_name}
                          riskVisibility={item.is_applies_to_app}
                          riskId={item.confidence_factors_config_id}
                          setSelectedCheckboxes={setSelectedCheckboxes}
                          selectedCheckboxes={selectedCheckboxes}
                          selectedScore={selectedScore}
                          type="app"
                          key={`${item.factor_name}${item.confidence_factors_config_id}app`}
                          accessDataByRole={accessDataByRole}
                          lastIteration={
                            riskFactorsByScore?.length > 0 &&
                            riskFactorsByScore?.length - 1 === index
                              ? true
                              : false
                          }
                        />
                      </div>
                    </>
                  );
                })}
              </div>
            </div>
            <div>
              <>
                <div style={{ marginBottom: "60px" }}>
                  <Box className="admin_data_type_heading">
                    <Box className="admin_data_type_heading_content">
                      <img
                        src={riskModelSvg}
                        alt="risImg"
                        className="head_img"
                      />
                      {entityNameConst.models}
                    </Box>
                    <Box className="admin_inner_data_type_heading">
                      <p className="risk_factor_field">Field Name</p>
                      <p>Visibility</p>
                    </Box>
                  </Box>
                </div>
              </>
              <div
                style={{
                  height: "50vh",
                  overflow: "auto",
                }}
              >
                {riskFactorsByScore?.map((item, index) => {
                  return (
                    <>
                      <AdminRiskTableData
                        riskName={item.factor_name}
                        riskVisibility={item.is_applies_to_model}
                        riskId={item.confidence_factors_config_id}
                        setSelectedCheckboxes={setSelectedCheckboxes}
                        selectedCheckboxes={selectedCheckboxes}
                        selectedScore={selectedScore}
                        type="model"
                        key={`${item.factor_name}${item.confidence_factors_config_id}model`}
                        accessDataByRole={accessDataByRole}
                        lastIteration={
                          riskFactorsByScore?.length > 0 &&
                          riskFactorsByScore?.length - 1 === index
                            ? true
                            : false
                        }
                      />
                    </>
                  );
                })}
              </div>
            </div>

            {/** For the demo purpose commented the below code */}
            {/* <div>
              <>
                <div style={{ marginBottom: "60px" }}>
                  <Box className="admin_data_type_heading">
                    <Box className="admin_data_type_heading_content">
                      <img
                        src={riskDataSetSvg}
                        alt="risImg"
                        className="head_img"
                      />
                      {entityNameConst.datasets}
                    </Box>
                    <Box className="admin_inner_data_type_heading">
                      <p className="risk_factor_field">Field Name</p>
                      <p>Visibility</p>
                    </Box>
                  </Box>
                </div>
              </>
              <div
                style={{
                  height: "50vh",
                  overflow: "auto",
                }}
              >
                {riskFactorsByScore?.map((item, index) => {
                  return (
                    <>
                      <AdminRiskTableData
                        riskName={item.factor_name}
                        riskVisibility={item.is_applies_to_input_data_table}
                        riskId={item.confidence_factors_config_id}
                        setSelectedCheckboxes={setSelectedCheckboxes}
                        selectedCheckboxes={selectedCheckboxes}
                        selectedScore={selectedScore}
                        type="input_data_table"
                        key={`${item.factor_name}${item.confidence_factors_config_id}input_data_table`}
                        accessDataByRole={accessDataByRole}
                         lastIteration={
                          riskFactorsByScore?.length > 0 &&
                          riskFactorsByScore?.length - 1 === index
                            ? true
                            : false
                        }
                      />
                    </>
                  );
                })}
              </div>
            </div> */}
          </div>
        </Box>
      </Box>
      {isAccessProvided(
        moduleConstants.admin_view,
        subModuleConstants.risk_factors,
        actionConstants.visibility,
        "execution",
        accessDataByRole
      ) === true && (
        <Box
          className={"table_risk_btn"}
          sx={{
            marginTop: "20px",
          }}
        >
          <button
            className={`${
              riskFactorUpdate
                ? "userlist-createNewUser-button-enable"
                : "userlist-createNewUser-button"
            } table_risk_btn`}
            disabled={!riskFactorUpdate}
            onClick={riskFactorUpdate && handleSaveChanges}
          >
            Update
          </button>
        </Box>
      )}
      <ToastContainer />
    </>
  );
};

export default AdminDataFieldTable;
