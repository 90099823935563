import { Tooltip, tooltipClasses } from "@mui/material";
import React, { useEffect } from "react";
import { styled } from "@mui/material/styles";
import "./CFGraphviewBarChart.css";
import { riskFactorValueCond } from "../../../constants/riskFactorMonth";

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#FFFFFF",
    color: "#000000",
    border: " 1px solid #C4C4C4",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    fontSize: 16,
    fontFamily: "Lato",
    fontWeight: 700,
  },
}));

const CFGraphviewBarChart = ({ factors }) => {
  const barHeightValue = (data) => {
    let heightVal;
    if (String(Math.abs(Math.round(data))).charAt(0) == Math.round(data)) {
      if (data === -1) {
        heightVal = 0;
      } else if (
        data === 0 ||
        data === riskFactorValueCond.ntAplicabl ||
        data === riskFactorValueCond.nullCondition
      ) {
        heightVal = 1;
      } else if (data <= 9) {
        heightVal = 1;
      } else {
        heightVal = (data / 10) * 2;
      }
    } else {
      if (data === -1) {
        heightVal = 0;
      } else if (
        data === 0 ||
        data === riskFactorValueCond.ntAplicabl ||
        data === riskFactorValueCond.nullCondition
      ) {
        heightVal = 1;
      } else if (data <= 9) {
        heightVal = 1;
      } else {
        heightVal = (data / 10) * 2;
      }
    }
    return heightVal;
  };

  const barTopMargin = (hgt) => {
    let mrgnTop;
    if (hgt === 20) {
      mrgnTop = 0;
    } else if (hgt < 20) {
      mrgnTop = 15 - hgt;
    }
    return mrgnTop;
  };

  return (
    <LightTooltip
      placement="top"
      sx={{ m: 1 }}
      // for demo purpose, i removed dataset title
      title={`${
        factors.appRiskAvg !== null &&
        factors.appRiskAvg !== Number(riskFactorValueCond.ntAplicabl)
          ? Math.round(factors.appRiskAvg)
          : "-"
      }, 
      ${
        factors.modelRiskAvg !== null &&
        factors.modelRiskAvg !== Number(riskFactorValueCond.ntAplicabl)
          ? Math.round(factors.modelRiskAvg)
          : "-"
      },
    
      `}
      followCursor
    >
      <div className="barChart">
        <div style={{ display: "flex", flexDirection: "column-reverse" }}>
          <div
            className="appBar"
            style={{
              height: `${barHeightValue(factors.appRiskAvg)}px`,
              marginTop:
                factors.appRiskAvg <= 9
                  ? `${barTopMargin(barHeightValue(factors.appRiskAvg))}px`
                  : "",
            }}
          ></div>{" "}
        </div>
        <div style={{ display: "flex", flexDirection: "column-reverse" }}>
          <div
            className="modelBar"
            style={{
              height: `${barHeightValue(factors.modelRiskAvg)}px`,
              marginTop:
                factors.modelRiskAvg <= 9
                  ? `${barTopMargin(barHeightValue(factors.modelRiskAvg))}px`
                  : "",
            }}
          ></div>
        </div>

        {/*   // for demo purpose, i removed dataset */}
        {/* <div style={{ display: "flex", flexDirection: "column-reverse" }}>
          <div
            className="datasetBar"
            style={{
              height: `${barHeightValue(factors.inputDataTableRiskAvg)}px`,
              marginTop:
                factors.inputDataTableRiskAvg <= 9
                  ? `${barTopMargin(
                      barHeightValue(factors.inputDataTableRiskAvg)
                    )}px`
                  : "",
            }}
          ></div>
        </div> */}
      </div>
    </LightTooltip>
  );
};

export default CFGraphviewBarChart;
