import React from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";

const borderColor = "#90e5fc";
const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    borderBottomColor: "#bff0fd",
    backgroundColor: "#bff0fd",
    borderBottomWidth: 1,
    alignItems: "center",
    textAlign: "center",
    fontStyle: "bold",
    padding: "2px",
    overflowWrap: "anywhere",
  },
  question: {
    width: "40%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    // padding: "5px",
    overflowWrap: "anywhere",
  },
  section: {
    width: "10%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
  },

  answer: {
    width: "50%",
    // borderRightColor: borderColor,
    // borderRightWidth: 1,
  },
});

const PDFQuestionnaireTableHeader = () => (
  <View style={styles.container}>
    <Text style={styles.section}>Section</Text>
    {/* <Text style={styles.section}>Subsection</Text> */}
    <Text style={styles.question}>Question</Text>
    <Text style={styles.answer}>Answer</Text>
  </View>
);

export default PDFQuestionnaireTableHeader;
