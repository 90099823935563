import { Checkbox } from "@mui/material";
import React, { useState } from "react";
import {
  detailsFieldsNames,
  disabledEditFields,
} from "../../constants/detailConstants";
import "./AdminCheckbox.css";
import { isAccessProvided } from "../../services/assetManagementService";
import {
  actionConstants,
  moduleConstants,
  subModuleConstants,
} from "../../constants/accessManagementConstants";

const AdminCheckbox = ({
  name,
  val,
  type,
  setSelectedCheckboxes,
  setAllSelected,
  accessDataByRole,
}) => {
  const [checked, setChecked] = useState(val);
  const [disable, setDisable] = useState(val);

  const handleCheckClick = (e) => {
    const checkboxVal = e.target.checked;

    if (disabledEditFields.includes(name)) {
      return;
    }
    if (name === "All") {
      setAllSelected((prevState) => ({
        ...prevState,
        [type]: checkboxVal,
      }));
      setChecked(!checked);
    } else {
      const payload = {
        type,
        name,
        val: checkboxVal,
      };
      setSelectedCheckboxes((prevState) => [...prevState, payload]);
      setChecked(!checked);
    }
  };

  return (
    <div className="checkbox_wrapper">
      {isAccessProvided(
        moduleConstants.admin_view,
        subModuleConstants.asset_metadata,
        actionConstants.modify,
        "execution",
        accessDataByRole
      ) === true ? (
        disabledEditFields.includes(name) ? (
          <>
            <p className="disabled">{detailsFieldsNames[name]}</p>

            <Checkbox
              sx={{ color: "#999999" }}
              checked={disable}
              disabled
              color="default"
            />
          </>
        ) : (
          <>
            <p>{detailsFieldsNames[name]}</p>

            <Checkbox
              checked={checked}
              sx={{
                color: "#6D8291",
                ":hover": { background: "none" },
              }}
              color="default"
              onClick={handleCheckClick}
            />
          </>
        )
      ) : (
        <>
          <p className="disabled">{detailsFieldsNames[name]}</p>

          <Checkbox
            sx={{ color: "#999999" }}
            checked={disable}
            disabled
            color="default"
          />
        </>
      )}
    </div>
  );
};

export default AdminCheckbox;
