import React from "react";
import { jsPDF } from "jspdf";
import { renderToString } from "react-dom/server";
import "./Cards.css";
import GenerateReprtIcon from "../../assets/images/dataSetCard/GenerateReportIcon.svg";
import GenerateReport from "../GenerateReport/GenerateReport";
import { useGetRiskFactorData } from "../../api/readApiFunctions";
import { MutationKeyName } from "../../constants/dataTypes";

/**
 * Component : GenerateReportIcon
 *
 * Description : This component is responsible for showing the icon of generate report.
 * While clicking , it downloads the pdf for the current cards.
 * For generating report, jspdf library is used
 *
 * Props : data - Actual response of a card getting from database,
           cardType - type of a crad, app/model/dataset,
           cardFilterWithPolicy - state for showing the filtered policies,
 *  */

const GenerateReportIcon = ({ data, cardType, cardFilterWithPolicy }) => {
  var doc = new jsPDF("p", "pt", "a4", true);

  const {
    data: riskFactorData,
    riskFactorError,
    riskFactorIsLoading,
  } = useGetRiskFactorData(data?.card_type, data?.reference_id, true);

  return (
    riskFactorData && (
      <button
        className="generate_report"
        onClick={() => {
          const string = renderToString(
            <div
              style={{
                width: "600px",
                padding: "1rem",
                margin: "0px 0px 12px 0px",
              }}
            >
              <GenerateReport
                data={data}
                cardType={cardType}
                listCardData={data?.sections[0]?.entity}
                riskFactorData={riskFactorData}
                doc={doc}
                cardFilterWithPolicy={cardFilterWithPolicy}
              />
            </div>
          );
          doc.html(string, {
            callback: function (doc) {
              doc.save(
                `${
                  !data?.display_name && data.display_name === ""
                    ? data.name
                    : data?.display_name
                }.pdf`
              );
            },

            autoPaging: "text",
          });
        }}
      >
        <img
          style={{
            width: "20px",
            height: "20px",
          }}
          alt="Generate Report Icon to generate report"
          className="datasetEditIcon icon_background"
          src={GenerateReprtIcon}
        />
      </button>
    )
  );
};

export default GenerateReportIcon;
