import { PolicyNameConst } from "./policyConstants";

export const adminRoleManagementPage = {
  dataDeleteMsg: "Role deleted successfully",
  roleCreateMsg: "Role created successfully",
  roleUpdateMsg: "Role updated successfully",
  roleAlreadyExisted: "Role already existed",
};

export const policyPage = {
  addPolicySuccessMsg: `${PolicyNameConst.policy} added successfully`,
  addPolicyErrorMsg: `Something went wrong`,
  updatePolicySuccessMsg: `${PolicyNameConst.policy} updated successfully`,
  updatePolicyErrorMsg: `Something went wrong`,
  deletePolicySuccessMsg: `${PolicyNameConst.policy} deleted successfully`,
  deletePolicyErrorMsg: `Something went wrong`,
};

export const adminConfigurationPage = {
  adminRequestFailMsg: "Could not complete your request, please try again",
  resetDBSucess: "Database reset successfully",
};

export const cardsPageToasts = {
  cardUpdateSuccessful: "Card Updated Successfully",
  importCsvSuccessful: "Response uploaded successfully",
  importCsvErrorMessage: "File upload failed. Please try again",
};

export const listviewTableToasts = {
  scoreInclusionApiErr:
    "Score Inclusion/Exclusion Updation failed. Please try again",
};

export const cardTemplateToasts = {
  saveTemplateClick: `${PolicyNameConst.policy} added successfully`,
  saveDraftClick: "Something went wrong",
  savePublishClick: `${PolicyNameConst.policy} updated successfully`,
};

export const adminBusinessUnitPageError = {
  buAlreadyAssigned:
    "This Business Unit is associated with one or more Objects.",
};

export const extractionToasts = {
  extractionFinished:
    "Extraction process completed successfully! Go to Extraction History Tab for further details",
  ErrLogFileDwnldFailed: "Something went wrong, please try again",
};

export const genAiToasts = {
  GenAICustomQueSuccessMsg: "Response for question generated.",
  genAITemplateQuestionSuccessToast: "Response for question generated.",
};

export const nlqQueryToasts = {
  nlqQueryFailed:
    "Your query failed. Rephrase it and try again or use a simpler keyword search",
};
