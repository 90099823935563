import React, { useState, useEffect } from "react";
import {
  IconButton,
  Dialog,
  DialogTitle,
  Typography,
  DialogContent,
  MenuItem,
  TextField,
  TextareaAutosize,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import Select from "react-select";
import { toast } from "react-toastify";
import CancelIcon from "../../../assets/images/adminPanel/CancelIcon.svg";
import "../UpdateNodeBusinessunitRel/UpdateNodeBusinessunitRel.css";
import { useMutation, useQueryClient } from "react-query";
import { queryKeyConstants } from "../../../constants/basicConstants";
import nodeApiInstance from "../../../api/instance/nodeApiInstance";
import {
  AssignBUDataTypes,
  AssignBUKeyName,
  AssignBUTypes,
} from "../../../constants/adminConfigConstant";
import { analyticServices } from "../../../services/analyticServices";
import { entityNameConst } from "../../../constants/dataTypes";

const NodeBUAssignee = ({
  openNodeBUAssignee,
  setOpenNodeBUAssignee,
  nodeData,
  selectedType,
  editNodeData,
  regionData,
}) => {
  const [optionsForBusinessUnit, setOptionsForBusinessUnit] = useState([]);
  const [selectedBusinessUnit, setSelectedBusinessUnit] = useState([]);
  const [selectedNodeDataName, setSelectedNodeDataName] = useState();
  const [selectedRegion, setSelectedRegion] = useState(null);
  const [optionsForRegion, setOptionsForRegion] = useState([]);
  const [selectedNodeId, setSelectedNodeID] = useState(0);
  const [isDisabled, setIsDisabled] = useState(false);

  const queryClient = useQueryClient();
  const endpoint = `${process.env.REACT_APP_GRAPHQL_API}v1/graphql`;

  //convert region data in the format of react select options
  useEffect(() => {
    let op = [];
    regionData &&
      regionData.length > 0 &&
      regionData?.map((region, index) => {
        op.push({
          value: region.region_id,
          label: region.name,
        });
      });
    setOptionsForRegion(op);
  }, [regionData]);

  // form validation rules
  const validationSchema = Yup.object().shape({
    region_id: Yup.string().required("Region is required"),
    business_units: Yup.array().required("Business units is required"),
  });
  const formOptions = { resolver: yupResolver(validationSchema) };

  //User Form
  const {
    register,
    handleSubmit,
    setError,
    setValue,
    clearErrors,
    getValues,
    formState: { errors },
  } = useForm(formOptions);

  /** Below useEffect is for adding the business units and regions for editing the node*/
  useEffect(() => {
    let arrayForBusinessUnitSelected = [];
    let arrayForBusinessUnitSelectedID = [];

    //Adding default values for business units of seleted node if it's available
    editNodeData[`${selectedType}_business_units`]?.map((data) => {
      arrayForBusinessUnitSelected.push({
        value: data.business_unit_id,
        label: data.business_unit.name,
      });
      arrayForBusinessUnitSelectedID.push(data.business_unit_id);
    });
    setSelectedBusinessUnit(arrayForBusinessUnitSelected);
    setValue("business_units", arrayForBusinessUnitSelectedID);

    //Adding default value for region of seleted node if it's available
    editNodeData[`${selectedType}_business_units`]?.map((data, index) => {
      if (index < 1) {
        setSelectedRegion({
          value: data.business_unit.region.region_id,
          label: data.business_unit.region.name,
        });
        setValue("region_id", data.business_unit.region.region_id);
        setIsDisabled(true);

        //Adding options of business units for selectd node as per region
        let op = [];
        regionData?.map((item) => {
          if (item?.region_id == data?.business_unit.region.region_id) {
            item?.business_unit?.map((businessUnit) => {
              businessUnit.name !== "Admin" &&
                op.push({
                  value: businessUnit.business_unit_id,
                  label: businessUnit.name,
                });
            });
          }
        });
        setOptionsForBusinessUnit(op);
      }
    });

    //Adding default value for node name of seleted node
    setSelectedNodeDataName(
      editNodeData.display_name === null || editNodeData.display_name === ""
        ? editNodeData.name
        : editNodeData.display_name
    );
    setSelectedNodeID(editNodeData[AssignBUKeyName[selectedType]]);
  }, [nodeData]);

  //API call for updating assigned business units in the database
  const updateNodeBUAssignee = useMutation(
    async (payload) => {
      const data = await nodeApiInstance.put(
        "/business_unit/update-business-units-for-type",
        payload
      );
      return data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(queryKeyConstants.getAllNodeBuRelData);
        toast.success("Business unit updated");
        nodeData?.map((data) => {
          data[AssignBUKeyName[selectedType]] === selectedNodeId &&
            analyticServices(
              `Business unit is updated for ${
                data.display_name === null ? data.name : data.display_name
              }`
            );
        });
      },
      onError: (error) => {
        toast.error(error.response.data.error);
      },
    }
  );

  //Assigning business units to nodes
  const handleRegistration = (data) => {
    delete data.name;
    delete data.region_id;
    if (!data.hasOwnProperty("business_units")) {
      //if none of the business unit is selected
      data.business_units = [];
    }

    if (data.business_units.length === 0) {
      setError("business_units", {
        type: "custom",
        message: `Business units is required`,
      });
    } else {
      updateNodeBUAssignee.mutate({
        ...data,
        id: [selectedNodeId],
        type: AssignBUDataTypes[selectedType],
      });

      setOpenNodeBUAssignee(false);
    }
  };

  //Business unit selection function
  const handleChange = (values) => {
    let arrayForBusinessUnit = [];
    //For setting Business ID for saving in database
    values.map((val) => {
      arrayForBusinessUnit.push(val.value);
    });
    setSelectedBusinessUnit(values);
    setValue("business_units", arrayForBusinessUnit);
    clearErrors("business_units");
  };

  const handleRegionChange = (regionId) => {
    let op = [];
    regionData?.map((data) => {
      if (data.region_id == regionId) {
        data.business_unit?.map((businessUnit) => {
          businessUnit.name !== "Admin" &&
            op.push({
              value: businessUnit.business_unit_id,
              label: businessUnit.name,
            });
        });
      }
    });
    setOptionsForBusinessUnit(op);
  };

  const handleError = (errors) => {};

  return (
    <>
      <Dialog
        open={openNodeBUAssignee}
        onClose={() => {
          setOpenNodeBUAssignee(!openNodeBUAssignee);
        }}
        aria-labelledby="new-user-dialog"
        aria-describedby="new-user-dialog"
        sx={{
          "& .MuiDialog-paper": {
            boxShadow: "0px 6px 10px rgba(0, 0, 0, 0.3)",
            borderRadius: "8px",
            width: "675px",
            height: "379px",
          },
        }}
      >
        <DialogTitle id="new-user-dialog" sx={{ m: 0, p: 3 }}>
          <Typography variant="h5" component="div" className="create-heading">
            Business Unit Assignment
          </Typography>
          <IconButton
            aria-label="close"
            onClick={() => {
              setOpenNodeBUAssignee(false);
            }}
            sx={{
              position: "absolute",
              right: 16,
              top: 27,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <img src={CancelIcon} alt="Delete" />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ position: "relative", p: 3 }}>
          <form
            onSubmit={handleSubmit(handleRegistration, handleError)}
            autoComplete="off"
            style={{ marginTop: "10px" }}
          >
            <div className="display-row">
              <label className="display-label">
                {entityNameConst.applications}
              </label>
              <div className="display-input" {...register("name")}>
                {selectedNodeDataName && selectedNodeDataName}
              </div>
            </div>

            <div className="display-row" id="NodeRBUAssignRegionRow">
              <label className="display-label">Region*</label>
              <Select
                id="NodeRBUAssign-region"
                value={selectedRegion && selectedRegion}
                isClearable={false}
                isDisabled={isDisabled}
                styles={{
                  container: (base) => ({
                    ...base,
                    focusVisible: {
                      outline: "none",
                    },
                  }),
                  control: (base) => ({
                    ...base,
                    marginTop: "-8px",
                    border: "none",
                    borderBottom: " 1px solid #6d8291",
                    borderRadius: "2px",
                  }),
                }}
                maxMenuHeight="100px"
                onChange={(event) => {
                  clearErrors("region_id");
                  setSelectedRegion(event);
                  if (event.label) {
                    setValue("region_id", event.value);
                    handleRegionChange(event.value);
                  } else {
                    setValue("region_id", "");
                  }
                }}
                options={optionsForRegion}
              />
            </div>
            {errors?.region_id && errors.region_id.message && (
              <div
                className="display-row-erros"
                id="NodeRBUAssignRegionRowError"
              >
                <div></div>
                <small className="text-danger">
                  {errors?.region_id && errors.region_id.message}
                </small>
              </div>
            )}

            <div className="display-row">
              <label className="display-label">Business Units</label>
              <Select
                value={selectedBusinessUnit && selectedBusinessUnit}
                isMulti
                isClearable={false}
                isDisabled={
                  optionsForBusinessUnit && optionsForBusinessUnit.length === 0
                    ? true
                    : false
                }
                styles={{
                  multiValue: (base) => ({
                    ...base,
                    background: " #FFFFFF",
                    border: " 0.2px solid #6D8291",
                    boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.25)",
                    borderRadius: "13px",
                  }),
                  control: (base) => ({
                    ...base,
                    // height: "24px",
                    border: "none",
                    borderBottom: " 1px solid #6d8291",
                    borderRadius: "2px",
                    marginTop: "-18px",
                  }),
                }}
                maxMenuHeight="100px"
                onChange={(event) => {
                  handleChange(event);
                }}
                options={optionsForBusinessUnit}
              />
            </div>
            {errors?.business_units && errors.business_units.message && (
              <div className="display-row-erros">
                <div></div>
                <small className="text-danger">
                  {errors?.business_units && errors.business_units.message}
                </small>
              </div>
            )}

            <button className="update-assignedBU-button">
              <span>Save</span>
            </button>
          </form>
          <button
            className="cancel-update-assignedBU-button"
            onClick={() => {
              setOpenNodeBUAssignee(false);
            }}
          >
            <span>Cancel</span>
          </button>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default NodeBUAssignee;
