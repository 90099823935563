import {
  Button,
  Checkbox,
  FormControlLabel,
  IconButton,
  Modal,
  Typography,
} from "@mui/material";
import DOMPurify from "dompurify";
import { Box } from "@mui/system";
import React, { useState } from "react";
import { useAtom } from "jotai";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "./TermsConditionPage.css";
import CancelIcon from "../../assets/images/adminPanel/CancelIcon.svg";
import {
  AccessServiceOne,
  AccessServiceThree,
  AccessServiceTwo,
  ContentAndRelatedOutputsFour,
  ContentAndRelatedOutputsOne,
  ContentAndRelatedOutputsThree,
  ContentAndRelatedOutputsTwo,
  FeesOne,
  FeesThree,
  FeesTwo,
  GeneralEight,
  GeneralEntireAgreement,
  GeneralFive,
  GeneralFour,
  GeneralNine,
  GeneralOne,
  GeneralSeven,
  GeneralSix,
  GeneralThree,
  GeneralTwo,
  NoWarrantiesEight,
  NoWarrantiesFive,
  NoWarrantiesFour,
  NoWarrantiesNine,
  NoWarrantiesOne,
  NoWarrantiesSeven,
  NoWarrantiesSix,
  NoWarrantiesThree,
  NoWarrantiesTwo,
  ProprietaryRightsFive,
  ProprietaryRightsFour,
  ProprietaryRightsOne,
  ProprietaryRightsThree,
  ProprietaryRightsTwo,
  TermsDataOne,
  TermsDataTwo,
  UseOfService,
  UseOfServiceEight,
  UseOfServiceEleven,
  UseOfServiceElevenA,
  UseOfServiceElevenB,
  UseOfServiceElevenC,
  UseOfServiceElevenD,
  UseOfServiceElevenE,
  UseOfServiceElevenF,
  UseOfServiceFive,
  UseOfServiceFour,
  UseOfServiceNine,
  UseOfServiceNotToAgree,
  UseOfServiceOne,
  UseOfServiceSeven,
  UseOfServiceSix,
  UseOfServiceTen,
  UseOfServiceThree,
  UseOfServiceTwo,
} from "../../constants/termsAndConditions";
import { authUser } from "../../atom/jotai";
import { useUpdateTermsAndCondAcceptance } from "../../api/updateApiFunctions";
import { logoutUser } from "../../utils/authUtils";
import { analyticServices } from "../../services/analyticServices";
import { useEffect } from "react";

const style = {
  position: "relative",
  // top: "98.76px",
  // left: "20%",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "700.02px",
  height: "75%",
  border: "none !important",
  bgcolor: "#FFFFFF",
  boxShadow: "0px 6px 10px rgba(0, 0, 0, 0.3)",
  borderRadius: "8px",
  p: 3,
};

const TermsConditionPage = ({ openTermsCondModal, setOpenTermsCondModal }) => {
  const navigate = useNavigate();
  const [termsCondAccept, setTermsCondAccept] = useState(false);
  const [user] = useAtom(authUser);
  const [redirectToHome, setReDirectToHome] = useState(false);

  // react -query muatations
  const {
    mutate: updateTermsAndCondition,
    data: updateTermsConditionSuccess,
    status,
  } = useUpdateTermsAndCondAcceptance();

  useEffect(() => {
    if (
      updateTermsConditionSuccess !== undefined &&
      updateTermsConditionSuccess?.data?.success === true
    ) {
      navigate("/");
      analyticServices("Terms and conditions Accepted");
      setOpenTermsCondModal(false);
    } else if (updateTermsConditionSuccess !== undefined) {
      logoutUser();
      navigate("/login");
      analyticServices(`sign_out`);
      setOpenTermsCondModal(false);
    }
  }, [status]);

  //Below function is called when user clicked on terms & cond. checkbox
  const handleTermsCondAccept = (event) => {
    setTermsCondAccept(event.target.checked);
  };

  //Below function is called when the user cancel the terms & cond. modal
  const handleCloseTermsContModal = () => {
    toast.error(`Please accept the Terms and Conditions to login `);
    setOpenTermsCondModal(false);
    logoutUser();
    navigate("/login");
    analyticServices(`sign_out`);
  };

  //Below functions is called when user accepted the terms & cond. and continue
  const handleTermsCondAcceptButton = () => {
    const payload = {
      contributerId: user.contributor_id,
      terms_accepted: termsCondAccept,
      modInvoker: "terms_conditions",
      subModInvoker: "terms_pages",
    };
    updateTermsAndCondition(payload);
  };

  return (
    <Modal
      open={openTermsCondModal}
      onClose={handleCloseTermsContModal}
      aria-labelledby="modal-Terms&Cond-title"
      aria-describedby="modal-Terms&Cond-description"
    >
      <Box sx={style}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <span
            id="modal-Terms&Cond-title"
            className="modal_Font modal_Terms_Cond-title"
          >
            Konfer, Inc.
          </span>
          <Button
            onClick={handleCloseTermsContModal}
            sx={{ marginRight: "-26px" }}
          >
            <img src={CancelIcon} alt="Cancel" style={{ width: "14px" }} />
          </Button>
        </div>
        <div className="modal_Font modal_Terms_Cond_subtitle">
          <span>Terms</span>
        </div>
        <div className="modal_Font modal_Terms_Cond_info">
          <span>Last updated: October 26, 2022</span>
        </div>
        <div className="moda_content_div">
          <div className="modal_Font modal_Terms_Cond_info">
            <span
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(`${TermsDataOne}`),
              }}
            ></span>
          </div>

          <div className="modal_Font modal_Terms_Cond_info">
            <span
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(`${TermsDataTwo}`),
              }}
            />
          </div>

          <div className="modal_Font modal_Terms_Cond_subtitle">
            <span>Access to the Service</span>
          </div>

          <div className="modal_Font modal_Terms_Cond_info">
            <span
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(`${AccessServiceOne}`),
              }}
            />
          </div>

          <div className="modal_Font modal_Terms_Cond_info">
            <span
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(`${AccessServiceTwo}`),
              }}
            />
          </div>

          <div className="modal_Font modal_Terms_Cond_info">
            <span
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(`${AccessServiceThree}`),
              }}
            />
          </div>

          <div className="modal_Font modal_Terms_Cond_subtitle">
            <span>Use of the Service; Prohibited Conduct </span>
          </div>
          <div className="modal_Font modal_Terms_Cond_info">
            <span
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(`${UseOfService}`),
              }}
            />
          </div>
          <div className="modal_Font modal_Terms_Cond_info">
            <span
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(`${UseOfServiceNotToAgree}`),
              }}
            />
          </div>
          <div className="modal_Font modal_Terms_Cond_info">
            <ol>
              <li className="margin_top">
                <span
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(`${UseOfServiceOne}`),
                  }}
                />
              </li>
              <li className="margin_top">
                <span
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(`${UseOfServiceTwo}`),
                  }}
                />
              </li>
              <li className="margin_top">
                <span
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(`${UseOfServiceThree}`),
                  }}
                />
              </li>
              <li className="margin_top">
                <span
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(`${UseOfServiceFour}`),
                  }}
                />
              </li>
              <li className="margin_top">
                <span
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(`${UseOfServiceFive}`),
                  }}
                />
              </li>
              <li className="margin_top">
                <span
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(`${UseOfServiceSix}`),
                  }}
                />
              </li>
              <li className="margin_top">
                <span
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(`${UseOfServiceSeven}`),
                  }}
                />
              </li>
              <li className="margin_top">
                <span
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(`${UseOfServiceEight}`),
                  }}
                />
              </li>
              <li className="margin_top">
                <span
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(`${UseOfServiceNine}`),
                  }}
                />
              </li>
              <li className="margin_top">
                <span
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(`${UseOfServiceTen}`),
                  }}
                />
              </li>
              <li className="margin_top">
                <span
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(`${UseOfServiceEleven}`),
                  }}
                />
                <ol type="a" style={{ paddingInlineStart: "15px" }}>
                  <li className="margin_top">
                    <span
                      dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(`${UseOfServiceElevenA}`),
                      }}
                    />
                  </li>
                  <li className="margin_top">
                    <span
                      dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(`${UseOfServiceElevenB}`),
                      }}
                    />
                  </li>
                  <li className="margin_top">
                    <span
                      dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(`${UseOfServiceElevenC}`),
                      }}
                    />
                  </li>
                  <li className="margin_top">
                    <span
                      dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(`${UseOfServiceElevenD}`),
                      }}
                    />
                  </li>
                  <li className="margin_top">
                    <span
                      dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(`${UseOfServiceElevenE}`),
                      }}
                    />
                  </li>
                  <li className="margin_top">
                    <span
                      dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(`${UseOfServiceElevenF}`),
                      }}
                    />
                  </li>
                </ol>
              </li>
            </ol>
          </div>

          <div className="modal_Font modal_Terms_Cond_subtitle">
            <span>Fees </span>
          </div>
          <div className="modal_Font modal_Terms_Cond_info">
            <span
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(`${FeesOne}`),
              }}
            />
          </div>
          <div className="modal_Font modal_Terms_Cond_info">
            <span
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(`${FeesTwo}`),
              }}
            />
          </div>
          <div className="modal_Font modal_Terms_Cond_info">
            <span
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(`${FeesThree}`),
              }}
            />
          </div>

          <div className="modal_Font modal_Terms_Cond_subtitle">
            <span>Our Proprietary Rights </span>
          </div>
          <div className="modal_Font modal_Terms_Cond_info">
            <span
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(`${ProprietaryRightsOne}`),
              }}
            />
          </div>
          <div className="modal_Font modal_Terms_Cond_info">
            <span
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(`${ProprietaryRightsTwo}`),
              }}
            />
          </div>
          <div className="modal_Font modal_Terms_Cond_info">
            <span
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(`${ProprietaryRightsThree}`),
              }}
            />
          </div>
          <div className="modal_Font modal_Terms_Cond_info">
            <span
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(`${ProprietaryRightsFour}`),
              }}
            />
          </div>
          <div className="modal_Font modal_Terms_Cond_info">
            <span
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(`${ProprietaryRightsFive}`),
              }}
            />
          </div>

          <div className="modal_Font modal_Terms_Cond_subtitle">
            <span>Your Content and Related Outputs</span>
          </div>
          <div className="modal_Font modal_Terms_Cond_info">
            <span
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(`${ContentAndRelatedOutputsOne}`),
              }}
            />
          </div>
          <div className="modal_Font modal_Terms_Cond_info">
            <span
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(`${ContentAndRelatedOutputsTwo}`),
              }}
            />
          </div>
          <div className="modal_Font modal_Terms_Cond_info">
            <span
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(`${ContentAndRelatedOutputsThree}`),
              }}
            />
          </div>
          <div className="modal_Font modal_Terms_Cond_info">
            <span
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(`${ContentAndRelatedOutputsFour}`),
              }}
            />
          </div>

          <div className="modal_Font modal_Terms_Cond_subtitle">
            <span>No Warranties </span>
          </div>
          <div className="modal_Font modal_Terms_Cond_info">
            <span
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(`${NoWarrantiesOne}`),
              }}
            />
          </div>
          <div className="modal_Font modal_Terms_Cond_subtitle">
            <span
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(`${NoWarrantiesTwo}`),
              }}
            />
          </div>
          <div className="modal_Font modal_Terms_Cond_info">
            <span
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(`${NoWarrantiesThree}`),
              }}
            />
          </div>
          <div className="modal_Font modal_Terms_Cond_subtitle">
            <span
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(`${NoWarrantiesFour}`),
              }}
            />
          </div>
          <div className="modal_Font modal_Terms_Cond_info">
            <span
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(`${NoWarrantiesFive}`),
              }}
            />
          </div>
          <div className="modal_Font modal_Terms_Cond_subtitle">
            <span
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(`${NoWarrantiesSix}`),
              }}
            />
          </div>
          <div className="modal_Font modal_Terms_Cond_info">
            <span
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(`${NoWarrantiesSeven}`),
              }}
            />
          </div>
          <div className="modal_Font modal_Terms_Cond_subtitle">
            <span
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(`${NoWarrantiesEight}`),
              }}
            />
          </div>
          <div className="modal_Font modal_Terms_Cond_info">
            <span
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(`${NoWarrantiesNine}`),
              }}
            />
          </div>

          <div className="modal_Font modal_Terms_Cond_subtitle">
            <span>General </span>
          </div>
          <div className="modal_Font modal_Terms_Cond_info">
            <span
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(`${GeneralOne}`),
              }}
            />
          </div>
          <div className="modal_Font modal_Terms_Cond_info">
            <span
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(`${GeneralEntireAgreement}`),
              }}
            />
          </div>
          <div className="modal_Font modal_Terms_Cond_info">
            <span
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(`${GeneralTwo}`),
              }}
            />
          </div>
          <div className="modal_Font modal_Terms_Cond_info">
            <span
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(`${GeneralThree}`),
              }}
            />
          </div>
          <div className="modal_Font modal_Terms_Cond_info">
            <span
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(`${GeneralFour}`),
              }}
            />
          </div>
          <div className="modal_Font modal_Terms_Cond_info">
            <span
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(`${GeneralFive}`),
              }}
            />
          </div>
          <div className="modal_Font modal_Terms_Cond_info">
            <span
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(`${GeneralSix}`),
              }}
            />
          </div>
          <div className="modal_Font modal_Terms_Cond_info">
            <span
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(`${GeneralSeven}`),
              }}
            />
          </div>
          <div className="modal_Font modal_Terms_Cond_info">
            <span
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(`${GeneralEight}`),
              }}
            />
          </div>
          <div className="modal_Font modal_Terms_Cond_info">
            <span
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(`${GeneralNine}`),
              }}
            />
          </div>
        </div>

        <div className="accept_terms_cond_div">
          <FormControlLabel
            control={
              <Checkbox
                sx={{
                  "& .MuiSvgIcon-root": { fontSize: 28 },
                  marginLeft: "10px",
                }}
                checked={termsCondAccept}
                onChange={(e) => {
                  handleTermsCondAccept(e);
                }}
                inputProps={{ "aria-label": "controlled" }}
              />
            }
            label="I accept the terms and conditions"
          />
        </div>

        <div className="Terms_and_Cond_Buttons">
          <Button
            className={
              termsCondAccept
                ? "Terms_and_Cond_ContinueButton"
                : "Terms_and_Cond_DisabledContinueButton"
            }
            disabled={!termsCondAccept}
            onClick={handleTermsCondAcceptButton}
          >
            Continue
          </Button>
          <Button
            className="Terms_and_Cond_CancelButton"
            onClick={handleCloseTermsContModal}
          >
            Cancel
          </Button>
        </div>
      </Box>
    </Modal>
  );
};

export default TermsConditionPage;
