import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableFooter,
  TablePagination,
  Tooltip,
  Popover,
  Typography,
  Checkbox,
} from "@mui/material";
import { IconButton, Box, useTheme } from "@mui/material";
import {
  FirstPage,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  LastPage,
} from "@mui/icons-material";
import _ from "lodash";
import "../../AdminUsetlistUserTable/AdminUsetlistUserTable.css";
import { dateFormate } from "../../../utils/basicUtils";
import { AssignBUKeyName } from "../../../constants/adminConfigConstant";
import NodeBUAssignee from "../NodeBUAssignee/NodeBUAssignee";
import Add from "../../../assets/images/adminPanel/Add.svg";
import { isAccessProvided } from "../../../services/assetManagementService";
import {
  moduleConstants,
  subModuleConstants,
} from "../../../constants/accessManagementConstants";

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPage /> : <FirstPage />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPage /> : <LastPage />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const NodeBusinessunitRelTable = ({
  nodeData,
  selectedType,
  regionData,
  accessDataByRole,
}) => {
  let a = window.innerHeight <= 760 ? 6 : window.innerHeight <= 900 ? 8 : 10;
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(a);
  const [openBusinessunitList, setOpenBusinessunitList] = useState(null); // to show a business list
  const [openNodeBUAssignee, setOpenNodeBUAssignee] = useState(false); // to show a update business unit assignee dialog
  const [editNodeData, setEditNodeData] = useState(null);

  const open = Boolean(openBusinessunitList);

  const [
    assignedBusinessunitAppIDForList,
    setAssignedBusinessunitAppIDForList,
  ] = useState(null);

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0
      ? Math.max(0, (1 + page) * rowsPerPage - nodeData?.contributor?.length)
      : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleEditUser = (data) => {
    setOpenNodeBUAssignee(true);
    setEditNodeData(data);
  };

  return (
    <div>
      <Table aria-label="simple table" sx={{ tableLayout: "fixed" }}>
        <TableHead>
          <TableRow className="usertable-row-headings">
            <TableCell className="usertable-coloumn-headings">Name</TableCell>
            <TableCell className="usertable-coloumn-headings">
              Version
            </TableCell>
            <TableCell className="usertable-coloumn-headings">
              Regions
            </TableCell>
            <TableCell className="usertable-coloumn-headings">
              Business Units
            </TableCell>
            <TableCell className="usertable-coloumn-headings">
              Date Created
            </TableCell>
            {isAccessProvided(
              moduleConstants.admin_view,
              subModuleConstants.business_units_assignment,
              null,
              "update",
              accessDataByRole
            ) === true && (
              <TableCell className="widthColoumn2Per usertable-coloumn-headings"></TableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {nodeData &&
            nodeData?.length > 0 &&
            (rowsPerPage > 0
              ? nodeData.slice(
                  page * rowsPerPage,
                  page * rowsPerPage + rowsPerPage
                )
              : nodeData
            ).map((data, i) => (
              <>
                <TableRow
                  key={data[AssignBUKeyName[selectedType]]} //.app_id
                  sx={{
                    "&:hover": {
                      cursor: "pointer",
                      backgroundColor: "var(--bacgroundColor) !important",
                    },
                  }}
                >
                  <Tooltip
                    title={
                      data.display_name === null || data.display_name === ""
                        ? data.name
                        : data.display_name
                    }
                  >
                    <TableCell className="usertable-coloumn text-ellipsis">
                      {data.display_name === null || data.display_name === ""
                        ? data.name
                        : data.display_name}
                    </TableCell>
                  </Tooltip>

                  <TableCell className="usertable-coloumn">
                    {(data.version === "" && data.hasOwnProperty("version")) ||
                    data.version === null
                      ? "-"
                      : data.version}
                  </TableCell>

                  <TableCell className="usertable-coloumn">
                    {data[`${selectedType}_business_units`]?.map(
                      (businessD, index) => {
                        return (
                          <>
                            {(() => {
                              if (index < 1) {
                                return businessD.business_unit.region.name;
                              }
                            })()}
                          </>
                        );
                      }
                    )}
                    {data[`${selectedType}_business_units`]?.length == 0 && "-"}
                  </TableCell>
                  <TableCell className="usertable-coloumn">
                    {/* data.apps_business_units */}
                    {data[`${selectedType}_business_units`]?.map(
                      (businessunitData, index) => {
                        return (
                          <>
                            {(() => {
                              if (index < 2) {
                                return (
                                  <span className="tt">
                                    {" "}
                                    {businessunitData.business_unit.name}{" "}
                                  </span>
                                );
                              }
                            })()}
                            {(() => {
                              // changed
                              if (
                                index + 1 ===
                                  data[`${selectedType}_business_units`]
                                    .length &&
                                data[`${selectedType}_business_units`].length >
                                  2
                              ) {
                                return (
                                  <span
                                    className="tt-without-name"
                                    onClick={(e) => {
                                      setOpenBusinessunitList(e.currentTarget);
                                      setAssignedBusinessunitAppIDForList(
                                        data[AssignBUKeyName[selectedType]] //.app_id
                                      );
                                    }}
                                  >
                                    +
                                    {data[`${selectedType}_business_units`]
                                      .length - 2}
                                  </span>
                                );
                              }
                            })()}
                          </>
                        );
                      }
                    )}
                    {data[`${selectedType}_business_units`]?.length == 0 && "-"}
                  </TableCell>
                  <TableCell className="usertable-coloumn">
                    {data.created_at ? dateFormate(data.created_at) : "-"}
                  </TableCell>
                  {isAccessProvided(
                    moduleConstants.admin_view,
                    subModuleConstants.business_units_assignment,
                    null,
                    "update",
                    accessDataByRole
                  ) === true && (
                    <TableCell className="usertable-coloumn">
                      <IconButton
                        id={`adminNodeRBUAssAddIconButton${i}`}
                        type="submit"
                        aria-label="Add"
                        style={{
                          padding: "5px",
                          marginLeft: "-28px",
                        }}
                        onClick={() => {
                          handleEditUser(data);
                        }}
                      >
                        <img src={Add} alt="Add" style={{ width: "25px" }} />
                      </IconButton>
                    </TableCell>
                  )}
                </TableRow>
              </>
            ))}
        </TableBody>
        <TableFooter>
          <TableRow
            sx={{
              "&:last-child td, &:last-child th": {
                border: 0,
                paddingRight: "50px",
              },
            }}
          >
            <TablePagination
              rowsPerPageOptions={[a]}
              colSpan={5}
              count={nodeData?.length || 0}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />
          </TableRow>
        </TableFooter>
      </Table>

      <Popover
        sx={{
          "& .MuiPopover-paper": {
            boxShadow: "0px 5px 10px rgba(0, 0, 0, 0.25)",
            borderRadius: "4px",
            border: "0.5px solid #6D8291 ",
            width: "180px",
          },
        }}
        id={assignedBusinessunitAppIDForList}
        open={open}
        anchorEl={openBusinessunitList}
        onClose={() => setOpenBusinessunitList(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        {nodeData?.map((data) => {
          if (
            data[AssignBUKeyName[selectedType]] ===
            assignedBusinessunitAppIDForList
          ) {
            return data[`${selectedType}_business_units`]?.map(
              (businessUnitData, index) => {
                return (
                  <Typography sx={{ margin: "5px 0px 5px 20px" }} key={index}>
                    {" "}
                    {businessUnitData.business_unit.name}
                  </Typography>
                );
              }
            );
          }
        })}
      </Popover>

      {openNodeBUAssignee && (
        <NodeBUAssignee
          openNodeBUAssignee={openNodeBUAssignee}
          setOpenNodeBUAssignee={setOpenNodeBUAssignee}
          nodeData={nodeData}
          // selectedNodes={selectedNodes}
          // setSelectedNodes={setSelectedNodes}
          selectedType={selectedType}
          editNodeData={editNodeData}
          regionData={regionData}
        />
      )}
    </div>
  );
};

export default NodeBusinessunitRelTable;
