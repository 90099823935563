import React, { useEffect, useLayoutEffect, useState } from "react";
import * as am5 from "@amcharts/amcharts5";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import * as am5xy from "@amcharts/amcharts5/xy";

import { Box } from "@mui/system";

const DivergentBarChart = ({ data }) => {
  const uniqueDivId = Math.floor(Math.random() * 800000);

  useLayoutEffect(() => {
    // Create root element
    let root = am5.Root.new(`${uniqueDivId}`);

    // Set themes
    root.setThemes([am5themes_Animated.new(root)]);

    // Create chart
    var chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panX: false,
        panY: false,
        wheelX: "panX",
        wheelY: "zoomX",
        layout: root.horizontalLayout,
        arrangeTooltips: false,
      })
    );

    // Add legend
    var legend = chart.children.push(
      am5.Legend.new(root, {
        centerX: am5.p50,
        x: am5.p50,
      })
    );

    // Create axes
    var yAxis = chart.yAxes.push(
      am5xy.CategoryAxis.new(root, {
        categoryField: "metric",
        renderer: am5xy.AxisRendererY.new(root, {}),
      })
    );

    yAxis.data.setAll(data);

    var xAxis = chart.xAxes.push(
      am5xy.ValueAxis.new(root, {
        renderer: am5xy.AxisRendererX.new(root, {
          minGridDistance: 50,
        }),
      })
    );

    var xRenderer = yAxis.get("renderer");
    xRenderer.axisFills.template.setAll({
      fill: am5.color(0x000000),
      fillOpacity: 0.05,
      visible: true,
    });

    // Add series
    function createSeries(field, name, color) {
      var series = chart.series.push(
        am5xy.ColumnSeries.new(root, {
          xAxis: xAxis,
          yAxis: yAxis,
          name: name,
          valueXField: field,
          categoryYField: "metric",
          sequencedInterpolation: false,
          stacked: false,
          fill: color,
          stroke: color,
          calculateAggregates: false,
        })
      );

      series.columns.template.setAll({
        height: am5.p100,
      });

      series.bullets.push(function (root, series) {
        return am5.Bullet.new(root, {
          locationX: 0.5,
          locationY: 0.5,
          sprite: am5.Label.new(root, {
            fill: am5.color(0xffffff),
            centerX: am5.p50,
            centerY: am5.p50,
            text: "{valueX}",
            populateText: true,
            oversizedBehavior: "hide",
          }),
        });
      });

      series.data.setAll(data);
      series.appear();
    }

    createSeries("original_training_data", "Base Model", am5.color(0x1e7898));
    createSeries(
      "reweighed_training_data",
      "Model with reweighed data",
      am5.color(0xeb9f34)
    );
    createSeries(
      "debiased_model_predicted_data",
      "Debiased Model",
      am5.color(0x50b300)
    );
    createSeries(
      "Rejection_option_classification_data",
      "Rejection Option Classification Model",
      am5.color(0xff0000)
    );

    // Add legend
    var legend = chart.children.push(
      am5.Legend.new(root, {
        centerY: am5.p50,
        y: am5.p50,
        layout: root.verticalLayout,
        marginLeft: 50,
      })
    );

    legend.data.setAll(chart.series.values);

    return () => {
      root.dispose();
    };
  }, []);

  return (
    <div>
      <Box
        id={uniqueDivId}
        className="custom-chart"
        style={{
          height: "50vh",
          width: "100%",
        }}
      ></Box>
    </div>
  );
};
export default DivergentBarChart;
