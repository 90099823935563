import React from "react";
import "./Popup.css";

// MUI imports
import { CancelPresentation } from "@mui/icons-material";
import { Box } from "@mui/material";
import { useAtom } from "jotai";
import { authUser } from "../../atom/jotai";
import _ from "lodash";

const Popup = ({
  setPopupOpen,
  popupOpen,
  setSearchText,
  inputRef,
  setiInputFilter,
  setQueryText,
  setUpdatedField,
}) => {
  const handlePopupListClick = (val) => {
    setQueryText(val);
    setSearchText(val);
    setPopupOpen(!popupOpen);
    inputRef.current.focus();
    setiInputFilter(true);
  };
  const [user] = useAtom(authUser);

  return (
    <>
      <div className="popup_container">
        <div className="triangle_up"></div>
        <div className="popup" id="popupOnHomeDiv">
          <Box className="popup_text_content">
            <div className="popup_text_items" id="popupItemsDiv">
              <p
                onClick={() => {
                  setUpdatedField({
                    value: "1",
                    type: "apps",
                    typeIndex: 2,

                    typeName: "version",
                    indexId: 4,

                    inputFieldIndex: 6,
                    textLength: 7,
                  });
                  handlePopupListClick("show all apps where version is 1");
                }}
              >
                “Show all apps where version is 1”
              </p>
              <p
                onClick={() => {
                  setUpdatedField({
                    type: "apps",
                    typeIndex: 2,
                    // indexId: 4,
                    typeName: "contributor",
                  });
                  handlePopupListClick("show all apps");
                }}
              >
                “Show all apps”
              </p>

              <p
                onClick={() => {
                  // setUpdatedField({
                  //   indexId: 4,
                  //   value: "1",
                  // });
                  setUpdatedField({
                    type: "datasets",
                    typeIndex: 2,
                    indexId: 4,
                    typeName: "contributors",
                    typeNameDescription: "name",
                    typeNameDescriptionIndex: 5,
                    inputFieldIndex: 7,
                    textLength: 8,
                  });

                  handlePopupListClick(
                    "Show all datasets where contributors name is "
                  );
                }}
              >
                {`"Show all datasets where contributors name is ___"`}
              </p>

              {/* <p
                onClick={() => {
                  setUpdatedField({
                    type: "datasets",
                    typeIndex: 2,
                    indexId: 4,
                    typeName: "contributors",
                    typeNameDescription: "name",
                    typeNameDescriptionIndex: 5,
                    subTypeNameDescription: "description",
                    subTypeNameDescriptionIndex: 9,
                    inputFieldIndex: 7,
                    subInputFieldIndex: 11,
                    textLength: 12,
                    textType: "subTextFiled",
                  });
                  handlePopupListClick(
                    "Show all datasets where contributors name is inputFieldText and description is subInputFieldText"
                  );
                }}
              >
                {`"Show all datasets where contributor's name is ___ and description is __"`}
              </p> */}
              <p style={{ height: "10px" }}></p>
            </div>
          </Box>

          <CancelPresentation
            id="popupCancelDiv"
            className="popup_cancel_icon"
            onClick={() => setPopupOpen(!popupOpen)}
          />
        </div>
      </div>
    </>
  );
};

export default Popup;
