import React, { useEffect, useState } from "react";
import { CircularProgress } from "@mui/material";
import { Box } from "@mui/system";
import { useParams } from "react-router-dom";
import { useAtom } from "jotai";

// Custom Imports
import "../../Listview/Listview.css";
import { useGetSearchDetails } from "../../../api/readApiFunctions";
import ListviewComplianceTable from "../ListviewComplianceTable/ListviewComplianceTable";
import { isKonferScore } from "../../../services/listviewServices";
import NoResult from "../../NoResult/NoResult";
import ListviewComplianceFilter from "../ListviewComplianceFilter/ListviewComplianceFilter";
import {
  activeCountOfHighlightWord,
  colNameForListviewFindAtom,
  finalMapping,
  indexOfSearchedKeywordAtom,
} from "../../../atom/jotai";

const ListviewCompliance = ({
  prevHighlightedLOC,
  setprevHighlightedLOC,
  findElement,
  setFindElement,
  currentCount,
  setCurrentCount,
  policyData,
}) => {
  const { parmsSearchText } = useParams();
  const searchText = localStorage.getItem("userSearchText");

  const [konferScoreSelected, setKonferScoreSelected] = useState(
    isKonferScore(searchText)
  );

  const [scoreCount, setScoreCount] = useState({
    redScore: 0,
    blueScore: 0,
  });

  //FIXME: Because of Demo, we set hardcoded "*" later we need to fix this.
  const { data, error, isLoading } = useGetSearchDetails("*");
  // const { data, error, isLoading } = useGetSearchDetails(searchText);
  const [finalPosition, setFinalPosition] = useAtom(finalMapping);
  const [activeCountOfHighlightedWord, setActiveCountOfHighlitedWord] = useAtom(
    activeCountOfHighlightWord
  );
  const [indexOfSearchedKeyword, setIndexOfSearchedKeyword] = useAtom(
    indexOfSearchedKeywordAtom
  );
  const [colNameForListviewFind, setColNameForListviewFind] = useAtom(
    colNameForListviewFindAtom
  );

  // check and update the konfer score state when search changes
  useEffect(() => {
    setKonferScoreSelected(isKonferScore(searchText));
  }, [searchText]);

  if (isLoading) {
    return (
      <Box sx={{ display: "flex", marginTop: "20%", marginLeft: "50%" }}>
        <CircularProgress />
      </Box>
    );
  }

  if (data) {
    //  check if no data exists
    if (data.appIds.length === 0) {
      return <NoResult />;
    } else {
    }
  }

  const handleDownHighlightedSelection = (e) => {
    e.preventDefault();
    let row;
    let startIndex;
    let colName;
    let colIndex;

    if (prevHighlightedLOC.length === 0) {
      setprevHighlightedLOC([0, Object.keys(finalPosition[0])[0], 0, 0]);
      row = 0;
      startIndex = 0;
      colIndex = 0;
      colName = `${Object.keys(finalPosition[0])[0]}`;
    } else {
      row = prevHighlightedLOC[0];
      startIndex = prevHighlightedLOC[2] + 1;
      colName = prevHighlightedLOC[1];
      colIndex = prevHighlightedLOC[3];

      if (finalPosition[row][colName].length === startIndex) {
        colIndex = colIndex + 1;
        startIndex = 0;

        if (colIndex === Object.keys(finalPosition[row]).length) {
          row = row + 1;
          startIndex = 0;
          colIndex = 0;
        }
        colName = `${Object.keys(finalPosition[row])[colIndex]}`;
      }
    }

    for (var i = row; i < finalPosition.length; i++) {
      for (var j = colIndex; j < Object.keys(finalPosition[i]).length; j++) {
        var key = Object.keys(finalPosition[i])[j];
        if (finalPosition[i][key].length > 0) {
          setprevHighlightedLOC([i, key, startIndex, j]);
          setIndexOfSearchedKeyword(finalPosition[i][key][startIndex]);
          setActiveCountOfHighlitedWord(i);
          setColNameForListviewFind(key);
          setCurrentCount(currentCount + 1);
          document
            .getElementById(`${i}scrollToHighlighter`)
            .scrollIntoView(false, {
              behavior: "smooth",
              block: "start",
            });
          return;
        }
      }
      colIndex = 0;
      document
        .getElementById(`${row + 1}scrollToHighlighter`)
        .scrollIntoView(false, {
          behavior: "smooth",
          block: "start",
        });
    }
  };

  const handleUpHighlightedSelection = (e) => {
    e.preventDefault();

    let row = prevHighlightedLOC[0];
    let startIndex = prevHighlightedLOC[2];
    let colName = prevHighlightedLOC[1];
    let colIndex = prevHighlightedLOC[3];

    if (startIndex === 0) {
      if (colIndex === 0 && row !== 0) {
        row = row - 1;
        colIndex = Object.keys(finalPosition[row]).length - 1;
      } else {
        colIndex = colIndex - 1;
      }
      colName = Object.keys(finalPosition[row])[colIndex];
      startIndex = finalPosition[row][colName].length - 1;
    } else {
      startIndex = startIndex - 1;
    }

    for (var i = row; i >= 0; i--) {
      for (var j = colIndex; j >= 0; j--) {
        var key = Object.keys(finalPosition[i])[j];
        if (startIndex >= 0) {
          setprevHighlightedLOC([i, key, startIndex, j]);
          setIndexOfSearchedKeyword(finalPosition[i][key][startIndex]);
          setActiveCountOfHighlitedWord(i);
          setColNameForListviewFind(key);
          setCurrentCount(currentCount - 1);
          document
            .getElementById(`${i}scrollToHighlighter`)
            .scrollIntoView(false, {
              behavior: "smooth",
              block: "start",
            });
          return;
        }
        if (j > 0) {
          startIndex =
            finalPosition[i][Object.keys(finalPosition[i])[j - 1]].length - 1;
        }
      }
      colIndex = Object.keys(finalPosition[i - 1]).length - 1;

      startIndex =
        finalPosition[i - 1][Object.keys(finalPosition[i - 1])[colIndex]]
          .length - 1;

      document.getElementById(`${i}scrollToHighlighter`).scrollIntoView(false, {
        behavior: "smooth",
        block: "start",
      });
    }
  };

  return (
    <>
      <div className="listview_container">
        {data && (
          <ListviewComplianceFilter
            scoreCount={scoreCount}
            findElement={findElement}
            setFindElement={setFindElement}
            handleDownHighlightedSelection={handleDownHighlightedSelection}
            handleUpHighlightedSelection={handleUpHighlightedSelection}
            prevHighlightedLOC={prevHighlightedLOC}
            setprevHighlightedLOC={setprevHighlightedLOC}
            currentCount={currentCount}
            setCurrentCount={setCurrentCount}
          />
        )}
        {data && data.appIds && data.modelIds && data.dataSetIds && (
          <ListviewComplianceTable
            appIds={data.appIds}
            setScoreCount={setScoreCount}
            konferScoreSelected={konferScoreSelected}
            findElement={findElement}
            keywords={data?.keywords}
            policyData={policyData}
          />
        )}
      </div>
    </>
  );
};

export default ListviewCompliance;
