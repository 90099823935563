import React from "react";
import { useAtom } from "jotai";
import AssetsListview from "../../components/Assets Listview/AssetsListview";
import NoBusinessUnitsAssignedModal from "../../components/NoBusinessUnitsAssignedModal/NoBusinessUnitsAssignedModal";
import { accessDataByRoleAtom } from "../../atom/jotai";

/**
 * Component : const AssetsListviewWrapperPage
 * Description :  This is the wrapper class for assest listview component
 */

const AssetsListviewWrapperPage = () => {
  const [accessDataByRole] = useAtom(accessDataByRoleAtom);
  return (
    <>
      <AssetsListview accessDataByRole={accessDataByRole} />
      {/**Below Code is for when no bussiness unit is attached to the logged in user */}
      <NoBusinessUnitsAssignedModal
        accessDataByRole={accessDataByRole}
        moduleInvoker={"asset_view"}
      />
    </>
  );
};

export default AssetsListviewWrapperPage;
