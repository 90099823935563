import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableFooter,
  TablePagination,
  Tooltip,
  tooltipClasses,
  Checkbox,
} from "@mui/material";
import {
  IconButton,
  Box,
  useTheme,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
} from "@mui/material";
import {
  FirstPage,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  LastPage,
} from "@mui/icons-material";
import { useQueryClient } from "react-query";
import CheckIcon from "@mui/icons-material/Check";

import "../../AdminUsetlistUserTable/AdminUsetlistUserTable.css";
import Edit from "../../../assets/images/adminPanel/Edit.svg";
import Delete from "../../../assets/images/adminPanel/Delete.svg";
import Vector from "../../../assets/images/adminPanel/Vector.svg";
import CancelIcon from "../../../assets/images/adminPanel/CancelIcon.svg";
import "./RoleManagementTable.css";
import styled from "@emotion/styled";
import { roleDeleteConfirmation } from "../../../constants/roleManagmenetMsg";
import { dateFormate } from "../../../utils/basicUtils";
import { isAccessProvided } from "../../../services/assetManagementService";
import {
  moduleConstants,
  subModuleConstants,
} from "../../../constants/accessManagementConstants";

/**
 * Component : RoleManagementTable
 *
 * Description : This component is responsible for showing the roles in tabular format.
 * Also shows the delete dialog box when delete role icon is clicked.
 * Also shows the new popup while clicking on row of table,
 *   for showing the role with their access page list
 *
 * Props : rolesData - list of all roles,
 *         setDeleteAdminRole - setter function which indicates to call the delete mutation,
 *         setRoleCreateData - payload state,
 *         setRoleEditTitle - etter function to save the title for create/update/clone,
 *         setCreateRole - setter which decides to show create screen of table view,
 *         roleAccessForRoleMang - state for managing the role access system on actions
 *  */

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#FFFFFF",
    color: "#666666",
    border: " 0.5px solid #6D8291",
    boxShadow: "0px 5px 10px rgba(0, 0, 0, 0.25)",
    borderRadius: "4px",
    fontSize: 16,
    fontFamily: "Lato",
    fontWeight: 400,
  },
}));
function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        id="buFirst"
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPage /> : <FirstPage />}
      </IconButton>
      <IconButton
        id="buBack"
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        id="buNext"
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        id="buLast"
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPage /> : <LastPage />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const RoleManagementTable = ({
  rolesData,
  setDeleteAdminRole,
  setCreateRole,
  setRoleCreateData,
  setRoleEditTitle,
  accessDataByRole,
}) => {
  let a = window.innerHeight <= 760 ? 6 : window.innerHeight <= 900 ? 8 : 10;
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(a);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false); // to show a confirmation dialog to delete a businessunit

  const [deleteRoleId, setDeleteRoleId] = useState();
  const [isRoleListViewPopUp, setIsRoleListViewPopUp] = useState();

  const [roleListViewPopUpData, setRoleListViewPopUpData] = useState({});

  const [isRolePresent, setIsRolePresent] = useState();

  const queryClient = useQueryClient();

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rolesData?.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleDeleteConfirmationDialog = (ID, delete_role_count) => {
    setDeleteRoleId(ID);
    setIsRolePresent(delete_role_count);
    setOpenDeleteDialog(true);
  };

  const handleDeleteRole = () => {
    setDeleteAdminRole(deleteRoleId);
  };

  return (
    <div
      style={{
        position: "relative",
      }}
    >
      <Table
        aria-label="simple table"
        sx={{ tableLayout: "fixed" }}
        id="roleTable"
      >
        <TableHead>
          <TableRow className="usertable-row-headings">
            <TableCell className="usertable-coloumn-headings">Role</TableCell>
            <TableCell
              className="usertable-coloumn-headings"
              sx={{ width: "50%", overflowX: "auto" }}
            >
              Description
            </TableCell>
            <TableCell className="usertable-coloumn-headings-role">
              Date Created
            </TableCell>
            {(isAccessProvided(
              moduleConstants.admin_view,
              subModuleConstants.role_management,
              null,
              "write",
              accessDataByRole
            ) === true ||
              isAccessProvided(
                moduleConstants.admin_view,
                subModuleConstants.role_management,
                null,
                "update",
                accessDataByRole
              ) === true ||
              isAccessProvided(
                moduleConstants.admin_view,
                subModuleConstants.role_management,
                null,
                "delete",
                accessDataByRole
              ) === true) && (
              <TableCell className="usertable-coloumn-headings-role">
                Actions
              </TableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {rolesData &&
            rolesData?.length > 0 &&
            (rowsPerPage > 0
              ? rolesData.slice(
                  page * rowsPerPage,
                  page * rowsPerPage + rowsPerPage
                )
              : rolesData
            ).map((data, index) => (
              <>
                <TableRow
                  id={`rolesTableRow${index}`}
                  key={data.roles_id}
                  sx={{
                    "&:hover": {
                      cursor: "pointer",
                      backgroundColor: "var(--bacgroundColor) !important",
                    },
                  }}
                  onClick={() => {
                    setIsRoleListViewPopUp(true);
                    setRoleListViewPopUpData(data);
                  }}
                >
                  <LightTooltip title={data?.name}>
                    <TableCell className="usertable-coloumn text-ellipsis">
                      {data.name}
                    </TableCell>
                  </LightTooltip>
                  <TableCell className="usertable-coloumn">
                    {data.description === "" || data.description === null
                      ? "-"
                      : data.description}
                  </TableCell>
                  <TableCell className="usertable-coloumn_role">
                    {data.createdAt ? dateFormate(data.createdAt) : "-"}
                  </TableCell>
                  {(isAccessProvided(
                    moduleConstants.admin_view,
                    subModuleConstants.role_management,
                    null,
                    "write",
                    accessDataByRole
                  ) === true ||
                    isAccessProvided(
                      moduleConstants.admin_view,
                      subModuleConstants.role_management,
                      null,
                      "update",
                      accessDataByRole
                    ) === true ||
                    isAccessProvided(
                      moduleConstants.admin_view,
                      subModuleConstants.role_management,
                      null,
                      "delete",
                      accessDataByRole
                    ) === true) && (
                    <TableCell
                      onClick={() => {
                        setIsRoleListViewPopUp(false);
                      }}
                      className="usertable-coloumn_role"
                    >
                      {isAccessProvided(
                        moduleConstants.admin_view,
                        subModuleConstants.role_management,
                        null,
                        "update",
                        accessDataByRole
                      ) === true && (
                        <IconButton
                          id={`roleEditIcon${index}`}
                          type="submit"
                          aria-label="Edit"
                          onClick={() => {
                            setRoleCreateData({
                              name: data.name,
                              description: data.description,
                              roles_id: data.roles_id,
                              data: data.roles_access,
                            });
                            setCreateRole(true);
                            setRoleEditTitle("Edit Role");
                            setIsRoleListViewPopUp(false);
                          }}
                          style={{ padding: "5px 5px 5px 0px" }}
                        >
                          <Tooltip title="Edit" followCursor>
                            <img
                              src={Edit}
                              alt="Edit"
                              style={{ width: "28px" }}
                            />
                          </Tooltip>
                        </IconButton>
                      )}
                      {isAccessProvided(
                        moduleConstants.admin_view,
                        subModuleConstants.role_management,
                        null,
                        "write",
                        accessDataByRole
                      ) === true && (
                        <IconButton
                          id={`cloneRoleIcon${index}`}
                          type="submit"
                          aria-label="Clone"
                          style={{ padding: "5px" }}
                          onClick={() => {
                            setRoleCreateData({
                              name: `Copy of ${data.name}`,
                              description: `${data.description}`,
                              // access: "org Data",
                              data: data.roles_access,
                            });
                            setCreateRole(true);
                            setIsRoleListViewPopUp(false);
                            setRoleEditTitle("Clone Role");
                          }}
                        >
                          <Tooltip title="Clone" followCursor>
                            <img
                              src={Vector}
                              alt="Clone"
                              style={{ width: "18px" }}
                            />
                          </Tooltip>
                        </IconButton>
                      )}
                      {isAccessProvided(
                        moduleConstants.admin_view,
                        subModuleConstants.role_management,
                        null,
                        "delete",
                        accessDataByRole
                      ) === true && (
                        <IconButton
                          id={`roleDeleteIconIcon${index}`}
                          type="submit"
                          aria-label="Delete"
                          style={{ padding: "5px" }}
                          onClick={() => {
                            handleDeleteConfirmationDialog(data.roles_id, 1);
                            setIsRoleListViewPopUp(false);
                          }}
                        >
                          <Tooltip title="Delete" followCursor>
                            <img
                              src={Delete}
                              alt="Delete"
                              style={{ width: "28px" }}
                            />
                          </Tooltip>
                        </IconButton>
                      )}
                    </TableCell>
                  )}
                </TableRow>
              </>
            ))}
        </TableBody>
        <TableFooter>
          <TableRow
            sx={{
              "&:last-child td, &:last-child th": {
                border: 0,
                paddingRight: "0px",
                alignContent: "flex-end",
                alignItems: "flex-end",
              },
            }}
          >
            <TablePagination
              rowsPerPageOptions={[a]}
              colSpan={3}
              count={rolesData?.length || 0}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />
          </TableRow>
        </TableFooter>
      </Table>
      <div>
        <Dialog
          id="buDeleteDialog"
          open={openDeleteDialog}
          // onClose={() => {
          //   setOpenDeleteDialog(!openDeleteDialog);
          // }}

          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            <IconButton
              id="buCloseDeleteDialog"
              aria-label="close"
              onClick={() => {
                setOpenDeleteDialog(false);
                setIsRoleListViewPopUp(false);
              }}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <img src={CancelIcon} alt="Delete" />
            </IconButton>
            <p className={"roleDeleteMsg"}>{roleDeleteConfirmation}</p>
          </DialogTitle>

          {isRolePresent > 0 && (
            <DialogActions>
              <Button
                id="roleCancelDeleteDialog"
                className="cancel-deleteUser-dialog"
                value="Cancel"
                onClick={() => {
                  setOpenDeleteDialog(false);
                  setIsRoleListViewPopUp(false);
                }}
                autoFocus
              >
                <span className="deleteUser-dialog-title">Cancel</span>
              </Button>
              <Button
                id="roleConfirmDelete"
                className="delete-deleteUser-dialog"
                value="Delete"
                onClick={() => {
                  handleDeleteRole();
                  setOpenDeleteDialog(false);
                  setIsRoleListViewPopUp(false);
                }}
              >
                <span className="deleteUser-dialog-title">Delete</span>
              </Button>
            </DialogActions>
          )}
        </Dialog>
      </div>
      {/* roleListViewPopUpData */}
      {isRoleListViewPopUp && !openDeleteDialog && (
        <div className="RoleListViewPopUp">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <p className="roleListViewPopUpTitle">
              {roleListViewPopUpData?.name}
            </p>
            <div>
              <TableCell className="usertable-coloumn-role-popup">
                {isAccessProvided(
                  moduleConstants.admin_view,
                  subModuleConstants.role_management,
                  null,
                  "update",
                  accessDataByRole
                ) === true && (
                  <IconButton
                    type="submit"
                    aria-label="Edit"
                    style={{ padding: "7px 7px 7px 0px" }}
                    onClick={() => {
                      setRoleCreateData({
                        name: roleListViewPopUpData.name,
                        description: roleListViewPopUpData.description,
                        roles_id: roleListViewPopUpData.roles_id,
                        data: roleListViewPopUpData.roles_access,
                      });
                      setCreateRole(true);
                      setRoleEditTitle("Edit Role");
                      setIsRoleListViewPopUp(false);
                    }}
                  >
                    <Tooltip title="Edit" followCursor>
                      <img src={Edit} alt="Edit" style={{ width: "28px" }} />
                    </Tooltip>
                  </IconButton>
                )}
                {isAccessProvided(
                  moduleConstants.admin_view,
                  subModuleConstants.role_management,
                  null,
                  "write",
                  accessDataByRole
                ) === true && (
                  <IconButton
                    type="submit"
                    aria-label="Clone"
                    style={{ padding: "7px" }}
                    onClick={() => {
                      setRoleCreateData({
                        name: `Copy of ${roleListViewPopUpData.name}`,
                        description: roleListViewPopUpData.description,
                        data: roleListViewPopUpData.roles_access,
                      });
                      setCreateRole(true);
                      setRoleEditTitle("Clone Role");
                      setIsRoleListViewPopUp(false);
                    }}
                  >
                    <Tooltip title="Clone" followCursor>
                      <img src={Vector} alt="Clone" style={{ width: "18px" }} />
                    </Tooltip>
                  </IconButton>
                )}
                {isAccessProvided(
                  moduleConstants.admin_view,
                  subModuleConstants.role_management,
                  null,
                  "delete",
                  accessDataByRole
                ) === true && (
                  <IconButton
                    type="submit"
                    aria-label="Delete"
                    style={{ padding: "7px" }}
                    onClick={() => {
                      handleDeleteConfirmationDialog(
                        roleListViewPopUpData.roles_id,
                        1
                      );
                      setIsRoleListViewPopUp(false);
                    }}
                  >
                    <Tooltip title="Delete" followCursor>
                      <img
                        src={Delete}
                        alt="Delete"
                        style={{ width: "28px" }}
                      />
                    </Tooltip>
                  </IconButton>
                )}
              </TableCell>
            </div>
          </div>
          <p
            style={{
              // height: "85px",
              overflowY: "auto",
            }}
          >
            {roleListViewPopUpData?.description}
          </p>
          <div className="roleAccess" id="roleUnit">
            <div className="roleAccess_popup_title">
              <p>Access</p>
              <p>View Access</p>
              <p>Full Access</p>
            </div>
            <div style={{ height: "34vh", overflowY: "auto" }}>
              {roleListViewPopUpData?.roles_access?.length > 0 &&
                roleListViewPopUpData?.roles_access?.map((moduleData) => {
                  let viewAccess = false;
                  let fullAccess = false;
                  {
                    Object.entries(moduleData).map(([key, val], i) => {
                      val.map((submodeulaData) => {
                        if (
                          submodeulaData.read === true &&
                          submodeulaData.delete === false &&
                          submodeulaData.execution === false &&
                          submodeulaData.update === false &&
                          submodeulaData.write === false
                        ) {
                          viewAccess = true;
                        } else viewAccess = false;

                        if (
                          submodeulaData.read === true &&
                          submodeulaData.delete === true &&
                          submodeulaData.execution === true &&
                          submodeulaData.update === true &&
                          submodeulaData.write === true
                        ) {
                          fullAccess = true;
                        } else fullAccess = false;
                      });
                    });
                  }
                  return (
                    <div className="roleAccess_data_popup">
                      <p>{Object.keys(moduleData)[0]}</p>

                      <p>
                        {viewAccess === true ? (
                          <CheckIcon
                            sx={{
                              color: "#049547",
                              width: "18px",
                            }}
                          />
                        ) : (
                          "-"
                        )}
                      </p>

                      <p>
                        {fullAccess === true ? (
                          <CheckIcon
                            sx={{
                              color: "#049547",
                              width: "18px",
                            }}
                          />
                        ) : (
                          "-"
                        )}
                      </p>
                    </div>
                  );
                })}
            </div>
          </div>
          <button
            className="roleBtn_popup"
            onClick={() => {
              setIsRoleListViewPopUp(false);
            }}
          >
            Cancel
          </button>
        </div>
      )}
    </div>
  );
};

export default RoleManagementTable;
