import React, { useState, useEffect } from "react";
import {
  IconButton,
  Dialog,
  DialogTitle,
  Typography,
  DialogContent,
  MenuItem,
  TextField,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import _ from "lodash";
import { toast } from "react-toastify";
import axios from "axios";
import CancelIcon from "../../../assets/images/adminPanel/CancelIcon.svg";
import "../CreateNewBusinessUnit/CreateNewBusinessunit.css";
import { useMutation, useQueryClient } from "react-query";

import { queryKeyConstants } from "../../../constants/basicConstants";
import nodeApiInstance from "../../../api/instance/nodeApiInstance";

const UpdateNewBusinessunit = ({
  openUpdateBusinessunitDialog,
  setOpenUpdateBusinessunitDialog,
  dataForEditbusinessunit,
}) => {
  // form validation rules
  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .required("Name is required")
      // .min(3, "Name should be minimun 3 characters.")
      .trim(),
    description: Yup.string().optional().trim(),
  });
  const formOptions = { resolver: yupResolver(validationSchema) };

  //User Form
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm(formOptions);

  const queryClient = useQueryClient();

  const updateBusinessUnit = useMutation(
    async (payload) => {
      return nodeApiInstance.post("details/update-business-unit", payload);
    },
    {
      onSuccess: (value) => {
        queryClient.invalidateQueries(queryKeyConstants.getAllBusinessUnits);
        setOpenUpdateBusinessunitDialog(false);
        if (value.data.errors?.length !== undefined) {
          toast.error(value.data.errors[0].message);
        } else {
          toast.success("Business Unit updated");
        }
      },
    }
  );

  //Update business unit
  const handleRegistration = (data) => {
    const payload = {
      business_unit_id: dataForEditbusinessunit?.business_unit_id,
      description: data.description,
      name: data.name,
    };
    if (data.description.length > 500) {
      setError("description", {
        type: "custom",
        message: `Length should not exceed 500 characters`,
      });
    } else {
      updateBusinessUnit.mutate(payload);
    }
  };

  const handleError = (errors) => {};

  return (
    <>
      <Dialog
        id="updateBusinessunitDialog"
        open={openUpdateBusinessunitDialog}
        onClose={() => {
          setOpenUpdateBusinessunitDialog(!openUpdateBusinessunitDialog);
        }}
        aria-labelledby="new-user-dialog"
        aria-describedby="new-user-dialog"
        sx={{
          "& .MuiDialog-paper": {
            boxShadow: "0px 6px 10px rgba(0, 0, 0, 0.3)",
            borderRadius: "8px",
            width: "675.4px",
            height: "470.96px",
          },
        }}
      >
        <DialogTitle id="new-user-dialog" sx={{ m: 0, p: 3 }}>
          <Typography variant="h5" component="div" className="create-heading">
            Edit Business Unit
          </Typography>
          <IconButton
            id="closeBuDialog"
            aria-label="close"
            onClick={() => {
              setOpenUpdateBusinessunitDialog(false);
            }}
            sx={{
              position: "absolute",
              right: 16,
              top: 27,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <img src={CancelIcon} alt="Delete" />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ position: "relative", p: 3 }}>
          <form
            id="cupdateBUform"
            onSubmit={handleSubmit(handleRegistration, handleError)}
            autoComplete="off"
            style={{ marginTop: "10px" }}
          >
            <div className="info-row">
              <label className="info-label">Business Unit*</label>
              <TextField
                className="info-input"
                sx={{ "& .MuiInputBase-input": { padding: "0px" } }}
                id="businessunit_name"
                defaultValue={dataForEditbusinessunit?.name}
                variant="standard"
                {...register("name")}
              />
            </div>
            {errors?.name && errors.name.message && (
              <div className="info-row-erros" id="updateBuNameEr">
                <div></div>
                <small className="text-danger">
                  {errors?.name && errors.name.message}
                </small>
              </div>
            )}

            <div className="info-row">
              <label className="info-label">Region*</label>
              <TextField
                disabled
                className="info-input"
                sx={{
                  "& .MuiInputBase-input": {
                    padding: "0px",
                  },
                  "& .MuiInputBase-root:before": {
                    borderBottomStyle: "solid",
                  },
                }}
                id="businessunit_region"
                defaultValue={dataForEditbusinessunit?.region.name}
                variant="standard"
                {...register("region_id")}
              />
            </div>

            <div className="info-row" id="updateBuDesc">
              <label className="user-info-label">Description</label>
              <TextField
                className="info-input"
                sx={{ "& .MuiInputBase-root": { padding: "0px" } }}
                id="businessunit_description"
                defaultValue={dataForEditbusinessunit?.description}
                variant="standard"
                maxRows={4}
                multiline
                {...register("description")}
              />
            </div>
            {errors?.description && errors.description.message && (
              <div className="info-row-erros" id="updateBuErr">
                <div></div>
                <small className="text-danger">
                  {errors?.description && errors.description.message}
                </small>
              </div>
            )}

            <button className="create-button" id="buUpdateButton">
              <span>Update</span>
            </button>
          </form>
          <button
            className="cancel-button"
            id="buCancelButton"
            onClick={() => {
              setOpenUpdateBusinessunitDialog(false);
            }}
          >
            <span>Cancel</span>
          </button>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default UpdateNewBusinessunit;
