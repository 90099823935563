import { getAccessDataByRole } from "../api/readApiFunctions";
import { accessConstants, moduleConstants, subModuleConstants } from "../constants/accessManagementConstants";
import { switchToAdminLocation } from "./adminServices";

/** isAccessProvided is a function for getting the accesses on component or any functionality
 * as per the module, sub module, actions and access type on particular component.
 * Also provide the accesses data which is coming from db */
export const isAccessProvided = (
  module,
  subModuleName,
  action,
  access_type,
  accessObject
) => {
  if (accessObject) {
    let selectedAccessModule = accessObject[module];

    const selectedSubModule = selectedAccessModule.sub_modules.filter(
      (subModule) => {
        //crud means any access on whole page
        if (subModuleName === "crud") {
          if (subModule.sub_module === subModuleName) {
            return subModule;
          }
        } else {
          //particular part of that page
          if (
            subModule.sub_module === subModuleName &&
            subModule.action === action
          ) {
            return subModule;
          }
        }
      }
    );

    const acessType = selectedSubModule[0]?.roles_access?.[access_type];

    return acessType;
  }
};

// this function is responsible for making sure that if user lands of any page by route , 
// or when he presses go back from the error page, and after login then he will be only shown the pages he has access to.
export const handleDefaultUserAccess = async (id , role , navigate , setIsSwitchToAdminPanel , setAccessData) => {
  const data = await getAccessDataByRole(id , role)

  setAccessData(data)
 
    // if user has no access to asset page then re-drect to profile page
    if (
      isAccessProvided(
        moduleConstants.asset_view,
        subModuleConstants.crud,
        null,
        accessConstants.read,
        data
      )
    ) {
      navigate("/");
    } else if (
      isAccessProvided(
        moduleConstants.profile_view,
        subModuleConstants.crud,
        null,
        accessConstants.read,
        data
      )
    ) {
      navigate(`/compliance-result/compliance-listview/konfer-confidence`);
    } else if (
      isAccessProvided(
        moduleConstants.extraction,
        subModuleConstants.crud,
        null,
        accessConstants.read,
        data
      )
    ) {
      navigate(`/discovery`);
    } else {
      let locationUrl = switchToAdminLocation(data);
      if (locationUrl !== "") {
        navigate(locationUrl);
        setIsSwitchToAdminPanel(false);
      } else {
        navigate("/403-error");
      }
    }

}
