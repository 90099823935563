import React from 'react'

const NotFound = () => {
  return (
    <div>
        <h2>404 Page not Found</h2>
    </div>
  )
}

export default NotFound