import React, { useEffect, useState } from "react";
import "./SubsectionFileUpload.css";
import UploadSVG from "../../assets/images/icons/upload-icon.svg";
import pdfIcon from "../../assets/images/icons/pdf-icon.svg";
import DeleteFileIcon from "../../assets/images/icons/delete-file-icon.svg";
import CancelIcon from "../../assets/images/adminPanel/CancelIcon.svg";
import { Button } from "@mui/material";
import { handleFileDownload } from "../../api/readApiFunctions";
import { useFileUploadForDocument } from "../../api/createApiFunctions";
import { documentFileUpload } from "../../atom/jotai";
import { useAtom } from "jotai";
import downloadBtn from "../../assets/images/icons/download-btn.svg";
import { useDeleteDocumentFiles } from "../../api/deleteApiFunctions";
import { getFileIcon } from "../../services/cardServices";

const SubsectionFileUpload = ({
  section_id,
  cardId,
  subsectionId,
  textDataId,
  setEditedTextData,
  editedTextData,
  isEditDatasetSection,
}) => {
  const [allDocumentFiles, setAllDocumentFiles] = useAtom(documentFileUpload);
  const { data: documentFileData, mutate: uploadDocumentFile } =
    useFileUploadForDocument();
  const { data: deletedFileData, mutate: deleteFileData } =
    useDeleteDocumentFiles();

  const onHandleFileChange = (e) => {
    const chosenFiles = Array.prototype.slice.call(e.target.files);

    const fileData = new FormData();

    chosenFiles.map((file) => {
      fileData.append(`files`, file);
    });

    fileData.append(`source`, "subsections");

    uploadDocumentFile({
      filePayload: fileData,
    });
  };

  useEffect(() => {
    if (documentFileData) {
      // store the ids in the localstorage
      const ids = localStorage.getItem("deleteFileIds")
        ? JSON.parse(localStorage.getItem("deleteFileIds"))
        : [];

      const modifiedFileRes = documentFileData.map((file) => {
        ids.push(file);
        return {
          file_name: file?.file_name,
          id: file?.id,
          url: `${process.env.REACT_APP_REST_API}/card/file?id=${file.id}`,
        };
      });
      // set the updated ids in localstorage
      localStorage.setItem("deleteFileIds", JSON.stringify(ids));

      const combinedFiles = [...modifiedFileRes, ...allDocumentFiles];

      // one the file is updated, modify the state by adding the new files in it
      setAllDocumentFiles(combinedFiles);

      // check if the  subsection with this id exists
      const existingItem = editedTextData.find(
        (item) => item.subsection_id === subsectionId
      );

      if (existingItem) {
        const updatedItems = editedTextData.map((item) => {
          if (item.subsection_id === subsectionId) {
            return { ...item, text_data: JSON.stringify(combinedFiles) }; // Replace existing item with the new one
          } else {
            return item; // Keep the other items unchanged
          }
        });

        setEditedTextData(updatedItems);
      } else {
        // Handle the case when the item with the provided ID doesn't exist
        const documentObj = {
          section_id: section_id,
          subsection_id: subsectionId,
          card_id: cardId,
          text_data: JSON.stringify(combinedFiles),
        };
        if (textDataId) {
          documentObj["text_data_id"] = textDataId;
        }
        setEditedTextData((prevState) => [...prevState, documentObj]);
      }
    }

    // store the new uploaded file ids in global state to delete on cancel
  }, [documentFileData]);

  const handleDeleteFile = (file) => {
    const filteredArr1 = allDocumentFiles.filter((obj) => file.id !== obj.id);

    // check if the  subsection with this id exists
    const existingItem = editedTextData.find(
      (item) => item.subsection_id === subsectionId
    );

    if (existingItem) {
      const updatedItems = editedTextData.map((item) => {
        if (item.subsection_id === subsectionId) {
          return { ...item, text_data: JSON.stringify(filteredArr1) }; // Replace existing item with the new one
        } else {
          return item; // Keep the other items unchanged
        }
      });

      setEditedTextData(updatedItems);
    } else {
      // Handle the case when the item with the provided ID doesn't exist
      const documentObj = {
        section_id: section_id,
        subsection_id: subsectionId,
        card_id: cardId,
        text_data: JSON.stringify(filteredArr1),
      };
      if (textDataId) {
        documentObj["text_data_id"] = textDataId;
      }
      setEditedTextData((prevState) => [...prevState, documentObj]);
    }

    setAllDocumentFiles(filteredArr1);
  };

  return (
    <>
      {isEditDatasetSection &&   (
        <div className="sub_upload_files">
          {allDocumentFiles.length === 0 && <div className="sub_upload__wrapper sub_upload__file--border">
            <div>
              <img
                src={UploadSVG}
                alt="Upload Icon"
                className="upload__image"
              />
            </div>
            <p>Drag and Drop to upload</p>
            <p>or</p>
            <Button
              variant="contained"
              component="label"
              class="custom-file-upload"
            >
              Browse
              <input
                type="file"
                //   onChange={(e) => {
                //     onHandleFileChange(e)
                //   }}
                onChange={onHandleFileChange}
                // multiple
                hidden
                accept=".pdf"

                //accept=".doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.pdf ,image/png ,application/pdf, .csv"
              />
            </Button>
            <div className="file_support--text">
              <p>Supports: .csv, .xlsx, .png, .pdf</p>
            </div>
          </div>}

          <div className="sub_upload__file__border sub_uploadedFiles">
            {allDocumentFiles.map((file, i) => (
              <>
                {file && (
                  <div key={file?.id} className="files_uploaded--list">
                    {/* <img src={pdfIcon} alt="File Icon" /> */}
                    <img
                      src={
                        file.file_name.split(".").length > 0
                          ? getFileIcon(file.file_name.split(".")[1])
                          : pdfIcon
                      }
                      style={{
                        width: "18px",
                      }}
                    />
                    <div className="file_name">
                      <p>{file.file_name}</p>
                    </div>
                    <div
                      onClick={() => {
                        // deleteFileData([file])
                        handleDeleteFile(file);
                      }}
                    >
                      <img src={DeleteFileIcon} alt="Delete button" />
                    </div>
                  </div>
                )}
              </>
            ))}
          </div>
        </div>
      )}

      {isEditDatasetSection === false &&
        allDocumentFiles.map((file, i) => (
          <>
            {file && (
              <div key={file?.id} className="files_uploaded--list">
                {/* <img src={pdfIcon} alt="File Icon" /> */}
                <img
                  src={
                    file.file_name.split(".").length > 0
                      ? getFileIcon(file.file_name.split(".")[1])
                      : pdfIcon
                  }
                  style={{
                    width: "18px",
                  }}
                />
                <div className="file_name">
                  <p>{file.file_name}</p>
                </div>
                <div>
                  <img
                    style={{
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      handleFileDownload(file, "card_modal");
                    }}
                    src={downloadBtn}
                    alt="Delete button"
                  />
                </div>
              </div>
            )}
          </>
        ))}
    </>
  );
};

export default SubsectionFileUpload;
