import * as am5 from "@amcharts/amcharts5";
import { riskColumnColors } from "../constants/amcharts";

export const riskFactorScoreColour = (RiskFactorData) => {
  // if (RiskFactorData && RiskFactorData?.length > 0) {
  if (RiskFactorData) {
    RiskFactorData.map((data) => {
      data?.value >= 5
        ? (data.scoreColumnColor = {
            fill: am5.color(riskColumnColors.scoreAboveLimit),

            stroke: am5.color(riskColumnColors.scoreAboveLimit),
          })
        : (data.scoreColumnColor = {
            fill: am5.color(riskColumnColors.scoreBelowLimit),

            stroke: am5.color(riskColumnColors.scoreBelowLimit),
          });
      return data;
    });

    return RiskFactorData;
  }
};
