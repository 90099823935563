import { FormatListBulleted, Share } from "@mui/icons-material";
import { Divider, Tooltip } from "@mui/material";
import { useAtom } from "jotai";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  businessUnits,
  businessUnitName,
  isGraphview,
  selectedBusinessUnit,
  regionIDAtom,
  regionNameAtom,
  totalCountOfHighlightWord,
  indexOfSearchedKeywordAtom,
} from "../../atom/jotai";
import { KONFER_SCORE } from "../../constants/basicConstants";
import { complianceTagName } from "../../constants/complianceConstants";
import { analyticServices } from "../../services/analyticServices";
import { deslugifyText } from "../../utils/basicUtils";
import MuiSelect from "../MuiSelect/MuiSelect";
import "./Resultview.css";
import ListviewIcon from "../../assets/images/listview/Listview.svg";
import GraphIcon from "../../assets/images/listview/GraphView.svg";
import { PolicyNameConst } from "../../constants/policyConstants";

const Resultview = ({
  regionData,
  setRegionData,
  businessUnitData,
  setprevHighlightedLOC,
  setCurrentCount,
  setFindElement,
}) => {
  const location = useLocation();

  const { searchText } = useParams();
  const [businessData, setBusinessData] = useAtom(businessUnits);
  const [businessName, setBusinessUnitName] = useAtom(businessUnitName);
  const [selectedBuId, setSelectedBusiness] = useAtom(selectedBusinessUnit);
  const [isGraphView, setIsGraphView] = useAtom(isGraphview);
  const [isListView, setIsListView] = useState(
    location.pathname.includes("listview") ? true : false
  );
  const [resultData, setResultData] = useState(null);
  const [regionID, setRegionID] = useAtom(regionIDAtom);
  const [regionName, setRegionName] = useAtom(regionNameAtom);
  const [, setTotalCountOfHighlitedWord] = useAtom(totalCountOfHighlightWord);
  const [, setIndexOfSearchedKeyword] = useAtom(indexOfSearchedKeywordAtom);

  const navigate = useNavigate();

  const handleListviewClick = () => {
    navigate(`/result/listview/${searchText}`);
  };

  const handleGraphviewClick = () => {
    navigate(`/result/graphview/${searchText}`);
  };

  useEffect(() => {
    let resultText;
    if (searchText === KONFER_SCORE) {
      resultText = `${PolicyNameConst.konfer_confidence} `;
      //  ${businessName !== null ? `| ${businessName}` : ""}`;
    } else {
      resultText = ` Results for " ${
        searchText == "*" ? "*" : deslugifyText(searchText)
      }" `;
      //  ${businessName !== null ? `| ${businessName}` : ""}`;
    }

    setResultData(resultText);
  }, [searchText, businessData, businessName]);

  //This functiona is called when we select from Region drop down
  const handleRegionData = (value) => {
    let tempArrForBD = [];
    setRegionID(value);

    regionData?.map((data) => {
      data.id == value && setRegionName(data.name);

      data.id === value &&
        analyticServices(`SEARCH  : konfer-confidence ${data.name}`);
    });

    businessUnitData.data.data.regionData?.map((item) => {
      //setting the region name name for selected region as per the id
      item.regionID === value && setRegionName(item.regionName);

      //For setting business dropdown data
      if (
        item.regionID == value &&
        item.regionName !== complianceTagName.AllRegionsTag
      ) {
        item.businessUnitsData?.map((unit) => {
          tempArrForBD.push({
            id: unit.businessUnitID,
            name: unit.businessUnitName,
          });
          //For showing All Business units by default
          if (unit.businessUnitName === complianceTagName.AllBusinessUnitsTag) {
            setBusinessUnitName(unit.businessUnitName);
            setSelectedBusiness([unit.businessUnitID]);
          }
        });
      } else if (item.regionName === complianceTagName.AllRegionsTag) {
        setSelectedBusiness([]);
      }
    });

    setBusinessData(tempArrForBD);
    setFindElement("");
    setTotalCountOfHighlitedWord(0);
    setIndexOfSearchedKeyword(-1);
    setCurrentCount(0);
    setprevHighlightedLOC([]);
  };

  //This functiona is called when we select from Business unit drop down
  const handleBusinessUnits = (value) => {
    setSelectedBusiness([value]);

    businessData?.map((data) => {
      data.id == value && setBusinessUnitName(data.name);

      data.id === value &&
        analyticServices(`SEARCH  : konfer-confidence ${data.name}`);
    });

    setFindElement("");
    setTotalCountOfHighlitedWord(0);
    setIndexOfSearchedKeyword(-1);
    setCurrentCount(0);
    setprevHighlightedLOC([]);
  };

  useEffect(() => {
    if (isListView) {
      analyticServices("List View Page Visit");
    } else {
      analyticServices("Graph View Page Visit");
    }
  }, [isListView]);

  return (
    <div className="result_title_list" id="resultTitle">
      <div className="result_title" id="resultTitleHeadings">
        <h2>{resultData && resultData}</h2>

        {isListView && (
          <>
            <Divider
              orientation="vertical"
              variant="middle"
              flexItem
              className="seperator-resultview"
            />
            <MuiSelect
              defaultValue={regionID && regionID}
              data={regionData && regionData}
              onChange={handleRegionData}
            />

            {regionName !== "" &&
              regionName !== complianceTagName.AllRegionsTag && (
                <>
                  <Divider
                    orientation="vertical"
                    variant="middle"
                    flexItem
                    className="seperator-resultview"
                  />
                  <MuiSelect
                    defaultValue={selectedBuId && selectedBuId}
                    data={businessData && businessData}
                    onChange={handleBusinessUnits}
                  />
                </>
              )}
          </>
        )}
      </div>

      {isGraphView && (
        <div className="listview-cont" id="resultButtonsDiv">
          <div
            id="resultListviewButton"
            className={
              !isListView ? "listview-btn" : "listview-btn list-active"
            }
            onClick={() => setIsListView(true)}
          >
            <Tooltip title="List View">
              <img
                src={ListviewIcon}
                alt=""
                onClick={handleListviewClick}
                style={{ width: "20px", height: "20px" }}
              />
            </Tooltip>
          </div>

          <div
            id="resultGraphviewButton"
            className={isListView ? "listview-btn" : "listview-btn list-active"}
            onClick={() => setIsListView(false)}
          >
            <Tooltip title="Graph View">
              <img
                src={GraphIcon}
                alt=""
                onClick={handleGraphviewClick}
                style={{ width: "25px", height: "20px" }}
              />
            </Tooltip>
          </div>
        </div>
      )}
    </div>
  );
};

export default Resultview;
