import React, { useEffect, useState } from "react";
import { Equalizer } from "@mui/icons-material";
import {
  Tooltip,
  IconButton,
  Button,
  Modal,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Link,
  TablePagination,
  CircularProgress,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import { NavLink, useParams } from "react-router-dom";
import {
  detailsFields,
  detailsFieldsNames,
  detailsTypeNamesCapitalized,
} from "../../constants/detailConstants";
import { CheckOutlined, CloseOutlined } from "@mui/icons-material";
import EditIcon from "../../assets/images/detail/edit_annotation.svg";
import { useAtom } from "jotai";
import { accessDataByRoleAtom, authUser } from "../../atom/jotai";
import {
  useUpdateNodeDetailsInfo,
  useUpdateScoreInclusion,
} from "../../api/updateApiFunctions";
import "./DetailList.css";
import HighlightText from "../HOC/HighlightText";
import { analyticServices } from "../../services/analyticServices";
import {
  useGetUserlist,
  useGetJsonDataForPythonCode,
  handleSampleFileDownload,
  useGetEntityEditableFields,
} from "../../api/readApiFunctions";
import { typeNames } from "../../constants/dataTypes";
import _ from "lodash";
import Select from "react-select";
import { riskFactorValueCond } from "../../constants/riskFactorMonth";
import ZoomImage from "../ZoomImage/ZoomImage";
import { detailsTabOnDetailsPage } from "../../constants/tooltipConstants";
import { isAccessProvided } from "../../services/assetManagementService";
import { PolicyNameConst } from "../../constants/policyConstants";

const DetailList = ({
  dataInfo,
  riskFactorsData,
  scoresSelectedPolicy,
  appRefetch,
  modelRefetch,
  datasetRefetch,
}) => {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "40%",
    bgcolor: "#FFFFFF",
    boxShadow: 24,
    p: 4,
    borderRadius: "8px",
  };

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const [editable, setEditable] = useState(false); //state to maintain wheather the detailsInfo is editabale or not
  const [user] = useAtom(authUser); // Jotai state
  let editNodeType, editNodeId;
  const [editDetailsValues, setEditDetailsValues] = useState(null);
  const [editDetailsFields, setEditDetailsFields] = useState(null);
  const { nodeType, nodeId } = useParams();
  const [contributorOptions, setContributorOptions] = useState([]);
  const [editContributor, setEditContributor] = useState("");
  // this flag decides to add contributors in request payload or not to avoid unnecessary logic in backend
  const [isEditContributor, setIsEditContributor] = useState(false);
  const [isGermanData, setIsGermanData] = useState(false);

  const [show, setShow] = useState(false);
  const {
    data: getJsonDataRes,
    error,
    isLoading,
  } = useGetJsonDataForPythonCode();

  const {
    mutate: updateScoreInclusion,
    data: updateScoreInclusionData,
    isSuccess: updateScoreInclusionIsSuccess,
  } = useUpdateScoreInclusion();

  const [tRows, setTRows] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(6);
  const [isLoadingModal, setIsLoadingModal] = useState(false);
  const [showPythonFiles, setShowPythonFiles] = useState(false);
  //Modal for showing the python image
  const [showZoomModal, setShowZoomModal] = useState(false);
  //Storing the image of Filtered Python Call Graph
  const [imageForFilteredPythonCallGraph, setImageForFilteredPythonCallGraph] =
    useState("");
  //Storing the image of Python Call Graph
  const [imageForPythonCallGraph, setImageForPythonCallGraph] = useState("");
  //State for Showing the whether Filtered Python Call Graph called or Python Call Graph called
  const [showPythonGraphName, setShowPythonGraphName] = useState("");
  const handleOpen = () => setShow(true);
  const handleClose = () => setShow(false);
  const [accessDataByRole, setAccessDataByRole] = useAtom(accessDataByRoleAtom);
  const [detailsDataInfo, setDetailsDataInfo] = useState(null);

  const { data } = useGetEntityEditableFields();
  const { data: contributorsList } = useGetUserlist("details", "crud", true);

  const [isScoreInclusion, setIsScoreInclusion] = useState();
  // const {mutate: updateEntityContributor} = useUpdateContributorsOnEntity()

  function createData(PackageName, ImportedModule) {
    return { PackageName, ImportedModule };
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const cardDocumentLink = process.env.REACT_APP_CARD_DOCUMENT_LINK;
  const funcCallGraphLink = process.env.REACT_APP_FUNC_CALL_GRAPH_LINK;
  const filteredGraphLink = process.env.REACT_APP_FILTERED_GRAPH_LINK;

  useEffect(() => {
    setIsLoadingModal(isLoading);
  }, [isLoading]);

  useEffect(() => {
    if (getJsonDataRes) {
      let tableRows = [];
      const fileData = getJsonDataRes?.data?.data;
      fileData?.import?.import &&
        fileData?.import?.import.length > 0 &&
        fileData?.import?.import.forEach((item) => {
          tableRows.push(createData(item, "-"));
        });

      fileData?.from_import?.extract &&
        fileData?.from_import?.extract.length > 0 &&
        fileData?.from_import?.extract.forEach((item) => {
          tableRows.push(createData(item.from, item.module.toString()));
        });

      setTRows(tableRows);
    }
  }, [getJsonDataRes]);

  useEffect(() => {
    dataInfo && setIsScoreInclusion(dataInfo?.calculate_score);
    if (
      !isAccessProvided(
        "score_inclusion",
        "crud",
        null,
        "read",
        accessDataByRole
      )
    ) {
      const newData = dataInfo;
      delete newData.calculate_score;
      setDetailsDataInfo(newData);
    } else {
      setDetailsDataInfo(dataInfo);
    }
  }, [dataInfo]);

  useEffect(() => {
    if (editable === true) {
      const contributorData = dataInfo.contributor;
      const editDefaultContributors = [];
      if (contributorData && contributorData.length > 0) {
        for (let i = 0; i < contributorData.length; i++) {
          const element = contributorData[i].contributor;
          editDefaultContributors.push({
            label: element.name,
            value: element.contributor_id,
          });
        }
      }

      setEditContributor(editDefaultContributors);
    }
  }, [editable]);

  useEffect(() => {
    const formattedMentionList = [];
    contributorsList?.map((user) => {
      formattedMentionList.push({
        label: user?.name,
        value: user?.contributor_id,
      });
    });

    setContributorOptions(formattedMentionList);
  }, [contributorsList]);

  const handleContributorSelect = (values, event) => {
    setIsEditContributor(true);
    setEditContributor(values);
  };

  useEffect(() => {
    if (
      data &&
      (data.app_details_editable.length > 0 ||
        data.model_details_editable.length > 0 ||
        data.input_data_table_details_editable.length > 0)
    ) {
      if (dataInfo.__typename === typeNames.apps) {
        setEditDetailsFields(data.app_details_editable);
      }
      if (dataInfo.__typename === typeNames.models) {
        setEditDetailsFields(data.model_details_editable);
      }
      if (dataInfo.__typename === typeNames.datasets) {
        setEditDetailsFields(data.input_data_table_details_editable);
      }
    }
  }, [data, dataInfo]);

  useEffect(() => {
    let isGerman = false;
    let name = dataInfo?.name?.toLowerCase();
    let displayName = dataInfo?.display_name?.toLowerCase();

    if (name && name.includes("german")) {
      isGerman = true;
    }

    if (displayName && displayName.includes("german")) {
      isGerman = true;
    }

    if (
      dataInfo &&
      dataInfo?.__typename === "app" &&
      ((displayName && displayName.includes("Credit-Risk-Classification")) ||
        (name && name.includes("credit-risk-classification")))
    ) {
      setShowPythonFiles(true);
      if (dataInfo?.card?.chart_data?.length > 0) {
        dataInfo?.card?.chart_data?.map((chartData) => {
          if (chartData.chart_type === "Function Call Graph") {
            setImageForPythonCallGraph(chartData.chart_data_image);
          }

          if (chartData.chart_type === "Filtered Function Call Graph") {
            setImageForFilteredPythonCallGraph(chartData.chart_data_image);
          }
        });
      }
    } else {
      setShowPythonFiles(false);
      setImageForPythonCallGraph("");
      setImageForFilteredPythonCallGraph("");
    }

    if (
      (displayName && displayName.includes("Credit-Risk-Classification")) ||
      (name && name.includes("credit-risk-classification"))
    ) {
      isGerman = true;
    }

    setIsGermanData(isGerman);
  }, [dataInfo]);

  // react -query muatations
  const {
    mutate: updateNodeDetailsInfo,
    isSuccess: updateUserSuccess,
    data: updateNodeDetailsInfoData,
  } = useUpdateNodeDetailsInfo();

  useEffect(() => {
    if (updateUserSuccess || updateScoreInclusionIsSuccess) {
      appRefetch();
      modelRefetch();
      datasetRefetch();
    }
  }, [updateNodeDetailsInfoData, updateScoreInclusionData]);

  // check if contributor field in editable
  const checkIsContributorEditable = (editableList) => {
    let isEditable = false;

    const data = editableList.filter(
      (ele) => ele.field_name === "contributors"
    );

    if (data) {
      isEditable = data[0]?.value;
    }

    return isEditable;
  };

  const createCardDocNameAndUrl = (type) => {
    let name = "";
    if (type === typeNames.apps) {
      name = "Model_Card_For_App.pdf";
    } else if (type === typeNames.models) {
      name = "Model_Card_For_Model.pdf";
    } else if (type === typeNames.datasets) {
      name = "Model_Card_For_Datasets.pdf";
    }

    const url = `${process.env.REACT_APP_REST_API}/card/file?file_name=${name}&source=template`;

    return {
      name,
      url,
    };
  };

  //Function for allowing user to edit details if the user is contributor for that node
  const handleAuthenticationForEditDetails = () => {
    let authenticated = false; //Making it false for not showing the Edit Icon on deatils section

    if (user?.role === "Admin" || user?.role === "SuperAdmin") {
      authenticated = true;
    } else if (
      dataInfo &&
      dataInfo.contributor &&
      dataInfo.contributor.length > 0
    ) {
      //If more than 2 contributors are present for particular node
      for (let i = 0; i < dataInfo.contributor.length; i++) {
        const element = dataInfo.contributor[i];
        if (element.contributor.contributor_id === user.contributor_id) {
          authenticated = true;
          break;
        }
      }
    }
    return authenticated;
  };

  //Function used to update node details info in database
  const handleUpdateDeatils = async () => {
    setEditable(false);

    const payload = {
      nodeId: editNodeId,
      nodeName: dataInfo.__typename, // editNodeType
      updateData: editDetailsValues,
    };

    if (isEditContributor) {
      payload.updatedContributors = editContributor;
    }

    if (dataInfo.calculate_score !== isScoreInclusion) {
      const scorePayload = {
        type: dataInfo.__typename,

        id: [dataInfo[`${dataInfo["__typename"]}_id`]],

        score_calculation: isScoreInclusion,
      };
      updateScoreInclusion(scorePayload);
    }

    updateNodeDetailsInfo(payload);
    analyticServices(
      `${dataInfo.__typename} - ${
        dataInfo.display_name === null ? dataInfo.name : dataInfo.display_name
      } details updated `
    );

    setEditDetailsValues(null);
    setIsEditContributor(false);
  };

  const ObservabilityAnalyticServices = () => {
    analyticServices(
      `Observability Page View for ${dataInfo.__typename} - ${
        dataInfo.display_name === null ? dataInfo.name : dataInfo.display_name
      }`
    );
  };

  return (
    <>
      {/* show edit icons */}
      <div className="editDetailsIcon" id="detailsEditIconDiv">
        {!editable &&
          isAccessProvided(
            "details",
            "crud",
            null,
            "write",
            accessDataByRole
          ) && (
            <Tooltip title="Edit" disableFocusListener disableInteractive>
              <IconButton
                id="detailsEditIconButton"
                className="editDetailsIconButton"
                fontSize="small"
                onClick={() => {
                  setEditable(true);
                }}
              >
                <img src={EditIcon} alt="" />
              </IconButton>
            </Tooltip>
          )}

        {/* show edit icons */}
        {editable && (
          <>
            <Tooltip title="Save" disableFocusListener disableInteractive>
              <CheckOutlined
                fontSize="small"
                onClick={handleUpdateDeatils}
                id="detailsUpdateSubmitIcon"
              />
            </Tooltip>
            <Tooltip title="Cancel" disableFocusListener disableInteractive>
              <CloseOutlined
                fontSize="small"
                onClick={() => {
                  setEditable(false);
                }}
                id="detailsUpdateCancelIcon"
              />
            </Tooltip>
          </>
        )}
      </div>

      {/* render the icons */}
      {editDetailsFields && editDetailsFields.length > 0 && (
        <div
          className="detail_data"
          id="detailsData"
          style={editable ? { alignItems: "center" } : {}}
        >
          {detailsDataInfo &&
            Object.entries(detailsDataInfo).map((val, index) => {
              val[1] = val[1] === "" || val[1] === null ? "-" : val[1];
              let data;
              if (val[0] === detailsTypeNamesCapitalized.created_at) {
                val[1] = val[1].split(":").splice(0, 2).join(".");
              }
              if (val[0] === detailsTypeNamesCapitalized.__typename) {
                val[1] = _.startCase(val[1]);
              }
              if (
                editable &&
                (val[0] === "app_id" ||
                  val[0] === "model_id" ||
                  val[0] === "input_data_table_id" ||
                  val[0] === "input_data_column_id")
              ) {
                editNodeId = val[1];
              }

              // check if field name is contributer
              if (val[0] === "contributor") {
                if (editable && checkIsContributorEditable(editDetailsFields)) {
                  data = (
                    <Select
                      id="createUser-regions"
                      value={editContributor}
                      isMulti
                      isClearable={false}
                      maxMenuHeight="100px"
                      onChange={handleContributorSelect}
                      options={contributorOptions}
                    />
                  );
                } else {
                  data = (
                    <HighlightText
                      text={
                        val[1] && val[1].length > 0
                          ? val[1].map((contributorDetail, i) => {
                              return ` ${contributorDetail.contributor.name}`;
                            })
                          : "-"
                      }
                    />
                  );
                }
              } else if (
                editable === true &&
                editDetailsFields.some(
                  (ele) => ele.field_name === val[0] && ele.value
                )
              ) {
                if (val[0] === "calculate_score") {
                  data = (
                    <div
                      className="scoreInclusionCont"
                      id="scoreInclusionButtonsDiv"
                    >
                      <div
                        id="scoreInclusionButton"
                        className={
                          isScoreInclusion
                            ? "scoreInclusionBtn scoreInclusionActive"
                            : "scoreInclusionBtn"
                        }
                        onClick={() => {
                          if (
                            isAccessProvided(
                              "score_inclusion",
                              "crud",
                              null,
                              "update",
                              accessDataByRole
                            )
                          ) {
                            setIsScoreInclusion(true);
                          }
                        }}
                      >
                        <span
                          className={
                            isScoreInclusion
                              ? "scoreInclusionBtnTextActive"
                              : " scoreInclusionBtnText"
                          }
                        >
                          YES
                        </span>
                      </div>

                      <div
                        id="resultGraphviewButton"
                        className={
                          isScoreInclusion
                            ? "scoreInclusionBtn"
                            : "scoreInclusionBtn scoreInclusionActive"
                        }
                        onClick={() => {
                          if (
                            isAccessProvided(
                              "score_inclusion",
                              "crud",
                              null,
                              "update",
                              accessDataByRole
                            )
                          ) {
                            setIsScoreInclusion(false);
                          }
                        }}
                      >
                        <span
                          className={
                            isScoreInclusion
                              ? "scoreInclusionBtnText"
                              : "scoreInclusionBtnTextActive"
                          }
                        >
                          NO
                        </span>
                      </div>
                    </div>
                  );
                } else {
                  data = (
                    <input
                      id={`detailsUpdate${val[0]}`}
                      className="data_value"
                      defaultValue={val[1]}
                      name={val[0]}
                      onChange={(e) => {
                        setEditDetailsValues({
                          ...editDetailsValues,
                          [val[0]]: e.target.value.trim(),
                        });
                      }}
                    />
                  );
                }
              } else {
                if (val[0] === "calculate_score") {
                  data = val[1] ? <span>YES</span> : <span>NO</span>;
                } else {
                  data = <HighlightText text={`${val[1]}`} />;
                }
              }
              let listDataValue = val[1];
              return (
                <>
                  {detailsFields.includes(val[0]) && (
                    <>
                      <p className="data_key">
                        {val[0] === "contributor"
                          ? "Contributors"
                          : detailsFieldsNames[val[0]]}
                      </p>
                      {val[0] == "in_use" ? (val[1] ? "Yes" : "No") : data}
                    </>
                  )}
                </>
              );
            })}

          {/* risk factor list  */}

          {riskFactorsData !== undefined && (
            <>
              <p
                className="data_key"
                id={`detailsRiskfactorskeyKonferConfidence`}
              >
                {PolicyNameConst.konfer_confidence_wo_tm}
              </p>

              <p
                className="data_value"
                id={`detailsRiskfactorskey${riskFactorsData["Konfer Confidence"]}`}
              >
                {riskFactorsData["Konfer Confidence"] !== null &&
                Number(riskFactorsData["Konfer Confidence"]) !==
                  riskFactorValueCond.ntAplicabl
                  ? riskFactorsData["Konfer Confidence"]
                  : "-"}
              </p>

              {/* /**commented for cme */}
              {/* <p className="data_key" id={`detailsRiskfactorskeyHumanImpact`}>
                {PolicyNameConst.human_impact}
              </p>

              <p
                className="data_value"
                id={`detailsRiskfactorskey${riskFactorsData["Human Impact"]}`}
              >
                {riskFactorsData["Human Impact"] !== null &&
                Number(riskFactorsData["Human Impact"]) !==
                  riskFactorValueCond.ntAplicabl
                  ? riskFactorsData["Human Impact"]
                  : "-"}
              </p> */}

              <p className="data_key" id={`detailsRiskfactorskeyHumanImpact`}>
                {scoresSelectedPolicy}
              </p>

              <p
                className="data_value"
                id={`detailsRiskfactorskey${riskFactorsData[scoresSelectedPolicy]}`}
              >
                {riskFactorsData[scoresSelectedPolicy] !== null &&
                Number(riskFactorsData[scoresSelectedPolicy]) !==
                  riskFactorValueCond.ntAplicabl
                  ? riskFactorsData[scoresSelectedPolicy]
                  : "-"}
              </p>
            </>
          )}

          {isAccessProvided(
            "details",
            "detail_info",
            "observability_button",
            "read",
            accessDataByRole
          ) && (
            <>
              <p className="data_key" id="detailsObervabilityKey">
                Observability
              </p>
              <p className="data_value" id="detailsObervabilityValue">
                <NavLink
                  id="detailsObervabilityLink"
                  to={`/observability/${dataInfo?.__typename}/${
                    dataInfo[`${dataInfo?.__typename}_id`]
                  }`}
                  onClick={() => ObservabilityAnalyticServices()}
                >
                  <Tooltip title="Click to see graph data">
                    <Equalizer className="observabilityIcon" />
                  </Tooltip>
                </NavLink>
              </p>
            </>
          )}

          {isGermanData &&
            isAccessProvided(
              "details",
              "detail_info",
              "model_card",
              "read",
              accessDataByRole
            ) && (
              <>
                <p className="data_key" id="detailsObervabilityKey">
                  Card Document
                </p>
                <p className="data_value" id="detailsObervabilityValue">
                  <Tooltip
                    title={createCardDocNameAndUrl(dataInfo.__typename).url}
                  >
                    <div
                      onClick={() => {
                        handleSampleFileDownload(
                          createCardDocNameAndUrl(dataInfo.__typename).name,
                          "template",
                          "details"
                        );
                      }}
                    >
                      <p className="card_doc">Model Card</p>
                    </div>
                  </Tooltip>
                </p>
              </>
            )}

          {imageForPythonCallGraph !== "" && (
            <>
              <p className="data_key" id="detailsFunctionCallGraphKey">
                Function Call Graph
              </p>
              <p className="data_value" id="detailsFunctionCallGraphKey">
                <Tooltip
                  title={`${process.env.REACT_APP_REST_API}/card/file?file_name=python_call_graph.png&source=template`}
                >
                  <div
                    onClick={() => {
                      if (showZoomModal === false) {
                        setShowZoomModal(true);
                        setShowPythonGraphName("python_call_graph");
                      }
                    }}
                  >
                    <p className="card_doc">python_call_graph</p>
                  </div>
                </Tooltip>
              </p>
            </>
          )}

          {imageForFilteredPythonCallGraph !== "" && (
            <>
              <p className="data_key" id="detailsFilteredGraphKey">
                Filtered Graph
              </p>
              <p className="data_value" id="detailsFilteredGraphKey">
                <Tooltip
                  title={`${process.env.REACT_APP_REST_API}/card/file?file_name=filtered_python_call_graph.png&source=template`}
                >
                  <div
                    onClick={() => {
                      if (showZoomModal === false) {
                        setShowZoomModal(true);
                        setShowPythonGraphName("filtered_python_call_graph");
                      }
                    }}
                  >
                    <p className="card_doc">filtered_python_call_graph</p>
                  </div>
                </Tooltip>
              </p>
            </>
          )}

          {showPythonFiles &&
            isAccessProvided(
              "details",
              "detail_info",
              "show_python_code_details",
              "read",
              accessDataByRole
            ) && (
              <>
                <p className="data_key" id="detailsDependenciesKey">
                  Dependencies
                </p>
                <p className="data_value" id="detailsDependenciesKey">
                  <Link onClick={handleOpen} href="#" underline="none">
                    {"Show"}
                  </Link>
                </p>
              </>
            )}

          {show && (
            <Modal open={show} onClose={handleClose}>
              <Box sx={style}>
                <div
                  style={{
                    marginTop: "-25px",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    textAlign: "center",
                  }}
                >
                  <div id="modalHeader">
                    <h2>Dependencies</h2>
                  </div>
                  <div
                    id="modalCloseButton"
                    onClick={() => {
                      setShow(false);
                    }}
                  >
                    <Close sx={{ "&.MuiIconButton-root": { width: "22px" } }} />
                  </div>
                </div>

                {isLoadingModal ? (
                  <Box
                    id="sageMakerLoadingBox"
                    sx={{
                      display: "flex",
                      position: "absolute ",
                      marginTop: "20%",
                      marginLeft: "50%",
                    }}
                  >
                    <CircularProgress />
                  </Box>
                ) : (
                  <>
                    <TableContainer component={Paper}>
                      <Table
                        sx={{ minWidth: 250, border: "1px solid #D0DCE2" }}
                        aria-label="simple table"
                      >
                        <TableHead>
                          <TableRow style={{ background: "#DDE6EA" }}>
                            <TableCell className="modal_table_cell">
                              Package
                            </TableCell>
                            <TableCell className="modal_table_cell">
                              Module
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {tRows
                            .slice(
                              page * rowsPerPage,
                              page * rowsPerPage + rowsPerPage
                            )
                            .map((row) => (
                              <TableRow
                                key={row.name}
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                              >
                                <TableCell
                                  align="left"
                                  component="th"
                                  scope="row"
                                >
                                  {row.PackageName}
                                </TableCell>
                                <TableCell align="left">
                                  {row.ImportedModule}
                                </TableCell>
                              </TableRow>
                            ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <TablePagination
                      rowsPerPageOptions={[6, 10]}
                      component="div"
                      count={tRows.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "end",
                        textAlign: "center",
                        marginBottom: "-23px",
                      }}
                      id="dependencyFileCancelDiv"
                    >
                      <button
                        className="form_button"
                        onClick={() => {
                          setShow(false);
                        }}
                        id="dependencyFileCancelButton"
                      >
                        Cancel
                      </button>
                    </div>
                  </>
                )}
              </Box>
            </Modal>
          )}

          {/* Modal fpr showing the python images */}
          {(imageForFilteredPythonCallGraph !== "" ||
            imageForPythonCallGraph !== "") && (
            <Modal
              id="modalCardForZoom"
              fullScreen={fullScreen}
              sx={{
                "& .MuiModal-root": {
                  margin: "0px 0px 0px 0px",
                },
              }}
              open={showZoomModal}
              onClose={() => {
                setShowZoomModal(false);
              }}
              aria-labelledby="zoomImageModal"
              aria-describedby="zoomImageModalDescription"
            >
              <div id="modalZoomBoxDiv">
                <>
                  <div style={{ display: "flex", justifyContent: "end" }}>
                    <Tooltip
                      title={detailsTabOnDetailsPage.closeZoomImage}
                      followCursor
                    >
                      <IconButton
                        onClick={() => {
                          setShowZoomModal(false);
                        }}
                        className="icon_background"
                        sx={{
                          padding: "0px",
                          background: "#fed",
                          border: "0.25px solid #6d8291",
                          color: "#6d8291",
                          boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)",
                          borderRadius: "4px",
                          padding: "1px",
                          cursor: "pointer",
                          "&:hover": {
                            backgroundColor: "#ffffff",
                          },
                        }}
                      >
                        <Close
                          sx={{ "&.MuiIconButton-root": { width: "22px" } }}
                        />
                      </IconButton>
                    </Tooltip>
                  </div>
                  <ZoomImage
                    image={
                      showPythonGraphName === "filtered_python_call_graph"
                        ? imageForFilteredPythonCallGraph
                        : imageForPythonCallGraph
                    }
                    id={"modalZoomBoxDiv"}
                  />
                </>
              </div>
            </Modal>
          )}
        </div>
      )}
    </>
  );
};

export default DetailList;
