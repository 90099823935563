import React, { Fragment } from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";

const borderColor = "#90e5fc";
const styles = StyleSheet.create({
  row: {
    flexDirection: "row",
    borderBottomColor: "#bff0fd",
    borderBottomWidth: 1,
    alignItems: "center",
    fontStyle: "bold",
    padding: "2px",
    overflowWrap: "anywhere",
  },
  section: {
    width: "10%",
    textAlign: "center",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    paddingLeft: 8,
  },

  question: {
    width: "40%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    // textAlign: 'right',
    paddingLeft: 8,
  },
  answer: {
    width: "50%",
    paddingLeft: 8,
  },
});

const showAnswer = (answerData) => {
  console.log("answerData", answerData);
  let fileAnswer = "";
  answerData.hasOwnProperty("file") &&
    answerData.file.map((fileData) => {
      fileAnswer = fileAnswer + fileData.file_name + ",";
    });

  return (
    <Text style={styles.answer}>
      {answerData.hasOwnProperty("boolean") && (
        <Text style={styles.answer}>{answerData.boolean}</Text>
      )}
      {answerData.hasOwnProperty("text") && (
        <Text style={styles.answer}>{answerData.text}</Text>
      )}
      {fileAnswer !== "" && <Text style={styles.answer}>{fileAnswer}</Text>}
    </Text>
  );
};

const PDFQuestionnaireTableRow = ({ dataForTable }) => {
  const rows = dataForTable.map((data, index) => {
    return (
      <View style={styles.row} key={`PDFQuestionnairetablerow:${index}`}>
        <Text style={styles.section}>{data.title}</Text>
        <Text style={styles.question}>{data.question}</Text>
        {data.answer === "No data available" ||
        data.answer === null ||
        data.answer === undefined ? (
          <Text style={styles.answer}>{"No data available"}</Text>
        ) : (
          <Text style={styles.answer}>{showAnswer(data.answer)}</Text>
        )}
      </View>
    );
  });

  return <Fragment>{rows}</Fragment>;
};

export default PDFQuestionnaireTableRow;
