import { Button, CircularProgress, Typography } from "@mui/material";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import "./NLPQueryresults.css";
import EntityCard from "./EntityCard";
import { isAccessProvided } from "../../services/assetManagementService";
import { useAtom } from "jotai";
import { accessDataByRoleAtom } from "../../atom/jotai";
import { listviewRowClick } from "../../services/listviewServices";
import { analyticServices } from "../../services/analyticServices";
import { useGetNlpQueryResults } from "../../api/readApiFunctions";
import NLPQqueryResultTable from "./NLPQqueryResultTable";
import NoResult from "../NoResult/NoResult";
import { deslugifyText } from "../../utils/basicUtils";
import { nlqQueryToasts } from "../../constants/toasterMsgConstants";

const NLPQueryresults = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const nlpQuery = location?.state?.originalSearch;

  const [nlpResponseData, setNlpResponseData] = useState(null);
  const [nlpResponseKeywords, setNlpResponseKeywords] = useState(null);

  const [accessDataByRole, setAccessDataByRole] = useAtom(accessDataByRoleAtom);

  const {
    data: nlpQueryResultsData,
    isSuccess: nlpQueryResultsIsSuccess,
    error: nlpQueryResultsError,
    isError: nlpQueryResultsIsError,
    isLoading: nlpQueryResultsIsLoading,
  } = useGetNlpQueryResults(
    nlpQuery === "*" ? "*" : nlpQuery,
    nlpQuery !== "" &&
      location.pathname.split("/")[1] == "nlp-query-result" &&
      location.pathname.split("/")[1] !== undefined
      ? true
      : false
  );

  //Success Case
  useEffect(() => {
    if (nlpQueryResultsIsSuccess) {
      console.log("Nlq Query Successfully run");
    }
  }, [nlpQueryResultsData]);

  //Error Case
  useEffect(() => {
    if (nlpQueryResultsIsError) {
      console.log("Nlq Query Error:", nlpQueryResultsError);
    }
  }, [nlpQueryResultsError]);

  // const handleOpenEntityDetails = (entityData) => {
  //   navigate(listviewRowClick(entityData));
  //   if (isAccessProvided("details", "crud", null, "read", accessDataByRole)) {
  //     analyticServices(
  //       `Selected Type : ${entityData?.__typename}/${entityData?.name} `
  //     );
  //     navigate(listviewRowClick(entityData));
  //   }
  // };

  useEffect(() => {
    if (nlpQueryResultsData) {
      if (nlpQueryResultsData?.hits?.hits) {
        setNlpResponseData(nlpQueryResultsData?.hits?.hits);
        setNlpResponseKeywords(nlpQueryResultsData?.keywords);
      }
    }
  }, [nlpQueryResultsData]);

  const handleBckToHome = () => {
    navigate("/");
  };

  return (
    <div style={{ position: "relative" }}>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Typography className="nlp-page-heading">Search Results for</Typography>
        <button
          className="nlp-back-to-home-btn"
          onClick={() => {
            handleBckToHome();
          }}
        >
          Back To Home
        </button>
      </div>
      <p className="nlp-query-text">{`${nlpQuery === "*" ? "*" : nlpQuery}`}</p>

      {/* <div id="nlp-query-results" className="nlp-query-results-div">
        {nlpResponseData &&
          data.map((displayInfo) => {
            return (
              <EntityCard
                displayCardInfo={displayInfo}
                handleOpenEntityDetails={handleOpenEntityDetails}
              />
            );
          })}
      </div> */}

      {nlpQueryResultsIsLoading ? (
        <CircularProgress />
      ) : nlpQueryResultsData && nlpQueryResultsData?.hits?.hits ? (
        nlpResponseData && nlpResponseData.length > 0 ? (
          <div id="nlp-query-results">
            <NLPQqueryResultTable nlpResponseData={nlpResponseData} />
          </div>
        ) : (
          <NoResult title={"No results to show"} />
        )
      ) : (
        <NoResult
          title={`${nlqQueryToasts.nlqQueryFailed}`}
          failedCase={"true"}
        />
      )}
    </div>
  );
};

export default NLPQueryresults;
