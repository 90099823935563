import React, { useState, useEffect } from "react";
import {
  KeyboardDoubleArrowLeft,
  KeyboardDoubleArrowRight,
} from "@mui/icons-material";
import { useAtom } from "jotai";
import {
  accessDataByRoleAtom,
  appIdForOpeningSummarViewAtom,
  authUser,
  businessUnitName,
  openCPOAnnotationsAtom,
  openSideBarAtom,
  organizationIDAtom,
  orgNameAtom,
  regionIDAtom,
  regionNameAtom,
  riskFactorCategoryNameAtom,
  selectedBusinessUnit,
} from "../../../atom/jotai";
import ConfidenceFactors from "../../../components/ComplianceComponents/ConfidenceFactors/ConfidenceFactors";
import Layout from "../../../layout/Layout";
import {
  getConfidenceFactors,
  useGetAllPolicies,
  useGetBusinessUnitsNew,
  useGetConfidenceFactors,
  useGetCPOListviewCF,
} from "../../../api/readApiFunctions";
import "./ListviewCompliancePage.css";
import ComplianceResultview from "../../../components/ComplianceComponents/complianceResultview/ComplianceResultview";
import { CircularProgress, IconButton } from "@mui/material";
import ComplianceAnnotation from "../../../components/ComplianceComponents/ComplianceAnnotation/ComplianceAnnotation";
import ListviewCompliance from "../../../components/ComplianceComponents/ListviewCompliance/ListviewCompliance";
import {
  complianceTagName,
  getType,
  getTypeID,
} from "../../../constants/complianceConstants";
import { useLocation } from "react-router-dom";
import AssetsListviewTable from "../../../components/Assets Listview/AssetsListviewTable";
import { isAccessProvided } from "../../../services/assetManagementService";
import NoBusinessUnitsAssignedModal from "../../../components/NoBusinessUnitsAssignedModal/NoBusinessUnitsAssignedModal";

const ListviewCompliancePage = () => {
  const [user] = useAtom(authUser);
  const [selectedBuId, setSelectedBusiness] = useAtom(selectedBusinessUnit);
  const [businessName, setBusinessUnitName] = useAtom(businessUnitName);
  // const [businessData, setBusinessData] = useState([]);
  // const [open, setOpen] = useState(true); //State for maintaing Annotations on compliance open or close
  const location = useLocation();
  const [, setOrgName] = useAtom(orgNameAtom);
  const [regionID, setRegionID] = useAtom(regionIDAtom);
  const [regionName, setRegionName] = useAtom(regionNameAtom);
  const [regionData, setRegionData] = useState([]);
  const [temp, setTemp] = useState(0);
  const [organizationID, setOrganizationID] = useAtom(organizationIDAtom);
  const [openCPOAnnotations, setOpenCPOAnnotations] = useAtom(
    openCPOAnnotationsAtom
  );
  //State used for maintianing the last position on highlighted words in listview find functionality
  const [prevHighlightedLOC, setprevHighlightedLOC] = useState([]);

  const [currentCount, setCurrentCount] = useState(0);
  const [findElement, setFindElement] = useState(null);
  //State for maintaing Annotations on compliance open or close
  const [openSideBar, setOpenSideBar] = useAtom(openSideBarAtom); //State for maintaing sidebar open or close
  const [appIdForOpeningSummarView, setAppIdForOpeningSummarView] = useAtom(
    appIdForOpeningSummarViewAtom
  );
  const [riskFactorCategoryName, setRiskFactorCategoryName] = useAtom(
    riskFactorCategoryNameAtom
  ); //State to maintain risk factor score type
  const [policyData, setPolicyData] = useState(); //state to maintain policies list coming from database
  const [accessDataByRole] = useAtom(accessDataByRoleAtom);

  //Getting breadcrums data (dropdown data)
  const {
    data: businessUnitData,
    isError,
    isLoading,
  } = useGetBusinessUnitsNew(
    user?.role,
    user?.contributor_id,
    location.pathname.split("/")[2] == "compliance-listview" ? true : false,
    "profile_view"
  );

  //Getting Policies
  const {
    data: allPolicy,
    error: allPolicyError,
    isLoading: allPolicyLoading,
  } = useGetAllPolicies(
    "profile_view",
    "crud",
    location.pathname.split("/")[2] == "compliance-listview" ? true : false
  );

  /**  Below useEffects is used to set policy data coming from database*/
  useEffect(() => {
    allPolicy && setPolicyData(allPolicy?.data?.data?.score_type_config);
    if (allPolicy) {
      const filteredRows = allPolicy?.data?.data?.score_type_config.filter(
        (row) => {
          return row.status.toUpperCase() === "PUBLISHED";
        }
      );
      setPolicyData(filteredRows);
    }
  }, [allPolicy]);

  // This useEffect is for setting policy  data
  // useEffect(() => {
  //   allPolicy && setPolicyData(allPolicy?.data?.data?.score_type_config);
  // }, [allPolicy]);

  // This useEffect is for setting region data
  useEffect(() => {
    if (businessUnitData && businessUnitData !== null) {
      let tempArrForRegion = [];
      let tempAppArr = [];
      businessUnitData?.data?.data?.regionData?.map((item, index) => {
        tempArrForRegion.push({
          id: item.regionID,
          name: item.regionName,
        });
        if (
          regionID === -1 &&
          item.regionName == complianceTagName.AllRegionsTag
        ) {
          setRegionName(item.regionName);
          setRegionID(item.regionID);
        }
      });
      setRegionData(tempArrForRegion);
      setOrgName(
        businessUnitData?.data?.data?.organizationDetail[0]?.organizationName
      );
      setOrganizationID(
        businessUnitData?.data?.data?.organizationDetail[0]?.organizationID
      );
    }
  }, [businessUnitData]);

  //This funstion is used for Annotations
  const handleCompAnnotations = () => {
    setOpenCPOAnnotations(!openCPOAnnotations);
    if (openSideBar === true) {
      setOpenSideBar(!openSideBar);
    }
  };

  // This code is for listview confidence factors
  const {
    data: listviewConFactors,
    isError: listviewConFactorsError,
    isLoading: listviewConFactorsLoading,
    refetch,
  } = useGetConfidenceFactors(
    getType(
      regionID,
      selectedBuId,
      appIdForOpeningSummarView,
      riskFactorCategoryName
    ),
    getTypeID(
      regionID,
      selectedBuId,
      appIdForOpeningSummarView,
      organizationID
    ),
    riskFactorCategoryName,
    businessUnitData && location.pathname.split("/")[2] == "compliance-listview"
      ? true
      : false
  );

  //Below useEffect is used for giving title to current page
  useEffect(() => {
    document.title = "Konfer Compliance Result Page";
  });

  return (
    <>
      <div
        id="complianceViewWrapper"
        className="compliance-listview-wrapper"
        style={openCPOAnnotations ? {} : { gridTemplateColumns: "1fr 50px" }}
      >
        <div style={{ overflowX: "auto" }}>
          {" "}
          {businessUnitData && businessUnitData !== null && (
            <>
              <ComplianceResultview
                // businessData={businessData}
                businessUnitData={businessUnitData}
                // round of and coverting 2 digit
                count={
                  listviewConFactors && listviewConFactors?.avgSummary?.value
                }
                regionData={regionData}
                setprevHighlightedLOC={setprevHighlightedLOC}
                setCurrentCount={setCurrentCount}
                setFindElement={setFindElement}
              />
              {listviewConFactorsLoading ? (
                <CircularProgress style={{ marginLeft: "20px" }} />
              ) : (
                listviewConFactors &&
                listviewConFactors?.confidence_factor?.length > 0 && (
                  <ConfidenceFactors
                    listviewConFactorsData={listviewConFactors}
                    riskFactorCategoryName={riskFactorCategoryName}
                    setRiskFactorCategoryName={setRiskFactorCategoryName}
                    policyData={policyData}
                  />
                )
              )}
              {/* <ListviewCompliance
                prevHighlightedLOC={prevHighlightedLOC}
                setprevHighlightedLOC={setprevHighlightedLOC}
                findElement={findElement}
                setFindElement={setFindElement}
                currentCount={currentCount}
                setCurrentCount={setCurrentCount}
              /> */}
              <div style={{ margin: "15px" }}>
                <AssetsListviewTable
                  page="profile"
                  policyData={policyData}
                  listviewConFactorsRefetch={refetch}
                />
              </div>
            </>
          )}
        </div>
        {isAccessProvided(
          "annotation",
          "profile",
          null,
          "read",
          accessDataByRole
        ) && (
          <div className="compliance-annoatation-box">
            <IconButton
              onClick={handleCompAnnotations}
              style={{ padding: "0px" }}
            >
              {openCPOAnnotations ? (
                <KeyboardDoubleArrowRight />
              ) : (
                <KeyboardDoubleArrowLeft />
              )}
            </IconButton>
            {openCPOAnnotations && <ComplianceAnnotation />}
          </div>
        )}
      </div>
      {/**Below Code is for when no bussiness unit is attached to the logged in user */}
      <NoBusinessUnitsAssignedModal
        accessDataByRole={accessDataByRole}
        moduleInvoker={"profile_view"}
      />
    </>
  );
};

export default ListviewCompliancePage;
