import React from "react";
import { Popover, Typography } from "@mui/material";
import { CancelPresentation, Close } from "@mui/icons-material";

const ListPopover = ({
  openRegionListPopover,
  setOpenRegionListPopover,
  openBusinessunitListPopover,
  setOpenBusinessunitListPopover,
  displayData,
  isRegion,
  isRegionFromExtraction,
}) => {
  return (
    <Popover
      sx={{
        "& .MuiPopover-paper": {
          boxShadow: "0px 5px 10px rgba(0, 0, 0, 0.25)",
          borderRadius: "4px",
          border: "0.5px solid #6D8291 ",
          width: "180px",
          paddingBottom: "5px",
        },
      }}
      //  id={assignedBusinessunitAppIDForList}
      open={
        isRegion || isRegionFromExtraction
          ? openRegionListPopover
          : openBusinessunitListPopover
      }
      anchorEl={
        isRegion || isRegionFromExtraction
          ? openRegionListPopover
          : openBusinessunitListPopover
      }
      onClose={() => {
        isRegion || isRegionFromExtraction
          ? setOpenRegionListPopover(false)
          : setOpenBusinessunitListPopover(false);
      }}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
    >
      <Close
        id="popOverCancelDiv"
        sx={{ marginLeft: "156px" }}
        fontSize="small"
        onClick={() => {
          isRegion || isRegionFromExtraction
            ? setOpenRegionListPopover(false)
            : setOpenBusinessunitListPopover(false);
        }}
      />

      {displayData?.map((data, index) => {
        return (
          <Typography
            sx={{
              margin: "5px 0px 10px 20px",
              fontFamily: "Lato",
              fontWeight: 400,
              fontSize: "20px",
              color: "#666666",
            }}
            key={index}
            id={`ListPopover${index}`}
          >
            {" "}
            {isRegionFromExtraction
              ? data
              : isRegion
              ? data?.region.name
              : data?.business_unit?.name}
          </Typography>
        );
      })}
    </Popover>
  );
};

export default ListPopover;
