import {
  Box,
  Drawer as MuiDrawer,
  Icon,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Tooltip,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { styled, useTheme } from "@mui/material/styles";
import {
  KeyboardDoubleArrowLeft,
  KeyboardDoubleArrowRight,
} from "@mui/icons-material";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
// custom imports
import AssetIcon from "../../assets/images/sidebar/AssetIcon.svg";
import ProfileIcon from "../../assets/images/sidebar/ProfileIcon.svg";
import feedBackIcon from "../../assets/images/sidebar/feedBackIcon.svg";

import HomeIcon from "../../assets/images/sidebar/HomeIcon.svg";
import ReleaseInfoIcon from "../../assets/images/sidebar/ReleaseInfoIcon.svg";

import UsersIcon from "../../assets/images/adminPanel/UsersIcon.svg";
import discoveryIcon from "../../assets/images/icons/discovery-icon.svg";

import DetailsSettingsIcon from "../../assets/images/adminPanel/DetailsSettingsIcon.svg";
import RiskFactorsIcon from "../../assets/images/adminPanel/RiskFactorsIcon.svg";
import BusinessUnitsIcon from "../../assets/images/adminPanel/BusinessUnits.svg";
import adminRegionIcon from "../../assets/images/adminPanel/adminRegionIcon.svg";
import AssignBUIcon from "../../assets/images/adminPanel/AssignBUIcon.svg";
import CardTemplate from "../../assets/images/adminPanel/cardTemplate.svg";
import RoleManageIcon from "../../assets/images/adminPanel/Rectangle.svg";
import PolicyIcon from "../../assets/images/adminPanel/Policy.svg";

import "./Sidebar.css";
import {
  authUser,
  businessUnits,
  selectedBusinessUnit,
  businessUnitName,
  isSidebar,
  isAdmin,
  isAdminRiskFactorUpdate,
  isComplianceViewAtom,
  regionIDAtom,
  appsNameDataComp,
  appIdForOpeningSummarViewAtom,
  regionNameAtom,
  feedBackCard,
  azureExtractionInProgress,
  isSwitchToAdminPanelAtom,
  accessDataByRoleAtom,
} from "../../atom/jotai";
import { useAtom } from "jotai";
import { analyticServices } from "../../services/analyticServices";
import { toast } from "react-toastify";
import Feedback from "../../components/Feedback/Feedback";
import { userRole } from "../../constants/dataTypes";
import { isAccessProvided } from "../../services/assetManagementService";
import {
  actionConstants,
  moduleConstants,
  subModuleConstants,
} from "../../constants/accessManagementConstants";
import { PolicyNameConst } from "../../constants/policyConstants";

const Sidebar = ({ open, handleDrawerOpen, handleDrawerClose }) => {
  const theme = useTheme();
  const searchText = localStorage.getItem("userSearchText");
  const [user] = useAtom(authUser);
  const [isFeedBack, setIsFeedBack] = useState(false);

  const [, setSidebar] = useAtom(isSidebar);
  const location = useLocation();
  const [admin, setAdmin] = useAtom(isAdmin);
  const [riskFactorUpdate, setRiskFactorUpdate] = useAtom(
    isAdminRiskFactorUpdate
  );
  const [isSwitchToAdminPanel, setIsSwitchToAdminPanel] = useAtom(
    isSwitchToAdminPanelAtom
  );
  const [isComplianceView, setIsComplianceView] = useAtom(isComplianceViewAtom);

  const [businessData, setBusinessData] = useAtom(businessUnits);
  const [selectedBuId, setSelectedBusiness] = useAtom(selectedBusinessUnit);
  const [businessName, setBusinessUnitName] = useAtom(businessUnitName);
  const [regionName, setRegionName] = useAtom(regionNameAtom);
  const [isFeedback, setIsFeedback] = useAtom(feedBackCard);
  const [azureExtractInprogress, setAzureExtractInprogress] = useAtom(
    azureExtractionInProgress
  );

  const [appIdForOpeningSummarView, setAppIdForOpeningSummarView] = useAtom(
    appIdForOpeningSummarViewAtom
  );
  const [appsNameData, setAppsNameData] = useAtom(appsNameDataComp);

  const [regionID, setRegionID] = useAtom(regionIDAtom);

  //state for managing access management system
  const [accessDataByRole] = useAtom(accessDataByRoleAtom);

  const drawerWidth = 215;

  const DrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 0),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  }));

  // the  function used for highlight background of navlink used fron router-6
  // we have to give input as isActive
  const navbarStyle = ({ isActive }) =>
    isActive ? "sidebarActiveLink" : "sidebarTab";

  const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: "hidden",
  });

  const closedMixin = (theme) => ({
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: `calc(${theme.spacing(6)} + 1px)`,
    [theme.breakpoints.up("sm")]: {
      width: `calc(${theme.spacing(6)} + 1px)`,
    },
  });

  const Drawer = styled(MuiDrawer, {
    shouldForwardProp: (prop) => prop !== "open",
  })(({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    boxSizing: "border-box",
    ...(open && {
      ...openedMixin(theme),
      "& .MuiDrawer-paper": openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      "& .MuiDrawer-paper": closedMixin(theme),
    }),
  }));
  setSidebar(open);

  const adminAnalyticServices = (adminSection) => {
    riskFactorUpdate && toast.warning("No changes made in Risk Factors");
    analyticServices(`Admin ${adminSection} section view `);
    setRiskFactorUpdate(false);
  };

  return (
    <Box
      id="sidebarBox"
      sx={{
        zIndex: 1,
      }}
    >
      <Drawer
        variant="permanent"
        open={open}
        position="fixed"
        id="sidebarDrawer"
      >
        <DrawerHeader>
          <IconButton
            onClick={handleDrawerClose}
            id="sidebarExpandIconButton"
            style={{ padding: "0px", marginTop: "-40px" }}
          >
            {open ? <KeyboardDoubleArrowLeft /> : <KeyboardDoubleArrowRight />}
          </IconButton>
        </DrawerHeader>

        {(location.pathname.split("/")[1] !== "admin" &&
          user?.role == "SuperAdmin") ||
        location.pathname.split("/")[1] !== "admin" ||
        user?.role == "DS" ? (
          //   ||
          // (location.pathname.split("/")[1] !== "admin" && user?.role == "SuperAdmin")

          <List id="sidebarList">
            {isAccessProvided(
              "asset_view",
              "crud",
              null,
              "read",
              accessDataByRole
            ) && (
              <ListItem
                component="div"
                sx={{ padding: "0px", marginLeft: "-2px" }}
              >
                <NavLink
                  to={"/"}
                  state={{
                    // resultViewPath
                    regionName: regionName,
                    regionID: regionID,
                    businessName: businessName,
                    selectedBuId: selectedBuId,
                    businessData: businessData,
                    appsNameData: appsNameData,
                    appIdForOpeningSummarView: appIdForOpeningSummarView,
                    route:
                      location.pathname.split("/")[2] == "compliance-summary"
                        ? true
                        : null,
                  }}
                  className={navbarStyle}
                  id="sidebarHomeLink"
                >
                  <ListItem button>
                    <Tooltip title={!open ? "Home" : ""} placement="bottom">
                      <ListItemIcon>
                        {/* <HomeOutlined /> */}
                        <img
                          src={HomeIcon}
                          alt=""
                          style={{
                            width: "20px",
                            height: "20px",
                            marginLeft: "2px",
                          }}
                        />
                      </ListItemIcon>
                    </Tooltip>
                    <ListItemText>
                      <span className="listitem_text">Home</span>{" "}
                    </ListItemText>
                  </ListItem>
                </NavLink>
              </ListItem>
            )}

            {accessDataByRole &&
              isAccessProvided(
                moduleConstants.asset_view,
                subModuleConstants.crud,
                null,
                "read",
                accessDataByRole
              ) && (
                <ListItem
                  sx={{ padding: "0px" }}
                  id="sidebarAdminConfigurations"
                >
                  <NavLink
                    to={
                      searchText === null
                        ? `/result/listview/konfer-confidence`
                        : `/result/listview/${searchText}`
                    }
                    state={{
                      // resultViewPath
                      regionName: regionName,
                      regionID: regionID,
                      businessName: businessName,
                      selectedBuId: selectedBuId,
                      businessData: businessData,
                      appsNameData: appsNameData,
                      appIdForOpeningSummarView: appIdForOpeningSummarView,
                    }}
                    className="sidebarTab"
                    style={
                      location.pathname.split("/")[2] == "listview"
                        ? {
                            background: "#D0DCE2",
                            borderLeft: "3px solid #6D8291",
                          }
                        : {}
                    }
                    id="sidebarHomeLink"
                    onClick={() => {
                      setRegionID(-1);
                      setSelectedBusiness([]);
                    }}
                  >
                    <ListItem button id="sidebarAssets">
                      <Tooltip title={!open ? "Assets" : ""} placement="bottom">
                        <ListItemIcon>
                          <img
                            src={AssetIcon}
                            alt=""
                            className="konferScoreIcon"
                          />
                        </ListItemIcon>
                      </Tooltip>
                      <ListItemText>
                        <span className="listitem_text">Assets</span>{" "}
                      </ListItemText>
                    </ListItem>
                  </NavLink>
                </ListItem>
              )}

            {accessDataByRole &&
              isAccessProvided(
                moduleConstants.profile_view,
                subModuleConstants.crud,
                null,
                "read",
                accessDataByRole
              ) && (
                <ListItem
                  sx={{ padding: "0px" }}
                  id="sidebarAdminConfigurations"
                >
                  <NavLink
                    to={`/compliance-result/compliance-listview/konfer-confidence`}
                    state={{
                      // resultViewPath

                      regionName: regionName,
                      regionID: regionID,
                      businessName: businessName,
                      selectedBuId: selectedBuId,
                      businessData: businessData,
                      appsNameData: appsNameData,
                      appIdForOpeningSummarView: appIdForOpeningSummarView,
                    }}
                    className="sidebarTab"
                    style={
                      location.pathname.split("/")[2] == "compliance-listview"
                        ? {
                            background: "#D0DCE2",
                            borderLeft: "3px solid #6D8291",
                          }
                        : {}
                    }
                    id="sidebarHomeLink"
                  >
                    <ListItem button id="sidebarProfile">
                      <Tooltip
                        title={!open ? "Profile" : ""}
                        placement="bottom"
                      >
                        <ListItemIcon>
                          <img
                            src={ProfileIcon}
                            alt=""
                            className="konferScoreIcon"
                          />
                        </ListItemIcon>
                      </Tooltip>
                      <ListItemText>
                        <span className="listitem_text">Profile</span>{" "}
                      </ListItemText>
                    </ListItem>
                  </NavLink>
                </ListItem>
              )}

            {accessDataByRole &&
              isAccessProvided(
                moduleConstants.extraction,
                subModuleConstants.crud,
                null,
                "read",
                accessDataByRole
              ) && (
                <>
                  <ListItem
                    sx={{ padding: "0px" }}
                    id="sidebarAdminConfigurations"
                  >
                    <NavLink
                      id="sidebarAdminConfigurationsLink"
                      to={!azureExtractInprogress && "/discovery"}
                      className={
                        location.pathname.split("/")[1] === "aws"
                          ? navbarStyle
                          : "sidebarTab"
                      }
                      style={
                        location.pathname.split("/")[1] == "discovery"
                          ? {
                              background: "#D0DCE2",
                              borderLeft: "3px solid #6D8291",
                            }
                          : {}
                      }
                    >
                      <ListItem button>
                        <Tooltip
                          title={!open ? "Discovery" : ""}
                          placement="bottom"
                        >
                          <ListItemIcon>
                            <img
                              src={discoveryIcon}
                              alt="Discovery"
                              width="20px"
                            />
                          </ListItemIcon>
                        </Tooltip>
                        <ListItemText>
                          <span className="listitem_text">Discovery</span>{" "}
                        </ListItemText>
                      </ListItem>
                    </NavLink>
                  </ListItem>
                </>
              )}

            <ListItem sx={{ padding: "0px" }} id="Release_Info">
              <NavLink
                to="/whats-new"
                className={navbarStyle}
                id="sidebarReleaseLink"
              >
                <ListItem button>
                  <Tooltip
                    title={!open ? "What's New in this Release" : ""}
                    placement="bottom"
                  >
                    <ListItemIcon>
                      <img
                        src={ReleaseInfoIcon}
                        alt=""
                        style={{
                          width: "22px",
                          height: "22px",
                          marginLeft: "1px",
                        }}
                      />
                    </ListItemIcon>
                  </Tooltip>
                  <ListItemText>
                    <span className="listitem_text">Release Info</span>{" "}
                  </ListItemText>
                </ListItem>
              </NavLink>
            </ListItem>

            {/* <ListItem id="sidebarReports">
              <Tooltip title={!open ? "Reports" : ""} placement="bottom">
                <ListItemIcon></ListItemIcon>
              </Tooltip>
              <ListItemText>
                <span className="listitem_text">Reports</span>{" "}
              </ListItemText>
            </ListItem>

            <ListItem id="sidebarSettings">
              <Tooltip title={!open ? "Settings" : ""} placement="bottom">
                <ListItemIcon></ListItemIcon>
              </Tooltip>
              <ListItemText>
                <span className="listitem_text">Settings</span>{" "}
              </ListItemText>
            </ListItem> */}
            {/* --------------------- */}
            {isAccessProvided(
              "feedback",
              "crud",
              null,
              "read",
              accessDataByRole
            ) && (
              <Box
                style={{
                  marginTop:
                    user?.role?.toLowerCase() === userRole.ds ||
                    user?.role?.toLowerCase() === userRole.superAdmin ||
                    user?.role?.toLowerCase() === userRole.admin
                      ? // ||
                        // user?.role === "admin"
                        "-150px"
                      : "",
                }}
              >
                <ListItem
                  className="feedbackTab_switchToApp"
                  onClick={() => setIsFeedback(true)}
                >
                  {/* <ListItem button onClick={() => setIsFeedback(true)}> */}
                  <Feedback />
                  <Tooltip title={!open ? "Feedback" : ""} placement="bottom">
                    <ListItemIcon>
                      <img
                        src={feedBackIcon}
                        alt=""
                        style={{
                          width: "22px",
                          height: "22px",
                          marginLeft: "1px",
                        }}
                      />
                    </ListItemIcon>
                  </Tooltip>
                  <ListItemText>
                    <span className="listitem_text">Feedback</span>{" "}
                  </ListItemText>
                </ListItem>
              </Box>
            )}
          </List>
        ) : (
          !isSwitchToAdminPanel && (
            <List>
              {accessDataByRole &&
                isAccessProvided(
                  moduleConstants.admin_view,
                  subModuleConstants.users,
                  null,
                  "read",
                  accessDataByRole
                ) && (
                  <ListItem sx={{ padding: "0px" }} id="sidebarAdminUserList">
                    <NavLink
                      id="sidebarAdminUserListLink"
                      to={"/admin/userlist"}
                      className={navbarStyle}
                      onClick={() => adminAnalyticServices("Users")}
                    >
                      <ListItem button>
                        <Tooltip
                          title={!open ? "Users" : ""}
                          placement="bottom"
                        >
                          <ListItemIcon>
                            <img src={UsersIcon} alt="Users" />
                          </ListItemIcon>
                        </Tooltip>
                        <ListItemText>
                          <span className="listitem_text">Users</span>{" "}
                        </ListItemText>
                      </ListItem>
                    </NavLink>
                  </ListItem>
                )}

              {accessDataByRole &&
                isAccessProvided(
                  moduleConstants.admin_view,
                  subModuleConstants.regions,
                  null,
                  "read",
                  accessDataByRole
                ) && (
                  <ListItem
                    sx={{ padding: "0px", marginLeft: "3px" }}
                    id="sidebarAdminBusinessunit"
                  >
                    <NavLink
                      id="sidebarAdminRegions"
                      to={"/admin/regions"}
                      className={navbarStyle}
                      onClick={() => adminAnalyticServices("Regions")}
                    >
                      <ListItem button>
                        <Tooltip
                          title={!open ? "Regions" : ""}
                          placement="bottom"
                        >
                          <ListItemIcon>
                            <img
                              src={adminRegionIcon}
                              alt="Regions"
                              width="18px"
                            />
                          </ListItemIcon>
                        </Tooltip>
                        <ListItemText>
                          <span className="listitem_text">Regions</span>{" "}
                        </ListItemText>
                      </ListItem>
                    </NavLink>
                  </ListItem>
                )}

              {accessDataByRole &&
                isAccessProvided(
                  moduleConstants.admin_view,
                  subModuleConstants.business_units,
                  null,
                  "read",
                  accessDataByRole
                ) && (
                  <ListItem
                    sx={{ padding: "0px" }}
                    id="sidebarAdminBusinessunit"
                  >
                    <NavLink
                      id="sidebarAdminBusinessunitLink"
                      to={"/admin/businessunit"}
                      className={navbarStyle}
                      onClick={() => adminAnalyticServices("Business Units")}
                    >
                      <ListItem button>
                        <Tooltip
                          title={!open ? "Business Units" : ""}
                          placement="bottom"
                        >
                          <ListItemIcon>
                            <img
                              src={BusinessUnitsIcon}
                              alt="Business Units"
                              width="22px"
                            />
                          </ListItemIcon>
                        </Tooltip>
                        <ListItemText>
                          <span className="listitem_text">Business Units</span>{" "}
                        </ListItemText>
                      </ListItem>
                    </NavLink>
                  </ListItem>
                )}

              {accessDataByRole &&
                isAccessProvided(
                  moduleConstants.policy,
                  subModuleConstants.policy_view,
                  null,
                  "read",
                  accessDataByRole
                ) && (
                  <ListItem sx={{ padding: "0px" }} id="sidebarAdminPolicy">
                    <NavLink
                      id="sidebarAdminPolicyLink"
                      to={"/admin/policy"}
                      className="sidebarTab"
                      style={
                        location.pathname.split("/")[2] == "policy"
                          ? {
                              background: "#D0DCE2",
                              borderLeft: "3px solid #6D8291",
                            }
                          : {}
                      }
                      onClick={() => adminAnalyticServices("Policy")}
                    >
                      <ListItem button>
                        <Tooltip
                          title={!open ? `${PolicyNameConst.policies}` : ""}
                          placement="bottom"
                        >
                          <ListItemIcon>
                            <img src={PolicyIcon} alt="Policy" width="18px" />
                          </ListItemIcon>
                        </Tooltip>
                        <ListItemText>
                          <span className="listitem_text">
                            {PolicyNameConst.policies}
                          </span>{" "}
                        </ListItemText>
                      </ListItem>
                    </NavLink>
                  </ListItem>
                )}
              {accessDataByRole &&
                isAccessProvided(
                  moduleConstants.admin_view,
                  subModuleConstants.asset_metadata,
                  actionConstants.modify,
                  "execution",
                  accessDataByRole
                ) && (
                  <ListItem
                    sx={{ padding: "0px" }}
                    id="sidebarAdminDetailsSettings"
                  >
                    <NavLink
                      id="sidebarAdminDetailsSettingsLink"
                      to={"/admin/details"}
                      className={navbarStyle}
                      onClick={() => adminAnalyticServices("Details Settings")}
                    >
                      <ListItem button>
                        <Tooltip
                          title={!open ? "Details Settings" : ""}
                          placement="bottom"
                        >
                          <ListItemIcon>
                            <img
                              src={DetailsSettingsIcon}
                              alt="Details Settings"
                            />
                          </ListItemIcon>
                        </Tooltip>
                        <ListItemText>
                          <span className="listitem_text">
                            Details Settings
                          </span>{" "}
                        </ListItemText>
                      </ListItem>
                    </NavLink>
                  </ListItem>
                )}

              {accessDataByRole &&
                isAccessProvided(
                  moduleConstants.admin_view,
                  subModuleConstants.risk_factors,
                  actionConstants.visibility,
                  "execution",
                  accessDataByRole
                ) && (
                  <ListItem
                    sx={{ padding: "0px" }}
                    id="sidebarAdminRiskFactors"
                  >
                    <NavLink
                      id="sidebarAdminRiskFactorsLink"
                      to={"/admin/riskfactors"}
                      className={navbarStyle}
                      onClick={() => adminAnalyticServices("Risk Factors")}
                    >
                      <ListItem button>
                        <Tooltip
                          title={!open ? "Risk Factors" : ""}
                          placement="bottom"
                        >
                          <ListItemIcon>
                            <img src={RiskFactorsIcon} alt="Risk Factors" />
                          </ListItemIcon>
                        </Tooltip>
                        <ListItemText>
                          <span className="listitem_text">Risk Factors</span>{" "}
                        </ListItemText>
                      </ListItem>
                    </NavLink>
                  </ListItem>
                )}

              {accessDataByRole &&
                isAccessProvided(
                  moduleConstants.admin_view,
                  subModuleConstants.business_units_assignment,
                  null,
                  "read",
                  accessDataByRole
                ) && (
                  <ListItem sx={{ padding: "0px" }}>
                    <NavLink
                      to={"/admin/node-businessunit-relationship"}
                      className={navbarStyle}
                      onClick={() => adminAnalyticServices("BU Assignment")}
                    >
                      <ListItem button>
                        <Tooltip
                          title={!open ? "BU Assignment" : ""}
                          placement="bottom"
                        >
                          <ListItemIcon>
                            <img
                              src={AssignBUIcon}
                              alt="BU Assignment"
                              width="22px"
                            />
                          </ListItemIcon>
                        </Tooltip>
                        <ListItemText>
                          <span className="listitem_text">BU Assignment</span>{" "}
                        </ListItemText>
                      </ListItem>
                    </NavLink>
                  </ListItem>
                )}

              {accessDataByRole &&
                isAccessProvided(
                  moduleConstants.card_templates,
                  subModuleConstants.crud,
                  null,
                  "read",
                  accessDataByRole
                ) && (
                  <ListItem sx={{ padding: "0px" }}>
                    <NavLink
                      to={"/admin/cardTemplate"}
                      className={navbarStyle}
                      onClick={() => adminAnalyticServices("Card Templates")}
                    >
                      <ListItem button>
                        <Tooltip
                          title={!open ? "Card Templates" : ""}
                          placement="bottom"
                        >
                          <ListItemIcon>
                            <img src={CardTemplate} alt="Card" width="20px" />
                          </ListItemIcon>
                        </Tooltip>
                        <ListItemText>
                          <span className="listitem_text">Card Templates</span>{" "}
                        </ListItemText>
                      </ListItem>
                    </NavLink>
                  </ListItem>
                )}

              {accessDataByRole &&
                isAccessProvided(
                  moduleConstants.admin_view,
                  subModuleConstants.role_management,
                  null,
                  "read",
                  accessDataByRole
                ) && (
                  <ListItem sx={{ padding: "0px" }}>
                    <NavLink
                      to={"/admin/roleManagement"}
                      className={navbarStyle}
                      onClick={() => adminAnalyticServices("Role Management")}
                    >
                      <ListItem button>
                        <Tooltip
                          title={!open ? "Role Management" : ""}
                          placement="bottom"
                        >
                          <ListItemIcon>
                            <img
                              src={RoleManageIcon}
                              alt="Role Management"
                              width="22px"
                            />
                          </ListItemIcon>
                        </Tooltip>
                        <ListItemText>
                          <span className="listitem_text">Role Management</span>{" "}
                        </ListItemText>
                      </ListItem>
                    </NavLink>
                  </ListItem>
                )}

              {accessDataByRole &&
                isAccessProvided(
                  moduleConstants.feedback,
                  subModuleConstants.crud,
                  null,
                  "read",
                  accessDataByRole
                ) && (
                  <ListItem sx={{ padding: "0px" }} className="feedbackTab">
                    <div
                      className="sidebarTab "
                      onClick={() => setIsFeedBack(true)}
                    >
                      <Feedback />
                      <ListItem button>
                        <Tooltip
                          title={!open ? "Feedback" : ""}
                          placement="bottom"
                        >
                          <ListItemIcon>
                            <img
                              src={feedBackIcon}
                              alt="BU Assignment"
                              width="22px"
                            />
                          </ListItemIcon>
                        </Tooltip>
                        <ListItemText>
                          <span className="listitem_text">Feedback</span>{" "}
                        </ListItemText>
                      </ListItem>
                    </div>
                  </ListItem>
                )}
            </List>
          )
        )}
      </Drawer>
    </Box>
  );
};

export default Sidebar;
