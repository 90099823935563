import React, { useState } from "react";
import { useAtom } from "jotai";
import AdminUsetlistSearch from "../../components/AdminUsetlistSearch/AdminUsetlistSearch";
import AdminUsetlistUserTable from "../../components/AdminUsetlistUserTable/AdminUsetlistUserTable";
import Layout from "../../layout/Layout";
import "./AdminUserlistPage.css";
import { useGetUserlist } from "../../api/readApiFunctions";
import { useEffect } from "react";
import AdminNewUserDialog from "../../components/AdminNewUserDialog/AdminNewUserDialog";
import NoResult from "../../components/NoResult/NoResult";
import { useLocation } from "react-router-dom";
import { accessDataByRoleAtom } from "../../atom/jotai";
import { isAccessProvided } from "../../services/assetManagementService";
import {
  moduleConstants,
  subModuleConstants,
} from "../../constants/accessManagementConstants";

const AdminUserlistPage = () => {
  //state for managing access management system golbal value
  const [accessDataByRole, SetAccessDataByRole] = useAtom(accessDataByRoleAtom);

  const location = useLocation();
  // get user list
  const {
    data: userlist,
    error: userlistError,
    isLoading: userlistLoading,
    refetch: userlistRefetch,
  } = useGetUserlist(
    "admin_view",
    "users",
    location.pathname.split("/")[2] == "userlist" &&
      location.pathname.split("/")[2] !== undefined &&
      accessDataByRole &&
      isAccessProvided(
        moduleConstants.admin_view,
        subModuleConstants.users,
        null,
        "read",
        accessDataByRole
      )
      ? true
      : false
  );

  const [userlistData, setUserlistData] = useState([]); //state to maintain all users list coming from database
  const [openNewUserDialog, setOpenNewUserDialog] = useState(false); // to show a create new user dialog

  useEffect(() => {
    setUserlistData(userlist);
  }, [userlist]);

  //Below useEffect is used for giving title to current page
  useEffect(() => {
    document.title = "Admin Userlist Page";
  });

  return (
    <>
      {/* here First I will call API for User DAta and business unit */}
      <div className="userList-heading1" id="adminUserListHeading">
        Users
      </div>
      {isAccessProvided(
        moduleConstants.admin_view,
        subModuleConstants.users,
        null,
        "read",
        accessDataByRole
      ) === true ? (
        <div className="userlist" id="adminUserlist">
          {/* <div className='userlist-heading paddingTRL30px'>
                User List
            </div> */}
          <div
            className="userlist-search-and-create paddingTRL30px"
            id="adminUserlistSearch"
          >
            <AdminUsetlistSearch
              userlist={userlist}
              userlistData={userlistData}
              setUserlistData={setUserlistData}
            />
            {isAccessProvided(
              moduleConstants.admin_view,
              subModuleConstants.users,
              null,
              "write",
              accessDataByRole
            ) === true && (
              <button
                id="adminUserlistCreate"
                className="userlist-createNewUser-button"
                onClick={() => {
                  setOpenNewUserDialog(true);
                }}
              >
                Create New User
              </button>
            )}
          </div>
          {userlistData && userlistData?.length === 0 ? (
            <div
              className="hgt userlist-users-table"
              id="adminUserlistNoResult"
            >
              <NoResult title={"No Data Available"} />
            </div>
          ) : (
            <div className="userlist-users-table" id="adminUserlistTable">
              <AdminUsetlistUserTable
                userlistData={userlistData}
                accessDataByRole={accessDataByRole}
                userlistRefetch={userlistRefetch}
              />
            </div>
          )}
        </div>
      ) : (
        <div className="hgt userlist-users-table" id="noAccessToRoleManagment">
          {accessDataByRole && <NoResult title={"No Access to Users"} />}
        </div>
      )}

      {openNewUserDialog && (
        <AdminNewUserDialog
          openNewUserDialog={openNewUserDialog}
          setOpenNewUserDialog={setOpenNewUserDialog}
          edit={false}
          userlistRefetch={userlistRefetch}
        />
      )}
    </>
  );
};

export default AdminUserlistPage;
