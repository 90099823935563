import React, { useState, useEffect } from "react";
import { useAtom } from "jotai";
import { useLocation } from "react-router-dom";
import { useGetRoleRegionBuData } from "../../api/readApiFunctions";
import AdminUsetlistSearch from "../../components/AdminUsetlistSearch/AdminUsetlistSearch";
import Layout from "../../layout/Layout";
import NoResult from "../../components/NoResult/NoResult";
import CreateNewBusinessunit from "../../components/AdminBusinessunit/CreateNewBusinessUnit/CreateNewBusinessunit";
import BusinessunitTable from "../../components/AdminBusinessunit/BusinessunitTable/BusinessunitTable";
import RegionTable from "../../components/AdminRegion/RegionTable/RegionTable";
import CreateNewRegion from "../../components/AdminRegion/CreateNewRegion/CreateNewRegion";
import { useCreateRegion } from "../../api/createApiFunctions";
import { useUpdateRegion } from "../../api/updateApiFunctions";
import { useDeleteRegion } from "../../api/deleteApiFunctions";
import { accessDataByRoleAtom } from "../../atom/jotai";
import {
  moduleConstants,
  subModuleConstants,
} from "../../constants/accessManagementConstants";
import { isAccessProvided } from "../../services/assetManagementService";

const AdminRegionsPage = () => {
  const location = useLocation();
  //state for managing access management system golbal value
  const [accessDataByRole] = useAtom(accessDataByRoleAtom);
  //get all regions
  const {
    data: allRegionData,
    error: allRegionDataError,
    isLoading: allRegionDataLoading,
  } = useGetRoleRegionBuData(
    "region",
    "regions",
    location.pathname.split("/")[2] == "regions" &&
      location.pathname.split("/")[2] !== undefined &&
      accessDataByRole &&
      isAccessProvided(
        moduleConstants.admin_view,
        subModuleConstants.regions,
        null,
        "read",
        accessDataByRole
      )
      ? true
      : false
  );

  const { mutate: addRegion } = useCreateRegion();
  const { mutate: updateRegion } = useUpdateRegion();
  const { mutate: deleteRegion } = useDeleteRegion();

  const [regionData, setRegionData] = useState([]); //state to maintain all users list coming from database
  const [openNewBusinessunitDialog, setOpenNewBusinessunitDialog] =
    useState(false); // to show a create new businessunit dialog
  const [createRegion, setCreateRegion] = useState();
  const [updateAdminRegion, setUpdateAdminRegion] = useState();
  const [deleteAdminRegion, setDeleteAdminRegion] = useState();

  useEffect(() => {
    if (createRegion) {
      addRegion(createRegion);
    }
  }, [createRegion]);

  useEffect(() => {
    if (updateAdminRegion) {
      updateRegion(updateAdminRegion);
    }
  }, [updateAdminRegion]);

  useEffect(() => {
    setRegionData(allRegionData);
  }, [allRegionData]);

  useEffect(() => {
    if (deleteAdminRegion) {
      deleteRegion(deleteAdminRegion);
    }
  }, [deleteAdminRegion]);

  //Below useEffect is used for giving title to current page
  useEffect(() => {
    document.title = "Admin Regions Page";
  });

  return (
    <>
      <div className="userList-heading1" id="businessunitHeading">
        Regions
      </div>
      {isAccessProvided(
        moduleConstants.admin_view,
        subModuleConstants.regions,
        null,
        "read",
        accessDataByRole
      ) === true ? (
        <div className="userlist" id="businessunit">
          <div
            className="userlist-search-and-create paddingTRL30px"
            id="businessunitSearch"
          >
            <AdminUsetlistSearch
              allRegionData={allRegionData}
              regionData={regionData}
              setRegionData={setRegionData}
              isRegion={true}
            />
            {isAccessProvided(
              moduleConstants.admin_view,
              subModuleConstants.regions,
              null,
              "write",
              accessDataByRole
            ) === true && (
              <button
                id="createBusinessunit"
                className="userlist-createNewUser-button"
                onClick={() => {
                  setOpenNewBusinessunitDialog(true);
                }}
              >
                New Region
              </button>
            )}
          </div>
          {regionData && regionData?.length === 0 ? (
            <div className="hgt userlist-users-table" id="businessunitNoResult">
              <NoResult title={"No Data Available"} />
            </div>
          ) : (
            <div className="userlist-users-table" id="businessunitTable">
              <RegionTable
                regionData={regionData}
                setUpdateAdminRegion={setUpdateAdminRegion}
                setDeleteAdminRegion={setDeleteAdminRegion}
                accessDataByRole={accessDataByRole}
              />
            </div>
          )}
        </div>
      ) : (
        <div className="hgt userlist-users-table" id="noAccessToRoleManagment">
          {accessDataByRole && <NoResult title={"No Access to Regions"} />}
        </div>
      )}
      {openNewBusinessunitDialog && (
        <CreateNewRegion
          openNewBusinessunitDialog={openNewBusinessunitDialog}
          setOpenNewBusinessunitDialog={setOpenNewBusinessunitDialog}
          setCreateRegion={setCreateRegion}
        />
      )}
    </>
  );
};

export default AdminRegionsPage;
