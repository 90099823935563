import React from "react";
import NLPQueryresults from "../../components/NLPQueryResults/NLPQueryresults";

const NLPQueryResultsWrapperPage = () => {
  return (
    <div style={{ margin: "20px" }}>
      <NLPQueryresults />
    </div>
  );
};

export default NLPQueryResultsWrapperPage;
