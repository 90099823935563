import React from "react";
import { useAtom } from "jotai";
import Graphview from "../../components/Graphview/Graphview";
import Resultview from "../../components/Resultview/Resultview";
import Layout from "../../layout/Layout";
import NoBusinessUnitsAssignedModal from "../../components/NoBusinessUnitsAssignedModal/NoBusinessUnitsAssignedModal";
import { accessDataByRoleAtom } from "../../atom/jotai";

const GraphviewPage = () => {
  const [accessDataByRole, setAccessDataByRole] = useAtom(accessDataByRoleAtom);
  //Funtion is used to disable built-in context menu
  const handleToControlLeftAndRightClick = React.useCallback((event) => {
    // prevent context menu from opening on right-click
    event.preventDefault();
    // synthetic event
    switch (event.type) {
      case "contextmenu":
        //setPositionOfMenuboxOnRightClick(null);
        break;
    }
    // native event
    switch (event.nativeEvent.button) {
      case 2:
        //setPositionOfMenuboxOnRightClick(null);
        break;
    }
  }, []);

  return (
    <div onContextMenu={handleToControlLeftAndRightClick} id="graphview">
      <Resultview />
      <Graphview />
      {/**Below Code is for when no bussiness unit is attached to the logged in user */}
      <NoBusinessUnitsAssignedModal
        accessDataByRole={accessDataByRole}
        moduleInvoker={"asset_view"}
      />
    </div>
  );
};

export default GraphviewPage;
