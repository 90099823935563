import { useAtom } from "jotai";
import React, { useEffect, useState } from "react";
import _ from "lodash";
import RiskFactor from "../RiskFactors/RiskFactor";
import {
  FormControl,
  MenuItem,
  Select,
  Table,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import { accessDataByRoleAtom, selectedNode } from "../../atom/jotai";
import "./DetailInfo.css";
import DetailList from "../DetailList/DetailList";
import {
  useGetAllPolicies,
  useGetRiskFactorData,
} from "../../api/readApiFunctions";
import {
  ntAplicablValue,
  nullConditionValue,
  RiskFactorMonth,
  riskFactorValueCond,
} from "../../constants/riskFactorMonth";
import { analyticServices } from "../../services/analyticServices";
import { truncateName } from "../../services/listviewServices";
import RiskFactorsNew from "../RiskFactors/RiskFactorsNew";

import { isRed } from "../../services/detailsServices";
import { ScoreColors } from "../../constants/amcharts";
import Cards from "../Cards/CardsNew";
import { isAccessProvided } from "../../services/assetManagementService";
import { PolicyNameConst } from "../../constants/policyConstants";

const DetailInfo = ({
  selectedData,
  appRefetch,
  modelRefetch,
  datasetRefetch,
}) => {
  const [nodeData, setSelectedNode] = useAtom(selectedNode);

  const [dataInfo, setDataInfo] = useState(null);

  const [isRiskFactor, setIsRiskFactor] = useState(false);
  const [riskFactorsData, setRiskFactorsData] = useState(null); //state used  for store riskfactor data from api
  const [showCardFromScores, setShowCardFromScores] = useState(false); //Opening the card
  const [selectedRiskFactor, setSelectedRiskFactor] = useState(null);
  const [scoresData, setScoresData] = useState([[]]);
  const [scoresSelectedPolicy, setScoresSelectedPolicy] = useState("");
  const [policyData, setPolicyData] = useState([]); //state to maintain policies list coming from database
  const [selectParentNode, setSelectParentNode] = useState(false);
  const [accessDataByRole, setAccessDataByRole] = useAtom(accessDataByRoleAtom);

  useEffect(() => {
    setDataInfo(selectedData[0]);
    const obj = {
      riskFactorIndexName: selectedData[0]?.__typename,
      riskFactorUrlId: selectedData[0][`${selectedData[0]?.__typename}_id`],
    };
    setRiskFactorsData(obj);
  }, [selectedData]);

  useEffect(() => {
    if (nodeData) {
      setDataInfo(nodeData);
      const obj = {
        riskFactorIndexName: nodeData.__typename,
        riskFactorUrlId: nodeData[`${nodeData.__typename}_id`],
      };
      setRiskFactorsData(obj);
    } else {
      setDataInfo(selectedData[0]);
      setSelectedNode(selectedData[0]);
      const obj = {
        riskFactorIndexName: selectedData[0]?.__typename,
        riskFactorUrlId: selectedData[0][`${selectedData[0]?.__typename}_id`],
      };
      setRiskFactorsData(obj);
    }
  }, [nodeData]);

  useEffect(() => {
    return () => {
      setSelectedNode(null);
    };
  }, []);

  useEffect(() => {
    if (isRiskFactor) {
      analyticServices("Detail View Visit");
    } else {
      analyticServices("Risk Factor View Visit");
    }
  }, [isRiskFactor]);

  const { data, error, isLoading } = useGetRiskFactorData(
    riskFactorsData?.riskFactorIndexName,
    riskFactorsData?.riskFactorUrlId,
    riskFactorsData
  );

  const {
    data: allPolicy,
    error: allPolicyError,
    isLoading: allPolicyLoading,
  } = useGetAllPolicies("details", "crud", true);

  /**  Below useEffects is used to set policy data coming from database*/
  useEffect(() => {
    allPolicy && setPolicyData(allPolicy?.data?.data?.score_type_config);
    if (allPolicy) {
      const filteredRows = allPolicy?.data?.data?.score_type_config.filter(
        (row) => {
          return (
            row.score_name !== "Human Impact" &&
            row.score_name !== "Konfer Confidence" &&
            row.status.toUpperCase() === "PUBLISHED"
          );
        }
      );
      setPolicyData(filteredRows);
      filteredRows.length > 0 &&
        setScoresSelectedPolicy(filteredRows[0].score_name);
    }
  }, [allPolicy]);

  const showRiskFactorMonth = (res) => {
    let finalRiskMonth = [];
    res?.data?.data.forEach((risk) => {
      risk?.quarter_data?.map((monthdata) => {
        if (monthdata?.quarter?.quarter !== "current_month")
          finalRiskMonth.push(monthdata?.quarter?.end_month);
      });
    });

    finalRiskMonth =
      finalRiskMonth.length > 0 && _.uniqWith(finalRiskMonth, _.isEqual);

    return { finalRiskMonth };
  };

  const handleSetCurrentData = (currentVal) => {
    setDataInfo((prevState) => ({
      ...prevState,
      cardData: currentVal,
    }));
  };

  return (
    dataInfo && (
      <div className="detail_wrapper" id="detailsAndRiskFactorDiv">
        <div
          className="detail_annoation_heading"
          id="detailsAndRiskFactorHeading"
        >
          <div className="detail_heading" id="nodeDetetailsHeadingDiv">
            <div className="heading_name" id="nodeDetetailsHeadingName">
              <Tooltip
                title={dataInfo?.name}
                disableFocusListener
                disableInteractive
              >
                <span>
                  {!dataInfo?.display_name
                    ? truncateName(dataInfo?.name)
                    : truncateName(dataInfo?.display_name)}
                </span>
              </Tooltip>
            </div>
            <div className="heading_cards" id="detailsRiskFactorCard">
              <div
                className={isRiskFactor ? "heading_chip" : "active_chip"}
                id="detailsChipTab"
              >
                <p
                  className="chip_name"
                  onClick={() => setIsRiskFactor(false)}
                  id="detailsChipTabName"
                >
                  Details
                </p>
              </div>
              {isAccessProvided(
                "details",
                "detail_score",
                null,
                "read",
                accessDataByRole
              ) && (
                <div
                  className={!isRiskFactor ? "heading_chip" : "active_chip"}
                  id="riskFactorChipTab"
                >
                  <p
                    className="chip_name"
                    onClick={() => setIsRiskFactor(true)}
                    id="riskFactorChipTabName"
                  >
                    Scores
                  </p>
                </div>
              )}
            </div>
          </div>

          {isRiskFactor && data !== null && data !== undefined ? (
            <>
              <Table sx={{ tableLayout: "fixed" }}>
                <TableHead>
                  <TableRow className="scores_column_heading">
                    <TableCell style={{ width: "40%" }}></TableCell>
                    <TableCell
                      align="center"
                      className="scores_column_header padding_bottom_0"
                      style={{ width: "20%" }}
                    >
                      {PolicyNameConst.konfer_confidence_wo_tm}
                    </TableCell>
                    {/* /**commented for cme */}
                    {/* <TableCell
                      align="center"
                      className="scores_column_header padding_bottom_0"
                      style={{ width: "20%" }}
                    >
                      {PolicyNameConst.human_impact}
                    </TableCell> */}
                    <TableCell
                      align="center"
                      className="scores_column_header padding_bottom_0"
                      style={{ width: "20%" }}
                    >
                      <Tooltip title={scoresSelectedPolicy}>
                        <FormControl
                          sx={{
                            width: "90%",
                            // whiteSpace: "nowrap",
                            // textOverflow: "ellipsis",
                            // overflow: "hidden",
                          }}
                          id="ScorePolicy"
                        >
                          <Select
                            sx={{
                              "& .MuiSelect-select": {
                                padding: "5px",
                                fontSize: "15px",
                                fontWeight: "600",
                                marginLeft: "-10px",
                                width: "85%",
                                lineHeight: "1rem",
                                // "@media screen and (max-height: 780px)": {
                                //   fontSize: "12px",
                                // },
                              },
                              "&::before": {
                                borderBottom: "none",
                                marginLeft: "-10px",
                              },
                              "&::after": {
                                background: "none",
                              },
                            }}
                            variant="standard"
                            id="complianceSelect"
                            value={scoresSelectedPolicy}
                            onChange={(e) => {
                              // handleChange(e);
                              setScoresSelectedPolicy(e.target.value);
                            }}
                            autoWidth={false}
                            MenuProps={{
                              style: { maxHeight: "40%" },
                              PaperProps: {
                                sx: {
                                  border: "0.5px solid #6D8291",
                                  boxShadow: "0px 5px 10px rgba(0, 0, 0, 0.25)",
                                  borderRadius: "4px",
                                  paddingTop: "1px",

                                  "& .MuiMenuItem-root": {
                                    // padding: 1,
                                    fontFamily:
                                      "var( --fontFamilyText) !important",
                                    lineHeight: "1rem",
                                    "@media screen and (max-height: 780px)": {
                                      fontSize: "14px",
                                    },
                                  },
                                },
                              },
                            }}
                            disableUnderline={true}
                            // inputProps={{ disableUnderline: true }}
                          >
                            {policyData &&
                              policyData.map((policyValue, index) => {
                                return (
                                  <MenuItem
                                    key={index}
                                    value={policyValue.score_name}
                                    id="selectPolicyValue"
                                  >
                                    {policyValue.score_name}
                                  </MenuItem>
                                );
                              })}
                          </Select>
                        </FormControl>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                </TableHead>
              </Table>

              {data?.Scores && (
                <Table
                  sx={{ tableLayout: "fixed" }}
                  stickyHeader
                  style={{ padding: "0px 3px" }}
                >
                  <TableHead>
                    <TableRow>
                      <TableCell
                        className="scores_column_header"
                        style={{ width: "40%" }}
                      >
                        Score
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{
                          width: "20%",
                          color: isRed(data?.Scores["Konfer Confidence"]),
                        }}
                      >
                        {data.Scores["Konfer Confidence"] !==
                        riskFactorValueCond.nullCondition
                          ? Number(data.Scores["Konfer Confidence"]) !==
                            riskFactorValueCond.ntAplicabl
                            ? data?.Scores["Konfer Confidence"].toLocaleString(
                                "en-US",
                                {
                                  minimumIntegerDigits: 2,
                                  useGrouping: false,
                                }
                              )
                            : dataInfo.calculate_score === false
                            ? "-"
                            : ntAplicablValue
                          : nullConditionValue}
                      </TableCell>

                      {/* /**commented for cme */}
                      {/* <TableCell
                        align="center"
                        style={{
                          width: "20%",
                          color: isRed(data?.Scores["Human Impact"]),
                        }}
                      >
                        {data.Scores["Human Impact"] !==
                        riskFactorValueCond.nullCondition
                          ? Number(data.Scores["Human Impact"]) !==
                            riskFactorValueCond.ntAplicabl
                            ? data?.Scores["Human Impact"].toLocaleString(
                                "en-US",
                                {
                                  minimumIntegerDigits: 2,
                                  useGrouping: false,
                                }
                              )
                            : dataInfo.calculate_score === false
                            ? "-"
                            : ntAplicablValue
                          : nullConditionValue}
                      </TableCell> */}

                      <TableCell
                        align="center"
                        style={{
                          width: "20%",
                          color: data?.Scores.hasOwnProperty(
                            `${scoresSelectedPolicy}`
                          )
                            ? isRed(data?.Scores[scoresSelectedPolicy])
                            : ScoreColors.blackC,
                        }}
                      >
                        {!data?.Scores.hasOwnProperty(`${scoresSelectedPolicy}`)
                          ? dataInfo.calculate_score === false
                            ? "-"
                            : ntAplicablValue
                          : data.Scores[scoresSelectedPolicy] !==
                            riskFactorValueCond.nullCondition
                          ? Number(data.Scores[scoresSelectedPolicy]) !==
                            riskFactorValueCond.ntAplicabl
                            ? data?.Scores[scoresSelectedPolicy].toLocaleString(
                                "en-US",
                                {
                                  minimumIntegerDigits: 2,
                                  useGrouping: false,
                                }
                              )
                            : dataInfo.calculate_score === false
                            ? "-"
                            : ntAplicablValue
                          : nullConditionValue}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                </Table>
              )}
            </>
          ) : (
            isRiskFactor &&
            (data === null || data === undefined) && (
              <p className="no_scores_displayed">No scores computed</p>
            )
          )}
        </div>

        <div style={{ padding: "0px 3px" }} id="detailsInfoList">
          {!isRiskFactor ? (
            <>
              <DetailList
                dataInfo={dataInfo}
                riskFactorsData={data?.Scores}
                scoresSelectedPolicy={scoresSelectedPolicy}
                appRefetch={appRefetch}
                modelRefetch={modelRefetch}
                datasetRefetch={datasetRefetch}
              />
            </>
          ) : (
            // <RiskFactor riskFactorsData={data} isLoading={isLoading} />
            <RiskFactorsNew
              setShowCardFromScores={setShowCardFromScores}
              setSelectedRiskFactor={setSelectedRiskFactor}
              sectionScores={data?.riskfactor}
              scoresSelectedPolicy={scoresSelectedPolicy}
              calculatedScore={dataInfo.calculate_score}
            />
          )}
        </div>

        {showCardFromScores &&
          isAccessProvided(
            "card_modal",
            "crud",
            null,
            "read",
            accessDataByRole
          ) && (
            <Cards
              cardId={dataInfo?.card_id}
              showCard={showCardFromScores}
              setShowCard={setShowCardFromScores}
              // datasetCardTemplateRes={datasetCardTemplateRes}
              // isSuccess={isSuccess}
              selectedRiskFactor={selectedRiskFactor}
              page={"Node Details"}
              appRefetch={appRefetch}
              modelRefetch={modelRefetch}
              datasetRefetch={datasetRefetch}
              handleSetCurrentData={handleSetCurrentData}
            />
          )}
      </div>
    )
  );
};

export default DetailInfo;
