import { isEmpty } from "lodash";
import * as am5 from "@amcharts/amcharts5";
import {
  colorSet,
  colorStrike,
  nodeText,
  setImageForNodes,
} from "../utils/amchartUtils";
import {
  ScoreColors,
  ScoreTypes,
  ScoreValue,
  ScoreValueNew,
} from "../constants/amcharts";
import { typeNames } from "../constants/dataTypes";
import { riskFactorValueCond } from "../constants/riskFactorMonth";

export const mchartDataFormat = (
  mainNodeData,
  checkedKonferScore,
  selectedData
) => {
  let finalNodeData = [];

  //node size value
  const rootNodeSize = 304;
  const childNodeSize = 100;
  const nodeWithChild = 170;
  const subChildNode = 10;

  //mchart data logic function
  if (mainNodeData && !isEmpty(mainNodeData)) {
    finalNodeData = JSON.parse(JSON.stringify(mainNodeData));

    finalNodeData?.forEach((nodeElement) => {
      Object.entries(nodeElement).forEach(([key, value]) => {
        //Don't add the contributor and Observabilities node in level 1 and business units key !== "apps_business_units"
        if (
          key !== "contributor" &&
          key !== "apps_business_units" &&
          key !== "models_business_units" &&
          key !== "input_data_tables_business_units" &&
          key !== "apps_risk_factors" &&
          key !== "models_risk_factors" &&
          key !== "input_data_tables_risk_factors" &&
          key !== "risk_factors_data"
        ) {
          if (value && Array.isArray(value) && value.length > 0) {
            value.forEach((element, index) => {
              let directObjPresent = true;
              if (index === 0 && !("children" in nodeElement)) {
                nodeElement.children = [];
                // set display name

                nodeElement.card_template_version =
                  nodeElement?.card?.latest_version;
                nodeElement.card_latest_version =
                  nodeElement?.card?.template_version;
                nodeElement.name = nodeElement.name;

                nodeElement.display_name =
                  nodeElement?.display_name !== null
                    ? nodeElement.display_name
                    : nodeElement.name;
                nodeElement.tooltipVal = !nodeElement.display_name
                  ? nodeElement.name
                  : nodeElement.display_name;

                nodeText(nodeElement, 7);
                let currentType = nodeElement[`__typename`];
                nodeElement.route_id = nodeElement[`${currentType}_id`];
                nodeElement.val = rootNodeSize;
                nodeElement.x = am5.percent(50);
                nodeElement.y = am5.percent(50);
                colorSet(nodeElement);
                nodeElement.image = setImageForNodes(nodeElement?.__typename); //Adding Image For Root Node as per the Node Type
              }
              Object.entries(element).forEach(([k, v]) => {
                if (
                  k !== "risk_factors_data" &&
                  v &&
                  typeof v == "object" &&
                  !Array.isArray(v)
                ) {
                  v.val = childNodeSize;
                  v.card_template_version = v?.card?.latest_version;
                  v.card_latest_version = v?.card?.template_version;

                  directObjPresent = false;

                  let currentType = v[`__typename`];
                  v.route_id = v[`${currentType}_id`];
                  v.name = v.name;
                  v.display_name =
                    v.display_name !== null ? v.display_name : v.name;
                  v.tooltipVal = !v.display_name ? v.name : v.display_name;

                  nodeText(v, 7);

                  colorSet(v);

                  nodeElement.children.push(v);
                }
              });

              if (directObjPresent) {
                element.val = childNodeSize;
                element.val = childNodeSize;
                element.card_template_version = element?.card?.latest_version;
                element.card_latest_version = element?.card?.template_version;
                element.name = element.name;
                element.display_name =
                  element.display_name !== null
                    ? element.display_name
                    : element.name;
                element.tooltipVal = !element.display_name
                  ? element.name
                  : element.display_name;
                let currentType = element[`__typename`];
                element.route_id = element[`${currentType}_id`];

                nodeText(element, 7);

                colorSet(element);

                nodeElement.children.push(element);
              }
            });
          } else {
            nodeElement.name = nodeElement.name;
            nodeElement.display_name =
              nodeElement.display_name !== null
                ? nodeElement.display_name
                : nodeElement.name;
            nodeElement.tooltipVal = !nodeElement.display_name
              ? nodeElement.name
              : nodeElement.display_name;

            nodeText(nodeElement, 7);

            let currentType = nodeElement[`__typename`];
            nodeElement.route_id = nodeElement[`${currentType}_id`];
            nodeElement.val = rootNodeSize;
            nodeElement.x = am5.percent(50);
            nodeElement.y = am5.percent(50);
            nodeElement.card_template_version =
              nodeElement?.card?.latest_version;
            nodeElement.card_latest_version =
              nodeElement?.card?.template_version;
            colorSet(nodeElement);
            nodeElement.image = setImageForNodes(nodeElement?.__typename); //Adding Image For Root Node as per the Node Type
          }
        }
      });
      if ("children" in nodeElement) {
        nodeElement?.children.forEach((element, index) => {
          Object.entries(element).forEach(([kItem, vItem]) => {
            //Don't add contributor node in children of children (2nd level)
            element.image = setImageForNodes(element.__typename); //Adding Image For Nodes (2nd level) as per the Node Type
            if (kItem !== "contributor" && kItem !== "risk_factors_data") {
              if (vItem && Array.isArray(vItem) && vItem.length > 0) {
                vItem.forEach((vElement, vIndex) => {
                  let directObjPresent = true;
                  if (vIndex === 0 && !("children" in element)) {
                    element.children = [];
                    element.val = nodeWithChild;
                  }
                  Object.entries(vElement).forEach(([kEle, vEle]) => {
                    if (
                      kEle !== "risk_factors_data" &&
                      vEle &&
                      typeof vEle == "object" &&
                      !Array.isArray(vEle)
                    ) {
                      directObjPresent = false;
                      vEle.val = subChildNode;
                      // set display name
                      vEle.card_template_version = vEle?.card?.latest_version;
                      vEle.card_latest_version = vEle?.card?.template_version;
                      vEle.name = vEle.name;
                      vEle.display_name =
                        vEle.display_name !== null
                          ? vEle.display_name
                          : vEle.name;
                      vEle.tooltipVal = !vEle.display_name
                        ? vEle.name
                        : vEle.display_name;

                      nodeText(vEle, 7);
                      colorSet(vEle);
                      element.children.push(vEle);
                    }
                  });
                  if (directObjPresent) {
                    vElement.val = subChildNode;
                    // set display name

                    vElement.card_template_version =
                      vElement?.card?.latest_version;
                    vElement.card_latest_version =
                      vElement?.card?.template_version;
                    vElement.name = vElement.name;
                    vElement.display_name =
                      vElement.display_name !== null &&
                      vElement.display_name !== undefined
                        ? vElement.display_name
                        : vElement.name;
                    vElement.tooltipVal = !vElement.display_name
                      ? vElement.name
                      : vElement.display_name;

                    nodeText(vElement, 7);
                    colorSet(vElement);
                    element.children.push(vElement);
                  }
                });
              }
            }
          });
        });
      }
    });

    //set min score value and color on the basis of scores.
    //setScoreValueAndColor(finalNodeData);

    //set min score value and color on the basis of scores.
    setScoreValueAndColorNew(finalNodeData);

    //set outer ring and fill color of node.
    if (checkedKonferScore) {
      setStrikeColor(finalNodeData);
    }
  }

  // let someArray = [
  //   { name: "Kristian", lines: "2,5,10" },
  //   { name: "John", lines: "1,19,26,96" },
  // ];
  // someArray = someArray.filter((e) => e.name != "Kristian");

  // finalNodeData[0]?.name == "German-Credit-Model" &&
  //   finalNodeData[0].children.filter((val) => {
  //   });

  // if (finalNodeData[0]?.name == "German-Credit-Model") {
  //   finalNodeData[0].children.filter((val) => {
  //     return val.minScoreValue > 50;
  //   });
  // }
  // if (finalNodeData[0]?.name == "German-Credit-Model") {
  //   console.log("check", finalNodeData[0]);
  // }

  /**If model.in_use is true means, the model is on production.
   * And we are showing only those models which are on production*/
  finalNodeData.map((item) => {
    finalNodeData[0].children = item.children?.filter(function (data) {
      return data.__typename === "model"
        ? data.in_use !== undefined && data.in_use === true
        : data;
    });
  });

  return finalNodeData;
};

export const setScoreValueAndColorNew = (finalNodeData) => {
  if (finalNodeData && finalNodeData.length > 0) {
    let minScoreOfParent;
    if (
      "children" in finalNodeData[0] &&
      finalNodeData[0].children.length > 0
    ) {
      finalNodeData[0].children.forEach((childElement) => {
        let childScore;
        if ("children" in childElement && !isEmpty(childElement)) {
          childElement.children.forEach((element) => {
            if (element?.risk_factors_data) {
              setIdAndTypeName(element);
              setMinScoreAndColor(element);
            } else {
              element.minScoreValue = null;
              element.nodeMinScoreColor = ScoreColors.redColor;
              element.minScoreColor = ScoreColors.redColor;
            }

            if (element.minScoreValue <= 49 || element.minScoreValue === null) {
              childScore = element.minScoreValue;
            }
            element.image = setImageForNodes(element.__typename); //Adding Image For Children Nodes as per the Node Type
          });
        }
        if (childElement?.risk_factors_data) {
          setIdAndTypeName(childElement);
          setMinScoreAndColor(childElement);
        } else {
          childElement.minScoreValue = null;
          childElement.nodeMinScoreColor = ScoreColors.redColor;
          childElement.minScoreColor = ScoreColors.redColor;
        }

        if (
          childElement.minScoreValue === null ||
          childElement.minScoreValue <= 49
        ) {
          minScoreOfParent = childElement.minScoreValue;
        }

        if (childScore === null || childScore <= 49) {
          childElement.minScoreValue = childScore;
          childElement.minScoreColor = ScoreColors.redColor;
          minScoreOfParent = childScore;
        }
      });
    }
    setIdAndTypeName(finalNodeData[0]);
    setMinScoreAndColor(finalNodeData[0]);
    if (minScoreOfParent === null || minScoreOfParent <= 49) {
      finalNodeData[0].minScoreValue = minScoreOfParent;
      finalNodeData[0].minScoreColor = ScoreColors.redColor;
    }
    //ddddddddd
  }
};

/* Set score and color in each node on the basis of scores. */
export const setScoreValueAndColor = (finalNodeData) => {
  let minScore = 0;
  if (finalNodeData && finalNodeData.length > 0) {
    if (
      "children" in finalNodeData[0] &&
      finalNodeData[0].children.length > 0
    ) {
      finalNodeData[0].children.forEach((childElement) => {
        let isChildNodeHasScore = true;
        let childScore = 0;
        if ("children" in childElement && !isEmpty(childElement)) {
          childElement.children.forEach((element) => {
            if ("children" in element) {
              // TODO: add some condition in future.
            } else if (element?.risk_factors_data) {
              //set bgID and BgTypeName for solid color.
              setIdAndTypeName(element);
            } else {
              //if the leaf node hasn't any score.
              isChildNodeHasScore = false;
            }
            setMinScoreAndColor(element);
            element.image = setImageForNodes(element.__typename); //Adding Image For Children Nodes as per the Node Type
            if (element.minScoreValue < childScore) {
              childScore = element.minScoreValue;
            }
            if (element.minScoreValue > ScoreValue.lowest_limit_yellow_score) {
              //If the leaf node has below 35 score.
              isChildNodeHasScore = false;
            }

            if (element.minScoreValue) {
              if (minScore === 0) {
                minScore = element.minScoreValue;
              } else if (minScore > element.minScoreValue) {
                minScore = element.minScoreValue;
              }
            }
          });
        } else if (childElement?.risk_factors_data) {
          setIdAndTypeName(childElement);
        }

        if (!isChildNodeHasScore && childElement?.risk_factors_data) {
          setIdAndTypeName(childElement);
        }
        setMinScoreAndColor(childElement);
        if (childScore > childElement.minScoreValue) {
          childElement.minScoreColor = setMinScoreColor(childScore);
        }

        if (childElement.minScoreValue) {
          if (minScore === 0) {
            minScore = childElement.minScoreValue;
          } else if (minScore > childElement.minScoreValue) {
            minScore = childElement.minScoreValue;
          }
        }
      });

      setMinScoreAndColor(finalNodeData[0]);

      //Set minScore & color to parent node.
      if (
        finalNodeData[0].minScoreValue &&
        finalNodeData[0].minScoreValue < minScore
      ) {
        minScore = finalNodeData[0].minScoreValue;
        setIdAndTypeName(finalNodeData[0]);
      } else {
        finalNodeData[0].minScoreValue = minScore;
        finalNodeData[0].minScoreColor = setMinScoreColor(minScore);
      }
    } else {
      setMinScoreAndColor(finalNodeData[0]);
    }
  }
};

/* Set strike color in each node on the basis of minScoreColor. */
export const setStrikeColor = (finalNodeData) => {
  if (finalNodeData && finalNodeData.length > 0) {
    colorStrike(finalNodeData[0]);
    if (
      "children" in finalNodeData[0] &&
      finalNodeData[0]?.children.length > 0
    ) {
      finalNodeData[0]?.children.forEach((childElement) => {
        colorStrike(childElement);
        if ("children" in childElement && !isEmpty(childElement)) {
          childElement?.children.forEach((element) => {
            colorStrike(element);
            if ("children" in element) {
              colorStrike(element);
            }
          });
        }
      });
    }
  }
  return finalNodeData;
};

/* Set bgID and BgTypeName for solid color */
export const setIdAndTypeName = (Element) => {
  Element.bgID = Element[`${Element.__typename}_id`];
  Element.bgTypeName = Element.__typename;
};

/* Set manScore and color. */
export const setMinScoreAndColor = (element, isChild) => {
  let konfer_composite_score =
    element.hasOwnProperty("risk_factors_data") &&
    element?.risk_factors_data.length > 0 &&
    element?.risk_factors_data[0].konfer_composite_score !== undefined
      ? element?.risk_factors_data[0].konfer_composite_score
      : 0;
  // if (konfer_composite_score) {
  element.minScoreValue = konfer_composite_score;

  element.nodeMinScoreColor = setMinScoreColor(konfer_composite_score);

  element.minScoreColor = setMinScoreColor(konfer_composite_score);
  //}
};

/* Set color on basis of score limit */
export const setMinScoreColor = (minOfScores) => {
  let scoreColor = "color";
  if (minOfScores <= ScoreValue.lowest_limit_red_score || minOfScores == null) {
    scoreColor = ScoreColors.redColor;
  } else {
    scoreColor = ScoreColors.greenColor;
  }
  return scoreColor;
};

export const handleAnnotationsRender = (annotation = "", slackUsers = []) => {
  // divide the sentence in array of words
  const splitText = annotation.split(" ");
  let formattedText = [];

  if (splitText.length > 0) {
    splitText.map((word) => {
      let newWord = "";
      if (word.startsWith("<@")) {
        // find the user with that id and replace the word with that user name
        const userId = word.split("@")[1].split(">")[0];
        const user = slackUsers.find((user) => user.id === userId);
        newWord = `@${user?.display}` || "@slack-user";
      } else {
        // else keep the same
        newWord = word;
      }

      formattedText.push(newWord);
    });

    // join that array and return it in string
    return formattedText.join(" ");
  } else {
    return "NO-ANNOTATION-VALUE";
  }
};

export const isRed = (val) => {
  if (
    !val ||
    val === riskFactorValueCond.nullCondition ||
    Number(val) === riskFactorValueCond.ntAplicabl
  ) {
    if (Number(val) === riskFactorValueCond.ntAplicabl || val === undefined) {
      return ScoreColors.blackC;
    } else {
      return ScoreColors.redColor;
    }
  } else {
    if (val <= ScoreValueNew.highest_limit_red_score) {
      return ScoreColors.redColor;
    } else {
      return ScoreColors.greenC;
    }
  }
};

// from the card response get the answers whose file type is "file" and attach the question id to it
// so that it can be used to render the files as a answer for that particular question id.
export const setFileAnswerData = (cardData) => {
  const queFileRes = [];

  cardData?.sections.map((sec) => {
    if (sec.title !== "Details" && sec?.subsections.length > 0) {
      const subData = sec.subsections;
      subData.map((subsection) => {
        if (subsection.title === "Questionnaire" && subsection?.questions) {
          subsection.questions.map((que) => {
            if (que.answer_type === "file") {
              // queFileRes.push(que)
              const questionId = que.card_question_id;
              if (que.text) {
                const questionFiles = JSON.parse(que.text);
                questionFiles.map((file) => {
                  file.questionId = questionId;
                  queFileRes.push(file);
                });
              }
            }
          });
        }
      });
    }
  });

  return queFileRes;
};
