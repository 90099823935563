import React from "react";
import "./ErrorPage.css";
import errorLogo from "../../assets/images/icons/error-page.svg";
import { useNavigate } from "react-router-dom";
import { handleDefaultUserAccess } from "../../services/assetManagementService";
import {
  accessDataByRoleAtom,
  authUser,
  isSwitchToAdminPanelAtom,
} from "../../atom/jotai";
import { useAtom } from "jotai";
import Navbar from "../Navbar/Navbar";

const ErrorPage = ({ text, type }) => {
  const navigate = useNavigate();
  const [, setIsSwitchToAdminPanel] = useAtom(isSwitchToAdminPanelAtom);
  const [user] = useAtom(authUser);
  const [accessDataByRole, setAccessDataByRole] = useAtom(accessDataByRoleAtom);

  const handleGoBack = () => {
    if (type === "404") {
      handleDefaultUserAccess(
        user?.contributor_id,
        user?.role,
        navigate,
        setIsSwitchToAdminPanel,
        setAccessDataByRole
      );
    }
    if (type === "403") {
      handleDefaultUserAccess(
        user?.contributor_id,
        user?.role,
        navigate,
        setIsSwitchToAdminPanel,
        setAccessDataByRole
      );
    }
  };

  return (
    <>
      <Navbar />
      <div className="error_container">
        <img src={errorLogo} />

        <p className="error_message">{text}</p>

        <p className="go_back_button" onClick={handleGoBack}>
          Go back
        </p>
      </div>
    </>
  );
};

export default ErrorPage;
