import { PolicyNameConst } from "./policyConstants";

export const accessesNamesConst = {
  admin_view: "Admin View",
  annotation: "Annotation",
  asset_view: "Asset View",
  card_modal: "Card Modal",
  card_templates: "Card Templates",
  details: "Details",
  extraction: "Discovery",
  feedback: "Feedback",
  policy: `${PolicyNameConst.policy}`,
  profile_view: "Profile View",
  score_inclusion: "Score Inclusion",
  summary_view: "Summary View",
  terms_conditions: "Terms Conditions",
};
