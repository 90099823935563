import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";

// MUI imports
import {
  AppBar,
  Box,
  CircularProgress,
  Divider,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import _ from "lodash";

// custom imports
import konferlogo from "../../assets/images/konferLogo/konferlogo.svg";
import SwitchToAdmin from "../../assets/images/adminPanel/SwitchToAdmin.svg";
import SignOut from "../../assets/images/Login/SignOut.svg";
import "./Navbar.css";
import Searchbox from "../Searchbox/Searchbox";
import { logoutUser } from "../../utils/authUtils";
import { Breadcrumbs } from "../Breadcrumbs/Breadcrumbs";
import OrgLogo from "../../assets/images/appbar/OrgLogo.svg";
import io from "socket.io-client";
import { useAtom } from "jotai";
import {
  authUser,
  isSwitchToAdminPanelAtom,
  isAdminRiskFactorUpdate,
  extractStarted,
  orgNameAtom,
  businessUnits,
  selectedBusinessUnit,
  businessUnitName,
  regionNameAtom,
  appIdForOpeningSummarViewAtom,
  appsNameDataComp,
  regionIDAtom,
  accessDataByRoleAtom,
  bulkQueResGeneratedAtom,
  organizationIDAtom,
} from "../../atom/jotai";
import { capitalize } from "lodash";
import { analyticServices } from "../../services/analyticServices";
import { toast } from "react-toastify";
import {
  useGetCompanyLogo,
  useGetExtractionHistory,
} from "../../api/readApiFunctions";
import { set } from "lodash";
import { socket } from "../../App.js";
import { queryKeyConstants } from "../../constants/basicConstants";
import {
  moduleConstants,
  subModuleConstants,
} from "../../constants/accessManagementConstants";
import { isAccessProvided } from "../../services/assetManagementService";
import { genAIToottips } from "../../constants/tooltipConstants";
import { extractionToasts } from "../../constants/toasterMsgConstants";
import {
  handleSwitchToAppAccess,
  handleSwitchToAppLocation,
  switchToAdminLocation,
} from "../../services/adminServices";

const Navbar = ({ breadcrumbData }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [riskFactorUpdate, setRiskFactorUpdate] = useAtom(
    isAdminRiskFactorUpdate
  );
  const [extractStatus, setExtractStatus] = useAtom(extractStarted);
  const open = Boolean(anchorEl);
  const [isSwitchToAdminPanel, setIsSwitchToAdminPanel] = useAtom(
    isSwitchToAdminPanelAtom
  );
  const [extractionProgress, setExtractionProgress] = useState(0);

  const [businessData, setBusinessData] = useAtom(businessUnits);
  const [selectedBuId, setSelectedBusiness] = useAtom(selectedBusinessUnit);
  const [businessName, setBusinessUnitName] = useAtom(businessUnitName);
  const [regionName, setRegionName] = useAtom(regionNameAtom);
  const [appIdForOpeningSummarView, setAppIdForOpeningSummarView] = useAtom(
    appIdForOpeningSummarViewAtom
  );
  const [appsNameData, setAppsNameData] = useAtom(appsNameDataComp);

  const [regionID, setRegionID] = useAtom(regionIDAtom);

  //state for managing access management system
  const [accessDataByRole, setAccessDataByRole] = useAtom(accessDataByRoleAtom);

  //State for showing the progress of bulk question
  const [bulkQueResGenerated, setBulkQueResGenerated] = useAtom(
    bulkQueResGeneratedAtom
  );

  const { data: extractHistory } = useGetExtractionHistory(
    isAccessProvided("extraction", "crud", null, "read", accessDataByRole)
      ? true
      : false
  );

  const navigate = useNavigate();

  const location = useLocation();

  const handleClick = (e) => {
    setAnchorEl(e.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleLogout = () => {
    logoutUser();
    navigate(`/login`);
    analyticServices(`sign_out`);
    setAccessDataByRole(null);
  };
  const [user] = useAtom(authUser);

  const [orgName] = useAtom(orgNameAtom);
  const [organizationID] = useAtom(organizationIDAtom);

  const {
    data: getCompanyLogo,
    isError: companyLogoIsError,
    error: companyLogoError,
    isLoading: companyLogoIsLoading,
  } = useGetCompanyLogo(
    user?.org_id,
    user !== null && user?.org_id !== undefined && location.pathname !== "/"
      ? true
      : false
  );

  useEffect(() => {
    if (companyLogoIsError) {
      toast.error(companyLogoError?.response?.data?.error);
    }
  }, [companyLogoError]);

  // the function is used to know the configuration page
  useEffect(() => {
    if (
      location.pathname.split("/")[2] == queryKeyConstants.configurations ||
      location.pathname.split("/")[1] === "result" ||
      location.pathname.split("/")[1] === "compliance-result"
    ) {
      setIsSwitchToAdminPanel(true);
    }
  }, []);

  const openAdminview = () => {
    if (location.pathname.split("/")[1] === "admin") {
      // navigate(`/`);
      let locationUrl = handleSwitchToAppLocation(accessDataByRole);
      if (locationUrl !== "") {
        navigate(locationUrl);
      }

      setIsSwitchToAdminPanel(true);
    } else {
      analyticServices("User switch to admin");
      if (
        isAccessProvided(
          moduleConstants.admin_view,
          subModuleConstants.users,
          null,
          "read",
          accessDataByRole
        )
      ) {
        navigate(`/admin/userlist`);
        setIsSwitchToAdminPanel(false);
      } else if (
        isAccessProvided(
          moduleConstants.admin_view,
          subModuleConstants.regions,
          null,
          "read",
          accessDataByRole
        )
      ) {
        navigate(`/admin/regions`);
        setIsSwitchToAdminPanel(false);
      } else if (
        isAccessProvided(
          moduleConstants.admin_view,
          subModuleConstants.business_units,
          null,
          "read",
          accessDataByRole
        )
      ) {
        navigate(`/admin/businessunit`);
        setIsSwitchToAdminPanel(false);
      } else if (
        isAccessProvided(
          moduleConstants.policy,
          subModuleConstants.policy_view,
          null,
          "read",
          accessDataByRole
        )
      ) {
        navigate(`/admin/policy`);
        setIsSwitchToAdminPanel(false);
      } else if (
        isAccessProvided(
          moduleConstants.admin_view,
          subModuleConstants.asset_metadata,
          null,
          "read",
          accessDataByRole
        )
      ) {
        navigate(`/admin/details`);
        setIsSwitchToAdminPanel(false);
      } else if (
        isAccessProvided(
          moduleConstants.admin_view,
          subModuleConstants.risk_factors,
          null,
          "read",
          accessDataByRole
        )
      ) {
        navigate(`/admin/riskfactors`);
        setIsSwitchToAdminPanel(false);
      } else if (
        isAccessProvided(
          moduleConstants.admin_view,
          subModuleConstants.business_units_assignment,
          null,
          "read",
          accessDataByRole
        )
      ) {
        navigate(`/admin/node-businessunit-relationship`);
        setIsSwitchToAdminPanel(false);
      } else if (
        isAccessProvided(
          moduleConstants.card_templates,
          subModuleConstants.crud,
          null,
          "read",
          accessDataByRole
        )
      ) {
        navigate(`/admin/cardTemplate`);
        setIsSwitchToAdminPanel(false);
      } else if (
        isAccessProvided(
          moduleConstants.admin_view,
          subModuleConstants.role_management,
          null,
          "read",
          accessDataByRole
        )
      ) {
        navigate(`/admin/roleManagement`);
        setIsSwitchToAdminPanel(false);
      } else {
        toast.info("No Access to Admin");
      }
    }
  };

  const adminAnalyticServices = () => {
    setRiskFactorUpdate(false);

    riskFactorUpdate && toast.warning("No changes made in Risk Factors");
    analyticServices(`Home Page View `);
  };

  const isSearch = () => {
    let res;
    if (
      location.pathname.split("/")[1] === "result" ||
      location.pathname.split("/")[1] === "compliance-result"
    ) {
      res = true;
    } else {
      res = false;
    }
    return res;
  };

  useEffect(() => {
    if (extractHistory && extractHistory?.agent_extract_history.length > 0) {
      setExtractionProgress(1);
      setExtractStatus(true);
    } else {
      setExtractStatus(false);
    }
  }, [extractHistory]);

  //Below socket code is commented
  useEffect(() => {
    if (extractStatus === true) {
      socket.on("extract details", (data) => {
        let value = data.percent_completed;
        setExtractionProgress(Number(value.split("%")[0]));
      });
    }
  }, [socket, extractStatus]);

  /**Recives web socket reponses when gen ai answers storing in db  */
  useEffect(() => {
    if (bulkQueResGenerated) {
      socket.on("GenAI bulk response", (genAISocketFrBlk) => {
        if (
          genAISocketFrBlk.status === "GenAI response generation in progress"
        ) {
          setBulkQueResGenerated(true);
        }
        if (genAISocketFrBlk.status === "GenAI responses copied successfully") {
          setBulkQueResGenerated(false);
          toast.success(
            `Responses for ${genAISocketFrBlk.entityName} are generated and updated`
          );
        }
      });
    }
  }, [socket, bulkQueResGenerated]);

  useEffect(() => {
    if (extractionProgress === 100) {
      toast.success(extractionToasts.extractionFinished);
      setExtractStatus(false);
      setExtractionProgress(0);
    }
  }, [extractionProgress]);

  const CircularProgressWithLabel = (props) => {
    return (
      <Box sx={{ position: "relative", display: "inline-flex" }}>
        <CircularProgress variant="determinate" {...props} />
        <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: "absolute",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography variant="caption" component="div" color="text.secondary">
            {`${Math.round(props.value)}%`}
          </Typography>
        </Box>
      </Box>
    );
  };

  return (
    <Box>
      <AppBar
        className={location.pathname == "/" ? "rootApp_homePage" : "rootApp"}
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        // position="static"
        position="fixed"
      >
        {/* <Toolbar> */}
        <div
          className={
            location.pathname == "/" ? "appbar-div_homePage" : "appbar-div"
          }
        >
          {location.pathname !== "/" && (
            <Box className="logo_breadcrumbs">
              {/* <Link
                id="konferLogoLinkOnAppbar"
                to={"/"}
                state={{
                  // resultViewPath
                  regionName: regionName,
                  regionID: regionID,
                  businessName: businessName,
                  selectedBuId: selectedBuId,
                  businessData: businessData,
                  appsNameData: appsNameData,
                  appIdForOpeningSummarView: appIdForOpeningSummarView,
                  route:
                    location.pathname.split("/")[2] == "compliance-summary"
                      ? true
                      : null,
                }}
                onClick={() => adminAnalyticServices("Home Page View")}
                className="navlinkSidebar"
              >
                <img
                  src={konferlogo}
                  alt=""
                  width="150px"
                  id="konferLogoOnAppbar"
                />
              </Link> */}

              <img
                src={konferlogo}
                alt=""
                width="150px"
                id="konferLogoOnAppbar"
                className="navlinkSidebar"
                onClick={() => {
                  if (
                    isAccessProvided(
                      "asset_view",
                      "crud",
                      null,
                      "read",
                      accessDataByRole
                    )
                  ) {
                    navigate("/", {
                      state: {
                        // resultViewPath
                        regionName: regionName,
                        regionID: regionID,
                        businessName: businessName,
                        selectedBuId: selectedBuId,
                        businessData: businessData,
                        appsNameData: appsNameData,
                        appIdForOpeningSummarView: appIdForOpeningSummarView,
                        route:
                          location.pathname.split("/")[2] ==
                          "compliance-summary"
                            ? true
                            : null,
                      },
                    });
                  }
                }}
              />

              {getCompanyLogo &&
                (getCompanyLogo?.logo !== "Logo not found for Organization" ||
                  getCompanyLogo?.name !== "-") && (
                  <Divider
                    id="divider"
                    orientation="vertical"
                    variant="middle"
                    flexItem
                    className="divider"
                  />
                )}

              {getCompanyLogo && (
                <div className="org-name-div" id="orgNameDiv">
                  {getCompanyLogo?.logo !==
                  "Logo not found for Organization" ? (
                    <img
                      src={getCompanyLogo?.logo}
                      alt="Org-Logo"
                      id="orgLogo"
                      className="org-logo"
                    />
                  ) : (
                    getCompanyLogo?.show_org_name &&
                    getCompanyLogo?.name !== "-" && (
                      <div className="org-logo-manually">
                        <text
                          style={{
                            fontSize: "22px",
                          }}
                        >
                          {getCompanyLogo?.name.charAt(0)}
                        </text>
                      </div>
                    )
                  )}

                  {getCompanyLogo?.show_org_name &&
                    getCompanyLogo?.name !== "-" && (
                      <span id="orgName" className="org-name">
                        {getCompanyLogo?.name}
                      </span>
                    )}
                </div>
              )}

              {bulkQueResGenerated && (
                <div>
                  <Tooltip title={genAIToottips.bulkQuesLoader} followCursor>
                    <CircularProgress className="extract_loader" />
                  </Tooltip>
                </div>
              )}

              {!isSearch() && location.pathname.split("/")[1] !== "admin" && (
                <Box className="breadcrumbs" id="breadcrumbsBox">
                  <Breadcrumbs breadcrumbData={breadcrumbData} />
                </Box>
              )}
            </Box>
          )}

          {extractStatus && (
            <div className="extract__parent">
              {/* <CircularProgress className="extract_loader" /> */}
              <CircularProgressWithLabel
                value={extractionProgress}
                className="extract_loader"
              />

              <p>Extraction in progress</p>
            </div>
          )}

          <div style={{ display: "flex" }}>
            {location.pathname !== "/" &&
              isAccessProvided(
                "asset_view",
                "crud",
                null,
                "read",
                accessDataByRole
              ) && (
                <>
                  {isSearch() && (
                    <div className="breadcrumb_search" id="appbarSearchBoxDiv">
                      <Box className="navbar_search_field" id="appbarSearchBox">
                        <Searchbox />
                      </Box>
                    </div>
                  )}
                </>
              )}

            <Box className="navbarUserName" id="appbarUserNameBox">
              <IconButton
                id="appbarUserNameButton"
                className="navbarUserNameButton"
                onClick={handleClick}
                size="small"
                sx={{
                  color: "black",
                  display: "flex",
                  marginTop: "5px",
                  alignItems: "center",
                  textAlign: "center",
                  float: "right",
                  "&:hover": {
                    borderRadius: "5px",
                  },
                }}
                aria-haspopup="true"
                aria-controls={open ? "account-menu" : undefined}
                aria-expanded={open ? "true" : undefined}
              >
                <p
                  id="appbarUserName"
                  className="appbarUserName"
                >{`Hi ${_.startCase(user?.name)}`}</p>
              </IconButton>
            </Box>

            <Menu
              anchorEl={anchorEl}
              id="account-menu"
              open={open}
              onClose={handleClose}
              transformOrigin={{ horizontal: "right", vertical: "top" }}
              anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
              {handleSwitchToAppAccess(accessDataByRole) && (
                <MenuItem onClick={openAdminview} id="appbarAdminMenu">
                  <ListItemIcon>
                    <img src={SwitchToAdmin} alt="Admin" />
                  </ListItemIcon>

                  {location.pathname.split("/")[1] !== "admin"
                    ? "Switch to Admin"
                    : "Switch to App"}
                </MenuItem>
              )}

              <MenuItem onClick={handleLogout} id="appbarLogoutMenu">
                <ListItemIcon>
                  <img src={SignOut} alt="" />
                </ListItemIcon>
                Sign out
              </MenuItem>
            </Menu>
          </div>
        </div>
        {/* </Toolbar> */}
      </AppBar>
    </Box>
  );
};

export default Navbar;
