import React from 'react'
import ErrorPage from '../../components/ErrorPage/ErrorPage'

const ErrorPage403 = () => {
  return (
   
      <ErrorPage text="ERROR 403: You don't have the permission to access this resource" type="403"/>
 
  )
}

export default ErrorPage403