import React, { useEffect, useLayoutEffect, useState } from "react";
import * as am5 from "@amcharts/amcharts5";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import * as am5xy from "@amcharts/amcharts5/xy";

import { Box } from "@mui/system";

const StackedBarChart = ({ data }) => {
  const uniqueDivId = Math.floor(Math.random() * 800000);

  useLayoutEffect(() => {
    let root = am5.Root.new(`${uniqueDivId}`);

    root.setThemes([am5themes_Animated.new(root)]);

    /* Create chart */
    var chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panX: false,
        panY: false,
        wheelX: "panX",
        wheelY: "zoomX",
        layout: root.verticalLayout,
      })
    );

    /* Show label for container */
    chart.rightAxesContainer.children.push(
      am5.Label.new(root, {
        text: "GoodCredit",
        fontSize: 16,
        fontWeight: "400",
        x: am5.p50,
        centerX: am5.p50,
      })
    );

    /* Create xAxis */
    var xAxis = chart.xAxes.push(
      am5xy.CategoryAxis.new(root, {
        categoryField: "value",
        renderer: am5xy.AxisRendererX.new(root, {}),
        tooltip: am5.Tooltip.new(root, {}),
      })
    );

    /* Show title on xAxis */
    xAxis.children.push(
      am5.Label.new(root, {
        text: data && data.yAxisText,
        x: am5.p50,
        centerX: am5.p50,
      })
    );

    xAxis.get("renderer").labels.template.setAll({
      oversizedBehavior: "wrap",
      maxWidth: 150
    });

    /* set data on xAxis */
    if(data.finalData){
      xAxis.data.setAll(data.finalData);
    }
   

    /* Create yAxis */
    var yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        min: 0,
        renderer: am5xy.AxisRendererY.new(root, {}),
      })
    );

    /* Show title on yAxis */
    yAxis.children.unshift(
      am5.Label.new(root, {
        rotation: -90,
        text: "Count",
        y: am5.p50,
        center: am5.p50,
      })
    );

    /* Create legend container */
    var legend = root.container.children.push(
      am5.Legend.new(root, {
        y: am5.percent(10),
        x: am5.percent(100),
        centerX: am5.percent(100),
        layout: root.verticalLayout,
      })
    );

    /* Add series */
    function makeSeries(name, fieldName, color) {
      var series = chart.series.push(
        am5xy.ColumnSeries.new(root, {
          name: name,
          stacked: true,
          xAxis: xAxis,
          yAxis: yAxis,
          fill: color,
          valueYField: fieldName,
          categoryXField: "value",
        })
      );

      /* set property for bar */
      series.columns.template.setAll({
        tooltipText: "{name}, {categoryX}: {valueY}",
        width: am5.percent(50),
        tooltipY: am5.percent(10),
      });
      
      /* set series data */
      if(data.finalData){
        series.data.setAll(data.finalData);
      }

      /* Make stuff animate on load */
      series.appear();

      series.bullets.push(function () {
        return am5.Bullet.new(root, {
          sprite: am5.Label.new(root, {
            fill: root.interfaceColors.get("alternativeText"),
            populateText: true,
          }),
        });
      });

      legend.data.push(series);
    }

    /* 1 means GoodCredit and 2 means BadCredit */
    makeSeries("1", "1" , am5.color(0x2596be));
    makeSeries("2", "2", am5.color(0xff7f0e));

    return () => {
      root.dispose();
    };
  }, []);

  return (
    <div>
      <Box
        id={uniqueDivId}
        style={{
          height: "50vh",
          width: "80%",
        }}
      ></Box>
    </div>
  );
};
export default StackedBarChart;
