import React, { useState } from "react";
import LightbulbOutlinedIcon from "@mui/icons-material/LightbulbOutlined";
import { IconButton, Tooltip } from "@mui/material";
import OpenAIModal from "../Subsection/OpenAIModal";
import GenAIResGenerator from "./GenAIResGenarator";
import { bulkQueResGeneratedAtom } from "../../atom/jotai";
import { useAtom } from "jotai";

/**
 * Component : OpenAIIcon
 *
 * Description : This component is responsible for showing the icon for opening open ai modal.
 * While clicking on icon it calls the open ai modal component.
 *
 */

const OpenAIIcon = ({ data }) => {
  //state for opening Gen Ai Modal
  const [openAIModal, setOpenAIModal] = useState(false);
  //state for opening Gen Ai Modal with particular question
  const [questionTextForOpenAI, setQusetionTextForOpenAI] = useState("");

  //State for showing the progress of bulk question
  const [bulkQueResGenerated, setBulkQueResGenerated] = useAtom(
    bulkQueResGeneratedAtom
  );
  return (
    <>
      <Tooltip title="GenAI Response">
        <IconButton
          sx={{
            "&.MuiIconButton-root": {
              padding: "1px 3px",
              marginTop: "7px",
              marginRight: "12px",
              border: " 0.25px solid #6d8291",
              borderRadius: "4px",
              boxShadow: "0px 0px 4px rgb(0 0 0 / 25%)",
              color: !bulkQueResGenerated ? "#000000" : "#B9B9B9",
            },
          }}
          disabled={bulkQueResGenerated}
          onClick={() => {
            if (!bulkQueResGenerated) {
              setOpenAIModal(true);
              setQusetionTextForOpenAI("");
            }
          }}
        >
          <LightbulbOutlinedIcon />
        </IconButton>
      </Tooltip>

      {openAIModal && (
        <GenAIResGenerator
          openAIModal={openAIModal}
          setOpenAIModal={setOpenAIModal}
          questionTextForOpenAI={questionTextForOpenAI}
          setQusetionTextForOpenAI={setQusetionTextForOpenAI}
          data={data}
        />
      )}
    </>
  );
};

export default OpenAIIcon;
