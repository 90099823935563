import React, { useState, useEffect } from "react";
import {
  IconButton,
  Dialog,
  DialogTitle,
  Typography,
  DialogContent,
  MenuItem,
  TextField,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import axios from "axios";
import Select from "react-select";
import request from "graphql-request";
import { toast } from "react-toastify";
import CancelIcon from "../../../assets/images/adminPanel/CancelIcon.svg";
import "./CreateNewBusinessunit.css";
import { useMutation, useQueryClient } from "react-query";
import { queryKeyConstants } from "../../../constants/basicConstants";
import { useGetRoleRegionBuData } from "../../../api/readApiFunctions";
import { analyticServices } from "../../../services/analyticServices";
import nodeApiInstance from "../../../api/instance/nodeApiInstance";
import { useLocation } from "react-router-dom";
import { isAccessProvided } from "../../../services/assetManagementService";
import {
  moduleConstants,
  subModuleConstants,
} from "../../../constants/accessManagementConstants";

const CreateNewBusinessunit = ({
  openNewBusinessunitDialog,
  setOpenNewBusinessunitDialog,
  accessDataByRole,
}) => {
  const location = useLocation();
  //get all regions
  const {
    data: allRegionData,
    error: allRegionError,
    isLoading: allRegionLoading,
  } = useGetRoleRegionBuData(
    "region",
    "business_units",
    location.pathname.split("/")[2] == "businessunit" &&
      location.pathname.split("/")[2] !== undefined &&
      accessDataByRole &&
      isAccessProvided(
        moduleConstants.admin_view,
        subModuleConstants.business_units,
        null,
        "read",
        accessDataByRole
      )
      ? true
      : false
  );

  const [selectedRegion, setSelectedRegion] = useState();
  const [optionsForRegion, setOptionsForRegion] = useState([]);
  // form validation rules
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required").trim(),
    region_id: Yup.string().required("Region is required"),
    description: Yup.string().optional().trim(),
  });
  const formOptions = { resolver: yupResolver(validationSchema) };

  //User Form
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm(formOptions);

  //convert region data in the format of react select options
  useEffect(() => {
    let op = [];
    allRegionData &&
      allRegionData.length > 0 &&
      allRegionData.map((region, index) => {
        op.push({
          value: region.region_id,
          label: region.name,
        });
      });
    setOptionsForRegion(op);
  }, [allRegionData]);

  const queryClient = useQueryClient();

  //API call for creating new business unit
  const createBusinessUnitMutation = useMutation(
    async (data) => {
      return nodeApiInstance.post("details/create-business-unit", data);
    },
    {
      onSuccess: (value) => {
        queryClient.invalidateQueries(queryKeyConstants.getAllBusinessUnits);
        setOpenNewBusinessunitDialog(false);
        if (value.data.errors?.length !== undefined) {
          toast.error(value.data.errors[0].message);
        } else {
          toast.success("Business Unit created");
          analyticServices(` ${getValues("name")}, business unit is created`);
        }
      },
    }
  );

  //Create New business unit
  const handleRegistration = (data) => {
    if (data.description.length > 500) {
      setError("description", {
        type: "custom",
        message: `Length should not exceed 500 characters`,
      });
    } else {
      data.business_unit_type = data.name;
      //Call API for create new business unit
      createBusinessUnitMutation.mutate(data);
    }
  };

  const handleError = (errors) => {};

  return (
    <>
      <Dialog
        id="createBusinessunitDialog"
        open={openNewBusinessunitDialog}
        onClose={() => {
          setOpenNewBusinessunitDialog(!openNewBusinessunitDialog);
        }}
        aria-labelledby="new-user-dialog"
        aria-describedby="new-user-dialog"
        sx={{
          "& .MuiDialog-paper": {
            boxShadow: "0px 6px 10px rgba(0, 0, 0, 0.3)",
            borderRadius: "8px",
            width: "675.4px",
            height: "470.96px",
          },
        }}
      >
        <DialogTitle id="new-user-dialog" sx={{ m: 0, p: 3 }}>
          <Typography variant="h5" component="div" className="create-heading">
            Create New Business Unit
          </Typography>
          <IconButton
            id="closeBusinessunitDialog"
            aria-label="close"
            onClick={() => {
              setOpenNewBusinessunitDialog(false);
            }}
            sx={{
              position: "absolute",
              right: 16,
              top: 27,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <img src={CancelIcon} alt="Delete" />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ position: "relative", p: 3 }}>
          <form
            id="createBusinessunitform"
            onSubmit={handleSubmit(handleRegistration, handleError)}
            autoComplete="off"
            style={{ marginTop: "10px" }}
          >
            <div className="info-row" id="createBusineeUnitName">
              <label className="info-label">Business Unit*</label>
              <TextField
                className="info-input"
                sx={{ "& .MuiInputBase-input": { padding: "0px" } }}
                id="businessunit_name"
                // label="Name"
                variant="standard"
                {...register("name")}
              />
            </div>
            {errors?.name && errors.name.message && (
              <div className="info-row-erros" id="createBusineeUnitNameEr">
                <div></div>
                <small className="text-danger">
                  {errors?.name && errors.name.message}
                </small>
              </div>
            )}

            <div className="info-row" id="BURoleRow">
              <label className="info-label">Region*</label>
              <Select
                id="BU-region"
                value={selectedRegion && selectedRegion}
                isClearable={false}
                styles={{
                  container: (base) => ({
                    ...base,
                    focusVisible: {
                      outline: "none",
                    },
                  }),
                  control: (base) => ({
                    ...base,
                    marginTop: "-8px",
                    border: "none",
                    borderBottom: " 1px solid #6d8291",
                    borderRadius: "2px",
                  }),
                }}
                maxMenuHeight="100px"
                onChange={(event) => {
                  clearErrors("region_id");
                  setSelectedRegion(event);
                  if (event.label) {
                    setValue("region_id", event.value);
                  } else {
                    setValue("region_id", "");
                  }
                }}
                options={optionsForRegion}
              />
            </div>
            {errors?.region_id && errors.region_id.message && (
              <div className="info-row-erros" id="BURegionRowError">
                <div></div>
                <small className="text-danger">
                  {errors?.region_id && errors.region_id.message}
                </small>
              </div>
            )}

            <div className="info-row" id="createBusineeUnitDesc">
              <label className="user-info-label">Description</label>
              <TextField
                className="info-input"
                sx={{ "& .MuiInputBase-root": { padding: "0px" } }}
                id="businessunit_description"
                maxRows={4}
                variant="standard"
                multiline
                {...register("description")}
              />
            </div>
            {errors?.description && errors.description.message && (
              <div className="info-row-erros" id="createBusineeUnitDescErr">
                <div></div>
                <small className="text-danger">
                  {errors?.description && errors.description.message}
                </small>
              </div>
            )}

            <button className="create-button" id="createBusineeunitButton">
              <span>Create</span>
            </button>
          </form>
          <button
            className="cancel-button"
            id="buCancelButton"
            onClick={() => {
              setOpenNewBusinessunitDialog(false);
            }}
          >
            <span>Cancel</span>
          </button>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default CreateNewBusinessunit;
