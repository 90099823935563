import React, { useState, useEffect, useRef } from "react";
import { capitalize } from "lodash";
import { jsonParse, jsonParser } from "../../../utils/basicUtils";
import UploadSVG from "../../../assets/images/icons/upload-icon.svg";
import pdfIcon from "../../../assets/images/icons/pdf-icon.svg";
import DeleteFileIcon from "../../../assets/images/icons/delete-file-icon.svg";
import CancelIcon from "../../../assets/images/adminPanel/CancelIcon.svg";
import "./CardsSubsection.css";
import {
  Button,
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import { useUploadCardFiles } from "../../../api/createApiFunctions";
import { toast } from "react-toastify";
import { useDeleteFiles } from "../../../api/deleteApiFunctions";
import { Download } from "@mui/icons-material";
import nodeApiInstance from "../../../api/instance/nodeApiInstance";
import { getLocalTokens } from "../../../utils/authUtils";
import { handleFileDownload } from "../../../api/readApiFunctions";
import { getFileIcon } from "../../../services/cardServices";
import PDFFileIcon from "../../../assets/images/dataSetCard/PDFFileIcon.svg";
import GenAIPopOver from "../../GenAIPopOver/GenAIPopOver";

/**
 * Component : SectionsQuestionnaireAnswer
 *
 * Description : This component is responsible for displaying section questionnaire answers 
 * in section format.
 *
 * Props : questionData - each question detailed infromation,
           isEditDatasetSection - shows the current mode of card (edited/display)
           handlEditedQuestionData - call back function to save the edited question data
           updatedFiles- holds the list of files which are newly added against question in edited mode,
           fileList-  holds the list of files which are already added against question and coming from db,
           handleUpdateFilesWhileDeleted- handler function for deleted files,
           handleUpdateFiles-handler function for updated files,
           showQueTextValue- handler function for setting text and boolean value,
 *  */
const SectionsQuestionnaireAnswer = ({
  questionData,
  isEditDatasetSection,
  handlEditedQuestionData,
  updatedFiles,
  fileList,
  handleUpdateFilesWhileDeleted,
  handleUpdateFiles,
  showQueTextValue,
}) => {
  const [selectedTab, setSelectedTab] = useState(questionData.answer_type[0]);
  const [localBooleanState, setLocalBooleanState] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const tabsArr = [
    {
      name: "Boolean",
      value: "boolean",
    },
    {
      name: "Text",
      value: "text",
    },
    {
      name: "Attachments",
      value: "file",
    },
  ];

  const {
    mutate: uploadFiles,
    isSuccess: uploadFileSuccess,
    data: uploadFileResponse,
    isError: uploadFilesIsError,
    error: uploadFilesError,
  } = useUploadCardFiles();

  // handle file upload
  const handleFileUpload = (e) => {
    const file = e.target.files[0];

    const fileData = new FormData();
    fileData.append(`files`, file);
    fileData.append(`source`, "Questionnaire");
    uploadFiles({
      filePayload: fileData,
    });
  };

  //Below useEffect is called when file updated successfully in database
  useEffect(() => {
    if (uploadFileResponse && uploadFileSuccess) {
      toast.success(uploadFileResponse.message);
      handleUpdateFiles(
        uploadFileResponse.data[0],
        questionData.card_question_id
      );
    }
  }, [uploadFileSuccess]);

  //Below useEffect is called while upadting files in Db and get some error
  useEffect(() => {
    if (uploadFilesIsError && uploadFilesError !== null) {
      toast.error(`${uploadFilesError?.Error}`);
    }
  }, [uploadFilesIsError]);

  const handleShowText = (currentQue, type) => {
    const val = jsonParser(currentQue?.answer_field, type);
    return val ? val : "-";
  };

  const showGenAiTag = () => {
    if (questionData.generated_answer) {
      if (
        questionData.generated_answer ===
          handleShowText(questionData, selectedTab) ||
        (handleShowText(questionData, selectedTab).length === 1 &&
          questionData.generated_answer === "i don't know")
      ) {
        return "GenAI";
      } else {
        return "Manual";
      }
    } else if (handleShowText(questionData, selectedTab) !== "-") {
      return "Manual";
    }
  };
  return (
    <>
      <div className="show_card_answer">
        {/* show tabs */}
        <div className="tab_container">
          {tabsArr.map((tab) => {
            let className = "answer_tab";
            if (tab.value === selectedTab) {
              className = "answer_tab selected_tab";
            }
            return questionData?.answer_type?.includes(tab.value) ? (
              <p
                onClick={() => {
                  setSelectedTab(tab.value);
                }}
                className={className}
              >
                {tab.name}
              </p>
            ) : (
              <p className="answer_tab_disabled">{tab.name}</p>
            );
          })}
        </div>
        <div>
          {!isEditDatasetSection ? (
            selectedTab === "file" ? (
              <div style={{ maxHeight: "100px", overflowY: "auto" }}>
                {questionData?.answer_type?.includes("file") &&
                eval("(" + questionData.answer_field + ")")?.file?.length >
                  0 ? (
                  eval("(" + questionData.answer_field + ")")?.file?.length >
                    0 &&
                  eval("(" + questionData.answer_field + ")")?.file?.map(
                    (fileInfo) => {
                      return (
                        <div style={{ display: "flex" }}>
                          <img
                            src={
                              fileInfo.file_name.split(".").length > 0
                                ? getFileIcon(fileInfo.file_name.split(".")[1])
                                : PDFFileIcon
                            }
                            alt="File Icon"
                            style={{
                              width: "18px",
                              margin: "0px 10px 0px 0px",
                            }}
                          />
                          <p style={{ margin: "10px 10px 0px 0px" }}>
                            {fileInfo.file_name}
                          </p>
                          <IconButton
                            sx={{
                              "&.MuiIconButton-root": {
                                padding: "10px 2px 10px 0px",
                              },
                            }}
                            onClick={() => {
                              handleFileDownload(fileInfo, "card_modal");
                            }}
                          >
                            <Download />
                          </IconButton>
                        </div>
                      );
                    }
                  )
                ) : (
                  <p style={{ margin: "10px 10px 15px 0px" }}>-</p>
                )}
              </div>
            ) : (
              <p style={{ margin: "10px 10px 15px 0px" }} onClick={handleClick}>
                {" "}
                {capitalize(handleShowText(questionData, selectedTab))}
                {selectedTab !== "text" && showGenAiTag() === "GenAI" && (
                  <span style={{ marginLeft: "10px" }} className="gen_ai_chip">
                    {showGenAiTag()}
                  </span>
                )}
                {selectedTab !== "text" && showGenAiTag() === "Manual" && (
                  <span style={{ marginLeft: "10px" }} className="gen_ai_chip">
                    {showGenAiTag()}
                  </span>
                )}
              </p>
            )
          ) : (
            <>
              {/**text */}
              {questionData?.answer_type.includes("text") &&
                selectedTab === "text" && (
                  <TextField
                    fullWidth
                    multiline
                    rows={2}
                    style={{ marginTop: "10px", marginBottom: "10px" }}
                    sx={{
                      "& .MuiInputBase-root": { padding: "7px" },
                      "& .MuiInputBase-input": {
                        fontFamily: "var(--fontFamilyText)!important",
                        fontWeight: 400,
                        fontSize: "16px",
                        color: "#000000",
                      },
                    }}
                    id={`cardTextTypeQueAnsEdtd${questionData.card_question_id}`}
                    label="Response"
                    variant="outlined"
                    defaultValue={showQueTextValue("text", questionData)}
                    onChange={(e) => {
                      handlEditedQuestionData(
                        questionData.card_question_id,
                        "text",
                        e.target.value
                      );
                    }}
                  />
                )}

              {/**Boolean */}
              {questionData?.answer_type?.includes("boolean") &&
                selectedTab === "boolean" && (
                  <>
                    <RadioGroup
                      id={`cardBooleanTypeQuenAnsEdtd${questionData.card_question_id}`}
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      value={
                        localBooleanState === ""
                          ? showQueTextValue("boolean", questionData)
                          : localBooleanState
                      }
                      onChange={(e) => {
                        if (e.target.value !== undefined) {
                          setLocalBooleanState(e.target.value);
                          handlEditedQuestionData(
                            questionData.card_question_id,
                            "boolean",
                            e.target.value
                          );
                        }
                      }}
                      onClick={(e) => {
                        if (e.target.value !== undefined) {
                          setLocalBooleanState(e.target.value);
                          handlEditedQuestionData(
                            questionData.card_question_id,
                            "boolean",
                            e.target.value
                          );
                        }
                      }}
                    >
                      <div
                        style={{
                          width: "100%",
                          // display: "flex",
                          // justifyContent: "space-around",

                          display: "grid",
                          gridTemplateColumns: "1fr 1fr 1fr",
                        }}
                      >
                        <div style={{ display: "flex" }}>
                          <FormControlLabel
                            value="yes"
                            control={<Radio color="default" />}
                            label="Yes"
                          />

                          {questionData.generated_answer &&
                            questionData.generated_answer === "yes" && (
                              <p className="gen_ai_chip" onClick={handleClick}>
                                GenAI
                              </p>
                            )}
                        </div>
                        <div style={{ display: "flex" }}>
                          <FormControlLabel
                            value="no"
                            control={<Radio color="default" />}
                            label="No"
                          />
                          {questionData.generated_answer &&
                            questionData.generated_answer === "no" && (
                              <p className="gen_ai_chip" onClick={handleClick}>
                                GenAI
                              </p>
                            )}
                        </div>
                        <div style={{ display: "flex" }}>
                          <FormControlLabel
                            value=""
                            control={<Radio color="default" />}
                            label="Not sure"
                          />
                          {questionData.generated_answer &&
                            questionData.generated_answer ===
                              "i don't know" && (
                              <p className="gen_ai_chip" onClick={handleClick}>
                                GenAI
                              </p>
                            )}
                        </div>
                      </div>
                    </RadioGroup>
                  </>
                )}

              {/**file */}
              {questionData?.answer_type?.includes("file") &&
                selectedTab === "file" && (
                  <div
                    className="upload_files"
                    id={`cardFileTypeQuenAnsEdtd${questionData.card_question_id}`}
                  >
                    <div className="upload__wrapper upload__file--border">
                      <div>
                        <img
                          src={UploadSVG}
                          alt="Upload Icon"
                          className="upload__image"
                        />
                      </div>
                      <Button
                        variant="contained"
                        component="label"
                        class="custom-file-upload"
                      >
                        Browse File
                        <input
                          type="file"
                          onChange={(e) => {
                            handleFileUpload(e);
                          }}
                          // multiple
                          hidden
                          accept=".doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.pdf ,image/png ,application/pdf, .csv, .txt, .xls, .xlsx"
                        />
                      </Button>
                      <div className="file_support--text">
                        <p>Supports: .csv, .xlsx, .png, .pdf</p>
                      </div>
                    </div>

                    <div className="upload__file__border uploadedFiles">
                      {updatedFiles.map((updatedFile, i) => (
                        <>
                          {updatedFile.card_question_id ===
                            questionData.card_question_id &&
                            updatedFile.file.map((fileData) => {
                              return (
                                <div
                                  className="files_uploaded--list"
                                  key={fileData.id}
                                  id={fileData.id}
                                >
                                  <img
                                    src={
                                      fileData.file_name.split(".").length > 0
                                        ? getFileIcon(
                                            fileData.file_name.split(".")[1]
                                          )
                                        : PDFFileIcon
                                    }
                                    alt="File Icon"
                                    style={{
                                      width: "18px",
                                    }}
                                  />
                                  <div className="file_name">
                                    <p>{fileData.file_name}</p>
                                  </div>
                                  <div>
                                    <img
                                      src={DeleteFileIcon}
                                      alt="Delete button"
                                      onClick={() =>
                                        handleUpdateFilesWhileDeleted(
                                          questionData.card_question_id,
                                          fileData.id,
                                          fileData.file_name
                                        )
                                      }
                                    />
                                  </div>
                                </div>
                              );
                            })}
                        </>
                      ))}

                      {fileList.map((updatedFile, i) => (
                        <>
                          {updatedFile.card_question_id ===
                            questionData.card_question_id &&
                            updatedFile.file.length > 0 &&
                            updatedFile.file.map((fileData) => {
                              return (
                                <div
                                  className="files_uploaded--list"
                                  key={fileData.id}
                                  id={fileData.id}
                                >
                                  {/* <img src={pdfIcon} alt="File Icon" /> */}
                                  <img
                                    src={
                                      fileData.file_name.split(".").length > 0
                                        ? getFileIcon(
                                            fileData.file_name.split(".")[1]
                                          )
                                        : PDFFileIcon
                                    }
                                    alt="File Icon"
                                    style={{
                                      width: "18px",
                                    }}
                                  />
                                  <div className="file_name">
                                    <p>{fileData.file_name}</p>
                                  </div>
                                  <div>
                                    <img
                                      src={DeleteFileIcon}
                                      alt="Delete button"
                                      onClick={() =>
                                        handleUpdateFilesWhileDeleted(
                                          questionData.card_question_id,
                                          fileData.id,
                                          fileData.file_name
                                        )
                                      }
                                    />
                                  </div>
                                </div>
                              );
                            })}
                        </>
                      ))}
                    </div>
                  </div>
                )}
            </>
          )}
        </div>
        {/* add gen ai popover here */}
        <GenAIPopOver
          answer={capitalize(questionData.generated_answer)}
          snippet={questionData.evidence_snippet}
          anchorEl={anchorEl}
          handleClose={handleClose}
        />
      </div>
    </>
  );
};

export default SectionsQuestionnaireAnswer;
