import React, { useState, useEffect } from "react";
import _ from "lodash";
import { useLocation } from "react-router-dom";
import { useGetRoles } from "../../api/readApiFunctions";
import AdminUsetlistSearch from "../../components/AdminUsetlistSearch/AdminUsetlistSearch";
import Layout from "../../layout/Layout";
import NoResult from "../../components/NoResult/NoResult";
import RoleManagementTable from "../../components/AdminRoleManagement/RoleManagementTable/RoleManagementTable";
import { toast } from "react-toastify";
import { useAtom } from "jotai";
import { useDeleteRole } from "../../api/deleteApiFunctions";
import { useCreateRoles } from "../../api/createApiFunctions";
import { useUpdateRole } from "../../api/updateApiFunctions";
import { createNewRoleMsg } from "../../constants/roleManagmenetMsg";
import { adminRoleManagementPage } from "../../constants/toasterMsgConstants";
import RoleCreateComp from "../../components/RoleCreateComp/RoleCreateComp";
import { dateFormate } from "../../utils/basicUtils";
import { accessDataByRoleAtom } from "../../atom/jotai";
import { isAccessProvided } from "../../services/assetManagementService";
import {
  moduleConstants,
  subModuleConstants,
} from "../../constants/accessManagementConstants";

/**
 * Component : AdminRoleManagementPage
 *
 * Description : This component is entry point of role management screen.
 * It manage all states, curd operation functions, and also call the
 * appropriate component to show either table view, create/update/clone screen
 */

const AdminRoleManagementPage = () => {
  const location = useLocation();
  const [rolesData, setRolesData] = useState([]); //Maintain all roles list coming from database
  const [deleteAdminRole, setDeleteAdminRole] = useState(); //Call delete mutation

  //Maintain the data which is used as payload for create/update
  const [roleCreateData, setRoleCreateData] = useState({});
  const [isCreateRole, setIsCreateRole] = useState(false); //Call create mutation

  //Show the block of create/update screen or table view of role
  const [createRole, setCreateRole] = useState(false);

  //Save the title edit/create/clone
  const [roleEditTitle, setRoleEditTitle] = useState();
  const [isEditRole, setIsEditRole] = useState(); //Call update mutation

  //state for managing access management system golbal value
  const [accessDataByRole, SetAccessDataByRole] = useAtom(accessDataByRoleAtom);

  //Get all Roles
  const {
    data: getRolesData,
    isSuccess: getRolesSuccess,
    error: getRolesError,
    isLoading: isGetRolesLoading,
    refetch: getRolesDataRefetch,
  } = useGetRoles(
    location.pathname.split("/")[2] == "roleManagement" &&
      location.pathname.split("/")[2] !== undefined &&
      accessDataByRole &&
      isAccessProvided(
        moduleConstants.admin_view,
        subModuleConstants.role_management,
        null,
        "read",
        accessDataByRole
      )
      ? true
      : false
  );

  //Create new role
  const {
    mutate: createRoles,
    isLoading: isLoading,
    isSuccess: isSuccessCreated,
  } = useCreateRoles();

  //delete role
  const { mutate: roleDeleteById, isSuccess: deleteRoleSuccess } =
    useDeleteRole();
  const {
    mutate: updateRole,
    isSuccess: roleUpdateSuccess,
    data: roleUpdateData,
  } = useUpdateRole();

  useEffect(() => {
    if (getRolesData) {
      let sortData = _.orderBy(getRolesData, ["createdAt"], ["desc"]);
      setRolesData(sortData);
      // setRolesData(getRolesData);
    }
  }, [getRolesData]);

  useEffect(() => {
    if (deleteAdminRole !== undefined) {
      roleDeleteById(deleteAdminRole);
    }
  }, [deleteAdminRole]);

  useEffect(() => {
    if (deleteRoleSuccess) {
      toast.success(adminRoleManagementPage.dataDeleteMsg);
    }
  }, [deleteRoleSuccess]);

  //Below useEffect is used call mutation for create the role
  useEffect(() => {
    if (isCreateRole) {
      if (roleCreateData) {
        const filterData =
          rolesData.length > 0 &&
          rolesData.filter((val) => val.name === roleCreateData.name);
        if (filterData.length === 0) {
          createRoles(roleCreateData);
        } else {
          toast.error(adminRoleManagementPage.roleAlreadyExisted);
        }
      }

      setRoleCreateData({});
      setIsCreateRole(false);
      setCreateRole(false);
    }
  }, [isCreateRole, roleCreateData]);

  //Below useEffect is used for showing toaster after successfully create role
  useEffect(() => {
    if (isSuccessCreated) {
      toast.success(adminRoleManagementPage.roleCreateMsg);
    }
  }, [isSuccessCreated]);

  //Below useEffect is used call mutation for update the role
  useEffect(() => {
    if (isEditRole) {
      updateRole(roleCreateData);
      setRoleCreateData({});
      setIsCreateRole(false);
      setIsEditRole(false);
      setCreateRole(false);
    }
  }, [isEditRole, roleCreateData]);

  //Below useEffect is used for showing toaster after successfully update role
  useEffect(() => {
    if (roleUpdateSuccess) {
      getRolesDataRefetch();
      toast.success(adminRoleManagementPage.roleUpdateMsg);
    }
  }, [roleUpdateData]);

  //Below useEffect is used for giving title to current page
  useEffect(() => {
    document.title = "Role Management";
  });

  return (
    <>
      {!createRole ? (
        <>
          <div className="userList-heading1" id="businessunitHeading">
            Role Management
          </div>
          {isAccessProvided(
            moduleConstants.admin_view,
            subModuleConstants.role_management,
            null,
            "read",
            accessDataByRole
          ) === true ? (
            <div className="userlist" id="businessunit">
              <div
                className="userlist-search-and-create paddingTRL30px"
                id="rolesSearch"
              >
                <AdminUsetlistSearch
                  getRolesData={getRolesData}
                  rolesData={rolesData}
                  setRolesData={setRolesData}
                  isRoleManagement={true}
                />

                {isAccessProvided(
                  moduleConstants.admin_view,
                  subModuleConstants.role_management,
                  null,
                  "write",
                  accessDataByRole
                ) === true && (
                  <button
                    id="createRole"
                    className="userlist-createNewUser-button"
                    onClick={() => {
                      setCreateRole(true);
                      setRoleEditTitle(createNewRoleMsg);
                    }}
                  >
                    Create Role
                  </button>
                )}
              </div>
              {!rolesData || rolesData?.length === 0 ? (
                <div className="hgt userlist-users-table" id="rolesNoResult">
                  <NoResult title={"No Role Available"} />
                </div>
              ) : (
                <div className="userlist-users-table" id="rolesTable">
                  <RoleManagementTable
                    rolesData={rolesData}
                    setDeleteAdminRole={setDeleteAdminRole}
                    setCreateRole={setCreateRole}
                    setRoleCreateData={setRoleCreateData}
                    setRoleEditTitle={setRoleEditTitle}
                    accessDataByRole={accessDataByRole}
                  />
                </div>
              )}
            </div>
          ) : (
            <div
              className="hgt userlist-users-table"
              id="noAccessToRoleManagment"
            >
              {accessDataByRole && (
                <NoResult title={"No Access to Role Management"} />
              )}
            </div>
          )}
        </>
      ) : (
        <RoleCreateComp
          setCreateRole={setCreateRole}
          setIsCreateRole={setIsCreateRole}
          roleCreateData={roleCreateData}
          setRoleCreateData={setRoleCreateData}
          roleEditTitle={roleEditTitle}
          setIsEditRole={setIsEditRole}
        />
      )}
    </>
  );
};

export default AdminRoleManagementPage;
