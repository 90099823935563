import {  Formik} from "formik";
import * as Yup from "yup";
import React, { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import "./AdminConfigrationTest.css";
import { useAtom } from "jotai";
import { authUser } from "../../atom/jotai";
import { useCreatAmiInstance } from "../../api/createApiFunctions";
import SimpleBackdrop from "../../components/SimpleBackdrop/SimpleBackdrop";


const AdminConfigrationTest = () => {

  const [userState, setUser] = useAtom(authUser);
    
 const {data: amiResponse , isLoading , mutate : createAMI , error} = useCreatAmiInstance()

  const handleAmiFormSubmit = (values , setSubmittingFn) => {
    const payload = {...values ,  userEmail : userState.email, requestedDomain : `${window.location.origin}` }
    createAMI(payload)
    setSubmittingFn(false)
  }


  return (
    <>
      <div className="ami_container">
      <Formik
      initialValues={{ email: "" , name : "" , organisation : "" }}
      onSubmit={(values,{ setSubmitting }) => {
          handleAmiFormSubmit(values,setSubmitting)
      }
      }
      validationSchema={Yup.object().shape({
        email: Yup.string().email().required("Email Required"),
        name: Yup.string().min(2, "Mininum 2 characters").max(15, "Maximum 15 characters").required("Name Required"),
        organisation: Yup.string().min(2, "Mininum 2 characters").max(15, "Maximum 15 characters").required("Organization Required")
      })}
    >
      {(props) => {
        const {
          values,
          touched,
          errors,
          dirty,
          isSubmitting,
          handleChange,
          handleBlur,
          handleSubmit,
          handleReset
        } = props;
        return (
          <form 
          // type="submit"
          className="form-container"
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit()
          }}>

            <div className="input_container">
              <label htmlFor="name" style={{ display: "block" }} className="input_label">
                Name
              </label>
              <input
                id="name"
                placeholder="Enter your name"
                type="text"
                value={values.name}
                onChange={handleChange}
                onBlur={handleBlur}
                className={
                  errors.name && touched.name
                    ? "text-input error"
                    : "text-input"
                }
              />
              {errors.name && touched.name && (
                <div className="input-feedback">{errors.name}</div>
              )}
            </div>

            <div className="input_container">
              <label htmlFor="email" style={{ display: "block" }} className="input_label">
                Email
              </label>
              <input
                id="email"
                placeholder="Enter your email"
                type="text"
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
                className={
                  errors.email && touched.email
                    ? "text-input error"
                    : "text-input"
                }
              />
              {errors.email && touched.email && (
                <div className="input-feedback">{errors.email}</div>
              )}
            </div>

            <div className="input_container">
              <label htmlFor="email" style={{ display: "block" }} className="input_label">
                Organisation Name
              </label>
              <input
                id="organisation"
                placeholder="Enter your organisation"
                type="text"
                value={values.organisation}
                onChange={handleChange}
                onBlur={handleBlur}
                className={
                  errors.organisation && touched.organisation
                    ? "text-input error"
                    : "text-input"
                }
              />
              {errors.organisation && touched.organisation && (
                <div className="input-feedback">{errors.organisation}</div>
              )}
            </div>

            <div  className="ami_btn_container">
              <button
                type="button"
                className="ami_buttons btn_disabled"
                onClick={handleReset}
                disabled={!dirty || isSubmitting}
              >
                Clear
              </button>
              <button 
                type="submit" 
                disabled={isSubmitting}
                className="ami_buttons"
                >
                Submit
              </button>
            </div>

          </form>
        );
      }}
    </Formik>
     <SimpleBackdrop openStat={isLoading} />
      </div>
      <ToastContainer/>
    </>
  );
};

export default AdminConfigrationTest;
