import { Button } from "@mui/material";
import React, { useEffect } from "react";
import { useState } from "react";
import UploadSVG from "../../assets/images/icons/upload-icon.svg";
// import awsSample from "../../assets/files/dev-aws-fle-test.json"
import "./FileExtraction.css";
import downloadBtn from "../../assets/images/icons/download-btn.svg";
import jsonIcon from "../../assets/images/icons/json-icon.svg";
import successTick from "../../assets/images/icons/success-tick.png";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import deleteBin from "../../assets/images/icons/delete-bin.svg";
import crossIcon from "../../assets/images/icons/cancel.svg";
import { useAtom } from "jotai";
import {
  accessDataByRoleAtom,
  extractStarted,
  isSidebar,
  uploadedExtractFiles,
} from "../../atom/jotai";
import { useFileExtraction } from "../../api/createApiFunctions";
import { downloadFile, formatBytes } from "../../utils/basicUtils";
import awsExtractionData from "../../assets/files/aws-file-extraction.json";
import genericExtractionData from "../../assets/files/generic-file-extraction.json";
import { toast, ToastContainer } from "react-toastify";
import { adminConfigurationPage } from "../../constants/toasterMsgConstants";
import { handleSampleFileDownload } from "../../api/readApiFunctions";
import { useResetDatabase } from "../../api/deleteApiFunctions";
import { isAccessProvided } from "../../services/assetManagementService";

const FileExtraction = () => {
  // set default as aws , as per the current requirement
  const [sampleFileSelect, setSampleFileSelect] = useState("aws");
  const [uploadedFiles, setUploadedFiles] = useAtom(uploadedExtractFiles);
  const [selectedFiles, setSelectedFiles] = useState(null);
  const [accessDataByRole, setAccessDataByRole] = useAtom(accessDataByRoleAtom);

  const sampleFiles = [
    {
      name: "General",
      id: "generic",
      fileValue: genericExtractionData,
      fileName: "generic-file-extraction-sample.json",
    },
    isAccessProvided(
      "extraction",
      "microsoft_azure_ml_file_upload",
      null,
      "read",
      accessDataByRole
    ) && { name: "Microsoft Azure ML", id: "azure", fileValue: null },
    {
      name: "Amazon SageMaker",
      id: "aws",
      fileValue: awsExtractionData,
      fileName: "aws-file-extraction-sample.json",
    },
  ];

  const handleFileChange = (e) => {
    setSelectedFiles(e.target.files[0]);
    const selFile = e.target.files[0];
    const { number, size } = formatBytes(selFile.size);

    setUploadedFiles((prevState) => [
      { name: selFile.name, size: number, type: size, status: "selected" },
    ]);
    e.target.value = "";
  };

  const handleSampleChange = (value) => {
    setSampleFileSelect(value);
  };

  const {
    mutate: resetDatabase,
    data: resetResult,
    isSuccess: resetIsSuccess,
    error: resetError,
    isError: resetIsError,
    isLoading: resetLoading,
  } = useResetDatabase();

  const {
    mutate: fileExtract,
    isLoading: fileLoadingFlag,
    isSuccess: fileSuccessFlag,
    status: fileStatus,
    data: fileResponse,
    error: fileError,
  } = useFileExtraction();

  const [extractStat, setExtractStat] = useAtom(extractStarted);

  // Handler Functions
  const handleResetCLick = () => {
    resetDatabase({
      source: sampleFileSelect || "aws",
      subModInvoker: "general_reset",
    });
  };

  // om file extract start button
  const handleFileExtractClick = () => {
    if (selectedFiles && extractStat === false) {
      setExtractStat(true);
      fileExtract({ selectedFiles, sampleFileSelect });

      const { number, size } = formatBytes(selectedFiles.size);
      // prevstate not passed since we are handling one file upload.
      setUploadedFiles((prevState) => [
        {
          name: selectedFiles.name,
          size: number,
          type: size,
          status: "progress",
        },
      ]);
      setSelectedFiles(null);
    }
  };

  const handleFileDeleteClick = (file) => {
    const unDeletedFiles = uploadedFiles.filter((uploadedFile) => {
      if (file.name !== uploadedFile.name && file.status === "progress") {
        return uploadedFile;
      }
    });
    setUploadedFiles([]);
    setSelectedFiles(null);
  };

  // sample download
  const handleSampleDownload = (sampleData) => {
    const selectedSample = sampleFiles.find(
      (fileData) => fileData.id === sampleData
    );
    if (selectedSample.id === "aws") {
      handleSampleFileDownload(
        "aws-file-extraction-sample.json",
        "template",
        "extraction"
      );
    }
    if (selectedSample.id === "generic") {
      handleSampleFileDownload(
        "generic-file-extraction-sample.json",
        "template",
        "extraction"
      );
    }
    if (selectedSample.id === "azure") {
      handleSampleFileDownload(
        "azure-file-extraction-sample.json",
        "template",
        "extraction"
      );
    }
  };

  useEffect(() => {
    if (fileSuccessFlag || fileResponse || fileError) {
      setUploadedFiles([]);
    }
  }, [fileSuccessFlag, fileResponse, fileError]);

  // handle reset notifcation
  useEffect(() => {
    if (resetIsSuccess) {
      toast.success(adminConfigurationPage.resetDBSucess);
    }
  }, [resetResult]);

  useEffect(() => {
    if (resetIsError) {
      toast.error(resetError?.response?.data?.error);
    }
  }, [resetIsError]);

  return (
    <div className="upload__screen">
      {/* File uplaod and description */}
      <div className="upload_actions page_description">
        {/* show description */}
        {/* <p className="file_description">
          Lorem ipsum dolor sit amet consectetur, adipisicing elit.
          Exercitationem, suscipit unde. Beatae corporis reprehenderit sequi qui
          minima fugit quam commodi saepe cupiditate! Laudantium magnam
          molestiae iste, asperiores vero ullam perspiciatis! Lorem ipsum dolor
          sit amet consectetur adipisicing elit. Itaque error odio accusamus.
          Officia amet, perspiciatis consequatur nihil sapiente, repellendus non
          eligendi, dolorem nemo ut quisquam. Ex asperiores excepturi expedita
          vero.
        </p> */}

        <div className="file_radio_controllers">
          {isAccessProvided(
            "extraction",
            "general_file_upload",
            null,
            "read",
            accessDataByRole
          ) && (
            <div className="sample_row" style={{ margin: "0px 0px 5px 0px" }}>
              <div className="sample_row_Selection">
                <input
                  type="radio"
                  id="generic"
                  className="input_radio"
                  name="fileSampleSelect"
                  value="generic"
                  checked={sampleFileSelect === "generic"}
                  onChange={(e) => handleSampleChange(e.target.value)}
                />
                <label htmlFor="generic" className="sampleName">
                  General
                </label>
              </div>
              {sampleFileSelect === "generic" && (
                <div className="download">
                  <p className="sample">Sample template</p>

                  <div
                    className="download_icon"
                    onClick={() => handleSampleDownload("generic")}
                  >
                    <img src={downloadBtn} alt="" />
                  </div>
                </div>
              )}
            </div>
          )}

          {isAccessProvided(
            "extraction",
            "amazon_sagemaker_file_upload",
            null,
            "read",
            accessDataByRole
          ) && (
            <div className="sample_row" style={{ margin: "5px 0px 0px 0px" }}>
              <div className="sample_row_Selection">
                <input
                  type="radio"
                  id="aws"
                  className="input_radio"
                  name="fileSampleSelect"
                  value="aws"
                  checked={sampleFileSelect === "aws"}
                  onChange={(e) => handleSampleChange(e.target.value)}
                />
                <label htmlFor="aws" className="sampleName">
                  Amazon SageMaker
                </label>
              </div>

              {sampleFileSelect === "aws" && (
                <div className="download">
                  <p className="sample">Sample template</p>

                  <div
                    className="download_icon"
                    onClick={() => handleSampleDownload("aws")}
                  >
                    <img src={downloadBtn} alt="" />
                  </div>
                </div>
              )}
            </div>
          )}

          {/* <div className="sample_row" style={{ margin: "5px 0px 0px 0px" }}>
            <div className="sample_row_Selection">
              <input
                type="radio"
                id="azure" 
                className="input_radio"
                name="fileSampleSelect"
                value="azure"
                checked={sampleFileSelect === "azure"}
                onChange={(e) => handleSampleChange(e.target.value)}
              />
              <label htmlFor="azure" className="sampleName">
                Microsoft Azure ML
              </label>
            </div>

            {sampleFileSelect === "azure" && (
              <div className="download">
                <p className="sample">Sample template</p>

                <div
                  className="download_icon"
                  onClick={() => handleSampleDownload("azure")}
                >
                  <img src={downloadBtn} alt="" />
                </div>
              </div>
            )}
          </div> */}
        </div>
      </div>

      <div className="upload_actions file_upload">
        {/* show file upload button */}
        {isAccessProvided(
          "extraction",
          "general_file_upload",
          null,
          "write",
          accessDataByRole
        ) && (
          <div
            className="upload__wrapper upload__file--border"
            style={{ padding: "40px 70px" }}
          >
            <div>
              <img
                src={UploadSVG}
                alt="Upload Icon"
                className="upload__image"
              />
            </div>
            <Button
              variant="contained"
              component="label"
              class="custom-file-upload"
            >
              Browse File
              <input
                type="file"
                onChange={handleFileChange}
                multiple
                hidden
                accept="application/JSON"
              />
            </Button>
            <div className="file_support--text">
              <p>Supports: .json</p>
            </div>
          </div>
        )}

        {/* show uploaded files */}
        <div className="uploaded_files_list">
          {uploadedFiles.map((file) => {
            return (
              <div className="upload_container">
                <div className="left_data">
                  <img
                    src={jsonIcon}
                    alt="Upload Icon"
                    className="upload__image"
                  />

                  <div className="file_metadata file_name">
                    <p>{file.name}</p>
                    <div className="file_metadata file_size">
                      <p className="size-data">{file.size}</p>
                      <p className="size-data">{file.type}</p>
                    </div>
                  </div>
                </div>

                <div className="right_data">
                  <div>
                    {file.status === "done" && (
                      <div className="status">
                        <img src={successTick} />
                      </div>
                    )}
                    {file.status === "progress" && (
                      <Box sx={{ display: "flex" }}>
                        <CircularProgress />
                      </Box>
                    )}
                  </div>
                  {!extractStat && (
                    <div
                      className="file_actions"
                      onClick={() => handleFileDeleteClick(file)}
                    >
                      <img
                        src={
                          file.status === "done" || ("selected" && deleteBin)
                        }
                      />
                    </div>
                  )}
                </div>
              </div>
            );
          })}
        </div>

        {/* buttons */}

        <div className="buttons_container">
          {process.env.REACT_APP_ENABLE_RESET_DB === "true" &&
            isAccessProvided(
              "extraction",
              "general_reset",
              "reset_button",
              "execution",
              accessDataByRole
            ) && (
              <button
                className={
                  extractStat
                    ? "extract_button extract_button_disabled reset_btn"
                    : "extract_button reset_btn"
                }
                onClick={handleResetCLick}
                disabled={extractStat}
              >
                Reset Database
              </button>
            )}

          {isAccessProvided(
            "extraction",
            "extract",
            "extraction_button",
            "execution",
            accessDataByRole
          ) && (
            <button
              className={
                extractStat || !selectedFiles
                  ? "extract_button extract_button_disabled"
                  : "extract_button"
              }
              onClick={handleFileExtractClick}
            >
              Start
            </button>
          )}
        </div>
        <ToastContainer />
      </div>
    </div>
  );
};

export default FileExtraction;
