import React, { useLayoutEffect, useEffect, useState, useRef } from "react";
import { useAtom } from "jotai";
import { Box } from "@mui/material";
import * as am5 from "@amcharts/amcharts5";
import * as am5hierarchy from "@amcharts/amcharts5/hierarchy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import NoResultImg from "../../assets/images/status/NoResult.png";
import {
  colorSetForOriginalColor,
  getUniqueAmchartId,
} from "../../utils/amchartUtils";
import {
  findElementInGraphviewAtom,
  totalCountForGraphViewAtom,
  activeCountForGraphViewAtom,
  matchElementUIDInGraphviewAtom,
  isGraphviewChild,
  isComplianceViewAtom,
  openCPOAnnotationsAtom,
  openSideBarAtom,
} from "../../atom/jotai";
import "./Amchart.css";
import {
  NodeFillColors,
  ScoreColors,
  ScoreValue,
} from "../../constants/amcharts";
import { ColorIndexNames } from "../../constants/dataTypes";
import { useLocation } from "react-router-dom";

const AmchartGraphview = ({
  seriesData,
  seriesOrphanNodeData,
  formatInfo,
  checkedShowLabel,
  nodeLabelFont,
  zoomInAndOut,
  handleRightClickOnNode,
  chartHeight,
  orphanNodes,
  checkedKonferScore,
  isUnUsedNodeData,
}) => {
  // give unique id to amchart canvas to avoid duplicate id error
  const uniqueDivId = getUniqueAmchartId();
  const [graphviewChild, setGraphviewChild] = useAtom(isGraphviewChild);

  const appLength = new Number(formatInfo.appLength);
  const modelLength = new Number(formatInfo.modelLength);
  const dataLength = new Number(formatInfo.dataLength);

  const maxNode = Math.max(appLength, modelLength, dataLength);
  const amchartHeight = maxNode * 160;
  const amchartMarginTop = 13 * zoomInAndOut;
  const [isComplianceView, setIsComplianceView] = useAtom(isComplianceViewAtom);
  const location = useLocation();
  const amchartOrphanNodeHeight =
    seriesOrphanNodeData !== undefined && seriesOrphanNodeData !== null
      ? seriesOrphanNodeData[0]?.children?.length * 15.5
      : 0;

  const [seriesa, setSeriesa] = useState();
  const [series1, setSeries1] = useState();
  const [findElementInGraphview, setFindElementInGraphview] = useAtom(
    findElementInGraphviewAtom
  );
  const [totalCountForGraphView, setTotalCountForGraphView] = useAtom(
    totalCountForGraphViewAtom
  );
  const [activeCountForGraphView, setActiveCountForGraphView] = useAtom(
    activeCountForGraphViewAtom
  );
  const [matchElementUIDInGraphview, setMatchElementUIDInGraphview] = useAtom(
    matchElementUIDInGraphviewAtom
  );

  // state used for highlight links
  const [selectedNodeData, setSelectedNodeData] = useState("");

  // state used for remove  links
  const [selectedSubNodeData, setSelectedSubNodeData] = useState([]);
  const [hideChildNode, setHideChildNode] = useState("");
  const myRef = useRef([]);
  let i = 0;
  let arrForSavingMatchElement = [];
  const [openCPOAnnotations] = useAtom(openCPOAnnotationsAtom); //State for maintaing Annotations on compliance open or close
  const [openSideBar] = useAtom(openSideBarAtom); //State for maintaing sidebar open or close

  if (location?.pathname.split("/")[1] === "result") {
    setIsComplianceView(false);
  }

  const summeryPath = location.pathname.split("/")[2];

  // console.log(summeryPath !== "graphview");

  /**
   * This useEffect is used for highlighting the node labels
   * It is dependant on findElementInGraphview props
   * if findElementInGraphview has some value then highlight it
   */
  useEffect(() => {
    let series;
    !orphanNodes ? (series = seriesa) : (series = series1);

    if (checkedShowLabel === true) {
      series?.labels.each(function (node) {
        node.set("text", "{name}"); //Re-setting the initial state of label (not highlighted) [eg:- node.set("text", "x[bold]y[/]z")]

        var myRegExp = new RegExp(`${findElementInGraphview}`, "gi");
        if (findElementInGraphview !== "" && checkedShowLabel === true) {
          const temp = node
            .getText()
            .replace(myRegExp, function (match, capture) {
              return `[#FFB000][bold]` + match + `[/]`;
            });
          node.set("text", temp);
        }

        var match1 = node.getText().match(myRegExp);
        if (match1) {
          i = i + 1; //counting the number of occurrence
          //arrForSavingMatchElement.push(node._dataItem.dataContext.name)
          arrForSavingMatchElement.push(node._parent.uid);
        }
      });
      setTotalCountForGraphView(i); // total count of all occurrence
      setMatchElementUIDInGraphview(arrForSavingMatchElement);
    }
  }, [findElementInGraphview]);

  /**
   * This useEffect is called when checkedKonferScore is set to true which means
   *  to show the colours as per the score else show the original colours of nodes
   */
  useEffect(() => {
    let series;
    !orphanNodes ? (series = seriesa) : (series = series1);

    series?.circles.each(function (node) {
      if (checkedKonferScore) {
        node._dataItem.dataContext.konfer_composite_score_value <=
        ScoreValue.lowest_limit_red_score
          ? node.dataItem
              .get("circle")
              .set("fill", am5.color(ScoreColors.redColor))
          : colorSetForOriginalColor(node, "true");

        if (node._dataItem.dataContext.__typename === ColorIndexNames.app) {
          node._dataItem.dataContext.konfer_composite_score_value <=
          ScoreValue.lowest_limit_red_score
            ? node.dataItem
                .get("outerCircle")
                .set("stroke", am5.color(ScoreColors.redColor))
            : node.dataItem
                .get("outerCircle")
                .set(
                  "stroke",
                  am5.color(node._dataItem.dataContext.outerRingColor)
                );
        } else if (
          node._dataItem.dataContext.__typename === ColorIndexNames.model
        ) {
          node._dataItem.dataContext.konfer_composite_score_value <=
          ScoreValue.lowest_limit_red_score
            ? node.dataItem
                .get("outerCircle")
                .set("stroke", am5.color(ScoreColors.redColor))
            : node.dataItem
                .get("outerCircle")
                .set(
                  "stroke",
                  am5.color(node._dataItem.dataContext.outerRingColor)
                );
        }
      } else {
        colorSetForOriginalColor(node, "true");
      }
    });
  }, [checkedKonferScore]);

  // links color onhover node

  useEffect(() => {
    let series;
    !orphanNodes ? (series = seriesa) : (series = series1);

    series?.links.each(function (node) {
      node.adapters.add("stroke", function (fill, target) {
        if (node.dataItem.dataContext.name == selectedNodeData) {
          // return am5.color("#ff0000");
          return am5.color(0x000000);
        } else if (
          selectedSubNodeData.includes(node.dataItem.dataContext.name)
        ) {
          return am5.color("#ffffff");
        } else {
          // return am5.color(0x707070);
          return am5.color(0x909090);
        }
      });
    });
  }, [selectedNodeData, selectedSubNodeData]);

  // links width onhover node
  useEffect(() => {
    let series;
    !orphanNodes ? (series = seriesa) : (series = series1);

    series?.links.each(function (node) {
      node.adapters.add("strokeWidth", function (fill, target) {
        if (node.dataItem.dataContext.name == selectedNodeData) {
          return 3.2;
        } else if (
          selectedSubNodeData.includes(node.dataItem.dataContext.name)
        ) {
          return 2;
        } else {
          return 2;
        }
      });
    });
  }, [selectedNodeData, selectedSubNodeData]);

  // used for hide sublevel node if they dont have relation with other parent node
  useEffect(() => {
    let series;
    !orphanNodes ? (series = seriesa) : (series = series1);

    series?.links.each(function (node) {
      node.adapters.add("stroke", function (fill, target) {
        if (selectedSubNodeData.includes(node.dataItem.dataContext.name)) {
          // node.dataItem.dataContext.children?.length > 0 &&

          return am5.color("#ffffff");
        } else {
          return am5.color(0x707070);
          // return am5.color(0x707070);
        }
      });
    });
  }, [selectedSubNodeData]);

  /**
   * This useEffect is used to render the node labels
   * It is dependant checkedShowLabel props
   * if checkedShowLabel is true then show labels else hide
   */
  useEffect(() => {
    let series;
    !orphanNodes ? (series = seriesa) : (series = series1);

    series?.labels.each(function (node) {
      if (checkedShowLabel) {
        node.set("text", "{name}");
        node.set("fontSize", nodeLabelFont);
      } else {
        node.set("text", "");
      }
    });
  }, [checkedShowLabel, nodeLabelFont]);

  /**
   * This useEffect is used for highlighting the occurence node
   * It is dependant on activeCountForGraphView props.
   * When the drop arrow is pressed from Find Input, this useEffect is called.
   */

  useEffect(() => {
    let series;
    !orphanNodes ? (series = seriesa) : (series = series1);

    series?.circles.each(function (node, ev) {
      node.states.apply("default");
      if (
        node._parent.uid ===
        matchElementUIDInGraphview[activeCountForGraphView - 1]
      ) {
        node.states.apply("highlight");
      }
    });

    //series?.circles.template.set("active", true);
  }, [activeCountForGraphView]);

  /**
   * This useEffect is used for selecting the occurence from highlighted node labels
   * It is dependant on activeCountForGraphView props.
   * When the drop arrow is pressed from Find Input, this useEffect is called.
   */
  //need to change the logic, and try to merge with activeCountForGraphView dependant useEffect() of highlighting circle
  useEffect(() => {
    let series;
    !orphanNodes ? (series = seriesa) : (series = series1);

    var myRegExp = new RegExp(`${findElementInGraphview}`, "gi");
    series?.labels.each(function (node) {
      if (
        node._parent.uid ===
        matchElementUIDInGraphview[activeCountForGraphView - 1]
      ) {
        if (findElementInGraphview !== "") {
          const temp = node
            .getText()
            .replace(myRegExp, function (match, capture) {
              return `[#FF7600][bold]` + match + `[/]`;
            });
          node.set("text", temp);
        }
      } else {
        if (findElementInGraphview !== "") {
          const temp = node
            .getText()
            .replace(myRegExp, function (match, capture) {
              return `[#FFB000][bold]` + match + `[/]`;
            });
          node.set("text", temp);
        }
      }
    });
  }, [activeCountForGraphView]);

  useLayoutEffect(() => {
    let root = am5.Root.new(uniqueDivId);

    // ...
    root.setThemes([am5themes_Animated.new(root)]);

    var container = root.container.children.push(
      am5.Container.new(root, {
        width: am5.percent(100),
        height: am5.percent(100),
        layout: root.verticalLayout,
      })
    );

    let series = container.children.push(
      am5hierarchy.Tree.new(root, {
        //   initialDepth: 1,
        // valueField: "value",
        categoryField: "name",
        idField: "name",
        childDataField: "children",
        linkWithField: "link",
        orientation: "horizontal",
        name: "text",
      })
    );

    setSeriesa(series); //setting the lacal state

    // node color
    //Right-Click event

    series.nodes.template.events.on("rightclick", function (ev) {
      handleRightClickOnNode(ev);
    });

    // Hide link
    // series.links.template.set("forceHidden", true);

    // node color
    series.circles.template.setAll({
      templateField: "nodeSettings",
    });

    //Outer Circle color
    series.outerCircles.template.setAll({
      templateField: "nodeSettings1",
    });

    // adding changes here reflets to all nodes
    series.links.template.setAll({
      templateField: "rootLinkColor",
      strokeWidth: 1,
      strokeOpacity: 0.3,
    });
    // used for hide and show sublevel node / toggel node
    series.nodes.template.setAll({
      toggleKey: "none",
      cursorOverStyle: "default",
    });

    // --------------------------------------------------------------------------------

    series.circles.template.events.on("click", function (ev) {
      setHideChildNode(ev.target);
    });

    // strokewidth change for test  original-strokeWidth: 1.5
    series.links.template.setAll({
      strokeWidth: 2,
    });
    // disable dragging
    series.nodes.template.setAll({
      draggable: false,
    });

    let data = seriesData;

    series.circles.template.setAll({
      radius: 25,
    });

    series.outerCircles.template.setAll({
      radius: 30,
    });

    /** this is from incoming */
    //Disabling built-in context menu
    root.addDisposer(
      am5.utils.addEventListener(root.dom, "contextmenu", function (ev) {
        ev.preventDefault();
      })
    );
    //Right-Click event
    series.nodes.template.events.on("rightclick", function (ev) {
      handleRightClickOnNode(ev);
    });
    /** this is from incoming end */
    // Set up labels
    series.labels.template.setAll({
      fontSize: nodeLabelFont,
      fill: am5.color(0x000000),
      y: -50,
      // x : 50,
      oversizedBehavior: "none",
      text: checkedShowLabel ? "{name}" : " ",
    });

    // labels color
    series.labels.template.setAll({
      templateField: "dataSet",
    });

    //Highlighting the node
    series.circles.template.states.create("highlight", {
      strokeOpacity: 8,
      stroke: am5.color("#FF7600"),
      strokeWidth: 6,
    });

    //Set the default value when highlighting the next one
    series.circles.template.states.create("default", {
      stroke: am5.color("#FFFFFF"),
    });

    series.nodes.template.setAll({
      // tooltipText: "", //Temp: For not showing tooltip
      // width: am5.percent(0), //Temp: For not showing tooltip

      tooltipText: "{route_name}",
      width: am5.percent(70),
      tooltipY: am5.percent(70),
      oversizedBehavior: "wrap",
    });

    var tooltip = am5.Tooltip.new(root, {
      autoTextColor: false,
      getFillFromSprite: false,
    });

    tooltip.get("background").setAll({
      fill: am5.color(0xffffff),
      stroke: am5.color(0x000000),
      strokeOpacity: 0.8,
    });

    tooltip.label.setAll({
      fill: am5.color(0x000000),
    });

    series.set("tooltip", tooltip);

    // Use template.setup function to prep up node with an image
    series.nodes.template.setup = function (target) {
      target.events.on("dataitemchanged", function (ev) {
        let icon = target.children.push(
          am5.Picture.new(root, {
            width: 25,
            height: 25,
            centerX: am5.percent(50),
            centerY: am5.percent(50),
            src: ev.target.dataItem.dataContext.image,
          })
        );
      });
    };

    // ---------------------default links
    series.links.template.adapters.add("stroke", function (fill, target) {
      return am5.color(0x707070);
    });

    //pointer hover on node links are highlighting

    series.nodes.template.events.on("pointerover", function (ev) {
      setSelectedNodeData(ev.target._dataItem.dataContext.name);
    });
    series.nodes.template.events.on("pointerout", function (ev) {
      setSelectedNodeData("");
    });

    // series.nodes.template.events.on("click", function (ev) {
    //   setSelectedSubNodeData((SelectedSubNodeData) => [
    //     ...SelectedSubNodeData,
    //     ev.target._dataItem.dataContext.name,
    //   ]);
    // });

    // // toggle node turn off
    // series.nodes.template.setAll({
    //   toggleKey: "none",
    //   cursorOverStyle: "default",
    // });

    series.data.setAll(data);

    root.current = root;

    return () => {
      root.dispose();
    };
  }, [seriesData]);

  //Creating one more tree graph for unused data and it is for only assets graphview
  useLayoutEffect(() => {
    if (
      !isComplianceView &&
      seriesOrphanNodeData &&
      seriesOrphanNodeData[0]?.isOrphanNodes !== false
    ) {
      let root = am5.Root.new("uniqueDivId");

      // ...
      root.setThemes([am5themes_Animated.new(root)]);

      var container = root.container.children.push(
        am5.Container.new(root, {
          width: am5.percent(100),
          height: am5.percent(100),
          layout: root.verticalLayout,
        })
      );

      let series = container.children.push(
        am5hierarchy.Tree.new(root, {
          //   initialDepth: 1,
          // valueField: "value",
          categoryField: "name",
          idField: "name",
          childDataField: "children",
          linkWithField: "link",
          orientation: "horizontal",
          name: "text",
        })
      );

      setSeries1(series); //setting the lacal state

      // node color
      //Right-Click event

      series.nodes.template.events.on("rightclick", function (ev) {
        handleRightClickOnNode(ev);
      });

      // Hide link
      // series.links.template.set("forceHidden", true);

      // node color
      series.circles.template.setAll({
        templateField: "nodeSettings",
      });

      //Outer Circle color
      series.outerCircles.template.setAll({
        templateField: "nodeSettings1",
      });

      // adding changes here reflets to all nodes
      series.links.template.setAll({
        templateField: "rootLinkColor",
        strokeWidth: 1,
        strokeOpacity: 0.3,
      });
      // used for hide and show sublevel node / toggel node
      series.nodes.template.setAll({
        toggleKey: "none",
        cursorOverStyle: "default",
      });

      // --------------------------------------------------------------------------------

      series.circles.template.events.on("click", function (ev) {
        setHideChildNode(ev.target);
      });

      // strokewidth change for test  original-strokeWidth: 1.5
      series.links.template.setAll({
        strokeWidth: 2,
      });
      // disable dragging
      series.nodes.template.setAll({
        draggable: false,
      });

      let data = seriesOrphanNodeData;

      series.circles.template.setAll({
        radius: 25,
      });

      series.outerCircles.template.setAll({
        radius: 30,
      });

      /** this is from incoming */
      //Disabling built-in context menu
      root.addDisposer(
        am5.utils.addEventListener(root.dom, "contextmenu", function (ev) {
          ev.preventDefault();
        })
      );
      //Right-Click event
      series.nodes.template.events.on("rightclick", function (ev) {
        handleRightClickOnNode(ev);
      });
      /** this is from incoming end */
      // Set up labels
      series.labels.template.setAll({
        fontSize: nodeLabelFont,
        fill: am5.color(0x000000),
        y: -50,
        // x : 50,
        oversizedBehavior: "none",
        text: checkedShowLabel ? "{name}" : " ",
      });

      // labels color
      series.labels.template.setAll({
        templateField: "dataSet",
      });

      //Highlighting the node
      series.circles.template.states.create("highlight", {
        strokeOpacity: 8,
        stroke: am5.color("#FF7600"),
        strokeWidth: 6,
      });

      //Set the default value when highlighting the next one
      series.circles.template.states.create("default", {
        stroke: am5.color("#FFFFFF"),
      });

      series.nodes.template.setAll({
        // tooltipText: "", //Temp: For not showing tooltip
        // width: am5.percent(0), //Temp: For not showing tooltip

        tooltipText: "{route_name}",
        width: am5.percent(90),
        tooltipY: am5.percent(90),
        oversizedBehavior: "wrap",
      });

      // tooltip.get("background").setAll({
      //   fill: am5.color(0xffffff),
      //   stroke: am5.color(0x000000),
      //   strokeOpacity: 0.8,
      // });
      // tooltip.label.setAll({
      //   fill: am5.color(0x000000),
      // });

      // series.set("tooltip", tooltip);

      // Use template.setup function to prep up node with an image
      series.nodes.template.setup = function (target) {
        target.events.on("dataitemchanged", function (ev) {
          let icon = target.children.push(
            am5.Picture.new(root, {
              width: 25,
              height: 25,
              centerX: am5.percent(50),
              centerY: am5.percent(50),
              src: ev.target.dataItem.dataContext.image,
            })
          );
        });
      };

      // ---------------------default links
      series.links.template.adapters.add("stroke", function (fill, target) {
        return am5.color(0x707070);
      });

      //pointer hover on node links are highlighting

      series.nodes.template.events.on("pointerover", function (ev) {
        setSelectedNodeData(ev.target._dataItem.dataContext.name);
      });
      series.nodes.template.events.on("pointerout", function (ev) {
        setSelectedNodeData("");
      });

      // series.nodes.template.events.on("click", function (ev) {
      //   setSelectedSubNodeData((SelectedSubNodeData) => [
      //     ...SelectedSubNodeData,
      //     ev.target._dataItem.dataContext.name,
      //   ]);
      // });

      // // toggle node turn off
      // series.nodes.template.setAll({
      //   toggleKey: "none",
      //   cursorOverStyle: "default",
      // });

      series.data.setAll(data);

      root.current = root;

      return () => {
        root.dispose();
      };
    }
  }, [seriesOrphanNodeData]);
  return (
    <div>
      {zoomInAndOut && (
        <>
          <Box
            id={uniqueDivId}
            className={
              orphanNodes
                ? "graphViewOrphanNodes"
                : seriesData[0]?.children[0]?.childAlign === true ||
                  seriesData[0]?.children[0]?.children[0]?.childAlign === true
                ? openCPOAnnotations || openSideBar
                  ? "cpographViewNodesWithSidePanel"
                  : "cpographViewNodes"
                : "graphViewNodes"
            }
            style={
              !orphanNodes
                ? {
                    // width: "120%", //For demo purpose commented
                    width: "158%",
                    height: `${amchartHeight}px`,
                    transform: `scale(${zoomInAndOut})`,
                    marginTop: `${zoomInAndOut > 1 ? amchartMarginTop : 0}vh`,
                    paddingTop: "2vh",
                    // paddingLeft: summeryPath !== "graphview" ? "" : "0vw",
                  }
                : { display: "none" }
            }
          ></Box>

          {!isComplianceView &&
            (seriesOrphanNodeData &&
            seriesOrphanNodeData[0]?.isOrphanNodes !== false ? (
              <Box
                id={"uniqueDivId"}
                className={
                  orphanNodes ? "graphViewOrphanNodes" : "graphViewNodes"
                }
                style={
                  orphanNodes
                    ? {
                        // width: "120%",//For demo purpose commented
                        width: "158%",
                        height:
                          amchartOrphanNodeHeight == 0
                            ? `30vh`
                            : `${amchartOrphanNodeHeight}vh`,

                        transform: `scale(${zoomInAndOut})`,
                        marginTop: "2rem",
                        // paddingT: "5vh",
                      }
                    : { display: "none" }
                }
              ></Box>
            ) : (
              orphanNodes &&
              !isUnUsedNodeData && (
                <>
                  <img
                    src={NoResultImg}
                    alt="noResult"
                    className="AmchartNoresultImg"
                  />
                  <h3 className="orphanNodeStatus">
                    No Unused Assets to show.
                  </h3>
                </>
              )
            ))}
        </>
      )}
    </div>
  );
};

export default AmchartGraphview;
