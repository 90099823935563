import { PolicyNameConst } from "./policyConstants";

export const tablelHedrsKeyVal = {
  name: "Name",
  konfer_confidence: "Konfer Confidence",
  konfer_confidence: PolicyNameConst.konfer_confidence_wo_tm,
  human_impact: "Human Impact",
  policy_score: "Policy Score",
  version: "Version",
  aws_region: "Deployment Region",
  regions: "Region",
  business_units: "Business Unit",
  algorithm_type: "Algorithm Type",
  source_type: "Source Type",
  usage_type: "Usage Type",
  created_at: "Created At",
  calculate_score: "Score Inclusion",
};

export const appsColoumsConstants = (
  isShowScoreInclusion,
  isCardButtonShow
) => {
  const apps_columns_const = [
    {
      field: "name",
      headerName: "Name",
      minWidth: 250,
      sortable: false,
      descending: true,
      checked: true,
      position: "sticky",
      left: 0,
      dropDown: false,
      slider: false,
    },
    isShowScoreInclusion && {
      field: "calculate_score",
      headerName: "Score Inclusion",
      minWidth: 100,
      sortable: false,
      descending: true,
      checked: true,
      position: "sticky",
      left: 0,
      dropDown: false,
      slider: true,
    },
    {
      field: "konfer_confidence",
      headerName: PolicyNameConst.konfer_confidence_wo_tm,
      minWidth: 100,
      sortable: true,
      descending: true,
      checked: true,
      position: "unset",
      left: "",
      dropDown: false,
      slider: false,
    },
    // {
    //   field: "human_impact",
    //   headerName: "Human Impact",
    //   minWidth: 85,
    //   sortable: true,
    //   descending: true,
    //   checked: true,
    //   position: "unset",
    //   left: "",
    //   dropDown: false,
    //slider:false
    // },

    // {
    //   field: "policy_score",
    //   headerName: "Policy Score",
    //   sortable: true,
    //   descending: true,
    //   minWidth: 100,
    //   checked: true,
    //   position: "unset",
    //   left: "",
    //   dropDown: true,
    //slider:false,
    // },

    {
      field: "version",
      headerName: "Version",
      sortable: false,
      descending: true,
      minWidth: 60,
      checked: true,
      position: "unset",
      left: "",
      dropDown: false,
      slider: false,
    },
    {
      field: "aws_region",
      headerName: "Deployment Region",
      sortable: false,
      descending: true,
      minWidth: 150,
      checked: false,
      position: "unset",
      left: "",
      dropDown: false,
      slider: false,
    },
    {
      field: "regions",
      headerName: "Region",
      sortable: false,
      descending: true,
      minWidth: 100,
      checked: false,
      position: "unset",
      left: "",
      dropDown: false,
      slider: false,
    },
    {
      field: "business_units",
      headerName: "Business Unit",
      sortable: false,
      descending: true,
      minWidth: 100,
      checked: false,
      position: "unset",
      left: "",
      dropDown: false,
      slider: false,
    },
    {
      field: "created_at",
      headerName: "Created At",
      sortable: false,
      descending: true,
      minWidth: 100,
      checked: true,
      position: "unset",
      left: "",
      dropDown: false,
      slider: false,
    },
    isCardButtonShow && {
      field: "card",
      sortable: false,
      descending: true,
      minWidth: 50,
      checked: true,
      position: "unset",
      left: "",
      dropDown: false,
      slider: false,
    },
  ];

  return apps_columns_const;
};

export const appsColoumsProfileConstants = (
  isProfileButtonShow,
  isShowScoreInclusion,
  isCardButtonShow
) => {
  const apps_columns_const_profile = [
    {
      field: "name",
      headerName: "Name",
      minWidth: 250,
      sortable: false,
      descending: true,
      checked: true,
      position: "sticky",
      left: 0,
      dropDown: false,
      slider: false,
    },
    isShowScoreInclusion && {
      field: "calculate_score",
      headerName: "Score Inclusion",
      minWidth: 100,
      sortable: false,
      descending: true,
      checked: true,
      position: "sticky",
      left: 0,
      dropDown: false,
      slider: true,
    },
    {
      field: "konfer_confidence",
      headerName: PolicyNameConst.konfer_confidence_wo_tm,
      minWidth: 100,
      sortable: true,
      descending: true,
      checked: true,
      position: "unset",
      left: "",
      dropDown: false,
      slider: false,
    },
    // {
    //   field: "human_impact",
    //   headerName: "Human Impact",
    //   minWidth: 85,
    //   sortable: true,
    //   descending: true,
    //   checked: true,
    //   position: "unset",
    //   left: "",
    //   dropDown: false,
    //slider:false
    // },

    // {
    //   field: "policy_score",
    //   headerName: "Policy Score",
    //   sortable: true,
    //   descending: true,
    //   minWidth: 100,
    //   checked: true,
    //   position: "unset",
    //   left: "",
    //   dropDown: true,
    //slider:false
    // },

    {
      field: "version",
      headerName: "Version",
      sortable: false,
      descending: true,
      minWidth: 60,
      checked: true,
      position: "unset",
      left: "",
      dropDown: false,
      slider: false,
    },
    {
      field: "aws_region",
      headerName: "Deployment Region",
      sortable: false,
      descending: true,
      minWidth: 150,
      checked: false,
      position: "unset",
      left: "",
      dropDown: false,
      slider: false,
    },
    {
      field: "regions",
      headerName: "Region",
      sortable: false,
      descending: true,
      minWidth: 100,
      checked: false,
      position: "unset",
      left: "",
      dropDown: false,
      slider: false,
    },
    {
      field: "business_units",
      headerName: "Business Unit",
      sortable: false,
      descending: true,
      minWidth: 100,
      checked: false,
      position: "unset",
      left: "",
      dropDown: false,
      slider: false,
    },
    {
      field: "created_at",
      headerName: "Created At",
      sortable: false,
      descending: true,
      minWidth: 100,
      checked: true,
      position: "unset",
      left: "",
      dropDown: false,
      slider: false,
    },
    isProfileButtonShow && {
      field: "profile",
      sortable: false,
      descending: true,
      minWidth: 100,
      checked: true,
      position: "unset",
      left: "",
      dropDown: false,
      slider: false,
    },
    isCardButtonShow && {
      field: "card",
      sortable: false,
      descending: true,
      minWidth: 50,
      checked: true,
      position: "unset",
      left: "",
      dropDown: false,
      slider: false,
    },
  ];

  return apps_columns_const_profile;
};

export const modelColumnsConstants = (isCardButtonShow) => {
  const models_columns_const = [
    {
      field: "name",
      headerName: "Name",
      minWidth: 250,
      sortable: false,
      descending: true,
      checked: true,
      position: "sticky",
      left: 0,
      dropDown: false,
      slider: false,
    },
    {
      field: "konfer_confidence",
      headerName: "Konfer Confidence",
      minWidth: 100,
      sortable: true,
      descending: true,
      checked: true,
      position: "unset",
      left: "",
      dropDown: false,
      slider: false,
    },
    // {
    //   field: "human_impact",
    //   headerName: "Human Impact",
    //   minWidth: 85,
    //   sortable: true,
    //   descending: true,
    //   checked: true,
    //   position: "unset",
    //   left: "",
    //   dropDown: false,
    //slider:false
    // },

    // {
    //   field: "policy_score",
    //   headerName: "Policy Score",
    //   sortable: true,
    //   descending: true,
    //   minWidth: 100,
    //   checked: true,
    //   position: "unset",
    //   left: "",
    //   dropDown: true,
    //slider:false
    // },

    {
      field: "version",
      headerName: "Version",
      sortable: false,
      descending: true,
      minWidth: 60,
      checked: true,
      position: "unset",
      left: "",
      dropDown: false,
      slider: false,
    },
    {
      field: "aws_region",
      headerName: "Deployment Region",
      sortable: false,
      descending: true,
      minWidth: 150,
      checked: false,
      position: "unset",
      left: "",
      dropDown: false,
      slider: false,
    },
    {
      field: "regions",
      headerName: "Region",
      sortable: false,
      descending: true,
      minWidth: 100,
      checked: true,
      position: "unset",
      left: "",
      dropDown: false,
      slider: false,
    },
    {
      field: "business_units",
      headerName: "Business Unit",
      sortable: false,
      descending: true,
      minWidth: 100,
      checked: true,
      position: "unset",
      left: "",
      dropDown: false,
      slider: false,
    },
    {
      field: "created_at",
      headerName: "CreatedAt",
      sortable: false,
      descending: true,
      minWidth: 100,
      checked: true,
      position: "unset",
      left: "",
      dropDown: false,
      slider: false,
    },
    {
      field: "algorithm_type",
      headerName: "Algorithm Type",
      sortable: false,
      descending: true,
      minWidth: 150,
      checked: true,
      position: "unset",
      left: "",
      dropDown: false,
      slider: false,
    },
    isCardButtonShow && {
      field: "card",
      sortable: false,
      descending: true,
      minWidth: 50,
      checked: true,
      position: "unset",
      left: "",
      dropDown: false,
      slider: false,
    },
  ];
  return models_columns_const;
};

export const datasetsColumnConstants = (isCardButtonShow) => {
  const datasets_columns_const = [
    {
      field: "name",
      headerName: "Name",
      minWidth: 250,
      sortable: false,
      descending: true,
      checked: true,
      position: "sticky",
      left: 0,
      dropDown: false,
      slider: false,
    },
    {
      field: "konfer_confidence",
      headerName: "Konfer Confidence",
      minWidth: 100,
      sortable: true,
      descending: true,
      checked: true,
      position: "unset",
      left: "",
      dropDown: false,
      slider: false,
    },
    // {
    //   field: "human_impact",
    //   headerName: "Human Impact",
    //   minWidth: 85,
    //   sortable: true,
    //   descending: true,
    //   checked: true,
    //   position: "unset",
    //   left: "",
    //   dropDown: false,
    //slider:false
    // },

    // {
    //   field: "policy_score",
    //   headerName: "Policy Score",
    //   sortable: true,
    //   descending: true,
    //   minWidth: 100,
    //   checked: true,
    //   position: "unset",
    //   left: "",
    //   dropDown: true,
    // slider:false
    // },

    {
      field: "version",
      headerName: "Version",
      sortable: false,
      descending: true,
      minWidth: 60,
      checked: true,
      position: "unset",
      left: "",
      dropDown: false,
      slider: false,
    },
    {
      field: "aws_region",
      headerName: "Deployment Region",
      sortable: false,
      descending: true,
      minWidth: 150,
      checked: false,
      position: "unset",
      left: "",
      dropDown: false,
      slider: false,
    },
    {
      field: "regions",
      headerName: "Region",
      sortable: false,
      descending: true,
      minWidth: 100,
      checked: true,
      position: "unset",
      left: "",
      dropDown: false,
      slider: false,
    },
    {
      field: "business_units",
      headerName: "Business Unit",
      sortable: false,
      descending: true,
      minWidth: 100,
      checked: true,
      position: "unset",
      left: "",
      dropDown: false,
      slider: false,
    },
    {
      field: "source_type",
      headerName: "Source Type",
      sortable: false,
      descending: true,
      minWidth: 100,
      checked: true,
      position: "unset",
      left: "",
      dropDown: false,
      slider: false,
    },
    {
      field: "usage_type",
      headerName: "Usage Type",
      sortable: false,
      descending: true,
      minWidth: 100,
      checked: true,
      position: "unset",
      left: "",
      dropDown: false,
      slider: false,
    },
    isCardButtonShow && {
      field: "card",
      sortable: false,
      descending: true,
      minWidth: 50,
      checked: true,
      position: "unset",
      left: "",
      dropDown: false,
      slider: false,
    },
  ];
  return datasets_columns_const;
};

export const include_columns_for_internal_find = [
  "name",
  "konfer_confidence",
  "human_impact",
  "policy_score",
  "version",
  "aws_region",
  "regions",
  "business_units",
  "algorithm_type",
  "source_type",
  "usage_type",
];
