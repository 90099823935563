import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableFooter,
  TablePagination,
  Tooltip,
} from "@mui/material";
import {
  IconButton,
  Box,
  useTheme,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
} from "@mui/material";
import {
  CloseOutlined,
  FirstPage,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  LastPage,
  Padding,
  Search,
} from "@mui/icons-material";
import "./AdminUsetlistUserTable.css";
import NoResult from "../NoResult/NoResult";
import Edit from "../../assets/images/adminPanel/Edit.svg";
import EditDisabled from "../../assets/images/adminPanel/EditDisabled.svg";
import Delete from "../../assets/images/adminPanel/Delete.svg";
import DeleteDisabled from "../../assets/images/adminPanel/DeleteDisabled.svg";
import DeleteIcon from "../../assets/images/detail/delete_annotation.svg";
import CancelIcon from "../../assets/images/adminPanel/CancelIcon.svg";
import { useDeleteUser } from "../../api/deleteApiFunctions";
import { toast, ToastContainer } from "react-toastify";
import { dateFormate } from "../../utils/basicUtils";
import AdminNewUserDialog from "../AdminNewUserDialog/AdminNewUserDialog";
import { analyticServices } from "../../services/analyticServices";
import ListPopover from "../ListPopover/ListPopover";
import { isAccessProvided } from "../../services/assetManagementService";
import {
  moduleConstants,
  subModuleConstants,
} from "../../constants/accessManagementConstants";

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box
      sx={{ flexShrink: 0, ml: 2.5 }}
      id="adminUserlistTablePaginationActions"
    >
      <IconButton
        id="adminUserlistFirstBtnClick"
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPage /> : <FirstPage />}
      </IconButton>
      <IconButton
        id="adminUserlistBackBtnClick"
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        id="adminUserlistNextBtnClick"
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        id="adminUserlistLastBtnClick"
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPage /> : <LastPage />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const AdminUsetlistUserTable = ({
  userlistData,
  accessDataByRole,
  userlistRefetch,
}) => {
  let a = window.innerHeight <= 760 ? 5 : window.innerHeight <= 900 ? 6 : 8;
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(a);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false); // to show a confirmation dialog to delete an user
  const [openUpdateUserDialog, setOpenUpdateUserDialog] = useState(false); // to show a create new user dialog
  const [deleteUserContributerId, setDeleteUserContributerId] = useState(null);
  const { mutate: deleteUser } = useDeleteUser();
  const [userDataForEditUser, setUserDataForEditUser] = useState(null);

  const [openBusinessunitListPopover, setOpenBusinessunitListPopover] =
    useState(); // to show a business list

  const [openRegionListPopover, setOpenRegionListPopover] = useState(); // to show a business list

  const [isRegion, setIsRegion] = useState(false);
  const [displayData, setDisplayData] = useState([]);

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0
      ? Math.max(
          0,
          (1 + page) * rowsPerPage - userlistData?.contributor?.length
        )
      : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleDeleteConfirmationDialog = (contributor_id) => {
    setDeleteUserContributerId(contributor_id);
    setOpenDeleteDialog(true);
  };

  const handleEditUser = (userData) => {
    setOpenUpdateUserDialog(true);
    setUserDataForEditUser(userData);
  };

  const handleDeleteUser = () => {
    const payload = {
      contributerId: deleteUserContributerId,
    };
    deleteUser(payload);
    setOpenDeleteDialog(false);
    toast.success("User deleted") &&
      userlistData.map((userData) => {
        userData.contributor_id === deleteUserContributerId &&
          analyticServices(` ${userData.name}, user deleted`);
      });
  };

  return (
    <div>
      <Table
        aria-label="simple table"
        sx={{ tableLayout: "fixed" }}
        id="adminUserlistTable"
      >
        <TableHead>
          <TableRow className="usertable-row-headings">
            <TableCell className="widthColoumn usertable-coloumn-headings">
              Username
            </TableCell>
            <TableCell className="usertable-coloumn-headings">
              Email ID
            </TableCell>
            <TableCell className="widthColoumn usertable-coloumn-headings">
              Role
            </TableCell>
            <TableCell className="usertable-coloumn-headings">
              Regions
            </TableCell>
            <TableCell className="usertable-coloumn-headings">
              Business Units
            </TableCell>
            <TableCell className="usertable-coloumn-headings">
              {" "}
              Last Modified Date
            </TableCell>
            {(isAccessProvided(
              moduleConstants.admin_view,
              subModuleConstants.users,
              null,
              "update",
              accessDataByRole
            ) === true ||
              isAccessProvided(
                moduleConstants.admin_view,
                subModuleConstants.users,
                null,
                "delete",
                accessDataByRole
              ) === true) && (
              <TableCell className="usertable-coloumn-headings"></TableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {userlistData &&
            userlistData?.length > 0 &&
            (rowsPerPage > 0
              ? userlistData.slice(
                  page * rowsPerPage,
                  page * rowsPerPage + rowsPerPage
                )
              : userlistData
            ).map((userData, index) => (
              <>
                <TableRow
                  id={`adminUserlistTableRow${index}`}
                  key={userData.contributor_id}
                  sx={{
                    "&:hover": {
                      cursor: "pointer",
                      backgroundColor: "var(--bacgroundColor) !important",
                    },
                  }}
                >
                  <Tooltip title={userData?.name}>
                    <TableCell className="usertable-coloumn text-ellipsis">
                      {userData.name}
                    </TableCell>
                  </Tooltip>
                  <Tooltip title={userData.email}>
                    <TableCell className="usertable-coloumn text-ellipsis">
                      {userData.email}
                    </TableCell>
                  </Tooltip>
                  <TableCell className="usertable-coloumn">
                    {userData.role}
                  </TableCell>
                  <TableCell className="usertable-coloumn">
                    {userData.contributerOnRegion?.map((regionData, index) => {
                      return (
                        <>
                          {(() => {
                            if (index < 2) {
                              return (
                                <span className="tt">
                                  {" "}
                                  {regionData.region.name}{" "}
                                </span>
                              );
                            }
                          })()}
                          {(() => {
                            // changed
                            if (
                              index + 1 ===
                                userData.contributerOnRegion.length &&
                              userData.contributerOnRegion.length > 2
                            ) {
                              return (
                                <>
                                  <span
                                    className="tt-without-name"
                                    // id={`regionList${index}`}
                                    onClick={(e) => {
                                      setOpenRegionListPopover(e.currentTarget);
                                      setIsRegion(true);
                                      setDisplayData(
                                        userData.contributerOnRegion
                                      );
                                    }}
                                  >
                                    +{userData.contributerOnRegion.length - 2}
                                  </span>
                                </>
                              );
                            }
                          })()}
                        </>
                      );
                    })}
                    {userData?.contributerOnRegion.length === 0 && "-"}
                  </TableCell>

                  <TableCell className="usertable-coloumn">
                    {userData.contributors_business_units?.map(
                      (businessunitData, index) => {
                        return (
                          <>
                            {(() => {
                              if (index < 2) {
                                return (
                                  <span className="tt">
                                    {" "}
                                    {businessunitData.business_unit.name}{" "}
                                  </span>
                                );
                              }
                            })()}
                            {(() => {
                              // changed
                              if (
                                index + 1 ===
                                  userData.contributors_business_units.length &&
                                userData.contributors_business_units.length > 2
                              ) {
                                return (
                                  <>
                                    <span
                                      className="tt-without-name"
                                      onClick={(e) => {
                                        setOpenBusinessunitListPopover(
                                          e.currentTarget
                                        );
                                        setIsRegion(false);
                                        setDisplayData(
                                          userData.contributors_business_units
                                        );
                                      }}
                                    >
                                      +
                                      {userData.contributors_business_units
                                        .length - 2}
                                    </span>
                                  </>
                                );
                              }
                            })()}
                          </>
                        );
                      }
                    )}

                    {userData?.contributors_business_units.length === 0 && "-"}
                  </TableCell>
                  <TableCell className="usertable-coloumn">
                    {userData.updatedAt ? dateFormate(userData.updatedAt) : "-"}
                  </TableCell>
                  {(isAccessProvided(
                    moduleConstants.admin_view,
                    subModuleConstants.users,
                    null,
                    "update",
                    accessDataByRole
                  ) === true ||
                    isAccessProvided(
                      moduleConstants.admin_view,
                      subModuleConstants.users,
                      null,
                      "delete",
                      accessDataByRole
                    ) === true) && (
                    <TableCell className="usertable-coloumn">
                      {isAccessProvided(
                        moduleConstants.admin_view,
                        subModuleConstants.users,
                        null,
                        "update",
                        accessDataByRole
                      ) === true && (
                        <IconButton
                          id={`adminUserlistEditIconButton${index}`}
                          type="submit"
                          aria-label="Edit"
                          style={{ padding: "5px" }}
                          onClick={() => {
                            handleEditUser(userData);
                          }}
                          // disabled={userData.role === "Admin"}
                        >
                          <img
                            // src={userData.role === "Admin" ? EditDisabled : Edit}
                            src={Edit}
                            alt="Edit"
                            style={{ width: "28px" }}
                          />
                        </IconButton>
                      )}

                      {isAccessProvided(
                        moduleConstants.admin_view,
                        subModuleConstants.users,
                        null,
                        "delete",
                        accessDataByRole
                      ) === true && (
                        <IconButton
                          id={`adminUserlistDeleteIconButton${index}`}
                          type="submit"
                          aria-label="Delete"
                          style={{ padding: "5px" }}
                          onClick={() => {
                            handleDeleteConfirmationDialog(
                              userData.contributor_id
                            );
                          }}
                          disabled={
                            userData.role === "Admin" ||
                            userData.role === "SuperAdmin"
                          }
                        >
                          <img
                            src={
                              userData.role === "Admin" ||
                              userData.role === "SuperAdmin"
                                ? DeleteDisabled
                                : Delete
                            }
                            alt="Delete"
                            style={{ width: "28px" }}
                          />
                        </IconButton>
                      )}
                    </TableCell>
                  )}
                </TableRow>
              </>
            ))}
        </TableBody>
        <TableFooter>
          <TableRow
            sx={{
              "&:last-child td, &:last-child th": {
                border: 0,
                paddingRight: "50px",
              },
            }}
          >
            <TablePagination
              rowsPerPageOptions={[a]}
              colSpan={6}
              count={userlistData?.length || 0}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />
          </TableRow>
        </TableFooter>
      </Table>

      <Dialog
        id="adminUserlistDeleteConfirmation"
        open={openDeleteDialog}
        onClose={() => {
          setOpenDeleteDialog(!openDeleteDialog);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <IconButton
            id="adminUserlistCloseDeleteDialog"
            aria-label="close"
            onClick={() => {
              setOpenDeleteDialog(false);
            }}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <img src={CancelIcon} alt="Delete" />
          </IconButton>
          <p> {"Are you sure you want to delete this user?"} </p>
        </DialogTitle>
        <DialogActions>
          <Button
            id="adminUserlistCancelDeleteDialog"
            className="cancel-deleteUser-dialog"
            value="Cancel"
            onClick={() => {
              setOpenDeleteDialog(false);
            }}
            autoFocus
          >
            <span className="deleteUser-dialog-title">Cancel</span>
          </Button>
          <Button
            id="adminUserlistConfirmDelete"
            className="delete-deleteUser-dialog"
            value="Delete"
            onClick={() => {
              handleDeleteUser();
            }}
          >
            <span className="deleteUser-dialog-title">Delete</span>
          </Button>
        </DialogActions>
      </Dialog>

      {(openBusinessunitListPopover || openRegionListPopover) && (
        <ListPopover
          openBusinessunitListPopover={openBusinessunitListPopover}
          setOpenBusinessunitListPopover={setOpenBusinessunitListPopover}
          openRegionListPopover={openRegionListPopover}
          setOpenRegionListPopover={setOpenRegionListPopover}
          displayData={displayData}
          isRegion={isRegion}
        />
      )}

      {openUpdateUserDialog && (
        <AdminNewUserDialog
          openNewUserDialog={openUpdateUserDialog}
          setOpenNewUserDialog={setOpenUpdateUserDialog}
          userDataForEditUser={userDataForEditUser}
          edit={true}
          userlistRefetch={userlistRefetch}
        />
      )}
    </div>
  );
};

export default AdminUsetlistUserTable;
