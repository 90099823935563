import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";

import "../../ListviewCard/ListviewCard.css";

import { mchartDataFormat } from "../../../services/detailsServices";
import modelCardIcon from "../../../assets/images/listview/modelCard.svg";
import { IconButton, Tooltip } from "@mui/material";
import { useAtom } from "jotai";
import {
  appIdForOpeningSummarViewAtom,
  businessUnitName,
  isComplianceSummaryView,
  regionIDAtom,
  regionNameAtom,
  selectedBusinessUnit,
} from "../../../atom/jotai";
import { analyticServices } from "../../../services/analyticServices";
import AmchartThumbnailNew from "../../AmchartComponents/AmchartThumbnailNew";
import { dateFormate } from "../../../utils/basicUtils";
import { dataTypes, typeNames } from "../../../constants/dataTypes";
import {
  complianceTagName,
  complianceTagValue,
} from "../../../constants/complianceConstants";
import HighLighter from "../../Highlighter/HightLigthter";
import { setTableData } from "../../../services/listviewServices";
import { useCreateDatasetCardFromTemplate } from "../../../api/createApiFunctions";
import WarningDialogForCard from "../../WarningDialogForCard/WarningDialogForCard";
import WarningIconForCard from "../../../assets/images/listview/WarningIconForCard.svg";
import HighlightText from "../../HOC/HighlightText";
import { riskFactorValueCond } from "../../../constants/riskFactorMonth";
import Cards from "../../Cards/CardsNew";

const ListviewComplianceCard = ({
  appData,
  index,
  findElement,
  keywords,
  scoresSelectedPolicy,
}) => {
  const { searchText } = useParams();

  const nodeSizes = {
    rootNodeSize: 500,
    firstChildSize: 500,
    secondChildSize: 100,
    thirdChildSize: 50,
  };

  const [convertedData, setConvertedData] = useState(null);
  const [currentData, setCurrentData] = useState(null);
  const [showCard, setShowCard] = useState(false);
  const [isSummaryView, setIsSummaryView] = useAtom(isComplianceSummaryView);
  const [selectedBuId, setSelectedBusiness] = useAtom(selectedBusinessUnit);
  const [, setAppIdForOpeningSummarView] = useAtom(
    appIdForOpeningSummarViewAtom
  );
  //   const [showModelCard, setShowModelCard] = useState(false);
  //   const [showDatasetCard, setShowDatasetCard] = useState(false);
  //   const [cardType, setCardType] = useState("");

  const [businessName] = useAtom(businessUnitName);
  const [regionID, setRegionID] = useAtom(regionIDAtom);
  const [regionName] = useAtom(regionNameAtom);
  const [listCardData, setListCardData] = useState();
  const [listCardId, setListCardId] = useState();
  const [showDatasetCard, setShowDatasetCard] = useState(false);
  const [cardType, setCardType] = useState("");
  const [openWarningDialogForCard, setOpenWarningDialogForCard] =
    useState(false);
  const [callCreateCardAPI, setCallCreateCardAPI] = useState(false);

  const {
    mutate: createDatasetCardFromTemplate,
    isSuccess,
    data: datasetCardTemplateRes,
    isLoading,
  } = useCreateDatasetCardFromTemplate();

  useEffect(() => {
    const res = [];
    if (appData) {
      setCurrentData(
        setTableData(
          mchartDataFormat([appData], true)[0],
          dataTypes.apps,
          scoresSelectedPolicy
        )
      );

      res.push(appData);
      setConvertedData(res);
    }
  }, [appData]);

  useEffect(() => {
    if (callCreateCardAPI === true) {
      let id;
      if (currentData.__typename === typeNames.apps) {
        id = currentData.app_id;
      }
      let tempObj = { ...currentData };
      tempObj.card_template_version = tempObj.card_latest_version;
      setCurrentData(tempObj);
      createDatasetCardFromTemplate({
        id: id,
        type: currentData?.__typename,
      });
      setCallCreateCardAPI(false);
      setOpenWarningDialogForCard(false);
      setShowCard(true);
    }
  }, [callCreateCardAPI]);

  const getLinkRoute = (data) => {
    let link = "/";
    if (data.__typename === typeNames.apps) {
      link = `/detail/app/${data.app_id}`;
    }
    return link;
  };

  const listViewTrack = (currentData) => {
    analyticServices(
      `Selected Type : ${currentData?.__typename}/${currentData?.name} `
    );
  };

  const showdatasetCard = () => {
    setShowCard(true);
    // setShowDatasetCard(true);
  };

  const handleCallDatasetApi = () => {
    let id;
    if (currentData.card_id === null) {
      if (currentData.__typename === typeNames.apps) {
        id = currentData.app_id;
      }

      createDatasetCardFromTemplate({
        id: id,
        type: currentData?.__typename,
      });
      setShowCard(true);
      setOpenWarningDialogForCard(false);
    } else {
      if (
        currentData.card_latest_version === currentData.card_template_version
      ) {
        setShowCard(true);
        setOpenWarningDialogForCard(false);
      } else {
        setShowCard(false);
        setOpenWarningDialogForCard(true);
      }
    }
    setShowCard(true)
  };

  useEffect(() => {
    if (isSuccess && datasetCardTemplateRes !== undefined) {
      let tempObj = { ...currentData };

      tempObj.card_id =
        datasetCardTemplateRes !== null
          ? datasetCardTemplateRes.data.card_id
          : null;
      setCurrentData(tempObj);

      setShowCard(true);
      setOpenWarningDialogForCard(false);
    }
  }, [isSuccess]);

  //  used for show loading status while loading
  if (!currentData && index === 0) {
    if (appData) {
      return <h2>Loading apps</h2>;
    }
  }

  const handleSetCurrentData = (currentVal) => {
    setCurrentData((prevState) => ({
      ...prevState,
      cardData : currentVal
    }))
  }

  return (
    <>
      {currentData && (
        <Link
          onClick={() => listViewTrack(currentData)}
          to={getLinkRoute(currentData)}
          className="link_color"
        >
          <div
            className={`card_container ${currentData.borderColor}`}
            id={`${index}scrollToHighlighter`}
          >
            {/* <div className="table_item_chart">
              {convertedData && (
                <AmchartThumbnailNew selectedData={convertedData} />
              )}
            </div> */}

            <Tooltip title={currentData?.originalName}>
              <div
                className="table_item truncate-text"
                style={{
                  marginLeft: "25px",
                }}
              >
                {!findElement ? (
                  <HighlightText
                    // keywords={keywords}
                    text={currentData.name}
                  />
                ) : (
                  <HighLighter
                    text={currentData?.name}
                    highlight={findElement}
                    columnName={`name`}
                    index={index}
                    findElement={findElement}
                  />
                )}
              </div>
            </Tooltip>

            <div className="table_item_score">
              {currentData?.konferConfidence === null ||
              currentData?.konferConfidence === undefined ||
              currentData?.konferConfidence ===
                riskFactorValueCond.ntAplicabl ? (
                <span style={{ marginLeft: "30%" }}>-</span>
              ) : (
                <div style={{ marginLeft: "30%" }}>
                  <HighLighter
                    text={`${Math.trunc(currentData.konferConfidence)}`}
                    highlight={findElement}
                    columnName={`konferConfidence`}
                    index={index}
                    findElement={findElement}
                  />
                </div>
              )}
            </div>

            <div className="table_item_score">
              <span style={{ marginLeft: "30%" }}>-</span>
            </div>

            <div className="table_item_score">
              {currentData?.humanImpact === null ||
              currentData?.humanImpact === undefined ||
              currentData?.humanImpact === riskFactorValueCond.ntAplicabl ? (
                <span style={{ marginLeft: "30%" }}>-</span>
              ) : (
                <div style={{ marginLeft: "30%" }}>
                  <HighLighter
                    text={`${currentData.humanImpact}`}
                    highlight={findElement}
                    columnName={`humanImpact`}
                    index={index}
                    findElement={findElement}
                  />
                </div>
              )}
            </div>

            <div
              className="table_item"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                // border: "1px solid",
              }}
            >
              {/* {currentData.version === "" ? "-" : currentData.version} */}
              {currentData.version === "" || currentData.version === null ? (
                "-"
              ) : (
                <>
                  {!findElement ? (
                    <HighlightText
                      // keywords={keywords}
                      text={currentData.version}
                    />
                  ) : (
                    <HighLighter
                      text={currentData.version}
                      highlight={findElement}
                      columnName={`version`}
                      index={index}
                      findElement={findElement}
                    />
                  )}
                </>
              )}
            </div>

            <div className="table_item">
              {currentData.aws_region === "" ||
              currentData.aws_region === null ? (
                <span style={{ marginLeft: "30%" }}>-</span>
              ) : (
                <HighLighter
                  text={currentData.aws_region}
                  highlight={findElement}
                  columnName={`aws_region`}
                  index={index}
                  findElement={findElement}
                />
              )}
            </div>

            {regionName === complianceTagName.AllRegionsTag && (
              <div className="table_item">
                {!currentData.region ? (
                  <span style={{ marginLeft: "30%" }}>-</span>
                ) : (
                  <>
                    {!findElement ? (
                      <HighlightText
                        // keywords={keywords}
                        text={currentData.region}
                      />
                    ) : (
                      <HighLighter
                        text={currentData.region}
                        highlight={findElement}
                        columnName={`region`}
                        index={index}
                        findElement={findElement}
                      />
                    )}
                  </>
                )}
              </div>
            )}

            {(selectedBuId[0] === undefined ||
              selectedBuId[0] === complianceTagValue.AllBusinessUnis) && (
              <div className="table_item">
                {!currentData.business_unit ? (
                  <span style={{ marginLeft: "30%" }}>-</span>
                ) : (
                  <>
                    {!findElement ? (
                      <HighlightText
                        // keywords={keywords}
                        text={currentData.business_unit}
                      />
                    ) : (
                      <HighLighter
                        text={currentData.business_unit}
                        highlight={findElement}
                        columnName={`business_unit`}
                        index={index}
                        findElement={findElement}
                      />
                    )}
                  </>
                )}
              </div>
            )}

            {/* <div className="table_item">
              {currentData.created_at !== false ? (
                <>
                  {!findElement ? (
                    <HighlightText
                      // keywords={keywords}
                      text={currentData.created_at}
                    />
                  ) : (
                    <HighLighter
                      text={currentData.created_at}
                      highlight={findElement}
                      columnName={`created_at`}
                      index={index}
                      findElement={findElement}
                    />
                  )}
                </>
              ) : (
                <span style={{ marginLeft: "30%" }}>-</span>
              )}
            </div> */}
            {/* <div className="table_item">
              {currentData.created_at !== false ? currentData.created_at : "-"}
            </div> */}

            <Link
              style={{ textDecorationColor: "#ffffff" }}
              to={`/compliance-result/compliance-summary/${searchText}/${
                currentData.region_id !== null ||
                currentData.region_id !== undefined
                  ? currentData.region_id
                  : complianceTagValue.AllRegions
              }/${
                currentData.business_unit_id !== null ||
                currentData.business_unit_id !== undefined
                  ? currentData.business_unit_id
                  : complianceTagValue.AllBusinessUnis
              }/${currentData.app_id}`}
            >
              <div
                className="card_child"
                onClick={() => {
                  setIsSummaryView(true);
                  setAppIdForOpeningSummarView(currentData.app_id);
                }}
              >
                <div className="model_card_button">
                  <p>Profile</p>
                </div>
              </div>
            </Link>

            <Link
              style={{
                textDecorationColor: "#ffffff",
                display: "flex",
                width: "95%",
              }}
              to={`/compliance-result/compliance-listview/${searchText}`}
            >
              <div
                className="card_child"
                onClick={() => {
                  // setShowCard(true);
                  setCardType(currentData.__typename);
                  setListCardData(currentData);
                  setListCardId(currentData?.card_id);
                  handleCallDatasetApi();
                }}
              >
                <div className="model_card_button">
                  <p>Card</p>
                </div>
              </div>
              {currentData.card_latest_version !== null &&
                currentData.card_template_version !== null &&
                currentData.card_latest_version >
                  currentData.card_template_version && (
                  <img
                    src={WarningIconForCard}
                    alt=""
                    style={{ width: "18px", margin: "-4px 0px 0px 5px" }}
                  />
                )}
            </Link>
          </div>
        </Link>
      )}

      {currentData && currentData?.card_id && showCard && (
        <Cards
          cardId={currentData?.card_id}
          showCard={showCard}
          setShowCard={setShowCard}
          listCardData={listCardData}
          cardType={cardType}
          datasetCardTemplateRes={datasetCardTemplateRes}
          selectedRiskFactor={null}
          currentData={currentData}
          listCardId={listCardId}
          showDatasetCard={showDatasetCard}
          setShowDatasetCard={setShowDatasetCard}
          isSuccess={isSuccess}
          handleSetCurrentData={handleSetCurrentData}
        />
      )}
    </>
  );
};

export default ListviewComplianceCard;
