import { useAtom } from "jotai";
import React, { useState, useEffect } from "react";
import { useGetAllScores } from "../../api/readApiFunctions";
import { accessDataByRoleAtom, isSidebar } from "../../atom/jotai";
import AdminDataFieldTable from "../../components/AdminDataFieldTable/AdminDataFieldTable";
import Layout from "../../layout/Layout";
import "../admin-userlist-page/AdminUserlistPage.css";
import "./AdminRiskfactorsPage.css";
import { FormControl, IconButton, MenuItem, Select } from "@mui/material";
import { Square, ExpandMore } from "@mui/icons-material";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { useLocation } from "react-router-dom";
import { isAccessProvided } from "../../services/assetManagementService";
import {
  actionConstants,
  moduleConstants,
  subModuleConstants,
} from "../../constants/accessManagementConstants";
import NoResult from "../../components/NoResult/NoResult";
import { entityNameConst } from "../../constants/dataTypes";
import { PolicyNameConst } from "../../constants/policyConstants";

const AdminRiskfactorsPage = () => {
  //state for managing access management system golbal value
  const [accessDataByRole] = useAtom(accessDataByRoleAtom);
  const [sidebar] = useAtom(isSidebar);
  const [selectedScore, setSelectedScore] = useState(null);
  const location = useLocation();

  // get all policies (scoresList)
  const { data: scoresList, isLoading } = useGetAllScores(
    "admin_view",
    "risk_factors",
    location.pathname.split("/")[2] == "riskfactors" &&
      location.pathname.split("/")[2] !== undefined &&
      accessDataByRole &&
      isAccessProvided(
        moduleConstants.admin_view,
        subModuleConstants.risk_factors,
        actionConstants.visibility,
        "execution",
        accessDataByRole
      )
      ? true
      : false
  );

  useEffect(() => {
    if (scoresList && scoresList.length > 0) {
      scoresList.map((scoreListData) => {
        scoreListData.score_name === "Konfer Confidence" &&
          setSelectedScore(scoreListData.score_id);
      });
      // setSelectedScore(scoresList[0].score_id);
    }
  }, [scoresList]);

  //Below useEffect is used for giving title to current page
  useEffect(() => {
    document.title = "Admin Risk Factor Page";
  });

  const handleSelectChange = (e) => {
    setSelectedScore(e.target.value);
  };

  return (
    <>
      <div className="risk_factor_heading">
        <div className="userList-heading1_risk" id="adminRiskFactorHeading">
          Risk Factors
        </div>
        {isAccessProvided(
          moduleConstants.admin_view,
          subModuleConstants.risk_factors,
          actionConstants.visibility,
          "execution",
          accessDataByRole
        ) === true &&
          scoresList && (
            <div className="userList-heading1_risk">
              <Select
                sx={{
                  "& .MuiSelect-select": {
                    padding: "1px 0px 1px 5px",
                    fontSize: "16px",
                    fontWeight: "500",
                    background: "#ffffff",
                    marginLeft: "10px",
                    width: "300px",
                    borderRadius: "4px",
                  },
                }}
                variant="standard"
                id="adminRiskFactorSelect"
                value={selectedScore}
                onChange={(e) => {
                  handleSelectChange(e);
                }}
                disableUnderline={true}
                IconComponent={ExpandMore}
                MenuProps={{ style: { maxHeight: "400px" } }}
              >
                {scoresList?.map((item, index) => {
                  const val = ["Konfer Confidence", "Human Impact"];
                  if (!val.includes(item.score_name)) {
                    return;
                  } else {
                    return (
                      item.score_name !== "Human Impact" && (
                        <MenuItem
                          key={index}
                          value={item.score_id}
                          id="selectMenuItem"
                          sx={{
                            "&.Mui-selected": {
                              backgroundColor: "#F6F6F6",
                              "&.Mui-focusVisible": { background: "#F6F6F6" },
                            },
                          }}
                        >
                          {/* for demo */}
                          {/* {item.score_name} */}
                          {item.score_name === "Konfer Confidence"
                            ? PolicyNameConst.konfer_confidence_wo_tm
                            : item.score_name}
                        </MenuItem>
                      )
                    );
                  }
                })}
                <MenuItem
                  id="selectMenuItem"
                  sx={{
                    "&.Mui-selected": {
                      backgroundColor: "#F6F6F6",
                      "&.Mui-focusVisible": { background: "#F6F6F6" },
                    },
                  }}
                >
                  <div style={{ display: "flex" }}>
                    <p
                      style={{
                        color: "#bec9d1",
                        margin: 0,
                        padding: 0,
                        marginRight: "10px",
                      }}
                    >
                      {PolicyNameConst.policies.toUpperCase()}
                    </p>
                    {/* <ArrowDownwardIcon style={{ color: '#bec9d1' }}/> */}
                  </div>
                </MenuItem>

                {scoresList?.map((item, index) => {
                  const val = ["Konfer Confidence", "Human Impact"];
                  if (val.includes(item.score_name)) {
                    return;
                  } else {
                    return (
                      <MenuItem
                        key={index}
                        value={item.score_id}
                        id="selectMenuItem"
                        sx={{
                          "&.Mui-selected": {
                            backgroundColor: "#F6F6F6",
                            "&.Mui-focusVisible": { background: "#F6F6F6" },
                          },
                        }}
                      >
                        {item.score_name}
                      </MenuItem>
                    );
                  }
                })}
              </Select>
            </div>
          )}
      </div>

      {isAccessProvided(
        moduleConstants.admin_view,
        subModuleConstants.risk_factors,
        actionConstants.visibility,
        "execution",
        accessDataByRole
      ) === true ? (
        <div
          className={sidebar ? "userlist_risk_sidebar" : "userlist_risk"}
          id="adminRiskFactorTable"
        >
          <AdminDataFieldTable
            selectedScore={selectedScore}
            accessDataByRole={accessDataByRole}
          />
        </div>
      ) : (
        <div className="hgt userlist-users-table" id="noAccessToRoleManagment">
          {accessDataByRole && <NoResult title={"No Access to Risk Factors"} />}
        </div>
      )}
    </>
  );
};

export default AdminRiskfactorsPage;
