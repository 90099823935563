import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import { useEffect } from 'react';

export default function SimpleBackdrop({openStat = false}) {
  const [open, setOpen] = React.useState(openStat);
  const handleClose = () => {
    // setOpen(false);
  };

  useEffect(() => {
    setOpen(openStat)
  }, [openStat])
  

  return (
    <div>
      {/* <Button onClick={handleOpen}>Show backdrop</Button> */}
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
        onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
}