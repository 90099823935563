import React, { useEffect, useState } from "react";
import { Close } from "@mui/icons-material";
import {
  Box,
  CircularProgress,
  IconButton,
  Modal,
  Tooltip,
} from "@mui/material";
import { useAtom } from "jotai";
import "./Cards.css";
import {
  useGetModalCardData,
  useGetRiskFactorData,
} from "../../api/readApiFunctions";
import { MutationKeyName } from "../../constants/dataTypes";
import { analyticServices } from "../../services/analyticServices";
import HighlightText from "../HOC/HighlightText";
import FilterIcon from "./FilterIcon";
import OpenAIIcon from "./OpenAIIcon";
import EditIcon from "../../assets/images/detail/edit_annotation.svg";
import EditDisabledIcon from "../../assets/images/adminPanel/EditDisabledNew.svg";
import {
  accessDataByRoleAtom,
  authUser,
  bulkQueResGeneratedAtom,
  documentFileUpload,
} from "../../atom/jotai";
import GenerateReportIcon from "./GenerateReportIcon";
import SectionTabs from "../modelCard/Tabs";
import DisplayDetailsInfo from "./Cards Subsection/DisplayDetailsInfo";
import migrationWarning from "../../assets/images/icons/migration-warning.svg";
import WarningModal from "../WarningModal/WarningModal";
import {
  useCreateDatasetCardFromTemplate,
  useGetMigrationDifference,
  useImprtCSVFilsFrCrdResponces,
} from "../../api/createApiFunctions";
import { toast } from "react-toastify";
import DisplaySections from "./Cards Subsection/DisplaySections";
import SectionsQuestionnaire from "./Cards Subsection/SectionsQuestionnaire";
import { useUpdateSubsectionTextDataAPI } from "../../api/updateApiFunctions";
import { useCreateSubsectionTextData } from "../../api/createApiFunctions";
import { useRiskFactorCalculate } from "../../api/createApiFunctions";
import { getAnsCombination } from "../../services/cardServices";
import { useDeleteDocumentFiles } from "../../api/deleteApiFunctions";
import { useDeleteFiles } from "../../api/deleteApiFunctions";
import { cardsPageToasts } from "../../constants/toasterMsgConstants";
import GenerateCSV from "./GenerateCSV";
import ImportCSVIcon from "../../assets/images/adminPanel/ImportCSVICon.svg";
import ImportCSVDisabledIcon from "../../assets/images/adminPanel/ImportCSVDisabled.svg";
import {
  cardsPageTooltip,
  genAIToottips,
} from "../../constants/tooltipConstants";
import { isAccessProvided } from "../../services/assetManagementService";
import SimpleBackdrop from "../SimpleBackdrop/SimpleBackdrop";
import RefreshIcon from "@mui/icons-material/Refresh";
import { PolicyNameConst } from "../../constants/policyConstants";

/**
 * Component : Cards
 *
 * Description : This component is responsible for showing the modal while clicking on Card button.
 * It is a parent component of card modal. It shows all the riskfactors as per the selected card.
 *
 * Props : cardId - to get the card data as per cardId,
 *         showCard, setShowCard - state for opening and closing card
 *         cardType - type of a crad, app/model/dataset,
 *         datasetCardTemplateRes - reponse of create card api , if the card is not created
           selectedRiskFactor - if we want to open the card for particular section rather than details,
           page - page depicts the location where the card is opened,
           assetsviewRefetch - refetch the assets/profile table api get-list-view-data,
           listviewConFactorsRefetch - refetch the profile confidence fators api profile-score,
           graphviewConFactorsRefetch - refetch the profile confidence fators api profile-score on summary,
           appRefetch, modelRefetch, datasetRefetch - graphql queries on node details page
 * 
 *  */
const Cards = ({
  cardId,
  showCard,
  setShowCard,
  datasetCardTemplateRes,
  selectedRiskFactor,
  setCallCreateCardAPI,
  assetsviewRefetch,
  listviewConFactorsRefetch,
  page,
  graphviewConFactorsRefetch,
  appRefetch,
  modelRefetch,
  datasetRefetch,
  handleSetCurrentData,
}) => {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "90%",
    height: "80%",
    // bgcolor: "#E7EDF0",
    bgcolor: "#ffffff",

    boxShadow: 24,
    p: 4,
    borderRadius: "8px",
  };

  //Global states
  const [user] = useAtom(authUser);
  //State for showing the progress of bulk question
  const [bulkQueResGenerated, setBulkQueResGenerated] = useAtom(
    bulkQueResGeneratedAtom
  );

  // the state used for set the card Response data  question data
  const [data, setData] = useState(null);
  // the state used for set editable data
  const [isEditDatasetSection, setIsEditDatasetSection] = useState(false);
  //State to save selected policy for filtering questions
  const [cardFilterWithPolicy, setCardFilterWithPolicy] = useState([]);
  // the state used for enable submit btn while editing card answer
  const [isEnableSubmit, setIsEnableSubmit] = useState(false);
  // the state used for submit btn
  const [isSave, setIsSave] = useState();

  // the state used for submit btn
  const [showMigrationModal, setShowMigrationModal] = useState(false);

  //state for selected risk factor(tab) in cards
  const [selectedSection, setSelectedSection] = useState({
    title: selectedRiskFactor !== null ? `${selectedRiskFactor}` : "Details",
  });
  //State to store edited values for text data
  const [editedTextData, setEditedTextData] = useState([]);

  //State editedQuestionData, to store edited values for question data(boolean, text & file)
  const [editedQuestionData, setEditedQuestionData] = useState([]);

  //updatedFiles, holds the list of files which are newly added against question in edited mode
  const [updatedFiles, setUpdatedFiles] = useState([]);

  //fileList, holds the list of files which are already added against question and coming from db
  const [fileList, setFileList] = useState([]);

  //State maintain to delete the files from db when save/cancel button or browser refresh hit,
  const [deletedFileInfo, setDeletedFileInfo] = useState([]);

  // store all the file uploads for card document
  const [allDocumentFiles, setAllDocumentFiles] = useAtom(documentFileUpload);
  const [accessDataByRole, setAccessDataByRole] = useAtom(accessDataByRoleAtom);
  const [showYesToallPopup, setShowYesToallPopup] = useState(false);

  const {
    data: cardResData,
    error,
    refetch: cardResDataRefetch,
  } = useGetModalCardData(cardId, cardId !== null ? true : false);

  const {
    data: riskFactorData,
    riskFactorError,
    riskFactorIsLoading,
  } = useGetRiskFactorData(
    cardResData?.card_type,
    cardResData?.reference_id,
    cardResData ? true : false
  );

  const {
    mutate: createCardAPI,
    data: createCardRes,
    isSuccess: createCardSuccess,
    isLoading: createCardLoading,
  } = useCreateDatasetCardFromTemplate();

  const {
    data: migrationDifference,
    mutate: getRMigrationDiff,
    error: migrationDiffErr,
    isLoading,
  } = useGetMigrationDifference();
  //To update subsection text data
  const {
    mutate: updateSubsectionTextData,
    data: updatedSubData,
    isSuccess: textUpdateSuccess,
  } = useUpdateSubsectionTextDataAPI();

  //To create new sunsection text data, but only for details summary
  const {
    mutate: createSubsectionTextData,
    data: createdSubData,
    isSuccess: textCreateSuccess,
  } = useCreateSubsectionTextData();

  //To update questions data
  const {
    mutate: riskFactorCalculate,
    isSuccess,
    data: cardQuestionDataRes,
  } = useRiskFactorCalculate();

  const { mutate: deleteFileUpload, data: deleteFileUploadData } =
    useDeleteDocumentFiles();

  const {
    mutate: deleteFiles,
    isSuccess: deleteFileSuccess,
    data: deleteFileResponse,
    isError: deleteFileIsError,
    error: deleteFileError,
  } = useDeleteFiles(updatedFiles);

  const {
    mutate: imprtCSVFilsFrCrdResponces,
    isSuccess: imprtCSVFilsFrCrdResponcesIsSuccess,
    data: imprtCSVFilsFrCrdResponcesData,
    isError: imprtCSVFilsFrCrdResponcesIsError,
    error: imprtCSVFilsFrCrdResponcesError,
  } = useImprtCSVFilsFrCrdResponces();

  //Card response csv validated successfully
  useEffect(() => {
    if (imprtCSVFilsFrCrdResponcesIsSuccess) {
      toast.success(imprtCSVFilsFrCrdResponcesData.data.message);
      cardResDataRefetch();
    }
  }, [imprtCSVFilsFrCrdResponcesData]);

  //Card response csv validation error
  useEffect(() => {
    if (imprtCSVFilsFrCrdResponcesIsError) {
      toast.error(imprtCSVFilsFrCrdResponcesError.response.data.message);
      setData(cardResData);
    }
  }, [imprtCSVFilsFrCrdResponcesError]);

  useEffect(() => {
    if (isSuccess) {
      if (page === "profile" || page === "listview") {
        assetsviewRefetch();
      }
      if (page === "profile") {
        listviewConFactorsRefetch();
      }
      if (page === "graphview") {
        graphviewConFactorsRefetch();
      }
    }
  }, [cardQuestionDataRes]);

  // empty the toDeleteFiles after sucessfull text data save
  useEffect(() => {
    if (updatedSubData || createdSubData) {
      setData(null);
      localStorage.removeItem("deleteFileIds");
    }
  }, [updatedSubData, createdSubData]);

  useEffect(() => {
    analyticServices("Model Card section View");
    if (cardResData) {
      setData(cardResData);
      let temArr = [];
      let newTemArr = [];
      setFileList([]);
      if (cardResData.sections?.length > 0) {
        cardResData.sections?.map((secData, index) => {
          if (selectedSection.title === secData.title) {
            setSelectedSection(secData);
          }

          //For adding the file list of Questionnaire in state
          secData.title !== "Details" &&
            secData.subsections?.length > 0 &&
            secData.subsections?.map((subSecData) => {
              subSecData.title === "Questionnaire" &&
                subSecData.questions?.length > 0 &&
                subSecData.questions?.map((quesData) => {
                  if (quesData.answer_type.includes("file")) {
                    let findData = eval("(" + quesData.answer_field + ")");

                    temArr.push({
                      card_question_id: quesData.card_question_id,
                      file:
                        findData === null || !findData?.hasOwnProperty("file")
                          ? []
                          : findData.file,
                    });
                  }
                });
            });
        });
      }
      setFileList(temArr);
      handleSetCurrentData(cardResData);
    }

    return () => {
      setData(null);
    };
  }, [cardResData]);

  /*This is the temporaray code */
  useEffect(() => {
    if (
      datasetCardTemplateRes?.data &&
      !cardResData &&
      cardResData !== undefined
    ) {
      setData(datasetCardTemplateRes.data);
    }
  }, [datasetCardTemplateRes]);

  // the below function used for card question filter , take input as policy name
  const cardFilterFunction = (policyName) => {
    cardFilterWithPolicy.includes(policyName)
      ? setCardFilterWithPolicy(
          cardFilterWithPolicy.filter((val) => val !== policyName)
        )
      : setCardFilterWithPolicy([...cardFilterWithPolicy, policyName]);
  };

  const handleSelectedSection = (section) => {
    setSelectedSection(section);
  };

  const handleMigrationCard = () => {
    setShowMigrationModal(true);
    getRMigrationDiff({
      card_id: cardId,
      card_type: data?.card_type,
      reference_id: data?.reference_id,
    });
  };

  // handle upgrade now on click
  const onUpgradeClick = () => {
    setData(null);
    setShowMigrationModal(false);
    createCardAPI({
      id: data?.reference_id,
      type: data?.card_type,
    });
  };

  useEffect(() => {
    if (createCardSuccess === true) {
      setData(createCardRes.data.cards[0]);
      // setCallCreateCardAPI(true)
      toast.success(cardsPageToasts.cardUpdateSuccessful);
      cardResDataRefetch();
    }
  }, [createCardSuccess]);
  /** Below function is used for storing the text data of all sections in one state */
  const handlEditedTextData = (
    textData,
    sectionId,
    subSectionId,
    textDataId
  ) => {
    let alreadyPresent = false;
    editedTextData.length > 0 &&
      editedTextData.map((data) => {
        if (data.text_data_id === textDataId) {
          data.text_data = textData;
          alreadyPresent = true;
          return;
        }
      });

    if (!alreadyPresent) {
      setEditedTextData([
        ...editedTextData,
        {
          section_id: sectionId,
          subsection_id: subSectionId,
          card_id: cardId,
          text_data: textData,
          text_data_id: textDataId,
        },
      ]);
    }
  };

  /**Function is used for deleting the file when the file is uploaded but user dosen't save,
   * rather than user clicks on cancel/cross button  */
  const handleDeletionOfUploadedFiles = () => {
    let tempArrayForDeletion = [];
    updatedFiles.map((data) => {
      data.file.map((fileInfo) => {
        tempArrayForDeletion.push({
          questionId: data.card_question_id,
          id: fileInfo.id,
          file_name: fileInfo.file_name,
        });
      });
    });

    tempArrayForDeletion.length > 0 && deleteFiles(tempArrayForDeletion);
  };

  // handler function on card cancel
  const handleEditModeCancel = () => {
    //mutation to delete the file in db, if the file is upload in the db
    updatedFiles.length > 0 && handleDeletionOfUploadedFiles();

    let temArr = [];
    //For adding the file list of Questionnaire in state
    if (cardResData.sections?.length > 0) {
      cardResData.sections?.map((secData, index) => {
        //For adding the file list of Questionnaire in state
        secData.title !== "Details" &&
          secData.subsections?.length > 0 &&
          secData.subsections?.map((subSecData) => {
            subSecData.title === "Questionnaire" &&
              subSecData.questions?.length > 0 &&
              subSecData.questions?.map((quesData) => {
                if (quesData.answer_type.includes("file")) {
                  let findData = eval("(" + quesData.answer_field + ")");

                  temArr.push({
                    card_question_id: quesData.card_question_id,
                    file:
                      findData === null || !findData.hasOwnProperty("file")
                        ? []
                        : findData.file,
                  });
                }
              });
          });
      });
    }
    setFileList(temArr);

    setUpdatedFiles([]);
    setIsEditDatasetSection(false);
    setEditedTextData([]);
    setEditedQuestionData([]);
    setAllDocumentFiles([]);
    handleDeleteUploadedFiles();
    setDeletedFileInfo([]);
  };

  // on refresh delete the files uploaded
  useEffect(() => {
    window.addEventListener("beforeunload", alertUser);
    return () => {
      window.removeEventListener("beforeunload", alertUser);
    };
  }, []);

  const alertUser = (e) => {
    e.preventDefault();
    handleDeleteUploadedFiles();
    e.returnValue = "";
  };

  // delete files on unmount
  useEffect(() => {
    return () => {
      handleDeleteUploadedFiles();
    };
  }, [showCard]);

  // handler function for updating new files
  const handleUpdateFiles = (fileData, questionId) => {
    //Below state (updatedFiles) is maintaining becuase if we click on cancel button, we should able to delete the new added files,
    let alreadyPresent = false;
    updatedFiles.length > 0 &&
      updatedFiles.map((data) => {
        if (data.card_question_id === questionId) {
          data.file.push({
            id: fileData.id,
            file_name: fileData.file_name,
          });

          alreadyPresent = true;
        }
      });

    if (!alreadyPresent) {
      setUpdatedFiles([
        ...updatedFiles,
        {
          card_question_id: questionId,
          file: [
            {
              id: fileData.id,
              file_name: fileData.file_name,
            },
          ],
        },
      ]);
    }

    /** below code is used for adding the files in main state, which is using as a payload for saving question data in db.*/
    handlEditedQuestionData(questionId, "file", fileData);
  };

  const handleUpdateFilesWhileDeleted = (
    questionId,
    deleteFileId,
    deleteFileName
  ) => {
    setDeletedFileInfo([
      ...deletedFileInfo,
      {
        questionId: questionId,
        id: deleteFileId,
        file_name: deleteFileName,
      },
    ]);

    if (updatedFiles.length > 0) {
      updatedFiles.map((fileInfoData) => {
        if (fileInfoData.card_question_id === questionId) {
          let remainingFileAfterDeletedArr = fileInfoData.file.filter(
            (fileInfo) => {
              return fileInfo.id !== deleteFileId;
            }
          );
          fileInfoData.file = remainingFileAfterDeletedArr;
        }
      });
    }

    if (fileList.length > 0) {
      fileList.map((fileInfoData) => {
        if (fileInfoData.card_question_id === questionId) {
          let remainingFileAfterDeletedArr = fileInfoData.file.filter(
            (fileInfo) => {
              return fileInfo.id !== deleteFileId;
            }
          );
          fileInfoData.file = remainingFileAfterDeletedArr;
        }
      });
    }

    let alreadyPresent = false;
    editedQuestionData.map((editedData) => {
      if (editedData.card_question_id === questionId) {
        let remainingFileAfterDeletedArr = editedData.ans["file"].filter(
          (fileInfo) => {
            return fileInfo.id !== deleteFileId;
          }
        );
        editedData.ans["file"] = remainingFileAfterDeletedArr;
        alreadyPresent = true;
      }
    });
    if (!alreadyPresent) {
      /**  This section handles, if that question in not edited yet, and we need to delete that file from that question
       * and then send to database, to tell delete it from textData in questions table */
      data.sections.map((secData) => {
        secData.title !== "Details" &&
          secData.subsections?.length > 0 &&
          secData.subsections?.map((subSecData) => {
            subSecData.title === "Questionnaire" &&
              subSecData.questions?.length > 0 &&
              subSecData.questions?.map((quesData) => {
                if (quesData.card_question_id === questionId) {
                  let ansCombination = {};
                  ansCombination = getAnsCombination(
                    quesData.answer_type,
                    eval("(" + quesData.answer_field + ")")
                  );

                  let remainingFileAfterDeletedArr = ansCombination.file.filter(
                    (fileInfo) => {
                      return fileInfo.id !== deleteFileId;
                    }
                  );
                  ansCombination.file = remainingFileAfterDeletedArr;
                  setEditedQuestionData([
                    ...editedQuestionData,
                    {
                      card_question_id: questionId,
                      ans: ansCombination,
                    },
                  ]);
                }
              });
          });
      });
    }
  };

  const handleDeleteUploadedFiles = () => {
    const fileDataArr = JSON.parse(localStorage.getItem("deleteFileIds"));
    if (fileDataArr && fileDataArr.length > 0) {
      deleteFileUpload(fileDataArr);
    }
  };

  //Function is called when save button is clicked
  const handleCardSave = () => {
    deletedFileInfo.length > 0 && deleteFiles(deletedFileInfo); //mutation to delete the file in db
    setUpdatedFiles([]);
    setFileList([]);
    setDeletedFileInfo([]);

    editedTextData.length > 0 && saveTextDataInDB(); //Function to save text data in Data base

    saveQuestionData(); //Function to save question data in Data base
    setIsEditDatasetSection(false);
  };

  //Function to save text data in Data base
  const saveTextDataInDB = () => {
    editedTextData.map((editedTxtData) => {
      if (!editedTxtData?.text_data_id) {
        const payload = {
          card_id: editedTxtData.card_id,
          section_id: editedTxtData.section_id,
          subsection_id: editedTxtData.subsection_id,
          text_data: editedTxtData.text_data,
        };
        createSubsectionTextData(payload);
      } else {
        updateSubsectionTextData(editedTxtData);
      }
    });
    setAllDocumentFiles([]);
    setEditedTextData([]);
    setData(null);
  };

  //Function to save question data in Data base
  const saveQuestionData = () => {
    riskFactorCalculate({
      type: data?.card_type,
      id: data?.reference_id,
      answer: editedQuestionData,
      storeAnswerFlag: editedQuestionData.length > 0 ? true : false,
    });

    if (editedQuestionData.length > 0) {
      setEditedQuestionData([]);
      setData(null);
    }
  };

  const showQueTextValue = (type, questionData) => {
    if (editedQuestionData.length > 0) {
      if (
        editedQuestionData.find(
          (quesData) =>
            quesData.card_question_id === questionData.card_question_id
        )?.ans[type] === undefined
      ) {
        if (questionData?.answer_field === null) {
          return null;
        } else {
          let stringAns = questionData?.answer_field.replace(/'/g, '"');
          let parseAns = JSON.parse(stringAns);
          return parseAns[type];
        }
      } else {
        return (
          editedQuestionData.length > 0 &&
          editedQuestionData.find(
            (quesData) =>
              quesData.card_question_id === questionData.card_question_id
          )?.ans[type]
        );
      }
    } else {
      if (questionData?.answer_field === null) {
        return null;
      } else {
        let stringAns = questionData?.answer_field.replace(/'/g, '"');
        let parseAns = JSON.parse(stringAns);
        return parseAns[type];
      }
    }
  };
  /** Below function is used for storing the question data of all sections questions in one state */
  const handlEditedQuestionData = (queId, answerTypeKey, value, valueObj) => {
    let alreadyPresent = false;
    editedQuestionData.length > 0 &&
      editedQuestionData.map((editedData) => {
        if (editedData.card_question_id === queId) {
          if (answerTypeKey === "file") {
            if (
              editedData.ans.file === undefined ||
              editedData.ans.file.length === 0
              //|| editedData.ans.file === []
            ) {
              editedData.ans.file = [
                {
                  id: value.id,
                  file_name: value.file_name,
                },
              ];
            } else {
              editedData.ans.file.push({
                id: value.id,
                file_name: value.file_name,
              });
            }
          } else {
            editedData.ans[answerTypeKey] = value;
          }
          alreadyPresent = true;
        }
      });

    if (!alreadyPresent) {
      data.sections.map((secData) => {
        secData.title !== "Details" &&
          secData.subsections?.length > 0 &&
          secData.subsections?.map((subSecData) => {
            subSecData.title === "Questionnaire" &&
              subSecData.questions?.length > 0 &&
              subSecData.questions?.map((quesData) => {
                if (quesData.card_question_id === queId) {
                  let ansCombination = {};
                  ansCombination = getAnsCombination(
                    quesData.answer_type,
                    eval("(" + quesData.answer_field + ")")
                  );

                  if (answerTypeKey === "file") {
                    if (
                      ansCombination.file === undefined ||
                      ansCombination.file.length === 0
                      // || ansCombination.file === []
                    ) {
                      ansCombination.file = [
                        {
                          id: value.id,
                          file_name: value.file_name,
                        },
                      ];
                    } else {
                      ansCombination.file.push({
                        id: value.id,
                        file_name: value.file_name,
                      });
                    }
                  } else {
                    ansCombination[answerTypeKey] = value;
                  }

                  setEditedQuestionData([
                    ...editedQuestionData,
                    {
                      card_question_id: queId,
                      ans: ansCombination,
                    },
                  ]);
                }
              });
          });
      });
    }
  };
  /* Below function used to set yes answer to all questions who's answer type is boolean */
  const handleCardYesToAll = () => {
    let tempArrForEdited = [];
    data &&
      data.sections?.map((secData) => {
        secData.title !== "Details" &&
          secData.subsections?.length > 0 &&
          secData.subsections?.map((subSecData) => {
            subSecData.title === "Questionnaire" &&
              subSecData.questions?.length > 0 &&
              subSecData.questions?.map((quesData) => {
                const filteredData = editedQuestionData.filter(
                  (editedData) =>
                    editedData.card_question_id === quesData.card_question_id
                );

                if (filteredData.length > 0) {
                  if (filteredData[0].ans.hasOwnProperty("boolean")) {
                    if (quesData.desired_response === true) {
                      filteredData[0].ans.boolean = "yes";
                    } else {
                      filteredData[0].ans.boolean = "no";
                    }
                  }
                  tempArrForEdited.push(filteredData[0]);
                } else {
                  if (quesData.answer_type?.includes("boolean")) {
                    let ansCombination = {};
                    ansCombination = getAnsCombination(
                      quesData.answer_type,
                      eval("(" + quesData.answer_field + ")")
                    );

                    if (quesData.desired_response === true) {
                      ansCombination.boolean = "yes";
                    } else {
                      ansCombination.boolean = "no";
                    }
                    tempArrForEdited.push({
                      card_question_id: quesData.card_question_id,
                      ans: ansCombination,
                    });
                  }
                }
              });
          });
      });

    tempArrForEdited.length > 0 && setEditedQuestionData(tempArrForEdited);
  };

  const handleImportCardResCsv = (e) => {
    const file = e.target.files[0];

    const csvData = new FormData();

    csvData.append(`files`, file);

    imprtCSVFilsFrCrdResponces({
      filePayload: csvData,
      cardId: cardId,
      cardType: data?.card_type,
      cardTypeId: data?.reference_id,
    });
    e.target.value = "";
    setData(null);
  };

  return (
    <>
      <Modal
        id="modalCard"
        sx={{
          marginLeft: 23,
          marginRight: 23,
        }}
        open={showCard}
        onClose={(e, reason) => {
          if (reason && reason == "backdropClick") return;

          setShowCard(false);
          //mutation to delete the file in db, if the file is upload in the db
          updatedFiles.length > 0 && handleDeletionOfUploadedFiles();
          setUpdatedFiles([]);
          setIsEditDatasetSection(false);
          setEditedTextData([]);
          setEditedQuestionData([]);
          // setFileList([]);
          setDeletedFileInfo([]);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} id="modalCardBox">
          <div
            id="modalCardCloseButton"
            className="close_button"
            onClick={() => {
              //mutation to delete the file in db, if the file is upload in the db
              updatedFiles.length > 0 && handleDeletionOfUploadedFiles();
              setShowCard(false);
              setData(null);
              setAllDocumentFiles([]);
              if (page === "Node Details") {
                appRefetch();
                modelRefetch();
                datasetRefetch();
              }
              if (page === "profile" || page === "listview") {
                assetsviewRefetch();
              }
              if (page === "profile") {
                listviewConFactorsRefetch();
              }
              if (page === "graphview") {
                graphviewConFactorsRefetch();
              }
            }}
          >
            {bulkQueResGenerated && (
              <div style={{ marginRight: "300px" }}>
                <Tooltip title={genAIToottips.bulkQuesLoader} followCursor>
                  <CircularProgress
                    style={{ width: "37px", height: "25px", margin: "0px" }}
                  />
                </Tooltip>
              </div>
            )}
            <Close sx={{ "&.MuiIconButton-root": { width: "22px" } }} />
          </div>
          {data && (
            <div
              className={
                data?.latest_version !== data?.template_version
                  ? "modal_header modal_header_warning"
                  : "modal_header"
              }
              id="modalHeader"
              onContextMenu={() => {
                setShowYesToallPopup(!showYesToallPopup);
              }}
            >
              <Tooltip
                title={
                  data?.display_name !== "" && data?.display_name !== null
                    ? data?.display_name
                    : data?.name !== "" || data?.name !== null
                    ? data?.name
                    : data?.title
                }
              >
                <h2
                  className="modal_title"
                  style={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  <HighlightText
                    text={
                      data?.display_name !== "" && data?.display_name !== null
                        ? data?.display_name
                        : data?.name !== "" || data?.name !== null
                        ? data?.name
                        : data?.title
                    }
                  />
                </h2>
              </Tooltip>

              {data && data?.latest_version !== data?.template_version && (
                <div className="warning_container">
                  <img
                    className="warning_icon_img"
                    src={migrationWarning}
                    alt=""
                  />
                  <p className="warning_text">
                    Card is based on older version of the template!
                  </p>
                  <p className="warning_click" onClick={handleMigrationCard}>
                    Upgrade Now
                  </p>
                </div>
              )}

              <Tooltip title="Refresh card data" followCursor>
                <div
                  style={{
                    border: "1px solid #7b8e9b",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    borderRadius: "5px",

                    margin: 0,
                    height: "27px",
                    padding: 0,
                    marginTop: "8px",
                    width: "27px",
                  }}
                  onClick={() => {
                    cardResDataRefetch();
                    setBulkQueResGenerated(false);
                  }}
                >
                  <RefreshIcon fontSize="small" />
                </div>
              </Tooltip>

              {data &&
                isAccessProvided(
                  "card_modal",
                  "card_view",
                  "gen_ai_response",
                  "execution",
                  accessDataByRole
                ) && <OpenAIIcon data={data} />}

              {data && (
                <FilterIcon
                  isEditDatasetSection={isEditDatasetSection}
                  cardFilterWithPolicy={cardFilterWithPolicy}
                  cardFilterFunction={cardFilterFunction}
                />
              )}

              {data &&
                isAccessProvided(
                  "card_modal",
                  "crud",
                  null,
                  "update",
                  accessDataByRole
                ) && (
                  <div className="datasetEditSubSection">
                    {
                      <>
                        {
                          <img
                            className="icon_background"
                            alt="card answer data edit icon"
                            src={
                              !isEditDatasetSection && !bulkQueResGenerated
                                ? EditIcon
                                : EditDisabledIcon
                            }
                            onClick={() => {
                              if (
                                !isEditDatasetSection &&
                                !bulkQueResGenerated
                              ) {
                                setIsEditDatasetSection(true);
                                setIsEnableSubmit(false);
                                setIsSave(false);
                                setCardFilterWithPolicy([]);
                              }
                            }}
                          />
                        }
                      </>
                    }
                  </div>
                )}

              {/* the below GenerateReport component contain report formate data used to generate report */}
              {data &&
                isAccessProvided(
                  "card_modal",
                  "card_view",
                  "download_csv_and_pdf",
                  "read",
                  accessDataByRole
                ) && (
                  <GenerateReportIcon
                    data={data}
                    cardType={data?.card_type}
                    cardFilterWithPolicy={cardFilterWithPolicy}
                  />
                )}

              {/* the below GenerateCSV component able to export csv */}
              {data &&
                isAccessProvided(
                  "card_modal",
                  "card_view",
                  "download_csv_and_pdf",
                  "read",
                  accessDataByRole
                ) && (
                  <GenerateCSV
                    data={data}
                    isEditDatasetSection={isEditDatasetSection}
                  />
                )}

              {/* import card csv functionality*/}
              {data &&
                isAccessProvided(
                  "card_modal",
                  "card_answer",
                  "import_response_csv",
                  "execution",
                  accessDataByRole
                ) && (
                  <Tooltip
                    title={
                      isEditDatasetSection || bulkQueResGenerated
                        ? cardsPageTooltip.importCSVDisabled
                        : cardsPageTooltip.importCSVEnabled
                    }
                    followCursor
                  >
                    <p style={{ margin: "0px", padding: "0px" }}>
                      <label for="myfile">
                        <img
                          src={
                            isEditDatasetSection || bulkQueResGenerated
                              ? ImportCSVDisabledIcon
                              : ImportCSVIcon
                          }
                          className="icon_background"
                          style={{
                            height: "20px",
                            width: "20px",
                            marginTop: "10px",
                            marginLeft: "15px",
                            color: "#bcc1c2",
                          }}
                        />
                      </label>

                      {!isEditDatasetSection && !bulkQueResGenerated && (
                        <input
                          id="myfile"
                          type="file"
                          name="Click here"
                          accept=".csv"
                          onChange={(e) => {
                            handleImportCardResCsv(e);
                          }}
                        />
                      )}
                    </p>
                  </Tooltip>
                )}
            </div>
          )}

          {/* the below code used to show policy tag after filtering  */}
          {!isEditDatasetSection &&
            cardFilterWithPolicy &&
            cardFilterWithPolicy.length > 0 && (
              <div className="fliteredPoliciesDiv">
                <div className="fliteredPoliciesInnerDiv">
                  {PolicyNameConst.policy}:
                  {cardFilterWithPolicy?.map((val, index) => {
                    return (
                      <p
                        style={{
                          marginLeft: "8px",
                        }}
                      >
                        {val}
                        {cardFilterWithPolicy?.length - 1 !== index ? "," : ""}
                      </p>
                    );
                  })}
                </div>
              </div>
            )}

          {data ? (
            data.sections.length > 0 && (
              <>
                <div className="sections_container" id="modalSectionsContainer">
                  <div className="sections" id="modalSectionsDiv">
                    <SectionTabs
                      sectionData={data?.sections}
                      setSelectedSection={setSelectedSection}
                      selectedSection={selectedSection}
                      handleSelectedSection={handleSelectedSection}
                    />
                  </div>
                </div>

                <div
                  className={
                    isEditDatasetSection
                      ? "subsections_main_edit_Enable"
                      : "subsections_main"
                  }
                  id="modalSubSectionsMainDiv"
                >
                  {selectedSection.title?.toLocaleLowerCase() === "details" && (
                    <DisplayDetailsInfo
                      detailsData={selectedSection}
                      isEditDatasetSection={isEditDatasetSection}
                      handlEditedTextData={handlEditedTextData}
                      editedTextData={editedTextData}
                      cardId={cardId}
                      setEditedTextData={setEditedTextData}
                    />
                  )}

                  {selectedSection.title?.toLocaleLowerCase() !== "details" && (
                    <DisplaySections
                      sectionData={selectedSection}
                      isEditDatasetSection={isEditDatasetSection}
                      editedTextData={editedTextData}
                      handlEditedTextData={handlEditedTextData}
                    />
                  )}

                  {selectedSection.title?.toLocaleLowerCase() !== "details" &&
                    selectedSection.subsections.length > 0 &&
                    selectedSection.subsections.map((subSections) => {
                      return (
                        <>
                          {subSections.title === "Questionnaire" &&
                            subSections.questions.length > 0 && (
                              <p className="sub_title">Questionnaire</p>
                            )}
                          {subSections.title === "Questionnaire" &&
                            subSections.questions.length > 0 &&
                            subSections.questions.map((questionData, index) => {
                              if (
                                cardFilterWithPolicy.length > 0
                                  ? questionData.policy_tag !== null &&
                                    questionData.policy_tag !== "" &&
                                    questionData.policy_tag
                                      .split(",")
                                      .some((val) =>
                                        cardFilterWithPolicy.includes(val)
                                      )
                                  : true
                              ) {
                                return (
                                  <div
                                    style={{ marginBottom: "10px" }}
                                    key={questionData.card_question_id}
                                  >
                                    {questionData.is_visible && (
                                      <SectionsQuestionnaire
                                        questionData={questionData}
                                        index={index}
                                        isEditDatasetSection={
                                          isEditDatasetSection
                                        }
                                        handlEditedQuestionData={
                                          handlEditedQuestionData
                                        }
                                        updatedFiles={updatedFiles}
                                        fileList={fileList}
                                        handleUpdateFilesWhileDeleted={
                                          handleUpdateFilesWhileDeleted
                                        }
                                        handleUpdateFiles={handleUpdateFiles}
                                        showQueTextValue={showQueTextValue}
                                        data={data}
                                        cardResDataRefetch={cardResDataRefetch}
                                      />
                                    )}
                                  </div>
                                );
                              }
                            })}
                        </>
                      );
                    })}
                </div>
              </>
            )
          ) : (
            <CircularProgress
              style={{ marginLeft: "35vw", marginTop: "200px" }}
            />
          )}

          <div>
            <WarningModal
              showMigrationModal={showMigrationModal}
              setShowMigrationModal={setShowMigrationModal}
              migrationDifference={migrationDifference}
              cardResData={cardResData}
              onUpgradeClick={onUpgradeClick}
              isLoading={isLoading}
            />
          </div>

          {isEditDatasetSection && (
            <div className="dataset_btn">
              {showYesToallPopup && (
                <button
                  className="dataset_btn_active"
                  style={{ padding: "3px 33px 3px 33px" }}
                  onClick={handleCardYesToAll}
                >
                  Respond to all
                </button>
              )}

              <button className="dataset_btn_active" onClick={handleCardSave}>
                Save
              </button>
              <button
                className="dataset_btn_inactive"
                onClick={handleEditModeCancel}
              >
                Cancel
              </button>
            </div>
          )}

          <SimpleBackdrop openStat={false} />
        </Box>
      </Modal>
    </>
  );
};

export default Cards;
