import React, { useEffect, useState } from "react";
import axios from "axios";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useFormik } from "formik";
import {
  Container,
  Grid,
  TextField,
  Button,
  IconButton,
  InputAdornment,
  Typography,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { toast } from "react-toastify";
import { useNavigate, useLocation } from "react-router-dom";
import "./Login.css";
import konferlogo from "../../assets/images/konferLogo/konferlogo.svg";
import {
  analyticServices,
  setUserAnalyticsInfo,
} from "../../services/analyticServices";

const ResetPassword = () => {
  const [showPass, setShowPass] = useState(false);
  const [showConfirmPass, setShowConfirmPass] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  //Below useEffect is used for giving title to current page
  useEffect(() => {
    document.title = "Konfer Reset Password Page";
  });

  const validationSchema = yup.object({
    password: yup
      .string("Enter your password")
      .min(6, "Password should be of minimum 6 characters length")
      .required("Password is required"),
    confirmPassword: yup
      .string("Enter your password")
      .min(6, "Password should be of minimum 6 characters length")
      .required("Password is required")
      .oneOf([yup.ref("password")], "Passwords must match"),
  });

  const formik = useFormik({
    initialValues: {
      password: "",
      confirmPassword: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      // pass formik values to confirm email function
      handleResetPasswordSubmit(values);
    },
  });

  const handleResetPasswordSubmit = async (values) => {
    const { password } = values;
    const email = location.state.email;

    try {
      const res = await axios.post(
        `${process.env.REACT_APP_REST_API}/auth/forget-password`,
        {
          email,
          password,
        }
      );
      // redirect user to login page
      toast.success(`${res?.data?.message}`);
      navigate("/login");
    } catch (error) {
      const errMsg = error?.response?.data?.error
        ? error.response.data.error
        : "Something went wrong. Please try again later!";

      // validation response are in array , else all other respose are string
      if (Array.isArray(errMsg)) {
        errMsg.map((e) => {
          toast.error(e);
        });
      } else {
        toast.error(errMsg);
      }
    }
  };

  const handleClickShowPassword = () => {
    setShowPass(!showPass);
  };

  const handleClickShowConfirmPassword = () => {
    setShowConfirmPass(!showConfirmPass);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <div className="login_Container" id="resetPswdMainConatiner">
      <div className="login-page" id="resetPasswordDiv">
        <Grid>
          <Container>
            <Grid item sm={12} lg={12} md={12} xl={12} xs={12}>
              <div className="login-logo">
                <img src={konferlogo} alt="konfer-logo"></img>
              </div>

              {/* <Typography
                variant="h5"
                component="div"
                className="forgot-password-heading"
              >
                Reset Password
              </Typography> */}

              <div className="login_form" id="resetPasswordFormDiv">
                <form
                  noValidate
                  autoComplete="off"
                  onSubmit={formik.handleSubmit}
                >
                  <div className="pass-filed" id="newPassword_RF">
                    <TextField
                      fullWidth
                      className="login_textField"
                      label="Enter new password"
                      variant="outlined"
                      type={showPass ? "text" : "password"}
                      name="password"
                      id="new_password"
                      // value={password}
                      // onChange={(e) => setPassword(e.target.value)}
                      value={formik.values.password}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.password &&
                        Boolean(formik.errors.password)
                      }
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              id="newPasswordVisibility_RF"
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {showPass ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      helperText={
                        formik.touched.password && formik.errors.password
                      }
                    />
                  </div>

                  <div className="pass-filed" id="confirmPassword_RF">
                    <TextField
                      fullWidth
                      className="login_textField"
                      label="Confirm New Password"
                      variant="outlined"
                      type={showConfirmPass ? "text" : "password"}
                      name="confirmPassword"
                      id="confirm_password"
                      // value={password}
                      // onChange={(e) => setPassword(e.target.value)}
                      value={formik.values.confirmPassword}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.confirmPassword &&
                        Boolean(formik.errors.confirmPassword)
                      }
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              id="confirmPasswordVisibility_RF"
                              aria-label="toggle confirm password visibility"
                              onClick={handleClickShowConfirmPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {showConfirmPass ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      helperText={
                        formik.touched.confirmPassword &&
                        formik.errors.confirmPassword
                      }
                    />
                  </div>

                  <div className="submit-btn" id="submitButton_RFDiv">
                    <Button
                      className="login-btn"
                      type="submit"
                      id="submitButton_RF"
                    >
                      Reset Password
                    </Button>
                    <div id="buttonResetPasswordDiv"></div>
                  </div>
                </form>
              </div>
            </Grid>
          </Container>
        </Grid>
      </div>
    </div>
  );
};

export default ResetPassword;
