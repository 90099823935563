import React, { useState, useEffect } from "react";
import {
  IconButton,
  Dialog,
  DialogTitle,
  Typography,
  DialogContent,
  TextField,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { toast } from "react-toastify";
import CancelIcon from "../../assets/images/adminPanel/CancelIcon.svg";
import UncheckedIcon from "../../assets/images/graphView/Unchecked.svg";
import CheckedIcon from "../../assets/images/graphView/Checked.svg";
import { analyticServices } from "../../services/analyticServices";
import { queryKeyConstants } from "../../constants/basicConstants";
import nodeApiInstance from "../../api/instance/nodeApiInstance";
import { useMutation, useQueryClient } from "react-query";
import { policyPage } from "../../constants/toasterMsgConstants";
import { PolicyNameConst } from "../../constants/policyConstants";

/**
 * Component : NewPolicy
 *
 * Description : This component is responsible for creating new policy or editing the exixting policy.
 * Component is made up of Mui Dialog box and react hook form. Main fiedls are name, description and visibilty of policy.
 * buttons will displayed according editing /new policy
 *
 * Props : openNewPolicyDialog, setOpenNewPolicyDialog, edit/new policy, data fro editing policy
 */

const NewPolicy = ({
  openNewPolicyDialog,
  setOpenNewPolicyDialog,
  edit,
  dataForEditPolicy,
}) => {
  const queryClient = useQueryClient();
  const [statusValue, setStatusValue] = useState("Published");

  const [isEdited, setIsEdited] = useState(false);

  // form validation rules
  const validationSchema = Yup.object().shape({
    score_name: Yup.string().required("Name is required").trim(),
    description: Yup.string().optional().trim(),
    url: Yup.string().optional(),
    status: Yup.string().optional(),
  });
  const formOptions = { resolver: yupResolver(validationSchema) };

  //User Form
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm(formOptions);

  //Below useEffect is used for adding values in form fields, if the form is open in edit mode.
  useEffect(() => {
    if (edit === true) {
      setValue("score_name", dataForEditPolicy.score_name);
      setValue(
        "description",
        dataForEditPolicy.description === null
          ? ""
          : dataForEditPolicy.description
      );
      setValue(
        "url",
        dataForEditPolicy.url === null ? "" : dataForEditPolicy.url
      );
      setStatusValue(dataForEditPolicy.status);
      setValue("status", dataForEditPolicy.status);
    } else {
      setValue("status", "Published");
    }
  }, [edit, dataForEditPolicy]);

  const handleChange = (event) => {
    setStatusValue(event.target.value);
    setValue("status", event.target.value);
  };

  //API call for adding policy in the database
  const createPolicyMutation = useMutation(
    async (payload) => {
      const data = await nodeApiInstance.post("/policy/create-policy", payload);
      return data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(queryKeyConstants.getAllPolicies);
        toast.success(policyPage.addPolicySuccessMsg);
        setOpenNewPolicyDialog(false);
      },

      onError: (error) => {
        if (error?.response?.data?.error?.includes("Uniqueness violation")) {
          setError("score_name", {
            type: "custom",
            message: `Policy already exist`,
          });
        } else {
          toast.error(policyPage.addPolicyErrorMsg);
          setOpenNewPolicyDialog(false);
        }
      },
    }
  );

  //API call for updating policy in the database
  const updatePolicyMutation = useMutation(
    async (payload) => {
      const data = await nodeApiInstance.put("/policy/update-policy", payload);
      return data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(queryKeyConstants.getAllPolicies);
        toast.success(policyPage.updatePolicySuccessMsg);
        setOpenNewPolicyDialog(false);
      },
      onError: (error) => {
        toast.error(policyPage.updatePolicyErrorMsg);
        setOpenNewPolicyDialog(false);
      },
    }
  );

  //Below function is used to Create or edit the Policy
  const handleRegistration = (data) => {
    if (data.description.length > 500) {
      setError("description", {
        type: "custom",
        message: `Length should not exceed 500 characters`,
      });
    } else if (data.score_name.length > 500) {
      setError("score_name", {
        type: "custom",
        message: `Length should not exceed 500 characters`,
      });
    } else {
      if (edit === true) {
        analyticServices(` ${getValues("score_name")}, editing policy`);
        updatePolicyMutation.mutate({
          ...data,
          score_id: dataForEditPolicy?.score_id,
        });
      } else {
        analyticServices(` ${getValues("score_name")}, policy created`);
        createPolicyMutation.mutate(data);
      }
    }
  };

  const handleError = (errors) => {};

  return (
    <>
      <Dialog
        id="createPolicyDialog"
        open={openNewPolicyDialog}
        onClose={() => {
          setOpenNewPolicyDialog(!openNewPolicyDialog);
        }}
        aria-labelledby="new-policy-dialog"
        aria-describedby="new-policy-dialog"
        sx={{
          "& .MuiDialog-paper": {
            boxShadow: "0px 6px 10px rgba(0, 0, 0, 0.3)",
            borderRadius: "8px",
            width: "675px",
            height: "400px",
          },
        }}
      >
        <DialogTitle id="new-user-dialog" sx={{ m: 0, p: 3 }}>
          <Typography variant="h5" component="div" className="create-heading">
            {edit === true
              ? `Edit ${PolicyNameConst.policy}`
              : `New ${PolicyNameConst.policy}`}
          </Typography>
          <IconButton
            id="closePolicyDialog"
            aria-label="close"
            onClick={() => {
              setOpenNewPolicyDialog(false);
            }}
            sx={{
              position: "absolute",
              right: 16,
              top: 27,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <img src={CancelIcon} alt="Delete" />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ position: "relative", p: 3 }}>
          <form
            id="createPolicyform"
            onSubmit={handleSubmit(handleRegistration, handleError)}
            autoComplete="off"
            style={{ marginTop: "10px" }}
          >
            <div className="info-row" id="createPolicyName">
              <label className="info-label">
                Name <span style={{ color: "red", marginLeft: "-2px" }}>*</span>
              </label>
              <TextField
                className="info-input"
                sx={{ "& .MuiInputBase-input": { padding: "0px" } }}
                id="score_name"
                // label="Name"
                variant="standard"
                {...register("score_name")}
              />
            </div>
            {errors?.score_name && errors.score_name.message && (
              <div className="info-row-erros" id="createPolicyNameEr">
                <div></div>
                <small className="text-danger">
                  {errors?.score_name && errors.score_name.message}
                </small>
              </div>
            )}

            <div className="info-row" id="createPolicyDesc">
              <label className="user-info-label">Description</label>
              <TextField
                className="info-input"
                sx={{ "& .MuiInputBase-root": { padding: "0px" } }}
                id="policy_description"
                maxRows={2}
                variant="standard"
                multiline
                {...register("description")}
              />
            </div>
            {errors?.description && errors.description.message && (
              <div className="info-row-erros" id="createPolicyDescErr">
                <div></div>
                <small className="text-danger">
                  {errors?.description && errors.description.message}
                </small>
              </div>
            )}

            <div className="info-row" id="createPolicyUrl">
              <label className="user-info-label">
                {PolicyNameConst.policy} URL
              </label>
              <TextField
                className="info-input"
                sx={{ "& .MuiInputBase-input": { padding: "0px" } }}
                id="policy_url"
                // label="https/..."
                variant="standard"
                {...register("url")}
              />
            </div>

            <div className="info-row" id="createPolicyDesc">
              <label className="user-info-label">Status</label>

              <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                row
                value={statusValue}
                onChange={handleChange}
                sx={{
                  "& .MuiRadio-root": { padding: "0px 0px" },
                  "& .MuiTypography-root": { marginLeft: "10px" },
                  "& .MuiRadio-root.Mui-checked": { color: "#049547" },
                }}
              >
                <FormControlLabel
                  value="Draft"
                  control={<Radio />}
                  label="Draft"
                  sx={{ padding: "0px 0px" }}
                />
                <FormControlLabel
                  value="Published"
                  control={<Radio />}
                  label="Published"
                  sx={{ padding: "0px 0px 0px 80px" }}
                />
              </RadioGroup>
            </div>
            {errors?.status && errors.status.message && (
              <div className="info-row-erros" id="createBusineeUnitDescErr">
                <div></div>
                <small className="text-danger">
                  {errors?.status && errors.status.message}
                </small>
              </div>
            )}

            <button className="create-button" id="createBusineeunitButton">
              <span>{edit ? "Update" : "Create"}</span>
            </button>
          </form>
          <button
            className="cancel-button"
            id="buCancelButton"
            onClick={() => {
              setOpenNewPolicyDialog(false);
            }}
          >
            <span>Cancel</span>
          </button>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default NewPolicy;
