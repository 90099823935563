export const parameterizeQueryType = [
  "name",
  "description",
  "display_name",
  "version",
  // "created_at",
  "model_type",
  "algorithm_type",
  "external_model_dependency",
  "is_gdpr_compliant",
  "source_type",
  "source",
  "usage_type",
  "role",
];

export const parameterizeQueryTypeName = [
  "apps",
  "models",
  "datasets",
  "contributors",
];

export const parameterizeQueryContributor = {
  apps: ["contributors", "models"],
  models: ["datasets", "apps", "contributors"],
  datasets: ["contributors", "models"],
  contributors: ["datasets", "apps", "models"],
};

export const paramQueryTypeNameDescription = {
  // apps: ["name", "description", "display_name", "version", "created_at"],
  apps: [
    "name",
    "description",
    "display_name",
    "version",
    // , "created_at"
  ],

  models: [
    "name",
    "description",
    "display_name",
    "version",
    "model_type",
    "algorithm_type",
    "external_model_dependency",
    // "created_at",
  ],
  datasets: [
    "name",
    "display_name",
    // "is_gdpr_compliant",
    "source_type",
    "source",
    "usage_type",
  ],
  contributors: ["name", "description", "role"],
};

export const searchBasicConstant = {
  display_name: "display name",
  model_type: "model type",
  algorithm_type: "algorithm type",
  external_model_dependency: "external model dependency",
  is_gdpr_compliant: "is gdpr compliant",
  source_type: "source type",
  usage_type: "usage type",
  inputField: "",

  contains: "contains",
  contributors: "contributors",
  apps: "apps",
  datasets: "datasets",
  models: "models",

  // contains: "contain's",
  // contributors: "contributor's",
  // apps: "app's",
  // datasets: "dataset's",
  // models: "model's",

  // is: "",
  // contains: "",
};
