export const getLocalTokens = () => {
    const tokens = localStorage.getItem("tokens")
      ? JSON.parse(localStorage.getItem("tokens"))
      : null;
  
    return tokens;
  };
  
  export const updateTokenData = (tokens) => {
    localStorage.setItem("tokens", JSON.stringify(tokens));
  };
  

  export const logoutUser = () => {
    localStorage.clear()
  }