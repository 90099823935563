import React, { useState, useEffect } from "react";
import {
  IconButton,
  Dialog,
  DialogTitle,
  Typography,
  DialogContent,
  MenuItem,
  TextField,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import _ from "lodash";
import { toast } from "react-toastify";
import axios from "axios";
import CancelIcon from "../../../assets/images/adminPanel/CancelIcon.svg";
import "../CreateNewRegion/CreateNewRegion.css";
import { useMutation, useQueryClient } from "react-query";

import { queryKeyConstants } from "../../../constants/basicConstants";

const UpdateNewRegion = ({
  openUpdateBusinessunitDialog,
  setOpenUpdateBusinessunitDialog,
  dataForEditbusinessunit,
  setUpdateAdminRegion,
}) => {
  // form validation rules
  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .required("Name is required")
      // .min(3, "Name should be minimun 3 characters.")
      .trim(),
    description: Yup.string().optional().trim(),
  });
  const formOptions = { resolver: yupResolver(validationSchema) };

  //User Form
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm(formOptions);

  //Update business unit
  const handleRegistration = (data) => {
    const payload = {
      ...data,
      region_id: dataForEditbusinessunit?.region_id,
    };

    if (data.description.length > 500) {
      setError("description", {
        type: "custom",
        message: `Length should not exceed 500 characters`,
      });
    } else {
      setUpdateAdminRegion(payload);
      setOpenUpdateBusinessunitDialog(!openUpdateBusinessunitDialog);
      toast.success("Region updated");
    }
  };

  const handleError = (errors) => {};

  return (
    <>
      <Dialog
        id="updateBusinessunitDialog"
        open={openUpdateBusinessunitDialog}
        onClose={() => {
          setOpenUpdateBusinessunitDialog(!openUpdateBusinessunitDialog);
        }}
        aria-labelledby="new-user-dialog"
        aria-describedby="new-user-dialog"
        sx={{
          "& .MuiDialog-paper": {
            boxShadow: "0px 6px 10px rgba(0, 0, 0, 0.3)",
            borderRadius: "8px",
            width: "500.4px",
            height: "320.96px",
          },
        }}
      >
        <DialogTitle id="new-user-dialog" sx={{ m: 0, p: 3 }}>
          <Typography variant="h5" component="div" className="create-heading">
            Edit Region
          </Typography>
          <IconButton
            id="closeBuDialog"
            aria-label="close"
            onClick={() => {
              setOpenUpdateBusinessunitDialog(false);
            }}
            sx={{
              position: "absolute",
              right: 16,
              top: 27,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <img src={CancelIcon} alt="Delete" />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ position: "relative", p: 3 }}>
          <form
            id="cupdateBUform"
            onSubmit={handleSubmit(handleRegistration, handleError)}
            autoComplete="off"
            style={{ marginTop: "10px" }}
          >
            <div className="info-row">
              <label className="info-label">Name*</label>
              <TextField
                className="info-input"
                sx={{ "& .MuiInputBase-input": { padding: "0px" } }}
                id="businessunit_name"
                defaultValue={dataForEditbusinessunit?.name}
                variant="standard"
                {...register("name")}
              />
            </div>
            {errors?.name && errors.name.message && (
              <div className="info-row-erros" id="updateBuNameEr">
                <div></div>
                <small className="text-danger">
                  {errors?.name && errors.name.message}
                </small>
              </div>
            )}

            <div className="info-row" id="updateBuDesc">
              <label className="user-info-label">Description</label>
              <TextField
                className="info-input"
                sx={{ "& .MuiInputBase-root": { padding: "0px" } }}
                id="businessunit_description"
                defaultValue={dataForEditbusinessunit?.description}
                variant="standard"
                maxRows={4}
                multiline
                {...register("description")}
              />
            </div>
            {errors?.description && errors.description.message && (
              <div className="info-row-erros" id="updateBuErr">
                <div></div>
                <small className="text-danger">
                  {errors?.description && errors.description.message}
                </small>
              </div>
            )}
            <button
              onClick={() => {
                setOpenUpdateBusinessunitDialog(false);
              }}
              type="button"
              className="cancel-button"
              id="createBusineeunitButton"
              style={{
                marginBottom: "-18px",
                cursor: "pointer",
              }}
            >
              <span>Cancel</span>
            </button>

            <button
              style={{
                marginBottom: "-18px",
                cursor: "pointer",
              }}
              type="submit"
              className="create-button"
              id="buUpdateButton"
            >
              <span>Update</span>
            </button>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default UpdateNewRegion;
