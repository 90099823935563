import { FormControl, MenuItem, Select, Tooltip } from "@mui/material";
import React from "react";

const MuiSelectForPolicy = ({ minWidthVal, data, value, handleChange }) => {
  return (
    <FormControl id="ScorePolicy">
      {/* <Tooltip title={value} disableTriggerFocus={true}> */}
      <Select
        sx={{
          "& .MuiSelect-select": {
            padding: "5px",
            fontSize: "15px",
            fontWeight: "600",
            marginLeft: "-10px",
            width: "70px",
            // width: "100%",
            background: "none",
            "@media screen and (max-height: 780px)": {
              fontSize: "12px",
            },
          },
          "&::after": {
            background: "none",
          },
        }}
        variant="standard"
        id="complianceSelect"
        value={value}
        disableUnderline={true}
        onChange={(e) => {
          handleChange(e);
        }}
        autoWidth={false}
        MenuProps={{
          style: { maxHeight: "30%" },
          PaperProps: {
            sx: {
              border: "0.5px solid #6D8291",
              boxShadow: "0px 5px 10px rgba(0, 0, 0, 0.25)",
              borderRadius: "4px",
              paddingTop: "1px",

              "& .MuiMenuItem-root": {
                // padding: 1,
                fontFamily: "var( --fontFamilyText) !important",
                lineHeight: "1rem",
                "@media screen and (max-height: 780px)": {
                  fontSize: "14px",
                },
              },
            },
          },
        }}
      >
        <MenuItem
          id="selectMenuItem"
          sx={{
            "&.Mui-selected": {
              backgroundColor: "#F6F6F6",
              "&.Mui-focusVisible": { background: "#F6F6F6" },
            },
          }}
        >
          <div style={{ display: "flex" }}>
            <p
              style={{
                color: "#B9B9B9",
                margin: 0,
                padding: 0,
              }}
            >
              POLICIES
            </p>
          </div>
        </MenuItem>
        {data?.map((policyValue, index) => {
          return (
            policyValue.status.toUpperCase() === "PUBLISHED" &&
            policyValue.score_name !== "Konfer Confidence" &&
            policyValue.score_name !== "Human Impact" && (
              <MenuItem
                key={index}
                value={policyValue.score_name}
                id="selectPolicyValue"
              >
                {policyValue.score_name}
              </MenuItem>
            )
          );
        })}
      </Select>
      {/* </Tooltip> */}
    </FormControl>
  );
};

export default MuiSelectForPolicy;
