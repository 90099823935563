export const KONFER_SCORE = "konfer-confidence";

export const queryKeyConstants = {
  appData: "appData",
  modelData: "modelData",
  datasetData: "datasetData",
  cardTemplateData: "cardTemplateData",

  getAnnotation: "getAnnotation",
  appsDetails: "appsDetails",
  modelsDetails: "modelsDetails",
  datasetsDetails: "datasetsDetails",
  businessUnits: "businessUnits",
  riskfactors: "riskfactors",
  getAppKonferScore: "getAppKonferScore",
  getModelKonferScore: "getModelKonferScore",
  getDatasetKonferScore: "getDatasetKonferScore",
  searchData: "searchData",
  modelCardData: "modelCardData",
  userlist: "userlist",
  deleteUser: "deleteUser",
  userRole: "userRole",
  userBusinessUnit: "userBusinessUnit",
  getRiskFactorsVisibilityData: "getRiskFactorsVisibilityData",
  getObservabilityData: "getObservabilityData",
  getEditableFields: "getEditableFields",
  getAllBusinessUnits: "getAllBusinessUnits",
  getAllRegion: "getAllRegion",
  appTypename: "app",
  modelTypename: "model",
  datasetTypename: "dataset",
  getlistviewConfidenceFactors: "getlistviewConfidenceFactors",
  getSummaryConfidenceFactors: "getSummaryConfidenceFactors",
  businessUnitsNew: "businessUnitsNew",
  allAppsDetailsWithBU: "allAppsDetailsWithBU",
  allModelsDetailsWithBU: "allModelsDetailsWithBU",
  allDatasetsDetailsWithBU: "allDatasetsDetailsWithBU",
  getSlackUsers: "getSlackUsers",
  getAllRegion: "getALlRegion",
  getBusinessUnitsWithRegionId: "getBusinessUnitsWithRegionId",
  cardTemplateDataContributor: "cardTemplateDataContributor",
  getCPOAnnotation: "getCPOAnnotation",
  getExtractionHistory: "getExtractionHistory",
  getConfigData: "getConfigData",
  getConfidenceFactors: "getConfidenceFactors",
  searchDataAppStatus: "DeleteCompleted",
  getQuestionById: "getQuestionById",
  searchDataAppStatusCompleted: "CreateCompleted",
  getDependencyFileData: "getDependencyFileData",
  getAllRoles: "getAllRoles",
  getCreateRoles: "getCreateRoles",
  deleteRole: "deleteRole",
  getAccessList: "getAccessList",
  getAccessByRole: "getAccessByRole",
  getTemplateData: "getTemplateData",
  getAllPolicies: "getAllPolicies",
  configurations: "configurations",
  getListviewTableData: "getListviewTableData",
  createTextData: "createTextData",
  updateTextData: "updateTextData",
  getMigrationDifference: "getMigrationDifference",
  getRiskFactorsByScoreId: "getRiskFactorsByScoreId",
  getScoresList: "getScoresList",
  getCodeAnalysisData: "getCodeAnalysisData",
  getAllNodeBuRelData: "getAllNodeBuRelData",
  getRoleRegionBuData: "getRoleRegionBuData",
  getAccessDataByRole: "getAccessDataByRole",
  getExtractHistoryData: "getExtractHistoryData",
  getCompanyLogo: "getCompanyLogo",
  getNlpQueryData: "getNlpQueryData",
};
