import React, { useState } from "react";
import HumanImpactIcon from "../../assets/images/adminPanel/HumanImpact.svg";
import Policy from "../../assets/images/adminPanel/Policy.svg";
import "./CardTemplateQuestionData.css";
import {
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Popover,
  Typography,
  Select,
  Tooltip,
} from "@mui/material";
import deleteIcon from "../../assets/images/dataSetCard/deleteIcon.svg";
import visibilityOff from "../../assets/images/dataSetCard/hideVisibility.svg";
import visibilityDisabled from "../../assets/images/dataSetCard/isMandatoryVisibleIcon.svg";
import visibilityOn from "../../assets/images/dataSetCard/showData.svg";
import { cardTemplateQuestionDataTooltips } from "../../constants/tooltipConstants";
import { isAccessProvided } from "../../services/assetManagementService";
import {
  moduleConstants,
  subModuleConstants,
} from "../../constants/accessManagementConstants";
import { PolicyNameConst } from "../../constants/policyConstants";

const queDropdownOptions = ["text", "boolean", "file"];
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const CardTemplateQuestionData = ({
  sectionId,
  isEditActive,
  questionData,
  index,
  policyData,
  handleTemplateEdit,
  editedQuestion,
  selectedEntity,
  accessDataByRole,
}) => {
  //state to show a policy list for a question
  const [openPolicyCardList, setOpenPolicyCardList] = useState(false);
  const open = Boolean(openPolicyCardList);

  const handleStringToBoolean = (booleanString) => {
    if (booleanString === true) {
      return true;
    }
    if (booleanString === false) {
      return false;
    }
  };

  const getPolicyValueNew = (val, ID) => {
    let valArray = [];

    if (val !== null && val !== "NULL" && val !== undefined) {
      val.split(",").length > 0 &&
        val.split(",").map((tags) => {
          tags !== undefined && tags !== "" && valArray.push(`${tags}`);
        });
    }

    return valArray;
  };

  const handleFormatData = (fieldType) => {
    if (editedQuestion) {
      if (fieldType === "answer_type") {
        return eval(editedQuestion[fieldType]);
        // return editedQuestion[fieldType];
      } else {
        return editedQuestion[fieldType];
      }
    } else {
      if (fieldType === "answer_type") {
        return eval(questionData[fieldType]);
        // return questionData[fieldType];
      } else {
        return questionData[fieldType];
      }
    }
  };

  //disable visibility if human impact or mandatory or policy added.
  const isVisibilityDisabled = () => {
    let isVisibilityDisabled;

    // if this condition is true then the visibility icon will be disabled
    if (
      handleFormatData("is_required") === true ||
      handleFormatData("is_human_impact") === true ||
      (handleFormatData("policy") !== null &&
        handleFormatData("policy") !== "NULL" &&
        handleFormatData("policy") !== "")
    ) {
      isVisibilityDisabled = true;
    } else {
      isVisibilityDisabled = false;
    }

    return isVisibilityDisabled;
  };

  // if boolean chnaged to non boolean , disable Mandatory, Human Impact and Desired Response and policy dropdown for Non-boolean question..
  const isNonBooleanDisabled = () => {
    if (handleFormatData("answer_type").includes("boolean")) {
      return false;
    } else {
      return true;
    }
  };

  const handleTooltip = (fieldName = "") => {
    let tooltipText = "";

    switch (fieldName) {
      case "is_human_impact":
        if (isNonBooleanDisabled()) {
          tooltipText = cardTemplateQuestionDataTooltips.human_impact;
        }
        break;
      case "mandatory":
        if (isNonBooleanDisabled()) {
          tooltipText = cardTemplateQuestionDataTooltips.mandatory;
        }
        break;
      case "is_desired_response":
        if (isNonBooleanDisabled()) {
          tooltipText = cardTemplateQuestionDataTooltips.desired_response;
        }
        break;
      case "visibility":
        if (isVisibilityDisabled()) {
          tooltipText = cardTemplateQuestionDataTooltips.visibility;
        }
        break;
      case "policy":
        if (isNonBooleanDisabled()) {
          tooltipText = cardTemplateQuestionDataTooltips.policy;
        }
        break;
      default:
        tooltipText = "";
        break;
    }

    return tooltipText;
  };

  return (
    <>
      <div
        className={
          isEditActive
            ? "dataset_card_question_container"
            : "dataset_card_question_container_unEdit"
        }
      >
        <p
          style={{
            fontSize: "15px",
            marginTop: !isEditActive ? "18px" : "0px",
            fontWeight: "bold",
            display: "flex",
          }}
        >
          {index + 1}

          <span style={{ color: "#F25757", marginLeft: "2px" }}>
            {questionData?.is_required === true && "*"}
          </span>
        </p>

        {!isEditActive && (
          <p
            style={{
              fontWeight: questionData.isbold == true ? "bold" : "",
              fontStyle: questionData.isitalic == true ? "italic" : "",
              textDecoration: questionData.underline == true ? "underline" : "",
              marginLeft: "10px",
            }}
          >
            {questionData?.question_text}
            {questionData.is_human_impact === true ? (
              <img src={HumanImpactIcon} style={{ width: "18px" }} />
            ) : (
              ""
            )}
            {questionData?.policy !== null &&
              questionData?.policy !== "NULL" &&
              questionData?.policy !== "" &&
              questionData?.policy?.split(",").length > 0 && (
                <span
                  style={{ marginTop: "5px", display: "block" }}
                  className="policyTaggingHeading"
                >
                  <img src={Policy} alt="" style={{ marginRight: "3px" }} />
                  {PolicyNameConst.policies}:
                  {questionData?.policy?.split(",").map((tags, index) => {
                    return (
                      <>
                        {index < 1 && (
                          <span key={index} className="policy_Tagging_Value">
                            {" "}
                            {`  ${tags}`}{" "}
                          </span>
                        )}

                        {(() => {
                          if (
                            index + 1 ===
                              questionData?.policy?.split(",").length &&
                            questionData?.policy?.split(",").length > 1
                          ) {
                            return (
                              <span
                                key={index}
                                className="policy_Tagging_Hidden_Value"
                                onClick={(e) => {
                                  setOpenPolicyCardList(e.currentTarget);
                                }}
                              >
                                +{questionData?.policy?.split(",").length - 1}
                              </span>
                            );
                          }
                        })()}
                      </>
                    );
                  })}
                </span>
              )}
            <Popover
              sx={{
                "& .MuiPopover-paper": {
                  boxShadow: "none",
                  border: "0.5px solid #6D8291 ",
                  width: "325px",
                  minHeight: "0px",
                  color: "#666666",
                },
              }}
              id={questionData.template_question_id}
              open={open}
              anchorEl={openPolicyCardList}
              onClose={() => setOpenPolicyCardList(null)}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
            >
              {questionData?.policy != null &&
                questionData?.policy != "NULL" &&
                questionData?.policy?.split(",").length > 0 &&
                questionData?.policy?.split(",").map((tags, index) => {
                  return (
                    <>
                      {index > 0 && (
                        <Typography
                          sx={{
                            margin: "5px 10px 5px 10px",
                          }}
                          key={index}
                        >
                          {" "}
                          {tags}
                        </Typography>
                      )}
                    </>
                  );
                })}
            </Popover>
          </p>
        )}

        {isEditActive && (
          <>
            <div className="dataset_card_question">
              <div className="bold_italic_underline_text">
                {/* /**For CME Purpose */}
                {/* <Tooltip
                  placement="top"
                  title={handleTooltip("is_human_impact")}
                  arrow
                >
                  <p style={{ display: "flex" }}>
                    <Checkbox
                      checked={handleStringToBoolean(
                        handleFormatData("is_human_impact")
                      )}
                      value={handleStringToBoolean(
                        handleFormatData("is_human_impact")
                      )}
                      color="default"
                      disabled={isNonBooleanDisabled()}
                      onClick={(e) => {
                        handleTemplateEdit(
                          sectionId,
                          "question",
                          "is_human_impact",
                          !handleStringToBoolean(
                            handleFormatData("is_human_impact")
                          ),
                          questionData.template_question_id
                        );
                      }}
                    />

                    <span
                      style={{
                        fontWeight: "normal",
                        marginTop: "13px",
                        color: isNonBooleanDisabled() ? "#c4c4c4" : "black",
                      }}
                    >
                      Human Impact
                    </span>
                  </p>
                </Tooltip> */}

                <Tooltip
                  placement="top"
                  title={handleTooltip("mandatory")}
                  arrow
                >
                  <p style={{ display: "flex" }}>
                    <Checkbox
                      checked={handleStringToBoolean(
                        handleFormatData("is_required")
                      )}
                      value={handleStringToBoolean(
                        handleFormatData("is_required")
                      )}
                      color="default"
                      disabled={isNonBooleanDisabled()}
                      onClick={(e) => {
                        handleTemplateEdit(
                          sectionId,
                          "question",
                          "is_required",
                          !handleStringToBoolean(
                            handleFormatData("is_required")
                          ),
                          questionData.template_question_id
                        );
                      }}
                    />

                    <span
                      style={{
                        fontWeight: "normal",
                        marginTop: "13px",
                        color: isNonBooleanDisabled() ? "#c4c4c4" : "black",
                      }}
                    >
                      Mandatory
                    </span>
                  </p>
                </Tooltip>

                <p
                  style={{
                    marginRight: "5px",
                    color: isNonBooleanDisabled() ? "#c4c4c4" : "black",
                  }}
                >
                  Desired Response:{" "}
                </p>
                <Tooltip
                  placement="top"
                  title={handleTooltip("is_desired_response")}
                  arrow
                >
                  <div
                    className="scoreInclusionCont"
                    id="scoreInclusionButtonsDiv"
                  >
                    <div
                      id="scoreInclusionButton"
                      className={
                        handleFormatData("is_desired_response") === true
                          ? "scoreInclusionBtn scoreInclusionActive"
                          : "scoreInclusionBtn"
                      }
                      onClick={() => {
                        if (isNonBooleanDisabled() === false) {
                          handleTemplateEdit(
                            sectionId,
                            "question",
                            "is_desired_response",
                            true,
                            questionData.template_question_id
                          );
                        }
                      }}
                    >
                      <span
                        className={
                          handleFormatData("is_desired_response") === false
                            ? "scoreInclusionBtnTextActive"
                            : " scoreInclusionBtnText"
                        }
                      >
                        YES
                      </span>
                    </div>

                    <div
                      id="resultGraphviewButton"
                      className={
                        questionData?.is_desired_response === true
                          ? "scoreInclusionBtn"
                          : "scoreInclusionBtn scoreInclusionActive"
                      }
                      onClick={() => {
                        if (isNonBooleanDisabled() === false) {
                          handleTemplateEdit(
                            sectionId,
                            "question",
                            "is_desired_response",
                            false,
                            questionData.template_question_id
                          );
                        }
                      }}
                    >
                      <span
                        className={
                          questionData?.is_desired_response === true
                            ? "scoreInclusionBtnText"
                            : "scoreInclusionBtnTextActive"
                        }
                      >
                        NO
                      </span>
                    </div>
                  </div>
                </Tooltip>
              </div>
              <div
                className="dataset_answer"
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <textarea
                  className="datasets_card_container_answer_input"
                  type="text"
                  style={{
                    fontWeight: questionData.isbold == true ? "bold" : "",

                    fontStyle: questionData.isitalic == true ? "italic" : "",

                    textDecoration:
                      questionData.underline == true ? "underline" : "",
                  }}
                  placeholder="Enter question text here..."
                  defaultValue={questionData.question_text}
                  value={handleFormatData("question_text")}
                  onChange={(e) => {
                    handleTemplateEdit(
                      sectionId,
                      "question",
                      "question_text",
                      e.target.value,
                      questionData.template_question_id
                    );
                  }}
                  wrap="soft"
                />
              </div>
            </div>
            <div className="question_type_div">
              <div>
                <div className="question_type_div">
                  <FormControl sx={{ width: 280 }} size="small">
                    <InputLabel id="demo-multiple-checkbox-label">
                      {" "}
                      Type
                    </InputLabel>
                    <Tooltip
                      placement="top"
                      title={handleTooltip("answer_type")}
                      arrow
                    >
                      <Select
                        labelId="demo-multiple-checkbox-label"
                        id="demo-multiple-checkbox"
                        multiple
                        value={handleFormatData("answer_type")}
                        onChange={(e) => {
                          handleTemplateEdit(
                            sectionId,
                            "question",
                            "answer_type",
                            e.target.value,
                            questionData.template_question_id
                          );
                        }}
                        input={<OutlinedInput label="Tag" />}
                        renderValue={(selected) => selected.join(", ")}
                        MenuProps={MenuProps}
                      >
                        {queDropdownOptions.map((name) => (
                          <MenuItem key={name} value={name}>
                            <Checkbox
                              checked={
                                handleFormatData("answer_type").indexOf(name) >
                                -1
                              }
                            />
                            <ListItemText primary={name} />
                          </MenuItem>
                        ))}
                      </Select>
                    </Tooltip>
                  </FormControl>

                  <div className="template_img_icon_div">
                    {isVisibilityDisabled() === false && (
                      <img
                        style={{
                          width: "21px",
                          height: "21px",
                        }}
                        className="template_img_icon"
                        src={
                          handleFormatData("is_visible") === true
                            ? visibilityOn
                            : visibilityOff
                        }
                        onClick={(e) => {
                          handleTemplateEdit(
                            sectionId,
                            "question",
                            "is_visible",
                            handleFormatData("is_visible") === true
                              ? false
                              : true,
                            questionData.template_question_id
                          );
                        }}
                      />
                    )}

                    {isVisibilityDisabled() === true && (
                      <Tooltip
                        placement="top"
                        title={handleTooltip("visibility")}
                        arrow
                      >
                        <img
                          style={{
                            width: "21px",
                            height: "21px",
                          }}
                          className="template_img_icon"
                          alt=""
                          src={visibilityDisabled}
                        />
                      </Tooltip>
                    )}

                    {isAccessProvided(
                      moduleConstants.card_templates,
                      subModuleConstants.crud,
                      null,
                      "delete",
                      accessDataByRole
                    ) === true && (
                      <img
                        src={deleteIcon}
                        style={{
                          width: "21px",
                          height: "21px",
                        }}
                        className="template_img_icon"
                        alt=""
                        onClick={() => {
                          handleTemplateEdit(
                            sectionId,
                            "questionDelete",
                            "delete",
                            null,
                            questionData.template_question_id
                          );
                        }}
                      />
                    )}
                  </div>
                </div>

                <FormControl
                  className="policy_popup_width"
                  size="small"
                  sx={{ mt: 1 }}
                >
                  <InputLabel id="demo-multiple-checkbox-label">
                    Select {PolicyNameConst.policy}
                  </InputLabel>
                  <Tooltip
                    placement="top"
                    title={handleTooltip("policy")}
                    arrow
                  >
                    <Select
                      labelId="demo-multiple-checkbox-label"
                      id="demo-multiple-checkbox"
                      multiple
                      value={getPolicyValueNew(handleFormatData("policy"))}
                      disabled={isNonBooleanDisabled()}
                      onChange={(e) => {
                        const {
                          target: { value },
                        } = e;
                        handleTemplateEdit(
                          sectionId,
                          "question",
                          "policy",
                          value.join(),
                          questionData.template_question_id
                        );
                      }}
                      input={<OutlinedInput label="Select Policy" />}
                      renderValue={(selected) => selected.join(", ")}
                      MenuProps={MenuProps}
                    >
                      {policyData.map((policy) => {
                        if (policy[`is_applies_to_${selectedEntity.type}`]) {
                          return (
                            <MenuItem
                              key={policy.score_name}
                              value={policy.score_name}
                            >
                              <Checkbox
                                checked={
                                  getPolicyValueNew(
                                    handleFormatData("policy")
                                  ).indexOf(policy.score_name) > -1
                                }
                              />
                              <ListItemText primary={policy.score_name} />
                            </MenuItem>
                          );
                        }
                      })}
                    </Select>
                  </Tooltip>
                </FormControl>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default CardTemplateQuestionData;
