import React, { useState } from "react";
import { useAtom } from "jotai";
import { IconButton } from "@mui/material";
import {
  CloseOutlined,
  KeyboardArrowDownOutlined,
  KeyboardArrowUpOutlined,
  Search,
} from "@mui/icons-material";
import "./InternalSearch.css";

const InternalSearch = ({
  isInputField,
  findElementInNodeDetails,
  setFindElementInNodeDetails,
  totalCountForNodeDetails,
  setTotalCountForNodeDetails,
  activeCountForNodeDetails,
  setActiveCountForNodeDetails,
}) => {
  const openSidebar = false;

  const [searchText, setSearchText] = useState("");

  const handleDown = () => {
    setActiveCountForNodeDetails(activeCountForNodeDetails + 1);
  };

  const handleUp = () => {
    setActiveCountForNodeDetails(activeCountForNodeDetails - 1);
  };

  const handleSearch = () => {};
  return (
    <>
      <div
        className={isInputField && "detailInput"}
        id="nodeDetailsInternalSearch"
      >
        <div
          className={isInputField ? "queryInput_sidebarOpen" : "queryInput"}
          id="nodeDetailsInternalSearchInput"
        >
          <input
            className="queryInputFiled"
            type="text"
            placeholder={`Find`}
            id="nodeDetails_input"
            defaultValue={findElementInNodeDetails}
            // value={searchText}
            // onKeyPress={(ev) => {
            //   if (ev.key === "Enter") {
            //     handleSearch();
            //   }
            // }}
            // onChange={(e) => {
            //   setSearchText(e.target.value);
            // }}

            onChange={(e) => {
              if (e.target.value === "") {
                setFindElementInNodeDetails(e.target.value);
              }
            }}
            onKeyPress={(ev) => {
              if (ev.key === "Enter") {
                setFindElementInNodeDetails(ev.target.value);
              }
            }}
          />

          <span style={{ marginLeft: "-10px" }} id="nodeDetailsShowCount">
            {findElementInNodeDetails === ""
              ? "0/0"
              : `${activeCountForNodeDetails}/${totalCountForNodeDetails}`}
          </span>
          <span style={{ marginLeft: "10px" }}>|</span>

          <IconButton
            id="nodeDetailsDownArrowIcon"
            aria-label="downArrow"
            style={{ padding: "0px", marginTop: "3px" }}
            title="Down"
            onClick={handleDown}
            disabled={
              activeCountForNodeDetails >= totalCountForNodeDetails ||
              findElementInNodeDetails === ""
            }
          >
            <KeyboardArrowDownOutlined />
          </IconButton>

          <IconButton
            id="nodeDetailsUpArrowIcon"
            aria-label="upArrow"
            style={{ padding: "0px", marginTop: "3px" }}
            title="Up"
            onClick={handleUp}
            disabled={
              activeCountForNodeDetails === 0 || findElementInNodeDetails === ""
            }
          >
            <KeyboardArrowUpOutlined />
          </IconButton>

          <IconButton
            id="nodeDetailsCancelIcon"
            type="submit"
            style={{ padding: "0px", marginTop: "3px", marginRight: "7px" }}
            //className="cancel_querypage_searchIcons"
            onClick={(e) => {
              setFindElementInNodeDetails("");
              document.getElementById("nodeDetails_input").value = "";
              setActiveCountForNodeDetails(0);
            }}
          >
            {" "}
            <CloseOutlined fontSize="small" />
          </IconButton>

          {/* {searchText && (
            <IconButton
              type="submit"
              style={{ marginRight: "-27px" }}
              className="cancel_querypage_searchIcons"
              onClick={() => setSearchText("")}
              aria-label="cancel"
            >
              <CloseOutlined fontSize="medium" />
            </IconButton>
          )} */}

          {/* <span className="searchIcons">
            <Search
              className={
                isInputField ? "searchIconResultForDetail" : "searchIconResult"
              }
              onClick={() => handleSearch()}
            />
          </span> */}
        </div>
      </div>
    </>
  );
};

export default InternalSearch;
