import React, { useEffect, useLayoutEffect, useState } from "react";
import * as am5 from "@amcharts/amcharts5";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import * as am5xy from "@amcharts/amcharts5/xy";

import { Box } from "@mui/system";

const GroupBarChart = ({ data }) => {
  const uniqueDivId = Math.floor(Math.random() * 800000);

  useLayoutEffect(() => {
    // Create root element
    let root = am5.Root.new(`${uniqueDivId}`);

    // Set themes
    root.setThemes([am5themes_Animated.new(root)]);

    // Create chart
    var chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panX: false,
        panY: false,
        layout: root.verticalLayout,
      })
    );

    /* show chart title */ 
    chart.topAxesContainer.children.push(
      am5.Label.new(root, {
        text: "counts|purpose",
        fontSize: 18,
        fontWeight: "400",
        x: am5.p50,
        centerX: am5.p50,
      })
    );

    // Create axes
    var yAxis = chart.yAxes.push(
      am5xy.CategoryAxis.new(root, {
        categoryField: "groupLabel",
        renderer: am5xy.AxisRendererY.new(root, {
          cellStartLocation: 0.1,
          cellEndLocation: 0.9,
        }),
      })
    );

    /* Show title on yAxis */
    yAxis.children.unshift(
      am5.Label.new(root, {
        rotation: -90,
        text: "purpose",
        y: am5.p50,
        center: am5.p50,
      })
    );

    yAxis.data.setAll(data);

    var xAxis = chart.xAxes.push(
      am5xy.ValueAxis.new(root, {
        extraMax: 0.1,
        renderer: am5xy.AxisRendererX.new(root, {
          strokeOpacity: 0.1,
        }),
        min: 0,
      })
    );

    // Add series
    function createSeries(field, name, color) {
      var series = chart.series.push(
        am5xy.ColumnSeries.new(root, {
          name: name,
          xAxis: xAxis,
          yAxis: yAxis,
          valueXField: field,
          fill: color,
          categoryYField: "groupLabel",
          tooltip: am5.Tooltip.new(root, {
            pointerOrientation: "horizontal",
            labelText: "[bold]{name}[/]\n{categoryY}: {valueX}",
          }),
        })
      );

      series.columns.template.setAll({
        height: am5.p100,
        strokeOpacity: 0,
      });

      series.data.setAll(data);
      series.appear();

      return series;
    }

    createSeries("GoodCredit", "GoodCredit", am5.color(0x2596be));
    createSeries("BadCredit", "BadCredit", am5.color(0xff7f0e));

    var legend = root.container.children.push(
      am5.Legend.new(root, {
        y: am5.percent(10),
        x: am5.percent(100),
        centerX: am5.percent(100),
        layout: root.verticalLayout,
      })
    );

    legend.data.setAll(chart.series.values);

    // Add cursor
    var cursor = chart.set(
      "cursor",
      am5xy.XYCursor.new(root, {
        behavior: "zoomY",
      })
    );
    cursor.lineY.set("forceHidden", true);
    cursor.lineX.set("forceHidden", true);

    return () => {
      root.dispose();
    };
  }, []);

  return (
    <div>
      <Box
        id={uniqueDivId}
        className="custom-chart"
        style={{
          height: "60vh",
          width: "80%",
        }}
      ></Box>
    </div>
  );
};
export default GroupBarChart;
