import { Box, CircularProgress } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  useGetAppData,
  useGetDatasetData,
  useGetModelData,
  useGetObservabilityData,
} from "../../api/readApiFunctions";
import AmchartLineChart from "../../components/AmchartComponents/AmchartLineChart";
import Layout from "../../layout/Layout";
import NoResult from "../../components/NoResult/NoResult";
import "./ObservabilityPage.css";
import ObservabilityTable from "../../components/ObservabilityTable/ObservabilityTable";
import { typeNames } from "../../constants/dataTypes";
import { useAtom } from "jotai";
import {
  accessDataByRoleAtom,
  observabilityBreadcrumbsData,
} from "../../atom/jotai";
import NoBusinessUnitsAssignedModal from "../../components/NoBusinessUnitsAssignedModal/NoBusinessUnitsAssignedModal";

const ObservabilityPage = () => {
  const { nodeType, nodeId } = useParams();

  const [lineChartData, setLineChartData] = useState(null);
  const [tableData, setTableData] = useState(null);
  const [selectedData, setSelectedData] = useState(null);
  const [observabilityBreadcrumbs, setObservabilityBreadcrumbs] = useAtom(
    observabilityBreadcrumbsData
  );
  const [accessDataByRole, setAccessDataByRole] = useAtom(accessDataByRoleAtom);

  // get observability Data
  const { data, isLoading, isError } = useGetObservabilityData(
    nodeType,
    nodeId
  );

  // get node details
  const { data: appData } = useGetAppData(nodeId, nodeType === typeNames.apps);
  const { data: modelData } = useGetModelData(
    nodeId,
    nodeType === typeNames.models
  );
  const { data: datasetData } = useGetDatasetData(
    nodeId,
    nodeType === typeNames.datasets
  );

  useEffect(() => {
    if (data?.resData?.data && Object.keys(data.resData.data).length !== 0) {
      const { mapData, finalTableFormat } = data;

      const lineData = {
        observabilities: mapData,
      };

      setLineChartData(lineData);
      setTableData(finalTableFormat);
    }
  }, [data]);

  useEffect(() => {
    if (nodeType === typeNames.apps && appData) {
      setSelectedData(appData.app);
    }
    if (nodeType === typeNames.models && modelData) {
      setSelectedData(modelData.model);
    }
    if (nodeType === typeNames.datasets && datasetData) {
      setSelectedData(datasetData.input_data_table);
    }
  }, [appData, modelData, datasetData, nodeType]);

  //Below useEffect is used for giving title to current page
  useEffect(() => {
    document.title = "Observability Page";
  });

  //  the useEffect used for set observability breadcrumbs
  useEffect(() => {
    setObservabilityBreadcrumbs([
      { names: "Search results" },
      { names: "Details" },
      { names: "Observability" },
    ]);
  }, []);

  return (
    <>
      <div class="observabilitylayout" id="observabilityLayout">
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            p: 3,
            overflowY: "auto",
          }}
          className="observabilityLayoutBox"
          id="observabilityLayoutBox"
        >
          {/* show node name*/}
          {selectedData && (
            <p className="chart-name" id="observabilityNodeName">
              {!selectedData[0]?.display_name
                ? selectedData[0].name
                : selectedData[0]?.display_name}
            </p>
          )}

          {isLoading && (
            <div className="observability__loader" id="observabilityLoader">
              <CircularProgress />
            </div>
          )}

          {/* Error Handler */}
          {(isError || lineChartData === null) ? (
            <NoResult title="No Observability data to show" />
          ) : (
            <>
              {/* Render Chart */}
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
                id="observabilityChartAndTable"
              >
                <AmchartLineChart data={lineChartData} />

                {/* Render Table */}
                {lineChartData && tableData && (
                  <ObservabilityTable
                    lineChartData={lineChartData}
                    tableData={tableData}
                    id={nodeId}
                    indexname={nodeType}
                  />
                )}
              </div>
            </>
          )}
        </Box>
      </div>
      {/**Below Code is for when no bussiness unit is attached to the logged in user */}
      <NoBusinessUnitsAssignedModal
        accessDataByRole={accessDataByRole}
        moduleInvoker={"home"}
      />
    </>
  );
};

export default ObservabilityPage;
